//js code packages
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//generic components and js functions
import * as LibraryReact from "../../Library/LibraryReact.js";
import * as JSFUNC from "../../Library/JSFUNC.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";

import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";




export const AdminTabCaptureImport = inject("AdminImportMobx")(observer(
class AdminTabCaptureImport extends Component {
  render() {
    const importFlag = this.props.AdminImportMobx.o_importFlag;

    if(importFlag === "capturesAnalysisTranslationAndImportOrUpdate") {
      return(
        <>
          <AdminImportTranslateFieldsAndImport />
          <AdminImportDatabaseProgressFloatingBoxes />
        </>
      );
    }

    if(importFlag === "contactsAnalysisAndImport") {
      return(
        <>
          <AdminImportContactsAnalysisAndImport />
          <AdminImportDatabaseProgressFloatingBoxes />
        </>
      );
    }

    return(<AdminImportUploadCsvAndSelectPreset />);
  }
}));


const AdminImportUploadCsvAndSelectPreset = inject("CaptureExecMobx", "AdminMobx", "AdminImportMobx", "DatabaseMobx", "UserMobx")(observer(
class AdminImportUploadCsvAndSelectPreset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_uploadingFileTF: false,
      s_showAllCsvRowsTF: false
    };
  }

  onclick_admin_import_subtab = (i_selectedImportTab) => {
    this.props.AdminImportMobx.a_import_set_import_flag(i_selectedImportTab);
    this.onclick_clear_uploaded_csv_file();
  }

  upload_import_csv_file = (i_filesArray) => {
    if(i_filesArray.length !== 1) {
      alert("Upload only 1 .csv file of Captures to import");
    }
    else {
      const dataTransferFileObj = i_filesArray[0];
      const filePartsObj = JSFUNC.file_parts_obj(dataTransferFileObj.name);

      if(filePartsObj.fileExt !== "csv") {
        alert("Uploaded file must have a '.csv' extension (Attempted to import '" + filePartsObj.fileNameAndExt + "')");
      }
      else {
        this.setState({s_uploadingFileTF:true});

        const functionOnSuccess = (i_fileDataString) => {
          this.props.AdminImportMobx.a_import_set_uploaded_csv_file_name(filePartsObj.fileNameAndExt);
          this.props.AdminImportMobx.a_import_set_uploaded_csv_file_data_string(i_fileDataString);
          this.setState({s_uploadingFileTF:false});
        };

        const functionOnError = () => {
          this.setState({s_uploadingFileTF:false});
          alert("Error reading the uploaded file ('" + filePartsObj.fileNameAndExt + "')");
        };

        JSFUNC.read_file_data_string_from_uploaded_data_transfer_file_obj(dataTransferFileObj, functionOnSuccess, functionOnError);
      }
    }
  }

  onswitch_csv_has_first_header_row_tf = () => {
    this.props.AdminImportMobx.a_import_toggle_csv_file_has_first_header_row_tf();
  }

  onclick_proceed_to_field_translation_and_import = () => {
    this.props.AdminImportMobx.a_import_set_import_flag("capturesAnalysisTranslationAndImportOrUpdate");
  }

  onclick_contacts_import_proceed_to_analysis_and_import = () => {
    this.props.AdminImportMobx.a_import_set_import_flag("contactsAnalysisAndImport");
  }

  onclick_clear_uploaded_csv_file = () => {
    this.props.AdminImportMobx.a_import_set_uploaded_csv_file_name(undefined);
    this.props.AdminImportMobx.a_import_set_uploaded_csv_file_data_string(undefined);
  }

  onclick_download_contacts_csv_template = () => {
    const o_importFlag = this.props.AdminImportMobx.o_importFlag;

    var importItemsPluralName = "";
    var contactCsvColumnNamesArray = [];
    if(o_importFlag === "importContactCompaniesUploadCsv") {
      importItemsPluralName = "Contact Companies";
      contactCsvColumnNamesArray = ["Legal Name", "Abbreviated Name", "Business Type", "Small Business Certification(s)", "Capabilities", "NAICS Code(s)"];
      for(let contactsCompaniesExtraFieldObj of this.props.DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs) {
        contactCsvColumnNamesArray.push(contactsCompaniesExtraFieldObj.display_name);
      }
    }
    else {
      importItemsPluralName = "Contact Persons";
      contactCsvColumnNamesArray = ["Contact Company Legal Name", "Person First Name", "Person Last Name", "Business Title", "Email", "Phone"];
      for(let contactsPersonsExtraFieldObj of this.props.DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs) {
        contactCsvColumnNamesArray.push(contactsPersonsExtraFieldObj.display_name);
      }
    }
    var fileDataString = JSFUNC.convert_array_to_comma_list(contactCsvColumnNamesArray);
    const downloadSaveAsFileNameAndExt = "Import " + importItemsPluralName + " csv Template.csv";
    JSFUNC.browser_offer_file_download_from_file_data_string(fileDataString, downloadSaveAsFileNameAndExt);
  }

  onclick_show_all_csv_rows = () => {
    this.setState({s_showAllCsvRowsTF:true});
  }

  render() {
    const s_uploadingFileTF = this.state.s_uploadingFileTF;
    const s_showAllCsvRowsTF = this.state.s_showAllCsvRowsTF;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_importFlag = this.props.AdminImportMobx.o_importFlag;
    const o_importUploadedCsvFileName = this.props.AdminImportMobx.o_importUploadedCsvFileName;
    const o_importUploadedCsvFileHasFirstHeaderRowTF = this.props.AdminImportMobx.o_importUploadedCsvFileHasFirstHeaderRowTF;
    const c_importUploadedCsvFileDataArrayOfArrays = this.props.AdminImportMobx.c_importUploadedCsvFileDataArrayOfArrays;
    const c_importCsvFileIsUploadedTF = this.props.AdminImportMobx.c_importCsvFileIsUploadedTF;
    const c_importNumCapturesToImport = this.props.AdminImportMobx.c_importNumCapturesToImport;
    const c_businessTypesArrayOfObjs = this.props.AdminMobx.c_businessTypesArrayOfObjs;
    const c_bitMasterSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs = this.props.AdminMobx.c_bitMasterSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs;
    const c_capabilitiesArrayOfObjs = this.props.AdminMobx.c_capabilitiesArrayOfObjs;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;
    const c_contactsCompaniesExtraFieldsArrayOfObjs = this.props.DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs;
    const c_contactsPersonsExtraFieldsArrayOfObjs = this.props.DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs;
    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const maxCsvRowsToInitiallyDisplay = 100;
    const uploadZoneHeightEm = 7;
    const uploadedFileFieldClass = "font11 fontItalic fontBlue";
    const uploadedFileFieldRowHeight = "1.6em";
    const bannerSvgSizeEm = 2.3;

    var importingCapturesTrueContactsFalse = false;
    var importingContactCompaniesTF = false;
    var importItemsPluralName = o_importFlag;
    var importCsvHelpComponent = null;
    var bannerBgClass = "bgLightestGray";
    var bannerSvgComponent = null;
    var bannerFontClass = "";
    var bannerText = "";
    var importUpdateActionText = "";
    var csvRowFirstColumnSingleItemName = "";
    if(o_importFlag === "importCapturesUploadCsv") { //import captures
      importingCapturesTrueContactsFalse = true;
      importItemsPluralName = "Captures";
      bannerBgClass = c_productStylingObj.openCaptureTopBarBgClass;
      bannerSvgComponent = (<CEGeneralReact.SvgCapture p_sizeEm={bannerSvgSizeEm} p_color="eee" p_plusTF={false} />);
      bannerFontClass = "font12 fontBold fontAlmostWhite";
      bannerText = "Importing New Captures";
      importUpdateActionText = "Import";
      csvRowFirstColumnSingleItemName = "Capture";
    }
    else if(o_importFlag === "updateCapturesUploadCsv") { //update captures
      importingCapturesTrueContactsFalse = true;
      importItemsPluralName = "Captures";
      bannerBgClass = "bgDarkGreen";
      bannerSvgComponent = (<CEGeneralReact.SvgCapture p_sizeEm={bannerSvgSizeEm} p_color="eee" p_plusTF={false} />);
      bannerFontClass = "font12 fontBold fontAlmostWhite";
      bannerText = "Updating Existing Captures";
      importUpdateActionText = "Update";
      csvRowFirstColumnSingleItemName = "Capture";
    }
    else if(o_importFlag === "importContactCompaniesUploadCsv") { //contact companies
      importingContactCompaniesTF = true;
      importItemsPluralName = "Contact Companies";
      bannerBgClass = "bgContactsYellow";
      bannerSvgComponent = (<CEGeneralReact.SvgCompany p_sizePx={(bannerSvgSizeEm * c_userFontSizePx)} p_color="996" />);
      bannerFontClass = "font12 fontBold fontTextLight";
      bannerText = "Importing Contact Companies";
      importUpdateActionText = "Import";
      csvRowFirstColumnSingleItemName = "Contact";
    }
    else if(o_importFlag === "importContactPersonsUploadCsv") { //contact persons
      importItemsPluralName = "Contact Persons";
      bannerBgClass = "bgContactsYellow";
      bannerSvgComponent = (<CEGeneralReact.SvgPerson p_sizePx={(bannerSvgSizeEm * c_userFontSizePx)} p_color="996" />);
      bannerFontClass = "font12 fontBold fontTextLight";
      bannerText = "Importing Contact Persons";
      importUpdateActionText = "Import";
      csvRowFirstColumnSingleItemName = "Contact";
    }

    const importUpdateActionTextLowercase = importUpdateActionText.toLowerCase();
    const rowCounterWithActionLabel = "# Rows (" + csvRowFirstColumnSingleItemName + "s) to " + importUpdateActionText;

    if(importingCapturesTrueContactsFalse) { //captures
      importCsvHelpComponent = (
        <font className="fontItalic fontTextLight">
          <font className="font11 fontBold">{"Upload a .csv file of captures to " + importUpdateActionTextLowercase + " to start the translation and " + importUpdateActionTextLowercase + " process"}</font>
          <div>{" - Each row in the file represents a single capture record to " + importUpdateActionTextLowercase}</div>
          <div>{" - You must specify whether the first row in the .csv is a header row labeling each data column"}</div>
          <div>{" - Each column in the file represents a CaptureExec " + c_cardNameDetails + " Card field that must be mapped ('Custom Assignments') or ignored, these columns can be in any order"}</div>
          <div>{" - 'Auto Assignments' can be optionally created to give all captures in this " + importUpdateActionTextLowercase + " the same constant value for CaptureExec fields not in this .csv file"}</div>
        </font>
      );
    }
    else { //contacts
      var contactCsvColumnNamesArray = [];
      if(importingContactCompaniesTF) {
        contactCsvColumnNamesArray = ["Legal Name", "Abbreviated Name", "Business Type", "Small Business Certification(s)", "Capabilities", "NAICS Code(s)"];
        for(let contactsCompaniesExtraFieldObj of c_contactsCompaniesExtraFieldsArrayOfObjs) {
          contactCsvColumnNamesArray.push(contactsCompaniesExtraFieldObj.display_name);
        }
      }
      else {
        contactCsvColumnNamesArray = ["Contact Company Legal Name", "Person First Name", "Person Last Name", "Business Title", "Email", "Phone"];
        for(let contactsPersonsExtraFieldObj of c_contactsPersonsExtraFieldsArrayOfObjs) {
          contactCsvColumnNamesArray.push(contactsPersonsExtraFieldObj.display_name);
        }
      }

      const contactTablesNamesArray = ["C - Business Type", "D - Small Business Certification(s)", "E - Capabilities"]
      const contactTablesArrayOfArrayOfObjs = [c_businessTypesArrayOfObjs, c_bitMasterSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs, c_capabilitiesArrayOfObjs];
      const contactTablesDisplayFieldNameArray = ["name" ,"bracketsShortNameName", "name"];

      importCsvHelpComponent = (
        <>
          <div className="smallBottomMargin">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text={"Download Template .csv for Importing " + importItemsPluralName}
              f_onClick={this.onclick_download_contacts_csv_template}
            />
          </div>
          <div className="displayFlexRow flexWrap">
            <div className="flex00a smallFullMargin smallFullPad border1ddd" style={{flexBasis:"48em"}}>
              <font className="fontItalic fontTextLight">
                <font className="font11 fontBold">{"Upload a .csv file of contacts to import to start the import process"}</font>
                <div>{" - Each row in the file represents a single contact to import"}</div>
                <div>{" - You must specify whether the first row in the .csv is a header row labeling each data column"}</div>
                <div>{" - The .csv file must have its data columns in the following order:"}</div>
                {contactCsvColumnNamesArray.map((m_columnName, m_index) =>
                  <div className="displayFlexRowVc">
                    <div className="flex00a" style={{flexBasis:"2em"}} />
                    <div className="flex00a" style={{flexBasis:"1.5em"}}>{JSFUNC.excel_AZAAZZ_from_int(m_index + 1)}</div>
                    <div className="flex00a" style={{flexBasis:"1em"}}>{"-"}</div>
                    <div className="flex11a">{m_columnName}</div>
                  </div>
                )}
              </font>
            </div>
            {(importingContactCompaniesTF) && contactTablesArrayOfArrayOfObjs.map((m_contactTableArrayOfObjs, m_index) =>
              <div className="flex00a smallFullMargin smallFullPad border1ddd" style={{flexBasis:"48em"}}>
                <div className="smallBottomMargin textCenter">
                  <font className="fontBold fontTextLight">
                    {contactTablesNamesArray[m_index]}
                  </font>
                </div>
                {m_contactTableArrayOfObjs.map((m_contactRowObj) =>
                  <div className="displayFlexRow">
                    <div className="flex00a" style={{flexBasis:"1.5em"}}>
                      <font className="fontBold fontTextLighter">
                        {m_contactRowObj.id}
                      </font>
                    </div>
                    <div className="flex00a" style={{flexBasis:"1em"}}>
                      <font className="fontItalic fontTextLighter">
                        {"-"}
                      </font>
                    </div>
                    <div className="flex11a">
                      <font className="fontItalic fontTextLighter">
                        {m_contactRowObj[contactTablesDisplayFieldNameArray[m_index]]}
                      </font>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      );
    }

    const importCEDropZoneComponent = (
      <CEGeneralReact.CEDropZone
        p_width="100%"
        p_heightEm={uploadZoneHeightEm}
        p_allowDropTF={true}
        p_uniqueString="adminImportCsv"
        p_fileUploadIsProcessingTF={s_uploadingFileTF}
        p_message={"Click or Drag to Upload a .csv File of " + importItemsPluralName + " to " + importUpdateActionText}
        p_dragOverMatchingItemMessage={"Drop .csv File of " + importItemsPluralName + " to " + importUpdateActionText}
        f_onDropFiles={this.upload_import_csv_file}
      />
    );

    const bannerComponent = (
      <div className={"displayFlexRow medBottomMargin border bevelBorderColors " + bannerBgClass} style={{maxWidth:"25em", height:"3.5em"}}>
        <div className="flex00a displayFlexColumnHcVc lrMedPad">
          {bannerSvgComponent}
        </div>
        <div className="flex11a displayFlexRowVc lrMedPad">
          <LibraryReact.MaxHeightWrap p_maxHeight="3em" p_fontClass={bannerFontClass}>
            {bannerText}
          </LibraryReact.MaxHeightWrap>
        </div>
      </div>
    );

    return(
      <>
        <div className="flex00a borderB1bbb smallFullPad">
          <CEGeneralReact.TabsList
            p_tabDbNamesArray={["importCapturesUploadCsv", "updateCapturesUploadCsv", "importContactCompaniesUploadCsv", "importContactPersonsUploadCsv"]}
            p_tabDisplayNamesArray={["Import New Captures", "Update Existing Captures", "Import Contact Companies", "Import Contact Persons"]}
            p_selectedTabDbName={o_importFlag}
            p_tabHeight="2.2em"
            p_textMaxHeight="2em"
            p_tabWidth="18em"
            p_unselectedFontClass="font11 fontBold fontTextLight"
            p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
            p_selectedBgClass="bgBlueGradient"
            p_selectedFontClass="fontBold fontAlmostWhite"
            f_onSelect={this.onclick_admin_import_subtab}
          />
        </div>
        {(!c_importCsvFileIsUploadedTF) ? (
          <div className="flex11a displayFlexRow">
            <div className="flex00a displayFlexColumn medFullPad" style={{flexBasis:"30em"}}>
              {importCEDropZoneComponent}
            </div>
            <div className="flex11a yScroll medTopPad hugeBottomPad lrMedPad">
              {bannerComponent}
              {importCsvHelpComponent}
            </div>
          </div>
        ) : (
          <>
            <div className="flex00a displayFlexRow">
              <div className="flex00a medFullPad" style={{flexBasis:"30em"}}>
                {importCEDropZoneComponent}
              </div>
              <div className="flex11a medFullPad">
                {bannerComponent}
                <div className="displayFlexRowVc" style={{height:uploadedFileFieldRowHeight}}>
                  <font className={uploadedFileFieldClass}>{"Uploaded .csv File: "}</font>
                  <font className="font11">{o_importUploadedCsvFileName}</font>
                </div>
                <div className="displayFlexRowVc" style={{height:uploadedFileFieldRowHeight}}>
                  <div className="flex00a">
                    <font className={uploadedFileFieldClass}>{"First Row is Header Row?: "}</font>
                  </div>
                  <div className="flex11a lrMedMargin">
                    <LibraryReact.Switch
                      p_isOnTF={o_importUploadedCsvFileHasFirstHeaderRowTF}
                      p_sizeEm={3.3}
                      f_onClick={this.onswitch_csv_has_first_header_row_tf}
                    />
                  </div>
                </div>
                <div className="displayFlexRowVc" style={{height:uploadedFileFieldRowHeight}}>
                  <font className={uploadedFileFieldClass}>{rowCounterWithActionLabel + ": "}</font>
                  <font className="font11">{c_importNumCapturesToImport}</font>
                </div>
              </div>
            </div>
            <div className="flex00a displayFlexRowVc lrMedMargin flexWrap">
              <div className="flex00a">
                {(c_importNumCapturesToImport > 0) ? (
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text={"Proceed to " + ((importingCapturesTrueContactsFalse) ? ("Translation into CaptureExec Fields") : ("Review Contacts for Import"))}
                    f_onClick={((importingCapturesTrueContactsFalse) ? (this.onclick_proceed_to_field_translation_and_import) : (this.onclick_contacts_import_proceed_to_analysis_and_import))}
                  />
                ) : (
                  <div className="">
                    <font className="fontItalic fontTextLight">
                      <div>{"There are 0 " + importItemsPluralName + " to " + importUpdateActionTextLowercase + " in this .csv file"}</div>
                      <div>{"Please upload a different file or check the contents/format of this file"}</div>
                    </font>
                  </div>
                )}
              </div>
              <div className="flex00a lrMedMargin">
                <CEGeneralReact.CEButton
                  p_type="gray"
                  p_text="Clear Uploaded File"
                  f_onClick={this.onclick_clear_uploaded_csv_file}
                />
              </div>
            </div>
            <div className="flex11a xyScroll medFullPad">
              <div className="smallBottomMargin">
                <font className="font12 fontBold fontItalic fontTextLighter">
                  {"Uploaded .csv File Data Preview Before Translation/" + importUpdateActionText}
                </font>
              </div>
              {c_importUploadedCsvFileDataArrayOfArrays.map((m_rowArray, m_index) =>
                (s_showAllCsvRowsTF || (m_index < maxCsvRowsToInitiallyDisplay)) &&
                <AdminImportCsvTableRow
                  p_rowArray={m_rowArray}
                  p_importUploadedCsvFileHasFirstHeaderRowTF={o_importUploadedCsvFileHasFirstHeaderRowTF}
                  p_rowIndex={m_index}
                  p_rowLabel={csvRowFirstColumnSingleItemName}
                />
              )}
              {((!s_showAllCsvRowsTF) && (c_importNumCapturesToImport > maxCsvRowsToInitiallyDisplay)) &&
                <div className="tbMedMargin">
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text={"Show All " + c_importNumCapturesToImport + " .csv Rows"}
                    f_onClick={this.onclick_show_all_csv_rows}
                  />
                </div>
              }
            </div>
          </>
        )}
      </>
    );
  }
}));


function AdminImportCsvTableRow(props) { //props: p_rowArray, p_importUploadedCsvFileHasFirstHeaderRowTF, p_rowIndex, p_rowLabel
  const p_rowArray = props.p_rowArray;
  const p_importUploadedCsvFileHasFirstHeaderRowTF = props.p_importUploadedCsvFileHasFirstHeaderRowTF;
  const p_rowIndex = props.p_rowIndex;
  const p_rowLabel = props.p_rowLabel;

  const isHeaderRowTF = (p_importUploadedCsvFileHasFirstHeaderRowTF && (p_rowIndex === 0));
  const numColumns = p_rowArray.length;

  const captureContactNumberColumnWidthEm = 8;
  const dataColumnWidthEm = 12;

  return(
    <div
      className={"displayFlexRowVc borderT1ddd hoverLighterBlueGradient " + ((isHeaderRowTF) ? ("bgLightBlue fontWhite") : ("bgLightestGray"))}
      style={{width:((numColumns * dataColumnWidthEm) + captureContactNumberColumnWidthEm) + "em", height:"1.6em"}}>
      <div className="flex00a lrPad" style={{flexBasis:captureContactNumberColumnWidthEm + "em"}}>
        {(!isHeaderRowTF) &&
          <LibraryReact.Nowrap p_fontClass="fontBold fontItalic fontTextLight">
            {p_rowLabel + " #" + ((p_importUploadedCsvFileHasFirstHeaderRowTF) ? (p_rowIndex) : (p_rowIndex + 1))}
          </LibraryReact.Nowrap>
        }
      </div>
      {p_rowArray.map((m_cell) =>
        <div
          className="flex00a lrPad borderL1ddd "
          style={{flexBasis:dataColumnWidthEm + "em"}}
          title={m_cell}>
          <LibraryReact.Nowrap>
            {m_cell}
          </LibraryReact.Nowrap>
        </div>
      )}
    </div>
  );
}


const AdminImportTranslateFieldsAndImport = inject("AdminImportMobx")(observer(
class AdminImportTranslateFieldsAndImport extends Component {
  onclick_import_or_update_captures = () => {
    const o_importCapturesImportTrueUpdateFalse = this.props.AdminImportMobx.o_importCapturesImportTrueUpdateFalse;

    this.props.AdminImportMobx.a_import_update_captures_success_skipped_failures_obj(undefined, undefined, undefined);

    if(o_importCapturesImportTrueUpdateFalse) { //import insert new captures
      this.props.AdminImportMobx.a_import_recursive_import_captures_using_current_settings();
    }
    else { //update existing captures
      this.props.AdminImportMobx.a_import_recursive_update_captures_using_current_settings();
    }
    
  }

  onclick_cancel_import_or_update = () => {
    const c_importAnalyzeCapturesReturnToTabDbNameWhenFinished = this.props.AdminImportMobx.c_importAnalyzeCapturesReturnToTabDbNameWhenFinished;
    this.props.AdminImportMobx.a_import_set_import_flag(c_importAnalyzeCapturesReturnToTabDbNameWhenFinished);
  }

  render() {
    const o_importCapturesImportTrueUpdateFalse = this.props.AdminImportMobx.o_importCapturesImportTrueUpdateFalse;
    const o_importUploadedCsvFileName = this.props.AdminImportMobx.o_importUploadedCsvFileName;
    const o_importLastChangesSavedTF = this.props.AdminImportMobx.o_importLastChangesSavedTF;
    const c_importNumCapturesToImport = this.props.AdminImportMobx.c_importNumCapturesToImport;
    const c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined = this.props.AdminImportMobx.c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined;
    const c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined = this.props.AdminImportMobx.c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined;
    const c_importUnfinishedColumnIndicesPreventingImportArray = this.props.AdminImportMobx.c_importUnfinishedColumnIndicesPreventingImportArray;

    const importOrUpdateText = ((o_importCapturesImportTrueUpdateFalse) ? ("Import") : ("Update"));
    const importOrUpdateTextLowercase = ((o_importCapturesImportTrueUpdateFalse) ? ("import") : ("update"));
    const importOrUpdatePresetText = ((o_importCapturesImportTrueUpdateFalse) ? ("Import Preset") : ("Update Preset"));
    const importingOrUpdatingTextLowercase = ((o_importCapturesImportTrueUpdateFalse) ? ("importing") : ("updating"));
    const numColumnsNeitherMappedNorIgnored = c_importUnfinishedColumnIndicesPreventingImportArray.length;

    var instructionsArray = [];
    instructionsArray.push("(Optional) Load an existing " + importOrUpdatePresetText);
    if(!o_importCapturesImportTrueUpdateFalse) {
      instructionsArray.push("Identify the unique key/ID column");
    }
    instructionsArray.push("Map or Ignore each .csv data column below");
    instructionsArray.push("Save this work in an " + importOrUpdatePresetText + ", then " + importOrUpdateTextLowercase);

    var importButtonComponent = null;
    if((!o_importCapturesImportTrueUpdateFalse) && (!c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedCsvColumnIsFilledOutAndMappedTF)) {
      importButtonComponent = (
        <div className="smallFullPad borderRadius05 textCenter" style={{border:"solid 1px #caa", background:"#ddd"}}>
          <font className="fontBold fontItalic fontDarkRed">
            <div>{"Unique key/ID column"}</div>
            <div>{"not yet identified"}</div>
          </font>
        </div>
      );
    }
    else if(numColumnsNeitherMappedNorIgnored > 0) {
      importButtonComponent = (
        <div className="smallFullPad borderRadius05 textCenter" style={{border:"solid 1px #caa", background:"#ebb"}}>
          <font className="fontBold fontItalic fontTextLight">
            <div>{numColumnsNeitherMappedNorIgnored + " csv " + JSFUNC.plural(numColumnsNeitherMappedNorIgnored, "column", "columns") + " not mapped/ignored,"}</div>
            <div>{"or " + JSFUNC.plural(numColumnsNeitherMappedNorIgnored, "has", "have") + " unassigned values"}</div>
          </font>
        </div>
      );
    }
    else if(!o_importLastChangesSavedTF) {
      importButtonComponent = (
        <div className="smallFullPad borderRadius05 textCenter" style={{border:"solid 1px #cca", background:"#eeb"}}>
          <font className="fontBold fontItalic fontTextLight">
            <div>{"Latest changes must be saved as an"}</div>
            <div>{importOrUpdatePresetText + " before " + importingOrUpdatingTextLowercase}</div>
          </font>
        </div>
      );
    }
    else {
      var importOrUpdateButtonType = "blue";
      var numNonSkippedCapturesToImport = c_importNumCapturesToImport;
      if(JSFUNC.is_array(c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined)) {
        numNonSkippedCapturesToImport = (c_importNumCapturesToImport - c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined.length); //subtract all captures skipped that did not map to CE captures in the Update
        if(numNonSkippedCapturesToImport <= 0) {
          importOrUpdateButtonType = "blueDisabled";
        }
      }

      importButtonComponent = (
        <CEGeneralReact.CEButton
          p_type={importOrUpdateButtonType}
          p_text={importOrUpdateText + " " + numNonSkippedCapturesToImport + " " + JSFUNC.plural(numNonSkippedCapturesToImport, "Capture", "Captures")}
          f_onClick={this.onclick_import_or_update_captures}
        />
      );
    }

    return(
      <>
        <div
          className={"flex00a displayFlexRow " + ((o_importCapturesImportTrueUpdateFalse) ? ("bgRedCapture") : ("bgDarkGreen"))}
          style={{flexBasis:"5em", borderBottom:"solid 1px #999"}}>
          <div className="flex00a displayFlexColumnVc lrPad borderR1ddd" style={{flexBasis:"25em"}}>
            {instructionsArray.map((m_instruction, m_index) =>
              <LibraryReact.Nowrap p_fontClass="fontAlmostWhite">
                {(m_index + 1) + ". " + m_instruction}
              </LibraryReact.Nowrap>
            )}
          </div>
          <div className="flex11a displayFlexColumn borderR1ddd">
            <AdminImportLoadSaveImportPresets />
          </div>
          <div className="flex00a displayFlexColumnHcVc lrMedPad">
            {importButtonComponent}
          </div>
          <div className="flex00a displayFlexColumnHcVc lrMedMargin">
            <CEGeneralReact.ButtonWithConfirmBox
              p_buttonType="gray"
              p_buttonText={"Cancel " + importOrUpdateText}
              p_confirmType="confirm"
              p_confirmMessage={"Are you sure you want to cancel this " + importOrUpdateTextLowercase + " process? Any translation work not saved as an " + importOrUpdatePresetText + " will be lost."}
              f_onClickConfirm={this.onclick_cancel_import_or_update}
            />
          </div>
        </div>
        <div className="flex11a yScroll bgDarkGray">
          <div className="displayFlexRow smallBottomMargin bgWhite">
            <div className="flex11a smallFullPad" style={{flexBasis:"100em"}}>
              <div className="">
                <font className="fontItalic fontBlue">{"Uploaded .csv File: "}</font>
                <font className="">{o_importUploadedCsvFileName}</font>
              </div>
              <div className="microTopMargin bigBottomMargin">
                <font className="fontItalic fontBlue">{"# Rows (Captures) to " + importOrUpdateText + ": "}</font>
                <font className="">{c_importNumCapturesToImport}</font>
              </div>
              {(o_importCapturesImportTrueUpdateFalse) ? (
                <AdminImportTranslateUnassignedCriticalFields />
              ) : (
                <AdminImportTranslateUpdateUniqueKeyFieldColumnIndexSelection />
              )}
            </div>
            <div className="flex11a smallFullPad" style={{flexBasis:"150em"}}>
              <AdminImportTranslateAutoAssigns />
            </div>
          </div>
          <AdminImportTranslateImportedCapturesList />
          <div style={{margin:"10em 0"}} />
        </div>
      </>
    );
  }
}));


const AdminImportLoadSaveImportPresets = inject("AdminImportMobx", "DatabaseMobx")(observer(
class AdminImportLoadSaveImportPresets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_newImportPresetSaveName: ""
    };
  }

  onclick_load_import_db_tbls_then_go_to_select_import_preset_to_load = () => {
    const o_importCapturesImportTrueUpdateFalse = this.props.AdminImportMobx.o_importCapturesImportTrueUpdateFalse;
    const o_importLastChangesSavedTF = this.props.AdminImportMobx.o_importLastChangesSavedTF;

    if(!o_importLastChangesSavedTF) { //if unsaved changes, show an error box when pushing the load preset button
      var alertMessage = "Please save your current work as an " + ((o_importCapturesImportTrueUpdateFalse) ? ("Import") : ("Update")) + " Preset before loading a different preset.";
      alertMessage += " You can click Cancel " + ((o_importCapturesImportTrueUpdateFalse) ? ("Import") : ("Update")) + " as well to start over.";
      alert(alertMessage);
    }
    else {
      this.props.AdminImportMobx.a_import_set_load_save_preset_flag("loadingDbTbls");

      const functionOnFinish = () => {
        this.props.AdminImportMobx.a_import_set_load_save_preset_flag("load");
      }
      JSPHP.load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array(this.props.DatabaseMobx.c_adminImportTblNamesToLoadArray, functionOnFinish);
    }
  }

  onclick_go_to_enter_name_to_save_import_preset = () => {
    //load the presets tbl to get all the already created preset names so that save can compare the new name to those old names to determine overwrite vs create new
    this.props.AdminImportMobx.a_import_set_load_save_preset_flag("loadingDbTbls");

    const functionOnFinish = () => {
      //if a preset was loaded, and now later trying to save, put the name of the loaded preset in as the starting text
      const selectedImportPresetName = this.props.AdminImportMobx.c_importSelectedImportPresetNamePlainTextOrUndefined;
      if(selectedImportPresetName !== undefined) {
        this.setState({s_newImportPresetSaveName:selectedImportPresetName});
      }

      this.props.AdminImportMobx.a_import_set_load_save_preset_flag("save");
    }
    JSPHP.load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array(this.props.DatabaseMobx.c_adminImportTblNamesToLoadArray, functionOnFinish);
  }

  onclick_return_to_load_save_preset_init = () => {
    this.props.AdminImportMobx.a_import_set_load_save_preset_flag("init");
  }

  onselect_import_preset_to_load = (i_newValue) => {
    this.props.AdminImportMobx.a_import_set_selected_import_preset_id(i_newValue);
  }

  onclick_import_load_preset = () => {
    this.props.AdminImportMobx.a_import_load_selected_import_preset();
    this.props.AdminImportMobx.a_import_set_load_save_preset_flag("init");
  }

  ondelete_selected_import_preset = () => {
    this.props.AdminImportMobx.a_import_delete_import_preset_from_id(this.props.AdminImportMobx.o_importSelectedImportPresetID, true);
    this.props.AdminImportMobx.a_import_set_selected_import_preset_id(-1);
  }

  onchange_new_save_preset_name = (i_newValue) => {
    this.setState({s_newImportPresetSaveName:i_newValue});
  }

  onclick_import_save_preset = () => {
    const s_newImportPresetSaveName = this.state.s_newImportPresetSaveName;
    if(JSFUNC.string_is_filled_out_tf(s_newImportPresetSaveName)) {
      this.props.AdminImportMobx.a_import_save_current_settings_as_new_or_overwrite_existing_import_preset(s_newImportPresetSaveName);
    }
    else {
      alert("New preset name cannot be blank");
    }
  }

  render() {
    const s_newImportPresetSaveName = this.state.s_newImportPresetSaveName;

    const o_importCapturesImportTrueUpdateFalse = this.props.AdminImportMobx.o_importCapturesImportTrueUpdateFalse;
    const o_importLoadSavePresetFlag = this.props.AdminImportMobx.o_importLoadSavePresetFlag;
    const o_importSelectedImportPresetID = this.props.AdminImportMobx.o_importSelectedImportPresetID;
    const o_importSavingInsertedNewPresetOrDeletedOldPresetSettingsTF = this.props.AdminImportMobx.o_importSavingInsertedNewPresetOrDeletedOldPresetSettingsTF;
    const o_importSavingNumTotalAutoAssigns = this.props.AdminImportMobx.o_importSavingNumTotalAutoAssigns;
    const o_importSavingNumCompletedAutoAssigns = this.props.AdminImportMobx.o_importSavingNumCompletedAutoAssigns;
    const o_importSavingNumTotalColumnAssigns = this.props.AdminImportMobx.o_importSavingNumTotalColumnAssigns;
    const o_importSavingNumCompletedColumnAssigns = this.props.AdminImportMobx.o_importSavingNumCompletedColumnAssigns;
    const o_importSavingNumTotalCustomAssigns = this.props.AdminImportMobx.o_importSavingNumTotalCustomAssigns;
    const o_importSavingNumCompletedCustomAssigns = this.props.AdminImportMobx.o_importSavingNumCompletedCustomAssigns;
    const c_importSelectCaptureImportOrUpdatePresetsFieldTypeObj = this.props.AdminImportMobx.c_importSelectCaptureImportOrUpdatePresetsFieldTypeObj;
    const c_importSelectedImportPresetNamePlainTextOrUndefined = this.props.AdminImportMobx.c_importSelectedImportPresetNamePlainTextOrUndefined;

    const importOrUpdatePresetText = ((o_importCapturesImportTrueUpdateFalse) ? ("Import Preset") : ("Update Preset"));
    const presetIsLoadedTF = (c_importSelectedImportPresetNamePlainTextOrUndefined !== undefined);

    if(o_importLoadSavePresetFlag === "loadingDbTbls") {
      return(
        <div className="flex11a displayFlexRowVc">
          <div className="flex00a lrMedPad">
            <CEGeneralReact.LoadingAnimation />
          </div>
          <div className="flex11a lrMedPad">
            <font className="font11 fontItalic fontTextLighter">
              {"Loading stored " + importOrUpdatePresetText + "s from database..."}
            </font>
          </div>
        </div>
      );
    }

    if(o_importLoadSavePresetFlag === "load") {
      var deleteMessage = "";
      if(presetIsLoadedTF) {
        deleteMessage = "Are you sure you want to delete " + importOrUpdatePresetText + " '" + c_importSelectedImportPresetNamePlainTextOrUndefined + "'?";
      }
      else {
        deleteMessage = "Select an " + importOrUpdatePresetText + " first to delete it";
      }

      return(
        <div className="flex11a displayFlexRow">
          <div className="flex11a displayFlexColumnVc lrMedMargin" style={{maxWidth:"30em"}}>
            <div className="microBottomMargin">
              <font className="fontAlmostWhite">
                {"Select an " + importOrUpdatePresetText + " to fill in mappings"}
              </font>
            </div>
            <div className="displayFlexRowVc">
              <div className="flex11a">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={c_importSelectCaptureImportOrUpdatePresetsFieldTypeObj}
                  p_valueRaw={o_importSelectedImportPresetID}
                  p_tabIndex={1}
                  f_onChangeOrOnSelect={this.onselect_import_preset_to_load}
                />
              </div>
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
                <div className="flex00a displayFlexColumnHcVc" style={{height:"1.1em"}}>
                  <CEGeneralReact.DeleteMenu
                    p_menuItemText="Delete Selected Preset"
                    p_message={deleteMessage}
                    p_noDeleteTF={(!presetIsLoadedTF)}
                    p_dotsFontClass="fontAlmostWhite"
                    f_onDelete={this.ondelete_selected_import_preset}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex00a displayFlexColumnHcVc lrMedMargin">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Load Preset"
              p_tabIndex={2}
              f_onClick={this.onclick_import_load_preset}
            />
          </div>
          <div className="flex00a displayFlexColumnHcVc lrMedMargin">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text="Cancel"
              p_tabIndex={3}
              f_onClick={this.onclick_return_to_load_save_preset_init}
            />
          </div>
        </div>
      );
    }

    if(JSFUNC.in_array(o_importLoadSavePresetFlag, ["save", "saving", "savingComplete", "savingError"])) {
      return(
        <div className="flex11a displayFlexRow">
          <div className="flex11a displayFlexColumnVc lrMedPad" style={{maxWidth:"30em"}}>
            <div className="microBottomMargin">
              <font className="fontAlmostWhite">
                {"Enter a name to save for this " + importOrUpdatePresetText}
              </font>
            </div>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
              p_valueRaw={s_newImportPresetSaveName}
              p_tabIndex={1}
              f_onChangeOrOnSelect={this.onchange_new_save_preset_name}
              f_onKeyDownEnter={((o_importLoadSavePresetFlag === "save") ? (this.onclick_import_save_preset) : (undefined))}
            />
          </div>
          {(o_importLoadSavePresetFlag === "save") ? (
            <>
              <div className="flex00a displayFlexColumnHcVc lrMedPad">
                <CEGeneralReact.CEButton
                  p_type="blue"
                  p_text="Save Preset"
                  p_tabIndex={2}
                  f_onClick={this.onclick_import_save_preset}
                />
              </div>
              <div className="flex00a displayFlexColumnHcVc lrMedPad">
                <CEGeneralReact.CEButton
                  p_type="gray"
                  p_text="Cancel"
                  p_tabIndex={3}
                  f_onClick={this.onclick_return_to_load_save_preset_init}
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex00a displayFlexColumnHcVc lrMedPad">
                <font className="fontItalic fontTextLighter">
                  {"Saving " + importOrUpdatePresetText + "..."}
                </font>
              </div>
              <CEGeneralReact.FloatingBoxWithSaveCancel
                p_trblFlag="smallVertical"
                p_title={"Saving Preset '" + s_newImportPresetSaveName + "'"}
                f_onClickCancel={undefined}>
                <div className="flex11a yScroll medFullPad">
                  <div className="smallBottomMargin">
                    <font className="fontItalic fontTextLight">
                      {"Writing Preset '" + s_newImportPresetSaveName + "'..."}
                    </font>
                  </div>
                  <AdminImportSavingProgressBar p_color="bde" p_completed={((o_importSavingInsertedNewPresetOrDeletedOldPresetSettingsTF) ? (1) : (0))} p_total={1} />
                  <div className="medTopMargin smallBottomMargin">
                    <font className="fontItalic fontTextLight">
                      {"Writing Constant Values..."}
                    </font>
                  </div>
                  <AdminImportSavingProgressBar p_color="bee" p_completed={o_importSavingNumCompletedAutoAssigns} p_total={o_importSavingNumTotalAutoAssigns} />
                  <div className="medTopMargin smallBottomMargin">
                    <font className="fontItalic fontTextLight">
                      {"Writing Column Assignments..."}
                    </font>
                  </div>
                  <AdminImportSavingProgressBar p_color="bed" p_completed={o_importSavingNumCompletedColumnAssigns} p_total={o_importSavingNumTotalColumnAssigns} />
                  <div className="medTopMargin smallBottomMargin">
                    <font className="fontItalic fontTextLight">
                      {"Writing Column Custom Mappings..."}
                    </font>
                  </div>
                  <AdminImportSavingProgressBar p_color="bec" p_completed={o_importSavingNumCompletedCustomAssigns} p_total={o_importSavingNumTotalCustomAssigns} />
                </div>
                <div className="flex00a displayFlexColumnHcVc tbPad">
                  {(o_importLoadSavePresetFlag === "saving") &&
                    <>
                      <div className="smallBottomMargin">
                        <font className="fontItalic fontTextLighter">
                          {"Saving " + importOrUpdatePresetText + "..."}
                        </font>
                      </div>
                      <CEGeneralReact.LoadingAnimation />
                    </>
                  }
                  {(o_importLoadSavePresetFlag === "savingComplete") &&
                    <>
                      <div className="smallBottomMargin">
                        <font className="font11 fontBold fontBlue">
                          {importOrUpdatePresetText + " saved successfully"}
                        </font>
                      </div>
                      <CEGeneralReact.CEButton
                        p_type="blue"
                        p_text="Close"
                        p_tabIndex={1}
                        f_onClick={this.onclick_return_to_load_save_preset_init}
                      />
                    </>
                  }
                  {(o_importLoadSavePresetFlag === "savingError") &&
                    <>
                      <div className="smallBottomMargin">
                        <font className="font13 fontBold fontRed">
                          {importOrUpdatePresetText + " save failed"}
                        </font>
                      </div>
                      <div className="smallBottomMargin">
                        <font className="fontBold fontDarkRed">
                          {"Please close this and try to save this Preset again to avoid losing this work"}
                        </font>
                      </div>
                      <CEGeneralReact.CEButton
                        p_type="red"
                        p_text="Close"
                        f_onClick={this.onclick_return_to_load_save_preset_init}
                      />
                    </>
                  }
                </div>
              </CEGeneralReact.FloatingBoxWithSaveCancel>
            </>
          )}
        </div>
      );
    }

    //"init" with load and save buttons
    return(
      <div className="flex11a displayFlexColumnVc">
        <div className="displayFlexRow microBottomMargin">
          <div className="flex00a lrMedMargin">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text={"Load Settings from " + importOrUpdatePresetText}
              f_onClick={this.onclick_load_import_db_tbls_then_go_to_select_import_preset_to_load}
            />
          </div>
          <div className="flex00a lrMedMargin">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text={"Save Current Work as " + importOrUpdatePresetText}
              f_onClick={this.onclick_go_to_enter_name_to_save_import_preset}
            />
          </div>
        </div>
        <div className="microTopMargin lrMedMargin">
          {(c_importSelectedImportPresetNamePlainTextOrUndefined === undefined) ? (
            <font className="fontBold fontItalic fontTextLightestest">
              {"No preset loaded"}
            </font>
          ) : (
            <>
              <font className="fontBold fontItalic fontTextLightestest">
                {"Working from preset: "}
              </font>
              <font className="fontWhite">
                {c_importSelectedImportPresetNamePlainTextOrUndefined}
              </font>
            </>
          )}
        </div>
      </div>
    );
  }
}));


function AdminImportSavingProgressBar(props) { //props: p_color, p_completed, p_total
  var percent = 100; //assume that 0/0 is 100% progress
  if(props.p_total > 0) {
    percent = (100 * (props.p_completed / props.p_total));
  }

  return(
    <LibraryReact.TwoColorDiv
      p_color2={"#" + props.p_color}
      p_color2Percent={percent}
      p_class="displayFlexColumnHcVc border1bbb borderRadius10"
      p_styleObj={{height:"2em"}}>
      <font className="fontBold fontTextLighter">
        {props.p_completed + " / " + props.p_total}
      </font>
    </LibraryReact.TwoColorDiv>
  );
}


const AdminImportTranslateUnassignedCriticalFields = inject("AdminImportMobx")(observer(
class AdminImportTranslateUnassignedCriticalFields extends Component { //props:
  render() {
    const c_importUnassignedCriticalCaptureFieldDisplayNamesArray = this.props.AdminImportMobx.c_importUnassignedCriticalCaptureFieldDisplayNamesArray;
    return(
      <div className="smallFullPad bgLighterGray border bevelBorderColors">
        <div className="textCenter">
          <font className="fontBold fontTextLighter">
            {"Critical Capture Fields Not Yet Assigned (" + c_importUnassignedCriticalCaptureFieldDisplayNamesArray.length + ")"}
          </font>
        </div>
        <div className="microTopMargin">
          <font className="fontItalic fontRed">
            {c_importUnassignedCriticalCaptureFieldDisplayNamesArray.map((m_unassignedCriticalCaptureFieldDisplayName, m_index) =>
              <>
                {(m_index > 0) &&
                  " | "
                }
                {m_unassignedCriticalCaptureFieldDisplayName}
              </>
            )}
          </font>
        </div>
      </div>
    );
  }
}));


const AdminImportTranslateUpdateUniqueKeyFieldColumnIndexSelection = inject("AdminImportMobx")(observer(
class AdminImportTranslateUpdateUniqueKeyFieldColumnIndexSelection extends Component { //props:
  onselect_update_unique_key_field_csv_column_index = (i_selectedColumnIndexOrM1) => {
    this.props.AdminImportMobx.a_import_set_update_unique_key_field_selected_csv_column_index_or_m1(i_selectedColumnIndexOrM1);
  }

  render() {
    const o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = this.props.AdminImportMobx.o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1;
    const c_importUpdateUniqueKeyFieldSelectCsvColumnFieldTypeObj = this.props.AdminImportMobx.c_importUpdateUniqueKeyFieldSelectCsvColumnFieldTypeObj;
    const c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined = this.props.AdminImportMobx.c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined;

    return(
      <div className="border bevelBorderColors" style={{background:"#ef8"}}>
        <div className="tbPad textCenter" style={{background:"linear-gradient(#dd7,#bb6)"}}>
          <font className="font11 fontBold">
            {"Identify the Unique Key/ID Column from the .csv to Map to each CaptureExec Opportunity"}
          </font>
        </div>
        <div className="medTopMargin lrMedMargin">
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_importUpdateUniqueKeyFieldSelectCsvColumnFieldTypeObj}
            p_valueRaw={o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1}
            f_onChangeOrOnSelect={this.onselect_update_unique_key_field_csv_column_index}
          />
        </div>
        {(!c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedCsvColumnIsFilledOutAndMappedTF) &&
          <div className="microTopMargin lrMedMargin">
            <font className="fontBold fontItalic fontRed">
              {c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedCsvColumnErrorMessage}
            </font>
          </div>
        }
        <div className="medTopMargin" />
      </div>
    );
  }
}));


const AdminImportTranslateAutoAssigns = inject("AdminImportMobx", "DatabaseMobx")(observer(
class AdminImportTranslateAutoAssigns extends Component { //props:
  onadd_constant_value_captureexec_field = (i_newValue) => {
    this.props.AdminImportMobx.a_import_set_current_auto_assign_captureexec_raw_value_string(i_newValue, undefined);
  }

  render() {
    const o_importCapturesImportTrueUpdateFalse = this.props.AdminImportMobx.o_importCapturesImportTrueUpdateFalse;
    const c_importConstantValuesCaptureFieldIDsAlreadyAssignedArray = this.props.AdminImportMobx.c_importConstantValuesCaptureFieldIDsAlreadyAssignedArray;
    const c_importExpandedCurrentAutoAssignsArrayOfObjs = this.props.AdminImportMobx.c_importExpandedCurrentAutoAssignsArrayOfObjs;

    return(
      <div style={{border:"solid 1px #bbb"}}>
        <div className="smallFullPad bgLightGreenGradient borderB1ddd textCenter">
          <font className="font11 fontBold">
            {"Constant Values Applied to All " + ((o_importCapturesImportTrueUpdateFalse) ? ("Imported") : ("Updated")) + " Captures"}
          </font>
        </div>
        {c_importExpandedCurrentAutoAssignsArrayOfObjs.map((m_expandedCurrentAutoAssignObj) =>
          <AdminImportTranslateAutoAssignSingleItem
            key={m_expandedCurrentAutoAssignObj.id}
            p_expandedCurrentAutoAssignObj={m_expandedCurrentAutoAssignObj}
          />
        )}
        <div className="smallFullPad bgLightGreen">
          <CEGeneralReact.CaptureExecFieldAddNewItemsButton
            p_buttonType="add"
            p_buttonText="Add Constant Value"
            p_addInstructions={"Select a CaptureExec Field to apply a constant value for all " + ((o_importCapturesImportTrueUpdateFalse) ? ("imported") : ("updated")) + " captures"}
            p_fieldTypeObj={this.props.DatabaseMobx.c_selectNoClearCaptureFieldFieldTypeObj}
            p_valuesToNotIncludeArray={c_importConstantValuesCaptureFieldIDsAlreadyAssignedArray}
            f_onAddSelectedItems={this.onadd_constant_value_captureexec_field}
          />
        </div>
      </div>
    );
  }
}));


const AdminImportTranslateAutoAssignSingleItem = inject("AdminImportMobx")(observer(
class AdminImportTranslateAutoAssignSingleItem extends Component { //props: p_expandedCurrentAutoAssignObj
  onsave_changed_auto_assign_value = (i_newValue) => {
    const p_expandedCurrentAutoAssignObj = this.props.p_expandedCurrentAutoAssignObj;
    this.props.AdminImportMobx.a_import_set_current_auto_assign_captureexec_raw_value_string(p_expandedCurrentAutoAssignObj.field_id, i_newValue);
  }

  onclick_remove_auto_assign_field = () => {
    const p_expandedCurrentAutoAssignObj = this.props.p_expandedCurrentAutoAssignObj;
    this.props.AdminImportMobx.a_import_delete_current_auto_assign_record_from_id(p_expandedCurrentAutoAssignObj.id);
  }

  render() {
    const p_expandedCurrentAutoAssignObj = this.props.p_expandedCurrentAutoAssignObj;

    return(
      <div className="displayFlexRow borderB1ddd">
        <div className="flex11a" style={{borderRight:"solid 1px #eee"}}>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"importAutoAssignID" + p_expandedCurrentAutoAssignObj.id}
            p_fieldDisplayName={p_expandedCurrentAutoAssignObj.fieldDisplayName}
            p_fieldTypeObj={p_expandedCurrentAutoAssignObj.fieldTypeObj}
            p_valueRaw={p_expandedCurrentAutoAssignObj.valueRaw}
            p_valueIsEditableTFU={true}
            p_containerClass="smallFullPad"
            p_fieldClass="fontBlue"
            p_fieldWidth="33%"
            p_fieldTitle={p_expandedCurrentAutoAssignObj.fieldDisplayName}
            p_valueClass=""
            p_fieldValueVerticallyAlignedTF={false}
            p_floatingBoxTitle={"Editing Constant Value for Capture Field '" + p_expandedCurrentAutoAssignObj.fieldDisplayName + "'"}
            f_onSaveChanged={this.onsave_changed_auto_assign_value}
          />
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove capture field '" + p_expandedCurrentAutoAssignObj.fieldDisplayName + "' as a constant value for this import process"}
            f_onClick={this.onclick_remove_auto_assign_field}
          />
        </div>
      </div>
    );
  }
}));


const AdminImportTranslateImportedCapturesList = inject("AdminImportMobx")(observer(
class AdminImportTranslateImportedCapturesList extends Component {
  onclick_visible_captures_skip_left = () => {
    this.props.AdminImportMobx.a_import_add_skip_value_to_visible_csv_captures_starting_index(-5);
  }

  onclick_visible_captures_skip_right = () => {
    this.props.AdminImportMobx.a_import_add_skip_value_to_visible_csv_captures_starting_index(5);
  }

  render() {
    const o_importVisibleCsvCapturesStartingIndex = this.props.AdminImportMobx.o_importVisibleCsvCapturesStartingIndex;
    const c_importNumCapturesToImport = this.props.AdminImportMobx.c_importNumCapturesToImport;
    const c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined = this.props.AdminImportMobx.c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined;
    const c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined = this.props.AdminImportMobx.c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined;
    const c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined = this.props.AdminImportMobx.c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined;
    const c_importVisibleCsvCaptureIndicesArray = this.props.AdminImportMobx.c_importVisibleCsvCaptureIndicesArray;
    const c_importColumnsArrayOfObjs = this.props.AdminImportMobx.c_importColumnsArrayOfObjs;
    
    const selectedCsvColumnIsFilledOutAndMappedTF = c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedCsvColumnIsFilledOutAndMappedTF;
    const selectedExpandedColumnObjOrUndefined = c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedExpandedColumnObjOrUndefined;

    const canSkipLeftTF = (o_importVisibleCsvCapturesStartingIndex > 0);
    const canSkipRightTF = ((o_importVisibleCsvCapturesStartingIndex + 5) < c_importNumCapturesToImport);
    const showCaptureMappingsHeaderBoxesTF = JSFUNC.is_array(c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined);

    var captureMappingsSummaryBoxComponent = null;
    if(selectedCsvColumnIsFilledOutAndMappedTF && JSFUNC.is_array(c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined)) {
      const numUnmappedCsvCaptures = c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined.length;

      var mappingsBoxBgColor = "";
      var mappingsBoxFontClass = "";
      var mappingsBoxMessage = "";

      var mappingsBoxTitle = "Unique Key/ID .csv Column: 'Csv Column " + selectedExpandedColumnObjOrUndefined.columnExcelLetters;
      if(JSFUNC.string_is_filled_out_tf(selectedExpandedColumnObjOrUndefined.header)) {
        mappingsBoxTitle += " - " + selectedExpandedColumnObjOrUndefined.header;
      }
      mappingsBoxTitle += "'\nUnique Key/ID CaptureExec Field: '" + selectedExpandedColumnObjOrUndefined.selectedCaptureFieldDisplayName + "'\n";

      if(numUnmappedCsvCaptures > 0) { //at least 1 .csv capture could not be matched to a Capture within CE
        mappingsBoxBgColor = "922";
        mappingsBoxFontClass = "fontWhite";
        mappingsBoxMessage = numUnmappedCsvCaptures + " .csv " + JSFUNC.plural(numUnmappedCsvCaptures, "record could not be mapped to an existing CaptureExec Capture", "records could not be mapped to existing CaptureExec Captures") + " (will be skipped)";
        mappingsBoxTitle += "The following .csv Capture #'s could not be mapped: ";
        for(let i = 0; i < numUnmappedCsvCaptures; i++) {
          if(i > 0) { mappingsBoxTitle += ", "; }
          mappingsBoxTitle += (c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined[i] + 1);
        }
      }
      else { //all Captures being updated were matched to CE captures
        mappingsBoxBgColor = "cfc";
        mappingsBoxFontClass = "fontDarkGreen";
        mappingsBoxMessage = "Mapped all .csv records to existing CaptureExec Captures";
        mappingsBoxTitle += "All .csv file records have found a matching CaptureExec Capture within the system to update with values in the .csv file";
      }

      captureMappingsSummaryBoxComponent = (
        <div className="flex00a displayFlexColumnHcVc lrMedPad">
          <div
            className="flex00a displayFlexColumnHcVc border1bbb lrPad textCenter"
            style={{width:"22em", height:"2.8em", background:"#" + mappingsBoxBgColor}}
            title={mappingsBoxTitle}>
            <font className={mappingsBoxFontClass}>
              {mappingsBoxMessage}
            </font>
          </div>
        </div>
      );
    }

    return(
      <>
        <div className="displayFlexRow bgLightBlueGradient borderT1ddd borderB1ddd" style={{height:((showCaptureMappingsHeaderBoxesTF) ? (4) : (3)) + "em"}}>
          <div className="flex00a displayFlexRowVc borderL1ddd borderR1ddd" style={{flexBasis:"50em"}}>
            <div className="flex11a" />
            {captureMappingsSummaryBoxComponent}
            <div className="flex00a lrPad">
              <LibraryReact.ButtonNowrap
                p_value="<<"
                p_class={"lrMedPad tbMicroPad border bevelBorderColors " + ((canSkipLeftTF) ? ("bgLighterGrayGradient hoverLighterBlueGradient cursorPointer") : ("bgLightestGray"))}
                p_fontClass={((canSkipLeftTF) ? ("fontBold") : ("fontItalic fontTextLightester"))}
                p_title="View previous 5 .csv Captures"
                f_onClick={((canSkipLeftTF) ? (this.onclick_visible_captures_skip_left) : (undefined))}
              />
            </div>
            <div className="flex00a lrPad">
              <LibraryReact.ButtonNowrap
                p_value=">>"
                p_class={"lrMedPad tbMicroPad border bevelBorderColors " + ((canSkipRightTF) ? ("bgLighterGrayGradient hoverLighterBlueGradient cursorPointer") : ("bgLightestGray"))}
                p_fontClass={((canSkipRightTF) ? ("fontBold") : ("fontItalic fontTextLightester"))}
                p_title="View next 5 .csv Captures"
                f_onClick={((canSkipRightTF) ? (this.onclick_visible_captures_skip_right) : (undefined))}
              />
            </div>
          </div>
          {c_importVisibleCsvCaptureIndicesArray.map((m_visibleCsvCaptureIndex) =>
            <div className={"flex11a displayFlexColumnHcVc borderR1ddd textCenter " + ((m_visibleCsvCaptureIndex < c_importNumCapturesToImport) ? ("") : ("bgDarkGrayGradient"))} style={{flexBasis:"100em"}}>
              {(m_visibleCsvCaptureIndex < c_importNumCapturesToImport) &&
                <>
                  <LibraryReact.Nowrap p_fontClass="font11 fontBold">
                    {"Capture #" + (m_visibleCsvCaptureIndex + 1)}
                  </LibraryReact.Nowrap>
                  {(showCaptureMappingsHeaderBoxesTF) &&
                    <div
                      className="flex00a displayFlexColumnHcVc microTopMargin border1bbb lrPad"
                      style={{width:"95%", height:"1.5em", background:"#" + c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined[m_visibleCsvCaptureIndex].bgColor}}
                      title={".csv File Capture #" + (m_visibleCsvCaptureIndex + 1) + "\n" + c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined[m_visibleCsvCaptureIndex].message}>
                      <LibraryReact.Nowrap p_fontClass={c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined[m_visibleCsvCaptureIndex].fontClass}>
                        {c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined[m_visibleCsvCaptureIndex].message}
                      </LibraryReact.Nowrap>
                    </div>
                  }
                </>
              }
            </div>
          )}
        </div>
        {c_importColumnsArrayOfObjs.map((m_columnObj) =>
          <AdminImportTranslateImportedSingleCaptureRow
            key={m_columnObj.index}
            p_columnObj={m_columnObj}
          />
        )}
      </>
    );
  }
}));


const AdminImportTranslateImportedSingleCaptureRow = inject("CaptureExecMobx", "AdminImportMobx")(observer(
class AdminImportTranslateImportedSingleCaptureRow extends Component { //props: p_columnObj
  onclick_import_expand_collapse_csv_column_row = () => {
    const newValue01 = ((this.props.p_columnObj.isCollapsedTF) ? (0) : (1)); //toggle the value
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, "collapsed_01", newValue01);
  }

  onclick_ignore_csv_column = () => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id", "collapsed_01"], [-1, -1, 0]);
  }

  onclick_map_csv_column_as_details_field = () => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [1, -1]);
    this.props.CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id("adminImportCsvColumn" + this.props.p_columnObj.columnNumber); //initialize the edit for selecting a capture field as open
  }

  onclick_map_csv_column_as_teammates = () => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [2, -1]);
    this.props.AdminImportMobx.a_import_attempt_predictive_fill_in_of_mapping_custom_assigns(this.props.p_columnObj.index, 2, -1); //teammates mappingTypeID 2
  }

  onclick_map_csv_column_as_competitors = () => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [3, -1]);
    this.props.AdminImportMobx.a_import_attempt_predictive_fill_in_of_mapping_custom_assigns(this.props.p_columnObj.index, 3, -1); //competitors mappingTypeID 3
  }

  onclick_map_csv_column_as_conversations = () => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [4, -1]);
  }

  onclick_map_csv_column_as_deal_shaping_select = () => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [5, -1]);
    this.props.CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id("adminImportCsvColumn" + this.props.p_columnObj.columnNumber); //initialize the edit for selecting a capture field as open
  }

  onclick_map_csv_column_as_deal_shaping_textarea = () => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [6, -1]);
    this.props.CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id("adminImportCsvColumn" + this.props.p_columnObj.columnNumber); //initialize the edit for selecting a capture field as open
  }

  onconfirm_reset_csv_column = () => {
    this.props.AdminImportMobx.a_import_delete_current_column_assign_record_and_all_mapped_custom_assign_records(this.props.p_columnObj.index);
  }

  onselect_view_other_mapping_types = () => {
    this.props.AdminImportMobx.a_import_add_column_index_to_column_indices_viewing_other_mapping_types_array(this.props.p_columnObj.index);
  }

  render() {
    const p_columnObj = this.props.p_columnObj;

    const o_importCapturesImportTrueUpdateFalse = this.props.AdminImportMobx.o_importCapturesImportTrueUpdateFalse;
    const o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = this.props.AdminImportMobx.o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1;
    const o_importColumnIndicesViewingOtherMappingTypesArray = this.props.AdminImportMobx.o_importColumnIndicesViewingOtherMappingTypesArray;
    const c_importNumCapturesToImport = this.props.AdminImportMobx.c_importNumCapturesToImport;
    const c_importUnfinishedColumnIndicesPreventingImportArray = this.props.AdminImportMobx.c_importUnfinishedColumnIndicesPreventingImportArray;

    const isSelectedUniqueKeyFieldTF = (!o_importCapturesImportTrueUpdateFalse && (p_columnObj.index === o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1));

    var rowBorderTop = undefined;
    var rowBorderBottom = "solid 1px #ddd";
    if(isSelectedUniqueKeyFieldTF) {
      rowBorderTop = "solid 3px #991";
      rowBorderBottom = "solid 3px #991";
    }
    
    var rowBgColor = "#bdf"; //cyan for error
    if(p_columnObj.isIgnoredOrMappedTF) {
      if(p_columnObj.isIgnoredTF) {
        rowBgColor = "#ccc"; //gray for row that is ignored csv column
      }
      else {
        if((p_columnObj.isMappedDetailsFieldTF || p_columnObj.isMappedDealShapingTextareaTF) && !p_columnObj.selectedCaptureFieldExistsTF) {
          rowBgColor = "#edb";
        }
        else if(p_columnObj.selectedCaptureFieldRequiresCustomAssignmentTF && JSFUNC.in_array(p_columnObj.index, c_importUnfinishedColumnIndicesPreventingImportArray)) {
          rowBgColor = "#edb";
        }
        else if(isSelectedUniqueKeyFieldTF) {
          rowBgColor = "#ef8";
        }
        else {
          rowBgColor = "#fff";
        }
      }
    }
    else {
      rowBgColor = "#ebb"; //red for row that has no type selected yet (ignored, details field mapped, etc)
    }

    //.csv column custom header from header row provided
    const columnHasHeaderTF = JSFUNC.string_is_filled_out_tf(p_columnObj.header);

    //vertical dots menu next to mapping type buttons
    var mappingTypeButtonsVerticalDotsMenuArrayOfObjs = [];
    if(o_importCapturesImportTrueUpdateFalse) { //don't allow 'Update' to choose the extra 4 options, only allow capture details field updates
      mappingTypeButtonsVerticalDotsMenuArrayOfObjs.push({
        displayName: "View Other Mapping Types",
        functionOnClickMenuItem: this.onselect_view_other_mapping_types
      });
    }
    mappingTypeButtonsVerticalDotsMenuArrayOfObjs.push({
      displayName: "Reset Csv Column",
      confirmType: "confirmDelete",
      confirmMessage: "Are you sure you want to reset this Csv Column '" + p_columnObj.header + "'?\n\nThis will allow you to choose a different mapping type or to ignore this column, however any mapping done on this column will be deleted.",
      functionOnClickConfirmButton: this.onconfirm_reset_csv_column
    });

    return(
      <div className="displayFlexRow" style={{borderTop:rowBorderTop, borderBottom:rowBorderBottom, background:rowBgColor}}>
        <div className="flex00a borderL1ddd borderR1ddd" style={{flexBasis:"50em"}}>
          <div className="displayFlexRowVc" style={{height:"2.5em"}}>
            <div className="flex00a lrPad" style={{flexBasis:"12em"}}>
              <font className="font13 fontItalic fontTextLight">
                {"Csv Column: " + p_columnObj.columnExcelLetters}
              </font>
            </div>
            <div className="flex11a lrPad" title={((columnHasHeaderTF) ? (p_columnObj.header) : (undefined))}>
              {(columnHasHeaderTF) &&
                <LibraryReact.Nowrap p_fontClass="font13 fontBold">
                  {p_columnObj.header}
                </LibraryReact.Nowrap>
              }
            </div>
            {(p_columnObj.canExpandOrCollapseTF) &&
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
                <CEGeneralReact.ButtonExpandCollapse
                  p_isExpandedTF={(!p_columnObj.isCollapsedTF)}
                  p_sizeEm={1.7}
                  p_title={(p_columnObj.isCollapsedTF) ? ("View the collapsed mappings for this Csv Column") : ("Collapse this Csv Column to hide the mappings")}
                  f_onClick={this.onclick_import_expand_collapse_csv_column_row}
                />
              </div>
            }
          </div>
          <div className="displayFlexRowVc tbPad">
            <div className="flex00a" style={{flexBasis:"40em"}}>
              <div className="displayFlexRow" style={{height:"1.8em"}}>
                <ImportCsvColumnMappingTypeButton p_label="Ignore This Column" p_selectedBgClass="bgDarkGrayGradient" p_selectedTF={p_columnObj.isIgnoredTF} p_anyMappingTypeSelectedTF={p_columnObj.isIgnoredOrMappedTF} f_onClick={this.onclick_ignore_csv_column} />
                <ImportCsvColumnMappingTypeButton p_label="Map to CaptureExec Details Field" p_selectedBgClass="bgDarkBlueGradient" p_selectedTF={p_columnObj.isMappedDetailsFieldTF} p_anyMappingTypeSelectedTF={p_columnObj.isIgnoredOrMappedTF} f_onClick={this.onclick_map_csv_column_as_details_field} />
              </div>
              {(JSFUNC.in_array(p_columnObj.index, o_importColumnIndicesViewingOtherMappingTypesArray) || (p_columnObj.isMappedTeammatesTF || p_columnObj.isMappedCompetitorsTF || p_columnObj.isMappedConversationsTF || p_columnObj.isMappedDealShapingSelectTF || p_columnObj.isMappedDealShapingTextareaTF)) &&
                <>
                  <div className="displayFlexRow smallTopMargin" style={{height:"1.5em"}}>
                    <ImportCsvColumnMappingTypeButton p_label="Teammates" p_selectedBgClass="bgDarkGreenGradient" p_selectedTF={p_columnObj.isMappedTeammatesTF} p_anyMappingTypeSelectedTF={p_columnObj.isIgnoredOrMappedTF} f_onClick={this.onclick_map_csv_column_as_teammates} />
                    <ImportCsvColumnMappingTypeButton p_label="Competitors" p_selectedBgClass="bgDarkGreenGradient" p_selectedTF={p_columnObj.isMappedCompetitorsTF} p_anyMappingTypeSelectedTF={p_columnObj.isIgnoredOrMappedTF} f_onClick={this.onclick_map_csv_column_as_competitors} />
                  </div>
                  <div className="displayFlexRow smallTopMargin" style={{height:"1.5em"}}>
                    <ImportCsvColumnMappingTypeButton p_label="Deal Shaping Question (Select)" p_selectedBgClass="bgDarkGreenGradient" p_selectedTF={p_columnObj.isMappedDealShapingSelectTF} p_anyMappingTypeSelectedTF={p_columnObj.isIgnoredOrMappedTF} f_onClick={this.onclick_map_csv_column_as_deal_shaping_select} />
                    <ImportCsvColumnMappingTypeButton p_label="Deal Shaping Question (Textarea)" p_selectedBgClass="bgDarkGreenGradient" p_selectedTF={p_columnObj.isMappedDealShapingTextareaTF} p_anyMappingTypeSelectedTF={p_columnObj.isIgnoredOrMappedTF} f_onClick={this.onclick_map_csv_column_as_deal_shaping_textarea} />
                  </div>
                </>
              }
            </div>
            <div className="flex11a displayFlexColumnVc" style={{height:"1.8em"}}>
              <CEGeneralReact.VerticalDotsMenu
                p_menuItemsArrayOfObjs={mappingTypeButtonsVerticalDotsMenuArrayOfObjs}
              />
            </div>
          </div>
          {(p_columnObj.isIgnoredOrMappedTF && !p_columnObj.isIgnoredTF) &&
            <>
              {(p_columnObj.isCollapsedTF) ? (
                <div className="smallFullMargin tbMicroPad bgLighterGray textCenter">
                  <font className="fontItalic fontTextLight">
                    {"This Csv Column's mappings are collapsed"}
                  </font>
                </div>
              ) : (
                <div className="smallTopMargin smallFullPad">
                  {(p_columnObj.isMappedDetailsFieldTF) &&
                    <ImportSingleCsvColumnTypeDetailsField p_columnObj={p_columnObj} />
                  }
                  {(p_columnObj.isMappedTeammatesTF) &&
                    <ImportSingleCsvColumnMappingUniqueValuesTableFromColumnObj
                      p_columnObj={p_columnObj}
                      p_tableTitle={"Importing a list of Teammate names requires manual assignment of Contact Companies"}
                    />
                  }
                  {(p_columnObj.isMappedCompetitorsTF) &&
                    <ImportSingleCsvColumnMappingUniqueValuesTableFromColumnObj
                      p_columnObj={p_columnObj}
                      p_tableTitle={"Importing a list of Competitor names requires manual assignment of Contact Companies"}
                    />
                  }
                  {(p_columnObj.isMappedDealShapingSelectTF) &&
                    <ImportSingleCsvColumnTypeDealShapingSelect p_columnObj={p_columnObj} />
                  }
                  {(p_columnObj.isMappedDealShapingTextareaTF) &&
                    <ImportSingleCsvColumnTypeDealShapingTextarea p_columnObj={p_columnObj} />
                  }
                </div>
              )}
            </>
          }
        </div>
        {p_columnObj.visibleCsvCaptureRawValuesArray.map((m_visibleCsvCaptureRawValue, m_index) =>
          <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
            {(p_columnObj.visibleCsvCaptureIndicesArray[m_index] < c_importNumCapturesToImport) ? (
              <AdminImportTranslateCsvColumnSingleCaptureCell
                key={"c" + p_columnObj.index + "r" + p_columnObj.visibleCsvCaptureIndicesArray[m_index]}
                p_csvRowIndexAfterHeader={p_columnObj.visibleCsvCaptureIndicesArray[m_index]}
                p_columnObj={p_columnObj}
                p_visibleCsvCaptureRawValue={m_visibleCsvCaptureRawValue}
              />
            ) : (
              <div className="flex11a bgGray borderR1ddd" />
            )}
          </div>
        )}
      </div>
    );
  }
}));


function ImportCsvColumnMappingTypeButton(props) { //props: p_label, p_selectedBgClass, p_selectedTF, p_anyMappingTypeSelectedTF, f_onClick
  const label = props.p_label;
  const selectedBgClass = props.p_selectedBgClass;
  const selectedTF = props.p_selectedTF;
  const anyMappingTypeSelectedTF = props.p_anyMappingTypeSelectedTF;

  const bgClass = ((selectedTF) ? (selectedBgClass) : ("bgLightGrayGradient"));
  const hoverClass = ((anyMappingTypeSelectedTF) ? ("cursorNotAllowed") : ("hoverLightestGrayGradient cursorPointer"));

  var columnMappingTypeButtonTitle = "";
  if(anyMappingTypeSelectedTF) {
    columnMappingTypeButtonTitle = "A mapping type is already selected for this Csv Column.\nClick the vertical dots and select 'Reset Csv Column' to reset and select a different mapping type.";
  }
  else {
    columnMappingTypeButtonTitle = "Click to select '" + label + "' as the mapping type for this Csv Column.\nClick the vertical dots and select 'View Other Mapping Types' to show the other mapping types: 'Teammates', 'Competitors', 'Deal Shaping Question (Select)', 'Deal Shaping Question (Textarea)'.";
  }

  return(
    <div
      className={"flex11a displayFlexColumnHcVc lrMargin lrMedPad border bevelBorderColors borderRadius05 textCenter " + bgClass + " " + hoverClass}
      style={{flexBasis:"100em"}}
      title={columnMappingTypeButtonTitle}
      onClick={((anyMappingTypeSelectedTF) ? (undefined) : (props.f_onClick))}>
      <font className={((selectedTF) ? ("fontWhite") : (""))}>
        {label}
      </font>
    </div>
  );
}


const ImportSingleCsvColumnTypeDetailsField = inject("AdminImportMobx", "DatabaseMobx")(observer(
class ImportSingleCsvColumnTypeDetailsField extends Component { //props: p_columnObj
  onselect_column_mapped_capture_field = (i_newValue) => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [1, i_newValue]);
    this.props.AdminImportMobx.a_import_attempt_predictive_fill_in_of_mapping_custom_assigns(this.props.p_columnObj.index, 1, i_newValue); //details field mappingTypeID 1
  }

  onswitch_multiply_percent_csv_value_by_100 = () => {
    const newValue01 = ((this.props.p_columnObj.percentFieldMultiplyBy100TF) ? (0) : (1)); //toggle the switch value
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, "percent_field_multiply_100_01", newValue01);
  }

  render() {
    const p_columnObj = this.props.p_columnObj;

    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;

    return(
      <>
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={"adminImportCsvColumn" + p_columnObj.columnNumber}
          p_fieldDisplayName={"Mapped CaptureExec " + c_cardNameDetails + " Card Field"}
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectNoClearCaptureFieldFieldTypeObj}
          p_valueRaw={p_columnObj.captureFieldID}
          p_valueIsEditableTFU={true}
          p_valuesToNotIncludeArray={p_columnObj.captureFieldIDsAlreadyAssignedToOtherColumnsArray}
          p_containerClass="smallFullPad bgLighterGrayGradient"
          p_fieldClass="fontItalic fontDarkBlue"
          p_fieldWidth="11em"
          p_fieldTitle={"Select the CaptureExec Field that data from this import csv column '" + p_columnObj.header + "' will be translated from"}
          p_valueClass="font13 fontBold fontBlue"
          f_onSaveChanged={this.onselect_column_mapped_capture_field}
        />
        {(p_columnObj.selectedCaptureFieldRequiresCustomAssignmentTF) &&
          <ImportSingleCsvColumnMappingUniqueValuesTableFromColumnObj
            p_columnObj={p_columnObj}
            p_tableTitle={"Capture Field '" + p_columnObj.selectedCaptureFieldDisplayName + "' requires manual assignment of values"}
          />
        }
        {(p_columnObj.selectedCaptureFieldIsPercentTF || p_columnObj.selectedCaptureFieldIsMoneyTF) &&
          <div className="displayFlexRowVc tbMargin">
            <div className="flex00a">
              <font className="fontItalic fontTextLighter">
                {((p_columnObj.selectedCaptureFieldIsMoneyTF) ? ("Multiply dollars from .csv by 1000 for import?") : ("Multiply percents from .csv by 100 for import?"))}
              </font>
            </div>
            <div className="flex11a lrMedPad">
              <LibraryReact.Switch
                p_isOnTF={p_columnObj.percentFieldMultiplyBy100TF}
                f_onClick={this.onswitch_multiply_percent_csv_value_by_100}
              />
            </div>
          </div>
        }
      </>
    );
  }
}));


function ImportSingleCsvColumnMappingUniqueValuesTableFromColumnObj(props) { //props: p_columnObj, p_tableTitle
  const p_columnObj = props.p_columnObj;
  const tableTitle = props.p_tableTitle;

  return(
    <>
      <div className="tbPad lrPad bgWhite">
        <font className="fontBold fontItalic fontTextLighter">
          {tableTitle}
        </font>
      </div>
      <div className="medBottomMargin bgWhite">
        <div className="displayFlexRow borderT1ddd borderB1ddd">
          <div className="flex11a displayFlexRowVc lrPad borderL1ddd borderR1ddd" style={{flexBasis:"100em"}}>
            <LibraryReact.Nowrap p_fontClass="fontBold fontItalic fontTextLight">
              {".csv Value"}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a displayFlexRowVc lrPad borderR1ddd" style={{flexBasis:"3em"}}>
            <font className="fontBold fontItalic fontTextLight">
              {"#"}
            </font>
          </div>
          <div className="flex11a displayFlexRowVc lrPad tbMicroPad borderR1ddd" style={{flexBasis:"200em"}}>
            <LibraryReact.Nowrap p_fontClass="fontBold fontItalic fontTextLight">
              {"Mapped CaptureExec Value"}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a borderR1ddd" style={{flexBasis:"1.5em"}} />
        </div>
        {p_columnObj.csvUniqueValuesAndCustomAssignsArrayOfObjs.map((m_csvUniqueValueAndCustomAssignObj) =>
          <AdminImportTranslateCsvUniqueValueAndCustomAssignSingleValue
            p_columnObj={p_columnObj}
            p_csvUniqueValueAndCustomAssignObj={m_csvUniqueValueAndCustomAssignObj}
          />
        )}
      </div>
    </>
  );
}


const AdminImportTranslateCsvUniqueValueAndCustomAssignSingleValue = inject("AdminImportMobx", "DatabaseMobx")(observer(
class AdminImportTranslateCsvUniqueValueAndCustomAssignSingleValue extends Component { //props: p_columnObj, p_csvUniqueValueAndCustomAssignObj
  onselect_custom_assign_single_mapped_captureexec_value = (i_newValue) => {
    const p_columnObj = this.props.p_columnObj;
    const csvUniqueValueAndCustomAssignObj = this.props.p_csvUniqueValueAndCustomAssignObj;
    const newIntentionallyUnassigned01 = 0; //always set intentionally_unassigned_01 back to 0 in case it was set as intentional, but then the value was changed back
    this.props.AdminImportMobx.a_import_set_current_custom_assign_captureexec_raw_value_string(p_columnObj.index, csvUniqueValueAndCustomAssignObj.value, i_newValue, newIntentionallyUnassigned01);
  }

  onselect_custom_assign_mark_as_intentionally_unassigned = () => {
    const p_columnObj = this.props.p_columnObj;
    const csvUniqueValueAndCustomAssignObj = this.props.p_csvUniqueValueAndCustomAssignObj;

    var newMappedCaptureExecValueRaw = -1; //default unassigned value for custom assigns not yet made
    if(p_columnObj.selectedCaptureFieldFieldTypeObj !== undefined) { //this should always exist, so the blank value of this fieldTypeObj can be used
      newMappedCaptureExecValueRaw = p_columnObj.selectedCaptureFieldFieldTypeObj.blankValue; //default unassigned value for custom assigns (for multiselects and sharedpercents)
    }

    const newIntentionallyUnassigned01 = 1; //mark as intentionally unassigned

    this.props.AdminImportMobx.a_import_set_current_custom_assign_captureexec_raw_value_string(p_columnObj.index, csvUniqueValueAndCustomAssignObj.value, newMappedCaptureExecValueRaw, newIntentionallyUnassigned01);
  }

  onselect_custom_assign_clear_this_assignment = () => {
    this.props.AdminImportMobx.a_import_delete_current_custom_assign_record_from_current_custom_assign_id(this.props.p_csvUniqueValueAndCustomAssignObj.currentCustomAssignRecordID);
  }

  render() {
    const p_columnObj = this.props.p_columnObj;
    const csvUniqueValueAndCustomAssignObj = this.props.p_csvUniqueValueAndCustomAssignObj;

    var rowBgColor = undefined; //white - filled out or intentionally unassigned
    if(!csvUniqueValueAndCustomAssignObj.mappedCaptureExecValueIsFilledOutTF && !csvUniqueValueAndCustomAssignObj.intentionallyUnassignedTF) {
      rowBgColor = "#fb7"; //orange - custom assign row is not yet filled out
    }

    return(
      <div className="displayFlexRow borderB1ddd" style={{background:rowBgColor}}>
        <div className="flex11a displayFlexRowVc lrPad borderL1ddd borderR1ddd" style={{flexBasis:"100em"}} title={csvUniqueValueAndCustomAssignObj.value}>
          <LibraryReact.Nowrap>
            {csvUniqueValueAndCustomAssignObj.value}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex00a displayFlexRowVc lrPad borderR1ddd" style={{flexBasis:"3em"}}>
          {csvUniqueValueAndCustomAssignObj.count}
        </div>
        <div className="flex11a displayFlexColumnVc lrPad tbMicroPad borderR1ddd" style={{flexBasis:"200em"}}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={p_columnObj.selectedCaptureFieldFieldTypeObj}
            p_valueRaw={csvUniqueValueAndCustomAssignObj.mappedCaptureExecValueRaw}
            f_onChangeOrOnSelect={this.onselect_custom_assign_single_mapped_captureexec_value}
          />
        </div>
        <div className="flex00a displayFlexColumnHcVc borderR1ddd" style={{flexBasis:"1.5em"}}>
          <div className="flex00a displayFlexColumnHcVc" style={{height:"1.1em"}}>
            <CEGeneralReact.VerticalDotsMenu
              p_menuItemsArrayOfObjs={[
                {displayName:"Mark as Intentionally Unassigned", functionOnClickMenuItem:this.onselect_custom_assign_mark_as_intentionally_unassigned},
                {displayName:"Clear This Assignment", functionOnClickMenuItem:this.onselect_custom_assign_clear_this_assignment}
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}));




const ImportSingleCsvColumnTypeDealShapingSelect = inject("AdminImportMobx", "DatabaseMobx")(observer(
class ImportSingleCsvColumnTypeDealShapingSelect extends Component { //props: p_columnObj
  onselect_column_mapped_deal_shaping_question_select = (i_newValue) => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [5, i_newValue]);
  }

  render() {
    const p_columnObj = this.props.p_columnObj;

    return(
      <>
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={"adminImportCsvColumn" + p_columnObj.columnNumber}
          p_fieldDisplayName="Mapped CaptureExec Deal Shaping Question (Select type)"
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectDealShapingQuestionsSelectFieldTypeObj}
          p_valueRaw={p_columnObj.captureFieldID}
          p_valueIsEditableTFU={true}
          p_valuesToNotIncludeArray={p_columnObj.dealShapingQuestionIDsAlreadyAssignedToOtherColumnsArray}
          p_containerClass="smallFullPad bgLighterGrayGradient"
          p_fieldClass="fontItalic fontDarkBlue"
          p_fieldWidth="11em"
          p_fieldTitle={"Select the CaptureExec Deal Shaping Question (Select type) that data from this import csv column '" + p_columnObj.header + "' will be translated from"}
          p_valueClass="font13 fontBold fontBlue"
          f_onSaveChanged={this.onselect_column_mapped_deal_shaping_question_select}
        />
        {(p_columnObj.selectedCaptureFieldExistsTF) &&
          <ImportSingleCsvColumnMappingUniqueValuesTableFromColumnObj
            p_columnObj={p_columnObj}
            p_tableTitle={"Deal Shaping Question (Select) requires manual assignment of answers"}
          />
        }
      </>
    );
  }
}));




const ImportSingleCsvColumnTypeDealShapingTextarea = inject("AdminImportMobx", "DatabaseMobx")(observer(
class ImportSingleCsvColumnTypeDealShapingTextarea extends Component { //props: p_columnObj
  onselect_column_mapped_deal_shaping_question_textarea = (i_newValue) => {
    this.props.AdminImportMobx.a_import_set_current_column_assign_field_from_column_index(this.props.p_columnObj.index, ["mapping_type_id", "field_id"], [6, i_newValue]);
  }

  render() {
    const p_columnObj = this.props.p_columnObj;

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={"adminImportCsvColumn" + p_columnObj.columnNumber}
        p_fieldDisplayName="Mapped CaptureExec Deal Shaping Question (Textarea type)"
        p_fieldTypeObj={this.props.DatabaseMobx.c_selectDealShapingQuestionsTextareaFieldTypeObj}
        p_valueRaw={p_columnObj.captureFieldID}
        p_valueIsEditableTFU={true}
        p_valuesToNotIncludeArray={p_columnObj.dealShapingQuestionIDsAlreadyAssignedToOtherColumnsArray}
        p_containerClass="smallFullPad bgLighterGrayGradient"
        p_fieldClass="fontItalic fontDarkBlue"
        p_fieldWidth="11em"
        p_fieldTitle={"Select the CaptureExec Deal Shaping Question (Textarea type) that data from this import csv column '" + p_columnObj.header + "' will be translated from"}
        p_valueClass="font13 fontBold fontBlue"
        f_onSaveChanged={this.onselect_column_mapped_deal_shaping_question_textarea}
      />
    );
  }
}));



const AdminImportTranslateCsvColumnSingleCaptureCell = inject("AdminImportMobx", "DatabaseMobx")(observer(
class AdminImportTranslateCsvColumnSingleCaptureCell extends Component { //props: p_csvRowIndexAfterHeader, p_columnObj, p_visibleCsvCaptureRawValue
  constructor(props) {
    super(props);
    this.state = {
      s_editingCellTF: false,
      s_localVisibleCsvCaptureRawValue: ""
    };
  }

  onclick_open_edit_single_capture_cell = () => {
    const p_visibleCsvCaptureRawValue = this.props.p_visibleCsvCaptureRawValue;
    this.setState({
      s_editingCellTF: true,
      s_localVisibleCsvCaptureRawValue: p_visibleCsvCaptureRawValue
    });
  }

  onchange_local_visible_csv_capture_raw_value = (i_newValueString) => {
    this.setState({s_localVisibleCsvCaptureRawValue:i_newValueString});
  }

  onclick_save_single_capture_cell = () => {
    const s_localVisibleCsvCaptureRawValue = this.state.s_localVisibleCsvCaptureRawValue;

    const p_csvRowIndexAfterHeader = this.props.p_csvRowIndexAfterHeader;
    const p_columnObj = this.props.p_columnObj;
    const p_visibleCsvCaptureRawValue = this.props.p_visibleCsvCaptureRawValue;

    this.props.AdminImportMobx.a_import_update_single_csv_cell_in_csv_file_data_string(p_csvRowIndexAfterHeader, p_columnObj.index, s_localVisibleCsvCaptureRawValue, p_visibleCsvCaptureRawValue);
    this.onclick_close_edit_single_capture_cell();
  }

  onclick_close_edit_single_capture_cell = () => {
    this.setState({s_editingCellTF:false});
  }

  render() {
    const s_editingCellTF = this.state.s_editingCellTF;
    const s_localVisibleCsvCaptureRawValue = this.state.s_localVisibleCsvCaptureRawValue;

    const p_csvRowIndexAfterHeader = this.props.p_csvRowIndexAfterHeader;
    const p_columnObj = this.props.p_columnObj;
    const p_visibleCsvCaptureRawValue = this.props.p_visibleCsvCaptureRawValue;

    const fieldTypeObj = p_columnObj.selectedCaptureFieldFieldTypeObj;

    var belowLineSampleComponent = null;
    if(!s_editingCellTF) {
      if(p_columnObj.isMappedDetailsFieldTF && (fieldTypeObj !== undefined)) {
        if(fieldTypeObj.valueDisplayIsNumericTF || fieldTypeObj.valueDisplayIsDateOrDateTimeTF) {
          const convertedValueRaw = this.props.AdminImportMobx.import_convert_date_number_money_percent_type_csv_value_raw_string_into_captureexec_value_raw(p_visibleCsvCaptureRawValue, fieldTypeObj, p_columnObj.percentFieldMultiplyBy100TF);
          const convertedValueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(convertedValueRaw, fieldTypeObj);

          belowLineSampleComponent = (
            <div>
              <font className="fontBold fontItalic fontTextLight">
                {convertedValueMaskSortIfoObj.valueMaskNoClickLinks}
              </font>
            </div>
          );
        }
      }
    }

    return(
      <div className="flex11a displayFlexRow borderR1ddd">
        <div className="flex11a smallFullPad" title={p_visibleCsvCaptureRawValue}>
          {(s_editingCellTF) ? (
            <LibraryReact.Textarea
              p_value={s_localVisibleCsvCaptureRawValue}
              p_styleObj={{width:"100%", height:"100%"}}
              p_tabIndex={1}
              f_onChange={this.onchange_local_visible_csv_capture_raw_value}
            />
          ) : (
            <>
              <LibraryReact.MaxHeightWrap p_maxHeight="3.3em">
                {p_visibleCsvCaptureRawValue}
              </LibraryReact.MaxHeightWrap>
              {belowLineSampleComponent}
            </>
          )}
        </div>
        <div className="flex00a displayFlexColumnHc" style={{flexBasis:"2em"}}>
          {(s_editingCellTF) ? (
            <>
              <CEGeneralReact.EditSaveCancelIcon
                p_iconType="save"
                f_onClick={this.onclick_save_single_capture_cell}
              />
              <CEGeneralReact.EditSaveCancelIcon
                p_iconType="cancel"
                f_onClick={this.onclick_close_edit_single_capture_cell}
              />
            </>
          ) : (
            <CEGeneralReact.EditSaveCancelIcon
              p_iconType="edit"
              p_title="Edit the value from the .csv without having to reupload the file"
              f_onClick={this.onclick_open_edit_single_capture_cell}
            />
          )}
        </div>
      </div>
    );
  }
}));


const AdminImportDatabaseProgressFloatingBoxes = inject("AdminImportMobx")(observer(
class AdminImportDatabaseProgressFloatingBoxes extends Component {
  onclick_cancel_import = () => {
    this.props.AdminImportMobx.a_import_set_database_progress_manual_cancel_recursion_flag_tf(true);
  }

  onclick_ok_import_finished = () => {
    const c_importAnalyzeCapturesReturnToTabDbNameWhenFinished = this.props.AdminImportMobx.c_importAnalyzeCapturesReturnToTabDbNameWhenFinished;
    this.props.AdminImportMobx.a_import_set_import_flag(c_importAnalyzeCapturesReturnToTabDbNameWhenFinished);
  }

  render() {
    const o_importFlag = this.props.AdminImportMobx.o_importFlag;
    const o_importCapturesImportTrueUpdateFalse = this.props.AdminImportMobx.o_importCapturesImportTrueUpdateFalse;
    const o_importDatabaseProgressCurrentCaptureIndexBeingImported = this.props.AdminImportMobx.o_importDatabaseProgressCurrentCaptureIndexBeingImported;
    const c_importNumCapturesToImport = this.props.AdminImportMobx.c_importNumCapturesToImport;
    const c_importProcessCurrentlyInProgressTF = this.props.AdminImportMobx.c_importProcessCurrentlyInProgressTF;
    const c_importProcessIsFinishedTF = this.props.AdminImportMobx.c_importProcessIsFinishedTF;

    if(!c_importProcessCurrentlyInProgressTF && !c_importProcessIsFinishedTF) {
      return(null);
    }

    const isContactsTF = (o_importFlag === "contactsAnalysisAndImport");
    const importOrUpdateText = ((o_importCapturesImportTrueUpdateFalse) ? ("Import") : ("Update"));
    const importingOrUpdatingText = ((o_importCapturesImportTrueUpdateFalse) ? ("Importing") : ("Updating"));

    var itemName = JSFUNC.plural(c_importNumCapturesToImport, "Capture", "Captures");
    if(isContactsTF) {
      itemName = JSFUNC.plural(c_importNumCapturesToImport, "Contact", "Contacts");
    }

    //in progress
    if(c_importProcessCurrentlyInProgressTF) {
      return(
        <CEGeneralReact.ConfirmBox
          p_type="loading"
          p_title={importingOrUpdatingText + " " + c_importNumCapturesToImport + " " + itemName}
          f_onClickCancel={undefined}>
          <div className="medFullPad">
            <AdminImportSavingProgressBar
              p_color="bec"
              p_completed={o_importDatabaseProgressCurrentCaptureIndexBeingImported}
              p_total={c_importNumCapturesToImport}
            />
          </div>
          <div className="smallTopMargin textCenter">
            {importingOrUpdatingText + " " + itemName}
          </div>
          <div className="displayFlexRow medTopMargin smallBottomMargin borderT1bbb borderB1bbb tbPad">
            <div className="flex11a" />
            <div className="flex00a lrMedPad">
              <CEGeneralReact.CEButton
                p_type="gray"
                p_text={"Cancel " + importOrUpdateText}
                f_onClick={this.onclick_cancel_import}
              />
            </div>
          </div>
        </CEGeneralReact.ConfirmBox>
      );
    }

    var finishedText = "";
    if(!isContactsTF) { //captures
      finishedText = this.props.AdminImportMobx.generate_success_skipped_failures_text_lines(1);
    }
    else { //contacts
      finishedText = "Completed " + importOrUpdateText + " of " + c_importNumCapturesToImport + " " + itemName;
    }

    //finished
    return(
      <CEGeneralReact.ConfirmBox
        p_type="ok"
        p_title={"Completed " + importOrUpdateText}
        f_onClickConfirm={this.onclick_ok_import_finished}>
        {finishedText}
      </CEGeneralReact.ConfirmBox>
    );
  }
}));






const AdminImportContactsAnalysisAndImport = inject("AdminImportMobx", "DatabaseMobx")(observer(
class AdminImportContactsAnalysisAndImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_contactsTablePageNumber: 1
    };
  }

  onclick_increment_page = () => {
    this.setState({s_contactsTablePageNumber:(this.state.s_contactsTablePageNumber + 1)})
  }

  onclick_decrement_page = () => {
    this.setState({s_contactsTablePageNumber:(this.state.s_contactsTablePageNumber - 1)})
  }

  onclick_import_contacts = () => {
    this.props.AdminImportMobx.a_import_recursive_import_contacts();
  }

  onclick_cancel_contact_import = () => {
    const o_importContactsIsPersonTF = this.props.AdminImportMobx.o_importContactsIsPersonTF;
    const newImportFlag = ((o_importContactsIsPersonTF) ? ("importContactPersonsUploadCsv") : ("importContactCompaniesUploadCsv"));
    this.props.AdminImportMobx.a_import_set_import_flag(newImportFlag);
  }

  render() {
    const s_contactsTablePageNumber = this.state.s_contactsTablePageNumber;

    const c_importProcessCurrentlyInProgressTF = this.props.AdminImportMobx.c_importProcessCurrentlyInProgressTF;
    const c_importProcessIsFinishedTF = this.props.AdminImportMobx.c_importProcessIsFinishedTF;
    if(c_importProcessCurrentlyInProgressTF || c_importProcessIsFinishedTF) {
      return(null);
    }

    const o_importContactsIsPersonTF = this.props.AdminImportMobx.o_importContactsIsPersonTF;
    const c_importContactsConvertedDataArrayOfObjs = this.props.AdminImportMobx.c_importContactsConvertedDataArrayOfObjs;
    const c_importNumContactsNoDuplicatesToImport = this.props.AdminImportMobx.c_importNumContactsNoDuplicatesToImport;
    const c_importContactsAtLeast1NonDuplicateRowHasInvalidDataCellTF = this.props.AdminImportMobx.c_importContactsAtLeast1NonDuplicateRowHasInvalidDataCellTF;

    const companyOrPerson = ((o_importContactsIsPersonTF) ? ("Person") : ("Company"));
    const companiesOrPersons = ((o_importContactsIsPersonTF) ? ("Persons") : ("Companies"));
    const numContactsToImport = c_importContactsConvertedDataArrayOfObjs.length;

    //paging of contacts in table
    const numContactsPerPage = 100;
    const firstContactNumber = (((s_contactsTablePageNumber - 1) * numContactsPerPage) + 1);
    var lastContactNumber = (s_contactsTablePageNumber * numContactsPerPage);
    if(numContactsToImport < lastContactNumber) {
      lastContactNumber = numContactsToImport;
    }
    const canIncrementPageTF = (lastContactNumber < numContactsToImport);
    const canDecrementPageTF = (s_contactsTablePageNumber > 1);

    var headerNamesArray = [];
    var rawFieldDbNamesArray = [];
    var maskFieldDbNamesArray = [];
    var maskPlainTextFieldNamesArray = [];
    if(o_importContactsIsPersonTF) {
      headerNamesArray = ["Parent Company/Division", "First Name", "Last Name", "Title", "Email", "Phone"];
      rawFieldDbNamesArray = ["parentCompanyAbbrOrLegalName", "first_name", "last_name", "title", "email", "phone"];
      maskFieldDbNamesArray = ["parentCompanyAbbrOrLegalName", "first_name", "last_name", "title", "email", "phone"];
      for(let contactsPersonsExtraFieldObj of this.props.DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs) {
        headerNamesArray.push(contactsPersonsExtraFieldObj.display_name);
        rawFieldDbNamesArray.push(contactsPersonsExtraFieldObj.db_name);
        maskFieldDbNamesArray.push(contactsPersonsExtraFieldObj.db_name);
      }
      maskPlainTextFieldNamesArray = maskFieldDbNamesArray;
    }
    else {
      headerNamesArray = ["Legal Name", "Abbreviated Name", "Business Type", "Small Business Certification(s)", "Capabilities", "NAICS Code(s)"];
      rawFieldDbNamesArray = ["legal_name", "abbreviated_name", "business_type_id", "sb_certifications_bm_set_aside_ids_comma", "capability_ids_comma", "naics_code_ids_comma"];
      maskFieldDbNamesArray = ["legal_name", "abbreviated_name", "businessTypeMask", "sbCertificationsMask", "capabilitiesMask", "naicsCodesMask"];
      maskPlainTextFieldNamesArray = ["legal_name", "abbreviated_name", "businessTypeMaskPlainText", "sbCertificationsMaskPlainText", "capabilitiesMaskPlainText", "naicsCodesMaskPlainText"];
      for(let contactsCompaniesExtraFieldObj of this.props.DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs) {
        headerNamesArray.push(contactsCompaniesExtraFieldObj.display_name);
        rawFieldDbNamesArray.push(contactsCompaniesExtraFieldObj.db_name);
        maskFieldDbNamesArray.push(contactsCompaniesExtraFieldObj.db_name);
        maskPlainTextFieldNamesArray.push(contactsCompaniesExtraFieldObj.db_name);
      }
    }

    const cellWidthEm = 20;

    var contactsImportButtonComponent = null;
    if(c_importContactsAtLeast1NonDuplicateRowHasInvalidDataCellTF) {
      contactsImportButtonComponent = (
        <font className="fontItalic fontRed">
          {"--Fix errors in highlighted red cells--"}
        </font>
      );
    }
    else if(c_importNumContactsNoDuplicatesToImport === 0) {
      contactsImportButtonComponent = (
        <font className="fontItalic fontTextLighter">
          {"--No Unique Contacts to Import--"}
        </font>
      );
    }
    else {
      contactsImportButtonComponent = (
        <CEGeneralReact.CEButton
          p_type="blue"
          p_text={"Import " + c_importNumContactsNoDuplicatesToImport + " " + JSFUNC.plural(c_importNumContactsNoDuplicatesToImport, "Contact", "Contacts")}
          f_onClick={this.onclick_import_contacts}
        />
      );
    }

    return(
      <>
        <div className="flex00a displayFlexRowVc medFullPad bgLighterBlue borderB1bbb">
          <div className="flex11a">
            <font className="font14 fontTextLight">
              {"Import Contact " + companiesOrPersons}
            </font>
          </div>
          <div className="flex00a displayFlexColumnHcVc lrMedPad">
            {contactsImportButtonComponent}
          </div>
          <div className="flex00a displayFlexColumnHcVc lrMedMargin">
            <CEGeneralReact.ButtonWithConfirmBox
              p_buttonType="gray"
              p_buttonText="Cancel Import"
              p_confirmType="confirm"
              p_confirmMessage="Are you sure you want to cancel this contact import process?"
              f_onClickConfirm={this.onclick_cancel_contact_import}
            />
          </div>
        </div>
        <div className="flex00a displayFlexRow">
          <div className="flex00a displayFlexRow tbMargin lrMedMargin border1bbb bgLightesterGray tbPad" style={{flexBasis:"27em"}}>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
              <LibraryReact.InteractiveDiv
                p_class={"displayFlexColumnHcVc border bevelBorderColors borderRadius05 bgLighterGray textCenter" + ((canDecrementPageTF) ? ("hoverLightestestGray cursorPointer") : (""))}
                p_styleObj={{width:"2.4em", height:"1.8em"}}
                f_onClick={((canDecrementPageTF) ? (this.onclick_decrement_page) : (undefined))}>
                <CEGeneralReact.SvgUpDownCarat p_isDownTF={false} p_sizeEm={1} />
              </LibraryReact.InteractiveDiv>
            </div>
            <div className="flex11a displayFlexColumnHcVc textCenter">
              <font className="fontItalic fontTextLight">
                {"Showing Contacts " + firstContactNumber + " - " + lastContactNumber + " of " + numContactsToImport}
              </font>
            </div>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
              <LibraryReact.InteractiveDiv
                p_class={"displayFlexColumnHcVc border bevelBorderColors borderRadius05 bgLighterGray textCenter " + ((canIncrementPageTF) ? ("hoverLightestestGray cursorPointer") : (""))}
                p_styleObj={{width:"2.4em", height:"1.8em"}}
                f_onClick={((canIncrementPageTF) ? (this.onclick_increment_page) : (undefined))}>
                <CEGeneralReact.SvgUpDownCarat p_isDownTF={true} p_sizeEm={1} />
              </LibraryReact.InteractiveDiv>
            </div>
          </div>
          <div className="flex11a" />
        </div>
        <div className="flex11a xyScroll lrMedPad hugeBottomPad">
          <div className="displayFlexRow" style={{height:"2em"}}>
            {headerNamesArray.map((m_headerName) =>
              <div className="flex00a displayFlexRowVc lrPad bgContactsDarkYellow" style={{flexBasis:cellWidthEm + "em"}} title={m_headerName}>
                <LibraryReact.Nowrap p_fontClass="fontWhite">
                  {m_headerName}
                </LibraryReact.Nowrap>
              </div>
            )}
          </div>
          {c_importContactsConvertedDataArrayOfObjs.map((m_importContactsConvertedDataObj, m_rowIndex) =>
            (((m_rowIndex + 1) >= firstContactNumber) && ((m_rowIndex + 1) <= lastContactNumber)) &&
            <div
              key={m_importContactsConvertedDataObj.uniqueKey}
              className={"displayFlexRow " + ((m_importContactsConvertedDataObj.isDuplicateTF || m_importContactsConvertedDataObj.isBlankTF) ? ("bgLightRed") : ("bgContactsLighterYellow")) + " hoverLighterBlueGradient"}
              style={{width:(cellWidthEm * maskFieldDbNamesArray.length) + "em"}}>
              {maskFieldDbNamesArray.map((m_maskFieldDbName, m_index) =>
                <AdminImportContactsConvertedDataCell
                  p_columnIndex={m_index}
                  p_importContactsConvertedDataObj={m_importContactsConvertedDataObj}
                  p_rawFieldDbName={rawFieldDbNamesArray[m_index]}
                  p_maskFieldDbName={m_maskFieldDbName}
                  p_maskPlainTextFieldName={maskPlainTextFieldNamesArray[m_index]}
                  p_cellWidthEm={cellWidthEm}
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}));


const AdminImportContactsConvertedDataCell = inject("AdminImportMobx")(observer(
class AdminImportContactsConvertedDataCell extends Component { //props: p_columnIndex, p_importContactsConvertedDataObj, p_rawFieldDbName, p_maskFieldDbName, p_maskPlainTextFieldName, p_cellWidthEm
  constructor(props) {
    super(props);
    this.state = {
      s_editingContactCellTF: false,
      s_localVisibleCsvContactRawValue: ""
    };
  }

  onclick_open_edit_single_contact_cell = () => {
    const p_importContactsConvertedDataObj = this.props.p_importContactsConvertedDataObj;
    const p_rawFieldDbName = this.props.p_rawFieldDbName;
    this.setState({
      s_editingContactCellTF: true,
      s_localVisibleCsvContactRawValue: p_importContactsConvertedDataObj[p_rawFieldDbName]
    });
  }

  onchange_local_visible_csv_contact_raw_value = (i_newValueString) => {
    this.setState({s_localVisibleCsvContactRawValue:i_newValueString});
  }

  onclick_save_single_contact_cell = () => {
    const s_localVisibleCsvContactRawValue = this.state.s_localVisibleCsvContactRawValue;

    const p_columnIndex = this.props.p_columnIndex;
    const p_importContactsConvertedDataObj = this.props.p_importContactsConvertedDataObj;
    const p_rawFieldDbName = this.props.p_rawFieldDbName;

    this.props.AdminImportMobx.a_import_update_single_csv_cell_in_csv_file_data_string(p_importContactsConvertedDataObj.csvRowIndexAfterHeader, p_columnIndex, s_localVisibleCsvContactRawValue, p_importContactsConvertedDataObj[p_rawFieldDbName]);
    this.onclick_close_edit_single_contact_cell();
  }

  onclick_close_edit_single_contact_cell = () => {
    this.setState({s_editingContactCellTF:false});
  }

  render() {
    const s_editingContactCellTF = this.state.s_editingContactCellTF;
    const s_localVisibleCsvContactRawValue = this.state.s_localVisibleCsvContactRawValue;

    const p_importContactsConvertedDataObj = this.props.p_importContactsConvertedDataObj;
    const p_rawFieldDbName = this.props.p_rawFieldDbName;
    const p_maskFieldDbName = this.props.p_maskFieldDbName;
    const p_maskPlainTextFieldName = this.props.p_maskPlainTextFieldName;
    const p_cellWidthEm = this.props.p_cellWidthEm;

    var valueMask = p_importContactsConvertedDataObj[p_maskFieldDbName];
    var valueMaskPlainText = p_importContactsConvertedDataObj[p_maskPlainTextFieldName];
    var cellDisplay = ((valueMask === undefined) ? ("") : (valueMask));
    var cellTitle = valueMaskPlainText;

    var displayFontClass = undefined;
    if(p_importContactsConvertedDataObj.isBlankTF) {
      displayFontClass = "fontRed";
      if(JSFUNC.in_array(p_maskFieldDbName, ["legal_name", "first_name", "last_name"])) {
        cellDisplay = "[blank name]";
        cellTitle = cellDisplay + "\nThis Contact has a blank name field and will not be imported";

      }
    }
    else if(p_importContactsConvertedDataObj.isDuplicateTF) {
      displayFontClass = "fontRed";
      if(JSFUNC.in_array(p_maskFieldDbName, ["legal_name", "parentCompanyAbbrOrLegalName"])) { //first column for contact companies or contact persons
        cellDisplay = "[duplicate] " + valueMaskPlainText;
        cellTitle = cellDisplay + "\nThis Contact has either a duplicate name within this import list, or matches a Contact already in the system. This contact entry will not be included in this import";
      }
    }

    var invalidCellMessage = undefined;
    if((p_maskFieldDbName === "first_name") && p_importContactsConvertedDataObj.personFirstNameInvalidTF) {
      invalidCellMessage = "First Name cannot be blank";
    }
    else if((p_maskFieldDbName === "last_name") && p_importContactsConvertedDataObj.personLastNameInvalidTF) {
      invalidCellMessage = "Last Name cannot be blank";
    }
    else if((p_maskFieldDbName === "email") && p_importContactsConvertedDataObj.personEmailInvalidTF) {
      invalidCellMessage = "Not a valid email format";
    }
    else if((p_maskFieldDbName === "legal_name") && p_importContactsConvertedDataObj.companyLegalNameInvalidTF) {
      invalidCellMessage = "Legal Name cannot be blank";
    }
    else if((p_maskFieldDbName === "businessTypeMask") && p_importContactsConvertedDataObj.businessTypeInvalidTF) {
      invalidCellMessage = "Business Type must be selected by ID number";
    }

    var cellBorder = undefined;
    if(invalidCellMessage !== undefined) {
      cellBorder = "solid 2px #d00";
      cellTitle += "\n" + invalidCellMessage;
    }

    return(
      <div
        className="flex00a displayFlexRow"
        style={{flexBasis:p_cellWidthEm + "em", height:"2.4em", border:cellBorder}}>
        <div className="flex11a displayFlexColumnVc" title={cellTitle}>
          {(s_editingContactCellTF) ? (
            <LibraryReact.Text
              p_value={s_localVisibleCsvContactRawValue}
              p_styleObj={{width:"100%"}}
              p_tabIndex={1}
              f_onChange={this.onchange_local_visible_csv_contact_raw_value}
              f_onKeyDownEnter={this.onclick_save_single_contact_cell}
            />
          ) : (
            <div className="lrPad">
              <LibraryReact.Nowrap p_fontClass={displayFontClass}>
                {cellDisplay}
              </LibraryReact.Nowrap>
            </div>
          )}
        </div>
        <div className="flex00a displayFlexRowVc lrPad">
          {(s_editingContactCellTF) ? (
            <>
              <CEGeneralReact.EditSaveCancelIcon
                p_iconType="save"
                f_onClick={this.onclick_save_single_contact_cell}
              />
              <CEGeneralReact.EditSaveCancelIcon
                p_iconType="cancel"
                f_onClick={this.onclick_close_edit_single_contact_cell}
              />
            </>
          ) : (
            <CEGeneralReact.EditSaveCancelIcon
              p_iconType="edit"
              p_title="Edit the value from the .csv without having to reupload the file"
              f_onClick={this.onclick_open_edit_single_contact_cell}
            />
          )}
        </div>
      </div>
    );
  }
}));
