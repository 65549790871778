import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from '../../CaptureExecLocalDatabaseMobx/JSPHP.js';

import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import OpenCaptureMobx from '../OpenCapture/OpenCaptureMobx.js';

class AdminIntegrationsMobx {
  k_integrationsLogActionsArrayOfObjs = [
    {dbName:"iCEiIN", displayName:"Insert CE"},
    {dbName:"uCEuIN", displayName:"Update CE"},
    {dbName:"dCEdIN", displayName:"Delete CE"}
  ];

  o_selectedTabDbName = "setup"; //"setup", "mapping", "specialHandling",  "log"
  o_setupVerifyApiCredentialsFlag = "start"; //"start", "working", "verified", "credentialsFailed", "ceError"
  o_mappingExampleFieldsPanelOpenTF = false;
  o_mappingExampleFieldsIntegrationOppIDString = "";
  o_mappingExampleFieldsFlag = "start"; //"start", "working", "verified", "credentialsFailed", "ceError"
  o_mappingExampleFieldsFetchedIntegrationOppsJsonString = "";
  o_integrationsChangelogLoadingDataOrErrorUndefinedTFU = false;
  o_integrationsChangelogFilterObj = {
    numItemsPerPage: 25,
    currentPageNumber: 1,
    dateMin: JSFUNC.blank_date(),
    dateMax: JSFUNC.blank_date(),
    userID: -1,
    actionDbNamesComma: "",
    ceCaptureIDsComma: "",
    integrationUniqueIDSearchText: "",
    ceFieldIDsComma: "",
    integrationFieldDbNamesComma: "",
    sortColumnDbName: "dateTimeLocalMask",
    sortIsAscTF: false
  };

  constructor() {
    makeObservable(this, {
      o_selectedTabDbName: observable,
      o_setupVerifyApiCredentialsFlag: observable,
      o_mappingExampleFieldsPanelOpenTF: observable,
      o_mappingExampleFieldsIntegrationOppIDString: observable,
      o_mappingExampleFieldsFlag: observable,
      o_mappingExampleFieldsFetchedIntegrationOppsJsonString: observable,
      o_integrationsChangelogLoadingDataOrErrorUndefinedTFU: observable,
      o_integrationsChangelogFilterObj: observable,

      c_integrationSystemDisplayName: computed,
      c_setupAllIntegrationApiConnectionFieldsArrayOfObjs: computed,
      c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined: computed,
      c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined: computed,
      c_linkedFieldsWithValuesArrayOfObjs: computed,
      c_expandedIntegrationsChangelogArrayOfObjs: computed,
      c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs: computed,
      c_integrationsChangelogFilteredTotalNumItems: computed,
      c_integrationsChangelogCurrentPageFirstItemNumber: computed,
      c_integrationsChangelogCurrentPageLastItemNumber: computed,
      c_integrationsChangelogCanIncrementCurrentPageNumberTF: computed,
      c_integrationsChangelogCanDecrementCurrentPageNumberTF: computed,
      c_filteredSortedItemsOnSelectedPageExpandedIntegrationsChangelogArrayOfObjs: computed,
      c_selectIntegrationsLogActionsFieldTypeObj: computed,
      c_selectMultiIntegrationFieldDbNamesFieldTypeObj: computed,

      a_set_selected_tab_db_name: action,
      a_set_setup_verify_api_credentials_flag: action,
      a_setup_verify_api_credentials: action,
      a_set_mapping_example_fields_panel_open_tf: action,
      a_set_mapping_example_fields_integration_opp_id_string: action,
      a_set_mapping_example_fields_flag: action,
      a_set_mapping_example_fields_fetched_integration_opps_json_string: action,
      a_mapping_example_fields_search_for_integration_opp_by_id_string: action,
      a_create_new_integration_linked_fields_pairing_from_integration_field_db_name: action,
      a_update_linked_fields_field: action,
      a_delete_linked_fields_and_all_mapped_values: action,
      a_create_new_value_pair_under_field_pairing: action,
      a_update_linked_field_values_field: action,
      a_delete_mapped_values_pair: action,
      a_integrations_changelog_set_loading_data_or_error_undefined_tfu: action,
      a_load_integrations_changelog_data: action,
      a_set_integrations_changelog_filter_field: action
    });
  }


  get c_integrationSystemDisplayName() {
    const c_bitIntegrationSystemVantagePointTF = DatabaseMobx.c_bitIntegrationSystemVantagePointTF;
    if(c_bitIntegrationSystemVantagePointTF) {
      return("VantagePoint");
    }
    return("--Unknown Integration System '" + DatabaseMobx.o_bitcompaniesTblRowMap.get("integration_system") + "'--");
  }


  get c_setupAllIntegrationApiConnectionFieldsArrayOfObjs() {
    const c_companyIntegrationOauthWebAddress = DatabaseMobx.c_companyIntegrationOauthWebAddress;
    const c_companyIntegrationApiWebAddress = DatabaseMobx.c_companyIntegrationApiWebAddress;
    const c_companyIntegrationUsername = DatabaseMobx.c_companyIntegrationUsername;
    const c_companyIntegrationPasswordScramble = DatabaseMobx.c_companyIntegrationPasswordScramble;
    const c_companyIntegrationDatabaseName = DatabaseMobx.c_companyIntegrationDatabaseName;
    const c_companyIntegrationClientIDScramble = DatabaseMobx.c_companyIntegrationClientIDScramble;
    const c_companyIntegrationClientSecretScramble = DatabaseMobx.c_companyIntegrationClientSecretScramble;

    var allIntegrationApiConnectionFieldsArrayOfObjs = [
      {valueRaw:c_companyIntegrationOauthWebAddress, fieldDbName:"integration_oauth_web_address", fieldDisplayName:"OAuth Web Address", inputType:"text", scrambleTF:false},
      {valueRaw:c_companyIntegrationApiWebAddress, fieldDbName:"integration_api_web_address", fieldDisplayName:"API Web Address", inputType:"text", scrambleTF:false},
      {valueRaw:c_companyIntegrationUsername, fieldDbName:"integration_username", fieldDisplayName:"Username", inputType:"text", scrambleTF:false},
      {valueRaw:c_companyIntegrationPasswordScramble, fieldDbName:"integration_password_scramble", fieldDisplayName:"Password", inputType:"text", scrambleTF:true},
      {valueRaw:c_companyIntegrationDatabaseName, fieldDbName:"integration_database_name", fieldDisplayName:"Database Name", inputType:"text", scrambleTF:false},
      {valueRaw:c_companyIntegrationClientIDScramble, fieldDbName:"integration_client_id_scramble", fieldDisplayName:"Client ID", inputType:"text", scrambleTF:true},
      {valueRaw:c_companyIntegrationClientSecretScramble, fieldDbName:"integration_client_secret_scramble", fieldDisplayName:"Client Secret", inputType:"text", scrambleTF:true},
    ];

    for(let integrationApiConnectionFieldObj of allIntegrationApiConnectionFieldsArrayOfObjs) {
      integrationApiConnectionFieldObj.isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(integrationApiConnectionFieldObj.valueRaw);
    }

    return(allIntegrationApiConnectionFieldsArrayOfObjs);
  }


  get c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined() {
    const c_tbl_captures_fields = DatabaseMobx.c_tbl_captures_fields;
    const c_companyIntegrationIntegrationUniqueIDCEFieldID = DatabaseMobx.c_companyIntegrationIntegrationUniqueIDCEFieldID;

    if(JSFUNC.select_int_is_filled_out_tf(c_companyIntegrationIntegrationUniqueIDCEFieldID)) {
      return(c_tbl_captures_fields.get(c_companyIntegrationIntegrationUniqueIDCEFieldID)); //either the correct expanded capture field map or undefined
    }
    return(undefined);
  }


  get c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined() {
    const o_mappingExampleFieldsFetchedIntegrationOppsJsonString = this.o_mappingExampleFieldsFetchedIntegrationOppsJsonString;

    const fetchedOppsArrayOfObjsOrUndefined = JSFUNC.parse_json_arrayOfObjs_string_into_arrayOfObjs_or_undefined(o_mappingExampleFieldsFetchedIntegrationOppsJsonString);
    
    if(!JSFUNC.is_array(fetchedOppsArrayOfObjsOrUndefined)) {
      return(undefined);
    }

    var oppFieldsArrayOfObjsOrUndefined = [];
    if(fetchedOppsArrayOfObjsOrUndefined.length >= 1) { //empty array means that 0 results were returned from the fetch
      const fetchedOppObj = fetchedOppsArrayOfObjsOrUndefined[0]; //if multiple opps were fetched from the integration, use the first one as the example
      for(var key in fetchedOppObj) {
        if(fetchedOppObj.hasOwnProperty(key)) {
          oppFieldsArrayOfObjsOrUndefined.push({
            integrationFieldDbName: key,
            integrationValueString: fetchedOppObj[key]
          });
        }
      }
    }
    return(oppFieldsArrayOfObjsOrUndefined);
  }


  get c_linkedFieldsWithValuesArrayOfObjs() {
    const o_tbl_a_integrations_linked_fields = DatabaseMobx.o_tbl_a_integrations_linked_fields;
    const o_tbl_a_integrations_linked_field_values = DatabaseMobx.o_tbl_a_integrations_linked_field_values;

    var linkedFieldsWithValuesArrayOfObjs = [];
    for(let linkedFieldMap of o_tbl_a_integrations_linked_fields.values()) {
      var linkedFieldObj = JSFUNC.obj_from_map(linkedFieldMap);

      //get the selected linked CE field info and fieldTypeObj if it is a select type
      var ceFieldIsValidTF = false;
      var canMapValuesTF = false;
      var ceFieldFieldTypeObjOrUndefined = undefined;
      if(JSFUNC.select_int_is_filled_out_tf(linkedFieldObj.capture_field_id)) {
        var captureFieldMapOrUndefined = DatabaseMobx.c_tbl_captures_fields.get(linkedFieldObj.capture_field_id);
        if(captureFieldMapOrUndefined !== undefined) {
          ceFieldFieldTypeObjOrUndefined = captureFieldMapOrUndefined.get("fieldTypeObj");
          if(ceFieldFieldTypeObjOrUndefined !== undefined) {
            ceFieldIsValidTF = true;
            if(ceFieldFieldTypeObjOrUndefined.requiresSelectWithSearchDataObjTF) {
              canMapValuesTF = true;
            }
          }
        }
      }

      //determine if the integration field dbName has any spaces in it, which displays a red warning
      var integrationFieldDbNameContainsAnySpacesTF = JSFUNC.input_lowercase_string_contains_lowercase_search_term_string_tf(linkedFieldObj.integration_field_db_name, " ");

      //compute fields related to the integration field new capture default value
      var integrationFieldUsingDefaultValueTF = (linkedFieldObj.integration_field_using_default_value_01 === 1);

      //fetch all mapped values for this linked pair
      var mappedValuesArrayOfObjs = []; //no mapped values loaded/shown if the selected CE field is not valid
      if(ceFieldIsValidTF) {
        mappedValuesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_integrations_linked_field_values, "linked_field_id", linkedFieldObj.id, "sort", true);
        
        //loop through mapped values and compute the valueMaskSortIfoObj from the CE raw value
        for(let mappedValueObj of mappedValuesArrayOfObjs) {
          //convert CE raw value string from the database to the raw value (int or string) based on the fieldTypeObj
          var captureFieldValueRaw = DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(mappedValueObj.capture_field_value_string, ceFieldFieldTypeObjOrUndefined);
          var captureFieldValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(captureFieldValueRaw, ceFieldFieldTypeObjOrUndefined);
          mappedValueObj.captureFieldValueRaw = captureFieldValueRaw;
          mappedValueObj.captureFieldValueMaskSortIfoObj = captureFieldValueMaskSortIfoObj;
        }
      }

      //used when converting a new CE raw value into the integration new value
      var hasAtLeast1MappedValueTF = (mappedValuesArrayOfObjs.length > 0);

      linkedFieldObj.ceFieldIsValidTF = ceFieldIsValidTF;
      linkedFieldObj.canMapValuesTF = canMapValuesTF;
      linkedFieldObj.ceFieldFieldTypeObjOrUndefined = ceFieldFieldTypeObjOrUndefined;
      linkedFieldObj.integrationFieldDbNameContainsAnySpacesTF = integrationFieldDbNameContainsAnySpacesTF;
      linkedFieldObj.integrationFieldUsingDefaultValueTF = integrationFieldUsingDefaultValueTF;
      linkedFieldObj.mappedValuesArrayOfObjs = mappedValuesArrayOfObjs;
      linkedFieldObj.hasAtLeast1MappedValueTF = hasAtLeast1MappedValueTF;

      linkedFieldsWithValuesArrayOfObjs.push(linkedFieldObj);
    }

    JSFUNC.sort_arrayOfObjs(linkedFieldsWithValuesArrayOfObjs, "sort", true);
    
    return(linkedFieldsWithValuesArrayOfObjs);
  }




  get c_expandedIntegrationsChangelogArrayOfObjs() {
    const o_tbl_a_integrations_log = DatabaseMobx.o_tbl_a_integrations_log;

    var expandedIntegrationsChangelogArrayOfObjs = [];
    for(let integrationChangelogMap of o_tbl_a_integrations_log.values()) {
      var expandedIntegrationsChangelogObj = JSFUNC.obj_from_map(integrationChangelogMap);

      expandedIntegrationsChangelogObj.dateLocalYmd = JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(expandedIntegrationsChangelogObj.datetime_utc);
      expandedIntegrationsChangelogObj.dateTimeLocalMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(expandedIntegrationsChangelogObj.datetime_utc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj);
      expandedIntegrationsChangelogObj.userNameMask = DatabaseMobx.user_name_mask_from_user_id(expandedIntegrationsChangelogObj.user_id);
      
      var iudActionStringMask = expandedIntegrationsChangelogObj.iud_action_string;
      if(expandedIntegrationsChangelogObj.iud_action_string === "iCEiIN") { iudActionStringMask = "Insert CE"; }
      else if(expandedIntegrationsChangelogObj.iud_action_string === "uCEuIN") { iudActionStringMask = "Update CE"; }
      else if(expandedIntegrationsChangelogObj.iud_action_string === "dCEdIN") { iudActionStringMask = "Delete CE"; }
      expandedIntegrationsChangelogObj.iudActionStringMask = iudActionStringMask;

      expandedIntegrationsChangelogObj.captureNameMask = DatabaseMobx.capture_name_plaintext_from_capture_id(expandedIntegrationsChangelogObj.ce_capture_id, true);
      expandedIntegrationsChangelogObj.ceFieldDisplayNameMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(expandedIntegrationsChangelogObj.ce_field_id, DatabaseMobx.c_selectCaptureFieldFieldTypeObj);

      var successErrorBgClass = "";
      var successErrorFontClass = "";
      var successErrorMessage = "";
      if(JSFUNC.string_is_filled_out_tf(expandedIntegrationsChangelogObj.error_message)) {
        if(expandedIntegrationsChangelogObj.error_message.substring(0, 9) === "[skipped]") {
          successErrorBgClass = "bgLightOrange";
          successErrorFontClass = "fontItalic fontTextLight";
        }
        else {
          successErrorBgClass = "bgLightRed";
          successErrorFontClass = "fontDarkRed";
        }
        successErrorMessage = expandedIntegrationsChangelogObj.error_message;
      }
      else {
        successErrorBgClass = "bgLightGreen";
        successErrorFontClass = "fontItalic fontDarkGreen";
        successErrorMessage = "Success";
      }
      var successErrorMask = (
        <div className={"flex11a displayFlexColumnHcVc " + successErrorBgClass + " tbMicroPad lrPad textCenter"}>
          <font className={successErrorFontClass}>
            {successErrorMessage}
          </font>
        </div>
      );
      expandedIntegrationsChangelogObj.successErrorMask = successErrorMask;

      expandedIntegrationsChangelogArrayOfObjs.push(expandedIntegrationsChangelogObj);
    }

    //sort log entries by date newest on top
    JSFUNC.sort_arrayOfObjs(expandedIntegrationsChangelogArrayOfObjs, "datetime_utc", false);

    return(expandedIntegrationsChangelogArrayOfObjs);
  }

  get c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs() {
    const o_integrationsChangelogFilterObj = this.o_integrationsChangelogFilterObj; //numItemsPerPage, currentPageNumber, dateMin, dateMax, userID, actionDbNamesComma, ceCaptureIDsComma, integrationUniqueIDSearchText, ceFieldIDsComma, integrationFieldDbNamesComma, sortColumnDbName, sortIsAscTF
    const c_expandedIntegrationsChangelogArrayOfObjs = this.c_expandedIntegrationsChangelogArrayOfObjs;
    
    const filterDateMinIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_integrationsChangelogFilterObj.dateMin);
    const filterDateMaxIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_integrationsChangelogFilterObj.dateMax);
    const filterUserIsFilledOutTF = JSFUNC.select_int_is_filled_out_tf(o_integrationsChangelogFilterObj.userID);
    const filterActionIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_integrationsChangelogFilterObj.actionDbNamesComma);
    const filterCECapturesIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_integrationsChangelogFilterObj.ceCaptureIDsComma);
    const filterIntegrationUniqueIDSearchIsFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(o_integrationsChangelogFilterObj.integrationUniqueIDSearchText);
    const filterCEFieldsIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_integrationsChangelogFilterObj.ceFieldIDsComma);
    const filterIntegrationFieldsIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_integrationsChangelogFilterObj.integrationFieldDbNamesComma);

    const selectedFilterActionDbNamesArray = JSFUNC.convert_comma_list_to_array(o_integrationsChangelogFilterObj.actionDbNamesComma);
    const selectedFilterCECaptureIDsArray = JSFUNC.convert_comma_list_to_int_array(o_integrationsChangelogFilterObj.ceCaptureIDsComma);
    const integrationUniqueIDSearchTextLowercase = o_integrationsChangelogFilterObj.integrationUniqueIDSearchText.toLowerCase();
    const selectedFilterCEFieldIDsArray = JSFUNC.convert_comma_list_to_int_array(o_integrationsChangelogFilterObj.ceFieldIDsComma);
    const selectedFilterIntegrationFieldDbNamesArray = JSFUNC.convert_comma_list_to_array(o_integrationsChangelogFilterObj.integrationFieldDbNamesComma);

    //filter the changelog items
    var changelogFilteredSortedItemsArrayOfObjs = [];
    for(let expandedIntegrationsChangelogObj of c_expandedIntegrationsChangelogArrayOfObjs) {
      var includeChangelogItemTF = true;

      if(includeChangelogItemTF && filterDateMinIsFilledOutTF && (expandedIntegrationsChangelogObj.dateLocalYmd < o_integrationsChangelogFilterObj.dateMin)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterDateMaxIsFilledOutTF && (expandedIntegrationsChangelogObj.dateLocalYmd > o_integrationsChangelogFilterObj.dateMax)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterUserIsFilledOutTF && (expandedIntegrationsChangelogObj.user_id !== o_integrationsChangelogFilterObj.userID)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterActionIsFilledOutTF && !JSFUNC.in_array(expandedIntegrationsChangelogObj.iud_action_string, selectedFilterActionDbNamesArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterCECapturesIsFilledOutTF && !JSFUNC.in_array(expandedIntegrationsChangelogObj.ce_capture_id, selectedFilterCECaptureIDsArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterIntegrationUniqueIDSearchIsFilledOutTF && !JSFUNC.input_string_converted_to_lowercase_contains_lowercase_search_term_string_tf(expandedIntegrationsChangelogObj.integration_unique_id_string, integrationUniqueIDSearchTextLowercase)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterCEFieldsIsFilledOutTF && !JSFUNC.in_array(expandedIntegrationsChangelogObj.ce_field_id, selectedFilterCEFieldIDsArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterIntegrationFieldsIsFilledOutTF && !JSFUNC.in_array(expandedIntegrationsChangelogObj.integration_field_db_name, selectedFilterIntegrationFieldDbNamesArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF) {
        changelogFilteredSortedItemsArrayOfObjs.push(expandedIntegrationsChangelogObj);
      }
    }

    //for sort, if the column being sorted is dateTimeLocalMask, use the raw datetime value datetime_utc instead
    var selectedSortColumnDbName = o_integrationsChangelogFilterObj.sortColumnDbName;
    if(o_integrationsChangelogFilterObj.sortColumnDbName === "dateTimeLocalMask") {
      selectedSortColumnDbName = "datetime_utc";
    }

    //sort the changelog items
    var sortColumnDbNamesArray = [selectedSortColumnDbName];
    var sortIsAscTFArray = [o_integrationsChangelogFilterObj.sortIsAscTF];
    if(o_integrationsChangelogFilterObj.sortColumnDbName !== "dateTimeLocalMask") { //if sorting by a column other than date
      sortColumnDbNamesArray.push("datetime_utc"); //do a secondary sort by date desc (so that sorting by field groups all fields together sorted by latest on top)
      sortIsAscTFArray.push(false);
      sortColumnDbNamesArray.push("id"); //tertiary sort on log id
      sortIsAscTFArray.push(false);
    }
    else { //primary sort is dateTimeLocalMask, add a secondary sort in the same direction for id
      sortColumnDbNamesArray.push("id");
      sortIsAscTFArray.push(o_integrationsChangelogFilterObj.sortIsAscTF);
    }
    JSFUNC.sort_arrayOfObjs(changelogFilteredSortedItemsArrayOfObjs, sortColumnDbNamesArray, sortIsAscTFArray);

    return(changelogFilteredSortedItemsArrayOfObjs);
  }

  get c_integrationsChangelogFilteredTotalNumItems() {
    const c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs = this.c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs;
    return(c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs.length);
  }

  get c_integrationsChangelogCurrentPageFirstItemNumber() {
    const o_integrationsChangelogFilterObj = this.o_integrationsChangelogFilterObj;
    const c_integrationsChangelogFilteredTotalNumItems = this.c_integrationsChangelogFilteredTotalNumItems;

    if(c_integrationsChangelogFilteredTotalNumItems === 0) {
      return(0);
    }
    return(((o_integrationsChangelogFilterObj.currentPageNumber - 1) * o_integrationsChangelogFilterObj.numItemsPerPage) + 1);
  }

  get c_integrationsChangelogCurrentPageLastItemNumber() {
    const o_integrationsChangelogFilterObj = this.o_integrationsChangelogFilterObj;
    const c_integrationsChangelogFilteredTotalNumItems = this.c_integrationsChangelogFilteredTotalNumItems;

    var changelogCurrentPageLastItemNumber = (o_integrationsChangelogFilterObj.currentPageNumber * o_integrationsChangelogFilterObj.numItemsPerPage);
    if(c_integrationsChangelogFilteredTotalNumItems < changelogCurrentPageLastItemNumber) {
      changelogCurrentPageLastItemNumber = c_integrationsChangelogFilteredTotalNumItems;
    }
    return(changelogCurrentPageLastItemNumber);
  }

  get c_integrationsChangelogCanIncrementCurrentPageNumberTF() {
    const c_integrationsChangelogFilteredTotalNumItems = this.c_integrationsChangelogFilteredTotalNumItems;
    const c_integrationsChangelogCurrentPageLastItemNumber = this.c_integrationsChangelogCurrentPageLastItemNumber;

    return(c_integrationsChangelogCurrentPageLastItemNumber < c_integrationsChangelogFilteredTotalNumItems);
  }

  get c_integrationsChangelogCanDecrementCurrentPageNumberTF() {
    const o_integrationsChangelogFilterObj = this.o_integrationsChangelogFilterObj;
    return(o_integrationsChangelogFilterObj.currentPageNumber > 1);
  }

  get c_filteredSortedItemsOnSelectedPageExpandedIntegrationsChangelogArrayOfObjs() {
    const c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs = this.c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs;
    const c_integrationsChangelogCurrentPageFirstItemNumber = this.c_integrationsChangelogCurrentPageFirstItemNumber;
    const c_integrationsChangelogCurrentPageLastItemNumber = this.c_integrationsChangelogCurrentPageLastItemNumber;

    var changelogFilteredSortedItemsOnSelectedPageArrayOfObjs = [];
    var itemNumber = 1;
    for(let expandedIntegrationsChangelogObj of c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs) {
      if((itemNumber >= c_integrationsChangelogCurrentPageFirstItemNumber) && (itemNumber <= c_integrationsChangelogCurrentPageLastItemNumber)) {
        changelogFilteredSortedItemsOnSelectedPageArrayOfObjs.push(expandedIntegrationsChangelogObj);
      }
      itemNumber++;
    }
    return(changelogFilteredSortedItemsOnSelectedPageArrayOfObjs);
  }

  get c_selectIntegrationsLogActionsFieldTypeObj() {
    const k_integrationsLogActionsArrayOfObjs = this.k_integrationsLogActionsArrayOfObjs;

    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:false, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("Action", k_integrationsLogActionsArrayOfObjs, "dbName", true, "displayName", swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiIntegrationFieldDbNamesFieldTypeObj() {
    const c_linkedFieldsWithValuesArrayOfObjs = this.c_linkedFieldsWithValuesArrayOfObjs;

    const allIntegrationFieldDbNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(c_linkedFieldsWithValuesArrayOfObjs, "integration_field_db_name");
    const uniqueIntegrationFieldDbNamesArray = JSFUNC.unique(allIntegrationFieldDbNamesArray);
    JSFUNC.sort_array(uniqueIntegrationFieldDbNamesArray, true, true); //sort fieldDbNames alphabetic case insensitive
    const valueDisplayArraysObjIntegrationsChangelogCategories = DatabaseMobx.create_value_display_arrays_obj_from_value_array_and_display_array("Integration Field Name", uniqueIntegrationFieldDbNamesArray, true, uniqueIntegrationFieldDbNamesArray);
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjIntegrationsChangelogCategories, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }





  a_set_selected_tab_db_name(i_tabDbName) {
    this.o_selectedTabDbName = i_tabDbName;
  }

  a_set_setup_verify_api_credentials_flag(i_newValueString) {
    this.o_setupVerifyApiCredentialsFlag = i_newValueString;
  }

  a_setup_verify_api_credentials() {
    const o_userID = UserMobx.o_userID;

    this.a_set_setup_verify_api_credentials_flag("working");

    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_setup_verify_api_credentials", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("integrationsFetchSingleOppByIDString", jsDescription);
    C_CallPhpScript.add_post_var("i_userID", o_userID);
    C_CallPhpScript.add_post_var("i_integrationOppIDString", "");
    C_CallPhpScript.add_return_vars(["integrationOppsJsonString", "integrationErrorMessage"]);

    const functionOnSuccess = (i_parseResponse) => {
      if(i_parseResponse.integrationErrorMessage === "") { //no error from test API fetch
        this.a_set_setup_verify_api_credentials_flag("verified");
      }
      else {
        this.a_set_setup_verify_api_credentials_flag(i_parseResponse.integrationErrorMessage);
      }
      
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      this.a_set_setup_verify_api_credentials_flag("ceError");
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();
  }

  a_set_mapping_example_fields_panel_open_tf(i_newValueTF) {
    this.o_mappingExampleFieldsPanelOpenTF = i_newValueTF;
  }

  a_set_mapping_example_fields_integration_opp_id_string(i_newValueString) {
    this.o_mappingExampleFieldsIntegrationOppIDString = i_newValueString;
  }

  a_set_mapping_example_fields_flag(i_newValueString) {
    this.o_mappingExampleFieldsFlag = i_newValueString;
  }

  a_set_mapping_example_fields_fetched_integration_opps_json_string(i_newValueString) {
    this.o_mappingExampleFieldsFetchedIntegrationOppsJsonString = i_newValueString;
  }

  a_mapping_example_fields_search_for_integration_opp_by_id_string(i_integrationOppIDString) {
    const o_userID = UserMobx.o_userID;

    this.a_set_mapping_example_fields_flag("working");

    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_mapping_example_fields_search_for_integration_opp_by_id_string", ["i_integrationOppIDString"], [i_integrationOppIDString]);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("integrationsFetchSingleOppByIDString", jsDescription);
    C_CallPhpScript.add_post_var("i_userID", o_userID);
    C_CallPhpScript.add_post_var("i_integrationOppIDString", i_integrationOppIDString);
    C_CallPhpScript.add_return_vars(["integrationOppsJsonString", "integrationErrorMessage"]);

    const functionOnSuccess = (i_parseResponse) => {
      if(i_parseResponse.integrationErrorMessage === "") {
        this.a_set_mapping_example_fields_fetched_integration_opps_json_string(i_parseResponse.integrationOppsJsonString);
        this.a_set_mapping_example_fields_flag("verified");
      }
      else {
        this.a_set_mapping_example_fields_flag(i_parseResponse.integrationErrorMessage);
      }
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      this.a_set_mapping_example_fields_flag("ceError");
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();
  }


  a_create_new_integration_linked_fields_pairing_from_integration_field_db_name(i_integrationFieldDbName) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_create_new_integration_linked_fields_pairing_from_integration_field_db_name", ["i_integrationFieldDbName"], [i_integrationFieldDbName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["sort", "integration_field_db_name", "capture_field_id"];
    const valuesArray = [JSFUNC.sort_max_mysqli_int(), i_integrationFieldDbName, -1];
    const idsbArray = ["i", "s", "i"];
    const resortSortColumnName = "sort";
    const resortFilterFieldNamesArray = [];
    const resortFilterValuesArray = [];
    C_CallPhpTblUID.add_insert("tbl_a_integrations_linked_fields", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNamesArray, resortFilterValuesArray);

    C_CallPhpTblUID.execute();
  }


  a_update_linked_fields_field(i_linkedFieldWithValuesObj, i_fieldDbName, i_newValue, i_idsb) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_update_linked_fields_field", ["i_linkedFieldWithValuesObj", "i_fieldDbName", "i_newValue", "i_idsb"], [i_linkedFieldWithValuesObj, i_fieldDbName, i_newValue, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_integrations_linked_fields", i_linkedFieldWithValuesObj.id, i_fieldDbName, i_newValue, i_idsb);
    C_CallPhpTblUID.execute();
  }


  a_delete_linked_fields_and_all_mapped_values(i_linkedFieldWithValuesObj) {
    var mappedValuesIDsToDeleteArray = [];
    if(JSFUNC.is_array(i_linkedFieldWithValuesObj.mappedValuesArrayOfObjs)) {
      mappedValuesIDsToDeleteArray = JSFUNC.get_column_vector_from_arrayOfObjs(i_linkedFieldWithValuesObj.mappedValuesArrayOfObjs, "id");
    }

    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_delete_linked_fields_and_all_mapped_values", ["i_linkedFieldWithValuesObj"], [i_linkedFieldWithValuesObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    if(mappedValuesIDsToDeleteArray.length > 0) {
      C_CallPhpTblUID.add_delete("tbl_a_integrations_linked_field_values", mappedValuesIDsToDeleteArray);
    }

    C_CallPhpTblUID.add_delete("tbl_a_integrations_linked_fields", i_linkedFieldWithValuesObj.id, "sort", [], []);

    C_CallPhpTblUID.execute();
  }


  a_create_new_value_pair_under_field_pairing(p_linkedFieldWithValuesObj, i_newIntegrationValueString) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_create_new_value_pair_under_field_pairing", ["p_linkedFieldWithValuesObj", "i_newIntegrationValueString"], [p_linkedFieldWithValuesObj, i_newIntegrationValueString]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["sort", "linked_field_id", "integration_value_string", "capture_field_value_string", "extra_capture_field_value_strings_comma"];
    const valuesArray = [JSFUNC.sort_max_mysqli_int(), p_linkedFieldWithValuesObj.id, i_newIntegrationValueString, "", ""];
    const idsbArray = ["i", "i", "s", "s", "s"];
    const resortSortColumnName = "sort";
    const resortFilterFieldNamesArray = ["linked_field_id"];
    const resortFilterValuesArray = [p_linkedFieldWithValuesObj.id];
    C_CallPhpTblUID.add_insert("tbl_a_integrations_linked_field_values", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNamesArray, resortFilterValuesArray);

    C_CallPhpTblUID.execute();
  }


  a_update_linked_field_values_field(i_mappedValueObj, i_fieldDbName, i_newValue, i_idsb) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_update_linked_fields_field", ["i_mappedValueObj", "i_fieldDbName", "i_newValue", "i_idsb"], [i_mappedValueObj, i_fieldDbName, i_newValue, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_integrations_linked_field_values", i_mappedValueObj.id, i_fieldDbName, i_newValue, i_idsb);
    C_CallPhpTblUID.execute();
  }


  a_delete_mapped_values_pair(i_mappedValueObj) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_delete_mapped_values_pair", ["i_mappedValueObj"], [i_mappedValueObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_integrations_linked_field_values", i_mappedValueObj.id, "sort", ["linked_field_id"], [i_mappedValueObj.linked_field_id]);
    C_CallPhpTblUID.execute();
  }





  a_integrations_changelog_set_loading_data_or_error_undefined_tfu(i_newValueTFU) {
    this.o_integrationsChangelogLoadingDataOrErrorUndefinedTFU = i_newValueTFU;
  }


  a_load_integrations_changelog_data() {
    const o_isLocalhost3000SystemTF = CaptureExecMobx.o_isLocalhost3000SystemTF;
    const c_adminIntegrationsChangelogTblNamesToLoadArray = DatabaseMobx.c_adminIntegrationsChangelogTblNamesToLoadArray;

    if(!o_isLocalhost3000SystemTF) {
      this.a_integrations_changelog_set_loading_data_or_error_undefined_tfu(true);

      const functionOnFinish = (i_successTrueErrorFalse) => {
        const updatedLoadingDataOrErrorUndefinedTFU = ((i_successTrueErrorFalse) ? (false) : (undefined));
        this.a_integrations_changelog_set_loading_data_or_error_undefined_tfu(updatedLoadingDataOrErrorUndefinedTFU);
      }
      JSPHP.load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array(c_adminIntegrationsChangelogTblNamesToLoadArray, functionOnFinish);
    }
  }


  a_set_integrations_changelog_filter_field(i_integrationsChangelogFilterField, i_newValue) {
    //numItemsPerPage, currentPageNumber, dateMin, dateMax, userID, actionDbNamesComma, ceCaptureIDsComma, integrationUniqueIDSearchText, ceFieldIDsComma, integrationFieldDbNamesComma, sortColumnDbName, sortIsAscTF
    this.o_integrationsChangelogFilterObj[i_integrationsChangelogFilterField] = i_newValue;
  }




  update_integration_opp_field_from_ce_capture_id_field_id_and_new_value_raw(i_ceCaptureID, i_ceFieldID, i_ceNewValueRaw) {
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;

    //3rd Party Integration is turned on in BIT and the Admin
    if(!(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF)) {
      return;
    }

    JSPHP.integration_update_integration_opp_field_if_ce_field_is_paired(i_ceCaptureID, i_ceFieldID, i_ceNewValueRaw);
  }

  insert_integration_new_opp_with_newly_created_unique_id_string_from_new_ce_capture_id(i_newCaptureID, i_newCaptureAllCECaptureFieldIDsArray, i_newCaptureAllCERawValuesArray) {
    //called for all 3 new capture creation sources: 1) CaptureExec Create New Capture, 2) CaptureExec Admin Import from .csv, 3) GCSS Import
    //i_newCaptureID - new capture id autoincrement from insert into tbl_captures
    //i_newCaptureAllCECaptureFieldIDsArray - every capture fieldID from newly inserted capture (filled out or blank) except `id` [copmuted in DatabaseMobx.create_new_capture_field_names_values_idsb_and_log_arrays_obj_from_new_capture_obj()]
    //i_newCaptureAllCERawValuesArray - every raw value for each fieldID

    const c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined = this.c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined;
    const c_linkedFieldsWithValuesArrayOfObjs = this.c_linkedFieldsWithValuesArrayOfObjs;
    const k_cardIDUserCreateNewCapture = DatabaseMobx.k_cardIDUserCreateNewCapture;
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;
    const c_companyIntegrationIntegrationUniqueIDCEFieldID = DatabaseMobx.c_companyIntegrationIntegrationUniqueIDCEFieldID;
    const c_fieldMapOfDivisionOwners = DatabaseMobx.c_fieldMapOfDivisionOwners;

    //3rd Party Integration is turned on in BIT and the Admin
    if(!(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF)) {
      return;
    }

    //if this CE field is filled out on the Setup tab (required to be filled out to insert/update integration system)
    if(c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined === undefined) {
      return;
    }

    //find the raw value for division owners in the fieldID/value input arrays
    const divisionOwnersFieldID = c_fieldMapOfDivisionOwners.get("id");
    var newCECaptureDivisionIDsColonPercentsCommaOrUndefined = JSFUNC.get_first_array2_value_or_undefined_where_array1_matches_input(divisionOwnersFieldID, i_newCaptureAllCECaptureFieldIDsArray, i_newCaptureAllCERawValuesArray);
    if(newCECaptureDivisionIDsColonPercentsCommaOrUndefined === undefined) {
      newCECaptureDivisionIDsColonPercentsCommaOrUndefined = DatabaseMobx.get_blank_value_or_undefined_from_expanded_capture_field_map(c_fieldMapOfDivisionOwners);
    }
    
    //create the new integration unique ID string based on the new CE captureID
    const newIntegrationOppUniqueIDString = this.create_new_integration_unique_id_string_from_new_ce_capture_id(i_newCaptureID, newCECaptureDivisionIDsColonPercentsCommaOrUndefined);
    
    //make a copy of the raw CE values array to replace the blank integration unique ID with this computed one below
    var rawValuesWithNewIntegrationOppUniqueIDStringArray = JSFUNC.copy_array(i_newCaptureAllCERawValuesArray);

    //find the CE field (matched from CE captureFieldID) representing the integration unique ID field (filled out on the 3rd Party Integration 'Setup' subtab)
    for(let f = 0; f < i_newCaptureAllCECaptureFieldIDsArray.length; f++) {
      if(i_newCaptureAllCECaptureFieldIDsArray[f] === c_companyIntegrationIntegrationUniqueIDCEFieldID) {
        rawValuesWithNewIntegrationOppUniqueIDStringArray[f] = newIntegrationOppUniqueIDString;
      }
    }

    //gather any integration field default values turned on for any integration fields on the 3rd Party Integrations Mapping tab to pass to the JSPHP insert in addition to the list of mapped CE fields
    var integrationFieldDbNamesWithDefaultValuesArrayOfObjs = [];
    for(let linkedFieldWithValuesObj of c_linkedFieldsWithValuesArrayOfObjs) {
      if(linkedFieldWithValuesObj.integrationFieldUsingDefaultValueTF) {
        integrationFieldDbNamesWithDefaultValuesArrayOfObjs.push({
          integrationFieldDbName: linkedFieldWithValuesObj.integration_field_db_name,
          defaultValueString: linkedFieldWithValuesObj.integration_field_default_value_string
        })
      }
    }

    //create the new opp in the integration system, defining the translated fields/values (from CE to Integration) and calling a php POST to make the insert (send this command off without waiting to see if it was successful)
    JSPHP.integration_insert_integration_new_opp(i_newCaptureID, i_newCaptureAllCECaptureFieldIDsArray, rawValuesWithNewIntegrationOppUniqueIDStringArray, newIntegrationOppUniqueIDString, integrationFieldDbNamesWithDefaultValuesArrayOfObjs);

    //update the new capture record with the new integration unique ID value (overwrite the original blank value from the new capture creation), also a changelog entry for it (no changelog entry created originally as the value was blank)
    const sendNotificationTF = false; //only capture/contract manager field updates send notifications, set it false anyway
    const updateIntegrationSystemLinkedFieldTF = false; //do not update the integration system with the integration unique ID since it was already included when the new opp was created in the integration system integration_insert_integration_new_opp(), without it the new opp could not be created, and don't want to update with the same value twice
    OpenCaptureMobx.a_details_update_field_value(c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined, newIntegrationOppUniqueIDString, i_newCaptureID, k_cardIDUserCreateNewCapture, sendNotificationTF, updateIntegrationSystemLinkedFieldTF, undefined, undefined);
  }



  create_new_integration_unique_id_string_from_new_ce_capture_id(i_newCaptureID, i_newCECaptureDivisionIDsColonPercentsCommaOrUndefined=undefined) {
    const c_tbl_a_divisions = DatabaseMobx.c_tbl_a_divisions;

    //initialize the integration unique ID prefix string as empty
    var integrationUniqueIDPrefix = "";

    //sort the raw value of sharedpercent colon comma list divisionIDs and their percents, and get the one with the largest percent ownership to use as the integration unique ID prefix (if filled out in tbl_a_divisions for that divisionID)
    if(JSFUNC.is_string(i_newCECaptureDivisionIDsColonPercentsCommaOrUndefined)) {
      const newCECaptureHighestPercentDivisionIntsObjOrUndefined = JSFUNC.get_first_ints_obj_or_undefined_from_colon_comma_list_after_sorting(i_newCECaptureDivisionIDsColonPercentsCommaOrUndefined, 2, false);
      if(newCECaptureHighestPercentDivisionIntsObjOrUndefined !== undefined) {
        const newCECaptureHighestPercentDivisionID = newCECaptureHighestPercentDivisionIntsObjOrUndefined.int1;
        const newCECaptureHighestExpandedDivisionMapOrUndefined = c_tbl_a_divisions.get(newCECaptureHighestPercentDivisionID);
        if(newCECaptureHighestExpandedDivisionMapOrUndefined !== undefined) {
          if(newCECaptureHighestExpandedDivisionMapOrUndefined.get("calcIntegrationPrefixIsFilledOutTF")) {
            integrationUniqueIDPrefix = newCECaptureHighestExpandedDivisionMapOrUndefined.get("integration_new_capture_integration_unique_id_prefix");
          }
        }
      }
    }

    //build string "CE1234" or "PREFIX-CE1234"
    var newIntegrationUniqueIDString = "";
    if(integrationUniqueIDPrefix !== "") {
      newIntegrationUniqueIDString += integrationUniqueIDPrefix + "-";
    }
    newIntegrationUniqueIDString += "CE" + i_newCaptureID;

    return(newIntegrationUniqueIDString);
  }


}
export default new AdminIntegrationsMobx();
