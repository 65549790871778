//***************************************************************
// CaptureExec v2.0 - React (ES6 JSX) + MobX (MobX React) + PHP + MYSQL database
//
// Development Environment:
//  - Atom (IDE that handles React syntax)     https://atom.io/
//    - Install Packages for code writing: Atom -> File -> Settings -> Install+ -> search for and install:
//      - nuclide   (Atom extension for React language development)  https://nuclide.io
//      - highlight-selected
//      - language-babel   (community created JSX language that translated to JS, arrow functions, decorators, etc) language integration https://atom.io/packages/language-babel    https://github.com/gandm/language-babel#installation
//    - Settings -> Editor
//      - preferred line length 500
//      - soft wrap at preferred line length
//      - uncheck zoom with ctrl
//  - Node.js (java runtime environment)    https://nodejs.org
//    - npm/npx (ecosystem of open source libraries, comes with Node install)    https://www.npmjs.com/
//  - AWS server running PHP7 and a MYSQL database with phpMyAdmin setup to edit them visually
//
// Code library requirements:
//  - create-react-app (React language with example App js files)  https://github.com/facebook/create-react-app   https://reactjs.org/docs
//  - MobX (State Management, Data Storage)   https://mobx.js.org/
//  - enquire.js (Media Query for screen width)   http://wicky.nillia.ms/enquire.js
//  - Stripe (credit card handling service)   https://stripe.com/
// Other libraries not being used:
//  - React Router (address routing)    https://www.sitepoint.com/react-router-v4-complete-guide/
//  - CKEditor 5 (Rich Textarea Editing)   https://docs.ckeditor.com/ckeditor5
//
//  Open cmd.exe, navigate to a folder where this new react project folder will be created
//  1. npm install -g create-react-app   (installs create-react-app through npm, includes installation of latest react-dom and react)
//  2. cd into folder where new project 'captureexecreact' will be created (don't need to create captureexecreact folder)
//    npx create-react-app captureexecreact   (create new react app project folder)
//    [running the below npm install commands loads the code .js and minified code min.js into a new folder in the node_modules subfolder]
//    [npm build on the create-react-app bundles/compiles these node_modules]
//  3. cd to the captureexecreact folder in cmd before running these install commands
//    3a. npm install --save mobx
//    3b. npm install --save mobx-react
//        enable decorators by transpiling babel manually: https://mobx.js.org/best/decorators.html    http://babeljs.io/docs/plugins/transform-decorators    http://technologyadvice.github.io/es7-decorators-babel6/    https://github.com/timarney/react-app-rewired/tree/master/packages/react-app-rewire-mobx
//    3c. npm install --save enquire.js
//    3d. npm install --save @stripe/react-stripe-js @stripe/stripe-js
//    3?. npm install --save react-router react-router-dom
//    3?. npm install --save @ckeditor/ckeditor5-build-classic
//  4. open captureexecreact/package.json
//    4a. add   "homepage": ".",   between private and dependencies lines
//  5. edit captureexecreact/public/ files
//    5a. delete favicon.ico, logo192.png, logo512.png and replace it with a small clear background .png for the browser favorite icon
//    5b. index.html - change <link rel="icon" href="%PUBLIC_URL%/NEWLOGO.PNG" />, <meta name="description" content="NEW DESCRIPTION" />, <link rel="apple-touch-icon" href="%PUBLIC_URL%/NEWLOGO.PNG" />, <title>NEW TITLE</title>
//    5c. manifest.json - change "short_name", "name", "icons"
//  6. in captureexecreact/src/, modify index.js for the new project, change App.js and the other App files using new React style to fit new site
//  7. create npmstart.bat
//    cd ..
//    cd Documents\captureexecreact
//    npm start
//  8. create npmrunbuild.bat
//    cd ..
//    cd Documents\captureexecreact
//    npm run build
//***************************************************************
//
//  --------Variable Name Syntax--------
//  javascript functions (all lowercase with underscores)
//      function_name() - name of function in all lowercase with underscore separation
//      i_inputName - function input variable passed as an argument
//      outputName - variable created in the function that will eventually be in the return() to be returned
//
//      function multiply_two_inputs(i_inputName1, i_inputName2) {
//        var output1 = i_inputName1 * i_inputName2;
//        return(output1);
//      }
//
//  javascript classes
//      ClassClassName - name of class with prefix "Class"
//      C_ClassInstance - instance of a class ("C_" prefix and capital first letter)    const C_ClassInstance = new ClassClassName(i_input1);
//
//
//  javascript data variable naming (camelCase starting with lowercase letter)
//      data - usually an int, sometimes a string or a number
//      dataTF - boolean value either true or false
//      dataObj - js object with property:"value" pairs  {property1:"value1", property2:22, property3:"3"}
//      dataArray - js array of strings or ints   [1,2,7,5] or ["hey", "there", {key:"val"}]
//      dataComma/dataCommaList - string of ints separated by commas with no spaces  "1,2,7,5"
//      dataColonCommaList - string of pairs of int1:int2 separated by commas with no spaces  "1:11,2:22,7:99,5:1"
//      dataArrayOfObjs - array of similar js objects that all have the same properties  [{id:1, name:"one"}, {id:2, name:"two"}, {id:3, name:"three"}] (sometimes called "Matrix")
//      dataMap - js Map() that holds key-value pairs   dataMap1 = new Map(); dataMap1.set("id", 1); dataMap1.set("name", "one");    dataMap1.get("id") returns 1
//      dataMapOfMaps - get Maps holding multiple similar Map() instances, usually keyed by "id" value of the map that it is holding   dataMapOfMaps = new Map(); dataMapOfMaps.set(1, dataMap1); dataMapOfMaps.set(2, dataMap2);    dataMapOfMaps.get(1) returns dataMap1
//
//
//  React Components
//      FileName_React.js - file name containing javascript code of multiple react components
//      ComponentName - name of react component in camel case with first letter uppercase
//      p_propName - prop passed into a react component
//      f_propFunctionName - function passed as a prop into a react component
//      s_stateVariableName - name of react component internal this.state variable
//      component_function_name() - function defined inside a component
//
//      <ComponentName p_prop1="stringValue" p_prop2={variableValue} f_onClick={this.onclick_button} />
//
//
//  MobX Stores
//      FileName_Mobx.js - name of the MobX store class containing observable, computed, and action definitions.  "export default new FileName_Mobx();" is at the bottom to be passed through <Provider> to the react components
//      o_observableValue1 - value decorated as observable [initialized as constants, modified by action functions]
//      c_computedValue1 - value decorated as computed [derived only from observable values and constants, no inputs]
//      a_action_function_name - function decorated as action [function that takes inputs, changes observable values, no outputs]
//      regular_function_name - undecorated function [takes inputs, can use observable values as read only data, returns an output]
//
//***************************************************************

//React imports
import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';

//MobX imports
import { configure } from 'mobx';
import { Provider } from 'mobx-react';

//css sheet for entire system
//  - defines default behavior for all html primitive tag types (div, input)
//  - defines basic classes used throughout for colors, font, hover, flex positioning, standard margins/padding (micro, small, med, big, huge)
//  - react divs use these classes while also defining their style (mostly borders and background)  <div className="class1 class2" style={{property:"value"}} />
//  - some standard colors are defined in JSFUNC as functions with the prefix "color_"
import './Library/LibraryReactCss.css';
import './index.css';

//main react component CaptureExec for entire system (login process and logged in system)
import CaptureExec from './CaptureExecReactMobxPairs/CaptureExec/CaptureExecReact.js';

//php interface functions - used here to catch the window errors and send them to the z_errors tbl in the database
import * as JSPHP from './CaptureExecLocalDatabaseMobx/JSPHP.js';

//mobx stores - control the state for React (passed through all components using mobx <Provider>)
// - @observable: data fields used to inject data into components (assign/manipulate/return these values in the computed/action methods by calling this.observableField)
// - @computed: methods for returning computed values using only the observable data fields
// - @action: methods to call from component click/change events that manipulate state observable data fields
//mobx - mark each component that renders using store data as observer and inject listed data stores to use them   @inject("StoreData1", "StoreData2") @observer...
import AdminMobx from './CaptureExecReactMobxPairs/Admin/AdminMobx.js';
import AdminImportMobx from './CaptureExecReactMobxPairs/AdminImport/AdminImportMobx.js';
import AdminIntegrationsMobx from './CaptureExecReactMobxPairs/AdminIntegrations/AdminIntegrationsMobx.js';
import BudgetMobx from './CaptureExecReactMobxPairs/Budget/BudgetMobx.js';
import CapturesMobx from './CaptureExecReactMobxPairs/Captures/CapturesMobx.js';
import CaptureExecMobx from './CaptureExecReactMobxPairs/CaptureExec/CaptureExecMobx.js';
import ContactsMobx from './CaptureExecReactMobxPairs/Contacts/ContactsMobx.js';
import DivexecMobx from './CaptureExecReactMobxPairs/Divexec/DivexecMobx.js';
import GCSSMobx from './CaptureExecReactMobxPairs/GCSS/GCSSMobx.js';
import OpenCaptureMobx from './CaptureExecReactMobxPairs/OpenCapture/OpenCaptureMobx.js';
import RightPanelMobx from './CaptureExecReactMobxPairs/RightPanel/RightPanelMobx.js';
import SamGovTrackerMobx from './CaptureExecReactMobxPairs/SamGovTracker/SamGovTrackerMobx.js';
import TeammateContractsMobx from './CaptureExecReactMobxPairs/TeammateContracts/TeammateContractsMobx.js';

import DatabaseMobx from './CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from './CaptureExecLocalDatabaseMobx/UserMobx.js';
import DevelopmentInitializeDataMobx from './CaptureExecLocalDatabaseMobx/DevelopmentInitializeDataMobx.js';


//MobX configuration for enforcement of better code writing practices https://mobx.js.org/configuration.html
configure({
  enforceActions: "always", //The goal of enforceActions is that you don't forget to wrap event handlers in action. "always" - State always needs to be changed through actions, which in practice also includes creation.
  computedRequiresReaction: true, //Forbids the direct access of any unobserved computed value from outside an action or reaction. This guarantees you aren't using computed values in a way where MobX won't cache them.
  reactionRequiresObservable: true, //Warns when a reaction (e.g. autorun) is created without accessing any observables. Use this to check whether you are unnecessarily wrapping React components with observer, wrapping functions with action, or find cases where you simply forgot to make some data structures or properties observable.
  observableRequiresReaction: true, //Warns about any unobserved observable access. Use this if you want to check whether you are using observables without a "MobX context".
  disableErrorBoundaries: true //By default, MobX will catch and re-throw exceptions happening in your code to make sure that a reaction in one exception does not prevent the scheduled execution of other, possibly unrelated, reactions. This means exceptions are not propagated back to the original causing code and therefore you won't be able to catch them using try/catch.
});

//MobX - Use spec compliant transpilation for class properties
// - Warning: When using MobX with TypeScript or Babel, and you plan to use classes; make sure to update your configuration to use a TC-39 spec compliant transpilation for class fields, since this is not the default. Without this, class fields cannot be made observable before they are initialized.
// - TypeScript: Set the compiler option "useDefineForClassFields": true., Babel: Make sure to use at least version 7.12, with the following configuration:
// - For verification insert this piece of code at the beginning of your sources (eg. index.js) (will show completely blank (gray) screen if it throws this Error, which can be seen in F12 menu)
//if (!new class { x }().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly');

//catch all for javascript errors that would show as red text in the chrome F12 "console" tab, send these errors to the BIT Solutions z_errors database
window.onerror = (i_message, i_source, i_lineNumber, i_columnNumber, i_errorObj) => {
  const jsDescription = "index.js - window.onerror()";
  const errorMessage = "Message: " + i_message + "\r\nSource: " + i_source + "\r\nLine Number: " + i_lineNumber + "\r\nColumn Number: " + i_columnNumber + "\r\nError: " + i_errorObj.stack;
  JSPHP.record_z_error(jsDescription, errorMessage);
  return(false); //returning true will prevent the firing of the default handler, and returning false will let the default handler run
};

//combine all Mobx Stores used in system into single obj to pass as multiple inputs to <Provider>
const mobxStoresObj = {AdminMobx, AdminImportMobx, AdminIntegrationsMobx, BudgetMobx, CapturesMobx, CaptureExecMobx, ContactsMobx, DivexecMobx, GCSSMobx, OpenCaptureMobx, RightPanelMobx, SamGovTrackerMobx, TeammateContractsMobx, DatabaseMobx, UserMobx, DevelopmentInitializeDataMobx};

//replace the <div> root in index.html with the CaptureExec react code
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider {...mobxStoresObj}>
      <CaptureExec />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
