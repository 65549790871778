import { makeObservable, observable, computed, action } from 'mobx';
import enquire from 'enquire.js'; //live updating media query in js sets this.o_mediaQueryFlag based on the screen width http://wicky.nillia.ms/enquire.js

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";
import DevelopmentInitializeDataMobx from '../../CaptureExecLocalDatabaseMobx/DevelopmentInitializeDataMobx.js';

import CapturesMobx from '../Captures/CapturesMobx.js';
import ContactsMobx from '../Contacts/ContactsMobx.js';
import OpenCaptureMobx from '../OpenCapture/OpenCaptureMobx.js';
import RightPanelMobx from '../RightPanel/RightPanelMobx.js';
import TeammateContractsMobx from '../TeammateContracts/TeammateContractsMobx.js';

export class CaptureExecMobx {
  //===constant fields===========================================================================================
  k_userBrowserDevCodeVersion = "2024.Q4.6.1"; //[year].Q[quarter release 1,2,3,4].[live push increment].[dev push increment]
  k_userBrowserLiveCodeVersion = "2024.Q4.6"; //[year].Q[quarter release 1,2,3,4].[live push increment]

  //on premise flags (set to false for cloud customers)
  k_onPremiseCustomerTF = false; //false
  k_onPremiseCustomerCheckAuthenticationQuarterlyPasscodeFileTF = false; //false
  k_onPremiseBitCompanyDomainsDotExtsArray = ["bitsolutionsllc.com"];
  k_onPremiseBitProduct = undefined; //this value is not needed for cloud customers (product is determined by website address url)
  k_onPremiseBitNumLicensesPurchased = 999;
  k_onPremiseBitLicenseStartDate = "2012-07-12";
  k_onPremiseBitLicenseNumMonths = 999;
  k_onPremiseBitSuperAdminsMaxNum = -1;
  k_onPremiseBitSuperAdminsLicenseCost = 2;
  k_onPremiseBitConsultantsLicenseCost = 0;
  k_onPremiseBitReadOnlyLicenseCost = 0.5;
  k_onPremiseBitGovConSmartSearchTF = false;
  k_onPremiseBitCexaiTF = false;
  k_onPremiseBitIdiqRapidResponseTF = true;
  k_onPremiseBitSingleCaptureDirectAccessLinksTF = false;
  k_onPremiseBitIntegrationSystemVantagePointTF = false;
  k_onPremiseBitSGTDocumentsCardUpgrade01TF = false;
  k_onPremiseBitSGTStripeCustomerID = undefined; //on prem customers can't have SGT

  /*
  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  //On Premise Customers (edit CaptureExecMobx.js, general_functions.php, CETeammateSurveyMobx.js)
  k_onPremiseCustomerTF = true;
  k_onPremiseCustomerCheckAuthenticationQuarterlyPasscodeFileTF = true;
  k_onPremiseBitCompanyDomainsDotExtsArray = ["amewas.com"];
  k_onPremiseBitProduct = "captureexec"; //"captureexec", "samgovtracker"
  k_onPremiseBitNumLicensesPurchased = 5;
  k_onPremiseBitLicenseStartDate = "2024-07-01";
  k_onPremiseBitLicenseNumMonths = 36;
  k_onPremiseBitSuperAdminsMaxNum = -1;
  k_onPremiseBitSuperAdminsLicenseCost = 2;
  k_onPremiseBitConsultantsLicenseCost = 1;
  k_onPremiseBitReadOnlyLicenseCost = 0.5;
  k_onPremiseBitGovConSmartSearchTF = true;
  k_onPremiseBitCexaiTF = true;
  k_onPremiseBitIdiqRapidResponseTF = true;
  k_onPremiseBitSingleCaptureDirectAccessLinksTF = true;
  k_onPremiseBitIntegrationSystemVantagePointTF = false;
  k_onPremiseBitSGTDocumentsCardUpgrade01TF = false;
  k_onPremiseBitSGTStripeCustomerID = undefined;
  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  */


  //===observable fields===========================================================================================
  //enquire.js media querys for screen width
  o_mediaQueryFlag = 4; //represents the number of cards that fit in 1 horizontal row on the capture screen
  o_mediaQuery1 = undefined; //the query boundaries change as the user font size is changed, so these are updated and the enquire is recalculated
  o_mediaQuery2 = undefined;
  o_mediaQuery3 = undefined;
  o_mediaQuery4 = undefined;
  o_mediaQuery5 = undefined;
  o_mediaQuery6 = undefined;

  //current date in Y-m-d format in local memory, kept updated
  o_nowDate = JSFUNC.now_date();

  //supplemental error visuals for development mode
  o_errorMessage = null; //null is a flag that no error message should be displayed, otherwise display the message in this variable which can be turned off by clicking the error

  //php interface
  o_phpFileIsCurrentlyRunningTF = false; //controls loading animation in CaptureExec.js
  o_refreshIsRunningTF = false; //set to true while fetch to database to refresh admin tables and individual captures from tbl_a_users_per_email is running, draws indicator on debug loading tables panel
  o_loadingThroughPhase1CompleteTF = false; //set to true when Phase 1 database data has been successfully fetched and loaded
  o_loadingThroughPhase2CompleteTF = false; //set to true when Phase 2 database data has been successfully fetched and loaded
  o_loadTimingLoginStartTimestamp = undefined;
  o_loadTimingLoginEndTimestamp = undefined;
  o_loadTimingPhase1StartTimestamp = undefined;
  o_loadTimingPhase1EndTimestamp = undefined;
  o_loadTimingPhase2StartTimestamp = undefined;
  o_loadTimingPhase2EndTimestamp = undefined;

  //website address url (info set immediately upon system initialization of login page display)
  o_initialFullWebsiteAddress = false; //https://captureexec.com/, https://captureexec.com/dev/, https://samgovtracker.com/, http://localhost:3000/, [On-Premise] https://captureexec.amewas.com
  o_isLocalhost3000SystemTF = false; //web address is http://localhost:3000/ for local react development using npm start
  o_isDevSystemTF = false; //web address is https://captureexec.com/dev/ (no /dev/ version for samgovtracker.com server)

  //Login
  o_verifyOnPremiseAuthenticationTimedIntervalID = null;
  o_verifyUserBrowserLiveOrDevCodeVersionTimedIntervalID = null;
  o_overrideCodeVersionMismatchTF = false;
  o_captureExecInSingleCaptureDirectAccessModeTF = false; //set to true if verification of single capture direct access link from url is a match, which bypasses login and restricts the CaptureExec system to have no navigation and only have the single capture open
  o_loginState = "out"; //"out", "sgtBuySgtEnterInfo", "sgtCaptureExecLearnMoreAd", "loadingCaptureExec", "onPremiseNotAuthenticated", "codeVersionMismatch", "licenseExpired", "adminPasswordChange", "forcedPasswordChange", "multiLogin", "in", "codeUpdate"
  o_companyCodeInput = "";
  o_emailInput = "";
  o_passwordInput = "";
  o_companyCodeInputErrorTF = false;
  o_emailInputErrorTF = false;
  o_loginMessageObj = null;
  o_loggingInUserEmail = ""; //store the email of the user that is moving through the login process to show it on the multilogin screen (o_emailInput gets cleared)

  //change password module process state controls
  o_oldPasswordInput = "";
  o_newPassword1Input = "";
  o_newPassword2Input = "";
  o_oldPasswordInputErrorTF = false;
  o_newPassword1InputErrorTF = false;
  o_newPassword2InputErrorTF = false;
  o_changePasswordMessageObj = null;

  //TopBar
  o_searchCapturesBoxIsOpenTF = false;
  o_searchCapturesInputText = "";
  o_searchSelectedSearchTypeTab = "captures"; //"captures", "documents"
  o_searchDocumentsIsLoadingDataTFU = false; //false - not loading, true - loading, undefined - tried to load and failed
  o_searchDocumentsDataObj = undefined;
  o_searchDocumentsDrawAllDocsTF = false;
  o_searchCapturesExactTF = false;

  //leftNav
  o_leftNavIsExpandedTF = false;
  o_leftNavTabNameSelected = "";

  //item currently being edited on any given capture card or right panel item
  o_itemEditingCaptureDashCardDashItemID = undefined;
  o_itemEditingContactFieldUniqueString = undefined; //similar to how o_itemEditingCaptureDashCardDashItemID works for all capture card fields, separate for the contacts system right side pencil edit fields

  //view specified captures in a floating box (admin/divexec graphics and tools can populate this to bring up the subset of captures to further explore)
  o_viewSpecifiedSearchResultCapturesInFloatingBoxCaptureIDsArray = undefined;
  o_viewSpecifiedSearchResultCapturesInFloatingBoxTitle = undefined;

  //drag/drop system wide variables holding information about the item currently being dragged
  o_anyFileOrCEItemDraggedOverAnyPartOfWebsiteTF = false;
  o_ceDraggedItemObjOrUndefined = undefined;
  o_cePreviouslyDraggedItemObjOrUndefined = undefined; //copy of current o_ceDraggedItemObjOrUndefined, only difference is it's not cleared when the dragged stops being dragged, some pieces need this data after the drop
  o_ceDraggedItemCurrentlyOverDropZoneObjOrUndefined = undefined; //if an item is currently being dragged, this is updated for each unique dropzone (uniqueString and itemID) that the item is dragged over top of at that moment

  //filefoldersystem system wide variable that initializes a ffs into a specified subfolder by the folder row id number, the FFS then resets this variable back to -1 when it initilizes
  o_navigateToFolderIDTblCDocumentsFileFolderSystem = undefined;
  o_navigateToFolderIDTblCTeammatesContractsFileFolderSystem = undefined;
  o_navigateToFolderIDTblGContactsFileFolderSystem = undefined;
  o_navigateToFolderIDTblGGeneralDocumentsFileFolderSystem = undefined;

  //My Surveys subtab
  o_mySurveysLoadingDataTF = false;
  o_mySurveysSelectedTabDbName = "allMySentSurveys"; //"allMySentSurveys", "mySentSurveysGroupedbyIDIQ"
  o_mySurveysTblCTeammatesSurveysArrayOfObjs = [];
  o_mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs = [];
  o_mySurveysCurrentViewSurveyStartIndex = 0;
  o_mySurveysSelectedFilterTeammateContactCompanyID = -1;
  o_mySurveysSelectedFilterIDIQCaptureID = -1;

  //Archived Captures Warning bottom bar
  o_archivedCapturesWarningBottomBarUserHasDismissedWarningTF = false;

  constructor() {
    makeObservable(this, {
      o_mediaQueryFlag: observable,
      o_mediaQuery1: observable,
      o_mediaQuery2: observable,
      o_mediaQuery3: observable,
      o_mediaQuery4: observable,
      o_mediaQuery5: observable,
      o_mediaQuery6: observable,
      o_nowDate: observable,
      o_errorMessage: observable,
      o_phpFileIsCurrentlyRunningTF: observable,
      o_refreshIsRunningTF: observable,
      o_loadingThroughPhase1CompleteTF: observable,
      o_loadingThroughPhase2CompleteTF: observable,
      o_loadTimingLoginStartTimestamp: observable,
      o_loadTimingLoginEndTimestamp: observable,
      o_loadTimingPhase1StartTimestamp: observable,
      o_loadTimingPhase1EndTimestamp: observable,
      o_loadTimingPhase2StartTimestamp: observable,
      o_loadTimingPhase2EndTimestamp: observable,
      o_isLocalhost3000SystemTF: observable,
      o_isDevSystemTF: observable,
      o_initialFullWebsiteAddress: observable,
      o_verifyOnPremiseAuthenticationTimedIntervalID: observable,
      o_verifyUserBrowserLiveOrDevCodeVersionTimedIntervalID: observable,
      o_overrideCodeVersionMismatchTF: observable,
      o_captureExecInSingleCaptureDirectAccessModeTF: observable,
      o_loginState: observable,
      o_companyCodeInput: observable,
      o_emailInput: observable,
      o_passwordInput: observable,
      o_companyCodeInputErrorTF: observable,
      o_emailInputErrorTF: observable,
      o_loginMessageObj: observable,
      o_loggingInUserEmail: observable,
      o_oldPasswordInput: observable,
      o_newPassword1Input: observable,
      o_newPassword2Input: observable,
      o_oldPasswordInputErrorTF: observable,
      o_newPassword1InputErrorTF: observable,
      o_newPassword2InputErrorTF: observable,
      o_changePasswordMessageObj: observable,
      o_searchCapturesBoxIsOpenTF: observable,
      o_searchCapturesInputText: observable,
      o_searchSelectedSearchTypeTab: observable,
      o_searchDocumentsIsLoadingDataTFU: observable,
      o_searchDocumentsDataObj: observable,
      o_searchDocumentsDrawAllDocsTF: observable,
      o_searchCapturesExactTF: observable,
      o_leftNavIsExpandedTF: observable,
      o_leftNavTabNameSelected: observable,
      o_itemEditingCaptureDashCardDashItemID: observable,
      o_itemEditingContactFieldUniqueString: observable,
      o_viewSpecifiedSearchResultCapturesInFloatingBoxCaptureIDsArray: observable,
      o_viewSpecifiedSearchResultCapturesInFloatingBoxTitle: observable,
      o_anyFileOrCEItemDraggedOverAnyPartOfWebsiteTF: observable,
      o_ceDraggedItemObjOrUndefined: observable,
      o_cePreviouslyDraggedItemObjOrUndefined: observable,
      o_ceDraggedItemCurrentlyOverDropZoneObjOrUndefined: observable,
      o_navigateToFolderIDTblCDocumentsFileFolderSystem: observable,
      o_navigateToFolderIDTblCTeammatesContractsFileFolderSystem: observable,
      o_navigateToFolderIDTblGContactsFileFolderSystem: observable,
      o_navigateToFolderIDTblGGeneralDocumentsFileFolderSystem: observable,
      o_mySurveysLoadingDataTF: observable,
      o_mySurveysSelectedTabDbName: observable,
      o_mySurveysTblCTeammatesSurveysArrayOfObjs: observable,
      o_mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs: observable,
      o_mySurveysCurrentViewSurveyStartIndex: observable,
      o_mySurveysSelectedFilterTeammateContactCompanyID: observable,
      o_mySurveysSelectedFilterIDIQCaptureID: observable,
      o_archivedCapturesWarningBottomBarUserHasDismissedWarningTF: observable,

      c_isMobileTF: computed,
      c_isTabletTF: computed,
      c_isMobileOrTabletTF: computed,
      c_productStringFromInitialFullWebsiteAddressOrOnPremiseProduct: computed,
      c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined: computed,
      c_productWebsiteAddressEndingSlashRemovedLiveOrDevOrUndefined: computed,
      c_productWebsiteGraphicsSubfolderAddress: computed,
      c_productIsCaptureExecTF: computed,
      c_productIsSamGovTrackerTF: computed,
      c_productStylingObj: computed,
      c_nowYearInt: computed,
      c_userIsLoggedIntoCaptureExecSystemTF: computed,
      c_topBarHeight: computed,
      c_searchFilteredCapturesFromSearchTermArrayOfObjs: computed,
      c_searchAllDocumentCategoriesArrayOfObjs: computed,
      c_searchFilteredDocumentCategoriesArrayOfObjs: computed,
      c_searchDocumentsTotalNumFilteredDocs: computed,
      c_searchDocumentsDisplayShowAllDocsButtonTF: computed,
      c_leftNavWidth: computed,
      c_leftNavRightEdge: computed,
      c_leftNavGeneralTabsArrayOfObjs: computed,
      c_leftNavExecutiveManagementTabWithSubtabsObj: computed,
      c_leftNavUserActivityTabObj: computed,
      c_leftNavCaptureImportTabObj: computed,
      c_leftNavContractsWorkloadTabObj: computed,
      c_leftNavContractsTodoTabObj: computed,
      c_leftNavBudgetsWorkloadTabObj: computed,
      c_leftNavBudgetsTodoTabObj: computed,
      c_leftNavSystemSetupTabWithSubtabsObj: computed,
      c_leftNavUpgradeToCaptureExecTabObj: computed,
      c_leftNavUserTabsArrayOfObjs: computed,
      c_leftNavSelectStartTabFieldTypeObj: computed,
      c_selectedLeftNavIsCaptureTableViewTF: computed,
      c_selectedLeftNavIsStageViewTF: computed,
      c_selectedLeftNavIsPriorityViewTF: computed,
      c_selectedLeftNavIsGanttChartViewTF: computed,
      c_selectedLeftNavIsProgressChartViewTF: computed,
      c_viewSpecifiedSearchResultsCapturesInFloatingBoxArrayOfObjs: computed,
      c_computerFileIsCurrentlyBeingDraggedAcrossWebsiteTF: computed,
      c_mySurveysAllMySentSurveysArrayOfObjs: computed,
      c_mySurveysSelectTeammateFieldTypeObj: computed,
      c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs: computed,
      c_mySurveysUniqueIDIQsArrayOfObjs: computed,
      c_mySurveysSelectedSingleIDIQSurveysFilteredBySelectedTeammateObj: computed,
      c_mySurveysSelectIDIQCaptureFieldTypeObj: computed,

      a_set_media_query_flag: action,
      a_set_enquire_media_width_from_font_size_and_right_panel: action,
      a_update_now_date_and_now_datetime_to_current_date: action,
      a_set_error_message: action,
      a_close_error_message: action,
      a_set_php_file_is_currently_running_tf: action,
      a_set_initial_full_website_address: action,
      a_set_is_localhost_3000_system_tf: action,
      a_set_is_dev_system_tf: action,
      a_run_verifications_to_initialize_login_from_url: action,
      a_verify_on_premise_customer_authentication_quarterly_passcode_file: action,
      a_start_verify_on_premise_authentication_timed_interval: action,
      a_clear_verify_on_premise_authentication_timed_interval: action,
      a_verify_user_browser_live_or_dev_code_version: action,
      a_start_verify_user_browser_live_or_dev_code_version_timed_interval: action,
      a_clear_verify_user_browser_live_or_dev_code_version_timed_interval: action,
      a_set_override_code_version_mismatch_tf: action,
      a_verify_single_capture_direct_access_link_to_bypass_login_from_url: action,
      a_set_captureexec_in_single_capture_direct_access_mode_tf: action,
      a_set_login_state: action,
      a_set_company_code_input: action,
      a_set_email_input: action,
      a_set_password_input: action,
      a_set_company_code_input_error_tf: action,
      a_set_email_input_error_tf: action,
      a_set_login_message_obj: action,
      a_set_logging_in_user_email: action,
      a_set_old_password_input: action,
      a_set_new_password1_input: action,
      a_set_new_password2_input: action,
      a_set_old_password_input_error_tf: action,
      a_set_new_password1_input_error_tf: action,
      a_set_new_password2_input_error_tf: action,
      a_set_change_password_message_obj: action,
      a_change_password_full_reset: action,
      a_logout: action,
      a_login_or_forgot_password: action,
      a_after_login_verify_license_set_mobx_data_change_login_state: action,
      a_change_password: action,
      a_multi_login_selection_click: action,
      a_update_local_user_num_logins_and_last_login_datetime: action,
      a_set_refresh_is_running_tf: action,
      a_set_loading_through_phase1_complete_tf: action,
      a_set_loading_through_phase2_complete_tf: action,
      a_set_load_timing_login_start_timestamp: action,
      a_set_load_timing_login_end_timestamp: action,
      a_set_load_timing_phase1_start_timestamp: action,
      a_set_load_timing_phase1_end_timestamp: action,
      a_set_load_timing_phase2_start_timestamp: action,
      a_set_load_timing_phase2_end_timestamp: action,
      a_load_phase1_phase2_data_into_local_memory: action,
      a_set_search_captures_input_text: action,
      a_set_search_captures_box_is_open_tf: action,
      a_set_search_selected_search_type_tab: action,
      a_set_search_documents_is_loading_data_tfu: action,
      a_search_load_thin_search_all_documents_data: action,
      a_set_search_documents_data_obj: action,
      a_set_search_documents_draw_all_docs_tf: action,
      a_search_documents_navigate_to_document_ffs_location_in_system: action,
      a_set_search_captures_exact_tf: action,
      a_set_left_nav_is_expanded_tf: action,
      a_left_nav_initialize_left_nav_is_expanded_tf: action,
      a_left_nav_expand_collapse_toggle: action,
      a_left_nav_select_tab: action,
      a_set_left_nav_to_default_tab: action,
      a_set_item_editing_capture_dash_card_dash_item_id: action,
      a_close_item_editing: action,
      a_set_view_specified_search_results_captures_in_floating_box_capture_ids_array_and_floating_box_title: action,
      a_set_any_file_or_ce_item_dragged_over_any_part_of_website_tf: action,
      a_set_captureexec_dragged_item_obj: action,
      a_unset_captureexec_dragged_item_obj: action,
      a_set_captureexec_dragged_item_currently_over_dropzone_obj: action,
      a_unset_captureexec_dragged_item_currently_over_dropzone_obj: action,
      a_set_navigate_to_folder_id_tbl_c_documents_filefoldersystem: action,
      a_set_navigate_to_folder_id_tbl_c_teammates_contracts_filefoldersystem: action,
      a_set_navigate_to_folder_id_tbl_g_contacts_filefoldersystem: action,
      a_set_navigate_to_folder_id_tbl_g_general_documents_filefoldersystem: action,
      a_set_my_surveys_loading_data_tf: action,
      a_set_my_surveys_selected_tab_db_name: action,
      a_set_my_surveys_tbl_c_teammates_surveys_arrayOfObjs: action,
      a_set_my_surveys_tbl_c_teammates_surveys_response_times_arrayOfObjs: action,
      a_set_my_surveys_current_view_survey_start_index: action,
      a_set_my_surveys_selected_filter_teammate_contact_company_id: action,
      a_set_my_surveys_selected_filter_idiq_capture_id: action,
      a_my_surveys_load_survey_data: action,
      a_set_archived_captures_warning_bottom_bar_user_has_dismissed_warning_tf: action
    });
  }



  //===computed fields============================================================================================
  //mobile layout represented by o_mediaQueryFlag value 1 or 2
  get c_isMobileTF() {
    return(this.o_mediaQueryFlag === 1);
  }

  get c_isTabletTF() {
    return(this.o_mediaQueryFlag === 2);
  }

  get c_isMobileOrTabletTF() {
    return((this.o_mediaQueryFlag === 1) || (this.o_mediaQueryFlag === 2));
  }


  //computed variations based on website address and/or product specified
  get c_productStringFromInitialFullWebsiteAddressOrOnPremiseProduct() { //product string is either "captureexec" or "samgovtracker"
    return(this.compute_product_string_from_initial_full_website_address_or_on_premise_product(this.k_onPremiseCustomerTF, this.k_onPremiseBitProduct, this.o_initialFullWebsiteAddress, this.o_isLocalhost3000SystemTF));
  }

  compute_product_string_from_initial_full_website_address_or_on_premise_product(i_onPremiseCustomerTF, i_onPremiseBitProduct, i_initialFullWebsiteAddress, i_isLocalhost3000SystemTF) {
    //if this is an on-premise customer, force the product to be the hardcoded value in this file (as opposed to deducing the website address or using the BIT companies customer row product value in the database which they could edit)
    if(i_onPremiseCustomerTF) {
      return(i_onPremiseBitProduct);
    }

    //if this is the localhost environment, use the hardcoded dev value in the BIT companies product field
    if(i_isLocalhost3000SystemTF) {
      return(DatabaseMobx.o_bitcompaniesTblRowMap.get("product"));
    }

    //for cloud customers, determine the product string based on the initial full website address when accessing the website login screen
    if(JSFUNC.is_string(i_initialFullWebsiteAddress)) { //https://www.example.captureexec.com/dev/?gclid=123456789
      const initialFullWebsiteAddressLowercase = i_initialFullWebsiteAddress.toLowerCase();
      const doubleSlashSplitArray = initialFullWebsiteAddressLowercase.split("//"); //["https:", "www.example.captureexec.com/dev/?gclid=123456789"]
      if(doubleSlashSplitArray.length == 2) {
        const websiteDotDomainSlashSubfolders = doubleSlashSplitArray[1]; //"www.example.captureexec.com/dev/?gclid=123456789"
        const dotSplitArray = websiteDotDomainSlashSubfolders.split("."); //["www", "example", "captureexec", "com/dev/?gclid=123456789"]
        const numDotStrings = dotSplitArray.length; //4
        if(numDotStrings >= 2) {
          const productStringDotIndex = (numDotStrings - 2); //get 2nd to last string (last string is the domain plus subfolders)
          return(dotSplitArray[productStringDotIndex]); //"captureexec"
        }
      }
    }

    return("invalidfullwebsiteaddress");
  }

  get c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined() {
    const o_isLocalhost3000SystemTF = this.o_isLocalhost3000SystemTF;
    const o_initialFullWebsiteAddress = this.o_initialFullWebsiteAddress;
    const c_productStringFromInitialFullWebsiteAddressOrOnPremiseProduct = this.c_productStringFromInitialFullWebsiteAddressOrOnPremiseProduct;
    return(this.compute_product_website_address_ending_slash_and_dev_removed_or_undefined(o_initialFullWebsiteAddress, o_isLocalhost3000SystemTF, c_productStringFromInitialFullWebsiteAddressOrOnPremiseProduct));
  }

  compute_product_website_address_ending_slash_and_dev_removed_or_undefined(i_initialFullWebsiteAddress, i_isLocalhost3000SystemTF, i_productString) {
    //for localhost, use the product to generate the correct live website address to load graphics while testing
    if(i_isLocalhost3000SystemTF) {
      if(i_productString === "samgovtracker") {
        return("https://samgovtracker.com");
      }
      return("https://captureexec.com");
    }

    if(JSFUNC.is_string(i_initialFullWebsiteAddress)) { //"https://captureexec.com/dev/?gclid=123456789" (or false if first moment of login page load)
      const initialAddressNumChars = i_initialFullWebsiteAddress.length;
      if(initialAddressNumChars >= 7) { //at least as long as "http://"
        if((i_initialFullWebsiteAddress.substring(0, 7) === "http://") || (i_initialFullWebsiteAddress.substring(0, 8) === "https://")) {
          const urlQuestionSplitArray = i_initialFullWebsiteAddress.split("?"); //["https://captureexec.com/dev/", "gclid=123456789"]
          var productWebsiteAddressEndingSlashAndDevRemoved = urlQuestionSplitArray[0]; //"https://captureexec.com/dev/"

          var numChars = productWebsiteAddressEndingSlashAndDevRemoved.length;
          if(productWebsiteAddressEndingSlashAndDevRemoved.substring(numChars - 1, numChars) === "/") {
            productWebsiteAddressEndingSlashAndDevRemoved = productWebsiteAddressEndingSlashAndDevRemoved.substring(0, numChars - 1); //"https://captureexec.com/dev"
            numChars = productWebsiteAddressEndingSlashAndDevRemoved.length;
          }

          if(productWebsiteAddressEndingSlashAndDevRemoved.substring(numChars - 4, numChars) === "/dev") {
            productWebsiteAddressEndingSlashAndDevRemoved = productWebsiteAddressEndingSlashAndDevRemoved.substring(0, numChars - 4); //"https://captureexec.com"
          }

          return(productWebsiteAddressEndingSlashAndDevRemoved);
        }
      }
    }

    return(undefined);
  }

  get c_productWebsiteAddressEndingSlashRemovedLiveOrDevOrUndefined() { //accounts for live and dev version
    const c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined = this.c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined;

    if(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined !== undefined) {
      if(this.o_isDevSystemTF) {
        return(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined + "/dev"); //"https://captureexec.com/dev"
      }
      return(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined); //"https://captureexec.com"
    }
    return(undefined);
  }

  get c_productWebsiteGraphicsSubfolderAddress() {
    const c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined = this.c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined;

    if(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined !== undefined) {
      return(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined + "/graphics"); //"https://captureexec.com/graphics" or "https://samgovtracker.com/graphics"
    }
    return("https://captureexec.com/graphics");
  }


  get c_productIsCaptureExecTF() { return(this.c_productStringFromInitialFullWebsiteAddressOrOnPremiseProduct === "captureexec"); }
  get c_productIsSamGovTrackerTF() { return(this.c_productStringFromInitialFullWebsiteAddressOrOnPremiseProduct === "samgovtracker"); }

  get c_productStylingObj() {
    const c_productIsCaptureExecTF = this.c_productIsCaptureExecTF;
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    const c_bitSGTDocumentsCardUpgrade01TF = DatabaseMobx.c_bitSGTDocumentsCardUpgrade01TF;

    const leftNavMarketingDocsTabTF = (c_productIsCaptureExecTF || (c_productIsSamGovTrackerTF && c_bitSGTDocumentsCardUpgrade01TF))

    const cardAdvanceStageTF = (c_productIsCaptureExecTF || c_productIsSamGovTrackerTF);
    const cardDatesTF = (c_productIsCaptureExecTF || c_productIsSamGovTrackerTF);
    const cardTasksTF = (c_productIsCaptureExecTF || c_productIsSamGovTrackerTF);
    const cardDetailsTF = (c_productIsCaptureExecTF || c_productIsSamGovTrackerTF);
    const cardDealShapingTF = c_productIsCaptureExecTF;
    const cardTeammatesTF = c_productIsCaptureExecTF;
    const cardCompetitorsTF = c_productIsCaptureExecTF;
    const cardProposalThemesTF = c_productIsCaptureExecTF;
    const cardRiskAssessmentTF = c_productIsCaptureExecTF;
    const cardBudgetTF = c_productIsCaptureExecTF;
    const cardConversationsTF = c_productIsCaptureExecTF;
    const cardDocumentsTF = (c_productIsCaptureExecTF || (c_productIsSamGovTrackerTF && c_bitSGTDocumentsCardUpgrade01TF));
    const cardTemplatesTF = c_productIsCaptureExecTF;
    const cardIDIQTaskOrdersTF = c_productIsCaptureExecTF;
    const cardChangeLogTF = c_productIsCaptureExecTF;
    const cardNotepadTF = (c_productIsCaptureExecTF || c_productIsSamGovTrackerTF);
    const cardRevenueTF = c_productIsCaptureExecTF;

    const capturesTabProgressChartSubTabTF = c_productIsCaptureExecTF;
    const capturesTabMasterPresetEditorButtonTF = c_productIsCaptureExecTF;

    const userPanelSettingsRightPanelWidthTF = c_productIsCaptureExecTF;
    const userPanelSettingsArchivedCapturesPreferenceTF = c_productIsCaptureExecTF;
    const userPanelSettingsOpenCaptureSnapshotCardsMinHeightTF = c_productIsCaptureExecTF;
    const userPanelSettingsOpenCaptureSnapshotCardsMaxHeightTF = c_productIsCaptureExecTF;
    const userPanelSettingsDatesCardLayoutTF = c_productIsCaptureExecTF;

    const setupCompanyCanCreateDivisionsTF = c_productIsCaptureExecTF;
    const setupCompanyCanEditDivisionSettingsTF = c_productIsCaptureExecTF;

    const setupUsersUserPowerSuperAdminTF = c_productIsCaptureExecTF;
    const setupUsersUserPowerAdminTF = (c_productIsCaptureExecTF || c_productIsSamGovTrackerTF);
    const setupUsersUserPowerDivisionExecutiveTF = c_productIsCaptureExecTF;
    const setupUsersUserPowerDivisionExecutiveReadOnlyTF = c_productIsCaptureExecTF;
    const setupUsersUserPowerCaptureExecutiveTF = (c_productIsCaptureExecTF || c_productIsSamGovTrackerTF);
    const setupUsersUserPowerCaptureExecutiveReadOnlyTF = c_productIsCaptureExecTF;
    const setupUsersUserPowerCaptureConsultantTF = c_productIsCaptureExecTF;
    const setupUsersUserPowerContractsExecutiveTF = c_productIsCaptureExecTF;
    const setupUsersUserPowerContractsManagerTF = c_productIsCaptureExecTF;
    const setupUsersUserPowerBudgetExecutiveTF = c_productIsCaptureExecTF;
    const setupUsersUserPowerBudgetManagerTF = c_productIsCaptureExecTF;
    const setupUsersShowLicenseExpirationTF = c_productIsCaptureExecTF;
    const setupUsersCreateUserAltNameTF = c_productIsCaptureExecTF;
    const setupUsersCreateUserInjectsSGTMasterButtonsTF = c_productIsSamGovTrackerTF; //only SAM Gov Tracker injects new/public master buttons (plus selected gantt chart preset) for newly created users
    const setupUsersDeactivedSwitchTF = c_productIsCaptureExecTF;
    const setupUsersCreatePublicSwitchTF = c_productIsCaptureExecTF;
    const setupUsersCanAddUserPowerTF = c_productIsCaptureExecTF;
    const setupUsersReassignUserCustomCreationsTF = c_productIsCaptureExecTF;

    const openCaptureChangelogRecordChangesTF = c_productIsCaptureExecTF;

    var productName = "CaptureExec";
    var productNameAcronym = "CE"; //used in GCSS filter button for "Already in CE"
    var productShortWebAddress = "captureexec.com";
    var productAutomatedEmailFromEmailAddress = "Captureexec@bitsolutionsllc.com";
    var topBarBgClass = "bgTopBar";
    var topBarFullLogoFileNameBase = "captureexec";
    var topBarLogoIconFileNameBase = "celogo";
    var topBarFullLogoWidth = "17em";
    var topBarLogoIconWidth = "3.6em";
    var topBarSearchInputTextBgHashColor = undefined;
    var topBarCompanyNameFontClass = "font15 fontTextLighter";
    var rightPanelLauncherLabelFontClass = "font12 fontBold fontBlue";
    var leftNavBgClass = "bgLeftNavAndTabs";
    var leftNavTabHeightEm = 5;
    var leftNavTabUnselectedBgClass = "borderTB bevelBorderDarkColors bgLeftNavAndTabs hoverLeftNavTab";
    var leftNavTabUnselectedIconColor = "eee";
    var leftNavTabUnselectedFontColor = "fff";
    var leftNavTabSelectedBgClass = "borderTB bevelBorderDarkColors bgLeftNavTabSelected";
    var leftNavTabSelectedIconColor = "eee";
    var leftNavTabSelectedFontColor = "fff";
    var leftNavSubTabUnselectedBgClass = "borderTB bevelBorderDarkColors bgLeftNavSubTab hoverLeftNavSubTab";
    var leftNavSubTabUnselectedNumberColor = "ccc";
    var leftNavSubTabUnselectedFontColor = "fff";
    var leftNavSubTabSelectedBgClass = "borderTB bevelBorderDarkColors bgLeftNavSubTabSelected";
    var leftNavSubTabSelectedNumberColor = "ccc";
    var leftNavSubTabSelectedFontColor = "fff";
    var capturesTabEmptyBgClass = "bgLightGray";
    var capturesTabEmptyMessageFontClass = "font12 fontItalic fontTextLight";
    var capturesTabCstCanDeleteAllCapturesInTableTF = true;
    var capturesTabCreateNewCaptureButtonBgClass = "border bevelBorderColors borderRadius05 bgRedCapture hoverRed";
    var capturesTabSubTabButtonUnselectedBgClass = "border bevelBorderColors borderRadius05 bgLightGrayGradient hoverLighterBlueGradient";
    var capturesTabSubTabButtonUnselectedFontClass = "";
    var capturesTabSubTabButtonSelectedBgClass = "border bevelBorderColors borderRadius05 bgBlueGradient";
    var capturesTabSubTabButtonSelectedFontClass = "fontWhite";
    var capturesTabMasterButtonUnselectedBgClass = "border bevelBorderColors bgLightGrayGradient hoverLighterBlueGradient";
    var capturesTabMasterButtonUnselectedFontClass = "fontBold";
    var capturesTabMasterButtonSelectedBgClass = "border bevelBorderColors bgBlueGradient";
    var capturesTabMasterButtonSelectedFontClass = "fontBold fontWhite";
    var cstColumnHeaderRowBgClass = "bgCstColumnHeaderRowBg";
    var cstColumnHeaderBgClass = "bgCstColumnHeader";
    var cstColumnHeaderFontClass = "fontWhite";
    var cstColumnHeaderSortedPrimaryBgClass = "bgCstColumnHeaderSortedPrimary";
    var cstColumnHeaderSortedSecondaryBgClass = "bgCstColumnHeaderSortedSecondary";
    var cstColumnHeaderSortedFontClass = "";
    var cstSumRowBgClass = "bgGreenGradient";
    var cstSumRowSumColumnBgClass = "bgDarkGreenGradient";
    var openCaptureCanArchiveCaptureTF = true;
    var openCaptureTopBarBgClass = "bgRedCapture";
    if(this.c_productIsSamGovTrackerTF) {
      productName = "SAM Gov Tracker";
      productNameAcronym = "SGT";
      productShortWebAddress = "samgovtracker.com";
      productAutomatedEmailFromEmailAddress = "Samgovtracker@bitsolutionsllc.com";
      topBarBgClass = "bgTopBarSamGovTracker";
      topBarFullLogoFileNameBase = "samgovtracker";
      topBarLogoIconFileNameBase = "sgticon";
      topBarFullLogoWidth = "12.5em";
      topBarLogoIconWidth = "4.5em";
      topBarSearchInputTextBgHashColor = "#fcfcfc"; //#f4f4f6
      topBarCompanyNameFontClass = "font15 bgLeftNavAndTabsSamGovTracker";
      rightPanelLauncherLabelFontClass = "font12 fontBold fontDarkGrayBlueSamGovTracker";
      leftNavBgClass = "bgLeftNavAndTabsSamGovTracker";
      leftNavTabHeightEm = 7;
      leftNavTabUnselectedBgClass = "borderTB bevelBorderColors bgLeftNavAndTabsSamGovTracker hoverLeftNavTabSamGovTracker";
      leftNavTabUnselectedIconColor = "394853"; //333
      leftNavTabUnselectedFontColor = "222"; //222
      leftNavTabSelectedBgClass = "borderTB bevelBorderColors bgLeftNavTabSamGovTrackerSelected";
      leftNavTabSelectedIconColor = "293843"; //222
      leftNavTabSelectedFontColor = "111"; //111
      leftNavSubTabUnselectedBgClass = "borderTB bevelBorderColors bgLeftNavSubTabSamGovTracker hoverLeftNavSubTabSamGovTracker";
      leftNavSubTabUnselectedNumberColor = "444"; //444
      leftNavSubTabUnselectedFontColor = "222"; //222
      leftNavSubTabSelectedBgClass = "borderTB bevelBorderColors bgLeftNavSubTabSamGovTrackerSelected";
      leftNavSubTabSelectedNumberColor = "333"; //333
      leftNavSubTabSelectedFontColor = "111"; //111
      capturesTabEmptyBgClass = "bgGray";
      capturesTabEmptyMessageFontClass = "font12 fontItalic fontAlmostWhite";
      capturesTabCstCanDeleteAllCapturesInTableTF = false;
      capturesTabCreateNewCaptureButtonBgClass = "border bevelBorderColors borderRadius05 bgDarkRedSamGovTracker hoverRedSamGovTracker";
      capturesTabSubTabButtonUnselectedBgClass = "border bevelBorderColors borderRadius10 bgLighterGray hoverLightestGrayGradient";
      capturesTabSubTabButtonUnselectedFontClass = "";
      capturesTabSubTabButtonSelectedBgClass = "border bevelBorderColors borderRadius10 bgDarkGrayBlueSamGovTracker";
      capturesTabSubTabButtonSelectedFontClass = "fontAlmostWhite";
      capturesTabMasterButtonUnselectedBgClass = "border bevelBorderColors bgLighterGray hoverLightestGrayGradient";
      capturesTabMasterButtonUnselectedFontClass = "fontBold fontTextLight";
      capturesTabMasterButtonSelectedBgClass = "border bevelBorderColors bgDarkGrayBlueSamGovTracker";
      capturesTabMasterButtonSelectedFontClass = "fontBold fontAlmostWhite";
      cstColumnHeaderRowBgClass = "bgCstColumnHeaderRowBgSamGovTracker";
      cstColumnHeaderBgClass = "bgCstColumnHeaderSamGovTracker";
      cstColumnHeaderFontClass = "fontWhite";
      cstColumnHeaderSortedPrimaryBgClass = "bgCstColumnHeaderSortedPrimarySamGovTracker";
      cstColumnHeaderSortedSecondaryBgClass = "bgCstColumnHeaderSortedSecondarySamGovTracker";
      cstColumnHeaderSortedFontClass = "fontWhite";
      cstSumRowBgClass = "bgGreen";
      cstSumRowSumColumnBgClass = "bgMidDarkGreen";
      openCaptureCanArchiveCaptureTF = false;
      openCaptureTopBarBgClass = "bgRedCapture";
    }

    return({
      leftNavMarketingDocsTabTF: leftNavMarketingDocsTabTF,
      cardAdvanceStageTF: cardAdvanceStageTF,
      cardDatesTF: cardDatesTF,
      cardTasksTF: cardTasksTF,
      cardDetailsTF: cardDetailsTF,
      cardDealShapingTF: cardDealShapingTF,
      cardTeammatesTF: cardTeammatesTF,
      cardCompetitorsTF: cardCompetitorsTF,
      cardProposalThemesTF: cardProposalThemesTF,
      cardRiskAssessmentTF: cardRiskAssessmentTF,
      cardBudgetTF: cardBudgetTF,
      cardConversationsTF: cardConversationsTF,
      cardDocumentsTF: cardDocumentsTF,
      cardTemplatesTF: cardTemplatesTF,
      cardIDIQTaskOrdersTF: cardIDIQTaskOrdersTF,
      cardChangeLogTF: cardChangeLogTF,
      cardNotepadTF: cardNotepadTF,
      cardRevenueTF: cardRevenueTF,
      capturesTabProgressChartSubTabTF: capturesTabProgressChartSubTabTF,
      capturesTabMasterPresetEditorButtonTF: capturesTabMasterPresetEditorButtonTF,
      userPanelSettingsRightPanelWidthTF: userPanelSettingsRightPanelWidthTF,
      userPanelSettingsArchivedCapturesPreferenceTF: userPanelSettingsArchivedCapturesPreferenceTF,
      userPanelSettingsOpenCaptureSnapshotCardsMinHeightTF: userPanelSettingsOpenCaptureSnapshotCardsMinHeightTF,
      userPanelSettingsOpenCaptureSnapshotCardsMaxHeightTF: userPanelSettingsOpenCaptureSnapshotCardsMaxHeightTF,
      userPanelSettingsDatesCardLayoutTF: userPanelSettingsDatesCardLayoutTF,
      setupCompanyCanCreateDivisionsTF: setupCompanyCanCreateDivisionsTF,
      setupCompanyCanEditDivisionSettingsTF: setupCompanyCanEditDivisionSettingsTF,
      setupUsersUserPowerSuperAdminTF: setupUsersUserPowerSuperAdminTF,
      setupUsersUserPowerAdminTF: setupUsersUserPowerAdminTF,
      setupUsersUserPowerDivisionExecutiveTF: setupUsersUserPowerDivisionExecutiveTF,
      setupUsersUserPowerDivisionExecutiveReadOnlyTF: setupUsersUserPowerDivisionExecutiveReadOnlyTF,
      setupUsersUserPowerCaptureExecutiveTF: setupUsersUserPowerCaptureExecutiveTF,
      setupUsersUserPowerCaptureExecutiveReadOnlyTF: setupUsersUserPowerCaptureExecutiveReadOnlyTF,
      setupUsersUserPowerCaptureConsultantTF: setupUsersUserPowerCaptureConsultantTF,
      setupUsersUserPowerContractsExecutiveTF: setupUsersUserPowerContractsExecutiveTF,
      setupUsersUserPowerContractsManagerTF: setupUsersUserPowerContractsManagerTF,
      setupUsersUserPowerBudgetExecutiveTF: setupUsersUserPowerBudgetExecutiveTF,
      setupUsersUserPowerBudgetManagerTF: setupUsersUserPowerBudgetManagerTF,
      setupUsersShowLicenseExpirationTF: setupUsersShowLicenseExpirationTF,
      setupUsersCreateUserAltNameTF: setupUsersCreateUserAltNameTF,
      setupUsersCreateUserInjectsSGTMasterButtonsTF: setupUsersCreateUserInjectsSGTMasterButtonsTF,
      setupUsersDeactivedSwitchTF: setupUsersDeactivedSwitchTF,
      setupUsersCreatePublicSwitchTF: setupUsersCreatePublicSwitchTF,
      setupUsersCanAddUserPowerTF: setupUsersCanAddUserPowerTF,
      setupUsersReassignUserCustomCreationsTF: setupUsersReassignUserCustomCreationsTF,
      openCaptureChangelogRecordChangesTF: openCaptureChangelogRecordChangesTF,
      productName: productName,
      productNameAcronym: productNameAcronym,
      productShortWebAddress: productShortWebAddress,
      productAutomatedEmailFromEmailAddress: productAutomatedEmailFromEmailAddress,
      topBarBgClass: topBarBgClass,
      topBarFullLogoFileNameBase: topBarFullLogoFileNameBase,
      topBarLogoIconFileNameBase: topBarLogoIconFileNameBase,
      topBarFullLogoWidth: topBarFullLogoWidth,
      topBarLogoIconWidth: topBarLogoIconWidth,
      topBarSearchInputTextBgHashColor: topBarSearchInputTextBgHashColor,
      topBarCompanyNameFontClass: topBarCompanyNameFontClass,
      rightPanelLauncherLabelFontClass: rightPanelLauncherLabelFontClass,
      leftNavBgClass: leftNavBgClass,
      leftNavTabHeightEm: leftNavTabHeightEm,
      leftNavTabUnselectedBgClass: leftNavTabUnselectedBgClass,
      leftNavTabUnselectedIconColor: leftNavTabUnselectedIconColor,
      leftNavTabUnselectedFontColor: leftNavTabUnselectedFontColor,
      leftNavTabSelectedBgClass: leftNavTabSelectedBgClass,
      leftNavTabSelectedIconColor: leftNavTabSelectedIconColor,
      leftNavTabSelectedFontColor: leftNavTabSelectedFontColor,
      leftNavSubTabUnselectedBgClass: leftNavSubTabUnselectedBgClass,
      leftNavSubTabUnselectedNumberColor: leftNavSubTabUnselectedNumberColor,
      leftNavSubTabUnselectedFontColor: leftNavSubTabUnselectedFontColor,
      leftNavSubTabSelectedBgClass: leftNavSubTabSelectedBgClass,
      leftNavSubTabSelectedNumberColor: leftNavSubTabSelectedNumberColor,
      leftNavSubTabSelectedFontColor: leftNavSubTabSelectedFontColor,
      capturesTabEmptyBgClass: capturesTabEmptyBgClass,
      capturesTabEmptyMessageFontClass: capturesTabEmptyMessageFontClass,
      capturesTabCstCanDeleteAllCapturesInTableTF: capturesTabCstCanDeleteAllCapturesInTableTF,
      capturesTabCreateNewCaptureButtonBgClass: capturesTabCreateNewCaptureButtonBgClass,
      capturesTabSubTabButtonUnselectedBgClass: capturesTabSubTabButtonUnselectedBgClass,
      capturesTabSubTabButtonUnselectedFontClass: capturesTabSubTabButtonUnselectedFontClass,
      capturesTabSubTabButtonSelectedBgClass: capturesTabSubTabButtonSelectedBgClass,
      capturesTabSubTabButtonSelectedFontClass: capturesTabSubTabButtonSelectedFontClass,
      capturesTabMasterButtonUnselectedBgClass: capturesTabMasterButtonUnselectedBgClass,
      capturesTabMasterButtonUnselectedFontClass: capturesTabMasterButtonUnselectedFontClass,
      capturesTabMasterButtonSelectedBgClass: capturesTabMasterButtonSelectedBgClass,
      capturesTabMasterButtonSelectedFontClass: capturesTabMasterButtonSelectedFontClass,
      cstColumnHeaderRowBgClass: cstColumnHeaderRowBgClass,
      cstColumnHeaderBgClass: cstColumnHeaderBgClass,
      cstColumnHeaderFontClass: cstColumnHeaderFontClass,
      cstColumnHeaderSortedPrimaryBgClass: cstColumnHeaderSortedPrimaryBgClass,
      cstColumnHeaderSortedSecondaryBgClass: cstColumnHeaderSortedSecondaryBgClass,
      cstColumnHeaderSortedFontClass: cstColumnHeaderSortedFontClass,
      cstSumRowBgClass: cstSumRowBgClass,
      cstSumRowSumColumnBgClass: cstSumRowSumColumnBgClass,
      openCaptureCanArchiveCaptureTF: openCaptureCanArchiveCaptureTF,
      openCaptureTopBarBgClass: openCaptureTopBarBgClass
    });
  }



  //now date
  get c_nowYearInt() {
    const yyyyString = JSFUNC.direct_get_yyyy_string_from_Ymd_date(this.o_nowDate);
    const yearInt = JSFUNC.str2int(yyyyString);
    return(yearInt);
  }


  //Login
  get c_userIsLoggedIntoCaptureExecSystemTF() {
    return(this.o_loginState === "in");
  }



  //topBar
  get c_topBarHeight() {
    if(this.c_isMobileOrTabletTF) { //thinner topbar for smartphones
      return("3.3em");
    }
    return("4.5em");
  }



  //top bar search
  get c_searchFilteredCapturesFromSearchTermArrayOfObjs() {
    const searchCapturesBoxIsOpenTF = this.o_searchCapturesBoxIsOpenTF;
    const searchCapturesInputText = this.o_searchCapturesInputText;
    const searchSelectedSearchTypeTab = this.o_searchSelectedSearchTypeTab;
    const searchCapturesExactTF = this.o_searchCapturesExactTF;

    //no need to filter the captures if the search box is not open and set to the "captures" search tab
    if(!searchCapturesBoxIsOpenTF || (searchSelectedSearchTypeTab !== "captures")) {
      return([]);
    }

    //if there is no text entered for the search term, show the recently visited captures
    if(searchCapturesInputText === "") {
      var recentlyVisitedCapturesArrayOfObjs = [];
      for(let recentlyVisitedCaptureID of UserMobx.c_recentlyVisitedCaptureIDsArray) {
        var captureMap = DatabaseMobx.o_tbl_captures.get(recentlyVisitedCaptureID);
        if(captureMap !== undefined) {
          var searchResultsCaptureObj = CapturesMobx.create_search_results_capture_obj_from_capture_map(captureMap);
          recentlyVisitedCapturesArrayOfObjs.push(searchResultsCaptureObj);
        }
      }
      return(recentlyVisitedCapturesArrayOfObjs);
    }

    //filter the captures based on the lowercase search term (all stored capture data is already lowercase and in string format in memory for search)
    const searchLowercase = searchCapturesInputText.toLowerCase();

    //filter all captures that match search term
    const filterFunction = (i_captureObj) => {
      return(CapturesMobx.any_search_results_capture_obj_field_matches_lowercase_search_term_tf(i_captureObj, searchLowercase, searchCapturesExactTF));
    };
    return(CapturesMobx.c_searchAllCapturesOnlySearchedColumnsMaskedArrayOfObjs.filter(filterFunction));
  }

  get c_searchAllDocumentCategoriesArrayOfObjs() {
    const searchCapturesBoxIsOpenTF = this.o_searchCapturesBoxIsOpenTF;
    const searchSelectedSearchTypeTab = this.o_searchSelectedSearchTypeTab;
    const searchDocumentsDataObj = this.o_searchDocumentsDataObj;

    //no need to filter the documents if the search box is not open and set to the "documents" search tab
    if(!searchCapturesBoxIsOpenTF || (searchDocumentsDataObj === undefined) || (searchSelectedSearchTypeTab !== "documents")) {
      return([]);
    }

    var searchAllDocumentCategoriesArrayOfObjs = [];
    for(var objKey in searchDocumentsDataObj) {
      if(searchDocumentsDataObj.hasOwnProperty(objKey)) {
        var tblName = objKey;
        var tblDocsAndFoldersArrayOfObjs = searchDocumentsDataObj[objKey];

        //create the tblDisplayName from the tblName
        var isCaptureDocsTblTF = false;
        var isCaptureTeammateContractsTblTF = false;
        var isCaptureTeammateSurveysTblTF = false;
        var isContactCompaniesTblTF = false;
        var isContactPersonsTblTF = false;
        var isMarketingDocsTblTF = false;
        var tblDisplayName = "";
        if(tblName === "tbl_c_documents_filefoldersystem") { isCaptureDocsTblTF = true; tblDisplayName = "Capture Documents"; }
        else if(tblName === "tbl_c_teammates_contracts_filefoldersystem") { isCaptureTeammateContractsTblTF = true; tblDisplayName = "Capture Teammate Contracts"; }
        else if(tblName === "tbl_c_teammates_surveys_filefoldersystem") { isCaptureTeammateSurveysTblTF = true; tblDisplayName = "Capture Teammate Survey Documents"; }
        else if(tblName === "tbl_g_contacts_companies_filefoldersystem") { isContactCompaniesTblTF = true; tblDisplayName = "Contact Company Documents"; }
        else if(tblName === "tbl_g_contacts_persons_filefoldersystem") { isContactPersonsTblTF = true; tblDisplayName = "Contact Person Documents"; }
        else if(tblName === "tbl_g_general_documents_filefoldersystem") { isMarketingDocsTblTF = true; tblDisplayName = "Marketing Docs"; }

        //separate array of all folders for easier tree searching
        var tblFoldersArrayOfObjs = [];
        for(let tblDocOrFolderObj of tblDocsAndFoldersArrayOfObjs) {
          if(tblDocOrFolderObj.folder0_file1 === 0) {
            var tblFolderObj = JSFUNC.copy_obj(tblDocOrFolderObj);
            tblFoldersArrayOfObjs.push(tblFolderObj);
          }
        }

        //only include uploaded files (and online link files), not any folders
        var tblDocsArrayOfObjs = [];
        for(let tblDocOrFolderObj of tblDocsAndFoldersArrayOfObjs) {
          if(tblDocOrFolderObj.folder0_file1 === 1) {
            var tblDocObj = JSFUNC.copy_obj(tblDocOrFolderObj);

            //for each file, compute the subfolder path within the particular fileFolderSystem
            var lastSubfolderID = -1;
            var docFolderPath = "Top Level";
            if(tblDocObj.parent_folder_id > 0) {
              lastSubfolderID = tblDocObj.parent_folder_id;
              var docPathFolderNamesArray = JSFUNC.tree_field_array_from_top_to_node(tblFoldersArrayOfObjs, "parent_folder_id", tblDocObj.parent_folder_id, "display_name");
              docFolderPath += "/" + docPathFolderNamesArray.join("/");
            }
            tblDocObj.lastSubfolderID = lastSubfolderID;

            //get the uploaded by user name from the userID (or user name string)
            var uploadedByUserName = "";
            var combinedUserMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.c_tbl_a_users, "user_id", tblDocObj.upload_user_id);
            if(combinedUserMap !== undefined) {
              uploadedByUserName = combinedUserMap.get("fullName");
            }
            else {
              uploadedByUserName = tblDocObj.upload_user_name;
            }
            tblDocObj.uploadedByUserName = uploadedByUserName;

            //create the column for the capture/contact name and the hover title string for the entire doc results row containing all information about the file
            var captureOrContactName = "";
            var rowTitle = "File Name: " + tblDocObj.display_name;
            if(isCaptureDocsTblTF) {
              var captureName = DatabaseMobx.capture_name_plaintext_from_capture_id(tblDocObj.capture_id);
              captureOrContactName = captureName;
              rowTitle += "\nCapture (ID: " + tblDocObj.capture_id + "): " + captureName;
            }
            else if(isCaptureTeammateContractsTblTF) {
              var captureName = DatabaseMobx.capture_name_plaintext_from_capture_id(tblDocObj.capture_id);
              var combinedTeammateObj = TeammateContractsMobx.create_combined_teammate_obj_from_teammate_id(tblDocObj.teammate_id);
              var contractTypeMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_types", tblDocObj.contract_type_id);
              var contractTypeShortName = contractTypeMap.get("short_name");
              captureOrContactName = "[" + contractTypeShortName + "] " + combinedTeammateObj.teammateNamePlainText + " - " + captureName;
              rowTitle += "\nCapture (ID: " + tblDocObj.capture_id + "): " + captureName;
              rowTitle += "\nTeammate: " + combinedTeammateObj.teammateNamePlainText;
              rowTitle += "\nContract Type: " + contractTypeShortName;
            }
            else if(isCaptureTeammateSurveysTblTF) {
              var captureName = DatabaseMobx.capture_name_plaintext_from_capture_id(tblDocObj.capture_id);
              //var surveyMap = DatabaseMobx.tbl_row_map_from_id("tbl_c_teammates_surveys", tblDocObj.survey_id); //tbl_c_teammates_surveys not loaded at login so no data available
              captureOrContactName = captureName;
              rowTitle += "\nCapture (ID: " + tblDocObj.capture_id + "): " + captureName;
              //rowTitle += "\nSurvey: " + surveyMap.get("title");
            }
            else if(isContactCompaniesTblTF) {
              var isPersonTF = false;
              var contactCompanyObj = ContactsMobx.contact_company_or_person_obj_from_id(isPersonTF, tblDocObj.contact_company_id);
              var contactCompanyName = ContactsMobx.contact_name_plaintext_from_contact_obj(contactCompanyObj);
              captureOrContactName = contactCompanyName;
              rowTitle += "\nContact Company: " + contactCompanyName;
            }
            else if(isContactPersonsTblTF) {
              var isPersonTF = true;
              var contactPersonObj = ContactsMobx.contact_company_or_person_obj_from_id(isPersonTF, tblDocObj.contact_person_id);
              var contactPersonName = ContactsMobx.contact_name_plaintext_from_contact_obj(contactPersonObj);
              captureOrContactName = contactPersonName + " (" + contactPersonObj.organizationAbbrOrLegalName + ")";
              rowTitle += "\nContact Person: " + contactPersonName + " (" + contactPersonObj.organizationAbbrOrLegalName + ")";
            }
            else if(isMarketingDocsTblTF) {
              captureOrContactName = "Marketing Documents";
              rowTitle += "\nMarketing Documents";
            }

            rowTitle += "\nKeywords: " + tblDocObj.keywords;
            rowTitle += "\nDescription: " + tblDocObj.notes;
            rowTitle += "\nFolder Path: " + docFolderPath;
            rowTitle += "\nUpload Date: " + tblDocObj.upload_date;
            rowTitle += "\nUploaded By: " + tblDocObj.uploadedByUserName;
            tblDocObj.captureOrContactName = captureOrContactName;
            tblDocObj.rowTitle = rowTitle;

            //save the file display name and notes in lowercase for search comparisons
            tblDocObj.displayNameLowercase = tblDocObj.display_name.toLowerCase();
            tblDocObj.captureOrContactNameLowercase = captureOrContactName.toLowerCase();
            tblDocObj.keywordsLowercase = tblDocObj.keywords.toLowerCase();
            tblDocObj.notesLowercase = tblDocObj.notes.toLowerCase();

            tblDocsArrayOfObjs.push(tblDocObj);
          }
        }

        //add this fileFolderSystem tbl of docs to the collection
        searchAllDocumentCategoriesArrayOfObjs.push({
          tblName: tblName,
          tblDisplayName: tblDisplayName,
          tblDocsArrayOfObjs: tblDocsArrayOfObjs
        });
      }
    }

    return(searchAllDocumentCategoriesArrayOfObjs);
  }

  get c_searchFilteredDocumentCategoriesArrayOfObjs() {
    const searchCapturesBoxIsOpenTF = this.o_searchCapturesBoxIsOpenTF;
    const searchCapturesInputText = this.o_searchCapturesInputText;
    const searchSelectedSearchTypeTab = this.o_searchSelectedSearchTypeTab;
    const searchDocumentsDrawAllDocsTF = this.o_searchDocumentsDrawAllDocsTF;
    const searchCapturesExactTF = this.o_searchCapturesExactTF;
    const searchAllDocumentCategoriesArrayOfObjs = this.c_searchAllDocumentCategoriesArrayOfObjs;

    //no need to filter the documents if the search box is not open and set to the "documents" search tab
    if(!searchCapturesBoxIsOpenTF || (searchSelectedSearchTypeTab !== "documents")) {
      return([]);
    }

    const maxDrawDocs = 50;
    const searchIsBlankTF = (searchCapturesInputText === "");
    const searchLowercase = searchCapturesInputText.toLowerCase();

    var searchFilteredDocumentCategoriesArrayOfObjs = [];
    var filteredDocsCount = 0;
    for(let documentCategoryObj of searchAllDocumentCategoriesArrayOfObjs) {
      var filteredTblDocsArrayOfObjs = [];
      var filteredTblDocsIndicesArray = [];
      var filteredTblDocsDrawDocTFArray = [];
      for(let tblDocObj of documentCategoryObj.tblDocsArrayOfObjs) {
        var docMatchesSearchTF = false;
        if(searchIsBlankTF) { //having a blank search allows all docs to match and be displayed
          docMatchesSearchTF = true;
        }
        else { //filter the docs using the search term and the exact flag
          if(tblDocObj.displayNameLowercase.indexOf(searchLowercase) >= 0) {
            docMatchesSearchTF = true;
          }
          else if(tblDocObj.captureOrContactNameLowercase.indexOf(searchLowercase) >= 0) {
            docMatchesSearchTF = true;
          }
          else if(tblDocObj.keywordsLowercase.indexOf(searchLowercase) >= 0) {
            docMatchesSearchTF = true;
          }
          else if(tblDocObj.notesLowercase.indexOf(searchLowercase) >= 0) {
            docMatchesSearchTF = true;
          }
        }

        if(docMatchesSearchTF) {
          filteredDocsCount++;
          filteredTblDocsArrayOfObjs.push(tblDocObj);
          filteredTblDocsIndicesArray.push(filteredDocsCount);
          filteredTblDocsDrawDocTFArray.push(searchDocumentsDrawAllDocsTF || (filteredDocsCount <= maxDrawDocs));
        }
      }
      var numFilteredTblDocs = filteredTblDocsIndicesArray.length;

      //sort the filtered docs by file name
      if(numFilteredTblDocs > 0) {
        JSFUNC.sort_arrayOfObjs(filteredTblDocsArrayOfObjs, "displayNameLowercase", true);
      }

      //determine if the category should be drawn based on the maxDrawDocs
      var drawCategoryTF = true;
      if(numFilteredTblDocs === 0) {
        drawCategoryTF = false;
      }
      else if(!searchDocumentsDrawAllDocsTF) {
        if(filteredTblDocsIndicesArray[0] > maxDrawDocs) {
          drawCategoryTF = false;
        }
      }

      searchFilteredDocumentCategoriesArrayOfObjs.push({
        tblName: documentCategoryObj.tblName,
        tblDisplayName: documentCategoryObj.tblDisplayName,
        tblDocsArrayOfObjs: filteredTblDocsArrayOfObjs,
        tblDocsIndicesArray: filteredTblDocsIndicesArray,
        tblDocsDrawDocTFArray: filteredTblDocsDrawDocTFArray,
        drawCategoryTF: drawCategoryTF
      });
    }

    return(searchFilteredDocumentCategoriesArrayOfObjs);
  }

  get c_searchDocumentsTotalNumFilteredDocs() {
    const searchFilteredDocumentCategoriesArrayOfObjs = this.c_searchFilteredDocumentCategoriesArrayOfObjs;

    var totalNumDocs = 0;
    for(let documentCategoryObj of searchFilteredDocumentCategoriesArrayOfObjs) {
      totalNumDocs += documentCategoryObj.tblDocsArrayOfObjs.length;
    }
    return(totalNumDocs);
  }

  get c_searchDocumentsDisplayShowAllDocsButtonTF() {
    const searchDocumentsDrawAllDocsTF = this.o_searchDocumentsDrawAllDocsTF;
    const searchDocumentsTotalNumFilteredDocs = this.c_searchDocumentsTotalNumFilteredDocs;

    //don't display show all button if it has already been pushed
    if(searchDocumentsDrawAllDocsTF) {
      return(false);
    }

    return(searchDocumentsTotalNumFilteredDocs > 50);
  }







  //leftNav
  get c_leftNavWidth() {
    if(this.c_isMobileOrTabletTF) { return("90vw"); }
    if(this.o_leftNavIsExpandedTF) { return("19em"); }
    return("3.3em");
  }

  get c_leftNavRightEdge() {
    return((this.c_isMobileOrTabletTF) ? (0) : (this.c_leftNavWidth));
  }

  get c_leftNavGeneralTabsArrayOfObjs() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    const c_productStylingObj = this.c_productStylingObj;

    var leftNavGeneralTabsArrayOfObjs = [];

    if(DatabaseMobx.c_bitGovConSmartSearchTF) {
      leftNavGeneralTabsArrayOfObjs.push({tabName: "GovCon Smart Search"});
    }

    var capturesTabSubTabNamesArray = ["Capture Table View", "Stage View", "Priority View", "Gantt Chart View"];
    if(c_productStylingObj.capturesTabProgressChartSubTabTF) {
      capturesTabSubTabNamesArray.push("Progress Chart View");
    }
    leftNavGeneralTabsArrayOfObjs.push({
      tabName: "Captures",
      subTabNamesArray: capturesTabSubTabNamesArray
    });

    if(!c_productIsSamGovTrackerTF) {
      leftNavGeneralTabsArrayOfObjs.push({
        tabName: "My Summaries",
        subTabNamesArray: [
          "My Performance",
          "My Surveys"
        ]
      });
      leftNavGeneralTabsArrayOfObjs.push({tabName: "Contacts"});
    }

    if(c_productStylingObj.leftNavMarketingDocsTabTF) {
      leftNavGeneralTabsArrayOfObjs.push({tabName: "Marketing Docs"});
    }

    return(leftNavGeneralTabsArrayOfObjs);
  }

  get c_leftNavExecutiveManagementTabWithSubtabsObj() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;

    if(!c_productIsSamGovTrackerTF) {
      return({
        tabName: "Executive Management",
        subTabNamesArray: [
          "Performance",
          "Daily Snapshot",
          "Trend Analyzer",
          "Financial Projections",
          "Hot Bits",
          "Critical Thresholds",
          "Stage Flow",
          "Excel Report Writer"
        ]
      });
    }
    return([]);
  }

  get c_leftNavUserActivityTabObj() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    if(!c_productIsSamGovTrackerTF) {
      return({tabName:"User Activity"});
    }
    return([]);
  }

  get c_leftNavCaptureImportTabObj() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    if(!c_productIsSamGovTrackerTF) {
      return({tabName:"Capture Import"});
    }
    return([]);
  }

  get c_leftNavContractsWorkloadTabObj() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    if(!c_productIsSamGovTrackerTF) {
      return({tabName:"Contracts Workload"});
    }
    return([]);
  }

  get c_leftNavContractsTodoTabObj() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    if(!c_productIsSamGovTrackerTF) {
      return({tabName:"Contracts Todo"});
    }
    return([]);
  }

  get c_leftNavBudgetsWorkloadTabObj() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    if(!c_productIsSamGovTrackerTF) {
      return({tabName:"Budgets Workload"});
    }
    return([]);
  }

  get c_leftNavBudgetsTodoTabObj() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    if(!c_productIsSamGovTrackerTF) {
      return({tabName:"Budgets Todo"});
    }
    return([]);
  }

  get c_leftNavSystemSetupTabWithSubtabsObj() {
    const k_onPremiseCustomerTF = this.k_onPremiseCustomerTF;
    const k_onPremiseCustomerCheckAuthenticationQuarterlyPasscodeFileTF = this.k_onPremiseCustomerCheckAuthenticationQuarterlyPasscodeFileTF;
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;

    var systemSetupSubTabNamesArray = [];

    if(c_productIsSamGovTrackerTF) {
      systemSetupSubTabNamesArray.push("Users");
      systemSetupSubTabNamesArray.push("Company/Divisions");
    }
    else {
      systemSetupSubTabNamesArray.push("Company/Divisions");
      systemSetupSubTabNamesArray.push("Users");
    }

    if(!c_productIsSamGovTrackerTF) {
      systemSetupSubTabNamesArray.push("Capture Types");
      systemSetupSubTabNamesArray.push("Capture Cards");
      systemSetupSubTabNamesArray.push("Stages");
      systemSetupSubTabNamesArray.push("Details Card Groups");
      systemSetupSubTabNamesArray.push("Details/Dates Fields");
      systemSetupSubTabNamesArray.push("Details Fields Options");
      systemSetupSubTabNamesArray.push("Deal Shaping Questions");
      systemSetupSubTabNamesArray.push("Teammates");
      systemSetupSubTabNamesArray.push("Teammate Contracts");
      systemSetupSubTabNamesArray.push("Teammate Ratings");
      systemSetupSubTabNamesArray.push("Teammate Surveys");
      systemSetupSubTabNamesArray.push("Competitors");
      systemSetupSubTabNamesArray.push("Proposal Themes");
      systemSetupSubTabNamesArray.push("Risk Assessment");
      systemSetupSubTabNamesArray.push("Budgets");
      systemSetupSubTabNamesArray.push("Capture Templates");
    }

    systemSetupSubTabNamesArray.push("GCSS Options");

    if(!c_productIsSamGovTrackerTF) {
      systemSetupSubTabNamesArray.push("Contacts Fields");
      systemSetupSubTabNamesArray.push("Tasks Options");
      systemSetupSubTabNamesArray.push("Automated Reminders");
      //systemSetupSubTabNamesArray.push("Automated Exports");
      systemSetupSubTabNamesArray.push("Excel Report Templates");
      systemSetupSubTabNamesArray.push("System");
      systemSetupSubTabNamesArray.push("Shortcut Presets");
      systemSetupSubTabNamesArray.push("Capture Archiving");
      systemSetupSubTabNamesArray.push("New Capture Additional Fields");
      systemSetupSubTabNamesArray.push("Search Fields");
      systemSetupSubTabNamesArray.push("PWin");
      systemSetupSubTabNamesArray.push("Progress Colors");
      systemSetupSubTabNamesArray.push("Upcoming Date Colors");
      systemSetupSubTabNamesArray.push("Delete Capture Reasons");
      systemSetupSubTabNamesArray.push("Question Tags");
      systemSetupSubTabNamesArray.push("Admin Changelog");

      if(c_bitUsing3rdPartyIntegrationTF) {
        systemSetupSubTabNamesArray.push("3rd Party Integrations");
      }

      if(k_onPremiseCustomerTF && k_onPremiseCustomerCheckAuthenticationQuarterlyPasscodeFileTF) {
        systemSetupSubTabNamesArray.push("On-Premise");
      }
    }

    if(c_productIsSamGovTrackerTF && false) {
      systemSetupSubTabNamesArray.push("My Account");
    }

    return({
      tabName: "System Setup",
      subTabNamesArray: systemSetupSubTabNamesArray
    });
  }

  get c_leftNavUpgradeToCaptureExecTabObj() {
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;
    if(c_productIsSamGovTrackerTF && false) { //TODO build this tab //upgrade to CE only in SGT as an ad for CE
      return({tabName:"Upgrade To CaptureExec"});
    }
    return([]);
  }


  get c_leftNavUserTabsArrayOfObjs() {
    const o_leftNavTabNameSelected = this.o_leftNavTabNameSelected;
    const c_leftNavGeneralTabsArrayOfObjs = this.c_leftNavGeneralTabsArrayOfObjs
    const c_leftNavExecutiveManagementTabWithSubtabsObj = this.c_leftNavExecutiveManagementTabWithSubtabsObj;
    const c_leftNavUserActivityTabObj = this.c_leftNavUserActivityTabObj;
    const c_leftNavCaptureImportTabObj = this.c_leftNavCaptureImportTabObj;
    const c_leftNavContractsWorkloadTabObj = this.c_leftNavContractsWorkloadTabObj;
    const c_leftNavContractsTodoTabObj = this.c_leftNavContractsTodoTabObj;
    const c_leftNavBudgetsWorkloadTabObj = this.c_leftNavBudgetsWorkloadTabObj;
    const c_leftNavBudgetsTodoTabObj = this.c_leftNavBudgetsTodoTabObj;
    const c_leftNavSystemSetupTabWithSubtabsObj = this.c_leftNavSystemSetupTabWithSubtabsObj;
    const c_leftNavUpgradeToCaptureExecTabObj = this.c_leftNavUpgradeToCaptureExecTabObj;
    const c_combinedUserObj = UserMobx.c_combinedUserObj;

    var leftNavUserTabsArrayOfObjs = []; //initialize no leftNav tabs for an invalid power_name
    if(c_combinedUserObj.powerIsSuperAdminTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavExecutiveManagementTabWithSubtabsObj, c_leftNavGeneralTabsArrayOfObjs, c_leftNavContractsWorkloadTabObj, c_leftNavContractsTodoTabObj, c_leftNavBudgetsWorkloadTabObj, c_leftNavBudgetsTodoTabObj, c_leftNavUserActivityTabObj, c_leftNavCaptureImportTabObj, c_leftNavSystemSetupTabWithSubtabsObj, c_leftNavUpgradeToCaptureExecTabObj);
    }
    else if(c_combinedUserObj.powerIsAdminTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavGeneralTabsArrayOfObjs, c_leftNavUserActivityTabObj, c_leftNavCaptureImportTabObj, c_leftNavSystemSetupTabWithSubtabsObj, c_leftNavUpgradeToCaptureExecTabObj);
    }
    else if(c_combinedUserObj.powerIsDivExecTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavExecutiveManagementTabWithSubtabsObj, c_leftNavGeneralTabsArrayOfObjs, c_leftNavUpgradeToCaptureExecTabObj);
    }
    else if(c_combinedUserObj.powerIsCaptureExecTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavGeneralTabsArrayOfObjs, c_leftNavUpgradeToCaptureExecTabObj);
    }
    else if(c_combinedUserObj.powerIsCaptureConsultantTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavGeneralTabsArrayOfObjs, c_leftNavUpgradeToCaptureExecTabObj);
    }
    else if(c_combinedUserObj.powerIsContractsExecTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavContractsWorkloadTabObj, c_leftNavContractsTodoTabObj, c_leftNavGeneralTabsArrayOfObjs, c_leftNavUpgradeToCaptureExecTabObj);
    }
    else if(c_combinedUserObj.powerIsContractsTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavContractsTodoTabObj, c_leftNavGeneralTabsArrayOfObjs, c_leftNavUpgradeToCaptureExecTabObj);
    }
    else if(c_combinedUserObj.powerIsBudgetExecTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavBudgetsWorkloadTabObj, c_leftNavBudgetsTodoTabObj, c_leftNavGeneralTabsArrayOfObjs, c_leftNavUpgradeToCaptureExecTabObj);
    }
    else if(c_combinedUserObj.powerIsBudgetTF) {
      leftNavUserTabsArrayOfObjs = JSFUNC.concat_arrays_or_values_into_new_array(c_leftNavBudgetsTodoTabObj, c_leftNavGeneralTabsArrayOfObjs, c_leftNavUpgradeToCaptureExecTabObj);
    }

    //precomuted fields for leftNav tabs/subtabs
    var tabNumber = 1;
    var subTabNumber = 1;
    for(let leftNavUserTabObj of leftNavUserTabsArrayOfObjs) {
      var tabDisplayName = this.get_tab_or_subtab_display_name_from_tab_name(leftNavUserTabObj.tabName);
      var hasSubTabsTF = (JSFUNC.is_array(leftNavUserTabObj.subTabNamesArray) && (leftNavUserTabObj.subTabNamesArray.length > 0));
      var tabIsSelectedTF = (leftNavUserTabObj.tabName === o_leftNavTabNameSelected);

      var anySubTabIsSelectedTF = false;
      var tabNameOpenedWhenSelected = leftNavUserTabObj.tabName;
      if(hasSubTabsTF) {
        anySubTabIsSelectedTF = JSFUNC.in_array(o_leftNavTabNameSelected, leftNavUserTabObj.subTabNamesArray);
        tabNameOpenedWhenSelected = leftNavUserTabObj.subTabNamesArray[0];
      }

      var tabOrAnySubTabIsSelectedTF = (tabIsSelectedTF || anySubTabIsSelectedTF);

      var subTabsArrayOfObjs = [];
      if(anySubTabIsSelectedTF) {
        subTabNumber = 1;
        for(let subTabName of leftNavUserTabObj.subTabNamesArray) {
          var subTabIsSelectedTF = (subTabName === o_leftNavTabNameSelected);
          var subTabDisplayName = this.get_tab_or_subtab_display_name_from_tab_name(subTabName);

          subTabsArrayOfObjs.push({
            subTabNumber: subTabNumber,
            subTabName: subTabName,
            subTabDisplayName: subTabDisplayName,
            subTabIsSelectedTF: subTabIsSelectedTF
          });

          subTabNumber++;
        }
      }

      leftNavUserTabObj.tabNumber = tabNumber;
      leftNavUserTabObj.tabDisplayName = tabDisplayName;
      leftNavUserTabObj.hasSubTabsTF = hasSubTabsTF;
      leftNavUserTabObj.tabIsSelectedTF = tabIsSelectedTF;
      leftNavUserTabObj.anySubTabIsSelectedTF = anySubTabIsSelectedTF;
      leftNavUserTabObj.tabNameOpenedWhenSelected = tabNameOpenedWhenSelected;
      leftNavUserTabObj.tabOrAnySubTabIsSelectedTF = tabOrAnySubTabIsSelectedTF;
      leftNavUserTabObj.subTabsArrayOfObjs = subTabsArrayOfObjs;

      tabNumber++;
    }

    return(leftNavUserTabsArrayOfObjs);
  }

  get_tab_or_subtab_display_name_from_tab_name(i_tabName) {
    const c_productStylingObj = this.c_productStylingObj;
    const c_cardNameDates = DatabaseMobx.c_cardNameDates;
    const c_cardNameDetails = DatabaseMobx.c_cardNameDetails;
    const c_customDealShapingQuestionsStringFromCardName = DatabaseMobx.c_customDealShapingQuestionsStringFromCardName;
    const c_fieldMapOfStage = DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfCapturePriorityLevel = DatabaseMobx.c_fieldMapOfCapturePriorityLevel;

    //tab display name overwriting with custom open capture card names
    var tabDisplayName = i_tabName;
    if(i_tabName === "Stage View") {
      tabDisplayName = c_fieldMapOfStage.get("display_name") + " View";
    }
    else if(i_tabName === "Priority View") {
      tabDisplayName = c_fieldMapOfCapturePriorityLevel.get("display_name") + " View";
    }
    else if(i_tabName === "Details Card Groups") {
      tabDisplayName = c_cardNameDetails + " Card Groups";
    }
    else if(i_tabName === "Details/Dates Fields") {
      tabDisplayName = c_cardNameDetails + "/" + c_cardNameDates + " Fields";
    }
    else if(i_tabName === "Details Fields Options") {
      tabDisplayName = c_cardNameDetails + " Fields Options";
    }
    else if(i_tabName === "Deal Shaping Questions") {
      tabDisplayName = c_customDealShapingQuestionsStringFromCardName;
    }
    else if(i_tabName === "Company/Divisions") { //SGT refers to this tab as just "Company"
      if(!c_productStylingObj.setupCompanyCanCreateDivisionsTF) {
        tabDisplayName = "Company";
      }
    }
    return(tabDisplayName);
  }


  left_nav_default_tab_name() { //called during login (through single user or selecting multi user), or changing multi user in user panel
    const c_productIsSamGovTrackerTF = this.c_productIsSamGovTrackerTF;

    if(c_productIsSamGovTrackerTF) {
      return("Capture Table View");
    }

    const userStartTabName = UserMobx.c_combinedUserObj.start_tab_name;
    if(JSFUNC.text_or_number_is_filled_out_tf(userStartTabName)) {
      return(userStartTabName);
    }

    if(UserMobx.c_combinedUserObj.powerIsSuperAdminTF) { return("User Activity"); }
    else if(UserMobx.c_combinedUserObj.powerIsAdminTF) { return("User Activity"); }
    else if(UserMobx.c_combinedUserObj.powerIsDivExecTF) { return("Performance"); }
    else if(UserMobx.c_combinedUserObj.powerIsCaptureExecTF) { return("Capture Table View"); }
    else if(UserMobx.c_combinedUserObj.powerIsCaptureConsultantTF) { return("Capture Table View"); }
    else if(UserMobx.c_combinedUserObj.powerIsContractsExecTF) { return("Contracts Todo"); }
    else if(UserMobx.c_combinedUserObj.powerIsContractsTF) { return("Contracts Todo"); }
    else if(UserMobx.c_combinedUserObj.powerIsBudgetExecTF) { return("Budgets Todo"); }
    else if(UserMobx.c_combinedUserObj.powerIsBudgetTF) { return("Budgets Todo"); }

    return("Capture Table View");
  }


  get c_leftNavSelectStartTabFieldTypeObj() {
    const c_leftNavUserTabsArrayOfObjs = this.c_leftNavUserTabsArrayOfObjs;

    var tabNamesValueArray = [""];
    var tabNamesDisplayArray = ["[Default Setting]"];
    for(let leftNavTabNameObj of c_leftNavUserTabsArrayOfObjs) {
      if(!JSFUNC.is_array(leftNavTabNameObj.subTabNamesArray)) {
        tabNamesValueArray.push(leftNavTabNameObj.tabName);
        tabNamesDisplayArray.push(leftNavTabNameObj.tabName);
      }
      else {
        for(let subTabName of leftNavTabNameObj.subTabNamesArray) {
          tabNamesValueArray.push(subTabName);
          tabNamesDisplayArray.push(leftNavTabNameObj.tabName + " -> " + subTabName);
        }
      }
    }

    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Starting Navigation Tab", tabNamesValueArray, true, tabNamesDisplayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectedLeftNavIsCaptureTableViewTF() { return(this.o_leftNavTabNameSelected === "Capture Table View"); }
  get c_selectedLeftNavIsStageViewTF() { return(this.o_leftNavTabNameSelected === "Stage View"); }
  get c_selectedLeftNavIsPriorityViewTF() { return(this.o_leftNavTabNameSelected === "Priority View"); }
  get c_selectedLeftNavIsGanttChartViewTF() { return(this.o_leftNavTabNameSelected === "Gantt Chart View"); }
  get c_selectedLeftNavIsProgressChartViewTF() { return(this.o_leftNavTabNameSelected === "Progress Chart View"); }




  //global view captures (styled like search results box) based on given array of captureIDs to show (results from graph clicks, etc)
  get c_viewSpecifiedSearchResultsCapturesInFloatingBoxArrayOfObjs() {
    const o_viewSpecifiedSearchResultCapturesInFloatingBoxCaptureIDsArray = this.o_viewSpecifiedSearchResultCapturesInFloatingBoxCaptureIDsArray;
    const viewSpecifiedSearchResultsCapturesArrayOfObjs = CapturesMobx.filtered_search_results_captures_from_capture_ids_array(o_viewSpecifiedSearchResultCapturesInFloatingBoxCaptureIDsArray);
    return(viewSpecifiedSearchResultsCapturesArrayOfObjs);
  }




  //drag/drop computed
  get c_computerFileIsCurrentlyBeingDraggedAcrossWebsiteTF() {
    const o_anyFileOrCEItemDraggedOverAnyPartOfWebsiteTF = this.o_anyFileOrCEItemDraggedOverAnyPartOfWebsiteTF;
    const o_ceDraggedItemObjOrUndefined = this.o_ceDraggedItemObjOrUndefined;
    return(o_anyFileOrCEItemDraggedOverAnyPartOfWebsiteTF && (o_ceDraggedItemObjOrUndefined === undefined));
  }






  //My Surveys subtab
  get c_mySurveysAllMySentSurveysArrayOfObjs() {
    const mySurveysTblCTeammatesSurveysArrayOfObjs = this.o_mySurveysTblCTeammatesSurveysArrayOfObjs;
    const mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs = this.o_mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs;

    const filterUserPerEmailIDsArray = [UserMobx.o_userPerEmailID];

    const nowDateTimeUTC = JSFUNC.now_datetime_utc();

    var mySurveysAllMySentSurveysArrayOfObjs = [];
    for(let surveyObj of mySurveysTblCTeammatesSurveysArrayOfObjs) {
      var captureMap = DatabaseMobx.o_tbl_captures.get(surveyObj.capture_id);
      if(captureMap !== undefined) {
        var surveyMostRecentActivityDateTimeUtc = JSFUNC.blank_datetime();

        //loop through every survey response and collect together each one matching the surveyID
        var surveyResponseTimesArrayOfObjs = [];
        for(let surveyResponseTimeObj of mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs) {
          if(surveyResponseTimeObj.survey_id === surveyObj.id) { //if this survey response is part of this survey
            if(JSFUNC.in_array(surveyResponseTimeObj.sent_by_user_per_email_id, filterUserPerEmailIDsArray)) { //if the filtered userPerEmailIDs match who sent this survey
              //get the survey response teammate contact
              var teammateMap = DatabaseMobx.o_tbl_c_teammates.get(surveyResponseTimeObj.teammate_id);
              if(teammateMap !== undefined) {
                //get the teammate contact name
                var teammateContactCompanyID = teammateMap.get("contact_company_id");
                var teammateContactCompanyObj = TeammateContractsMobx.teammate_contact_company_obj_from_contact_company_id_and_capture_map(teammateContactCompanyID, captureMap);
                var teammateNamePlainText = ContactsMobx.contact_name_plaintext_from_contact_obj(teammateContactCompanyObj);

                //get name of sent by user
                var sentByUserNamePlainText = DatabaseMobx.user_full_name_plaintext_from_user_per_email_id(surveyResponseTimeObj.sent_by_user_per_email_id);

                //convert the utc datetimes to local
                var sentDateTimeLocal = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(surveyResponseTimeObj.sent_datetime_utc, DatabaseMobx.c_genericDateTimeFieldTypeObj);
                var dueDateTimeLocal = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(surveyResponseTimeObj.due_datetime_utc, DatabaseMobx.c_genericDateTimeFieldTypeObj);
                var completedDateTimeLocal = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(surveyResponseTimeObj.completed_datetime_utc, DatabaseMobx.c_genericDateTimeFieldTypeObj);

                var surveyLightColor = "eee";
                var surveyLightText = "Not Yet Sent";
                if(JSFUNC.datetime_is_filled_out_tf(surveyResponseTimeObj.completed_datetime_utc)) {
                  surveyLightColor = "5b5";
                  surveyLightText = "Completed";
                }
                else {
                  if(JSFUNC.datetime_is_filled_out_tf(surveyResponseTimeObj.sent_datetime_utc)) {
                    if(nowDateTimeUTC > surveyResponseTimeObj.due_datetime_utc) {
                      surveyLightColor = "b55";
                      surveyLightText = "Expired";
                    }
                    else {
                      surveyLightColor = "bb5";
                      surveyLightText = "Sent";
                    }
                  }
                }

                //update the most recent activity date for this response
                var responseMostRecentActivityDateTimeUtc = JSFUNC.blank_datetime();
                if(surveyResponseTimeObj.sent_datetime_utc > responseMostRecentActivityDateTimeUtc) {
                  responseMostRecentActivityDateTimeUtc = surveyResponseTimeObj.sent_datetime_utc;
                }

                if(surveyResponseTimeObj.completed_datetime_utc > responseMostRecentActivityDateTimeUtc) {
                  responseMostRecentActivityDateTimeUtc = surveyResponseTimeObj.completed_datetime_utc;
                }

                //update the most recent activity date for any response on the survey
                if(responseMostRecentActivityDateTimeUtc > surveyMostRecentActivityDateTimeUtc) {
                  surveyMostRecentActivityDateTimeUtc = responseMostRecentActivityDateTimeUtc;
                }

                surveyResponseTimesArrayOfObjs.push({
                  capture_id: surveyResponseTimeObj.capture_id,
                  teammate_id: surveyResponseTimeObj.teammate_id,
                  survey_id: surveyResponseTimeObj.survey_id,
                  sent_by_user_per_email_id: surveyResponseTimeObj.sent_by_user_per_email_id,
                  teammateContactCompanyID: teammateContactCompanyID,
                  teammateNamePlainText: teammateNamePlainText,
                  sentByUserNamePlainText: sentByUserNamePlainText,
                  sentDateTimeLocal: sentDateTimeLocal,
                  dueDateTimeLocal: dueDateTimeLocal,
                  completedDateTimeLocal: completedDateTimeLocal,
                  surveyLightColor: surveyLightColor,
                  surveyLightText: surveyLightText,
                  responseMostRecentActivityDateTimeUtc: responseMostRecentActivityDateTimeUtc
                });
              }
            }
          }
        }

        if(surveyResponseTimesArrayOfObjs.length > 0) {
          JSFUNC.sort_arrayOfObjs(surveyResponseTimesArrayOfObjs, "responseMostRecentActivityDateTimeUtc", false);

          var captureIDIQCaptureID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfIdiqCaptureIDTOLink);
          var captureNamePlainText = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);
          var surveyMostRecentActivityDateTimeLocal = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(surveyMostRecentActivityDateTimeUtc, DatabaseMobx.c_genericDateTimeFieldTypeObj);

          var surveyInfoObj = {
            id: surveyObj.id,
            capture_id: surveyObj.capture_id,
            sort: surveyObj.sort,
            title: surveyObj.title,
            captureIDIQCaptureID: captureIDIQCaptureID,
            captureNamePlainText: captureNamePlainText,
            surveyMostRecentActivityDateTimeUtc: surveyMostRecentActivityDateTimeUtc,
            surveyMostRecentActivityDateTimeLocal: surveyMostRecentActivityDateTimeLocal
          };

          mySurveysAllMySentSurveysArrayOfObjs.push({
            surveyInfoObj: surveyInfoObj,
            surveyMostRecentActivityDateTimeUtc: surveyMostRecentActivityDateTimeUtc,
            surveyResponseTimesArrayOfObjs: surveyResponseTimesArrayOfObjs
          });
        }
      }
    }

    JSFUNC.sort_arrayOfObjs(mySurveysAllMySentSurveysArrayOfObjs, "surveyMostRecentActivityDateTimeUtc", false);

    return(mySurveysAllMySentSurveysArrayOfObjs);
  }


  get c_mySurveysSelectTeammateFieldTypeObj() {
    const c_mySurveysAllMySentSurveysArrayOfObjs = this.c_mySurveysAllMySentSurveysArrayOfObjs;

    var uniqueMySurveysTeammateContactCompanyIDsArray = [];
    var mySurveysTeammatesArrayOfObjs = [];
    for(let mySentSurveyObj of c_mySurveysAllMySentSurveysArrayOfObjs) {
      for(let mySentSurveyResponseTimeObj of mySentSurveyObj.surveyResponseTimesArrayOfObjs) {
        if(!JSFUNC.in_array(mySentSurveyResponseTimeObj.teammateContactCompanyID, uniqueMySurveysTeammateContactCompanyIDsArray)) {
          uniqueMySurveysTeammateContactCompanyIDsArray.push(mySentSurveyResponseTimeObj.teammateContactCompanyID);

          mySurveysTeammatesArrayOfObjs.push({
            value: mySentSurveyResponseTimeObj.teammateContactCompanyID,
            display: mySentSurveyResponseTimeObj.teammateNamePlainText
          });
        }
      }
    }
    JSFUNC.sort_arrayOfObjs(mySurveysTeammatesArrayOfObjs, "display", true);

    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("Teammate", mySurveysTeammatesArrayOfObjs, "value", false, "display", swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }


  get c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs() {
    const o_mySurveysSelectedFilterTeammateContactCompanyID = this.o_mySurveysSelectedFilterTeammateContactCompanyID;
    const c_mySurveysAllMySentSurveysArrayOfObjs = this.c_mySurveysAllMySentSurveysArrayOfObjs;

    if((o_mySurveysSelectedFilterTeammateContactCompanyID !== -2) && !JSFUNC.select_int_is_filled_out_tf(o_mySurveysSelectedFilterTeammateContactCompanyID)) {
      return(c_mySurveysAllMySentSurveysArrayOfObjs);
    }

    var mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs = [];
    for(let mySentSurveyObj of c_mySurveysAllMySentSurveysArrayOfObjs) {
      var filteredSurveyResponseTimesArrayOfObjs = [];
      for(let mySentSurveyResponseTimeObj of mySentSurveyObj.surveyResponseTimesArrayOfObjs) {
        if(mySentSurveyResponseTimeObj.teammateContactCompanyID === o_mySurveysSelectedFilterTeammateContactCompanyID) {
          filteredSurveyResponseTimesArrayOfObjs.push(mySentSurveyResponseTimeObj);
        }
      }

      if(filteredSurveyResponseTimesArrayOfObjs.length > 0) {
        mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs.push({
          surveyInfoObj: mySentSurveyObj.surveyInfoObj,
          surveyResponseTimesArrayOfObjs: filteredSurveyResponseTimesArrayOfObjs
        });
      }
    }

    return(mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs);
  }


  get c_mySurveysUniqueIDIQsArrayOfObjs() {
    const c_mySurveysAllMySentSurveysArrayOfObjs = this.c_mySurveysAllMySentSurveysArrayOfObjs;

    //gather a list of unique capture link IDIQs from the entire list of surveys
    var uniqueCaptureIDIQCaptureIDsArray = [];
    for(let mySentSurveyObj of c_mySurveysAllMySentSurveysArrayOfObjs) {
      if(!JSFUNC.in_array(mySentSurveyObj.surveyInfoObj.captureIDIQCaptureID, uniqueCaptureIDIQCaptureIDsArray)) {
        uniqueCaptureIDIQCaptureIDsArray.push(mySentSurveyObj.surveyInfoObj.captureIDIQCaptureID);
      }
    }

    var mySurveysUniqueIDIQsArrayOfObjs = [];
    for(let uniqueCaptureIDIQCaptureID of uniqueCaptureIDIQCaptureIDsArray) {
      if(DatabaseMobx.o_tbl_captures.has(uniqueCaptureIDIQCaptureID)) {
        mySurveysUniqueIDIQsArrayOfObjs.push({
          idiqCaptureID: uniqueCaptureIDIQCaptureID,
          idiqCaptureName: DatabaseMobx.capture_name_plaintext_from_capture_id(uniqueCaptureIDIQCaptureID)
        });
      }
    }
    return(mySurveysUniqueIDIQsArrayOfObjs);
  }


  get c_mySurveysSelectedSingleIDIQSurveysFilteredBySelectedTeammateObj() {
    const o_mySurveysSelectedFilterIDIQCaptureID = this.o_mySurveysSelectedFilterIDIQCaptureID;
    const c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs = this.c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs;
    const c_mySurveysUniqueIDIQsArrayOfObjs = this.c_mySurveysUniqueIDIQsArrayOfObjs;

    var idiqCapturesWithSurveysArrayOfObjs = [];

    if(JSFUNC.select_int_is_filled_out_tf(o_mySurveysSelectedFilterIDIQCaptureID)) {
      //find all unique captureIDs from all the surveys within the IDIQ
      var uniqueCaptureIDsWithinIDIQArray = [];
      for(let mySentSurveyObj of c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs) {
        if(mySentSurveyObj.surveyInfoObj.captureIDIQCaptureID === o_mySurveysSelectedFilterIDIQCaptureID) {
          if(!JSFUNC.in_array(mySentSurveyObj.surveyInfoObj.capture_id, uniqueCaptureIDsWithinIDIQArray)) {
            uniqueCaptureIDsWithinIDIQArray.push(mySentSurveyObj.surveyInfoObj.capture_id);
          }
        }
      }

      //gather all the captures within this IDIQ
      for(let uniqueCaptureIDWithinIDIQ of uniqueCaptureIDsWithinIDIQArray) {
        //filter for each captureID and gather all surveys under each capture
        var idiqCaptureSurveysArrayOfObjs = [];
        var captureID = -1;
        var captureNamePlainText = "";
        var captureIDIQCaptureID = -1;
        for(let mySentSurveyObj of c_mySurveysMySentSurveysFilteredBySelectedTeammateArrayOfObjs) {
          if(mySentSurveyObj.surveyInfoObj.capture_id === uniqueCaptureIDWithinIDIQ) {
            idiqCaptureSurveysArrayOfObjs.push({
              surveyInfoObj: mySentSurveyObj.surveyInfoObj,
              surveyResponseTimesArrayOfObjs: mySentSurveyObj.surveyResponseTimesArrayOfObjs
            });
            captureID = mySentSurveyObj.surveyInfoObj.capture_id;
            captureNamePlainText = mySentSurveyObj.surveyInfoObj.captureNamePlainText;
            captureIDIQCaptureID = mySentSurveyObj.surveyInfoObj.captureIDIQCaptureID;
          }
        }

        if(idiqCaptureSurveysArrayOfObjs.length > 0) {
          idiqCapturesWithSurveysArrayOfObjs.push({
            captureID: captureID,
            captureNamePlainText: captureNamePlainText,
            captureIDIQCaptureID: captureIDIQCaptureID,
            idiqCaptureSurveysArrayOfObjs: idiqCaptureSurveysArrayOfObjs
          });
        }
      }
    }

    return({
      idiqCaptureID: o_mySurveysSelectedFilterIDIQCaptureID,
      idiqCaptureName: DatabaseMobx.capture_name_plaintext_from_capture_id(o_mySurveysSelectedFilterIDIQCaptureID),
      idiqCapturesWithSurveysArrayOfObjs: idiqCapturesWithSurveysArrayOfObjs
    });
  }


  get c_mySurveysSelectIDIQCaptureFieldTypeObj() {
    const c_mySurveysUniqueIDIQsArrayOfObjs = this.c_mySurveysUniqueIDIQsArrayOfObjs;

    var mySurveysIDIQsArrayOfObjs = [];
    for(let uniqueIDIQObj of c_mySurveysUniqueIDIQsArrayOfObjs) {
      mySurveysIDIQsArrayOfObjs.push({
        value: uniqueIDIQObj.idiqCaptureID,
        display: uniqueIDIQObj.idiqCaptureName
      });
    }
    JSFUNC.sort_arrayOfObjs(mySurveysIDIQsArrayOfObjs, "display", true);

    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("IDIQ Capture", mySurveysIDIQsArrayOfObjs, "value", false, "display", swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }








  //===action methods==============================================================================================
  a_set_media_query_flag(i_mediaQueryFlag) {
    //force left nav open/closed when changing to or from mobile/tablet and desktop
    if((this.o_mediaQueryFlag > 2) && (i_mediaQueryFlag <= 2)) {
      this.a_set_left_nav_is_expanded_tf(false); //force the left nav closed when shrinking to mobile/tablet screen (does not update user preference in database)
    }
    else if((this.o_mediaQueryFlag < 3) && (i_mediaQueryFlag >= 3)) {
      this.a_set_left_nav_is_expanded_tf(UserMobx.c_userLeftNavIsExpandedTF); //return left nav to the user preference when expanding out of mobile/tablet
    }

    this.o_mediaQueryFlag = i_mediaQueryFlag;
  }


  a_set_enquire_media_width_from_font_size_and_right_panel(i_fontSizePx, i_rightPanelWidthEm) { //enquire.js media query for screen size, continuously updating screen size flag kept in this.o_mediaQueryFlag
    //if there are already enqires registered, unregister them all now
    if(this.o_mediaQuery1) { //if 1 is set, they are all set, remove them all in 1 enquire unregister call
      enquire
      .unregister(this.o_mediaQuery1)
      .unregister(this.o_mediaQuery2)
      .unregister(this.o_mediaQuery3)
      .unregister(this.o_mediaQuery4)
      .unregister(this.o_mediaQuery5)
      .unregister(this.o_mediaQuery6);
    }

    //determine the width in pixels of the right panel if it is open
    const rightPanelWidthPx = ((i_rightPanelWidthEm > 0) ? (i_rightPanelWidthEm * i_fontSizePx) : (0));

    //define the o_mediaQueryFlag boundaries in px based on font size
    const width12 = (i_fontSizePx * 58); //right panel sits on top of the system in mediaQueryFlags 1 and 2, thus they don't need to adjust with the panel size between 1 and 2
    const width23 = (i_fontSizePx * 87) + rightPanelWidthPx;
    const width34 = (i_fontSizePx * 115) + rightPanelWidthPx;
    const width45 = (i_fontSizePx * 150) + rightPanelWidthPx;
    const width56 = (i_fontSizePx * 200) + rightPanelWidthPx;

    const newMediaQuery1 = "screen and (max-width:" + width12 + "px)";
    const newMediaQuery2 = "screen and (min-width:" + width12 + "px) and (max-width:" + width23 + "px)";
    const newMediaQuery3 = "screen and (min-width:" + width23 + "px) and (max-width:" + width34 + "px)";
    const newMediaQuery4 = "screen and (min-width:" + width34 + "px) and (max-width:" + width45 + "px)";
    const newMediaQuery5 = "screen and (min-width:" + width45 + "px) and (max-width:" + width56 + "px)";
    const newMediaQuery6 = "screen and (min-width:" + width56 + "px)";

    enquire
    .register(newMediaQuery1, (() => this.a_set_media_query_flag(1)))
    .register(newMediaQuery2, (() => this.a_set_media_query_flag(2)))
    .register(newMediaQuery3, (() => this.a_set_media_query_flag(3)))
    .register(newMediaQuery4, (() => this.a_set_media_query_flag(4)))
    .register(newMediaQuery5, (() => this.a_set_media_query_flag(5)))
    .register(newMediaQuery6, (() => this.a_set_media_query_flag(6)));

    this.o_mediaQuery1 = newMediaQuery1;
    this.o_mediaQuery2 = newMediaQuery2;
    this.o_mediaQuery3 = newMediaQuery3;
    this.o_mediaQuery4 = newMediaQuery4;
    this.o_mediaQuery5 = newMediaQuery5;
    this.o_mediaQuery6 = newMediaQuery6;
  }


  //nowDate
  a_update_now_date_and_now_datetime_to_current_date() {
    const o_itemEditingCaptureDashCardDashItemID = this.o_itemEditingCaptureDashCardDashItemID;
    const o_itemEditingContactFieldUniqueString = this.o_itemEditingContactFieldUniqueString;

    if((o_itemEditingCaptureDashCardDashItemID === undefined) && (o_itemEditingContactFieldUniqueString === undefined)) { //do not update the times if a field is currently being edited (prevents difficulty manually typing in an edited date)
      //now date (only update if it has changed date)
      const nowDateLocal = JSFUNC.now_date();
      if(this.o_nowDate !== nowDateLocal) {
        this.o_nowDate = nowDateLocal;
      }
    }
  }


  //development error message and data tables
  a_set_error_message(i_newValue) {
    this.o_errorMessage = i_newValue;
  }

  a_close_error_message() {
    this.o_errorMessage = null;
  }





  //PHP Interface
  a_set_php_file_is_currently_running_tf(i_newValueTF) {
    this.o_phpFileIsCurrentlyRunningTF = i_newValueTF;
  }






  //Login
  a_set_initial_full_website_address(i_newValueString) {
    this.o_initialFullWebsiteAddress = i_newValueString;
  }

  a_set_is_localhost_3000_system_tf(i_newValueTF) {
    this.o_isLocalhost3000SystemTF = i_newValueTF;
  }

  a_set_is_dev_system_tf(i_newValueTF) {
    this.o_isDevSystemTF = i_newValueTF;
  }




  a_run_verifications_to_initialize_login_from_url() {
    const k_onPremiseCustomerTF = this.k_onPremiseCustomerTF;
    const k_onPremiseBitProduct = this.k_onPremiseBitProduct;
    const k_onPremiseCustomerCheckAuthenticationQuarterlyPasscodeFileTF = this.k_onPremiseCustomerCheckAuthenticationQuarterlyPasscodeFileTF;
    const c_userFontSizePx = UserMobx.c_userFontSizePx; //uses a default value of 12 before the user preference is known from the database

    //browser url is in one of these 4 formats
    // - "https://captureexec.com/"
    // - "https://captureexec.com/?c=ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ABCDEFGH"  (teammate survey)
    // - "https://captureexec.com/dev/"
    // - "https://samgovtracker.com/"
    // - "https://samgovtracker.com/?gclid=Cj0KCQiAi8KfBhCuARIsADp-A56rSwggdF-MpRCE6-5CsOz-R08585aKVtFkk5XNWljrnuZqhP_6aUoaAk9_EALw_wcB"  (SGT linked from Google Ad)
    // - "http://localhost:3000/"
    const fullUrlAddress = document.location.href;
    this.a_set_initial_full_website_address(fullUrlAddress);

    //determine if url is localhost
    const localhostWebAddress = "http://localhost:3000/";
    const urlAddressIsLocalhost3000SystemTF = (fullUrlAddress === localhostWebAddress);
    this.a_set_is_localhost_3000_system_tf(urlAddressIsLocalhost3000SystemTF);

    //determine if url is dev site
    const productString = this.compute_product_string_from_initial_full_website_address_or_on_premise_product(k_onPremiseCustomerTF, k_onPremiseBitProduct, fullUrlAddress, urlAddressIsLocalhost3000SystemTF);
    const productWebsiteAddress = this.compute_product_website_address_ending_slash_and_dev_removed_or_undefined(fullUrlAddress, urlAddressIsLocalhost3000SystemTF, productString);
    const devWebAddress = productWebsiteAddress + "/dev/";
    const urlAddressIsDevSystemTF = (fullUrlAddress === devWebAddress);
    this.a_set_is_dev_system_tf(urlAddressIsDevSystemTF);

    //determine if url has direct access link (search url for "?c="), if so, change loginState to loading while all data loads, if access link is later found to be invalid, loginState is reverted back to manual login
    const directAccessLink70charsOrUndefined = this.isolate_direct_access_link_70_chars_or_undefined_from_full_url_address(fullUrlAddress);
    if(directAccessLink70charsOrUndefined !== undefined) { //url appears to have a valid direct access link
      this.a_set_login_state("loadingCaptureExec");
    }

    //localhost system, set all Mobx variables with hardcoded localhost data
    if(urlAddressIsLocalhost3000SystemTF) {
      DevelopmentInitializeDataMobx.a_initialize_development_data();
    }

    const verifyOnPremiseAuthenticationFileTF = (k_onPremiseCustomerTF && k_onPremiseCustomerCheckAuthenticationQuarterlyPasscodeFileTF && !urlAddressIsLocalhost3000SystemTF);
    const verifyCodeVersionTrueBypassFalse = (!urlAddressIsLocalhost3000SystemTF);
    const verifyDirectAccessLinkTrueBypassFalse = (!urlAddressIsLocalhost3000SystemTF);

    //1) On-Prem Auth, 2) Code Version, 3) Direct Access Link
    const functionOnSuccessVerifyOnPremiseAuthentication = () => {
      const functionOnSuccessVerifyCodeVersion = () => {
        this.a_verify_single_capture_direct_access_link_to_bypass_login_from_url(verifyDirectAccessLinkTrueBypassFalse, fullUrlAddress); //3) check for url GET variable 'c' which enables direct access link to a single capture
      }
      this.a_verify_user_browser_live_or_dev_code_version(verifyCodeVersionTrueBypassFalse, urlAddressIsDevSystemTF, functionOnSuccessVerifyCodeVersion); //2) verify live or dev code version in BITcompanies database
    }
    this.a_verify_on_premise_customer_authentication_quarterly_passcode_file(verifyOnPremiseAuthenticationFileTF, functionOnSuccessVerifyOnPremiseAuthentication); //1) verify on premise customer quarterly authentication file

    //for on premise, every 1 hour verify the authentication passcode so that users can't keep a page open permanently past the authentication period
    if(verifyOnPremiseAuthenticationFileTF) {
      this.a_start_verify_on_premise_authentication_timed_interval();
    }

    //every 1 hour (only on login screen) verify live/dev code version to handle case where user leaves captureexec.com on login screen for several days then logs in (during which a code update was released), this 1 hour check will change login to the forced refresh screen when the update happened
    if(verifyCodeVersionTrueBypassFalse) {
      this.a_start_verify_user_browser_live_or_dev_code_version_timed_interval(verifyCodeVersionTrueBypassFalse, urlAddressIsDevSystemTF);
    }

    //set initial mediaQueryFlag based on screen size
    const initialRightPanelWidthEm = 0; //default right panel state is closed, specify the width as 0
    this.a_set_enquire_media_width_from_font_size_and_right_panel(c_userFontSizePx, initialRightPanelWidthEm);
  }


  a_verify_on_premise_customer_authentication_quarterly_passcode_file(i_verifyAuthenticationFileTrueBypassFalse=true, i_functionOnSuccess=undefined) {
    if(i_verifyAuthenticationFileTrueBypassFalse) {
      const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_verify_on_premise_customer_authentication_quarterly_passcode_file", [], []);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("verifyOnPremiseCustomerAuthenticationQuarterlyPasscodeFile", jsDescription);
      C_CallPhpScript.add_return_vars("passcode");

      //on success verify the authentication passcode using the opa() function
      const functionOnSuccess = (i_parseResponse) => {
        var onPremiseAuthenticationExpirationDate = this.opa(i_parseResponse.passcode);
        var todayDate = JSFUNC.now_date();
        if(JSFUNC.date_is_filled_out_tf(onPremiseAuthenticationExpirationDate) && (onPremiseAuthenticationExpirationDate >= todayDate)) { //on-premise customer is authenticated for today's date
          if(JSFUNC.is_function(i_functionOnSuccess)) {
            i_functionOnSuccess();
          }
        }
        else { //unsuccessful on-premise authentication from passcode, redirect login page to 'not authenticated' screen where a new passcode file can be uploaded
          this.a_set_login_state("onPremiseNotAuthenticated");
        }
      }
      C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

      //on error redirect to the version mismatch page which requests a refresh to try again
      const functionOnError = () => {
        this.a_set_login_state("codeVersionMismatch");
      }
      C_CallPhpScript.add_function("onError", functionOnError);

      C_CallPhpScript.execute();
    }
    else { //not doing on-premise authentication (this is a cloud based customer), skip the check and run the success function
      if(JSFUNC.is_function(i_functionOnSuccess)) {
        i_functionOnSuccess();
      }
    }
  }

  a_start_verify_on_premise_authentication_timed_interval() {
    const waitTimeMs = (3600 * 1000); //1 hour interval time
    this.o_verifyOnPremiseAuthenticationTimedIntervalID = setInterval(() => this.a_verify_on_premise_customer_authentication_quarterly_passcode_file(), waitTimeMs);
  }

  a_clear_verify_on_premise_authentication_timed_interval() {
    clearInterval(this.o_verifyOnPremiseAuthenticationTimedIntervalID);
  }


  a_verify_user_browser_live_or_dev_code_version(i_verifyCodeVersionTrueBypassFalse=true, i_urlAddressIsDevSystemTF=false, i_functionOnSuccess=undefined) {
    const o_overrideCodeVersionMismatchTF = this.o_overrideCodeVersionMismatchTF;

    if(i_verifyCodeVersionTrueBypassFalse && !o_overrideCodeVersionMismatchTF) {
      //fetch live or dev version and compare with browser version
      const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_verify_user_browser_live_or_dev_code_version", ["i_urlAddressIsDevSystemTF"], [i_urlAddressIsDevSystemTF]);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadBitCodeVersions", jsDescription);
      C_CallPhpScript.add_return_vars(["bitDatabaseDevCodeVersion", "bitDatabaseLiveCodeVersion"]);

      const functionOnSuccess = (i_parseResponse) => {
        const userBrowserCodeVersion = ((i_urlAddressIsDevSystemTF) ? (this.k_userBrowserDevCodeVersion) : (this.k_userBrowserLiveCodeVersion));
        const bitDatabaseCodeVersion = ((i_urlAddressIsDevSystemTF) ? (i_parseResponse.bitDatabaseDevCodeVersion) : (i_parseResponse.bitDatabaseLiveCodeVersion));
        if(bitDatabaseCodeVersion === "") { //blank code version returning from the database means loading from the database tbl had an error, show the mismatch screen to prompt a refresh
          this.a_set_login_state("codeVersionMismatch");
        }
        else if(userBrowserCodeVersion !== bitDatabaseCodeVersion) { //if the code version stored in this user's browser does not match the database value, redirect the user to a page that tells them to refresh
          this.a_set_login_state("codeVersionMismatch");

          //record an error message
          var codeVersionErrorMessage = "userBrowserCodeVersion: " + userBrowserCodeVersion + ", bitDatabaseCodeVersion: " + bitDatabaseCodeVersion;
          JSPHP.record_z_error(jsDescription, codeVersionErrorMessage);
        }
        else { //code versions match, run success function if provided
          if(JSFUNC.is_function(i_functionOnSuccess)) {
            i_functionOnSuccess();
          }
        }
      }
      C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

      //on error redirect to the version mismatch page which requests a refresh to try again
      const functionOnError = () => {
        this.a_set_login_state("codeVersionMismatch");
      }
      C_CallPhpScript.add_function("onError", functionOnError);

      C_CallPhpScript.execute();
    }
    else { //not doing code version check, call the success function if provided
      if(JSFUNC.is_function(i_functionOnSuccess)) {
        i_functionOnSuccess();
      }
    }
  }

  a_start_verify_user_browser_live_or_dev_code_version_timed_interval(i_verifyCodeVersionTrueBypassFalse=true, i_urlAddressIsDevSystemTF=false) {
    const waitTimeMs = (3600 * 1000); //1 hour interval time (waits full interval duration before calling for the first time)
    this.o_verifyUserBrowserLiveOrDevCodeVersionTimedIntervalID = setInterval(() => this.a_verify_user_browser_live_or_dev_code_version(i_verifyCodeVersionTrueBypassFalse, i_urlAddressIsDevSystemTF), waitTimeMs);
  }

  a_clear_verify_user_browser_live_or_dev_code_version_timed_interval() {
    clearInterval(this.o_verifyUserBrowserLiveOrDevCodeVersionTimedIntervalID);
  }


  a_set_override_code_version_mismatch_tf(i_newValueTF) {
    this.o_overrideCodeVersionMismatchTF = i_newValueTF;
  }


  a_verify_single_capture_direct_access_link_to_bypass_login_from_url(i_verifyDirectAccessLinkTrueBypassFalse=true, i_fullUrlAddress="") {
    if(i_verifyDirectAccessLinkTrueBypassFalse) {
      //initialize the single capture direct access mode flag to false (in case url is invalid you can manually log in and have a normal system), set to true when link is verified
      this.a_set_captureexec_in_single_capture_direct_access_mode_tf(false);

      //determine if the link is in a valid format, otherwise remain on the manual login screen
      const directAccessLink70charsOrUndefined = this.isolate_direct_access_link_70_chars_or_undefined_from_full_url_address(i_fullUrlAddress);

      //if the direct access link in the url is invalid (doesn't match capture direct_access_link 64 char string), send user to the manual login
      if(directAccessLink70charsOrUndefined === undefined) {
        this.a_set_login_state("out");
      }
      else { //if set, verify the provided direct access link matches the specified company code jumble, captureID, and userID in the database tbl_captures
        //char index locations within 70 char long string (r - random A-Za-z0-9) "rrrrrrrrrr rrJrJrUrrU rUrCrrrrrJ UrrUrJrrrJ rrCrrrrCrr rrJCrrrUrr rCrrCrrrrr"
        const directAccessCompanyJumbleFromUrl = JSFUNC.create_string_from_string_and_array_of_char_indices(directAccessLink70charsOrUndefined, [12, 14, 29, 35, 39, 52]); //12, 14, 29, 35, 39, 52 - (J) customer BITcompanies 6 char unique jumble
        const directAccessCaptureIDBase52FromUrl = JSFUNC.create_string_from_string_and_array_of_char_indices(directAccessLink70charsOrUndefined, [23, 42, 47, 53, 61, 64]); //23, 42, 47, 53, 61, 64 - (C) captureID as base52String
        const directAccessUserIDBase52FromUrl = JSFUNC.create_string_from_string_and_array_of_char_indices(directAccessLink70charsOrUndefined, [16, 19, 21, 30, 33, 57]); //16, 19, 21, 30, 33, 57 - (U) userID as base52String

        //remove the 6 userID chars from the 70 char string to create the 64 char direct_access_link field in each tbl_captures record, check database to see if that 64 char matches for the given company/captureID
        const captureDirectAccessLink64CharsFromUrl = JSFUNC.remove_chars_in_string_from_string_and_array_of_char_indices(directAccessLink70charsOrUndefined, [16, 19, 21, 30, 33, 57]);

        //convert the captureID and userID from base52 6 char A-Za-z strings to ints
        const directAccessCaptureIDIntFromUrl = JSFUNC.convert_base_52_string_to_int(directAccessCaptureIDBase52FromUrl);
        const directAccessUserIDIntFromUrl = JSFUNC.convert_base_52_string_to_int(directAccessUserIDBase52FromUrl);

        //verify 64 char string from url against database single capture direct_access_link field, bypass login and load all data for single capture if it matches (remain on manual login page if unsuccessful)
        const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_verify_single_capture_direct_access_link_to_bypass_login_from_url", ["i_verifyDirectAccessLinkTrueBypassFalse", "i_fullUrlAddress"], [i_verifyDirectAccessLinkTrueBypassFalse, i_fullUrlAddress]);
        const C_CallPhpScript = new JSPHP.ClassCallPhpScript("single_capture_direct_access_link_bypass_login_load_all_data", jsDescription);
        C_CallPhpScript.add_post_var("i_companyJumble", directAccessCompanyJumbleFromUrl);
        C_CallPhpScript.add_post_var("i_captureID", directAccessCaptureIDIntFromUrl);
        C_CallPhpScript.add_post_var("i_userID", directAccessUserIDIntFromUrl);
        C_CallPhpScript.add_post_var("i_directAccessLink", captureDirectAccessLink64CharsFromUrl);
        C_CallPhpScript.add_post_var("i_tblNamesToLoadComma", DatabaseMobx.c_singleCaptureDirectAccessLinkLoginTblNamesToLoadArray);
        C_CallPhpScript.add_return_vars(["companyCode", "userPerEmailID", "loginDataTablesArray"]);

        const functionOnSuccess = (i_parseResponse) => {
          const companyCode = i_parseResponse.companyCode;
          const userPerEmailID = i_parseResponse.userPerEmailID;
          const loginDataTablesArray = i_parseResponse.loginDataTablesArray; //data tbls loaded for single capture direct access login phase of loading

          //verify from returned flags that matching the directAccessLink was successful and the login data was loaded
          if((companyCode !== "") && (userPerEmailID > 0)) {
            //set the system mobx var to disable any navigation in the system other than within the open capture
            this.a_set_captureexec_in_single_capture_direct_access_mode_tf(true);
            this.a_set_loading_through_phase1_complete_tf(false); //flag that phase 1 has started loading (displays Loading CaptureExec instead of CST)
            this.a_set_loading_through_phase2_complete_tf(false);

            //check if license is expired, then load all data and set the login state to "in" (bypassing the full login process)
            const newLoginState = "in";
            const loginWithKeyTF = false;
            this.a_after_login_verify_license_set_mobx_data_change_login_state(newLoginState, loginWithKeyTF, directAccessUserIDIntFromUrl, userPerEmailID, companyCode, loginDataTablesArray);

            //load phase 1 (single capture and all capture supplement tbls filtered for that single capture) and phase 2 (contacts, BIT help)
            const singleCaptureDirectAccessLinkPhase1TblNamesToLoadComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_singleCaptureDirectAccessLinkPhase1TblNamesToLoadArray);
            const forceLoadArchivedCapturesTF = true; //able to force an archived single capture to load with direct access link regardless of user setting on loading archived captures
            const singleCaptureIDOrM1AllCaptures = directAccessCaptureIDIntFromUrl; //load only single capture
            const functionOnFinishPhase1 = () => {
              OpenCaptureMobx.a_set_open_capture_id(directAccessCaptureIDIntFromUrl); //open the single capture
              JSPHP.load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array(DatabaseMobx.c_singleCaptureDirectAccessLinkPhase2TblNamesToLoadArray);
              this.a_set_loading_through_phase1_complete_tf(true);
              this.a_set_loading_through_phase2_complete_tf(true);
            }
            JSPHP.load_db_data_to_local_memory_from_filtered_capture_tbl_names_comma(singleCaptureDirectAccessLinkPhase1TblNamesToLoadComma, forceLoadArchivedCapturesTF, singleCaptureIDOrM1AllCaptures, functionOnFinishPhase1);
          }
          else { //if the 64 char string did not match the capture direct access link
            this.a_set_login_state("out");
          }
        }
        C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

        //on error redirect to the version mismatch page which requests a refresh to try again
        const functionOnError = () => {
          this.a_set_login_state("codeVersionMismatch");
        }
        C_CallPhpScript.add_function("onError", functionOnError);

        C_CallPhpScript.execute();
      }
    }
  }

  isolate_direct_access_link_70_chars_or_undefined_from_full_url_address(i_fullUrlAddress) {
    if(JSFUNC.is_string(i_fullUrlAddress)) {
      const urlQuestionSplitArray = i_fullUrlAddress.split("?c="); //["https://captureexec.com/", "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ABCDEFGH&othervar1=junk&othervar2=junk#junkref"]
      if(urlQuestionSplitArray.length === 2) {
        const directAccessLinkWithAmpsAndHashes = urlQuestionSplitArray[1]; //"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ABCDEFGH&othervar1=junk&othervar2=junk#junkref"
        const hashSplitArray = directAccessLinkWithAmpsAndHashes.split("#"); //["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ABCDEFGH&othervar1=junk&othervar2=junk", "junkref"]
        const directAccessLinkWithAmps = hashSplitArray[0]; //"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ABCDEFGH&othervar1=junk&othervar2=junk"
        const ampSplitArray = directAccessLinkWithAmps.split("&"); //["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ABCDEFGH", "othervar1=junk", "othervar2=junk"]
        const directAccessLink70chars = ampSplitArray[0]; //"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789ABCDEFGH"
        if(directAccessLink70chars.length === 70) {
          return(directAccessLink70chars);
        }
      }
    }
    return(undefined); //url address does not have a valid 70 char direct access link
  }

  a_set_captureexec_in_single_capture_direct_access_mode_tf(i_newValueTF) {
    this.o_captureExecInSingleCaptureDirectAccessModeTF = i_newValueTF;
  }


  a_set_login_state(i_newLoginState) {
    const o_isLocalhost3000SystemTF = this.o_isLocalhost3000SystemTF;
    
    if(i_newLoginState !== this.o_loginState) { //set the new login state (if it will change from its current value)
      this.o_loginState = i_newLoginState;

      if(i_newLoginState === "codeVersionMismatch") {
        this.a_clear_verify_user_browser_live_or_dev_code_version_timed_interval();
      }
      else if(i_newLoginState === "out") {
        if(!o_isLocalhost3000SystemTF) { //only need to run version verifications for live/dev system, localhost3000 system loads all DevInitData vars and overwrites o_loginState to that value
          this.a_run_verifications_to_initialize_login_from_url();
        }
      }
    }
  }


  a_set_company_code_input(i_newValue) { this.o_companyCodeInput = i_newValue; }
  a_set_email_input(i_newValue) { this.o_emailInput = i_newValue; }
  a_set_password_input(i_newValue) { this.o_passwordInput = i_newValue; }
  a_set_company_code_input_error_tf(i_newValue) { this.o_companyCodeInputErrorTF = i_newValue; }
  a_set_email_input_error_tf(i_newValue) { this.o_emailInputErrorTF = i_newValue; }
  a_set_login_message_obj(i_messageObj) { this.o_loginMessageObj = i_messageObj; }
  a_set_logging_in_user_email(i_newValue) { this.o_loggingInUserEmail = i_newValue; }

  a_set_old_password_input(i_newValue) { this.o_oldPasswordInput = i_newValue; }
  a_set_new_password1_input(i_newValue) { this.o_newPassword1Input = i_newValue; }
  a_set_new_password2_input(i_newValue) { this.o_newPassword2Input = i_newValue; }
  a_set_old_password_input_error_tf(i_newValue) { this.o_oldPasswordInputErrorTF = i_newValue; }
  a_set_new_password1_input_error_tf(i_newValue) { this.o_newPassword1InputErrorTF = i_newValue; }
  a_set_new_password2_input_error_tf(i_newValue) { this.o_newPassword2InputErrorTF = i_newValue; }
  a_set_change_password_message_obj(i_messageObj) { this.o_changePasswordMessageObj = i_messageObj; }

  a_change_password_full_reset() {
    this.a_set_old_password_input("");
    this.a_set_new_password1_input("");
    this.a_set_new_password2_input("");
    this.a_set_old_password_input_error_tf(false);
    this.a_set_new_password1_input_error_tf(false);
    this.a_set_new_password2_input_error_tf(false);
    this.a_set_change_password_message_obj(null);
  }


  a_logout(i_logoutTypeString=undefined) {
    const c_productStylingObj = this.c_productStylingObj;

    //most logout calls will redirect to the "out" starting page to log in again
    var newLoginState = "out";

    var loginMessageCssClass = undefined;
    var loginMessage = undefined;
    if(i_logoutTypeString === "userLoggedOut") {
      loginMessageCssClass = "loginSuccess";
      loginMessage = "Logged out of " + c_productStylingObj.productName + ". We'll see you next time!";
    }
    else if(i_logoutTypeString === "sessionExpire") {
      loginMessageCssClass = "loginSystemError";
      loginMessage = "Your " + c_productStylingObj.productName + " session has expired from inactivity. Please log in again.";
    }
    else if(i_logoutTypeString === "codeUpdate") {
      newLoginState = "codeUpdate";
    }

    //add a message to the login box for when the login state is changed to "out"
    if(loginMessage !== undefined) {
      const loginMessageObj = JSFUNC.message_obj(loginMessageCssClass, loginMessage);
      this.a_set_login_message_obj(loginMessageObj);
    }

    //redirect the logged in system to a different login state to draw the login screen
    this.a_set_login_state(newLoginState);

    //manually clear Mobx data that hold their value even through logout (close any open capture)
    OpenCaptureMobx.a_close_single_capture();

    //clear all tbls in DatabaseMobx
    DatabaseMobx.a_clear_all_local_tbls();

    //mark that no data has been loaded in the system
    this.a_set_loading_through_phase1_complete_tf(false);
    this.a_set_loading_through_phase2_complete_tf(false);
  }


  a_login_or_forgot_password(i_loginForgotPasswordFlag) {
    const k_userBrowserDevCodeVersion = this.k_userBrowserDevCodeVersion;
    const k_userBrowserLiveCodeVersion = this.k_userBrowserLiveCodeVersion;
    const o_mediaQueryFlag = this.o_mediaQueryFlag;
    const o_isLocalhost3000SystemTF = this.o_isLocalhost3000SystemTF;
    const o_isDevSystemTF = this.o_isDevSystemTF;
    const c_productStylingObj = this.c_productStylingObj;

    //initialize the single capture direct access mode flag to false (in user was in direct access, then hit log out, then manually logs in, this gets the system back to its normal state)
    this.a_set_captureexec_in_single_capture_direct_access_mode_tf(false);

    //set the logging in user email used on the multilogin selection page while moving through the login process
    this.a_set_logging_in_user_email(this.o_emailInput);

    if(this.o_companyCodeInput.length === 0) {
      this.a_set_login_message_obj(JSFUNC.message_obj("loginUserError", "Please enter your " + c_productStylingObj.productName + " company code"));
      this.a_set_company_code_input_error_tf(true);
      this.a_set_email_input_error_tf(false);
    }
    else if(this.o_emailInput.length === 0) {
      this.a_set_login_message_obj(JSFUNC.message_obj("loginUserError", "Please enter the email address your " + c_productStylingObj.productName + " Admin has provided"));
      this.a_set_company_code_input_error_tf(false);
      this.a_set_email_input_error_tf(true);
    }
    else { //only allow the user through to the database verification step if the company code and email are not blank
      //reset the error messages
      this.a_set_login_message_obj(null);
      this.a_set_company_code_input_error_tf(false);
      this.a_set_email_input_error_tf(false);

      //allow the localhost sysetm to pass through
      if(o_isLocalhost3000SystemTF) {
        if(UserMobx.c_hasMultipleLoginsTF) { this.a_set_login_state("multiLogin"); }
        else { this.a_set_login_state("in"); }
        return;
      }

      //set load timing start for login
      this.a_set_load_timing_login_start_timestamp();

      const urlAddressIsDevSystem01 = ((o_isDevSystemTF) ? ("1") : ("0"));
      const userBrowserCodeVersion = ((o_isDevSystemTF) ? (k_userBrowserDevCodeVersion) : (k_userBrowserLiveCodeVersion));
      const timezoneString = JSFUNC.get_user_browser_timezone_string();
      const browserType = JSFUNC.get_user_browser_type_string();

      //call login.php
      const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_login_or_forgot_password", ["i_loginForgotPasswordFlag"], [i_loginForgotPasswordFlag]);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("login", jsDescription);
      C_CallPhpScript.add_post_var("i_productString", this.c_productStringFromInitialFullWebsiteAddressOrOnPremiseProduct);
      C_CallPhpScript.add_post_var("i_urlAddressIsDevSystem01", urlAddressIsDevSystem01);
      C_CallPhpScript.add_post_var("i_userBrowserCodeVersion", userBrowserCodeVersion);
      C_CallPhpScript.add_post_var("i_loginForgotPasswordFlag", i_loginForgotPasswordFlag);
      C_CallPhpScript.add_post_var("i_companyCodeValue", this.o_companyCodeInput);
      C_CallPhpScript.add_post_var("i_emailValue", this.o_emailInput);
      C_CallPhpScript.add_post_var("i_passwordValue", this.o_passwordInput);
      C_CallPhpScript.add_post_var("i_tblNamesToLoadComma", JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_loginTblNamesToLoadArray));
      C_CallPhpScript.add_post_var("i_timezoneString", timezoneString);
      C_CallPhpScript.add_post_var("i_browserType", browserType);
      C_CallPhpScript.add_post_var("i_mediaQueryFlag", o_mediaQueryFlag);
      C_CallPhpScript.add_return_vars(["newLoginState", "key", "userID", "userPerEmailID", "companyCode", "dataTables"]);

      const functionOnSuccess = (i_parseResponse) => {
        const newLoginState = i_parseResponse.newLoginState; //also doubles as error code string, translated below into the displayed error message
        const loginWithKeyTF = (i_parseResponse.key === "key");
        const userID = i_parseResponse.userID; //returns -1 if user has multiLogins to choose from (newLoginState will be "multiLogin")
        const userPerEmailID = i_parseResponse.userPerEmailID;
        const companyCode = i_parseResponse.companyCode;
        const dataTables = i_parseResponse.dataTables;

        if(newLoginState === "forgotPasswordSuccess") { //the forgot password button was pushed
          this.a_set_login_message_obj(JSFUNC.message_obj("loginSuccess", "A new temporary password for " + c_productStylingObj.productName + " has been emailed to you"));
        }
        else if(JSFUNC.in_array(newLoginState, ["adminPasswordChange", "forcedPasswordChange", "multiLogin", "in"])) { //successful login to either the multiLogin login page or into the system as a single user (or change password)
          this.a_after_login_verify_license_set_mobx_data_change_login_state(newLoginState, loginWithKeyTF, userID, userPerEmailID, companyCode, dataTables);

          //reset the login screen inputs to empty in case you return to login
          this.a_set_company_code_input("");
          this.a_set_email_input("");
          this.a_set_password_input("");

          //set load timing end for login
          this.a_set_load_timing_login_end_timestamp();
        }
        else if(newLoginState === "codeVersionMismatch") {
          this.a_set_login_state("codeVersionMismatch");
        }
        else { //login unsuccessful due to user input error, display a message under the login to alert the user of these issues
          var loginMessage = "";
          if(newLoginState === "databaseError") { loginMessage = "Failed to connect to " + c_productStylingObj.productName + " database. Please refresh to try again."; }
          if(newLoginState === "adminError") { loginMessage = "An issue with the setup of your " + c_productStylingObj.productName + " system is preventing login. BIT Solutions has been alerted and will resolve this issue."; } //duplicate company codes, no users matching the UPE, etc
          else if(newLoginState === "companyOrUserDNE") { loginMessage = "Company Code and Email combination entered could not be found"; }
          else if(newLoginState === "passwordWrong") { loginMessage = "Incorrect Password"; }
          else if(newLoginState === "lockedOut") { loginMessage = "'" + this.o_emailInput + "' is currently locked out after 5 unsuccessful login attempts.  Please contact your " + c_productStylingObj.productName + " Admin for assistance unlocking your account."; }
          else if(newLoginState === "disabled") { loginMessage = "'" + this.o_emailInput + "' is currently disabled in the system.  Please contact your " + c_productStylingObj.productName + " Admin for assistance unlocking your account."; }
          else { loginMessage = newLoginState; } //any unexpected newLoginState variable (should never happen if all types are accounted for in this if/else list)

          this.a_set_login_message_obj(JSFUNC.message_obj("loginUserError", loginMessage));
        }
      }
      C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

      const functionOnError = () => {
        var errorExplanation = "Could not reach " + c_productStylingObj.productName + " database\n\n";
        errorExplanation += "This is most likely caused by a " + c_productStylingObj.productName + " code update, however your browser is still holding the incompatible old version of the code in its memory. ";
        errorExplanation += "Please refresh this page and try to log in again. ";
        errorExplanation += "If this message persists, try closing your browser completely (all tabs) and reopening. ";
        errorExplanation += "If this further persists, contact BIT Solutions (support@bitsolutionsllc.com) for assistance.";
        this.a_set_login_message_obj(JSFUNC.message_obj("loginSystemError", errorExplanation));
      }
      C_CallPhpScript.add_function("onError", functionOnError);

      C_CallPhpScript.execute();
    }
  }


  a_after_login_verify_license_set_mobx_data_change_login_state(i_newLoginState, i_loginWithKeyTF, i_userID, i_userPerEmailID, i_companyCode, i_loginDataTablesObj) {
    const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_after_login_verify_license_set_mobx_data_change_login_state", ["i_newLoginState", "i_loginWithKeyTF", "i_userID", "i_userPerEmailID", "i_companyCode", "i_loginDataTablesObj"], [i_newLoginState, i_loginWithKeyTF, i_userID, i_userPerEmailID, i_companyCode, i_loginDataTablesObj]);

    //verify that the license has not expired (data source depends on on premise flag)
    var loginVerifyLicenseStartDate = JSFUNC.blank_date();
    var loginVerifyLicenseNumMonths = 0;
    if(this.k_onPremiseCustomerTF) { //on premise
      loginVerifyLicenseStartDate = this.k_onPremiseBitLicenseStartDate;
      loginVerifyLicenseNumMonths = this.k_onPremiseBitLicenseNumMonths;
    }
    else { //live cloud customer data from bitcompanies database tbl_companies company row
      loginVerifyLicenseStartDate = i_loginDataTablesObj["bitcompaniesTblRowMap"].license_start_date;
      loginVerifyLicenseNumMonths = i_loginDataTablesObj["bitcompaniesTblRowMap"].license_num_months;
    }

    const loginVerifyLicenseExpirationDate = JSFUNC.compute_end_date_from_start_date_and_num_months(loginVerifyLicenseStartDate, loginVerifyLicenseNumMonths);
    const todayDate = JSFUNC.now_date();

    if(todayDate > loginVerifyLicenseExpirationDate) {
      DatabaseMobx.a_insert_or_update_local_data_map("bitcompaniesTblRowMap", i_loginDataTablesObj["bitcompaniesTblRowMap"], true, jsDescription);
      this.a_set_login_state("licenseExpired");
    }
    else { //load data from login.php login phase into local memory (all admin tbls), proceed to either a password change, multilogin selection, or into the system
      //clear any mobx data that should be reset just in case
      OpenCaptureMobx.a_clear_single_capture_from_local_memory();

      //send all of the data sent from php to the root state variables
      UserMobx.a_set_login_with_key_tf(i_loginWithKeyTF);
      UserMobx.a_set_user_id(i_userID);
      UserMobx.a_set_user_per_email_id(i_userPerEmailID);
      UserMobx.a_set_user_company_code(i_companyCode);

      //store each table loaded from login.php into local memory
      const clearOldMapDataFirstTF = true;
      DatabaseMobx.a_insert_or_update_multiple_local_data_maps_with_uncompression_from_data_tbls_obj(i_loginDataTablesObj, clearOldMapDataFirstTF, jsDescription);

      //update the local logged in user's num logins and last login date (if the userID is valid, meaning this was a single login)
      if(i_userID > 0) {
        this.a_update_local_user_num_logins_and_last_login_datetime(i_userID);
      }

      //move to changePassword, multiLogin, or a full login, because of this page redirect, no need to erase error messages/indicators
      this.a_set_login_state(i_newLoginState);
    }
  }



  a_change_password(i_adminOrForcedOrUserFlag) {
    const o_isLocalhost3000SystemTF = this.o_isLocalhost3000SystemTF;
    const c_productStylingObj = this.c_productStylingObj;

    const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_change_password", ["i_adminOrForcedOrUserFlag"], [i_adminOrForcedOrUserFlag])

    //input checking
    if(!JSFUNC.in_array(i_adminOrForcedOrUserFlag, ["admin", "forced", "user"])) {
      JSPHP.record_z_error(jsDescription, "i_adminOrForcedOrUserFlag must be admin, forced, or user");
    }

    //localhost system quick fake routing
    if(o_isLocalhost3000SystemTF) {
      if(i_adminOrForcedOrUserFlag === "user") {
        this.a_set_change_password_message_obj(JSFUNC.message_obj("loginSuccess", "test of the password change system"));
      }
      else {
        this.a_set_login_state((UserMobx.c_hasMultipleLoginsTF) ? ("multiLogin") : ("in"));
      }
      return;
    }

    //live database system
    if(this.o_newPassword1Input !== this.o_newPassword2Input) {
      this.a_set_old_password_input_error_tf(false);
      this.a_set_new_password1_input_error_tf(true);
      this.a_set_new_password2_input_error_tf(true);
      this.a_set_change_password_message_obj(JSFUNC.message_obj("loginUserError", "New Password and Retyped New Password do not match"));
    }
    else if(this.o_newPassword1Input.length === 0) {
      this.a_set_old_password_input_error_tf(false);
      this.a_set_new_password1_input_error_tf(true);
      this.a_set_new_password2_input_error_tf(false);
      this.a_set_change_password_message_obj(JSFUNC.message_obj("loginUserError", "New Password cannot be blank"));
    }
    else if(i_adminOrForcedOrUserFlag !== "admin" && (this.o_oldPasswordInput === this.o_newPassword1Input)) { //admin does not verify the old password
      this.a_set_old_password_input_error_tf(true);
      this.a_set_new_password1_input_error_tf(true);
      this.a_set_new_password2_input_error_tf(false);
      this.a_set_change_password_message_obj(JSFUNC.message_obj("loginUserError", "New Password must be different from Old Password"));
    }
    else {
      //reset the inputs and error messages
      this.a_set_old_password_input_error_tf(false);
      this.a_set_new_password1_input_error_tf(false);
      this.a_set_new_password2_input_error_tf(false);
      this.a_set_change_password_message_obj(null);

      //call the change password database api
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("changePassword", jsDescription);
      C_CallPhpScript.add_post_var("i_adminOrForcedOrUserFlag", i_adminOrForcedOrUserFlag);
      C_CallPhpScript.add_post_var("i_oldPassword", this.o_oldPasswordInput);
      C_CallPhpScript.add_post_var("i_newPassword", this.o_newPassword1Input);
      C_CallPhpScript.add_return_vars("success");

      const functionOnSuccess = (i_parseResponse) => {
        if(i_parseResponse.success === "oldPasswordWrong") { //old password checked against the database was incorrect
          this.a_set_change_password_message_obj(JSFUNC.message_obj("loginUserError", "Old Password incorrect"));
        }
        else { //successful password change
          if(i_adminOrForcedOrUserFlag === "user") { //for a user panel password change, keep the user logged in, reset the password panel, and display a success message
            this.a_set_old_password_input("");
            this.a_set_new_password1_input("");
            this.a_set_new_password2_input("");
            this.a_set_change_password_message_obj(JSFUNC.message_obj("loginSuccess", "Password changed successfully"));
          }
          else { //changed password through the admin/forced process while logging in
            if(UserMobx.c_hasMultipleLoginsTF) { //if this user has a multiLogin to choose from
              this.a_set_login_state("multiLogin");
            }
            else { //otherwise, proceed with a full login to the captureexec system for this user
              this.a_set_login_state("in");
            }
          }
        }
      }
      C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

      const functionOnError = () => {
        this.a_set_change_password_message_obj(JSFUNC.message_obj("loginSystemError", "Could not reach " + c_productStylingObj.productName + " database"));
      }
      C_CallPhpScript.add_function("onError", functionOnError);

      C_CallPhpScript.execute();
    }
  }


  a_multi_login_selection_click(i_selectedUserID) {
    const o_mediaQueryFlag = this.o_mediaQueryFlag;
    const o_isLocalhost3000SystemTF = this.o_isLocalhost3000SystemTF;

    const userIsAlreadyLoggedInTF = (this.o_loginState === "in");

    //set the newly selected userID
    UserMobx.a_set_user_id(i_selectedUserID);

    //locally updated the selected user's num logins and last login date
    this.a_update_local_user_num_logins_and_last_login_datetime(i_selectedUserID);

    //if this multilogin was part of the login process
    if(!userIsAlreadyLoggedInTF) {
      this.a_set_login_state("in"); //redirect the loginState to "in" from "multiLogin" (no need to set to "in" if selecting from the user panel where you are already "in")
    }
    else { //if you are already logged in and are changing the multiLogin user from the user panel
      RightPanelMobx.a_set_open_panel(0); //shut the user panel
      OpenCaptureMobx.a_close_single_capture(); //shut the open capture (if one is open)
    }

    //set the leftNav tab selected to the default tab (for the case of switching users while logged in)
    this.a_set_left_nav_to_default_tab();

    //do not execute database update if this is the localhost system
    if(o_isLocalhost3000SystemTF) {
      return;
    }

    //if changing user from the user panel, add a function on finish to load any data required for the newly selected user (tasks, contracts data if a contracts user)
    if(userIsAlreadyLoggedInTF) {
      var userPanelMultiLoginSwitchTblNamesToLoadArray = [];

      //database call to load tbls specifically for contracts/contractsexec users
      if(UserMobx.c_combinedUserObj.powerHasContractsPowerTF) {
        userPanelMultiLoginSwitchTblNamesToLoadArray = JSFUNC.concat_arrays_or_values_into_new_array(userPanelMultiLoginSwitchTblNamesToLoadArray, DatabaseMobx.c_contractsUserTblNamesToLoadArray);
      }

      //database call to load tbls specifically for budget/budgetexec users
      if(UserMobx.c_combinedUserObj.powerHasBudgetPowerTF) {
        userPanelMultiLoginSwitchTblNamesToLoadArray = JSFUNC.concat_arrays_or_values_into_new_array(userPanelMultiLoginSwitchTblNamesToLoadArray, DatabaseMobx.c_budgetUserTblNamesToLoadArray);
      }

      if(userPanelMultiLoginSwitchTblNamesToLoadArray.length > 0) {
        JSPHP.load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array(userPanelMultiLoginSwitchTblNamesToLoadArray);
      }
    }

    //api database call to update the number of logins for this user (login.php handles updating the num logins in the case of a nonmulti user logging in, but does not handle the multilogin case done here)
    if(!UserMobx.o_loginWithKeyTF) {
      const timezoneString = JSFUNC.get_user_browser_timezone_string();
      const browserType = JSFUNC.get_user_browser_type_string();

      const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_multi_login_selection_click", ["i_selectedUserID"], [i_selectedUserID]);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("multiLoginSelection", jsDescription);
      C_CallPhpScript.add_post_var("i_userID", i_selectedUserID);
      C_CallPhpScript.add_post_var("i_timezoneString", timezoneString);
      C_CallPhpScript.add_post_var("i_browserType", browserType);
      C_CallPhpScript.add_post_var("i_mediaQueryFlag", o_mediaQueryFlag);
      C_CallPhpScript.add_return_vars("success");
      C_CallPhpScript.execute();
    }
  }


  a_update_local_user_num_logins_and_last_login_datetime(i_userID) {
    const userMap = DatabaseMobx.o_tbl_a_users.get(i_userID);
    if(userMap !== undefined) {
      const updatedNumLogins = (userMap.get("num_logins") + 1);
      userMap.set("num_logins", updatedNumLogins);
      userMap.set("last_login_datetime_utc", JSFUNC.now_datetime_utc());
    }
  }


  a_set_refresh_is_running_tf(i_newValueTF) { this.o_refreshIsRunningTF = i_newValueTF; } //5 sec refreshes
  a_set_loading_through_phase1_complete_tf(i_newValueTF) { this.o_loadingThroughPhase1CompleteTF = i_newValueTF; }
  a_set_loading_through_phase2_complete_tf(i_newValueTF) { this.o_loadingThroughPhase2CompleteTF = i_newValueTF; }
  a_set_load_timing_login_start_timestamp() { this.o_loadTimingLoginStartTimestamp = JSFUNC.now_timestamp_ms(); }
  a_set_load_timing_login_end_timestamp() { this.o_loadTimingLoginEndTimestamp = JSFUNC.now_timestamp_ms(); }
  a_set_load_timing_phase1_start_timestamp() { this.o_loadTimingPhase1StartTimestamp = JSFUNC.now_timestamp_ms(); }
  a_set_load_timing_phase1_end_timestamp() { this.o_loadTimingPhase1EndTimestamp = JSFUNC.now_timestamp_ms(); }
  a_set_load_timing_phase2_start_timestamp() { this.o_loadTimingPhase2StartTimestamp = JSFUNC.now_timestamp_ms(); }
  a_set_load_timing_phase2_end_timestamp() { this.o_loadTimingPhase2EndTimestamp = JSFUNC.now_timestamp_ms(); }


  a_load_phase1_phase2_data_into_local_memory() {
    this.a_set_loading_through_phase1_complete_tf(false);
    this.a_set_loading_through_phase2_complete_tf(false);
    this.a_set_load_timing_phase1_start_timestamp(); //set load timing start for phase1

    const phase1TblNamesComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_phase1TblNamesToLoadArray);

    const functionOnFinishPhase1 = () => {
      this.a_set_loading_through_phase1_complete_tf(true);
      this.a_set_load_timing_phase1_end_timestamp(); //set load timing end for phase1
      this.a_set_load_timing_phase2_start_timestamp(); //set load timing start for phase2

      const functionOnFinishPhase2 = () => {
        this.a_set_loading_through_phase2_complete_tf(true);
        this.a_set_load_timing_phase2_end_timestamp(); //set load timing end for phase2

        //write the load times to the companies database (only if not using the ghost login key)
        if(!UserMobx.o_loginWithKeyTF) {
          const loginTimeMs = (this.o_loadTimingLoginEndTimestamp - this.o_loadTimingLoginStartTimestamp);
          const phase1TimeMs = (this.o_loadTimingPhase1EndTimestamp - this.o_loadTimingPhase1StartTimestamp);
          const phase2TimeMs = (this.o_loadTimingPhase2EndTimestamp - this.o_loadTimingPhase2StartTimestamp);
          const numCaptures = DatabaseMobx.c_numCapturesLoadedInSystem;
          JSPHP.write_companies_login_load_times(loginTimeMs, phase1TimeMs, phase2TimeMs, numCaptures);
        }
      }

      JSPHP.load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array(DatabaseMobx.c_phase2TblNamesToLoadArray, functionOnFinishPhase2);
    }

    //load filtered capture phase 1 tbls, with function to load phase 2 when finished
    const forceLoadArchivedCapturesTF = false;
    const singleCaptureIDOrM1AllCaptures = -1; //load all captures accessible by logged in user
    JSPHP.load_db_data_to_local_memory_from_filtered_capture_tbl_names_comma(phase1TblNamesComma, forceLoadArchivedCapturesTF, singleCaptureIDOrM1AllCaptures, functionOnFinishPhase1);
  }







  //TopBar search captures
  a_set_search_captures_input_text(i_newValue) {
    this.o_searchCapturesInputText = i_newValue;

    if(i_newValue === "") { //if the search has been deleted down to "", close the search floating box
      this.o_searchCapturesBoxIsOpenTF = false;
    }
    else if(!this.o_searchCapturesBoxIsOpenTF) { //if typing, but the search box was closed prior, open it again
      this.o_searchCapturesBoxIsOpenTF = true;
    }

    //for the documents search, always reset the drawAllDocsTF to false
    this.o_searchDocumentsDrawAllDocsTF = false;
  }

  a_set_search_captures_box_is_open_tf(i_newValueTF) {
    if(!(i_newValueTF && this.o_searchCapturesBoxIsOpenTF)) { //don't set this to true if it is already open
      this.o_searchCapturesBoxIsOpenTF = i_newValueTF;
      this.a_set_search_documents_draw_all_docs_tf(false);

      //if the search is opened and is on the documents tab, relaod the documents from the server
      if(i_newValueTF && (this.o_searchSelectedSearchTypeTab === "documents")) {
        this.a_search_load_thin_search_all_documents_data();
      }
    }
  }

  a_set_search_selected_search_type_tab(i_newSelectedSearchTypeTab) {
    this.o_searchSelectedSearchTypeTab = i_newSelectedSearchTypeTab;
  }

  a_set_search_documents_is_loading_data_tfu(i_newValueTFU) {
    this.o_searchDocumentsIsLoadingDataTFU = i_newValueTFU;
  }

  a_search_load_thin_search_all_documents_data() {
    this.a_set_search_documents_is_loading_data_tfu(true);
    this.a_set_search_documents_data_obj({});

    const functionOnSuccess = (i_parseResponse) => {
      this.a_set_search_documents_data_obj(i_parseResponse.thinSearchAllDocumentsData)
      this.a_set_search_documents_is_loading_data_tfu(false);
    }

    const functionOnError = () => {
      this.a_set_search_documents_is_loading_data_tfu(undefined);
    }

    //capture consultants only load docs from captures they are a part of, provide captureIDs of all captures from the capture consultants loaded system
    var filterCaptureIDsComma = "-1"; //"-1" is a flag to load all docs in the system for full users
    if(UserMobx.c_userDocsSearchLimitedToLoadedCapturesTF) {
      const filterCaptureIDsArray = JSFUNC.get_column_vector_from_mapOfMaps(DatabaseMobx.o_tbl_captures, "id");
      filterCaptureIDsComma = JSFUNC.convert_array_to_comma_list(filterCaptureIDsArray);
    }

    const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_search_load_thin_search_all_documents_data", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadThinSearchAllDocumentsData", jsDescription);
    C_CallPhpScript.add_post_var("i_filterCaptureIDsComma", filterCaptureIDsComma);
    C_CallPhpScript.add_return_vars("thinSearchAllDocumentsData");
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
    C_CallPhpScript.add_function("onError", functionOnError);
    C_CallPhpScript.execute();
  }

  a_set_search_documents_data_obj(i_searchDocumentsDataObj) {
    this.o_searchDocumentsDataObj = i_searchDocumentsDataObj;
  }

  a_set_search_documents_draw_all_docs_tf(i_newValueTF) {
    this.o_searchDocumentsDrawAllDocsTF = i_newValueTF;
  }

  a_search_documents_navigate_to_document_ffs_location_in_system(i_tblName, i_tblDocObj) {
    if(i_tblName === "tbl_c_documents_filefoldersystem") {
      const captureID = i_tblDocObj.capture_id;

      const functionOnSuccess = (i_parseResponse) => {
        OpenCaptureMobx.a_launch_card_full(DatabaseMobx.k_cardIDDocuments);
        this.a_set_navigate_to_folder_id_tbl_c_documents_filefoldersystem(i_tblDocObj.lastSubfolderID);
      }

      OpenCaptureMobx.a_open_single_capture(captureID, functionOnSuccess);
    }
    else if(i_tblName === "tbl_c_teammates_contracts_filefoldersystem") {
      const captureID = i_tblDocObj.capture_id;
      const teammateID = i_tblDocObj.teammate_id;
      const contractTypeID = i_tblDocObj.contract_type_id;

      const functionOnSuccess = (i_parseResponse) => {
        OpenCaptureMobx.a_launch_card_full(DatabaseMobx.k_cardIDTeammates);
        TeammateContractsMobx.a_open_teammate_contract(captureID, teammateID, contractTypeID);
        this.a_set_navigate_to_folder_id_tbl_c_teammates_contracts_filefoldersystem(i_tblDocObj.lastSubfolderID);
      }

      OpenCaptureMobx.a_open_single_capture(captureID, functionOnSuccess);
    }
    else if(i_tblName === "tbl_c_teammates_surveys_filefoldersystem") {
      const captureID = i_tblDocObj.capture_id;
      const surveyID = i_tblDocObj.survey_id;

      const functionOnSuccess = (i_parseResponse) => { //don't need to navigate to folderID for surveys since folders are not allowed to be created in the surveys FFS
        OpenCaptureMobx.a_launch_card_full(DatabaseMobx.k_cardIDTeammates);
        OpenCaptureMobx.a_teammates_set_left_tab_selected("surveysCapabilitiesGapAnalysis");
        OpenCaptureMobx.a_teammates_surveys_select_survey(surveyID);
        OpenCaptureMobx.a_teammates_surveys_select_capabilities_gap_analysis_subtab("uploadSurveyDocuments");
      }

      OpenCaptureMobx.a_open_single_capture(captureID, functionOnSuccess);
    }
    else if(i_tblName === "tbl_g_contacts_companies_filefoldersystem") {
      const isPersonTF = false;
      this.a_left_nav_select_tab("Contacts");
      ContactsMobx.a_open_contact(isPersonTF, i_tblDocObj.contact_company_id);
      ContactsMobx.a_set_open_contact_view_edit_single_contact_selected_tab_db_name("viewDocs");
      this.a_set_navigate_to_folder_id_tbl_g_contacts_filefoldersystem(i_tblDocObj.lastSubfolderID);
    }
    else if(i_tblName === "tbl_g_contacts_persons_filefoldersystem") {
      const isPersonTF = true;
      this.a_left_nav_select_tab("Contacts");
      ContactsMobx.a_open_contact(isPersonTF, i_tblDocObj.contact_person_id);
      ContactsMobx.a_set_open_contact_view_edit_single_contact_selected_tab_db_name("viewDocs");
      this.a_set_navigate_to_folder_id_tbl_g_contacts_filefoldersystem(i_tblDocObj.lastSubfolderID);
    }
    else if(i_tblName === "tbl_g_general_documents_filefoldersystem") {
      this.a_left_nav_select_tab("Marketing Docs");
      this.a_set_navigate_to_folder_id_tbl_g_general_documents_filefoldersystem(i_tblDocObj.lastSubfolderID);
    }
  }

  a_set_search_captures_exact_tf(i_newValueTF) {
    this.o_searchCapturesExactTF = i_newValueTF;
  }




  //LeftNav
  a_set_left_nav_is_expanded_tf(i_newValueTF) {
    this.o_leftNavIsExpandedTF = i_newValueTF;
  }

  a_left_nav_initialize_left_nav_is_expanded_tf() {
    var intializedLeftNavIsExpandedTF = false; //starts collapsed if system is mobile or tablet
    if(!this.c_isMobileOrTabletTF) {
      intializedLeftNavIsExpandedTF = UserMobx.c_userLeftNavIsExpandedTF; //otherwise initializes with database stored user last setting
    }
    this.a_set_left_nav_is_expanded_tf(intializedLeftNavIsExpandedTF);
  }

  a_left_nav_expand_collapse_toggle() {
    //new value after toggle
    const newValue01 = ((this.o_leftNavIsExpandedTF) ? (0) : (1));

    //update user preference in database
    const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_left_nav_expand_collapse_toggle", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_users_per_email", UserMobx.o_userPerEmailID, "left_nav_0collapsed_1expanded", newValue01, "i");
    C_CallPhpTblUID.execute();

    //update local value
    this.a_set_left_nav_is_expanded_tf(!this.o_leftNavIsExpandedTF);
  }

  a_left_nav_select_tab(i_tabNameSelected) {
    this.o_leftNavTabNameSelected = i_tabNameSelected;

    //for the Captures subtabs, reset the max # captures drawn when switching to a tab
    if(JSFUNC.in_array(i_tabNameSelected, ["Capture Table View", "Stage View", "Gantt Chart View", "Progress Chart View"])) {
      const maxTrueResetFalse = false; //switching tabs, reset to 50 captures shown in all cases
      const viewIsProgressChartTF = (i_tabNameSelected === "Progress Chart View");
      CapturesMobx.a_set_cst_or_chart_max_num_captures_drawn(maxTrueResetFalse, viewIsProgressChartTF);
    }

    //for the Contacts left tab, reinitialize the contacts system when this tab is selected (floating box contact selection systems are automatically initialized when they are opened in React code)
    if(i_tabNameSelected === "Contacts") {
      ContactsMobx.a_initialize_contacts_system();
    }

    //for mobile, the leftNav takes up the entire screen, so selecting a tab auto closes the left nav to help out
    if(this.c_isMobileOrTabletTF) {
      this.a_left_nav_expand_collapse_toggle();
    }
  }

  a_set_left_nav_to_default_tab() { //called during login (through single user or selecting multi user), or changing multi user in user panel
    this.o_leftNavTabNameSelected = this.left_nav_default_tab_name();
  }


  //Editing items (especially floating edit boxes) are given unique identifier strings so that only 1 can be open at a time (all items on all capture cards, items in right panel, etc)
  a_set_item_editing_capture_dash_card_dash_item_id(i_newCaptureDashCardDashItemID, i_isContactFieldTF=false) {
    if(i_isContactFieldTF) {
      this.o_itemEditingContactFieldUniqueString = i_newCaptureDashCardDashItemID;
    }
    else {
      this.o_itemEditingCaptureDashCardDashItemID = i_newCaptureDashCardDashItemID; //if editing a different item on the same card or a different card, this will 'cancel' the previously open item, not saving the changes
    }
  }

  a_close_item_editing(i_isContactFieldTF=false) {
    if(i_isContactFieldTF) {
      this.o_itemEditingContactFieldUniqueString = undefined;
    }
    else {
      this.o_itemEditingCaptureDashCardDashItemID = undefined;
    }
  }

  is_editing_item_tf(i_newCaptureDashCardDashItemID, i_isContactFieldTF=false) {
    if(i_isContactFieldTF) {
      return(i_newCaptureDashCardDashItemID === this.o_itemEditingContactFieldUniqueString);
    }
    return(i_newCaptureDashCardDashItemID === this.o_itemEditingCaptureDashCardDashItemID);
  }




  //divexec global
  a_set_view_specified_search_results_captures_in_floating_box_capture_ids_array_and_floating_box_title(i_captureIDsArray, i_floatingBoxTitle) {
    this.o_viewSpecifiedSearchResultCapturesInFloatingBoxCaptureIDsArray = i_captureIDsArray;
    this.o_viewSpecifiedSearchResultCapturesInFloatingBoxTitle = i_floatingBoxTitle;
  }





  //system wide drag/drop storing information about the dragged item when it is first onDragStart
  a_set_any_file_or_ce_item_dragged_over_any_part_of_website_tf(i_newValueTF) {
    this.o_anyFileOrCEItemDraggedOverAnyPartOfWebsiteTF = i_newValueTF;
  }

  a_set_captureexec_dragged_item_obj(i_draggedItemUniqueString, i_draggedItemID, i_dragStartX, i_dragStartY) {
    const ceDraggedItemObj = {
      uniqueString: i_draggedItemUniqueString,
      itemID: i_draggedItemID,
      dragStartX: i_dragStartX,
      dragStartY: i_dragStartY
    };
    this.o_ceDraggedItemObjOrUndefined = ceDraggedItemObj;
    this.o_cePreviouslyDraggedItemObjOrUndefined = ceDraggedItemObj;
  }

  a_unset_captureexec_dragged_item_obj() {
    this.o_ceDraggedItemObjOrUndefined = undefined;
  }

  a_set_captureexec_dragged_item_currently_over_dropzone_obj(i_dropZoneUniqueString, i_dropZoneItemID) {
    this.o_ceDraggedItemCurrentlyOverDropZoneObjOrUndefined = {
      uniqueString: i_dropZoneUniqueString,
      itemID: i_dropZoneItemID
    };
  }

  a_unset_captureexec_dragged_item_currently_over_dropzone_obj() {
    this.o_ceDraggedItemCurrentlyOverDropZoneObjOrUndefined = undefined;
  }



  //system wide FFS initialized folder to open on
  a_set_navigate_to_folder_id_tbl_c_documents_filefoldersystem(i_navigateToFolderID) { this.o_navigateToFolderIDTblCDocumentsFileFolderSystem = i_navigateToFolderID; }
  a_set_navigate_to_folder_id_tbl_c_teammates_contracts_filefoldersystem(i_navigateToFolderID) { this.o_navigateToFolderIDTblCTeammatesContractsFileFolderSystem = i_navigateToFolderID; }
  a_set_navigate_to_folder_id_tbl_g_contacts_filefoldersystem(i_navigateToFolderID) { this.o_navigateToFolderIDTblGContactsFileFolderSystem = i_navigateToFolderID; }
  a_set_navigate_to_folder_id_tbl_g_general_documents_filefoldersystem(i_navigateToFolderID) { this.o_navigateToFolderIDTblGGeneralDocumentsFileFolderSystem = i_navigateToFolderID; }



  //My Surveys subtab
  a_set_my_surveys_loading_data_tf(i_newValueTF) { this.o_mySurveysLoadingDataTF = i_newValueTF; }
  a_set_my_surveys_selected_tab_db_name(i_selectedTabDbName) { this.o_mySurveysSelectedTabDbName = i_selectedTabDbName; }
  a_set_my_surveys_tbl_c_teammates_surveys_arrayOfObjs(i_arrayOfObjs) { this.o_mySurveysTblCTeammatesSurveysArrayOfObjs = i_arrayOfObjs; }
  a_set_my_surveys_tbl_c_teammates_surveys_response_times_arrayOfObjs(i_arrayOfObjs) { this.o_mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs = i_arrayOfObjs; }
  a_set_my_surveys_current_view_survey_start_index(i_newValueInt) { this.o_mySurveysCurrentViewSurveyStartIndex = i_newValueInt; }
  a_set_my_surveys_selected_filter_teammate_contact_company_id(i_newValueInt) { this.o_mySurveysSelectedFilterTeammateContactCompanyID = i_newValueInt; }
  a_set_my_surveys_selected_filter_idiq_capture_id(i_newValueInt) { this.o_mySurveysSelectedFilterIDIQCaptureID = i_newValueInt; }

  a_my_surveys_load_survey_data() {
    this.a_set_my_surveys_loading_data_tf(true);

    const jsDescription = JSFUNC.js_description_from_action("CaptureExecMobx", "a_my_surveys_load_survey_data", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadMySurveysData", jsDescription);

    C_CallPhpScript.add_post_var("i_firewalledCaptureIDsComma", UserMobx.c_userFirewalledCaptureIDsCommaOrAllm1);

    C_CallPhpScript.add_return_vars(["surveysMatrix", "surveysResponseTimesMatrix"]);

    const functionOnSuccess = (i_parseResponse) => {
      this.a_set_my_surveys_tbl_c_teammates_surveys_arrayOfObjs(i_parseResponse.surveysMatrix);
      this.a_set_my_surveys_tbl_c_teammates_surveys_response_times_arrayOfObjs(i_parseResponse.surveysResponseTimesMatrix);
      this.a_set_my_surveys_loading_data_tf(false);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
  }



  //Archived Captures Warning bottom bar
  a_set_archived_captures_warning_bottom_bar_user_has_dismissed_warning_tf(i_newValueTF) {
    this.o_archivedCapturesWarningBottomBarUserHasDismissedWarningTF = i_newValueTF;
  }


  //on_premise_authentication_quarterly_passcode_expiration_date_from_64char_passcode
  opa(i_passcode64Chars) {
    var vpwArray = [];
    vpwArray.push("4Xq9BvWh67HGysxZZwBNdCmdrFjjFCZhYFBgbkHb4J5sHPX5SLGcxgreLqD4TuFRUH7CMBqPngJUSyckFjcCVyMwFT8vDJGD8fydzftZYg3bKkb");
    vpwArray.push("A9kEJbPBJesuv2tfRkj5EvpsyF7eYL5dNEPK5mLF6tBCUKyAM3H9J5qE7ZzfgBrdK7q9StAmpcRHsDzN79Tm5qefqxZPEna5mfydLgRNyBN2Cdh");
    vpwArray.push("pzHfCFTKBxGzJtJvh79PhCHvU7ZvLNpdUVPHSTbXmA5p9Yt4HjfGUK7hbeuXrLsdyxp23rWyxG6HYLwevfr3jpeHbWk5wbyaSMGUgHS4GazrgR9");
    vpwArray.push("DjbtuBpu6ze8rYKDrNMcz6RhQdLCktSbvzdjvdUeYzALhmrftmhyD8Nw8Rgs64NcKEqTzuARuZssRAyCw83s3FSQWtjfqwjJVJEvMGZS8pcewYG");
    vpwArray.push("VcJMNzSjG9m8cvYgAXv3xSWdEhEvnYSE5bPUqdZ6jBXgsrRpLQLzP78PcUfrJr6xDbsQC3eagdCPdWNJhF2qPeH4CbMxzvanrz8sckHCX8rR34D");
    vpwArray.push("K45FmPBKQUV5Jfnj8BsLpQ4yZVhpbjBVMFgUDfRc5FNRBPQCt6HTMDfcZkNnW7uS5HcsMwF8GjH3SXFGUrHkHrxqkvVsRvmuRJEAJRPfrZHx9Ra");
    vpwArray.push("5HWj4KLmmrgYBeq3NQEG9vxk6PYJmkejRkFRCVCSkYsZDjvrfc7ZnXX9ppeYxkNJBJwa7uv5F4dgD7TZfZ243QE3YCTEb5pxj5ssBGfKG3pNC4F");
    vpwArray.push("a5WaxK7H8TgjbqFhxahhb3pVkwztWJa7DTs8BVnrGj8UA6n3r3ZmJdGK3pDKtPWnLJhNWBWajJ44QteyYerwWadPyVRnFbnw5GbZyv6Qv7ZdAp2");
    vpwArray.push("6emuXbTD76aFWeBN8LnaBbCVTbYdPauXTKh4zppDweXGNUXJLPr482UWRfY3L7gktkgxwLe69AQK7PDHBaS5D7kK2HTVwjdB4c3THcrgEDNSKhW");
    vpwArray.push("rT5SCKH8YqsJAwxXUy3VUubbNAcnKP452Efx8qrUHGaeAPMgY5DmYUF6akhb3fwpbDSygxwx8PJPXXG7k6BhGFYTBdpFMQAsHm7VDHgWLSq7rt8");
    vpwArray.push("t5asHEh5dGjtgHZrBzzJL5xEBNgGfgv8L4T4cTEQbmrGGNyNUPxgUPUWtEnUccQUnCpyXFXzaScgVVe6sYSqruZJLxqAm6zgnDSLqkRSzjgQQmE");
    vpwArray.push("NERjqKLfakp3wG7NQTxKYVfPmFstAbzuwnLUzqRm5JRptWJVymAe2uZnKRTRSaeZcex9vTXeNhQFp7KEtMVLTgzrWD2yc8TRneyWtqCLJR2n7dJ");
    vpwArray.push("VkTrvDDS2dPxU95agF6PaUJUrTujyBV3QBksSNMQdBk5U8r6bdD5BAcG4WvqrD33peNsZnMmPCDRWdRr4gktdqkbjHuJEw3eDeUEtBLJwvdfreF");
    vpwArray.push("dugwayggXfkxxZPWt7XaHzsSWCsmMj2D9UWJfmQG4QSzHp7b9MxxuPZmLSRCqdJ7hvbHxbeBSHU7h6wmgQpSnFRFbhWG9wWkLpJLSHDpRtQGwLT");
    vpwArray.push("XAyf8FREcGKXvwEapRTnvRFEUbfbZsGYjwKqHpmtt2P2jhhmELSVUCaRxFZQscPz57hhpGXwthTeSMEzJJdTE3eLXU4cFaFgGwCUTmBbq2Yju6Z");
    vpwArray.push("ay6f2m4D9TtCnJB5FYhLnAVeZrZNvPrcna68xMvKHJdwNTSktmx9FjLe8UHJfrPGeLCQvSKABTnyx8qDsUNQA5XgbruAe2SJYrABWJbcKKHM5uv");
    vpwArray.push("9dDv63gZHQbKZQNmrLdKHmXJLY4N3PKHJchUSRBSvn2cAbVKBk525dwWHkXGZsSAyu6QhtAE9MtwbDhBH8YRDJzFKwG83EjgTnmk6XYaggfpZhq");
    vpwArray.push("7S45LSduzcFavELygxY5hdSmc5f6qGFcdPEP2c8kczCFShsnRmVm5xvrrjfcb4b677HHDkzExkrSFK8rwpgBkHZuE2qfeLyY3p4GxfahYrLd58L");
    vpwArray.push("KsdVd64qDL2vL2Q88r5SSFX6rpVYDTPn6QRtppzg8TXpBrdEuQsva323uhy23kfczvtjL2v4x4R9p86zBWHdDLwhuMgLzu9NDAEeAK7G2AQbagw");
    vpwArray.push("cFr6TxMKvv6jbmSz5NE93q2K6WDGHCWA9XwrbrBKcnF3PN3JnLtSYYTGGc3ZnRYkGpmRjmrg9LUW4KuX85EYJDL4xAdfknDnMy9NvtkHh8DtDM3");
    vpwArray.push("KTqAC3BjM7R4mszkUQK3HGSFyVYQqGqa73QQGwbknnq8UAyUnwzNKhPHT5rHrCDSD3YRrjz9AMjDpVXHfqXzHxwHkpYHxAPvQvmceMjx7ttASfD");
    vpwArray.push("MJNextcs6A86ZQbDUH2dbtNxhUVxxWuaV3FDcnkF4hadmt73tugGgKLDM2NFV9ry5WQSy5qfB7xakvJ4kBBFnSLcNCfffj8KAhkW65k25JVaJEj");
    vpwArray.push("hUCzX2EB7GB4uQs2t83QNruW7ZeyGbRMRtZ2XB7NbyNbsKZJJDDh8x9G53JDWePxgb3HcEVp8g6EjG9YMTLQ4H2j7XpspqAGefu6dzNaxHNQPy4");
    vpwArray.push("FnfqPPMUN6Eat3RL36996SVEB3TzTakDBCzatwJMk6gq3ujEeM39n5kJRA7D2ynZdHtfxk6HZ4jRs3cHa3H7FUAYm3wReHYfuhJvqfZdfhWsBTU");
    vpwArray.push("TQNxyCDG8DE8s7qbJwupE3AT7vAHezQ6Dmtb5WjQzscxE4dqyUbfTYV7UeHukq5ntq4TahLZ5nJdaprtqvj2NZh8Mp4EbHfVJ2KWxfXu3TzprvD");
    vpwArray.push("xRsqZPQKNd8EHFdnWwefqrUv3jKfUuSkEJSngsbNhZ9v4XnCEMuG8ZRz4rmPqxKSCxcyWZywKdYNBVav7RjDqGbqn4sN6sEXP7nEy4SvqukqXHV");
    vpwArray.push("vx7CajxEyvny9hG7DcUGXEGhYbyJmsUrANxNk3SuFQsUZZWvBMhukuEprvmLujScHkCTRGxgwjm4cgFQL2jLPYD8KDry8Q3EQ6ede4Ms4exvMQB");
    vpwArray.push("Mn96etqhyZyRvsDMqTVuYZaVLCRe4deCDeJw9KKqRE2qfKUhVKqLCGJyAPdk7YEa7JgcrbFye6jex7T2PkefmYVzwuRQMGGsmas56uY4xCFgEFF");
    vpwArray.push("vqftzHPnyKCdAnfctwmuDu4mYXTLrrJ43ewN5FXR4R9LaGEJtCPwbeJcGYPc7T47erHyQ3rpMKq6fDNY2KL3LdxTzB5FBXBU66t2TJVEPt7NLtH");
    vpwArray.push("t9BPZnsq7aqdTB7TyrR5dsBwRvjRZvRskYjzMRVUQUeSkCmvRpt8nQv3YwG8cvBRaqAhW9HFdPcBe5W9jzaMtZSsEYC9DpeAzd4qz9bSJHscGZE");
    vpwArray.push("RWGXqxYcNnTckCrF8tSz8bzEUvJXBJ2uKLq8GZQHytmyvefKAJGGfSf7HFFZUyRkQgFB4QQzLNZX2XebHWBeWP3LuLG42TuMgrdzNY49JAJ6RSX");
    vpwArray.push("Dt9XFrF4mH9jSNKpwy7LUzqabCN3sLYNq3wUJnmp24u29SFN6Bwgr8xTbYcbQvFR2gj6MzegdkyuQtLN2BbEgetR58M7w2mAnFueLGMvLh7NHrx");
    vpwArray.push("tAyC3nzz853Y9XWWmrKAKjMFKCGcAvxU8MrzLPLTPnkT5z8rznJ9udFaGXcdRAZdyxn66ys8sySMtpBRt6WrZ6y4jehrXdzpfSK4hQFRhcxuxpm");
    vpwArray.push("5z7NmVhhmH2bEGFyzaAq7mesdqP7CtACHj56efMF7uU4qYFujJ9EZKKD3kdqRZbrJCfsgV8fMGFSSkVX5vQ9BbKSCBKhY62d67dK2V5Snf7hmDY");
    vpwArray.push("nat9feNtA3aFML7KxAekS8EkkaY9rj3m9vzybttgQAysj3zZbfBrmdu8NvurxfcDy55Z2LeVqdN8mGs3kjZGRTMVXRfxj6GZF5LwXb9TemzFVqD");
    vpwArray.push("vnf6cVKFRLkWnp7FZvRKRvK4vB5svYXkFApQK6KkLNrDjasXy8JR2fqAPkf5PaS8dgmNkSnwY4THVg7RVEFqWYpBzh4kryYHcQH5KmXheXwybTp");
    vpwArray.push("X6vNNVKHNURUxz4dEYwGP3pEs43HQ3P6hqCwG6uUHt7fwXUg6NGjV8Z6KYwtqAkhUC8GKbJG25seSKucFKUXCrPzfSsLrPBrk2nKdUYsVqecUuz");
    vpwArray.push("KW6L5t4zFwk3bmEbymAVZac7cAxd7YcNwdGRCP63ambYBRncw9M6g29cTr8DwuCghzBbzJq5xgLakgyGqePd9ncKZ2dY2WHq6Ne46G4p2MRDpjJ");
    vpwArray.push("GB5VdVqv5ELGNFjKJE5CdNTV5KvXbVucRnZvWaRjVrMzsMYbacF3DuEWZRL53mkWN7dStswbyRXVypPj736kNy3zU74ZJMCUn3FXguuVqL5ctZn");
    vpwArray.push("DBTYJfVp9XbkN8xbBLmyC4fuLc2xWmzm2Uyu7yJMwCzDrhbagkKCjRLyVTBWRnTFgMJ2qyQ4Xv3ZvRyJTXjnysNQXkpruv4dDrPwfpzr2XzSLeB");
    vpwArray.push("f8vHLpk7aZLcjJQe8f8YsypbBKXzfgBHHfDR8jHZsMwuGYFhXex5pcPwd4eF586PJLSqDNKxjS7YSmTKNkxNWYeNkfCnb5TwK87CsgPWakfKbL6");
    vpwArray.push("qQHV7atqKB9NsupQQAspfmhycEbzvuMYTEX8NtjVR4RFh4M9WVaUFA55A8GgAscdsSj5jVt7vtF4FDfAf6HtbxNUKjGXZa4tfdmYb9kekpqWHVs");
    vpwArray.push("PRDDWqQ9GquFgZsmPFxtGAEAYX2QZYjSpq4tsTGHwbUTvF9GSbbmPAbgFaB6RnpEZUnjKfmfCbNZ2K2MkGD8cwbkk6kap48AwNnGwRqJjh7h9en");
    vpwArray.push("sFnGxRVR7w5AmuEVAYmsFXedzsPJrN7xr3yBWRCa4QeWUTgk8YhkT8JHyhktSjgY7Pp4tadSVhqNeGjn6q3QgSXWSaPWhtPbxVXMKDgJZpHcxPF");
    vpwArray.push("xqyfbx3548tVubVNzAc8TwSECxCMe2ks8ychb2V8snxLBZt2n6CQcEmMSZEmsjS29xnnkrcpeNpG4hPpCUVFkuxtJM49dFmEmqCpgJRhgr9F8pq");
    vpwArray.push("vbrueVkNEUkY9CQ7t8jJkTtQxPkQuWmzU6zZDYmVbysvrMrV2pNC8eDe3ssRr2t7CXHG5ACWCkPwsnBxjqBKUp9C2DHDDhwaD93PVMhX35NqSQJ");
    vpwArray.push("rL4FysZdwAWnayMqs6JRkh5h6w4Auczu7rsXpnRCyJ5tGXbbD5qMw4Pgu4DCcz4rsWZyKcbxGZsXSS5L5pZ6A7qAD7D82YrKYwLq3QgrF5PTZXp");
    vpwArray.push("cH5UJf9R44TJwfnvNJ7XyDeH5rR5zK4PnMQ5jXRPqeTfxXXkmk6C98tQSkEbNett7sHCkBRv4v9Zh3MG99xdnhjYrNU6nSbrsEAExEugjCLCnuw");
    vpwArray.push("t53a3C4R26YYNdr2JUMgpEY2t828h3U8GTDK2GJMyfLm8LPheShdecSwGegBqbvtq9aCeRAZJNHjLrguE6LVD9BceuzgJB3S5muPqJCMX7RgT3C");
    vpwArray.push("YfZYwhYJEp5CzXbDvdRRH2WD85tQKDX95w6LHQW6Jeers84uHwjdJ5fXTGqTZsHhnbQYFcMd8Vk58kVE9dYyYzaWHq6MqwqwE24ZxYSteg68XtP");
    vpwArray.push("EcmdybYfDZezF5AnpDHkBvN8wfsBrVatVKxaau7jnWZ9WQGubNhWNWJn2EfjK6j5kssUfFaKKdcQQrWFK6xRTfFNfmsGYcWAaDrRg5JurKAUJfL");
    vpwArray.push("PARctDfpHBKYKhAuGjKpfv6k23MQsSW4udxMCsQ47NTMEVJFHe6EN4xnCqup5hVdaJqrq4yjn2ea3xDVyfZjrKL3qeK3rqmtSDgut5x4UvxCkfp");
    vpwArray.push("NbGfPZpbmSqnTMuZZgJvJ2d8jvF2R6nybx99CCq3wdLSYSMpffQJVzYLn868SJv7VAFD7Ge5uYaZ7b3WdFQZZrUJxpQFwa3vLWNYAUqEmMYHDH8");
    vpwArray.push("qTrMXVBWcYNeK7LYXBcCgUjFgTpCc3xpMatbrEMdk8NNsMyp8ZS4PVcPLmAGbshD8c2PedbR5Ga7fs2SXGWDmcy9mNXTrrxShe7ZxRFAyjBYgDa");
    vpwArray.push("waH7aBR7cTtyJ92wg767ajStt2LDmJHuhCEeqsZxLenTuvFfueUkekJX3Ly7cRkWKtsuVcxnBeqnnyKYFvPUkvPbHTX9b8ZNcdNnQMZ4aARatvT");
    vpwArray.push("g3VSHVEFDSJtdsvd8QcwegkqCcCFNttAJJEu8yak3eygfWzqLTVX8UA7rbgu7h7J7PrUWEaUmG3hUfzU6RVpkCd9MRQdHT4XjEN4S8uEEdT6Zk7");
    vpwArray.push("CLceSTkPLCpWeT8AvdMtCAGuc4dBpW4LPaCCqcpN83h3ZzuZ25Cfa3yfwDnywErxQT8Zrr3K8q5KR6fgzCCceqezPzzLYHzn3TKQYfELRrRtwjM");
    vpwArray.push("PxtXGR65KhVGERh5gdJXMDN5AtZUwMbJ2jrnTPE3bpnMQkW8LD367Sz5VUjdtrVsmTFrZuWZkp7uHmdSVmqDkckcj4KGJngNe7jGz8cTKQtvVgh");
    vpwArray.push("PrzsaNpDT6vDPTZ4NEpPg7BaRhhfzhhScU4r2FhxzUr8SRkRw8JNMBjJVgU97fzZURntEYa2X5hkkYYunUP5xP354wcFd3FqpfskcmAqdcuTQZv");
    vpwArray.push("Y7R5MWGY6ekzFacUTVS64LTZAesr7RXHCABKQLnYKBARMgTN6Lj9dUHbDjKqvTYX9a9hTpFrNUUhA7bBfDkDXuRFB7wSrhusz8Qq9A6XkVyU2p9");
    vpwArray.push("mY7W3FuEg9kdGXvrxDYmWfdaaYH2ebX5JJM4AMuvv56Uk7ytzEuEAxJQathtgKDBbtBCJvxjv4CWjEu7Fvdh5UJrQyQYxKeVwZNbAnzejjxdBQA");
    vpwArray.push("YEhZZH8dV324Fa56JxnACK4geSfxeyrF3qDVy394vfxZgBWyVDqTw26SRX5RaD39BqEdGECthmwWwecsUarNRYa27PLTBW6vpW7RbEmWvGsL7Du");
    vpwArray.push("5TXccErWaTw7FtHsUeGS8aSp238ed3NpqzXDgze5AyCkR8NMBFR5cz8t6FTYBQesZSmzyRzDeqbPZ5VPBpkp5h2hKpuSxcV46HVPTdJyQxZq9H8");
    vpwArray.push("uSvfLvKK9F6sAGZ9DTzH6sSxd5QetTUczrLCCcveNUZdbDwRuqkqPWZh4hdJTDZBvdqjjysBPTKm6waG75nU86XdpcfGSAbCq7XpXZbVxp3Ur87");

    return(JSFUNC.match_grid(i_passcode64Chars, vpwArray));
  }

}
export default new CaptureExecMobx();
