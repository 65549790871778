import { makeObservable, observable, computed, action, override } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";


import BudgetMobx from '../Budget/BudgetMobx.js';
import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import ContactsMobx from '../Contacts/ContactsMobx.js';
import RightPanelMobx from '../RightPanel/RightPanelMobx.js';
import TeammateContractsMobx from '../TeammateContracts/TeammateContractsMobx.js';
import AdminIntegrationsMobx from '../AdminIntegrations/AdminIntegrationsMobx.js';
import GCSSMobx from '../GCSS/GCSSMobx.js';

export class OpenCaptureMobx {
  //===observable fields===========================================================================================
  o_openCaptureID = undefined; //undefined is a flag that no capture is currently open
  o_openCardLeftID = undefined;
  o_openCardRightID = undefined;
  o_openCardFullID = undefined;

  //items in the top red bar
  o_captureConnectedToGcssCompareFloatingBoxIsOpenTF = false;
  o_captureConnectedToGcssFetchedSingleSamExpandedDetailSearchResultsObjOrUndefined = undefined;
  o_captureConnectedToGcssLoadingFlagOrErrorMessageOrUndefined = undefined;
  o_userPerEmailIDsWithSameCaptureOpenComma = undefined;
  o_copyCaptureState = undefined; //undefined, "copyOptions", "copying", "failedCaptureRow", "failedCaptureData"
  o_copyCaptureOldNewNamesString = undefined;
  o_copyCaptureStageTF = true;
  o_copyCaptureDatesTF = true;
  o_copyCaptureTasksTF = false; //default not copy tasks
  o_copyCaptureDealShapingTF = true;
  o_copyCaptureTeammatesTF = true;
  o_copyCaptureTeammatesSurveysTF = true;
  o_copyCaptureCompetitorsTF = true;
  o_copyCaptureProposalThemesTF = true;
  o_copyCaptureRisksTF = true;
  o_deleteCaptureState = undefined; //undefined, "delete", "deleteRequestMade"

  //Tasks
  o_tasksMobileShowingActiveTF = true;

  //Deal Shaping
  o_dealShapingAppliedTagIDsArray = [];

  //Teammates
  o_teammatesLeftTabSelected = "teammateSelection"; //"teammateSelection", "sbRequirements", "surveysCapabilitiesGapAnalysis"
  o_teammatesExpandedTeammateID = undefined;
  o_teammatesSurveysSelectedSurveyID = undefined;
  o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab = "surveyResultsMatrix"; //"surveyResultsMatrix", "editSurveyQuestions", "uploadSurveyDocuments", "invitationText"
  o_teammatesFloatingBoxSurveyTeammateID = undefined;
  o_teammatesFloatingBoxSurveySurveyID = undefined;
  o_teammatesSurveysWorkingOnSendingSurveyTF = false;
  o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF = false;
  o_teammatesSurveysResultsMatrixFilterIDsComma = "1,2,3,4,5,6,7,8,9"; //1-Yes, 2-No, 3-[5], 4-[4], 5-[3], 6-[2], 7-[1], 8-[0], 9-Text Responses
  o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF = false;
  o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF = false;
  o_teammatesSurveysEditQuestionsImportQuestionsFloatingBoxIsOpenTF = false;

  //Competitors
  o_competitorExpandedID = undefined; //can only have 1 competitor expanded at a time

  //Proposal Themes
  o_ptDifferentiatorIDAddingOrEditing = undefined; //undefined neither is open, -1 is adding, id>0 is editing an existing item
  o_ptWinThemeIDAddingOrEditing = undefined; //undefined neither is open, -1 is adding, id>0 is editing an existing item
  o_ptGhostThemeIDAddingOrEditing = undefined; //undefined neither is open, -1 is adding, id>0 is editing an existing item

  //Budget
  o_budgetSelectedBudgetCategoryID = undefined;
  o_budgetMobilePage = undefined; //undefined, "funding", "expenses"
  o_budgetEditingFundingRequestID = undefined;

  //Changelog
  o_changelogNumItemsPerPage = 25;
  o_changelogCurrentPageNumber = 1;
  o_changelogFilterCaptureCardIDsComma = "";
  o_changelogFilterCaptureFieldID = -1;
  o_changelogFilterUserID = -1;
  o_changelogFilterDateMin = JSFUNC.blank_date();
  o_changelogFilterDateMax = JSFUNC.blank_date();
  o_changelogSortColumnDbName = "dateTimeUtc";
  o_changelogSortIsAscTF = false;

  //Notepad
  o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined = undefined; //undefined, "new", "edit", "read"
  o_notepadNoteStampsEditingOrReadingNoteStampObjOrUndefined = undefined;

  constructor() {
    makeObservable(this, {
      o_openCaptureID: observable,
      o_openCardLeftID: observable,
      o_openCardRightID: observable,
      o_openCardFullID: observable,
      o_captureConnectedToGcssCompareFloatingBoxIsOpenTF: observable,
      o_captureConnectedToGcssFetchedSingleSamExpandedDetailSearchResultsObjOrUndefined: observable,
      o_captureConnectedToGcssLoadingFlagOrErrorMessageOrUndefined: observable,
      o_userPerEmailIDsWithSameCaptureOpenComma: observable,
      o_copyCaptureState: observable,
      o_copyCaptureOldNewNamesString: observable,
      o_copyCaptureStageTF: observable,
      o_copyCaptureDatesTF: observable,
      o_copyCaptureTasksTF: observable,
      o_copyCaptureDealShapingTF: observable,
      o_copyCaptureTeammatesTF: observable,
      o_copyCaptureTeammatesSurveysTF: observable,
      o_copyCaptureCompetitorsTF: observable,
      o_copyCaptureProposalThemesTF: observable,
      o_copyCaptureRisksTF: observable,
      o_deleteCaptureState: observable,
      o_tasksMobileShowingActiveTF: observable,
      o_dealShapingAppliedTagIDsArray: observable,
      o_teammatesLeftTabSelected: observable,
      o_teammatesExpandedTeammateID: observable,
      o_teammatesSurveysSelectedSurveyID: observable,
      o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab: observable,
      o_teammatesFloatingBoxSurveyTeammateID: observable,
      o_teammatesFloatingBoxSurveySurveyID: observable,
      o_teammatesSurveysWorkingOnSendingSurveyTF: observable,
      o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF: observable,
      o_teammatesSurveysResultsMatrixFilterIDsComma: observable,
      o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF: observable,
      o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF: observable,
      o_teammatesSurveysEditQuestionsImportQuestionsFloatingBoxIsOpenTF: observable,
      o_competitorExpandedID: observable,
      o_ptDifferentiatorIDAddingOrEditing: observable,
      o_ptWinThemeIDAddingOrEditing: observable,
      o_ptGhostThemeIDAddingOrEditing: observable,
      o_budgetSelectedBudgetCategoryID: observable,
      o_budgetMobilePage: observable,
      o_budgetEditingFundingRequestID: observable,
      o_changelogNumItemsPerPage: observable,
      o_changelogCurrentPageNumber: observable,
      o_changelogFilterCaptureCardIDsComma: observable,
      o_changelogFilterCaptureFieldID: observable,
      o_changelogFilterUserID: observable,
      o_changelogFilterDateMin: observable,
      o_changelogFilterDateMax: observable,
      o_changelogSortColumnDbName: observable,
      o_changelogSortIsAscTF: observable,
      o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined: observable,
      o_notepadNoteStampsEditingOrReadingNoteStampObjOrUndefined: observable,

      c_singleCaptureIsOpenTF: computed,
      c_captureConnectedToGcssGovWinAndSamFieldsWithValuesArrayOfObjs: computed,
      c_userNamesWithSameCaptureOpenHoverString: computed,
      c_cardIDNotInSnapshotCardsContainer: computed,
      c_captureExistsTF: computed,
      c_openCaptureMap: computed,
      c_userIsCaptureManagerOfOpenCaptureTF: computed,
      c_captureName: computed,
      c_captureDirectAccessLink: computed,
      c_captureIsArchivedTF: computed,
      c_captureTypeID: computed,
      c_captureTypeExistsTF: computed,
      c_captureTypeMap: computed,
      c_captureTypeName: computed,
      c_captureTypeIsPrimeTF: computed,
      c_captureTypeVisibleCardIDsArray: computed,
      c_captureTypeHas0CardsTF: computed,
      c_contractTypeID: computed,
      c_contractTypeMap: computed,
      c_contractTypeIsIDIQTF: computed,
      c_contractTypeIsTaskOrderTF: computed,
      c_idiqCaptureIDTOLink: computed,
      c_captureStageID: computed,
      c_captureStageIsWonTF: computed,
      c_captureStageIsLostTF: computed,
      c_captureStageIsLostNoBidOrCancelledTF: computed,
      c_captureOverallContractValue: computed,
      c_captureReasonsWonLostIDsColonPercentComma: computed,
      c_captureShapingTotalProgress: computed,
      c_captureShapingStageProgress: computed,
      c_capturePwin: computed,
      c_captureOurPrimeSubTeammateDivisionID: computed,
      c_captureOurPrimeSubTeammateDivisionIsSmallBusinessTF: computed,
      c_captureOurPrimeSubTeammateAllocationPercent0to100: computed,
      c_captureTotalSmallBusinessAllocationPercent0to100: computed,
      c_captureIncumbentContactCompanyIDsArray: computed,
      c_captureWeAreAnIncumbentTF: computed,
      c_captureContractsManagerUserIDsToNotIncludeArray: computed,
      c_captureBudgetManagerUserIDsToNotIncludeArray: computed,
      c_captureReasonsWonLostIDsToNotIncludeArrayOrUndefined: computed,
      c_divisionOwnersCaptureFieldValueObj: computed,
      c_captureTypeCaptureFieldValueObj: computed,
      c_contractTypeCaptureFieldValueObj: computed,
      c_idiqCaptureIDTOLinkCaptureFieldValueObj: computed,
      c_stageCaptureFieldValueObj: computed,
      c_contractOverallValueCaptureFieldValueObj: computed,
      c_contractRevenueValueCaptureFieldValueObj: computed,
      c_allocatedRevenueValueCaptureFieldValueObj: computed,
      c_allocatedNetValueCaptureFieldValueObj: computed,
      c_allocatedRevenuePerMonthCaptureFieldValueObj: computed,
      c_allocatedNetPerMonthCaptureFieldValueObj: computed,
      c_pwinAdjustedContractOverallValueCaptureFieldValueObj: computed,
      c_pwinAdjustedContractRevenueValueCaptureFieldValueObj: computed,
      c_pwinAdjustedAllocatedRevenueValueCaptureFieldValueObj: computed,
      c_pwinAdjustedAllocatedNetValueCaptureFieldValueObj: computed,
      c_idiqTOAnticipatedValueCaptureFieldValueObj: computed,
      c_reasonsWonLostCaptureFieldValueObj: computed,
      c_sbAllocationCaptureFieldValueObj: computed,
      c_pwinCaptureFieldValueObj: computed,
      c_periodOfPerformanceCaptureFieldValueObj: computed,
      c_contractStartDateCaptureFieldValueObj: computed,
      c_contractEndDateCaptureFieldValueObj: computed,
      c_contractsManagerCaptureFieldValueObj: computed,
      c_ourPrimeSubTeammateDivisionIDCaptureFieldValueObj: computed,
      c_primeCompanyCaptureFieldValueObj: computed,
      c_ourIncumbentCompetitorDivisionIDCaptureFieldValueObj: computed,
      c_budgetManagerCaptureFieldValueObj: computed,
      c_notepadCaptureFieldValueObj: computed,
      c_archiveDateCaptureFieldValueObj: computed,
      c_gcssGovWinIDStringFieldValueObj: computed,
      c_solicitationNumberFieldValueObj: computed,
      c_captureTypeStagesArrayOfObjs: computed,
      c_activeStagesArrayOfObjs: computed,
      c_closedWonStagesArrayOfObjs: computed,
      c_closedLostStagesArrayOfObjs: computed,
      c_closedNoBidStagesArrayOfObjs: computed,
      c_closedCancelledStagesArrayOfObjs: computed,
      c_captureTypeStageIDsArray: computed,
      c_captureTypeClosedStageIDsArray: computed,
      c_captureTypeClosedWonLostStageIDsArray: computed,
      c_captureTypeActiveStageIDsArray: computed,
      c_captureTypeShapingQuestionsMapOfMaps: computed,
      c_captureTypeDebriefQuestionsMapOfMaps: computed,
      c_captureTypeActiveShapingCombinedQuestionsAnswersArrayOfObjs: computed,
      c_captureTypeDebriefCombinedQuestionsAnswersArrayOfObjs: computed,
      c_shapingAnswersSelectOnlyForOpenCaptureObjOfArrays: computed,
      c_shapingAnswersTextareaOnlyForOpenCaptureObjOfArrays: computed,
      c_revenueCostCaptureFieldValuesArrayOfObjs: computed,
      c_revenueCardAddedFieldIDsArray: computed,
      c_revenuePopFyrTableArrayOfObjs: computed,
      c_advanceStageRequiredAndNotFilledOutDetailsFieldsArrayOfObjs: computed,
      c_advanceStageRequiredAndNotFilledOutDatesFieldsArrayOfObjs: computed,
      c_advanceStageRequiredAndNotFilledOutRevenueCostFieldsArrayOfObjs: computed,
      c_advanceStageRequiredAndUnansweredShapingQuestionAnswersArrayOfObjs: computed,
      c_advanceStageCurrentStageExistsTF: computed,
      c_advanceStageTimelineDataObj: computed,
      c_advanceStageCurrentStageNextStageObj: computed,
      c_advanceStageCurrentStageIsActiveTFU: computed,
      c_advanceStageClosedStageTeammateRatingsTeammatesObj: computed,
      c_allCaptureTasksArrayOfObjs: computed,
      c_myActiveCaptureTasksArrayOfObjs: computed,
      c_activeCaptureTasksIAssignedToOthersArrayOfObjs: computed,
      c_activeCaptureTasksOthersAssignedToOthersArrayOfObjs: computed,
      c_myCompletedCaptureTasksArrayOfObjs: computed,
      c_completedCaptureTasksIAssignedToOthersArrayOfObjs: computed,
      c_completedCaptureTasksOthersAssignedToOthersArrayOfObjs: computed,
      c_tasksNumMyActive: computed,
      c_tasksNumActiveToOthers: computed,
      c_tasksNumActiveOtherToOther: computed,
      c_tasksNumMyCompleted: computed,
      c_tasksNumCompletedToOthers: computed,
      c_tasksNumCompletedOtherToOther: computed,
      c_tasksNumActive: computed,
      c_tasksNumCompleted: computed,
      c_datesArrayOfObjs: computed,
      c_datesCardTotalNumDates: computed,
      c_datesCardAddedFieldIDsArray: computed,
      c_upcomingDatesArrayOfObjs: computed,
      c_notsetDatesArrayOfObjs: computed,
      c_pastDatesArrayOfObjs: computed,
      c_allDatesFixedArrayOfObjs: computed,
      c_detailsSnapshotCaptureFieldValuesArrayOfObjs: computed,
      c_detailsGroupsArrayOfObjs: computed,
      c_detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs: computed,
      c_dealShapingAppliedTagsArrayOfObjs: computed,
      c_dealShapingActiveStagesArrayOfObjs: computed,
      c_dealShapingQuestionsPerActiveStageDataObj: computed,
      c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs: computed,
      c_teammatesUsMinus2CombinedTeammateObj: computed,
      c_teammatesUsMinus2TblCTeammatesRowExistsTF: computed,
      c_teammatesAllCombinedTeammatesArrayOfObjs: computed,
      c_teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs: computed,
      c_selectedLargeBusinessTeammatesArrayOfObjs: computed,
      c_selectedSmallBusinessTeammatesArrayOfObjs: computed,
      c_selectedInvalidBusinessTypeTeammatesArrayOfObjs: computed,
      c_notSelectedLargeBusinessTeammatesArrayOfObjs: computed,
      c_notSelectedSmallBusinessTeammatesArrayOfObjs: computed,
      c_notSelectedInvalidBusinessTypeTeammatesArrayOfObjs: computed,
      c_teammatesSelectedTeammatesArrayOfObjs: computed,
      c_teammatesNotSelectedTeammatesArrayOfObjs: computed,
      c_teammatesUsAndSelectedTeammatesArrayOfObjs: computed,
      c_teammatesSubCaptureTypeAllPrimesTotalPercent: computed,
      c_teammatesSelectedLargeBusinessesTotalPercent: computed,
      c_teammatesSelectedSmallBusinessesTotalPercent: computed,
      c_teammatesContactCompanyIDsArray: computed,
      c_teammatesSBCertificationAllocationsArrayOfObjs: computed,
      c_teammatesSBCertificationIDsArray: computed,
      c_teammatesOurPrimeSubTeammateDivisionMap: computed,
      c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj: computed,
      c_teammatesRatingsExpandedTeammateOverallCaptureRating: computed,
      c_teammatesRatingsExpandedTeammateMostRecentReviewDate: computed,
      c_teammatesFloatingBoxSurveyObj: computed,
      c_teammatesFloatingBoxSurveyIsOpenTF: computed,
      c_teammatesSurveysArrayOfObjs: computed,
      c_teammatesSurveysIDsArray: computed,
      c_teammatesSurveysNumSurveys: computed,
      c_teammatesSurveysSelectedExpandedSurveyObj: computed,
      c_teammatesSurveysSelectMultiDocumentsCardAllFilesFieldTypeObj: computed,
      c_teammatesSurveysResultsMatrixObj: computed,
      c_teammatesSurveysResultsMatrixMultiSelectFilterFieldTypeObj: computed,
      c_teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs: computed,
      c_competitorsArrayOfObjs: computed,
      c_competitorsExtraFieldsArrayOfObjs: computed,
      c_competitorsContactCompanyIDsArray: computed,
      c_ptDifferentiatorsArrayOfObjs: computed,
      c_ptDifferentiatorIDsAlreadyAddedArray: computed,
      c_ptWinThemesArrayOfObjs: computed,
      c_ptGhostThemesArrayOfObjs: computed,
      c_risksArrayOfObjs: computed,
      c_budgetCaptureTypeBudgetCategoriesArrayOfObjs: computed,
      c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs: computed,
      c_budgetSelectedExpandedBudgetCategoryObj: computed,
      c_budgetSelectedBudgetCategoryFundingRequestsNr0ArrayOfObjs: computed,
      c_budgetSelectedBudgetCategoryFundingRequestsRq1ArrayOfObjs: computed,
      c_budgetSelectedBudgetCategoryFundingRequestsRj2ArrayOfObjs: computed,
      c_budgetSelectedBudgetCategoryFundingRequestsRs3ArrayOfObjs: computed,
      c_budgetSelectedBudgetCategoryFundingRequestsAp4ArrayOfObjs: computed,
      c_budgetEditingFundingRequestObj: computed,
      c_conversationsArrayOfObjs: computed,
      c_upcomingConversationsArrayOfObjs: computed,
      c_notsetConversationsArrayOfObjs: computed,
      c_pastConversationsArrayOfObjs: computed,
      c_singleCaptureTemplatesWithinFoldersObj: computed,
      c_documentsFileFolderSystemMapOfMaps: computed,
      c_documentsAllFilesMapOfMaps: computed,
      c_documentsNumFiles: computed,
      c_idiqTaskOrdersObj: computed,
      c_combinedChangeLogsArrayOfObjs: computed,
      c_changelogFilteredSortedItemsArrayOfObjs: computed,
      c_changelogFilteredTotalNumItems: computed,
      c_changelogCurrentPageFirstItemNumber: computed,
      c_changelogCurrentPageLastItemNumber: computed,
      c_changelogCanIncrementCurrentPageNumberTF: computed,
      c_changelogCanDecrementCurrentPageNumberTF: computed,
      c_changelogFilteredSortedItemsOnSelectedPageArrayOfObjs: computed,
      c_changelogSelectUserIDsToNotIncludeArray: computed,
      c_notepadNoteStampsArrayOfObjs: computed,

      a_open_single_capture: action,
      a_set_open_capture_id: action,
      a_close_single_capture: action,
      a_clear_single_capture_from_local_memory: action,
      a_launch_card_left: action,
      a_launch_card_right: action,
      a_launch_card_full: action,
      a_close_card_left: action,
      a_close_card_right: action,
      a_close_card_full: action,
      a_run_when_card_that_wasnt_open_is_opened: action,
      a_run_when_card_is_closed: action,
      a_set_capture_connected_to_gcss_compare_floating_box_is_open_tf: action,
      a_set_capture_connected_to_gcss_fetched_single_sam_expanded_detail_search_results_obj: action,
      a_set_capture_connected_to_gcss_loading_flag_or_error_message: action,
      a_capture_connected_to_gcss_fetch_single_sam_record_data: action,
      a_set_user_per_email_ids_with_same_capture_open: action,
      a_copy_capture_set_copy_state: action,
      a_copy_capture_set_copy_stage_tf: action,
      a_copy_capture_set_copy_dates_tf: action,
      a_copy_capture_set_copy_tasks_tf: action,
      a_copy_capture_set_copy_deal_shaping_tf: action,
      a_copy_capture_set_copy_teammates_tf: action,
      a_copy_capture_set_copy_teammates_surveys_tf: action,
      a_copy_capture_set_copy_competitors_tf: action,
      a_copy_capture_set_copy_proposal_themes_tf: action,
      a_copy_capture_set_copy_risks_tf: action,
      a_copy_currently_open_capture_close_then_open_copied_capture: action,
      a_delete_capture_set_delete_state: action,
      a_delete_and_close_currently_open_capture: action,
      a_delete_capture_request_admin_delete_send_notification: action,
      a_tasks_set_mobile_showing_active_tf: action,
      a_deal_shaping_apply_tag_id: action,
      a_deal_shaping_remove_tag_id: action,
      a_deal_shaping_update_answer: action,
      a_details_update_field_value: action,
      a_teammates_set_left_tab_selected: action,
      a_teammates_update_teammate_field: action,
      a_teammates_insert_new_teammates: action,
      a_teammates_delete_teammate: action,
      a_teammates_update_total_req_sb_allocation: action,
      a_teammates_update_sb_certification_allocation: action,
      a_teammates_insert_new_sb_certifications: action,
      a_teammates_delete_sb_certification_allocation: action,
      a_teammates_expand_collapse_teammate: action,
      a_teammates_add_contact_person: action,
      a_teammates_remove_contact_person: action,
      a_teammates_ratings_update_or_insert_questionnaire_submission: action,
      a_teammates_ratings_recompute_contact_teammate_ratings_from_contact_company_id: action,
      a_teammates_ratings_compute_contact_company_teammate_ratings_from_filtered_questionnaire_submissions_arrayOfObjs: action,
      a_teammates_surveys_add_contact_persons: action,
      a_teammates_surveys_remove_contact_person: action,
      a_teammates_floating_box_survey_send_out_survey_email: action,
      a_teammates_set_floating_box_survey_teammate_id_and_survey_id: action,
      a_teammates_floating_box_survey_update_or_insert_answer_rating_and_comment: action,
      a_teammates_floating_box_survey_update_respone_times_completed_datetime_utc: action,
      a_teammates_floating_box_survey_update_or_insert_unsaved_answer_text_responses: action,
      a_teammates_surveys_select_survey: action,
      a_teammates_surveys_select_capabilities_gap_analysis_subtab: action,
      a_teammates_surveys_create_new_empty_survey_from_new_title: action,
      a_teammates_surveys_update_survey_title: action,
      a_teammates_surveys_update_survey_invitation_text: action,
      a_teammates_surveys_delete_survey: action,
      a_teammates_surveys_edit_questions_set_add_new_question_floating_box_is_open_tf: action,
      a_teammates_surveys_edit_questions_set_copy_questions_floating_box_is_open_tf: action,
      a_teammates_surveys_edit_questions_set_import_questions_floating_box_is_open_tf: action,
      a_teammates_surveys_insert_csv_imported_new_questions: action,
      a_teammates_surveys_add_new_question: action,
      a_teammates_surveys_delete_all_questions_from_currently_selected_survey: action,
      a_teammates_surveys_update_question_type_id: action,
      a_teammates_surveys_update_question_text: action,
      a_teammates_surveys_delete_question: action,
      a_teammates_surveys_documents_include_files_from_documents_card: action,
      a_teammates_set_surveys_working_on_sending_survey_tf: action,
      a_teammates_surveys_survey_results_matrix_set_prepare_survey_to_multiple_teammates_floating_box_is_open_tf: action,
      a_teammates_surveys_results_matrix_set_filter_ids_comma: action,
      a_teammates_surveys_survey_results_matrix_export_survey_matrix_to_excel_xml: action,
      a_set_expanded_competitor_id: action,
      a_competitors_update_changed_field_value: action,
      a_competitors_insert_new_competitors: action,
      a_competitors_delete_competitor: action,
      a_competitors_remove_incumbent_marking_from_capture_id_and_contact_company_id: action,
      a_competitors_add_teammate_contact_companies: action,
      a_competitors_remove_teammate_contact_company: action,
      a_pt_differentiators_set_id_adding_or_editing: action,
      a_pt_win_themes_set_id_adding_or_editing: action,
      a_pt_ghost_themes_set_id_adding_or_editing: action,
      a_differentiators_insert_new_differentiator: action,
      a_differentiators_update_differentiator: action,
      a_differentiators_delete_differentiator: action,
      a_themes_insert_new_theme: action,
      a_themes_update_theme: action,
      a_themes_delete_theme: action,
      a_risks_insert_new_risk: action,
      a_risks_update_risk: action,
      a_risks_delete_risk: action,
      a_budget_set_selected_budget_category_id: action,
      a_budget_set_mobile_page: action,
      a_budget_set_editing_funding_request_id: action,
      a_budget_create_new_empty_funding_request: action,
      a_budget_create_new_funding_request_return_unspent_funding: action,
      a_budget_update_funding_request_field: action,
      a_budget_delete_funding_request: action,
      a_conversations_insert_new_conversation: action,
      a_conversations_update_conversation: action,
      a_conversations_delete_conversation: action,
      a_notepad_set_note_stamps_new_edit_read_floating_box_flag: action,
      a_notepad_set_note_stamps_editing_or_reading_note_stamp_obj_or_undefined: action,
      a_notepad_insert_new_note_stamp: action,
      a_notepad_update_note_stamp: action,
      a_notepad_delete_note_stamp: action,
      a_notepad_pin_or_unpin_note_stamp: action,
      a_load_single_capture_changelog_data: action,
      a_set_changelog_items_per_page: action,
      a_set_changelog_current_page_number: action,
      a_set_changelog_sort_column_db_name: action,
      a_set_changelog_sort_is_asc_tf: action,
      a_set_changelog_filter_capture_card_ids_comma: action,
      a_set_changelog_filter_capture_field_id: action,
      a_set_changelog_filter_user_id: action,
      a_set_changelog_filter_date_min: action,
      a_set_changelog_filter_date_max: action
    });
  }

  //===computed fields============================================================================================
  //capture slide out panel
  get c_singleCaptureIsOpenTF() {
    return(JSFUNC.is_number_not_nan_gt_0(this.o_openCaptureID));
  }


  get c_captureConnectedToGcssGovWinAndSamFieldsWithValuesArrayOfObjs() {
    const o_captureConnectedToGcssFetchedSingleSamExpandedDetailSearchResultsObjOrUndefined = this.o_captureConnectedToGcssFetchedSingleSamExpandedDetailSearchResultsObjOrUndefined;
    const c_openCaptureMap = this.c_openCaptureMap;

    const allGcssFieldsWithHistoryObjNamesOrganizedBySingleDetailSectionsArrayOfObjs = GCSSMobx.get_all_gcss_fields_with_history_obj_names_organized_by_single_detail_sections_arrayOfObjs();
    const samFetchExistsTF = (o_captureConnectedToGcssFetchedSingleSamExpandedDetailSearchResultsObjOrUndefined !== undefined);

    var captureConnectedToGcssGovWinAndSamFieldsWithValuesArrayOfObjs = [];
    for(let gcssDetailSectionObj of allGcssFieldsWithHistoryObjNamesOrganizedBySingleDetailSectionsArrayOfObjs) {
      if(gcssDetailSectionObj.leftSideTF || gcssDetailSectionObj.rightSideTF) { //only include left/right side of GCSS single details panel fields sections, sections where both are false contain fields only needed for GCSS database info (not fields for display)
        var connectedFieldsArrayOfObjs = [];
        for(let gcssGovWinAndSamFieldObj of gcssDetailSectionObj.gcssFieldsArrayOfObjs) {
          if(gcssGovWinAndSamFieldObj.samTF || gcssGovWinAndSamFieldObj.govWinTF) { //only include for comparison fields that are either SAM or GovWin
            var valuesMatchTFU = undefined; //undefined (gray color block) represents the capture or SAM field/value not existing

            var currentCaptureFieldValueObj = DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(c_openCaptureMap, gcssGovWinAndSamFieldObj.expandedCaptureFieldMap);

            var samValueMaskSortIfoObj = undefined;
            if(samFetchExistsTF) {
              var fieldHistoryObj = o_captureConnectedToGcssFetchedSingleSamExpandedDetailSearchResultsObjOrUndefined[gcssGovWinAndSamFieldObj.fieldHistoryObjName];
              if(fieldHistoryObj !== undefined) {
                samValueMaskSortIfoObj = fieldHistoryObj.currentValueMaskSortIfoObj;
              }
            }

            if((currentCaptureFieldValueObj !== undefined) && (samValueMaskSortIfoObj !== undefined)) {
              valuesMatchTFU = (currentCaptureFieldValueObj.valueMaskPlainText === samValueMaskSortIfoObj.valueMaskPlainText);
            }

            var valuesMatchBgHashColor = undefined; //match (white)
            if(valuesMatchTFU === false) { valuesMatchBgHashColor = "#ddcc22"; } //doesn't match (yellow)
            else if(valuesMatchTFU === undefined) { valuesMatchBgHashColor = "#cccccc"; } //field doesn't exist (gray)

            connectedFieldsArrayOfObjs.push({
              fieldDisplayName: gcssGovWinAndSamFieldObj.expandedCaptureFieldMap.get("display_name"),
              currentCaptureFieldValueObj: currentCaptureFieldValueObj,
              samValueMaskSortIfoObj: samValueMaskSortIfoObj,
              valuesMatchBgHashColor: valuesMatchBgHashColor
            });
          }
        }

        captureConnectedToGcssGovWinAndSamFieldsWithValuesArrayOfObjs.push({
          sectionHeader: gcssDetailSectionObj.sectionHeader,
          gcssFieldsArrayOfObjs: connectedFieldsArrayOfObjs
        });
      }
    }

    return(captureConnectedToGcssGovWinAndSamFieldsWithValuesArrayOfObjs);
  }


  get c_userNamesWithSameCaptureOpenHoverString() { //string that appears if you hover over the icon in the top bar next to the capture name which indicates other users that have this capture open at the same time
    if(!this.o_userPerEmailIDsWithSameCaptureOpenComma) {
      return(undefined); //undefined is the flag for the display code not to show the icon
    }

    const userPerEmailIDsWithSameCaptureOpenArray = JSFUNC.convert_comma_list_to_int_array(this.o_userPerEmailIDsWithSameCaptureOpenComma);
    var userNamesWithSameCaptureOpenArray = [];
    for(let userPerEmailID of userPerEmailIDsWithSameCaptureOpenArray) {
      if(userPerEmailID !== UserMobx.o_userPerEmailID) { //do not include yourself as viewing this capture (should never happen)
        var userPerEmailMap = DatabaseMobx.o_tbl_a_users_per_email.get(userPerEmailID);
        if(userPerEmailMap !== undefined) {
          userNamesWithSameCaptureOpenArray.push(JSFUNC.full_name_from_first_name_last_name(userPerEmailMap.get("first_name"), userPerEmailMap.get("last_name")));
        }
      }
    }

    const numUsersWithSameCaptureOpen = userNamesWithSameCaptureOpenArray.length;
    if(numUsersWithSameCaptureOpen === 0) {
      return(undefined);
    }

    const userNamesWithSameCaptureOpenCommaWithAnd = JSFUNC.convert_array_to_comma_list_with_oxford_and(userNamesWithSameCaptureOpenArray);
    return(userNamesWithSameCaptureOpenCommaWithAnd + " also " + JSFUNC.plural(numUsersWithSameCaptureOpen, "has", "have") + " this capture open");
  }





  //snapshot cards or left/right/full cards open
  get c_cardIDNotInSnapshotCardsContainer() { //not possible for snapshot to be displayed when 2 cards are open left/right, thus just taking 1 of the open cardIDs is sufficient here
    if(this.o_openCardFullID > 0) { return(this.o_openCardFullID); }
    else if(this.o_openCardLeftID > 0) { return(this.o_openCardLeftID); }
    else if(this.o_openCardRightID > 0) { return(this.o_openCardRightID); }
    return(undefined);
  }



  //gather individual capture field values and linked data from fields with id references
  get c_captureExistsTF() {
    if((this.o_openCaptureID === undefined) || (this.o_openCaptureID <= 0)) {
      return(false);
    }
    return(DatabaseMobx.o_tbl_captures.has(this.o_openCaptureID));
  }

  get c_openCaptureMap() { //single map, key: field db_name, value: capture raw database value (textareas have tags stripped and are truncated due to php code delivery)
    if(this.c_captureExistsTF) {
      return(DatabaseMobx.o_tbl_captures.get(this.o_openCaptureID));
    }
    return(new Map());
  }

  get c_userIsCaptureManagerOfOpenCaptureTF() {
    if(this.c_captureExistsTF) {
      var openCaptureCaptureManagerIDsColonCommaList = this.c_openCaptureMap.get("capture_managers_ids_colon_percent_comma");
      return(JSFUNC.int1_is_in_colon_comma_list_tf(UserMobx.o_userID, openCaptureCaptureManagerIDsColonCommaList));
    }
    return(false);
  }

  get c_captureName() {
    return(DatabaseMobx.capture_name_plaintext_from_capture_map(this.c_openCaptureMap));
  }

  get c_captureDirectAccessLink() {
    if(this.c_captureExistsTF) {
      return(this.c_openCaptureMap.get("direct_access_link"));
    }
    return("");
  }

  get c_captureIsArchivedTF() {
    return(JSFUNC.date_is_filled_out_tf(this.c_archiveDateCaptureFieldValueObj.valueRaw));
  }

  get c_captureTypeID() {
    return(this.c_openCaptureMap.get("capture_type_id"));
  }
  get c_captureTypeExistsTF() {
    if(!JSFUNC.select_int_is_filled_out_tf(this.c_captureTypeID)) {
      return(false);
    }
    return(DatabaseMobx.o_tbl_a_capture_types.has(this.c_captureTypeID));
  }
  get c_captureTypeMap() {
    return(DatabaseMobx.tbl_row_map_from_id("tbl_a_capture_types", this.c_captureTypeID));
  }
  get c_captureTypeName() {
    return(this.c_captureTypeMap.get("name"));
  }
  get c_captureTypeIsPrimeTF() {
    return(DatabaseMobx.capture_type_is_prime_tf_from_capture_map(this.c_openCaptureMap));
  }
  get c_captureTypeVisibleCardIDsArray() { //capture card ids from comma list based on captureType
    const c_availableDetailsCardIDsArray = DatabaseMobx.c_availableDetailsCardIDsArray;

    const openCaptureCaptureTypeCardIDsComma = this.c_captureTypeMap.get("card_ids_comma");
    const openCaptureCaptureTypeCardIDsArray = JSFUNC.convert_comma_list_to_int_array(openCaptureCaptureTypeCardIDsComma);
    var openCaptureCaptureTypeAvailableCardIDsArray = [];
    for(let cardID of openCaptureCaptureTypeCardIDsArray) {
      if(JSFUNC.in_array(cardID, c_availableDetailsCardIDsArray)) {
        openCaptureCaptureTypeAvailableCardIDsArray.push(cardID);
      }
    }
    return(openCaptureCaptureTypeAvailableCardIDsArray);
  }
  get c_captureTypeHas0CardsTF() {
    return(this.c_captureTypeVisibleCardIDsArray.length === 0);
  }

  get c_contractTypeID() {
    return(this.c_openCaptureMap.get("contract_type_id"));
  }
  get c_contractTypeMap() {
    return(DatabaseMobx.tbl_row_map_from_id("tbl_a_contract_types", this.c_contractTypeID));
  }
  get c_contractTypeIsIDIQTF() {
    return(DatabaseMobx.contract_type_is_idiq_tf_from_capture_map(this.c_openCaptureMap));
  }
  get c_contractTypeIsTaskOrderTF() {
    return(DatabaseMobx.contract_type_is_task_order_tf_from_capture_map(this.c_openCaptureMap));
  }

  get c_idiqCaptureIDTOLink() {
    return(this.c_openCaptureMap.get("idiq_capture_id_TO_link"));
  }
  get c_captureStageID() {
    return(this.c_openCaptureMap.get("stage_id"));
  }
  get c_captureStageIsWonTF() {
    return(JSFUNC.in_array(this.c_captureStageID, DatabaseMobx.c_closedWonStageIDsArray));
  }
  get c_captureStageIsLostTF() {
    return(JSFUNC.in_array(this.c_captureStageID, DatabaseMobx.c_closedLostStageIDsArray));
  }
  get c_captureStageIsLostNoBidOrCancelledTF() {
    return(JSFUNC.in_array(this.c_captureStageID, DatabaseMobx.c_lostNoBidOrCancelledStageIDsArray));
  }
  get c_captureOverallContractValue() {
    return(this.c_openCaptureMap.get("contract_overall_value"));
  }
  get c_captureReasonsWonLostIDsColonPercentComma() {
    return(this.c_openCaptureMap.get("reasons_won_lost_ids_colon_percent_comma"));
  }
  get c_captureShapingTotalProgress() {
    return(this.c_openCaptureMap.get("shaping_total_progress"));
  }
  get c_captureShapingStageProgress() {
    return(this.c_openCaptureMap.get("shaping_stage_progress"));
  }
  get c_capturePwin() {
    return(this.c_openCaptureMap.get("pwin"));
  }
  get c_captureOurPrimeSubTeammateDivisionID() {
    return(this.c_openCaptureMap.get("our_prime_sub_teammate_division_id"));
  }
  get c_captureOurPrimeSubTeammateDivisionIsSmallBusinessTF() {
    const ourPrimeSubTeammateDivisionMap = DatabaseMobx.c_tbl_a_divisions.get(this.c_captureOurPrimeSubTeammateDivisionID);
    if(ourPrimeSubTeammateDivisionMap !== undefined) {
      return(ourPrimeSubTeammateDivisionMap.get("divisionIsSmallBusinessTF"));
    }
    return(false); //invalid divisions are considered not small (large) by default
  }
  get c_captureOurPrimeSubTeammateAllocationPercent0to100() {
    return(DatabaseMobx.capture_our_prime_sub_teammate_allocation_from_capture_map(this.c_openCaptureMap));
  }
  get c_captureTotalSmallBusinessAllocationPercent0to100() {
    return(this.c_openCaptureMap.get("req_sb_allocation"));
  }
  get c_captureIncumbentContactCompanyIDsArray() {
    const captureIncumbentContactCompanyIDsComma = this.c_openCaptureMap.get("incumbent_contact_company_ids_comma");
    return(JSFUNC.convert_comma_list_to_int_array(captureIncumbentContactCompanyIDsComma));
  }
  get c_captureWeAreAnIncumbentTF() {
    return(JSFUNC.in_array(-2, this.c_captureIncumbentContactCompanyIDsArray));
  }

  get c_captureContractsManagerUserIDsToNotIncludeArray() { //when selecting a contracts manager for this open capture, remove users that would be firewalled off from this capture (if they could be selected, they would get a notification to do work on a capture they can't load in their system)
    return(DatabaseMobx.get_user_ids_array_that_cannot_access_single_capture_from_user_ids_array_and_capture_id(DatabaseMobx.c_userIDsOfAllContractsPowerUsersNotDeactivatedArray, this.o_openCaptureID));
  }
  get c_captureBudgetManagerUserIDsToNotIncludeArray() { //when selecting a budget manager for this open capture, remove users that would be firewalled off from this capture (if they could be selected, they would get a notification to do work on a capture they can't load in their system)
    return(DatabaseMobx.get_user_ids_array_that_cannot_access_single_capture_from_user_ids_array_and_capture_id(DatabaseMobx.c_userIDsOfAllBudgetPowerUsersNotDeactivatedArray, this.o_openCaptureID));
  }
  get c_captureReasonsWonLostIDsToNotIncludeArrayOrUndefined() { //when selecting Reasons Won/Lost (in DetailsItem within AdvanceStage card Requirements List section), filter out the reasons based on which stage the open capture is currently in
    if(this.c_captureStageIsWonTF) {
      return(DatabaseMobx.c_reasonsLostIDsArray); //remove only the lost reasons, keeping the won/both reasons
    }
    else if(this.c_captureStageIsLostTF) {
      return(DatabaseMobx.c_reasonsWonIDsArray); //remove only the won reasons, keeping the lost/both reasons
    }
    return(undefined);
  }

  //capture field value objs (pairs an expanded capture field map with the raw/mask/sort values from the open capture)
  get c_divisionOwnersCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfDivisionOwners));
  }
  get c_captureTypeCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfCaptureType));
  }
  get c_contractTypeCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfContractType));
  }
  get c_idiqCaptureIDTOLinkCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfIdiqCaptureIDTOLink));
  }
  get c_stageCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfStage));
  }
  get c_contractOverallValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfContractOverallValue));
  }
  get c_contractRevenueValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfContractRevenueValue));
  }
  get c_allocatedRevenueValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfAllocatedRevenueValue));
  }
  get c_allocatedNetValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfAllocatedNetValue));
  }
  get c_allocatedRevenuePerMonthCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfAllocatedRevenuePerMonth));
  }
  get c_allocatedNetPerMonthCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfAllocatedNetPerMonth));
  }
  get c_pwinAdjustedContractOverallValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfPwinAdjustedContractOverallValue));
  }
  get c_pwinAdjustedContractRevenueValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfPwinAdjustedContractRevenueValue));
  }
  get c_pwinAdjustedAllocatedRevenueValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfPwinAdjustedAllocatedRevenueValue));
  }
  get c_pwinAdjustedAllocatedNetValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfPwinAdjustedAllocatedNetValue));
  }
  get c_idiqTOAnticipatedValueCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfIDIQTOAnticipatedValue));
  }
  get c_reasonsWonLostCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfReasonsWonLost));
  }
  get c_sbAllocationCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfSmallBusinessAllocation));
  }
  get c_pwinCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfPwin));
  }
  get c_periodOfPerformanceCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfPeriodOfPerformance));
  }
  get c_contractStartDateCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfContractStartDate));
  }
  get c_contractEndDateCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfContractEndDate));
  }
  get c_contractsManagerCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfContractsManager));
  }
  get c_ourPrimeSubTeammateDivisionIDCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateDivisionID));
  }
  get c_primeCompanyCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfPrimeCompany));
  }
  get c_ourIncumbentCompetitorDivisionIDCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfOurIncumbentCompetitorDivisionID));
  }
  get c_budgetManagerCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfBudgetManager));
  }
  get c_notepadCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfNotepad));
  }
  get c_archiveDateCaptureFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfArchiveDate));
  }
  get c_solicitationNumberFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfSolicitationNumber));
  } 
  get c_gcssGovWinIDStringFieldValueObj() {
    return(DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(this.c_openCaptureMap, DatabaseMobx.c_fieldMapOfGcssGovWinIDString));
  }

  //stages for this capture type
  get c_captureTypeStagesArrayOfObjs() { //get all of the stages in order for this captureType
    const stageIDsComma = this.c_captureTypeMap.get("stage_ids_comma"); //already sorted in order based on order of stageID commas in database
    const stagesTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_stages_pool"); //stage pool fields: "id", "name", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", "color", "admin_sort"
    const stagesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_id_array_or_comma(stagesTblRef, stageIDsComma);
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(stagesMapOfMaps));
  }

  get c_activeStagesArrayOfObjs() {
    const filterFunction = (i_stageObj) => { return(i_stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7 === 1 || i_stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7 === 2 || i_stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7 === 3); };
    return(this.c_captureTypeStagesArrayOfObjs.filter(filterFunction));
  }

  get c_closedWonStagesArrayOfObjs() {
    const filterFunction = (i_stageObj) => { return(i_stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7 === 4); };
    return(this.c_captureTypeStagesArrayOfObjs.filter(filterFunction));
  }

  get c_closedLostStagesArrayOfObjs() {
    const filterFunction = (i_stageObj) => { return(i_stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7 === 5); };
    return(this.c_captureTypeStagesArrayOfObjs.filter(filterFunction));
  }

  get c_closedNoBidStagesArrayOfObjs() {
    const filterFunction = (i_stageObj) => { return(i_stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7 === 6); };
    return(this.c_captureTypeStagesArrayOfObjs.filter(filterFunction));
  }

  get c_closedCancelledStagesArrayOfObjs() {
    const filterFunction = (i_stageObj) => { return(i_stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7 === 7 || i_stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7 === 8); };
    return(this.c_captureTypeStagesArrayOfObjs.filter(filterFunction));
  }

  get c_captureTypeStageIDsArray() { //used to determine questions that fall in the "Stage Independent" category on deal shaping
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_captureTypeStagesArrayOfObjs, "id"));
  }

  get c_captureTypeClosedStageIDsArray() { //used to determine questions that fall in the "Stage Independent" plus "Active" levels on deal shaping
    var closedStageIDsArray = [];
    for(let stageObj of this.c_closedWonStagesArrayOfObjs) { closedStageIDsArray.push(stageObj.id); }
    for(let stageObj of this.c_closedLostStagesArrayOfObjs) { closedStageIDsArray.push(stageObj.id); }
    for(let stageObj of this.c_closedNoBidStagesArrayOfObjs) { closedStageIDsArray.push(stageObj.id); }
    for(let stageObj of this.c_closedCancelledStagesArrayOfObjs) { closedStageIDsArray.push(stageObj.id); }
    return(closedStageIDsArray);
  }

  get c_captureTypeClosedWonLostStageIDsArray() {
    var closedStageIDsArray = [];
    for(let stageObj of this.c_closedWonStagesArrayOfObjs) { closedStageIDsArray.push(stageObj.id); }
    for(let stageObj of this.c_closedLostStagesArrayOfObjs) { closedStageIDsArray.push(stageObj.id); }
    return(closedStageIDsArray);
  }

  get c_captureTypeActiveStageIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_activeStagesArrayOfObjs, "id"));
  }

  //deal shaping question id references and stage relevance for this capture type
  get c_captureTypeShapingQuestionsMapOfMaps() { //every question reference assigned to this capture type, either active/invalid stage_id_relevant
    const captureTypeShapingQuestionsTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_capture_types_shaping_questions");
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(captureTypeShapingQuestionsTblRef, "capture_type_id", this.c_captureTypeID));
  }

  get c_captureTypeDebriefQuestionsMapOfMaps() { //every closed stage debrief question reference assigned to this capture type
    const captureTypeDebriefQuestionsTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_capture_types_debrief_questions");
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(captureTypeDebriefQuestionsTblRef, "capture_type_id", this.c_captureTypeID));
  }

  get c_captureTypeActiveShapingCombinedQuestionsAnswersArrayOfObjs() { //every shaping capture type question reference coupled with its question and answer data
    var combinedShapingQuestionAnswersArrayOfObjs = [];
    for(let captureTypeShapingQuestionMap of this.c_captureTypeShapingQuestionsMapOfMaps.values()) { //loop through each shaping question defined for this capture type
      var questionAnswerObj = this.create_combined_shaping_question_answer_obj_from_capture_type_shaping_question_map(captureTypeShapingQuestionMap);
      combinedShapingQuestionAnswersArrayOfObjs.push(questionAnswerObj);
    }
    JSFUNC.sort_arrayOfObjs(combinedShapingQuestionAnswersArrayOfObjs, "sort", true); //sort the questions in this stage by the sort number
    return(combinedShapingQuestionAnswersArrayOfObjs);
  }

  get c_captureTypeDebriefCombinedQuestionsAnswersArrayOfObjs() { //every debrief capture type question reference coupled with its question and answer data
    var combinedDebriefQuestionAnswersArrayOfObjs = [];
    for(let captureTypeDebriefQuestionMap of this.c_captureTypeDebriefQuestionsMapOfMaps.values()) { //loop through each debrief question defined for this capture type
      var questionAnswerObj = this.create_combined_debrief_question_answer_obj(captureTypeDebriefQuestionMap);
      combinedDebriefQuestionAnswersArrayOfObjs.push(questionAnswerObj);
    }
    JSFUNC.sort_arrayOfObjs(combinedDebriefQuestionAnswersArrayOfObjs, "sort", true); //sort all closed debrief questions by the sort field
    return(combinedDebriefQuestionAnswersArrayOfObjs);
  }

  create_combined_shaping_question_answer_obj_from_capture_type_shaping_question_map(i_captureTypeShapingQuestionMap, i_changedQuestionID=undefined, i_changedQuestionNewAnswer=undefined) {
    //question/answer obj from the questionID specified by this capture type shaping question reference
    const questionID = i_captureTypeShapingQuestionMap.get("question_id");
    const activeQuestionTrueClosedDebriefQuestionFalse = true;
    var questionAnswerObj = this.create_combined_question_answer_obj_from_question_id(questionID, activeQuestionTrueClosedDebriefQuestionFalse, i_changedQuestionID, i_changedQuestionNewAnswer);

    //add tbl_a_capture_types_shaping_questions data fields to the question pool fields
    questionAnswerObj.sort = i_captureTypeShapingQuestionMap.get("sort");
    questionAnswerObj.progress_weight = i_captureTypeShapingQuestionMap.get("progress_weight");
    questionAnswerObj.pwin_weight = i_captureTypeShapingQuestionMap.get("pwin_weight");
    questionAnswerObj.stage_id_relevant = i_captureTypeShapingQuestionMap.get("stage_id_relevant");
    questionAnswerObj.rec1_reqsig2_req3 = i_captureTypeShapingQuestionMap.get("rec1_reqsig2_req3");

    //compute progress answer score total and full question weight total for this question
    if(questionAnswerObj.progress_weight > 0) {
      questionAnswerObj.progressScoreWeight = ((questionAnswerObj.answerScore0to100 / 100) * questionAnswerObj.progress_weight); //add a fraction of the question's total progress_weight
      questionAnswerObj.progressTotalWeight = questionAnswerObj.progress_weight; //add the full value of the question progress_weight
    }
    else {
      questionAnswerObj.progressScoreWeight = 0;
      questionAnswerObj.progressTotalWeight = 0;
    }

    //pwin
    if(questionAnswerObj.pwin_weight > 0) {
      questionAnswerObj.pwinScoreWeight = ((questionAnswerObj.answerScore0to100 / 100) * questionAnswerObj.pwin_weight); //add a fraction of the question's total pwin_weight
      questionAnswerObj.pwinTotalWeight = questionAnswerObj.pwin_weight; //add the full value of the question pwin_weight
    }
    else {
      questionAnswerObj.pwinScoreWeight = 0;
      questionAnswerObj.pwinTotalWeight = 0;
    }

    return(questionAnswerObj);
  }

  create_combined_debrief_question_answer_obj(i_captureTypeDebriefQuestionMap) {
    const questionID = i_captureTypeDebriefQuestionMap.get("question_id");
    const activeQuestionTrueClosedDebriefQuestionFalse = false;
    const changedQuestionID = undefined;
    const changedQuestionNewAnswer = undefined;
    var questionAnswerObj = this.create_combined_question_answer_obj_from_question_id(questionID, activeQuestionTrueClosedDebriefQuestionFalse, changedQuestionID, changedQuestionNewAnswer);
    questionAnswerObj.sort = i_captureTypeDebriefQuestionMap.get("sort"); //include the sort from the capture type debrief question assignment
    questionAnswerObj.closed_stage_id = i_captureTypeDebriefQuestionMap.get("closed_stage_id"); //debrief questions have a closed stage id they are attached to

    return(questionAnswerObj);
  }

  create_combined_question_answer_obj_from_question_id(i_questionID, i_activeQuestionTrueClosedDebriefQuestionFalse, i_changedQuestionID=undefined, i_changedQuestionNewAnswer=undefined) {
    //get the question from the question pool by question_id (if the question does not exist, produces a questionMap where all fields are Does Not Exist)
    const questionMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shaping_questions_pool", i_questionID);

    //convert questionMap into an obj with fields "id", "name", "answer_select1_text2_textarea3", "tag_ids_comma", "admin_sort", "hover_text"
    var questionAnswerObj = JSFUNC.obj_from_map(questionMap);

    //question tagIDs converted to an array and counted
    questionAnswerObj.tagIDsArray = JSFUNC.convert_comma_list_to_int_array(questionAnswerObj.tag_ids_comma);
    questionAnswerObj.numTags = questionAnswerObj.tagIDsArray.length;

    //convert the stylingStringCommaList to a presetStylingObj
    questionAnswerObj.presetStylingObj = DatabaseMobx.get_preset_styling_obj_from_styling_string_comma_list_or_preset_colon_id_string(questionAnswerObj.question_name_styling_string_comma);

    //create the fieldTypeObj for now to edit the answer to this question (for select type, all of the answers are gathered to make the selectWithSearchDataObj)
    questionAnswerObj.fieldTypeObj = DatabaseMobx.get_question_field_type_obj_from_question_id(questionAnswerObj.id); //get the precomputed fieldTypeObj, "select" for select question types, "scored_text"/"scored_textarea" for those question types (precomputed in computed fields below, select needs its swsDataObj created and added to it for its own answers)

    //create extra fields to store the answer for this question for the currently open capture
    const answerValueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_shaping_question_id_and_answer_type123(i_questionID, questionAnswerObj.answer_select1_text2_textarea3, i_activeQuestionTrueClosedDebriefQuestionFalse, i_changedQuestionID, i_changedQuestionNewAnswer);
    questionAnswerObj.answerValueRaw = answerValueMaskSortIfoObj.valueRaw;
    questionAnswerObj.answerValueMaskPlainText = answerValueMaskSortIfoObj.valueMaskPlainText;
    questionAnswerObj.answerValueMask = answerValueMaskSortIfoObj.valueMask;
    questionAnswerObj.answerScore0to100 = answerValueMaskSortIfoObj.score0to100;
    questionAnswerObj.answerIsFilledOutTF = answerValueMaskSortIfoObj.isFilledOutTF;

    return(questionAnswerObj);
  }

  value_mask_sort_ifo_obj_from_shaping_question_id_and_answer_type123(i_questionPoolID, i_answerType123, i_activeQuestionTrueClosedDebriefQuestionFalse, i_changedQuestionID=undefined, i_changedQuestionNewAnswer=undefined) {
    //i_questionPoolID: id from a question row in tbl_a_shaping_questions_pool
    //i_answerType123:  1-select, 2-text, 3-textarea
    //i_activeQuestionTrueClosedDebriefQuestionFalse: true-active stage deal shaping question (has percent score for text/textarea questions), false-closed stage debrief deal shaping question (score slider is hidden for text/textarea questions)

    var valueRaw = undefined; //valueRaw, single id int for select answer types, obj with fields "textarea" and "score0to100" for text/textarea answer types
    var valueMaskPlainText = undefined;
    var valueMask = undefined;
    var isFilledOutTF = false; //(used in OpenCaptureMobx to determine for AdvanceStage if questions have been filled out) true if the record exists and the selected answer is valid as one of the existing select choices
    var score0to100 = 0; //(used for computing progress percent calculations) by default for answers not filled out (record does not exist in answer data tbl)

    //get this capture's selected or entered answer weight for this question
    if(i_answerType123 === 1) { //select question
      //determine if this select question has been answered yet and get the masked/sort/isFilledOut values from the answer rawValue
      if(i_changedQuestionID !== undefined && i_changedQuestionID === i_questionPoolID) { //this question has been changed
        valueRaw = i_changedQuestionNewAnswer; //select answer pool id
        var answerPoolMap = DatabaseMobx.o_tbl_a_shaping_select_answers.get(valueRaw);
        if(answerPoolMap !== undefined) {
          valueMaskPlainText = answerPoolMap.get("name"); //selected answer text
          valueMask = valueMaskPlainText;
          score0to100 = answerPoolMap.get("score0to100"); //set the score0to100 to the selected answer's score
          isFilledOutTF = true;
        }
        else {
          valueMaskPlainText = "--Answer Does Not Exist (ID: " + valueRaw + ")--";
          valueMask = DatabaseMobx.not_filled_out_font_html(valueMaskPlainText, false);
          score0to100 = 0;
          isFilledOutTF = false;
        }
      }
      else { //load the answer normally without the optional changed inputs
        const matchIndex = this.c_shapingAnswersSelectOnlyForOpenCaptureObjOfArrays.questionIDsArray.indexOf(i_questionPoolID);
        if(matchIndex >= 0) { //answer record exists, has been answered at least once
          valueRaw = this.c_shapingAnswersSelectOnlyForOpenCaptureObjOfArrays.selectAnswerIDsArray[matchIndex]; //select answer pool id
          if(valueRaw > 0) { //quick check to make sure the selected answerID is filled out (-1 is a flag that this answer is not filled out)
            var answerPoolMap = DatabaseMobx.o_tbl_a_shaping_select_answers.get(valueRaw);
            if(answerPoolMap !== undefined) {
              valueMaskPlainText = answerPoolMap.get("name"); //selected answer text
              valueMask = valueMaskPlainText;
              score0to100 = answerPoolMap.get("score0to100"); //set the score0to100 to the selected answer's score
              isFilledOutTF = true;
            }
            else { //answer does not exist in the answer pool
              valueMaskPlainText = "--Answer Does Not Exist (ID: " + valueRaw + ")--";
              valueMask = DatabaseMobx.not_filled_out_font_html(valueMaskPlainText, false);
              score0to100 = 0;
              isFilledOutTF = false;
            }
          }
          else { //answers has been answered with no answer selected
            valueMaskPlainText = "--No Answer Selected--";
            valueMask = DatabaseMobx.not_filled_out_font_html(valueMaskPlainText, false);
            score0to100 = 0;
            isFilledOutTF = false;
          }
        }
        else {
          valueRaw = -1; //question has never been answered before with no record in the data select table
          valueMaskPlainText = "--Not Yet Answered--";
          valueMask = DatabaseMobx.not_filled_out_font_html(valueMaskPlainText, false);
          score0to100 = 0;
          isFilledOutTF = false;
        }
      }
    }
    else if(i_answerType123 === 2 || i_answerType123 === 3) { //text/textarea question
      //determine if this scored text question has been answered yet and get the masked/sort/isFilledOut values from the answer rawValue
      if(i_changedQuestionID !== undefined && i_changedQuestionID === i_questionPoolID) { //overwrite the existing textarea database answer with a new answer for this question
        valueRaw = i_changedQuestionNewAnswer;
        valueMaskPlainText = i_changedQuestionNewAnswer.textarea;
        valueMask = valueMaskPlainText;
        score0to100 = i_changedQuestionNewAnswer.score0to100;
        isFilledOutTF = true;
      }
      else {
        const matchIndex = this.c_shapingAnswersTextareaOnlyForOpenCaptureObjOfArrays.questionIDsArray.indexOf(i_questionPoolID);
        if(matchIndex >= 0) { //answer record exists, has been answered at least once
          var answerText = this.c_shapingAnswersTextareaOnlyForOpenCaptureObjOfArrays.textAnswersArray[matchIndex];
          var answerScore = this.c_shapingAnswersTextareaOnlyForOpenCaptureObjOfArrays.answerScoresArray[matchIndex];
          valueRaw = {textarea: answerText, score0to100: answerScore};
          if(i_activeQuestionTrueClosedDebriefQuestionFalse) { //include percent score in masked answer for text/textarea if this is an acive stage deal shaping question with scoring, otherwise do not show for closed debrief questions (scoring slider mechanism is hidden for debrief questions)
            isFilledOutTF = JSFUNC.scored_textarea_obj_is_filled_out_tf(valueRaw);
            valueMaskPlainText = "[" + answerScore + "%] " + answerText;
            valueMask = valueMaskPlainText;
            score0to100 = answerScore; //set the score0to100 to the textarea answer's self given score
          }
          else { //debrief text/textarea question answer, just show text without hidden score
            isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(answerText);
            valueMaskPlainText = answerText;
            valueMask = valueMaskPlainText;
            score0to100 = ((isFilledOutTF) ? (100) : (0)); //100% if any text is filled out, 0% if empty
          }

          if(!isFilledOutTF) {
            valueMaskPlainText = "--No Answer Entered--";
            valueMask = DatabaseMobx.not_filled_out_font_html(valueMaskPlainText, false);
          }
        }
        else {
          valueRaw = {textarea:"", score0to100:0}; //default textarea values when answering a question that has never been answered before
          valueMaskPlainText = "--Not Yet Answered--";
          valueMask = DatabaseMobx.not_filled_out_font_html(valueMaskPlainText, false);
          score0to100 = 0;
          isFilledOutTF = false;
        }
      }
    }

    return({
      valueRaw: valueRaw,
      valueMaskPlainText: valueMaskPlainText,
      valueMask: valueMask,
      isFilledOutTF: isFilledOutTF,
      score0to100: score0to100
    });
  }

  get c_shapingAnswersSelectOnlyForOpenCaptureObjOfArrays() {
    //filter the data for all select questions to be only the data for this open capture (greatly reduces the search time in the loop above to find answers to each questions on the deal shaping card)
    var questionIDsArray = [];
    var selectAnswerIDsArray = []; //data per captureID per questionID of which answerID has been selected for this select type question
    if(this.o_openCaptureID > 0) { //if any capture is currently open
      for(let shapingAnswerSelectMap of DatabaseMobx.o_tbl_c_shaping_answers_select.values()) {
        if(shapingAnswerSelectMap.get("capture_id") === this.o_openCaptureID) { //only allow answer data for the currently open capture
          questionIDsArray.push(shapingAnswerSelectMap.get("question_id"));
          selectAnswerIDsArray.push(shapingAnswerSelectMap.get("answer_id"));
        }
      }
    }

    return({
      questionIDsArray: questionIDsArray,
      selectAnswerIDsArray: selectAnswerIDsArray
    });
  }

  get c_shapingAnswersTextareaOnlyForOpenCaptureObjOfArrays() {
    //filter the data for all text/textarea scored questions to be only the data for this open capture (greatly reduces the search time in the loop above to find answers to each questions on the deal shaping card)
    var questionIDsArray = [];
    var textAnswersArray = [];
    var answerScoresArray = [];
    if(this.o_openCaptureID > 0) { //if any capture is currently open
      for(let shapingAnswerTextareaMap of DatabaseMobx.o_tbl_c_shaping_answers_textarea.values()) {
        if(shapingAnswerTextareaMap.get("capture_id") === this.o_openCaptureID) { //only allow answer data for the currently open capture
          questionIDsArray.push(shapingAnswerTextareaMap.get("question_id"));
          textAnswersArray.push(shapingAnswerTextareaMap.get("answer_text"));
          answerScoresArray.push(shapingAnswerTextareaMap.get("score0to100"));
        }
      }
    }

    return({
      questionIDsArray: questionIDsArray,
      textAnswersArray: textAnswersArray,
      answerScoresArray: answerScoresArray
    });
  }





  //Revenue card
  get c_revenueCostCaptureFieldValuesArrayOfObjs() {
    return(this.details_group_capture_field_value_arrayOfObjs_from_group_id(-1, false, false));
  }

  get c_revenueCardAddedFieldIDsArray() { //array of each field_id for each date on the dates card (used for AdvanceStage to check if a Details field has already been seen on the Dates card)
    var revenueCardAddedFieldIDsArray = [];
    for(let revenueCostCaptureFieldValueObj of this.c_revenueCostCaptureFieldValuesArrayOfObjs) {
      revenueCardAddedFieldIDsArray.push(revenueCostCaptureFieldValueObj.expandedCaptureFieldMap.get("id"));
    }
    return(revenueCardAddedFieldIDsArray);
  }

  get c_revenuePopFyrTableArrayOfObjs() {
    const contractStartDate = this.c_contractStartDateCaptureFieldValueObj.valueRaw;
    const contractEndDate = this.c_contractEndDateCaptureFieldValueObj.valueRaw;
    const revenuePerMonth = this.c_allocatedRevenuePerMonthCaptureFieldValueObj.valueRaw;
    const netPerMonth = this.c_allocatedNetPerMonthCaptureFieldValueObj.valueRaw;

    const csdValidTF = JSFUNC.date_is_filled_out_tf(contractStartDate);
    const cedValidTF = JSFUNC.date_is_filled_out_tf(contractEndDate);

    var revenuePopFyrTableArrayOfObjs = [];
    if(csdValidTF && csdValidTF && (contractEndDate > contractStartDate)) {
      const csdFyrObj = DatabaseMobx.convert_date_Ymd_to_fyrYear_fyrMonth0to11_obj(contractStartDate);
      const cedFyrObj = DatabaseMobx.convert_date_Ymd_to_fyrYear_fyrMonth0to11_obj(contractEndDate);

      if(csdFyrObj.fyrYear >= cedFyrObj.fyrYear) { //start/end dates are both within the same fyr, return a single fyr entry with the number of months times the monthly revenue
        const numMonths = (cedFyrObj.fyrMonth0to11 - csdFyrObj.fyrMonth0to11);
        revenuePopFyrTableArrayOfObjs.push({
          fyr: csdFyrObj.fyrYear,
          revenueMask: JSFUNC.money_short(numMonths * revenuePerMonth),
          netMask: JSFUNC.money_short(numMonths * netPerMonth)
        });
      }
      else {
        var fyr = csdFyrObj.fyrYear;
        while(fyr <= cedFyrObj.fyrYear) {
          var numMonths = 12;
          if(fyr === csdFyrObj.fyrYear) {
            numMonths = (12 - csdFyrObj.fyrMonth0to11);
          }
          else if(fyr === cedFyrObj.fyrYear) {
            var csdDay = JSFUNC.direct_get_dd_01to31_string_from_Ymd_date(contractStartDate);
            if(csdDay === "01") {
              numMonths = (cedFyrObj.fyrMonth0to11 + 1);
            }
            else {
              numMonths = cedFyrObj.fyrMonth0to11;
            }
          }

          revenuePopFyrTableArrayOfObjs.push({
            fyr: fyr,
            revenueMask: JSFUNC.money_short(numMonths * revenuePerMonth),
            netMask: JSFUNC.money_short(numMonths * netPerMonth)
          });

          fyr++;
        }
      }
    }
    return(revenuePopFyrTableArrayOfObjs);
  }







  //Advance Stage card
  get c_advanceStageRequiredAndNotFilledOutDetailsFieldsArrayOfObjs() {
    var requiredAndNotFilledOutDetailsFieldsArrayOfObjs = [];
    for(let groupArrayOfObjs of this.c_detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs) {
      for(let detailsCaptureFieldValueObj of groupArrayOfObjs) {
        if(detailsCaptureFieldValueObj.required3TF && !detailsCaptureFieldValueObj.isFilledOutTF) {
          var fieldID = detailsCaptureFieldValueObj.expandedCaptureFieldMap.get("id");
          if(!JSFUNC.in_array(fieldID, this.c_datesCardAddedFieldIDsArray) && !JSFUNC.in_array(fieldID, this.c_revenueCardAddedFieldIDsArray)) { //if a date field appears on the details card and on the dates card, the dates card takes precedence to report the field and not filled out
            requiredAndNotFilledOutDetailsFieldsArrayOfObjs.push(detailsCaptureFieldValueObj);
          }
        }
      }
    }
    return(requiredAndNotFilledOutDetailsFieldsArrayOfObjs);
  }

  get c_advanceStageRequiredAndNotFilledOutDatesFieldsArrayOfObjs() {
    var requiredAndNotFilledOutDatesFieldsArrayOfObjs = [];
    for(let dateCaptureFieldValueObj of this.c_datesArrayOfObjs) {
      if(dateCaptureFieldValueObj.required3TF && !dateCaptureFieldValueObj.isFilledOutTF) {
        requiredAndNotFilledOutDatesFieldsArrayOfObjs.push(dateCaptureFieldValueObj);
      }
    }
    return(requiredAndNotFilledOutDatesFieldsArrayOfObjs);
  }

  get c_advanceStageRequiredAndNotFilledOutRevenueCostFieldsArrayOfObjs() {
    var requiredAndNotFilledOutDatesFieldsArrayOfObjs = [];
    for(let revenueCostCaptureFieldValueObj of this.c_revenueCostCaptureFieldValuesArrayOfObjs) {
      if(revenueCostCaptureFieldValueObj.required3TF && !revenueCostCaptureFieldValueObj.isFilledOutTF) {
        requiredAndNotFilledOutDatesFieldsArrayOfObjs.push(revenueCostCaptureFieldValueObj);
      }
    }
    return(requiredAndNotFilledOutDatesFieldsArrayOfObjs);
  }

  get c_advanceStageRequiredAndUnansweredShapingQuestionAnswersArrayOfObjs() { //required, unanswered questions in active stages only
    var requiredAndUnansweredQuestionsArrayOfObjs = [];
    for(let questionAnswerObj of this.c_captureTypeActiveShapingCombinedQuestionsAnswersArrayOfObjs) {
      if((questionAnswerObj.rec1_reqsig2_req3 === 3) && !questionAnswerObj.answerIsFilledOutTF && JSFUNC.in_array(questionAnswerObj.stage_id_relevant, this.c_captureTypeActiveStageIDsArray)) {
        requiredAndUnansweredQuestionsArrayOfObjs.push(questionAnswerObj);
      }
    }
    return(requiredAndUnansweredQuestionsArrayOfObjs);
  }

  get c_advanceStageCurrentStageExistsTF() {
    return(JSFUNC.in_array(this.c_captureStageID, this.c_captureTypeStageIDsArray));
  }

  get c_advanceStageTimelineDataObj() {
    var activeStagesArrayOfObjs = [];

    var hasSeenCurrentStageTF = false; //stages are "past" until the current stage is found, which is labeled "current", all future stages are either "futureOpen" or "futureLocked" depending on whether the previous stage met all of its requirements
    var anUnansweredRequirementWasFoundTF = false; //once an unanswered requirement is found for a stages, all future stages cannot be accessed until that requirement is resolved

    var stageReqDetails = []; //these unanswered requirements accumulate through each stage, so that if a capture is in a late stage, and a new early req was just made by the admin, it shows up blocking progress for the much later current stage
    var stageReqDates = [];
    var stageReqRevenue = [];
    var stageReqDetailsDatesRevenueHidden = [];
    var stageReqQuestions = [];

    //add a stage that does not exist at the beginning of the timeline if the current stage is not one of the stages within this capture type
    if(this.c_captureTypeStagesArrayOfObjs.length === 0) {
      activeStagesArrayOfObjs.push({
        id: -1,
        name: "--Capture Type '" + this.c_captureTypeMap.get("name") + "' Does Not Have Any Stages--",
        isActiveStageTF: false,
        pastCurrentFutureFlag: "current",
        reqDetailsFieldsArrayOfObjs: [],
        reqDatesFieldsArrayOfObjs: [],
        reqRevenueFieldsArrayOfObjs: [],
        reqDetailsDatesRevenueHiddenFieldsArrayOfObjs: [],
        reqShapingQuestionsArrayOfObjs: []
      });

      hasSeenCurrentStageTF = true;
    }
    else if(!this.c_advanceStageCurrentStageExistsTF) {
      activeStagesArrayOfObjs.push({
        id: -1,
        name: "--Stage Does Not Exist (ID: " + this.c_captureStageID + ")--",
        isActiveStageTF: false,
        pastCurrentFutureFlag: "current",
        reqDetailsFieldsArrayOfObjs: [],
        reqDatesFieldsArrayOfObjs: [],
        reqRevenueFieldsArrayOfObjs: [],
        reqDetailsDatesRevenueHiddenFieldsArrayOfObjs: [],
        reqShapingQuestionsArrayOfObjs: []
      });

      hasSeenCurrentStageTF = true;
    }

    var prevStageNumReqDetails = 0;
    var prevStageNumReqDates = 0;
    var prevStageNumReqRevenue = 0;
    var prevStageNumReqDetailsDatesRevenue = 0;
    var prevStageNumReqQuestions = 0;

    for(let activeStageObj of this.c_activeStagesArrayOfObjs) {
      var stageObj = JSFUNC.copy_obj(activeStageObj);

      //this loop is all active stages in the timeline
      stageObj.isActiveStageTF = true;

      //calculate this stage's unanswered requirements, count number of unanswered requirements for the next stage to have as can advance criteria
      for(let detailsCaptureFieldValueObj of this.c_advanceStageRequiredAndNotFilledOutDetailsFieldsArrayOfObjs) { //find any not filled out required details fields for this stage
        if(detailsCaptureFieldValueObj.stage_id_relevant === stageObj.id) {
          var reqDetailsFieldObj = {
            cardID: DatabaseMobx.k_cardIDDetails,
            itemID: detailsCaptureFieldValueObj.expandedCaptureFieldMap.get("id"),
            itemName: detailsCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")
          };

          if(detailsCaptureFieldValueObj.fieldIsVisibleToUserTF) {
            stageReqDetails.push(reqDetailsFieldObj);
          }
          else {
            stageReqDetailsDatesRevenueHidden.push(reqDetailsFieldObj);
          }
        }
      }

      for(let datesCaptureFieldValueObj of this.c_advanceStageRequiredAndNotFilledOutDatesFieldsArrayOfObjs) { //find any not filled out required dates fields for this stage
        if(datesCaptureFieldValueObj.stage_id_relevant === stageObj.id) {
          var reqDatesFieldObj = {
            cardID: DatabaseMobx.k_cardIDDates,
            itemID: datesCaptureFieldValueObj.expandedCaptureFieldMap.get("id"),
            itemName: datesCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")
          };

          if(datesCaptureFieldValueObj.fieldIsVisibleToUserTF) {
            stageReqDates.push(reqDatesFieldObj);
          }
          else {
            stageReqDetailsDatesRevenueHidden.push(reqDatesFieldObj);
          }
        }
      }

      for(let revenueCostCaptureFieldValueObj of this.c_advanceStageRequiredAndNotFilledOutRevenueCostFieldsArrayOfObjs) { //find any not filled out required revenue costs fields for this stage
        if(revenueCostCaptureFieldValueObj.stage_id_relevant === stageObj.id) {
          var reqRevenueFieldObj = {
            cardID: DatabaseMobx.k_cardIDRevenue,
            itemID: revenueCostCaptureFieldValueObj.expandedCaptureFieldMap.get("id"),
            itemName: revenueCostCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")
          };

          if(revenueCostCaptureFieldValueObj.fieldIsVisibleToUserTF) {
            stageReqRevenue.push(reqRevenueFieldObj);
          }
          else {
            stageReqDetailsDatesRevenueHidden.push(reqRevenueFieldObj);
          }
        }
      }

      for(let questionAnswerObj of this.c_advanceStageRequiredAndUnansweredShapingQuestionAnswersArrayOfObjs) { //find any unanswered required deal shaping questions in this stage
        if(questionAnswerObj.stage_id_relevant === stageObj.id) {
          stageReqQuestions.push({
            cardID: DatabaseMobx.k_cardIDDealShaping,
            itemID: questionAnswerObj.id,
            itemName: questionAnswerObj.name
          });
        }
      }

      //set the previous stage's requirements for this stage
      stageObj.reqDetailsFieldsArrayOfObjs = JSFUNC.copy_array(stageReqDetails);
      stageObj.reqDatesFieldsArrayOfObjs = JSFUNC.copy_array(stageReqDates);
      stageObj.reqRevenueFieldsArrayOfObjs = JSFUNC.copy_array(stageReqRevenue);
      stageObj.reqDetailsDatesRevenueHiddenFieldsArrayOfObjs = JSFUNC.copy_array(stageReqDetailsDatesRevenueHidden);
      stageObj.reqShapingQuestionsArrayOfObjs = JSFUNC.copy_array(stageReqQuestions);

      //determine if this stage can be advanced to based on the unanswered requirements of past stages
      if(stageObj.id === this.c_captureStageID) { //this is the current stage
        stageObj.pastCurrentFutureFlag = "current";
        hasSeenCurrentStageTF = true;
      }
      else if(!hasSeenCurrentStageTF) {
        stageObj.pastCurrentFutureFlag = "past";
      }
      else {
        if((prevStageNumReqDetails === 0) && (prevStageNumReqDates === 0) && (prevStageNumReqRevenue === 0) && (prevStageNumReqDetailsDatesRevenue === 0) && (prevStageNumReqQuestions === 0) && !anUnansweredRequirementWasFoundTF) {
          stageObj.pastCurrentFutureFlag = "futureOpen";
        }
        else {
          stageObj.pastCurrentFutureFlag = "futureLocked";
          anUnansweredRequirementWasFoundTF = true;
        }
      }

      //add this stage to the timeline
      activeStagesArrayOfObjs.push(stageObj);

      //count the number of unanswered requirements and carry them over for the next stage
      prevStageNumReqDetails = stageObj.reqDetailsFieldsArrayOfObjs.length;
      prevStageNumReqDates = stageObj.reqDatesFieldsArrayOfObjs.length;
      prevStageNumReqRevenue = stageObj.reqRevenueFieldsArrayOfObjs.length;
      prevStageNumReqDetailsDatesRevenue = stageObj.reqDetailsDatesRevenueHiddenFieldsArrayOfObjs.length;
      prevStageNumReqQuestions = stageObj.reqShapingQuestionsArrayOfObjs.length;
    }

    //closed stages objs
    const canAdvanceToWonLostStagesTF = ((prevStageNumReqDetails === 0) && (prevStageNumReqDates === 0) && (prevStageNumReqRevenue === 0) && (prevStageNumReqDetailsDatesRevenue === 0) && (prevStageNumReqQuestions === 0) && !anUnansweredRequirementWasFoundTF);

    return({
      activeStagesArrayOfObjs: activeStagesArrayOfObjs,
      wonStagesArrayOfObjs: this.create_closed_stages_array_of_objs(this.c_closedWonStagesArrayOfObjs, canAdvanceToWonLostStagesTF),
      lostStagesArrayOfObjs: this.create_closed_stages_array_of_objs(this.c_closedLostStagesArrayOfObjs, canAdvanceToWonLostStagesTF),
      noBidStagesArrayOfObjs: this.create_closed_stages_array_of_objs(this.c_closedNoBidStagesArrayOfObjs, true),
      cancelledStagesArrayOfObjs: this.create_closed_stages_array_of_objs(this.c_closedCancelledStagesArrayOfObjs, true),
      canAdvanceToWonLostStagesTF: canAdvanceToWonLostStagesTF
    });
  }

  create_closed_stages_array_of_objs(i_closedStagesArrayOfObjs, i_canAdvanceToStageTypeTF) {
    var expandedClosedStagesArrayOfObjs = []; //closed stage objs with more data in them for advance stage debrief system
    for(let stageObj of i_closedStagesArrayOfObjs) {
      var expandedClosedStageObj = JSFUNC.copy_obj(stageObj);

      //closed stage
      expandedClosedStageObj.isActiveStageTF = false;
      expandedClosedStageObj.isWonOrLostStageTF = JSFUNC.in_array(stageObj.id, this.c_captureTypeClosedWonLostStageIDsArray);

      //current or future stage
      var closedStagePastCurrentFutureFlag = "futureOpen";
      if(expandedClosedStageObj.id === this.c_captureStageID) {
        closedStagePastCurrentFutureFlag = "current";
      }
      else if(!i_canAdvanceToStageTypeTF) {
        closedStagePastCurrentFutureFlag = "futureLocked";
      }
      expandedClosedStageObj.pastCurrentFutureFlag = closedStagePastCurrentFutureFlag;

      //find any debrief questions in this closed stage
      var stageReqQuestions = [];
      var numUnansweredDebriefQuestionsInCurrentStage = 0;
      for(let questionAnswerObj of this.c_captureTypeDebriefCombinedQuestionsAnswersArrayOfObjs) {
        if(stageObj.id === questionAnswerObj.closed_stage_id) {
          stageReqQuestions.push(questionAnswerObj); //closed stages take the entire qustionAnswerObj and store it rather than a condensed requiredObj used in the active stages to reference fields on other cards
          if((this.c_captureStageID === stageObj.id) && !questionAnswerObj.answerIsFilledOutTF) {
            numUnansweredDebriefQuestionsInCurrentStage++;
          }
        }
      }
      expandedClosedStageObj.reqShapingQuestionsArrayOfObjs = stageReqQuestions;
      expandedClosedStageObj.numUnansweredDebriefQuestionsInCurrentStage = numUnansweredDebriefQuestionsInCurrentStage;

      expandedClosedStagesArrayOfObjs.push(expandedClosedStageObj);
    }
    return(expandedClosedStagesArrayOfObjs);
  }

  get c_advanceStageCurrentStageNextStageObj() {
    const activeStagesArrayOfObjs = this.c_advanceStageTimelineDataObj.activeStagesArrayOfObjs;
    const numStages = activeStagesArrayOfObjs.length;

    //current stage does not exist
    if(!this.c_advanceStageCurrentStageExistsTF && numStages > 0) {
      return({
        currentStageObj: activeStagesArrayOfObjs[0],
        nextStageObj: undefined
      });
    }

    //loop through all active stages to figure out the current stage
    for(let s = 0; s < numStages; s++) {
      var activeStageObj = activeStagesArrayOfObjs[s];
      if(this.c_captureStageID === activeStageObj.id) {
        var nextStageObj = undefined; //returns undefined if the next stage is either closed or if the current stage is the very last stage
        if(s < (numStages - 1)) { //current stage is not the last stage in the array
          nextStageObj = activeStagesArrayOfObjs[s + 1]; //next stage is the next active stage
        }
        else { //current stage is the last active stage
          nextStageObj = {id:-2, name:"Close the Deal", pastCurrentFutureFlag:((this.c_advanceStageTimelineDataObj.canAdvanceToWonLostStagesTF) ? ("futureOpen") : ("futureLocked"))}; //next stage is any of the won/lost closed stages
        }

        return({
          currentStageObj: activeStageObj,
          nextStageObj: nextStageObj
        });
      }
    }

    //current stage is not an active stage, loop through all closed stages to find the current stage
    const allClosedStagesArrayOfArrayOfObjs = [this.c_advanceStageTimelineDataObj.wonStagesArrayOfObjs, this.c_advanceStageTimelineDataObj.lostStagesArrayOfObjs, this.c_advanceStageTimelineDataObj.noBidStagesArrayOfObjs, this.c_advanceStageTimelineDataObj.cancelledStagesArrayOfObjs]
    for(let closedStagesArrayOfObjs of allClosedStagesArrayOfArrayOfObjs) {
      for(let closedStageObj of closedStagesArrayOfObjs) {
        if(this.c_captureStageID === closedStageObj.id) {
          return({
            currentStageObj: closedStageObj,
            nextStageObj: undefined
          });
        }
      }
    }

    //current stage could not be found within stages for this capture type, return a does not exist stage with no next stage
    return({
      currentStageObj: {id:this.c_captureStageID, name:"--Stage Does Not Exist (ID: " + this.c_captureStageID + ")--", pastCurrentFutureFlag:"current"},
      nextStageObj: undefined
    });
  }

  get c_advanceStageCurrentStageIsActiveTFU() {
    if(this.c_advanceStageCurrentStageExistsTF) { //true for active stage, false for closed stage
      return(this.c_advanceStageCurrentStageNextStageObj.currentStageObj.isActiveStageTF);
    }
    return(undefined); //undefined for stage that does not exist (technically placed in active timeline before all active stages)
  }

  get c_advanceStageClosedStageTeammateRatingsTeammatesObj() {
    const teammatesSelectedTeammatesArrayOfObjs = this.c_teammatesSelectedTeammatesArrayOfObjs;
    const teammatesNotSelectedTeammatesArrayOfObjs = this.c_teammatesNotSelectedTeammatesArrayOfObjs;
    const teammatesRatingsQuestionsArrayOfObjs = DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    const ascstrSelectedTeammatesArrayOfObjs = this.compute_advance_stage_closed_stage_teammate_ratings_teammates_arrayOfObjs_from_teammates_arrayOfObjs(teammatesSelectedTeammatesArrayOfObjs);
    const ascstrNotSelectedTeammatesArrayOfObjs = this.compute_advance_stage_closed_stage_teammate_ratings_teammates_arrayOfObjs_from_teammates_arrayOfObjs(teammatesNotSelectedTeammatesArrayOfObjs);
    const numSelectedTeammates = ascstrSelectedTeammatesArrayOfObjs.length;
    const numNotSelectedTeammates = ascstrNotSelectedTeammatesArrayOfObjs.length;
    const numTeammates = (numSelectedTeammates + numNotSelectedTeammates);

    const numTeammatesRatingsQuestions = teammatesRatingsQuestionsArrayOfObjs.length;

    const numSelectedTeammatesNotYetReviewed = JSFUNC.count_num_filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(ascstrSelectedTeammatesArrayOfObjs, "reviewedTF", false);

    return({
      ascstrSelectedTeammatesArrayOfObjs: ascstrSelectedTeammatesArrayOfObjs,
      ascstrNotSelectedTeammatesArrayOfObjs: ascstrNotSelectedTeammatesArrayOfObjs,
      numTeammates: numTeammates,
      numSelectedTeammates: numSelectedTeammates,
      numNotSelectedTeammates: numNotSelectedTeammates,
      numTeammatesRatingsQuestions: numTeammatesRatingsQuestions,
      numSelectedTeammatesNotYetReviewed: numSelectedTeammatesNotYetReviewed
    });
  }

  compute_advance_stage_closed_stage_teammate_ratings_teammates_arrayOfObjs_from_teammates_arrayOfObjs(i_teammatesArrayOfObjs) {
    const openCaptureID = this.o_openCaptureID;

    var ascstrteammatesArrayOfObjs = [];
    for(let teammateObj of i_teammatesArrayOfObjs) {
      var reviewedTF = false;
      var tblCTeammatesRatingsQuestionnaireSubmissionObj = undefined;
      var tblCTeammatesRatingsQuestionnaireSubmissionMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_c_teammates_ratings_questionnaire_submissions, ["teammate_id", "capture_id"], [teammateObj.id, openCaptureID]);
      if(tblCTeammatesRatingsQuestionnaireSubmissionMap !== undefined) {
        reviewedTF = true;
        tblCTeammatesRatingsQuestionnaireSubmissionObj = JSFUNC.obj_from_map(tblCTeammatesRatingsQuestionnaireSubmissionMap);
      }
      var teammateOverallCaptureRating = this.compute_teammate_overall_capture_rating_from_single_questionnaire_submission_row_obj(tblCTeammatesRatingsQuestionnaireSubmissionObj);

      ascstrteammatesArrayOfObjs.push({
        teammateObj: teammateObj,
        reviewedTF: reviewedTF,
        tblCTeammatesRatingsQuestionnaireSubmissionObj: tblCTeammatesRatingsQuestionnaireSubmissionObj,
        teammateOverallCaptureRating: teammateOverallCaptureRating
      });
    }
    return(ascstrteammatesArrayOfObjs);
  }






  //Tasks card
  get c_allCaptureTasksArrayOfObjs() {
    const allCaptureTasksMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_u_tasks, "capture_id", this.o_openCaptureID);
    var allCaptureTasksArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(allCaptureTasksMapOfMaps);
    var allCaptureExpandedTasksArrayOfObjs = [];
    for(let taskObj of allCaptureTasksArrayOfObjs) {
      var expandedTaskObj = RightPanelMobx.tasks_compute_extra_fields_for_task_obj(taskObj);
      allCaptureExpandedTasksArrayOfObjs.push(expandedTaskObj);
    }
    return(allCaptureExpandedTasksArrayOfObjs);
  }

  get c_myActiveCaptureTasksArrayOfObjs() { //sort the tasks ASC so that the date closest to today is on top, then going into the future
    const assignerIsMeTFU = undefined, assignedToMeTFU = true, dueDateSetTFU = undefined, completedTFU = false, sortField = "date_due", sortIsAscTF = true;
    return(RightPanelMobx.filter_tasks(this.c_allCaptureTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }
  get c_activeCaptureTasksIAssignedToOthersArrayOfObjs() {
    const assignerIsMeTFU = true, assignedToMeTFU = false, dueDateSetTFU = undefined, completedTFU = false, sortField = "date_due", sortIsAscTF = true;
    return(RightPanelMobx.filter_tasks(this.c_allCaptureTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }
  get c_activeCaptureTasksOthersAssignedToOthersArrayOfObjs() {
    const assignerIsMeTFU = false, assignedToMeTFU = false, dueDateSetTFU = undefined, completedTFU = false, sortField = "date_due", sortIsAscTF = true;
    return(RightPanelMobx.filter_tasks(this.c_allCaptureTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }

  get c_myCompletedCaptureTasksArrayOfObjs() {
    const assignerIsMeTFU = undefined, assignedToMeTFU = true, dueDateSetTFU = undefined, completedTFU = true, sortField = "date_due", sortIsAscTF = false;
    return(RightPanelMobx.filter_tasks(this.c_allCaptureTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }
  get c_completedCaptureTasksIAssignedToOthersArrayOfObjs() {
    const assignerIsMeTFU = true, assignedToMeTFU = false, dueDateSetTFU = undefined, completedTFU = true, sortField = "date_due", sortIsAscTF = false;
    return(RightPanelMobx.filter_tasks(this.c_allCaptureTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }
  get c_completedCaptureTasksOthersAssignedToOthersArrayOfObjs() {
    const assignerIsMeTFU = false, assignedToMeTFU = false, dueDateSetTFU = undefined, completedTFU = true, sortField = "date_due", sortIsAscTF = false;
    return(RightPanelMobx.filter_tasks(this.c_allCaptureTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }

  get c_tasksNumMyActive() { return(this.c_myActiveCaptureTasksArrayOfObjs.length); }
  get c_tasksNumActiveToOthers() { return(this.c_activeCaptureTasksIAssignedToOthersArrayOfObjs.length); }
  get c_tasksNumActiveOtherToOther() { return(this.c_activeCaptureTasksOthersAssignedToOthersArrayOfObjs.length); }
  get c_tasksNumMyCompleted() { return(this.c_myCompletedCaptureTasksArrayOfObjs.length); }
  get c_tasksNumCompletedToOthers() { return(this.c_completedCaptureTasksIAssignedToOthersArrayOfObjs.length); }
  get c_tasksNumCompletedOtherToOther() { return(this.c_completedCaptureTasksOthersAssignedToOthersArrayOfObjs.length); }
  get c_tasksNumActive() { return(this.c_tasksNumMyActive + this.c_tasksNumActiveToOthers + this.c_tasksNumActiveOtherToOther); }
  get c_tasksNumCompleted() { return(this.c_tasksNumMyCompleted + this.c_tasksNumCompletedToOthers + this.c_tasksNumCompletedOtherToOther); }







  //Dates card
  get c_datesArrayOfObjs() {
    return(this.details_group_capture_field_value_arrayOfObjs_from_group_id(0, false, true)); //all dates fields are stored in special "group_id 0" (which is actually the separate Dates card) from groups 1-N on the details card, recast the fieldInputType as "date_dayMdyDaysUntil1" so that this format is displayed in the card
  }

  get c_datesCardTotalNumDates() {
    return(this.c_datesArrayOfObjs.length);
  }

  get c_datesCardAddedFieldIDsArray() { //array of each field_id for each date on the dates card (used for AdvanceStage to check if a Details field has already been seen on the Dates card)
    var datesCardAddedFieldIDsArray = [];
    for(let dateObj of this.c_datesArrayOfObjs) {
      datesCardAddedFieldIDsArray.push(dateObj.expandedCaptureFieldMap.get("id"));
    }
    return(datesCardAddedFieldIDsArray);
  }

  get c_upcomingDatesArrayOfObjs() { //includes today and future
    var upcomingDatesArrayOfObjs = [];
    for(let dateObj of this.c_datesArrayOfObjs) {
      if(dateObj.isFilledOutTF && dateObj.isUpcomingTF) { //set and upcoming
        upcomingDatesArrayOfObjs.push(dateObj);
      }
    }
    JSFUNC.sort_arrayOfObjs(upcomingDatesArrayOfObjs, "valueRaw", true); //sort the dates ASC so that the date closest to today is on top, then going into the future
    return(upcomingDatesArrayOfObjs);
  }

  get c_notsetDatesArrayOfObjs() { //includes yesterday and previous
    var notsetDatesArrayOfObjs = [];
    for(let dateObj of this.c_datesArrayOfObjs) {
      if(!dateObj.isFilledOutTF) { //not set
        var dateObjWithAdminSort = JSFUNC.copy_obj(dateObj);
        dateObjWithAdminSort.adminSort = dateObjWithAdminSort.expandedCaptureFieldMap.get("sort");
        notsetDatesArrayOfObjs.push(dateObjWithAdminSort);
      }
    }
    JSFUNC.sort_arrayOfObjs(notsetDatesArrayOfObjs, "adminSort", true); //sort the not set dates by the admin capture type sort
    return(notsetDatesArrayOfObjs);
  }

  get c_pastDatesArrayOfObjs() { //includes yesterday and previous
    var pastDatesArrayOfObjs = [];
    for(let dateObj of this.c_datesArrayOfObjs) {
      if(dateObj.isFilledOutTF && !dateObj.isUpcomingTF) { //set and not upcoming
        pastDatesArrayOfObjs.push(dateObj);
      }
    }
    JSFUNC.sort_arrayOfObjs(pastDatesArrayOfObjs, "valueRaw", false); //sort the dates DESC so that the date closest to today is on top, then going into the past
    return(pastDatesArrayOfObjs);
  }

  get c_allDatesFixedArrayOfObjs() {
    var allDatesFixedArrayOfObjs = [];
    for(let dateObj of this.c_datesArrayOfObjs) {
      var dateObjWithAdminSort = JSFUNC.copy_obj(dateObj);
      dateObjWithAdminSort.adminSort = dateObjWithAdminSort.expandedCaptureFieldMap.get("sort");
      allDatesFixedArrayOfObjs.push(dateObjWithAdminSort);
    }
    JSFUNC.sort_arrayOfObjs(allDatesFixedArrayOfObjs, "adminSort", true);
    return(allDatesFixedArrayOfObjs);
  }






  //Details card
  get c_detailsSnapshotCaptureFieldValuesArrayOfObjs() {
    const detailsSnapshotFieldIDsComma = this.c_captureTypeMap.get("details_snapshot_field_ids_comma"); //already sorted in order based on order of fieldID commas in database
    const detailsSnapshotFieldIDsArray = JSFUNC.convert_comma_list_to_int_array(detailsSnapshotFieldIDsComma);
    var detailsSnapshotCaptureValuesArrayOfObjs = [];
    for(let fieldID of detailsSnapshotFieldIDsArray) {
      var captureFieldValueObj = this.details_group_capture_field_value_obj_from_field_id(fieldID, false);
      if(captureFieldValueObj.fieldIsVisibleToUserTF) {
        detailsSnapshotCaptureValuesArrayOfObjs.push(captureFieldValueObj);
      }
    }
    return(detailsSnapshotCaptureValuesArrayOfObjs);
  }

  get c_detailsGroupsArrayOfObjs() {
    const detailsGroupIDsComma = this.c_captureTypeMap.get("details_group_ids_comma"); //already sorted in order based on order of groupID commas in database
    const detailsGroupsMapOfMaps = JSFUNC.filtered_mapOfMaps_from_id_array_or_comma(DatabaseMobx.o_tbl_a_details_groups_pool, detailsGroupIDsComma, "Details Group");
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(detailsGroupsMapOfMaps));
  }

  get c_detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs() {
    var detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs = [];
    for(let groupObj of this.c_detailsGroupsArrayOfObjs) {
      const groupCaptureFieldValueArrayOfObjs = this.details_group_capture_field_value_arrayOfObjs_from_group_id(groupObj.id, true, false);
      detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs.push(groupCaptureFieldValueArrayOfObjs);
    }
    return(detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs);
  }

  details_group_capture_field_value_arrayOfObjs_from_group_id(i_groupID, i_includeFieldsNotVisibleToUserTF, i_convertDateFieldTypesToDayMdyDaysUntilTF) { //combine the properties of the capture field and capture values (captureValuesMap) together with properties from the details group (stage_id_relevant, rec1_reqsig2_req3) in the order of group "sort"
    const c_captureTypeID = this.c_captureTypeID;
    const c_tbl_a_capture_types_details_fields = DatabaseMobx.c_tbl_a_capture_types_details_fields;

    const groupExpandedCaptureTypeDetailsFieldsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "group_id"], [c_captureTypeID, i_groupID], "sort", true); //get the fields assigned to this group, sort the fields by the "sort" field within each group
    
    var groupCaptureFieldValueArrayOfObjs = [];
    for(let groupExpandedCaptureTypeDetailsFieldObj of groupExpandedCaptureTypeDetailsFieldsArrayOfObjs) {
      var captureFieldValueObj = this.details_group_capture_field_value_obj_from_capture_types_details_fields_row_obj(groupExpandedCaptureTypeDetailsFieldObj, i_convertDateFieldTypesToDayMdyDaysUntilTF);
      if(i_includeFieldsNotVisibleToUserTF || captureFieldValueObj.fieldIsVisibleToUserTF) {
        groupCaptureFieldValueArrayOfObjs.push(captureFieldValueObj);
      }
    }
    return(groupCaptureFieldValueArrayOfObjs);
  }

  details_group_capture_field_value_obj_from_field_id(i_fieldID, i_convertDateFieldTypesToDayMdyDaysUntilTF) {
    const c_captureTypeID = this.c_captureTypeID;
    const c_tbl_a_capture_types_details_fields = DatabaseMobx.c_tbl_a_capture_types_details_fields;

    var expandedCaptureTypesDetailsFieldsRowObj = {};
    const captureTypesDetailsFieldsRowMap = JSFUNC.get_first_map_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "field_id"], [c_captureTypeID, i_fieldID]);
    if(captureTypesDetailsFieldsRowMap !== undefined) {
      expandedCaptureTypesDetailsFieldsRowObj = JSFUNC.obj_from_map(captureTypesDetailsFieldsRowMap);
    }
    else {
      expandedCaptureTypesDetailsFieldsRowObj = {
        field_id: i_fieldID,
        stage_id_relevant: -1,
        rec1_reqsig2_req3: 1,
        use_default_01: 0,
        default_value: "",
        only_capture_managers_can_view_field_01: 0
      };
    }
    return(this.details_group_capture_field_value_obj_from_capture_types_details_fields_row_obj(expandedCaptureTypesDetailsFieldsRowObj, i_convertDateFieldTypesToDayMdyDaysUntilTF));
  }

  details_group_capture_field_value_obj_from_capture_types_details_fields_row_obj(i_expandedCaptureTypesDetailsFieldsRowObj, i_convertDateFieldTypesToDayMdyDaysUntilTF) {
    const nowDate = CaptureExecMobx.o_nowDate;
    const openCaptureMap = this.c_openCaptureMap;
    const userIsCaptureManagerOfOpenCaptureTF = this.c_userIsCaptureManagerOfOpenCaptureTF;

    const expandedCaptureFieldMap = DatabaseMobx.fetch_expanded_capture_field_map_from_field_id(i_expandedCaptureTypesDetailsFieldsRowObj.field_id);
    
    var detailsGroupCaptureFieldValueObj = DatabaseMobx.capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(openCaptureMap, expandedCaptureFieldMap, false);

    const fieldTypeObj = detailsGroupCaptureFieldValueObj.expandedCaptureFieldMap.get("fieldTypeObj");
    const valueRaw = detailsGroupCaptureFieldValueObj.valueRaw;
    const valueSort = detailsGroupCaptureFieldValueObj.valueSort;

    //copied values from i_expandedCaptureTypesDetailsFieldsRowObj needed for detailsGroupCaptureFieldValueObj
    detailsGroupCaptureFieldValueObj.required3TF = i_expandedCaptureTypesDetailsFieldsRowObj.required3TF;
    detailsGroupCaptureFieldValueObj.stage_id_relevant = i_expandedCaptureTypesDetailsFieldsRowObj.stage_id_relevant;

    //field (for this open capture) is only visible to 0) everyone or 1) only assigned capture managers or 2) only to admins, based on admin Details/Dates Fields setting
    var fieldIsVisibleToUserTF = true; //when only_capture_managers_can_view_field_01 is 0, everyone can see this field
    if(i_expandedCaptureTypesDetailsFieldsRowObj.private1CaptureManagersOnlyTF) { //only visible to capture managers
      fieldIsVisibleToUserTF = userIsCaptureManagerOfOpenCaptureTF;
    }
    else if(i_expandedCaptureTypesDetailsFieldsRowObj.private2AdminsOnlyTF) { //only visible to admins
      fieldIsVisibleToUserTF = UserMobx.c_anyOfUsersMultiLoginsHaveAdminPowerTF;
    }
    detailsGroupCaptureFieldValueObj.fieldIsVisibleToUserTF = fieldIsVisibleToUserTF;

    //compute isUpcomingTF for each date
    if(fieldTypeObj.valueDisplayIsDateOrDateTimeTF) {
      detailsGroupCaptureFieldValueObj.isUpcomingTF = (valueSort >= nowDate);
    }

    //recast date fields from company format to dayMdyDaysUntil format for the dates card (snapshot and full card edit/save/cancel fields)
    if(i_convertDateFieldTypesToDayMdyDaysUntilTF) {
      if(!fieldTypeObj.maskDateFormatDayMdyDaysUntilTF) { //no need to convert a field that already is a dayMdyDaysUntil type, keep the original dayMdyDaysUntil format with its color and overdue setting
        if(fieldTypeObj.dateTF) { //force date mask to be days until
          detailsGroupCaptureFieldValueObj.valueMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(valueRaw, DatabaseMobx.c_genericDateDayMdyDaysUntil1FieldTypeObj);
        }
        else if(fieldTypeObj.dateTimeTF) { //force datetime mask to be datetime days until
          detailsGroupCaptureFieldValueObj.valueMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(valueRaw, DatabaseMobx.c_genericDateTimeDayMdyDaysUntil1FieldTypeObj);
        }
      }
    }

    return(detailsGroupCaptureFieldValueObj);
  }






  //Deal Shaping card
  get c_dealShapingAppliedTagsArrayOfObjs() { //return in the order of the tagIDs selected
    const shapingQuestionsTagsTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_shaping_questions_tags");
    const appliedTagsMapOfMaps = JSFUNC.filtered_mapOfMaps_from_id_array_or_comma(shapingQuestionsTagsTblRef, this.o_dealShapingAppliedTagIDsArray.slice());
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(appliedTagsMapOfMaps));
  }

  get c_dealShapingActiveStagesArrayOfObjs() { //add the last "Stage Independent Questions" stage to the bottom of the active stages to complete the list of deal shaping stages shown
    var dealShapingActiveStagesArrayOfObjs = [];
    for(let stageObj of this.c_activeStagesArrayOfObjs) {
      dealShapingActiveStagesArrayOfObjs.push(JSFUNC.copy_obj(stageObj));
    }
    dealShapingActiveStagesArrayOfObjs.push({id:-1, name: "Stage Independent Questions"});
    return(dealShapingActiveStagesArrayOfObjs);
  }

  get c_dealShapingQuestionsPerActiveStageDataObj() {
    var questionsPerActiveStageArrayOfObjs = [];
    for(let stageObj of this.c_dealShapingActiveStagesArrayOfObjs) {
      var questionsArrayOfObjs = [];
      var answersProgressWeightSum = 0;
      var questionsProgressWeightSum = 0;
      for(let questionAnswerObj of this.c_captureTypeActiveShapingCombinedQuestionsAnswersArrayOfObjs) { //a question in this stage, or if this is "Stage Independent" (id -1) all questions that are not active or closed stageIDs for this capture type (stage_id_relevant is -1 or a positive id that does not exist as an active or closed stage)
        if((questionAnswerObj.stage_id_relevant === stageObj.id) || (stageObj.id === -1 && !JSFUNC.in_array(questionAnswerObj.stage_id_relevant, this.c_captureTypeStageIDsArray))) {
          questionsArrayOfObjs.push(questionAnswerObj);
          answersProgressWeightSum += questionAnswerObj.progressScoreWeight;
          questionsProgressWeightSum += questionAnswerObj.progressTotalWeight;
        }
      }

      questionsPerActiveStageArrayOfObjs.push({
        stageObj: stageObj,
        questionsArrayOfObjs: questionsArrayOfObjs,
        numQuestions: questionsArrayOfObjs.length,
        stageProgress: this.compute_progress_from_answer_weight_sum_and_total_weight_sum(answersProgressWeightSum, questionsProgressWeightSum)
      });
    }
    return(questionsPerActiveStageArrayOfObjs);
  }

  get c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs() {
    const appliedTagIDsArray = this.o_dealShapingAppliedTagIDsArray.slice();
    const numAppliedTags = appliedTagIDsArray.length;

    //if no tags are applied, return the precomputed set of all questions for each active stage
    if(numAppliedTags === 0) {
      return(this.c_dealShapingQuestionsPerActiveStageDataObj);
    }

    const singleAppliedTagTF = (numAppliedTags === 1); //allows for === comparison rather than in_array
    const singleAppliedTagID = appliedTagIDsArray[0];

    //only keep questions that match the tags within each stage
    var questionsPerActiveStageFilteredByTagsArrayOfObjs = [];
    for(let activeStageObj of this.c_dealShapingQuestionsPerActiveStageDataObj) {
      var fitleredQuestionsArrayOfObjs = [];
      var fitleredQuestionIDsArray = [];
      for(let questionObj of activeStageObj.questionsArrayOfObjs) { //loop through full set of questions for this stage, no need to include a question that has 0 tags
        var questionMatchesTagsTF = false;
        if(questionObj.numTags === 1) { //question has a single tag
          if(singleAppliedTagTF) { //filtering for a single tag on deal shaping
            questionMatchesTagsTF = (questionObj.tagIDsArray[0] === singleAppliedTagID);
          }
          else { //filtering for multiple tags on deal shaping
            questionMatchesTagsTF = JSFUNC.in_array(questionObj.tagIDsArray[0], appliedTagIDsArray);
          }
        }
        else if(questionObj.numTags > 1) { //question has multiple tags
          if(singleAppliedTagTF) { //filtering for a single tag on deal shaping
            questionMatchesTagsTF = JSFUNC.in_array(singleAppliedTagID, questionObj.tagIDsArray);
          }
          else { //filtering for multiple tags on deal shaping
            questionMatchesTagsTF = JSFUNC.any_of_array1_is_in_array2(questionObj.tagIDsArray, appliedTagIDsArray);
          }
        }

        if(questionMatchesTagsTF) {
          fitleredQuestionsArrayOfObjs.push(questionObj);
          fitleredQuestionIDsArray.push(questionObj.id);
        }
      }

      questionsPerActiveStageFilteredByTagsArrayOfObjs.push({
        stageObj: activeStageObj.stageObj,
        questionsArrayOfObjs: fitleredQuestionsArrayOfObjs,
        numQuestions: fitleredQuestionsArrayOfObjs.length,
        stageProgress: activeStageObj.stageProgress
      });
    }
    return(questionsPerActiveStageFilteredByTagsArrayOfObjs);
  }

  calculate_shaping_progress_and_pwin_from_changed_stage_or_question_answer(i_currentStageID, i_changedQuestionID=undefined, i_changedQuestionNewAnswer=undefined) {
    //compute updated total progress, stage progress, and calculated pwin values using the newly updated question/answer
    var answersTotalProgressWeightSum = 0;
    var questionsTotalProgressWeightSum = 0;
    var answersCurrentStageProgressWeightSum = 0;
    var questionsCurrentStageProgressWeightSum = 0;
    var answersTotalPwinWeightSum = 0;
    var questionsTotalPwinWeightSum = 0;
    var allPwinQuestionsMinimumAnswerScore = JSFUNC.sort_max_mysqli_bigint();

    for(let captureTypeShapingQuestionMap of this.c_captureTypeShapingQuestionsMapOfMaps.values()) { //loop through all shaping questions checking each one if it's an active stage
      if(!JSFUNC.in_array(captureTypeShapingQuestionMap.get("stage_id_relevant"), this.c_captureTypeClosedStageIDsArray)) { //all deal shaping Active stages plus the Stage Independent questions are all questions that are not Closed
        var changedQuestionAnswerObj = this.create_combined_shaping_question_answer_obj_from_capture_type_shaping_question_map(captureTypeShapingQuestionMap, i_changedQuestionID, i_changedQuestionNewAnswer);

        answersTotalProgressWeightSum += changedQuestionAnswerObj.progressScoreWeight;
        questionsTotalProgressWeightSum += changedQuestionAnswerObj.progressTotalWeight;
        answersTotalPwinWeightSum += changedQuestionAnswerObj.pwinScoreWeight;
        questionsTotalPwinWeightSum += changedQuestionAnswerObj.pwinTotalWeight;

        if(changedQuestionAnswerObj.stage_id_relevant === i_currentStageID) { //this stage is the current stage
          answersCurrentStageProgressWeightSum += changedQuestionAnswerObj.progressScoreWeight;
          questionsCurrentStageProgressWeightSum += changedQuestionAnswerObj.progressTotalWeight;
        }

        //check every pwin question to see if the answer falls below the minimum pwin answer score threshold (a single question below this threshold locks the calc PWin at a constant value)
        if((changedQuestionAnswerObj.pwin_weight > 0) && (changedQuestionAnswerObj.answerScore0to100 < allPwinQuestionsMinimumAnswerScore)) {
          allPwinQuestionsMinimumAnswerScore = changedQuestionAnswerObj.answerScore0to100;
        }
      }
    }

    const totalProgress = this.compute_progress_from_answer_weight_sum_and_total_weight_sum(answersTotalProgressWeightSum, questionsTotalProgressWeightSum);
    const currentStageProgress = this.compute_progress_from_answer_weight_sum_and_total_weight_sum(answersCurrentStageProgressWeightSum, questionsCurrentStageProgressWeightSum);

    var calculatedPwin = -1; //this value is only updated to tbl_captures if the company pwin flag setting is calcualted
    if(DatabaseMobx.c_companyPwinIsCalculatedTF && (questionsTotalPwinWeightSum > 0)) { //no need to calculate if pwinflag is not calculated using the result of this calculation, also if there are 0 PWin questions (weight ends up 0), set the calc PWin to -1, which makes PWin "N/A"
      const allPwinQuestionsAverageWeightedScore = this.compute_progress_from_answer_weight_sum_and_total_weight_sum(answersTotalPwinWeightSum, questionsTotalPwinWeightSum);
      calculatedPwin = this.calculate_interpolated_pwin(allPwinQuestionsAverageWeightedScore, allPwinQuestionsMinimumAnswerScore);
      calculatedPwin = Math.round(calculatedPwin); //store in the database as the rounded number
    }

    return([totalProgress, currentStageProgress, calculatedPwin]);
  }

  compute_progress_from_answer_weight_sum_and_total_weight_sum(i_answersWeightSum, i_questionsWeightSum) {
    if(i_questionsWeightSum <= 0) { //if the total weight from all questions was 0, there were no questions with any weight to answer, avoid a divide by 0 by returning -1
      return(-1); //-1 is a flag for "N/A" that there are no questions in this stage to answer
    }
    return((i_answersWeightSum / i_questionsWeightSum) * 100);
  }

  calculate_interpolated_pwin(i_allPwinQuestionsAverageWeightedScore, i_allPwinQuestionsMinimumAnswerScore) {
    //example: 2 lines defined, (20,20 to 40,40) and (60,60 to 80,80)
    //pwin every 5 score values:
    //  score: 0   5   10  15  20  25  30  35  40  45  50  55  60  65  70  75  80  85  90  95  100
    //  pwin:  20  20  20  20  20  25  30  35  40  60  60  60  60  65  70  75  80  80  80  80  80

    //individual PWin question minimum score threshold
    if((DatabaseMobx.c_companyPwinCalcMinThreshScore >= 0) && (DatabaseMobx.c_companyPwinCalcMinThreshPwin >= 0)) { //if Admin -> System Setup -> PWin switch 'Using Calculated Minimum PWin Threshold' is switched, both of these values will be set >= 0, if turned off, both values are -1
      if(i_allPwinQuestionsMinimumAnswerScore < DatabaseMobx.c_companyPwinCalcMinThreshScore) {
        return(DatabaseMobx.c_companyPwinCalcMinThreshPwin);
      }
    }

    //if 0 lines are specified, return PWin as 0%
    const numLines = DatabaseMobx.c_pwinCalcLinesSortedArrayOfObjs.length;
    if(numLines === 0) {
      return(0);
    }

    for(let i = 0; i < numLines; i++) {
      var lineObj = DatabaseMobx.c_pwinCalcLinesSortedArrayOfObjs[i];
      if(i_allPwinQuestionsAverageWeightedScore <= lineObj.progress_x1) {
        return(lineObj.pwin_y1); //if the average score is less than this line's starting point, use this line's starting pwin value
      }

      var nextLineStartX = ((i === (numLines - 1)) ? (100) : (DatabaseMobx.c_pwinCalcLinesSortedArrayOfObjs[i + 1].progress_x1));
      var interpX2 = ((lineObj.progress_x2 <= nextLineStartX) ? (lineObj.progress_x2) : (nextLineStartX)); //the endpoint is either the end of this line, or the start of the next one if it overlaps this line
      if(i_allPwinQuestionsAverageWeightedScore >= lineObj.progress_x1 && i_allPwinQuestionsAverageWeightedScore <= interpX2) { //average score is between this line's start and end point
        return(JSFUNC.two_point_interpolation(lineObj.progress_x1, interpX2, lineObj.pwin_y1, lineObj.pwin_y2, i_allPwinQuestionsAverageWeightedScore));
      }
    }

    //score is outside the x2 of the last line, use the last y2 value
    return(lineObj.pwin_y2);
  }








  //Teammates card
  get c_teammatesUsMinus2CombinedTeammateObj() {
    const o_openCaptureID = this.o_openCaptureID;

    var usMinus2TblCTeammatesRowMap = undefined;
    for(let tblCTeammatesRowMap of DatabaseMobx.o_tbl_c_teammates.values()) {
      if(tblCTeammatesRowMap.get("capture_id") === o_openCaptureID) { //teammate record is added to this captureID
        if(tblCTeammatesRowMap.get("contact_company_id") === -2) {
          if((usMinus2TblCTeammatesRowMap === undefined) || (tblCTeammatesRowMap.get("id") < usMinus2TblCTeammatesRowMap.get("id"))) { //if there are duplicate sets of -2 teammate records for this capture, use the one with the lowest captureID
            usMinus2TblCTeammatesRowMap = tblCTeammatesRowMap;
          }
        }
      }
    }
    const usMinus2TeammateRowExistsTF = (usMinus2TblCTeammatesRowMap !== undefined);

    //if the ccid -2 teammate record for our company cannot be found, create a fake row
    if(!usMinus2TeammateRowExistsTF) {
      //get the tbl_c_teammates fields/idsb (including the admin extra fields)
      const tblCTeammatesFieldDbNamesAndIdsbObj = DatabaseMobx.get_tbl_info_field_db_names_array_and_idsb_array_from_tbl_name("tbl_c_teammates");
      var teammateFieldNamesArray = tblCTeammatesFieldDbNamesAndIdsbObj.fieldDbNamesArray;
      var teammateIdsbArray = tblCTeammatesFieldDbNamesAndIdsbObj.idsbArray;
      var teammateBlankValuesArray = tblCTeammatesFieldDbNamesAndIdsbObj.blankValuesArray;

      usMinus2TblCTeammatesRowMap = new Map();
      for(let f = 0; f < teammateFieldNamesArray.length; f++) {
        var usMinus2TeammateValue = teammateBlankValuesArray[f];
        if(teammateFieldNamesArray[f] === "id") {
          usMinus2TeammateValue = -999;
        }
        else if(teammateFieldNamesArray[f] === "capture_id") {
          usMinus2TeammateValue = o_openCaptureID;
        }
        else if(teammateFieldNamesArray[f] === "contact_company_id") {
          usMinus2TeammateValue = -2;
        }
        usMinus2TblCTeammatesRowMap.set(teammateFieldNamesArray[f], usMinus2TeammateValue);
      }
    }

    var usMinus2CombinedTeammateObj = this.create_combined_teammate_obj_with_contracts_and_surveys_from_tbl_c_teammates_row_map(usMinus2TblCTeammatesRowMap, usMinus2TeammateRowExistsTF);
    return(usMinus2CombinedTeammateObj);
  }

  get c_teammatesUsMinus2TblCTeammatesRowExistsTF() {
    const teammatesUsMinus2CombinedTeammateObj = this.c_teammatesUsMinus2CombinedTeammateObj;
    return(teammatesUsMinus2CombinedTeammateObj.id > 0);
  }

  get c_teammatesAllCombinedTeammatesArrayOfObjs() { //gathers all teammate records for this captureID (not including the contactCompanyID -2 teammate record for our division)
    const o_openCaptureID = this.o_openCaptureID;
    const o_tbl_c_teammates = DatabaseMobx.o_tbl_c_teammates;

    const teammateRowExistsTF = true;

    var teammatesAllCombinedTeammatesArrayOfObjs = [];
    for(let tblCTeammatesRowMap of o_tbl_c_teammates.values()) {
      if(tblCTeammatesRowMap.get("capture_id") === o_openCaptureID) { //filter teammate records for only this open captureID
        var combinedTeammateObj = this.create_combined_teammate_obj_with_contracts_and_surveys_from_tbl_c_teammates_row_map(tblCTeammatesRowMap, teammateRowExistsTF);
        teammatesAllCombinedTeammatesArrayOfObjs.push(combinedTeammateObj);
      }
    }
    return(teammatesAllCombinedTeammatesArrayOfObjs);
  }

  create_combined_teammate_obj_with_contracts_and_surveys_from_tbl_c_teammates_row_map(i_tblCTeammatesRowMap, i_teammateRowExistsTF) {
    const o_openCaptureID = this.o_openCaptureID;
    const c_captureTypeIsPrimeTF = this.c_captureTypeIsPrimeTF;
    const c_companyTeammateContractsOnOtherTeammatesWhenSubTF = DatabaseMobx.c_companyTeammateContractsOnOtherTeammatesWhenSubTF;
    const c_teammatesContractTypesArrayOfObjs = DatabaseMobx.c_teammatesContractTypesArrayOfObjs;
    const c_bitIdiqRapidResponseTF = DatabaseMobx.c_bitIdiqRapidResponseTF;

    //combined teammate obj (tbl_c_teammates fields, contactCompanyObj, teammateNamePlainText, allocationX, workshareX, businessTypeX, businessSizeLabelX, sbCertsX, contactPersonIDsArray)
    var combinedTeammateObj = TeammateContractsMobx.create_combined_teammate_obj_from_tbl_c_teammates_row_map(i_tblCTeammatesRowMap);

    //determine if this teammate has contracts and the ability to expand to view workshare/contracts/contacts/etc
    var hasContractsTF = false;
    if(i_teammateRowExistsTF) { //on a sub deal, if the c_companyTeammateContractsOnOtherTeammatesWhenSubTF flag is not set (default), only show contracts on us and the prime
      hasContractsTF = (c_captureTypeIsPrimeTF || combinedTeammateObj.isPrimeOnSubCaptureTypeTF || combinedTeammateObj.isUsTF || c_companyTeammateContractsOnOtherTeammatesWhenSubTF);
    }
    
    //teammate contracts (compute expanded obj for each contract type NDA/TA/SubK)
    var expandedTeammateContractsArrayOfObjs = [];
    for(let teammateContractTypeObj of c_teammatesContractTypesArrayOfObjs) {
      var expandedTeammateContractObj = TeammateContractsMobx.expanded_teammate_contract_obj_from_capture_id_and_teammate_id_and_contract_type_id(combinedTeammateObj.capture_id, combinedTeammateObj.id, teammateContractTypeObj.id);
      expandedTeammateContractsArrayOfObjs.push(expandedTeammateContractObj);
    }

    //teammate surveys (if survey feature is turned on by BIT)
    var surveysAndResponseTimesArrayOfObjs = [];
    if(c_bitIdiqRapidResponseTF) {
      for(let teammatesSurveyObj of this.c_teammatesSurveysArrayOfObjs) { //loop through each created survey for this open capture
        var surveyObj = JSFUNC.copy_obj(teammatesSurveyObj);
        var responseTimeObj = this.get_survey_response_time_obj_from_teammate_id_and_survey_id(o_openCaptureID, combinedTeammateObj.id, teammatesSurveyObj.id);

        surveysAndResponseTimesArrayOfObjs.push({
          surveyObj: surveyObj,
          responseTimeObj: responseTimeObj
        });
      }
    }

    combinedTeammateObj.hasContractsTF = hasContractsTF;
    combinedTeammateObj.teammateRowExistsTF = i_teammateRowExistsTF;
    combinedTeammateObj.expandedTeammateContractsArrayOfObjs = expandedTeammateContractsArrayOfObjs;
    combinedTeammateObj.surveysAndResponseTimesArrayOfObjs = surveysAndResponseTimesArrayOfObjs;

    return(combinedTeammateObj);
  }

  get_survey_response_time_obj_from_teammate_id_and_survey_id(i_captureID, i_teammateID, i_surveyID) {
    const nowDateTimeUtc = JSFUNC.now_datetime_utc();

    //intialized reponseTimeObj when the record does not exist (survey not yet emailed to teammate)
    var responseTimeObj = {
      id: -1,
      capture_id: i_captureID,
      survey_id: i_surveyID,
      teammate_id: i_teammateID,
      sent_by_user_per_email_id: -1,
      file_ids_comma: "",
      sent_datetime_utc: JSFUNC.blank_datetime(),
      first_opened_datetime_utc: JSFUNC.blank_datetime(),
      due_datetime_utc: JSFUNC.blank_datetime(),
      completed_datetime_utc: JSFUNC.blank_datetime(),
      not_interested_01: 0
    };

    var tblCTeammatesSurveyResponseTimesRowMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_c_teammates_surveys_response_times, ["capture_id", "survey_id", "teammate_id"], [i_captureID, i_surveyID, i_teammateID]);
    if(tblCTeammatesSurveyResponseTimesRowMap !== undefined) {
      responseTimeObj = JSFUNC.obj_from_map(tblCTeammatesSurveyResponseTimesRowMap);
    }

    //load the files (tbl_c_teammates_surveys_filefoldersystem)
    responseTimeObj.selectedFileIDsArray = JSFUNC.convert_comma_list_to_int_array(responseTimeObj.file_ids_comma);

    //not interested
    responseTimeObj.surveyIsIndicatedAsNotInterestedTF = (responseTimeObj.not_interested_01 === 1);

    //status
    var responseColorObj = undefined;
    if(responseTimeObj.surveyIsSentTF) {
      if(responseTimeObj.surveyIsIndicatedAsNotInterestedTF) { //green completed
        responseColorObj = this.survey_response_color_obj_from_status_db_name("notinterested");
      }
      else if(responseTimeObj.surveyIsCompletedTF) { //green completed
        responseColorObj = this.survey_response_color_obj_from_status_db_name("completed");
      }
      else if(nowDateTimeUtc > responseTimeObj.due_datetime_utc) { //dark gray past due not completed, survey was sent
        responseColorObj = this.survey_response_color_obj_from_status_db_name("sentexpired");
      }
      else if(responseTimeObj.surveyIsFirstOpenedTF) { //yellow sent and opened
        responseColorObj = this.survey_response_color_obj_from_status_db_name("sentopened");
      }
      else { //gold, not yet past due, not yet completed, survey was sent
        responseColorObj = this.survey_response_color_obj_from_status_db_name("sentnotopened");
      }
    }

    if(responseColorObj === undefined) {
      responseColorObj = this.survey_response_color_obj_from_status_db_name("notsent");
    }

    responseTimeObj.responseBgColor = responseColorObj.responseBgColor;
    responseTimeObj.responseFontClass = responseColorObj.responseFontClass;
    responseTimeObj.responseStatus = responseColorObj.responseStatus;

    //dates
    var sentDateJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(responseTimeObj.sent_datetime_utc);
    responseTimeObj.sentDateTimeLocal = JSFUNC.get_MjYgiA_datetime_from_jsdateobj_and_utctf(sentDateJsDateObj, false);
    responseTimeObj.sentDateTimeLocalShort = JSFUNC.get_md_date_from_jsdateobj_and_utctf(sentDateJsDateObj, false);
    responseTimeObj.surveyIsSentTF = JSFUNC.datetime_is_filled_out_tf(responseTimeObj.sent_datetime_utc);

    var dueDateJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(responseTimeObj.due_datetime_utc);
    responseTimeObj.dueDateTimeLocal = JSFUNC.get_MjYgiA_datetime_from_jsdateobj_and_utctf(dueDateJsDateObj, false);
    responseTimeObj.dueDateTimeLocalShort = JSFUNC.get_md_date_from_jsdateobj_and_utctf(dueDateJsDateObj, false);

    var firstOpenedDateJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(responseTimeObj.first_opened_datetime_utc);
    responseTimeObj.firstOpenedDateTimeLocal = JSFUNC.get_MjYgiA_datetime_from_jsdateobj_and_utctf(firstOpenedDateJsDateObj, false);
    responseTimeObj.firstOpenedDateTimeLocalShort = JSFUNC.get_md_date_from_jsdateobj_and_utctf(firstOpenedDateJsDateObj, false);
    responseTimeObj.surveyIsFirstOpenedTF = JSFUNC.datetime_is_filled_out_tf(responseTimeObj.first_opened_datetime_utc);

    var completedDateJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(responseTimeObj.completed_datetime_utc);
    responseTimeObj.completedDateTimeLocal = JSFUNC.get_MjYgiA_datetime_from_jsdateobj_and_utctf(completedDateJsDateObj, false);
    responseTimeObj.completedDateTimeLocalShort = JSFUNC.get_md_date_from_jsdateobj_and_utctf(completedDateJsDateObj, false);
    responseTimeObj.surveyIsCompletedTF = JSFUNC.datetime_is_filled_out_tf(responseTimeObj.completed_datetime_utc);

    return(responseTimeObj);
  }

  survey_response_color_obj_from_status_db_name(i_surveyStatusDbName) {
    var responseBgColor = "eee";
    var responseFontClass = "fontTextLight";
    var responseStatus = "--";
    if(i_surveyStatusDbName === "notsent") {
      responseBgColor = "bbbbbb";
      responseFontClass = "fontTextLight";
      responseStatus = "Not Sent";
    }
    else if(i_surveyStatusDbName === "sentnotopened") {
      responseBgColor = "b38911";
      responseFontClass = "fontWhite";
      responseStatus = "Not Yet Opened";
    }
    else if(i_surveyStatusDbName === "sentopened") {
      responseBgColor = "f0e607";
      responseFontClass = "fontWhite";
      responseStatus = "Opened";
    }
    else if(i_surveyStatusDbName === "completed") {
      responseBgColor = "00b050";
      responseFontClass = "fontWhite";
      responseStatus = "Completed";
    }
    else if(i_surveyStatusDbName === "notinterested") {
      responseBgColor = "c00000";
      responseFontClass = "fontWhite";
      responseStatus = "Responded Not Interested";
    }
    else if(i_surveyStatusDbName === "sentexpired") {
      responseBgColor = "404040";
      responseFontClass = "fontWhite";
      responseStatus = "Expired";
    }

    return({
      responseBgColor: responseBgColor,
      responseFontClass: responseFontClass,
      responseStatus: responseStatus
    });
  }

  get c_teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs() {
    const captureTypeIsPrimeTF = this.c_captureTypeIsPrimeTF;
    const teammatesAllCombinedTeammatesArrayOfObjs = this.c_teammatesAllCombinedTeammatesArrayOfObjs;

    var teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs = [];
    if(!captureTypeIsPrimeTF) { //if this is a sub deal, take all teammates marked as prime and sort them by allocation_percent desc
      teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(teammatesAllCombinedTeammatesArrayOfObjs, "isPrimeOnSubCaptureTypeTF", true, "allocation_percent", false);
    }
    return(teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs);
  }

  get c_selectedLargeBusinessTeammatesArrayOfObjs() {
    return(this.filter_sort_teammate_groups_arrayOfObjs(true, "businessTypeIsLargeTF"));
  }

  get c_selectedSmallBusinessTeammatesArrayOfObjs() {
    return(this.filter_sort_teammate_groups_arrayOfObjs(true, "businessTypeIsSmallTF"));
  }

  get c_selectedInvalidBusinessTypeTeammatesArrayOfObjs() {
    return(this.filter_sort_teammate_groups_arrayOfObjs(true, "businessTypeIsInvalidTF"));
  }

  get c_notSelectedLargeBusinessTeammatesArrayOfObjs() {
    return(this.filter_sort_teammate_groups_arrayOfObjs(false, "businessTypeIsLargeTF"));
  }

  get c_notSelectedSmallBusinessTeammatesArrayOfObjs() {
    return(this.filter_sort_teammate_groups_arrayOfObjs(false, "businessTypeIsSmallTF"));
  }

  get c_notSelectedInvalidBusinessTypeTeammatesArrayOfObjs() {
    return(this.filter_sort_teammate_groups_arrayOfObjs(false, "businessTypeIsInvalidTF"));
  }

  filter_sort_teammate_groups_arrayOfObjs(i_isSelectedTF, businessTypeTFFieldName) {
    const captureTypeIsPrimeTF = this.c_captureTypeIsPrimeTF;
    const teammatesAllCombinedTeammatesArrayOfObjs = this.c_teammatesAllCombinedTeammatesArrayOfObjs;

    var fieldNamesArrayToCheck = ["isSelectedTF", businessTypeTFFieldName];
    var valuesArrayToCompare = [i_isSelectedTF, true];
    if(!captureTypeIsPrimeTF) { //if this is a prime deal, include all teammates in these groups regardless of prime_01 status (since Us is the prime), otherwise for sub deals, remove all isPrimeOnSubCaptureTypeTF teammates from these groups
      fieldNamesArrayToCheck.push("isPrimeOnSubCaptureTypeTF");
      valuesArrayToCompare.push(false);
    }

    //sort by allocation_percent descending
    const filteredSortedCombinedTeammatesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(teammatesAllCombinedTeammatesArrayOfObjs, fieldNamesArrayToCheck, valuesArrayToCompare, "allocation_percent", false);
    return(filteredSortedCombinedTeammatesArrayOfObjs);
  }

  get c_teammatesSelectedTeammatesArrayOfObjs() {
    return(JSFUNC.concat_arrays_or_values_into_new_array(this.c_selectedLargeBusinessTeammatesArrayOfObjs, this.c_selectedSmallBusinessTeammatesArrayOfObjs, this.c_selectedInvalidBusinessTypeTeammatesArrayOfObjs));
  }

  get c_teammatesNotSelectedTeammatesArrayOfObjs() {
    return(JSFUNC.concat_arrays_or_values_into_new_array(this.c_notSelectedLargeBusinessTeammatesArrayOfObjs, this.c_notSelectedSmallBusinessTeammatesArrayOfObjs, this.c_notSelectedInvalidBusinessTypeTeammatesArrayOfObjs));
  }

  get c_teammatesUsAndSelectedTeammatesArrayOfObjs() {
    return(JSFUNC.concat_arrays_or_values_into_new_array(this.c_teammatesUsMinus2CombinedTeammateObj, this.c_teammatesSelectedTeammatesArrayOfObjs));
  }

  get c_teammatesSubCaptureTypeAllPrimesTotalPercent() {
    return(JSFUNC.sum_of_arrayOfObjs_column(this.c_teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs, "allocation_percent", 0));
  }

  get c_teammatesSelectedLargeBusinessesTotalPercent() {
    return(JSFUNC.sum_of_arrayOfObjs_column(this.c_selectedLargeBusinessTeammatesArrayOfObjs, "allocation_percent", 0));
  }

  get c_teammatesSelectedSmallBusinessesTotalPercent() {
    return(JSFUNC.sum_of_arrayOfObjs_column(this.c_selectedSmallBusinessTeammatesArrayOfObjs, "allocation_percent", 0));
  }

  get c_teammatesContactCompanyIDsArray() {
    //used when adding a new teammate to know which contact companies have already been added
    var teammatesContactCompanyIDsArray = [];
    for(let teammateObj of this.c_teammatesAllCombinedTeammatesArrayOfObjs) {
      teammatesContactCompanyIDsArray.push(teammateObj.contactCompanyObj.id);
    }
    return(teammatesContactCompanyIDsArray);
  }

  get c_teammatesSBCertificationAllocationsArrayOfObjs() {
    var teammatesSBCertificationAllocationsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_teammates_sb_certification_allocations, "capture_id", this.o_openCaptureID, "allocation_percent", false);
    for(let sbCertificationAllocationObj of teammatesSBCertificationAllocationsArrayOfObjs) {
      var sbCertificationBmSetAsideID = sbCertificationAllocationObj.sb_certification_bm_set_aside_id;

      //load the data for this sb certification from the sbCertID
      var sbCertificationMap = DatabaseMobx.tbl_row_map_from_id("tbl_bit_master_set_asides", sbCertificationBmSetAsideID);
      sbCertificationAllocationObj.sbCertificationObj = JSFUNC.obj_from_map(sbCertificationMap);
      sbCertificationAllocationObj.sbCertificationObj.shortNameAndNamePlainText = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(sbCertificationBmSetAsideID, DatabaseMobx.c_selectBitMasterSetAsideFieldTypeObj, true);

      //compute the sb allocations for each teammate (and "us") for the right side tables/graphs
      var sbCertAchievedPercent = 0;

      //our division sb certs
      if(JSFUNC.text_or_number_is_filled_out_tf(this.c_captureOurPrimeSubTeammateAllocationPercent0to100)) {
        if(this.c_teammatesOurPrimeSubTeammateDivisionMap.get("divisionIsSmallBusinessTF")) {
          if(JSFUNC.in_array(sbCertificationBmSetAsideID, this.c_teammatesOurPrimeSubTeammateDivisionMap.get("calcPrimeSBCertificationsBmSetAsideIDsArray"))) {
            sbCertAchievedPercent += this.c_captureOurPrimeSubTeammateAllocationPercent0to100;
          }
        }
      }

      //teammates
      for(let sbTeammateObj of this.c_selectedSmallBusinessTeammatesArrayOfObjs) {
        if(JSFUNC.text_or_number_is_filled_out_tf(sbTeammateObj.allocation_percent) && JSFUNC.in_array(sbCertificationBmSetAsideID, sbTeammateObj.teammateSBCertificationsBmSetAsideIDsArray)) {
          sbCertAchievedPercent += sbTeammateObj.allocation_percent;
        }
      }
      sbCertificationAllocationObj.sbCertAchievedPercent = sbCertAchievedPercent;
    }
    return(teammatesSBCertificationAllocationsArrayOfObjs);
  }

  get c_teammatesSBCertificationIDsArray() { //used when adding a new small business certification type to know which have already been added
    var teammatesSBCertificationIDsArray = [];
    for(let sbCertificationAllocationObj of this.c_teammatesSBCertificationAllocationsArrayOfObjs) {
      teammatesSBCertificationIDsArray.push(sbCertificationAllocationObj.sbCertificationObj.id);
    }
    return(teammatesSBCertificationIDsArray);
  }

  get c_teammatesOurPrimeSubTeammateDivisionMap() {
    var ourPrimeSubTeammateDivisionMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_divisions", this.c_captureOurPrimeSubTeammateDivisionID);
    if(!JSFUNC.select_int_is_filled_out_tf(this.c_captureOurPrimeSubTeammateDivisionID)) {
      var copyOurPrimeSubTeammateDivisionMap = JSFUNC.copy_map(ourPrimeSubTeammateDivisionMap);
      copyOurPrimeSubTeammateDivisionMap.set("name", "--Unassigned--");
      return(copyOurPrimeSubTeammateDivisionMap);
    }
    return(ourPrimeSubTeammateDivisionMap);
  }

  get c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj() {
    const openCaptureID = this.o_openCaptureID;
    const teammatesExpandedTeammateID = this.o_teammatesExpandedTeammateID;
    if(teammatesExpandedTeammateID !== undefined) {
      const teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_c_teammates_ratings_questionnaire_submissions, ["teammate_id", "capture_id"], [teammatesExpandedTeammateID, openCaptureID]);
      if(teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowMap !== undefined) {
        return(JSFUNC.obj_from_map(teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowMap));
      }
    }
    return(undefined);
  }

  get c_teammatesRatingsExpandedTeammateOverallCaptureRating() {
    return(this.compute_teammate_overall_capture_rating_from_single_questionnaire_submission_row_obj(this.c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj));
  }

  compute_teammate_overall_capture_rating_from_single_questionnaire_submission_row_obj(i_teammateQuestionnaireSubmissionRowObj) {
    var teammateOverallCaptureRating = -1; //N/A by default
    if(i_teammateQuestionnaireSubmissionRowObj !== undefined) {
      var trqsArrayOfObjs = [i_teammateQuestionnaireSubmissionRowObj];
      var cctrObj = this.a_teammates_ratings_compute_contact_company_teammate_ratings_from_filtered_questionnaire_submissions_arrayOfObjs(trqsArrayOfObjs);
      for(let f = 0; f < cctrObj.contactCompanyFieldNamesArray.length; f++) {
        if(cctrObj.contactCompanyFieldNamesArray[f] === "tr_total") {
          teammateOverallCaptureRating = cctrObj.contactCompanyValuesArray[f];
          break;
        }
      }
    }
    return(teammateOverallCaptureRating);
  }

  get c_teammatesRatingsExpandedTeammateMostRecentReviewDate() {
    var teammatesRatingsExpandedTeammateMostRecentReviewDate = JSFUNC.blank_datetime();
    if(this.c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj !== undefined) {
      teammatesRatingsExpandedTeammateMostRecentReviewDate = JSFUNC.convert_mysqldate_or_mysqldatetimeutc_to_mysqldatelocal(this.c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj.datetime_utc);
    }
    return(teammatesRatingsExpandedTeammateMostRecentReviewDate);
  }


  get c_teammatesFloatingBoxSurveyObj() {
    const openCaptureID = this.o_openCaptureID;
    const teammateID = this.o_teammatesFloatingBoxSurveyTeammateID;
    const surveyID = this.o_teammatesFloatingBoxSurveySurveyID;

    if((teammateID === undefined) || (surveyID === undefined)) {
      return(undefined);
    }

    //get the teammateObj (us or a teammate), can be undefined if teammateObj not found
    var combinedTeammateObj = undefined;
    if(teammateID === this.c_teammatesUsMinus2CombinedTeammateObj.id) {
      combinedTeammateObj = this.c_teammatesUsMinus2CombinedTeammateObj;
    }
    else {
      for(let allTeammateObj of this.c_teammatesAllCombinedTeammatesArrayOfObjs) {
        if(teammateID === allTeammateObj.id) {
          combinedTeammateObj = allTeammateObj;
          break;
        }
      }
    }

    //check if the teammate added contact persons for the surveys are all valid
    const teammateSurveysContactPersonsAllEmailsValidTF = this.determine_if_teammate_surveys_contact_persons_all_emails_valid_tf_from_teammate_obj(combinedTeammateObj);

    //get the surveyObj matching this surveyID
    var surveyObj = undefined;
    for(let teammatesSurveyObj of this.c_teammatesSurveysArrayOfObjs) {
      if(surveyID === teammatesSurveyObj.id) {
        surveyObj = teammatesSurveyObj;
        break;
      }
    }

    //get the responseTimeObj
    var responseTimeObj = this.get_survey_response_time_obj_from_teammate_id_and_survey_id(openCaptureID, teammateID, surveyID);

    //get uploaded files for this survey and initialize the selected files
    const fileFolderSystemMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_c_teammates_surveys_filefoldersystem, ["capture_id", "survey_id"], [openCaptureID, surveyID]);
    const allSurveyFileIDsArray = JSFUNC.get_column_vector_from_mapOfMaps(fileFolderSystemMapOfMaps, "id");

    var initialSelectedFileIDsArray = allSurveyFileIDsArray;
    if(responseTimeObj.surveyIsSentTF) {
      initialSelectedFileIDsArray = [];
      for(let fileID of responseTimeObj.selectedFileIDsArray) {
        if(JSFUNC.in_array(fileID, allSurveyFileIDsArray)) {
          initialSelectedFileIDsArray.push(fileID);
        }
      }
    }

    //get the questions for this survey with the type and teammate answers to each one
    const questionResponsesArrayOfObjs = this.teammate_surveys_build_question_responses_arrayOfObjs_from_capture_id_survey_id_teammate_id(openCaptureID, surveyID, teammateID);

    return({
      combinedTeammateObj: combinedTeammateObj,
      teammateSurveysContactPersonsAllEmailsValidTF: teammateSurveysContactPersonsAllEmailsValidTF,
      surveyObj: surveyObj,
      responseTimeObj: responseTimeObj,
      fileFolderSystemMapOfMaps: fileFolderSystemMapOfMaps,
      initialSelectedFileIDsArray: initialSelectedFileIDsArray,
      questionResponsesArrayOfObjs: questionResponsesArrayOfObjs
    });
  }


  teammate_surveys_build_question_responses_arrayOfObjs_from_capture_id_survey_id_teammate_id(i_captureID, i_surveyID, i_teammateIDOrUndefined) {
    const o_tbl_c_teammates_surveys_questions = DatabaseMobx.o_tbl_c_teammates_surveys_questions;
    const o_tbl_c_teammates_surveys_answers = DatabaseMobx.o_tbl_c_teammates_surveys_answers;

    const fetchTeammateAnswersTF = JSFUNC.is_number(i_teammateIDOrUndefined);

    //filter questions for this capture and the selected survey on this capture
    const questionsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_c_teammates_surveys_questions, ["capture_id", "survey_id"], [i_captureID, i_surveyID], "sort", true);

    //loop through each question and fetch the answers from the given teammate
    var questionResponsesArrayOfObjs = [];
    for(let questionObj of questionsArrayOfObjs) {
      //copy fields from o_tbl_c_teammates_surveys_questions row (id, name, question_type_id)
      var questionResponseObj = JSFUNC.copy_obj(questionObj);

      //convert the question_type_id to true/false flags
      var typeIsYesNoTF = false;
      var typeIsScale05TF = false;
      var typeIsTextResponseTF = false;
      var typeIsInstructionsBlockTF = false;
      if(questionObj.question_type_id === 1) { typeIsYesNoTF = true; }
      else if(questionObj.question_type_id === 2) { typeIsScale05TF = true; }
      else if(questionObj.question_type_id === 3) { typeIsTextResponseTF = true; }
      else if(questionObj.question_type_id === 4) { typeIsInstructionsBlockTF = true; }
      questionResponseObj.typeIsYesNoTF = typeIsYesNoTF;
      questionResponseObj.typeIsScale05TF = typeIsScale05TF;
      questionResponseObj.typeIsTextResponseTF = typeIsTextResponseTF;
      questionResponseObj.typeIsInstructionsBlockTF = typeIsInstructionsBlockTF;

      //fetch the response (rating and comment) to this survey question for this capture and the provided teammate
      var rating = -1;
      var comment = "";
      if(fetchTeammateAnswersTF) {
        var teammateAnswerMap = JSFUNC.get_first_map_matching_field_value(o_tbl_c_teammates_surveys_answers, ["capture_id", "teammate_id", "question_id"], [i_captureID, i_teammateIDOrUndefined, questionObj.id]);
        if(teammateAnswerMap !== undefined) {
          rating = teammateAnswerMap.get("rating");
          comment = teammateAnswerMap.get("comment");
        }
      }
      questionResponseObj.rating = rating;
      questionResponseObj.comment = comment;

      questionResponsesArrayOfObjs.push(questionResponseObj);
    }

    return(questionResponsesArrayOfObjs);
  }


  get c_teammatesFloatingBoxSurveyIsOpenTF() {
    return(this.c_teammatesFloatingBoxSurveyObj !== undefined);
  }


  get c_teammatesSurveysArrayOfObjs() {
    var teammatesSurveysArrayOfObjs = [];
    if(DatabaseMobx.c_bitIdiqRapidResponseTF) {
      for(let teammatesSurveyMap of DatabaseMobx.o_tbl_c_teammates_surveys.values()) {
        if(teammatesSurveyMap.get("capture_id") === this.o_openCaptureID) {
          var teammatesSurveyObj = JSFUNC.obj_from_map(teammatesSurveyMap);
          teammatesSurveysArrayOfObjs.push(teammatesSurveyObj);
        }
      }
      JSFUNC.sort_arrayOfObjs(teammatesSurveysArrayOfObjs, "sort", true);
    }
    return(teammatesSurveysArrayOfObjs);
  }

  get c_teammatesSurveysIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_teammatesSurveysArrayOfObjs, "id"));
  }

  get c_teammatesSurveysNumSurveys() {
    return(this.c_teammatesSurveysIDsArray.length);
  }

  get c_teammatesSurveysSelectedExpandedSurveyObj() {
    for(let teammatesSurveyObj of this.c_teammatesSurveysArrayOfObjs) {
      if(teammatesSurveyObj.id === this.o_teammatesSurveysSelectedSurveyID) {
        var questionsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_teammates_surveys_questions, ["capture_id", "survey_id"], [teammatesSurveyObj.capture_id, teammatesSurveyObj.id], "sort", true);
        var fileFolderSystemMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_c_teammates_surveys_filefoldersystem, ["capture_id", "survey_id"], [teammatesSurveyObj.capture_id, teammatesSurveyObj.id]);
        return({
          id: teammatesSurveyObj.id,
          capture_id: teammatesSurveyObj.capture_id,
          sort: teammatesSurveyObj.sort,
          title: teammatesSurveyObj.title,
          invitation_text: teammatesSurveyObj.invitation_text,
          questionsArrayOfObjs: questionsArrayOfObjs,
          fileFolderSystemMapOfMaps: fileFolderSystemMapOfMaps
        });
      }
    }
    return(undefined);
  }

  get c_teammatesSurveysSelectMultiDocumentsCardAllFilesFieldTypeObj() {
    const c_documentsFileFolderSystemMapOfMaps = this.c_documentsFileFolderSystemMapOfMaps;

    const itemName = "Capture Document";
    return(DatabaseMobx.create_filefoldersystem_field_type_obj_from_tbl_name(c_documentsFileFolderSystemMapOfMaps, itemName, true, true, true));
  }

  get c_teammatesSurveysResultsMatrixObj() {
    const openCaptureID = this.o_openCaptureID;
    const teammatesSurveysResultsMatrixFilterIDsComma = this.o_teammatesSurveysResultsMatrixFilterIDsComma;
    const teammatesUsAndSelectedTeammatesArrayOfObjs = this.c_teammatesUsAndSelectedTeammatesArrayOfObjs;
    const teammatesSurveysSelectedExpandedSurveyObj = this.c_teammatesSurveysSelectedExpandedSurveyObj;

    //initialize output for this matrix obj
    var allFilterOptionsSelectedTF = true;
    var usAndTeammatesArrayOfObjs = [];
    var questionResponsesArrayOfObjs = [];
    var atLeast1TeammateHasAnswered1QuestionTF = false;
    var teamStrengthScore = -1;
    var teamStrengthBgColor = "ddd";

    var totalStrengthPoints = 0;
    var totalMaxStrengthPoints = 0;

    //determine the items selected in the results matrix questions filter
    var filterIDsArray = []; //no need to convert this if all options are selected
    if(teammatesSurveysResultsMatrixFilterIDsComma.length < 17) { //"1,2,3,4,5,6,7,8,9" is a string with 17 characters, in any order, means all options are selected
      allFilterOptionsSelectedTF = false;
      filterIDsArray = JSFUNC.convert_comma_list_to_int_array(teammatesSurveysResultsMatrixFilterIDsComma);
    }

    if(teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      const surveyID = teammatesSurveysSelectedExpandedSurveyObj.id;
      const questionsArrayOfObjs = teammatesSurveysSelectedExpandedSurveyObj.questionsArrayOfObjs;

      //get us and teammates together
      for(let teammateObj of teammatesUsAndSelectedTeammatesArrayOfObjs) {
        var surveyContactPersonsMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(teammateObj.surveys_contact_person_ids_comma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj);
        var surveyContactPersonsAllEmailsValidTF = this.determine_if_teammate_surveys_contact_persons_all_emails_valid_tf_from_ids_comma(teammateObj.surveys_contact_person_ids_comma);
        var teammateResponseTimeObj = this.get_survey_response_time_obj_from_teammate_id_and_survey_id(openCaptureID, teammateObj.id, surveyID);
        
        var responseExistsAndSurveyIsSentTF = false;
        if(teammateResponseTimeObj !== undefined) {
          responseExistsAndSurveyIsSentTF = teammateResponseTimeObj.surveyIsSentTF;
        }

        usAndTeammatesArrayOfObjs.push({
          id: teammateObj.id,
          contact_company_id: teammateObj.contact_company_id,
          isUsTF: teammateObj.isUsTF,
          name: teammateObj.teammateNamePlainText,
          surveys_contact_person_ids_comma: teammateObj.surveys_contact_person_ids_comma,
          surveyContactPersonsMaskPlainText: surveyContactPersonsMaskPlainText,
          surveyContactPersonsAllEmailsValidTF: surveyContactPersonsAllEmailsValidTF,
          selectedSurveyResponseTimeObj: teammateResponseTimeObj,
          responseExistsAndSurveyIsSentTF: responseExistsAndSurveyIsSentTF
        });
      }

      //create matrix of question rows containing answers from each teammate
      for(let questionObj of questionsArrayOfObjs) {
        var questionResponseObj = JSFUNC.copy_obj(questionObj);

        var typeIsYesNoTF = false;
        var typeIsScale05TF = false;
        var typeIsTextResponseTF = false;
        var typeIsInstructionsBlockTF = false;
        if(questionObj.question_type_id === 1) { typeIsYesNoTF = true; }
        else if(questionObj.question_type_id === 2) { typeIsScale05TF = true; }
        else if(questionObj.question_type_id === 3) { typeIsTextResponseTF = true; }
        else if(questionObj.question_type_id === 4) { typeIsInstructionsBlockTF = true; }
        questionResponseObj.typeIsYesNoTF = typeIsYesNoTF;
        questionResponseObj.typeIsScale05TF = typeIsScale05TF;
        questionResponseObj.typeIsTextResponseTF = typeIsTextResponseTF;
        questionResponseObj.typeIsInstructionsBlockTF = typeIsInstructionsBlockTF;

        var teammateAnswersArrayOfObjs = [];
        for(let teammateObj of teammatesUsAndSelectedTeammatesArrayOfObjs) {
          var teammateAnswerObj = undefined; //undefined if a record for this teammate answering this survey question does not exist in the database (teammate didn't answer it yet)
          var teammateSurveyAnswerMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_c_teammates_surveys_answers, ["capture_id", "teammate_id", "question_id"], [openCaptureID, teammateObj.id, questionObj.id]);
          if(teammateSurveyAnswerMap !== undefined) {
            teammateAnswerObj = JSFUNC.obj_from_map(teammateSurveyAnswerMap);
            atLeast1TeammateHasAnswered1QuestionTF = true;
          }
          teammateAnswersArrayOfObjs.push(teammateAnswerObj);
        }
        questionResponseObj.teammateAnswersArrayOfObjs = teammateAnswersArrayOfObjs;

        var maxRating = 0;
        var maxAnswerColumnBg = undefined;
        if(typeIsYesNoTF) {
          maxAnswerColumnBg = this.teammates_survey_results_matrix_yn_color_from_rating(0);
          for(let teammateAnswerObj of teammateAnswersArrayOfObjs) {
            if(teammateAnswerObj !== undefined) {
              if(teammateAnswerObj.rating === 1) {
                maxRating = 1;
                maxAnswerColumnBg = this.teammates_survey_results_matrix_yn_color_from_rating(1);
                break;
              }
            }
          }

          totalStrengthPoints += (maxRating * 5); //for Y/N questions, a yes scores a 5 strength, a no scores 0
          totalMaxStrengthPoints += 5;
        }
        else if(typeIsScale05TF) {
          for(let teammateAnswerObj of teammateAnswersArrayOfObjs) {
            if(teammateAnswerObj !== undefined) {
              if(teammateAnswerObj.rating > maxRating) {
                maxRating = teammateAnswerObj.rating;
              }
            }
          }
          maxAnswerColumnBg = this.teammates_survey_results_matrix_scale05_color_from_rating(maxRating);

          totalStrengthPoints += maxRating; //for scale questions, the scale score 0-5 contributes towards strength
          totalMaxStrengthPoints += 5;
        }
        else if(typeIsTextResponseTF) {
          maxAnswerColumnBg = "fff";
        }
        questionResponseObj.maxAnswerColumnBg = maxAnswerColumnBg;

        //determine if this question will be drawn on the matrix based on the filter multiselect settings
        var includeQuestionRowTF = true;
        if(!allFilterOptionsSelectedTF) {
          if(typeIsYesNoTF) { //1 - yes, 2 - no
            if(maxRating === 1) { includeQuestionRowTF = JSFUNC.in_array(1, filterIDsArray); }
            else if(maxRating === 0) { includeQuestionRowTF = JSFUNC.in_array(2, filterIDsArray); }
          }
          else if(typeIsScale05TF) { //3-8 are scale options 5 through 0
            if(maxRating === 5) { includeQuestionRowTF = JSFUNC.in_array(3, filterIDsArray); }
            else if(maxRating === 4) { includeQuestionRowTF = JSFUNC.in_array(4, filterIDsArray); }
            else if(maxRating === 3) { includeQuestionRowTF = JSFUNC.in_array(5, filterIDsArray); }
            else if(maxRating === 2) { includeQuestionRowTF = JSFUNC.in_array(6, filterIDsArray); }
            else if(maxRating === 1) { includeQuestionRowTF = JSFUNC.in_array(7, filterIDsArray); }
            else if(maxRating === 0) { includeQuestionRowTF = JSFUNC.in_array(8, filterIDsArray); }
          }
          else if(typeIsTextResponseTF) {
            includeQuestionRowTF = JSFUNC.in_array(9, filterIDsArray); //9 - text responses
          }
        }

        if(includeQuestionRowTF) {
          questionResponsesArrayOfObjs.push(questionResponseObj);
        }
      }
    }

    //team strength calculations
    if(atLeast1TeammateHasAnswered1QuestionTF && (totalMaxStrengthPoints > 0)) {
      teamStrengthScore = ((totalStrengthPoints / totalMaxStrengthPoints) * 5);
      teamStrengthBgColor = this.teammates_survey_results_matrix_scale05_color_from_rating(teamStrengthScore);
    }

    return({
      allFilterOptionsSelectedTF: allFilterOptionsSelectedTF,
      usAndTeammatesArrayOfObjs: usAndTeammatesArrayOfObjs,
      questionResponsesArrayOfObjs: questionResponsesArrayOfObjs,
      atLeast1TeammateHasAnswered1QuestionTF: atLeast1TeammateHasAnswered1QuestionTF,
      teamStrengthScore: teamStrengthScore,
      teamStrengthBgColor: teamStrengthBgColor
    });
  }

  teammates_survey_results_matrix_yn_color_from_rating(i_rating01) {
    if(i_rating01 === 0) { return("d33"); }
    else if(i_rating01 === 1) { return("191"); }
    return(undefined);
  }

  teammates_survey_results_matrix_scale05_color_from_rating(i_rating0to5) {
    if((i_rating0to5 >= 0) && (i_rating0to5 <= 5)) {
      const teammatesSurveysScale05ColorsMap = DatabaseMobx.o_tbl_a_teammates_surveys_scale05_colors.get(1);
      if(teammatesSurveysScale05ColorsMap !== undefined) {
        const colorFieldDbName = "color" + Math.round(i_rating0to5);
        return(teammatesSurveysScale05ColorsMap.get(colorFieldDbName));
      }
      else {
        const hardcodedColorsArray = ["d77", "da7", "dd7", "ddd", "7bd", "77d"];
        return(hardcodedColorsArray[i_rating0to5]);
      }
    }
    return("fff");
  }

  determine_if_teammate_surveys_contact_persons_all_emails_valid_tf_from_teammate_obj(i_teammateObj) {
    if(i_teammateObj !== undefined) {
      if(JSFUNC.is_string(i_teammateObj.surveys_contact_person_ids_comma)) {
        return(this.determine_if_teammate_surveys_contact_persons_all_emails_valid_tf_from_ids_comma(i_teammateObj.surveys_contact_person_ids_comma));
      }
    }
    return(false);
  }

  determine_if_teammate_surveys_contact_persons_all_emails_valid_tf_from_ids_comma(i_surveyContactPersonIDsComma) {
    var teammateSurveysContactPersonsAllEmailsValidTF = false;
    const surveysContactPersonIDsArray = JSFUNC.convert_comma_list_to_int_array(i_surveyContactPersonIDsComma);
    if(surveysContactPersonIDsArray.length > 0) {
      teammateSurveysContactPersonsAllEmailsValidTF = true; //assume all emails are valid, if any one is found to be invalid, break the loop and return false
      for(let surveysContactPersonID of surveysContactPersonIDsArray) {
        var contactPersonObj = ContactsMobx.contact_person_obj_from_id(surveysContactPersonID);
        var contactPersonEmail = ContactsMobx.contact_person_email_from_contact_person_obj(contactPersonObj);
        var contactPersonEmailErrorMessage = JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(contactPersonEmail);
        if(contactPersonEmailErrorMessage !== undefined) {
          teammateSurveysContactPersonsAllEmailsValidTF = false;
          break;
        }
      }
    }
    return(teammateSurveysContactPersonsAllEmailsValidTF);
  }

  get c_teammatesSurveysResultsMatrixMultiSelectFilterFieldTypeObj() {
    const valueArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const displayArray = ["(Yes)", "(No)", "[5]", "[4]", "[3]", "[2]", "[1]", "[0]", "Text Responses"];
    const treeIDArray = ["", "", "", "", "", "", "", "", ""];

    const yn0 = this.teammates_survey_results_matrix_yn_color_from_rating(0);
    const yn1 = this.teammates_survey_results_matrix_yn_color_from_rating(1);
    const s0 = this.teammates_survey_results_matrix_scale05_color_from_rating(0);
    const s1 = this.teammates_survey_results_matrix_scale05_color_from_rating(1);
    const s2 = this.teammates_survey_results_matrix_scale05_color_from_rating(2);
    const s3 = this.teammates_survey_results_matrix_scale05_color_from_rating(3);
    const s4 = this.teammates_survey_results_matrix_scale05_color_from_rating(4);
    const s5 = this.teammates_survey_results_matrix_scale05_color_from_rating(5);
    const colorArray = [yn1, yn0, s5, s4, s3, s2, s1, s0, "fff"];

    const swsOptionsObj = {treeIDArray:treeIDArray, colorArray:colorArray, isMultiSelectTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Results Filter", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs() {
    var teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs = [];
    teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs.push(this.survey_response_color_obj_from_status_db_name("notsent"));
    teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs.push(this.survey_response_color_obj_from_status_db_name("sentnotopened"));
    teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs.push(this.survey_response_color_obj_from_status_db_name("sentopened"));
    teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs.push(this.survey_response_color_obj_from_status_db_name("completed"));
    teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs.push(this.survey_response_color_obj_from_status_db_name("notinterested"));
    teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs.push(this.survey_response_color_obj_from_status_db_name("sentexpired"));
    return(teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs);
  }








  //Competitors card
  get c_competitorsArrayOfObjs() {
    var competitorsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_competitors, "capture_id", this.o_openCaptureID, "sort", true);
    for(let competitorObj of competitorsArrayOfObjs) {
      competitorObj.contactCompanyObj = ContactsMobx.contact_company_or_person_obj_from_id(false, competitorObj.contact_company_id);
    }
    return(competitorsArrayOfObjs);
  }

  get c_competitorsExtraFieldsArrayOfObjs() {
    var expandedMapOfMaps = new Map();
    for(let [fieldIDKey, fieldMap] of DatabaseMobx.o_tbl_a_competitors_extra_fields) {
      var expandedFieldMap = DatabaseMobx.create_expanded_capture_field_map_from_capture_field_map(fieldMap);
      expandedMapOfMaps.set(fieldIDKey, expandedFieldMap);
    }
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(expandedMapOfMaps, "sort", true));
  }

  get c_competitorsContactCompanyIDsArray() { //used when adding a new competitor to know which contact companies have already been added
    var competitorsContactCompanyIDsArray = [];
    for(let competitorObj of this.c_competitorsArrayOfObjs) {
      competitorsContactCompanyIDsArray.push(competitorObj.contactCompanyObj.id);
    }
    return(competitorsContactCompanyIDsArray);
  }





  //Proposal Themes
  get c_ptDifferentiatorsArrayOfObjs() {
    var ptDifferentiatorsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_pt_differentiators, "capture_id", this.o_openCaptureID, "sort", true);
    for(let ptDifferentiatorObj of ptDifferentiatorsArrayOfObjs) {
      ptDifferentiatorObj.differentiatorName = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(ptDifferentiatorObj.differentiator_id, DatabaseMobx.c_selectAddPTDifferentiatorsFieldTypeObj);
    }
    return(ptDifferentiatorsArrayOfObjs);
  }

  get c_ptDifferentiatorIDsAlreadyAddedArray() {
    var differentiatorIDsAlreadyAddedArray = [];
    for(let differentiatorObj of this.c_ptDifferentiatorsArrayOfObjs) {
      differentiatorIDsAlreadyAddedArray.push(differentiatorObj.differentiator_id);
    }
    return(differentiatorIDsAlreadyAddedArray);
  }

  get c_ptWinThemesArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_pt_win_themes, "capture_id", this.o_openCaptureID, "sort", true));
  }

  get c_ptGhostThemesArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_pt_ghost_themes, "capture_id", this.o_openCaptureID, "sort", true));
  }




  //Risk Assessment
  get c_risksArrayOfObjs() {
    var risksArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_risks, "capture_id", this.o_openCaptureID, "sort", true);
    for(let riskObj of risksArrayOfObjs) {
      riskObj.categoryName = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(riskObj.risk_category_id, DatabaseMobx.c_selectRisksCategoriesFieldTypeObj);

      var probabilityRiskLevelNameColorObj = this.mask_risk_level_name_and_color_obj_from_id(riskObj.probability_risk_level_id, true);
      riskObj.probabilityRiskLevelName = probabilityRiskLevelNameColorObj.name;
      riskObj.probabilityRiskLevelColor = probabilityRiskLevelNameColorObj.color;

      var impactRiskLevelNameColorObj = this.mask_risk_level_name_and_color_obj_from_id(riskObj.impact_risk_level_id, false);
      riskObj.impactRiskLevelName = impactRiskLevelNameColorObj.name;
      riskObj.impactRiskLevelColor = impactRiskLevelNameColorObj.color;
    }
    return(risksArrayOfObjs);
  }

  mask_risk_level_name_and_color_obj_from_id(i_riskLevelID, i_probabilityTrueImpactFalse) {
    var name = undefined;
    var color = undefined;
    if(!JSFUNC.select_int_is_filled_out_tf(i_riskLevelID)) {
      name = "--No " + ((i_probabilityTrueImpactFalse) ? ("Probability") : ("Impact")) + " Risk Level Selected--";
      color = "aaaaaa";
    }
    else {
      const tblName = ((i_probabilityTrueImpactFalse) ? ("tbl_a_risks_probability_levels") : ("tbl_a_risks_impact_levels"));
      const riskLevelMap = DatabaseMobx.tbl_row_map_from_id(tblName, i_riskLevelID);
      name = riskLevelMap.get("name");
      color = riskLevelMap.get("color");
    }

    return({
      name: name,
      color: color
    })
  }




  //Budget card
  get c_budgetCaptureTypeBudgetCategoriesArrayOfObjs() {
    const captureTypeBudgetCategoryIDsComma = this.c_captureTypeMap.get("budget_category_ids_comma");
    const captureTypeBudgetCategoriesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_id_array_or_comma(DatabaseMobx.o_tbl_a_budget_categories_pool, captureTypeBudgetCategoryIDsComma);
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(captureTypeBudgetCategoriesMapOfMaps, "sort", true));
  }

  get c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs() {
    var captureTypeExpandedBudgetCategoriesArrayOfObjs = [];
    for(let budgetCategoryObj of this.c_budgetCaptureTypeBudgetCategoriesArrayOfObjs) {
      var expandedBudgetCategoryObj = BudgetMobx.expanded_budget_category_obj_from_budget_category_obj(this.o_openCaptureID, budgetCategoryObj, DatabaseMobx.o_tbl_c_budget_funding_requests, DatabaseMobx.o_tbl_c_budget_expenses);
      captureTypeExpandedBudgetCategoriesArrayOfObjs.push(expandedBudgetCategoryObj);
    }
    return(captureTypeExpandedBudgetCategoriesArrayOfObjs);
  }

  get c_budgetSelectedExpandedBudgetCategoryObj() {
    for(let budgetCategoryObj of this.c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs) {
      if(this.o_budgetSelectedBudgetCategoryID === budgetCategoryObj.id) {
        return(budgetCategoryObj);
      }
    }
    return(undefined);
  }

  get c_budgetSelectedBudgetCategoryFundingRequestsNr0ArrayOfObjs() {
    if(this.c_budgetSelectedExpandedBudgetCategoryObj !== undefined) {
      return(this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsNr0ArrayOfObjs);
    }
    return([]);
  }

  get c_budgetSelectedBudgetCategoryFundingRequestsRq1ArrayOfObjs() {
    if(this.c_budgetSelectedExpandedBudgetCategoryObj !== undefined) {
      return(this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsRq1ArrayOfObjs);
    }
    return([]);
  }

  get c_budgetSelectedBudgetCategoryFundingRequestsRj2ArrayOfObjs() {
    if(this.c_budgetSelectedExpandedBudgetCategoryObj !== undefined) {
      return(this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsRj2ArrayOfObjs);
    }
    return([]);
  }

  get c_budgetSelectedBudgetCategoryFundingRequestsRs3ArrayOfObjs() {
    if(this.c_budgetSelectedExpandedBudgetCategoryObj !== undefined) {
      return(this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsRs3ArrayOfObjs);
    }
    return([]);
  }

  get c_budgetSelectedBudgetCategoryFundingRequestsAp4ArrayOfObjs() {
    if(this.c_budgetSelectedExpandedBudgetCategoryObj !== undefined) {
      return(this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsAp4ArrayOfObjs);
    }
    return([]);
  }

  get c_budgetEditingFundingRequestObj() {
    if(this.c_budgetSelectedExpandedBudgetCategoryObj !== undefined) {
      const allFundingRequestsArrayOfObjs = [].concat(
        this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsNr0ArrayOfObjs,
        this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsRq1ArrayOfObjs,
        this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsRj2ArrayOfObjs,
        this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsRs3ArrayOfObjs,
        this.c_budgetSelectedExpandedBudgetCategoryObj.fundingRequestsAp4ArrayOfObjs
      );

      for(let fundingRequestObj of allFundingRequestsArrayOfObjs) {
        if(fundingRequestObj.id === this.o_budgetEditingFundingRequestID) {
          return(fundingRequestObj);
        }
      }
    }
    return(undefined);
  }





  //Conversations card
  get c_conversationsArrayOfObjs() {
    const nowDateTimeUtc = JSFUNC.now_datetime_utc();
    var conversationsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_conversations, "capture_id", this.o_openCaptureID, undefined, undefined);
    for(let conversationObj of conversationsArrayOfObjs) {
      conversationObj.dateTimeIsSetTF = JSFUNC.datetime_is_filled_out_tf(conversationObj.datetime_utc);
      conversationObj.dateTimeIsUpcomingTF = (conversationObj.datetime_utc >= nowDateTimeUtc);
      conversationObj.dateTimeMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(conversationObj.datetime_utc, DatabaseMobx.c_genericDateTimeDayMdyDaysUntil1FieldTypeObj);
      conversationObj.contactPersonNamesComma = DatabaseMobx.value_mask_no_click_links_from_value_raw_and_field_type_obj(conversationObj.contact_person_ids_comma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj);
    }
    return(conversationsArrayOfObjs);
  }
  get c_upcomingConversationsArrayOfObjs() { //includes today and future
    const filterFunction = (i_conversationObj) => { return(i_conversationObj.dateTimeIsSetTF && i_conversationObj.dateTimeIsUpcomingTF); };
    var upcomingConversationsArrayOfObjs = this.c_conversationsArrayOfObjs.filter(filterFunction);
    JSFUNC.sort_arrayOfObjs(upcomingConversationsArrayOfObjs, "datetime_utc", true); //sort the dates ASC so that the date closest to today is on top, then going into the future
    return(upcomingConversationsArrayOfObjs);
  }
  get c_notsetConversationsArrayOfObjs() { //includes yesterday and previous
    const filterFunction = (i_conversationObj) => { return(!i_conversationObj.dateTimeIsSetTF); };
    var notsetConversationsArrayOfObjs = this.c_conversationsArrayOfObjs.filter(filterFunction);
    JSFUNC.sort_arrayOfObjs(notsetConversationsArrayOfObjs, "id", false); //sort desc id so most recently created are on top
    return(notsetConversationsArrayOfObjs);
  }
  get c_pastConversationsArrayOfObjs() { //includes yesterday and previous
    const filterFunction = (i_conversationObj) => { return(i_conversationObj.dateTimeIsSetTF && !i_conversationObj.dateTimeIsUpcomingTF); };
    var pastConversationsArrayOfObjs = this.c_conversationsArrayOfObjs.filter(filterFunction);
    JSFUNC.sort_arrayOfObjs(pastConversationsArrayOfObjs, "datetime_utc", false); //sort the dates ASC so that the date closest to today is on top, then going into the future
    return(pastConversationsArrayOfObjs);
  }




  //Documents Card
  get c_documentsFileFolderSystemMapOfMaps() {
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_c_documents_filefoldersystem, "capture_id", this.o_openCaptureID));
  }
  get c_documentsAllFilesMapOfMaps() {
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.c_documentsFileFolderSystemMapOfMaps, "folder0_file1", 1));
  }
  get c_documentsNumFiles() {
    return(this.c_documentsAllFilesMapOfMaps.size);
  }




  //Templates Card
  get c_singleCaptureTemplatesWithinFoldersObj() {
    return(DatabaseMobx.generate_templates_within_folders_obj_from_admin_templates_ffs_tbl_name("tbl_a_single_capture_templates_filefoldersystem", "Templates"));
  }



  //IDIQ/Task Orders Card
  get c_idiqTaskOrdersObj() {
    const c_contractTypeMap = this.c_contractTypeMap
    const c_contractTypeIsIDIQTF = this.c_contractTypeIsIDIQTF
    const c_contractTypeIsTaskOrderTF = this.c_contractTypeIsTaskOrderTF;

    var idiqTaskOrdersObj = {
      isIDIQContractTF: c_contractTypeIsIDIQTF,
      isTaskOrderContractTF: c_contractTypeIsTaskOrderTF,
      contractTypeName: this.c_contractTypeMap.get("name"),
      idiqObj: undefined,
      taskOrdersArrayOfObjs: [],
      taskOrdersContractValueRawTotal: 0,
      wonTaskOrdersContractValueRawTotal: 0
    };

    var idiqCaptureID = undefined;
    if(c_contractTypeIsIDIQTF) { //this open capture is an IDIQ
      idiqCaptureID = this.o_openCaptureID;
    }
    else if(c_contractTypeIsTaskOrderTF) {
      idiqCaptureID = this.c_idiqCaptureIDTOLink;
    }

    if(idiqCaptureID !== undefined) {
      idiqTaskOrdersObj.idiqObj = this.create_idiq_task_orders_capture_obj(idiqCaptureID);

      //load task orders for this IDIQ
      var taskOrdersArrayOfObjs = [];
      const capturesTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_captures");
      for(let captureMap of capturesTblRef.values()) {
        var captureContractTypeID = captureMap.get("contract_type_id");
        var captureIDIQCaptureID = captureMap.get("idiq_capture_id_TO_link");
        if(JSFUNC.in_array(captureContractTypeID, DatabaseMobx.c_taskOrderContractTypeIDsArray) && (captureIDIQCaptureID === idiqCaptureID)) { //if this capture is a task order
          var captureID = captureMap.get("id");
          var taskOrderCaptureObj = this.create_idiq_task_orders_capture_obj(captureID);
          taskOrdersArrayOfObjs.push(taskOrderCaptureObj);

          idiqTaskOrdersObj.taskOrdersContractValueRawTotal += taskOrderCaptureObj.contractValueRaw;

          if(taskOrderCaptureObj.stageIsWonTF) {
            idiqTaskOrdersObj.wonTaskOrdersContractValueRawTotal += taskOrderCaptureObj.contractValueRaw;
          }
        }
      }

      JSFUNC.sort_arrayOfObjs(taskOrdersArrayOfObjs, ["stageSort", "contractValueRaw"], [true, false]); //sort the Task Orders by Stage, then COV, highest first
      idiqTaskOrdersObj.taskOrdersArrayOfObjs = taskOrdersArrayOfObjs;
    }

    return(idiqTaskOrdersObj);
  }


  create_idiq_task_orders_capture_obj(i_captureID) {
    var captureObj = {
      isCurrentlyOpenCaptureTF: (i_captureID === this.o_openCaptureID),
      id: i_captureID,
      captureFullName: DatabaseMobx.capture_name_plaintext_from_capture_id(i_captureID),
      contractValueRaw: undefined,
      contractValue: "",
      stageName: "",
      stageSort: 100,
      stageIsWonTF: false,
      captureTeam: "",
      divisionOwners: "",
      anticipatedValueRaw: undefined,
      anticipatedValueMask: "",
      anticipatedValueMaskPlainText: ""
    };

    const captureMap = DatabaseMobx.o_tbl_captures.get(i_captureID);
    if(captureMap !== undefined) {
      //capture contract value, stage, owners
      const covValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfContractOverallValue);
      const stageValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfStage);
      const captureManagersValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfCaptureManagers);
      const divisionOwnersValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfDivisionOwners);
      const idiqTOAnticipatedValueValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfIDIQTOAnticipatedValue);

      var stageSort = 100;
      var stageIsWonTF = false;
      if(JSFUNC.in_array(stageValueMaskSortIfoCanEditObj.valueRaw, DatabaseMobx.c_closedWonStageIDsArray)) {
        stageSort = 1000000;
        stageIsWonTF = true;
      }
      else if(JSFUNC.in_array(stageValueMaskSortIfoCanEditObj.valueRaw, DatabaseMobx.c_activeStageIDsArray)) {
        var captureTypeStageSort = 1000;
        const captureTypeID = captureMap.get("capture_type_id");
        var captureTypeMap = DatabaseMobx.o_tbl_a_capture_types.get(captureTypeID);
        if(captureTypeMap !== undefined) {
          var captureTypeStageIDsComma = captureTypeMap.get("stage_ids_comma");
          var captureTypeStageIDsArray = JSFUNC.convert_comma_list_to_int_array(captureTypeStageIDsComma);
          var stageCounter = 1;
          for(let stageID of captureTypeStageIDsArray) {
            if(stageValueMaskSortIfoCanEditObj.valueRaw === stageID) {
              captureTypeStageSort = stageCounter;
              break;
            }
            stageCounter++;
          }
        }
        stageSort = (2000000 - captureTypeStageSort);
      }
      else if(JSFUNC.in_array(stageValueMaskSortIfoCanEditObj.valueRaw, DatabaseMobx.c_closedLostStageIDsArray)) {
        stageSort = 3000000;
      }
      else if(JSFUNC.in_array(stageValueMaskSortIfoCanEditObj.valueRaw, DatabaseMobx.c_closedNoBidStageIDsArray)) {
        stageSort = 4000000;
      }
      else if(JSFUNC.in_array(stageValueMaskSortIfoCanEditObj.valueRaw, DatabaseMobx.c_closedCancelledStageIDsArray)) {
        stageSort = 5000000;
      }

      captureObj.contractValueRaw = covValueMaskSortIfoCanEditObj.valueRaw;
      captureObj.contractValue = covValueMaskSortIfoCanEditObj.valueMask;
      captureObj.stageName = stageValueMaskSortIfoCanEditObj.valueMask;
      captureObj.stageSort = stageSort;
      captureObj.stageIsWonTF = stageIsWonTF;
      captureObj.captureTeam = captureManagersValueMaskSortIfoCanEditObj.valueMask;
      captureObj.divisionOwners = divisionOwnersValueMaskSortIfoCanEditObj.valueMask;
      captureObj.anticipatedValueRaw = idiqTOAnticipatedValueValueMaskSortIfoCanEditObj.valueRaw;
      captureObj.anticipatedValueMask = idiqTOAnticipatedValueValueMaskSortIfoCanEditObj.valueMask;
      captureObj.anticipatedValueMaskPlainText = idiqTOAnticipatedValueValueMaskSortIfoCanEditObj.valueMaskPlainText;
    }

    return(captureObj);
  }




  //Change Log card
  get c_combinedChangeLogsArrayOfObjs() { //combines tbl_c_log_details and tbl_c_log_shaping for this capture and sorts by date with newest on top
    var combinedChangeLogsArrayOfObjs = [];

    //details change log from tbl_c_log_details
    for(let logDetailsMap of DatabaseMobx.o_tbl_c_log_details.values()) {
      var cardIDInt = JSFUNC.str2int(logDetailsMap.get("card_id"));
      var dateTimeUtc = logDetailsMap.get("datetime_utc");
      changeLogObj = {
        key: "d" + logDetailsMap.get("id"),
        id: logDetailsMap.get("id"),
        dateTimeUtc: dateTimeUtc,
        dateLocalYmd: JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(dateTimeUtc),
        dateTimeLocalMask: DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(dateTimeUtc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj),
        detailsCardID: cardIDInt,
        detailsCardName: DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(cardIDInt, DatabaseMobx.c_selectMultiChangelogCaptureCardsFieldTypeObj),
        fieldID: JSFUNC.str2int(logDetailsMap.get("field_id")),
        field: logDetailsMap.get("field"),
        value: logDetailsMap.get("value"),
        userID: logDetailsMap.get("user_id"),
        user: logDetailsMap.get("user")
      };
      combinedChangeLogsArrayOfObjs.push(changeLogObj);
    }

    //stages change log from tbl_c_log_stages
    const stageFieldID = DatabaseMobx.c_fieldMapOfStage.get("id");
    const changelogStageFieldDisplay = "Changed " + DatabaseMobx.c_fieldMapOfStage.get("display_name");
    for(let logStagesMap of DatabaseMobx.o_tbl_c_log_stages.values()) {
      var dateTimeUtc = logStagesMap.get("datetime_utc");
      changeLogObj = {
        key: "s" + logStagesMap.get("id"),
        id: logStagesMap.get("id"),
        dateTimeUtc: dateTimeUtc,
        dateLocalYmd: JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(dateTimeUtc),
        dateTimeLocalMask: DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(dateTimeUtc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj),
        detailsCardID: DatabaseMobx.k_cardIDAdvanceStage,
        detailsCardName: DatabaseMobx.c_cardNameAdvanceStage,
        fieldID: stageFieldID,
        field: changelogStageFieldDisplay,
        value: logStagesMap.get("stage_name"),
        userID: logStagesMap.get("user_id"),
        user: logStagesMap.get("user")
      };
      combinedChangeLogsArrayOfObjs.push(changeLogObj);
    }

    //pwin change log from tbl_c_log_pwin
    const pwinFieldID = DatabaseMobx.c_fieldMapOfPwin.get("id");
    for(let logPwinMap of DatabaseMobx.o_tbl_c_log_pwin.values()) {
      var dateTimeUtc = logPwinMap.get("datetime_utc");
      changeLogObj = {
        key: "p" + logPwinMap.get("id"),
        id: logPwinMap.get("id"),
        dateTimeUtc: dateTimeUtc,
        dateLocalYmd: JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(dateTimeUtc),
        dateTimeLocalMask: DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(dateTimeUtc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj),
        detailsCardID: DatabaseMobx.k_cardIDDealShaping,
        detailsCardName: "PWin",
        fieldID: pwinFieldID,
        field: ((logPwinMap.get("is_calc_01") === 1) ? ("Calculated PWin") : ("Manual PWin Change")),
        value: logPwinMap.get("pwin") + "%",
        userID: logPwinMap.get("user_id"),
        user: logPwinMap.get("user")
      };
      combinedChangeLogsArrayOfObjs.push(changeLogObj);
    }

    //deal shaping change log from tbl_c_log_shaping
    var changeLogObj = undefined;
    for(let logDealShapingMap of DatabaseMobx.o_tbl_c_log_shaping.values()) {
      var dateTimeUtc = logDealShapingMap.get("datetime_utc");
      changeLogObj = {
        key: "ds" + logDealShapingMap.get("id"),
        id: logDealShapingMap.get("id"),
        dateTimeUtc: dateTimeUtc,
        dateLocalYmd: JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(dateTimeUtc),
        dateTimeLocalMask: DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(dateTimeUtc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj),
        detailsCardID: DatabaseMobx.k_cardIDDealShaping,
        detailsCardName: DatabaseMobx.c_cardNameDealShaping,
        fieldID: undefined,
        field: logDealShapingMap.get("question"),
        value: logDealShapingMap.get("answer"),
        userID: logDealShapingMap.get("user_id"),
        user: logDealShapingMap.get("user")
      };
      combinedChangeLogsArrayOfObjs.push(changeLogObj);
    }

    //budget change log from tbl_c_log_budget
    for(let logBudgetMap of DatabaseMobx.o_tbl_c_log_budget.values()) {
      var dateTimeUtc = logBudgetMap.get("datetime_utc");
      changeLogObj = {
        key: "b" + logBudgetMap.get("id"),
        id: logBudgetMap.get("id"),
        dateTimeUtc: dateTimeUtc,
        dateLocalYmd: JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(dateTimeUtc),
        dateTimeLocalMask: DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(dateTimeUtc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj),
        detailsCardID: DatabaseMobx.k_cardIDBudget,
        detailsCardName: DatabaseMobx.c_cardNameBudget,
        fieldID: undefined,
        field: logBudgetMap.get("field"),
        value: logBudgetMap.get("value"),
        userID: logBudgetMap.get("user_id"),
        user: logBudgetMap.get("user")
      };
      combinedChangeLogsArrayOfObjs.push(changeLogObj);
    }

    //teammate contracts status change log from tbl_c_log_teammate_contracts
    for(let logTeammateContractsMap of DatabaseMobx.o_tbl_c_log_teammate_contracts.values()) {
      var dateTimeUtc = logTeammateContractsMap.get("datetime_utc");
      const teammateMap = DatabaseMobx.tbl_row_map_from_id("tbl_c_teammates", logTeammateContractsMap.get("teammate_id"));
      const teammateContactCompanyName = ContactsMobx.contact_name_from_id(false, teammateMap.get("contact_company_id"));
      const contractTypeMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_types", logTeammateContractsMap.get("contract_type_id"));
      const valueString = "[" + contractTypeMap.get("short_name") + " for teammate '" + teammateContactCompanyName + "'] ";
      changeLogObj = {
        key: "tc" + logTeammateContractsMap.get("id"),
        id: logTeammateContractsMap.get("id"),
        dateTimeUtc: dateTimeUtc,
        dateLocalYmd: JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(dateTimeUtc),
        dateTimeLocalMask: DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(dateTimeUtc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj),
        detailsCardID: DatabaseMobx.k_cardIDTeammates,
        detailsCardName: DatabaseMobx.c_cardNameTeammates,
        fieldID: undefined,
        field: logTeammateContractsMap.get("field"),
        value: valueString + logTeammateContractsMap.get("value"),
        userID: logTeammateContractsMap.get("user_id"),
        user: logTeammateContractsMap.get("user")
      };
      combinedChangeLogsArrayOfObjs.push(changeLogObj);
    }

    return(combinedChangeLogsArrayOfObjs);
  }

  get c_changelogFilteredSortedItemsArrayOfObjs() {
    const o_changelogFilterCaptureCardIDsComma = this.o_changelogFilterCaptureCardIDsComma;
    const o_changelogFilterCaptureFieldID = this.o_changelogFilterCaptureFieldID;
    const o_changelogFilterUserID = this.o_changelogFilterUserID;
    const o_changelogFilterDateMin = this.o_changelogFilterDateMin;
    const o_changelogFilterDateMax = this.o_changelogFilterDateMax;
    const o_changelogSortColumnDbName = this.o_changelogSortColumnDbName;
    const o_changelogSortIsAscTF = this.o_changelogSortIsAscTF;
    const c_combinedChangeLogsArrayOfObjs = this.c_combinedChangeLogsArrayOfObjs;

    const filterCaptureCardIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_changelogFilterCaptureCardIDsComma);
    const filterCaptureFieldIsFilledOutTF = JSFUNC.select_int_is_filled_out_tf(o_changelogFilterCaptureFieldID);
    const filterUserIsFilledOutTF = JSFUNC.select_int_is_filled_out_tf(o_changelogFilterUserID);
    const filterDateMinIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_changelogFilterDateMin);
    const filterDateMaxIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_changelogFilterDateMax);

    const changelogFilterCaptureCardIDsArray = JSFUNC.convert_comma_list_to_int_array(o_changelogFilterCaptureCardIDsComma);

    //filter the changelog items
    var changelogFilteredSortedItemsArrayOfObjs = [];
    for(let changelogObj of c_combinedChangeLogsArrayOfObjs) {
      var includeChangelogItemTF = true;
      if(includeChangelogItemTF && filterCaptureCardIsFilledOutTF && !JSFUNC.in_array(changelogObj.detailsCardID, changelogFilterCaptureCardIDsArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterCaptureFieldIsFilledOutTF && (changelogObj.fieldID !== o_changelogFilterCaptureFieldID)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterUserIsFilledOutTF && (changelogObj.userID !== o_changelogFilterUserID)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterDateMinIsFilledOutTF && (changelogObj.dateLocalYmd < o_changelogFilterDateMin)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterDateMaxIsFilledOutTF && (changelogObj.dateLocalYmd > o_changelogFilterDateMax)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF) {
        changelogFilteredSortedItemsArrayOfObjs.push(changelogObj);
      }
    }

    //sort the changelog items
    var sortColumnDbNamesArray = [o_changelogSortColumnDbName];
    var sortIsAscTFArray = [o_changelogSortIsAscTF];
    if(sortColumnDbNamesArray !== "dateTimeUtc") { //if sorting by a column other than date
      sortColumnDbNamesArray.push("dateTimeUtc"); //do a secondary sort by date desc (so that sorting by field groups all fields together sorted by latest on top)
      sortIsAscTFArray.push(false);
      sortColumnDbNamesArray.push("id"); //tertiary sort on log id
      sortIsAscTFArray.push(false);
    }
    else { //primary sort is dateTimeUtc, add a secondary sort in the same direction for id
      sortColumnDbNamesArray.push("id");
      sortIsAscTFArray.push(o_changelogSortIsAscTF);
    }
    JSFUNC.sort_arrayOfObjs(changelogFilteredSortedItemsArrayOfObjs, sortColumnDbNamesArray, sortIsAscTFArray);

    return(changelogFilteredSortedItemsArrayOfObjs);
  }

  get c_changelogFilteredTotalNumItems() {
    return(this.c_changelogFilteredSortedItemsArrayOfObjs.length);
  }

  get c_changelogCurrentPageFirstItemNumber() {
    if(this.c_changelogFilteredTotalNumItems === 0) {
      return(0);
    }
    return(((this.o_changelogCurrentPageNumber - 1) * this.o_changelogNumItemsPerPage) + 1);
  }

  get c_changelogCurrentPageLastItemNumber() {
    var changelogCurrentPageLastItemNumber = (this.o_changelogCurrentPageNumber * this.o_changelogNumItemsPerPage);
    if(this.c_changelogFilteredTotalNumItems < changelogCurrentPageLastItemNumber) {
      changelogCurrentPageLastItemNumber = this.c_changelogFilteredTotalNumItems;
    }
    return(changelogCurrentPageLastItemNumber);
  }

  get c_changelogCanIncrementCurrentPageNumberTF() {
    return(this.c_changelogCurrentPageLastItemNumber < this.c_changelogFilteredTotalNumItems);
  }

  get c_changelogCanDecrementCurrentPageNumberTF() {
    return(this.o_changelogCurrentPageNumber > 1);
  }

  get c_changelogFilteredSortedItemsOnSelectedPageArrayOfObjs() {
    const c_changelogFilteredSortedItemsArrayOfObjs = this.c_changelogFilteredSortedItemsArrayOfObjs;
    const c_changelogCurrentPageFirstItemNumber = this.c_changelogCurrentPageFirstItemNumber;
    const c_changelogCurrentPageLastItemNumber = this.c_changelogCurrentPageLastItemNumber;

    var changelogFilteredSortedItemsOnSelectedPageArrayOfObjs = [];
    var itemNumber = 1;
    for(let changelogObj of c_changelogFilteredSortedItemsArrayOfObjs) {
      if((itemNumber >= c_changelogCurrentPageFirstItemNumber) && (itemNumber <= c_changelogCurrentPageLastItemNumber)) {
        changelogFilteredSortedItemsOnSelectedPageArrayOfObjs.push(changelogObj);
      }
      itemNumber++;
    }
    return(changelogFilteredSortedItemsOnSelectedPageArrayOfObjs);
  }

  get c_changelogSelectUserIDsToNotIncludeArray() {
    const c_combinedChangeLogsArrayOfObjs = this.c_combinedChangeLogsArrayOfObjs;
    const c_valueDisplayArraysObjUsers = DatabaseMobx.c_valueDisplayArraysObjUsers;
    const o_userID = UserMobx.o_userID;

    var loggedInUserAndUserIDsInLogsArray = [o_userID]; //always have at least the logged in user as an option in the user filter
    for(let changeLogObj of c_combinedChangeLogsArrayOfObjs) {
      if(!JSFUNC.in_array(changeLogObj.userID, loggedInUserAndUserIDsInLogsArray)) {
        loggedInUserAndUserIDsInLogsArray.push(changeLogObj.userID);
      }
    }

    return(JSFUNC.remove_all_values_from_array(loggedInUserAndUserIDsInLogsArray, c_valueDisplayArraysObjUsers.valueArray));
  }




  //Notepad card
  get c_notepadNoteStampsArrayOfObjs() {
    const o_openCaptureID = this.o_openCaptureID;
    return(this.compute_expanded_sorted_note_stamps_arrayOfObjs_from_capture_id(o_openCaptureID));
  }

  compute_expanded_sorted_note_stamps_arrayOfObjs_from_capture_id(i_captureID) {
    //get sorted note stamp objs from only this i_captureID
    var expandedSortedNoteStampsArrayOfObjs = DatabaseMobx.capture_sorted_pinned_then_most_recent_created_note_stamps_arrayOfObjs_from_capture_id(i_captureID);
    
    //loop through and expand the obj with calculated new fields
    for(let noteStampObj of expandedSortedNoteStampsArrayOfObjs) {
      noteStampObj.pinnedTF = (noteStampObj.pinned_01 === 1);
      noteStampObj.pinnedSortNumberOrUndefined = ((noteStampObj.pinnedTF) ? (noteStampObj.sort) : (undefined));

      noteStampObj.createdDateLocalMask = DatabaseMobx.get_company_date_format_from_Ymdhis_datetimeutc_natural(noteStampObj.created_datetime_utc);
      noteStampObj.createdDateTimeLocalMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(noteStampObj.created_datetime_utc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj);
      noteStampObj.createdByUserNameMaskPlainText = DatabaseMobx.user_name_mask_plaintext_from_user_id_and_alt_user_name_if_not_filled_out(noteStampObj.created_by_user_id, noteStampObj.created_by_user_name);

      noteStampObj.lastEditedDateLocalMask = DatabaseMobx.get_company_date_format_from_Ymdhis_datetimeutc_natural(noteStampObj.last_edited_datetime_utc);
      noteStampObj.lastEditedDateTimeLocalMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(noteStampObj.last_edited_datetime_utc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj);
      noteStampObj.lastEditedByUserNameMaskPlainText = DatabaseMobx.user_name_mask_plaintext_from_user_id_and_alt_user_name_if_not_filled_out(noteStampObj.last_edited_by_user_id, noteStampObj.last_edited_by_user_name);
      
      noteStampObj.createdDateTimeByUserNameInfoPlainText = "Created " + noteStampObj.createdDateTimeLocalMaskPlainText + " by " + noteStampObj.createdByUserNameMaskPlainText;
      noteStampObj.lastEditedDateTimeByUserNameInfoPlainText = "Last Edited " + noteStampObj.lastEditedDateTimeLocalMaskPlainText + " by " + noteStampObj.lastEditedByUserNameMaskPlainText;

      noteStampObj.titleIsFilledOutTF = JSFUNC.string_is_filled_out_tf(noteStampObj.title);

      var infoBlockPlainText = noteStampObj.createdDateTimeByUserNameInfoPlainText;
      if(noteStampObj.titleIsFilledOutTF) {
        infoBlockPlainText += "\nTitle: " + noteStampObj.title;
      }
      infoBlockPlainText += "\n'" + noteStampObj.body + "'";
      noteStampObj.infoBlockPlainText = infoBlockPlainText;
    }

    return(expandedSortedNoteStampsArrayOfObjs);
  }






  //===action methods==============================================================================================
  a_open_single_capture(i_captureID, i_functionOnSuccess=undefined) {
    if(this.o_openCaptureID === i_captureID) { //no reason to load everything if the capture selected is already open (if the capture is open and you pick the same capture from Search Captures)
      if(i_functionOnSuccess !== undefined) {
        i_functionOnSuccess();
      }
      return;
    }

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "open_single_capture", ["i_captureID"], [i_captureID]);

    //capture does not exist locally, alert the user with a message
    if(!DatabaseMobx.o_tbl_captures.has(i_captureID)) {
      const captureDoesNotExistLocallyMessage = "This Capture (ID: " + i_captureID + ") was not successfully loaded from the database. This capture may have been recently deleted. If this message persists after logging out and back in, please submit a ticket noting which capture was affected.";
      JSPHP.record_z_error(jsDescription, captureDoesNotExistLocallyMessage);
      alert(captureDoesNotExistLocallyMessage);
      return;
    }

    //first, if a capture was already open, clear all local observable variable settings so that the new capture does not briefly show the previous capture's data inside of it
    this.a_clear_single_capture_from_local_memory();

    //append this captureID to the recently visited captures list (don't do this if the ghost login key was used)
    var updatedRecentlyVisitedCaptureIDsComma = ""; //"" is a flag in single_capture_load_supplementary_data php call to not update the recently visited captures
    if(!UserMobx.o_loginWithKeyTF) {
      //compute the list of recently visited captures, adding this newly opened capture to the front
      updatedRecentlyVisitedCaptureIDsComma = UserMobx.compute_updated_recently_visited_capture_ids_comma(i_captureID);

      //save the new recently viewed captureIDs list to local memory (single_capture_load_supplementary_data() handles updating this field in the database)
      const updatedUserPerEmailRowObj = {
        id: UserMobx.o_userPerEmailID,
        recently_visited_capture_ids_comma: updatedRecentlyVisitedCaptureIDsComma
      };
      const clearOldMapDataFirstTF = false;
      DatabaseMobx.a_insert_or_update_local_data_map("tbl_a_users_per_email", updatedUserPerEmailRowObj, clearOldMapDataFirstTF, jsDescription);
    }

    //call database_api "openSingleCaptureLoadSupplementaryData" to save the recently visited captureIDs, save which captureID you currently have open, and to fetch all the supplemental capture data tables except for the changelogs
    JSPHP.single_capture_load_supplementary_data(i_captureID, updatedRecentlyVisitedCaptureIDsComma, i_functionOnSuccess);

    //set the new capture as the open captureID (happens immediately instead of after successful supplementary data load so that the capture opens instantly and other card data loads just after)
    this.a_set_open_capture_id(i_captureID);
  }

  a_set_open_capture_id(i_captureID) {
    this.o_openCaptureID = i_captureID;
  }

  a_close_single_capture() {
    if(this.o_openCaptureID > 0) { //only need to shut the capture if one is currently open
      //clear all openCardIDs, items currently editing, and supplemental data tables from local memory
      this.a_clear_single_capture_from_local_memory();

      //update database user setting of which captureID you currently have open to 0 (0 is the flag meaning no capture is currently open)
      if(!UserMobx.o_loginWithKeyTF) { //don't reset this if using ghost login key
        const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_close_single_capture", [], []);
        const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
        C_CallPhpTblUID.add_update("tbl_a_users_per_email", UserMobx.o_userPerEmailID, "currently_open_capture_id", 0, "i");
        C_CallPhpTblUID.execute();
      }
    }
  }

  a_clear_single_capture_from_local_memory() {
    //close any fields currently open for editing (so that they are not still open when a different capture is opened)
    CaptureExecMobx.a_close_item_editing();

    this.o_openCaptureID = undefined; //reset the open captureID to undefined, meaning no capture is currently open
    this.o_openCardLeftID = undefined;
    this.o_openCardRightID = undefined;
    this.o_openCardFullID = undefined;
    this.o_userPerEmailIDsWithSameCaptureOpenComma = undefined;
    this.o_tasksMobileShowingActiveTF = true;
    this.o_dealShapingAppliedTagIDsArray = [];
    this.o_teammatesLeftTabSelected = "teammateSelection";
    this.o_teammatesExpandedTeammateID = undefined;
    this.o_teammatesSurveysSelectedSurveyID = undefined;
    this.o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab = "surveyResultsMatrix";
    this.o_teammatesFloatingBoxSurveyTeammateID = undefined;
    this.o_teammatesFloatingBoxSurveySurveyID = undefined;
    this.o_competitorExpandedID = undefined;
    this.o_ptDifferentiatorIDAddingOrEditing = undefined;
    this.o_ptWinThemeIDAddingOrEditing = undefined;
    this.o_ptGhostThemeIDAddingOrEditing = undefined;
    this.o_budgetSelectedBudgetCategoryID = undefined;
    this.o_budgetMobilePage = undefined;
    this.o_budgetEditingFundingRequestID = undefined;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_clear_single_capture_from_local_memory", [], []);

    //clear capture tables from memory (do not clear the tbl_u_tasks tbl)
    const openCaptureTblNamesToLoadWithoutTasksTblArray = JSFUNC.remove_all_values_from_array("tbl_u_tasks", DatabaseMobx.c_openCaptureTblNamesToLoadArray);
    DatabaseMobx.a_clear_local_tbls_from_tbl_names_array(openCaptureTblNamesToLoadWithoutTasksTblArray, jsDescription);

    //clear the capture changelog entries from local memory
    DatabaseMobx.a_clear_local_tbls_from_tbl_names_array(DatabaseMobx.c_openCaptureChangeLogCardTblNamesToLoadArray, jsDescription);
  }

  a_launch_card_left(i_cardID) {
    if(i_cardID !== this.o_openCardLeftID) { //no need for any action if the card requested is already in the left spot
      if(i_cardID !== this.o_openCardRightID && i_cardID !== this.o_openCardFullID) {
        this.a_run_when_card_that_wasnt_open_is_opened(i_cardID);
      }

      if(this.o_openCardRightID === i_cardID) {
        this.o_openCardRightID = undefined; //if this card was in the right spot, make that spot available and move the card to the left
      }
      this.o_openCardFullID = undefined; //if the card being sent to the left is currently full open, empty it from the full spot
      this.o_openCardLeftID = i_cardID;
    }
  }

  a_launch_card_right(i_cardID) {
    if(i_cardID !== this.o_openCardRightID) { //no need for any action if the card requested is already in the right spot
      if(i_cardID !== this.o_openCardLeftID && i_cardID !== this.o_openCardFullID) {
        this.a_run_when_card_that_wasnt_open_is_opened(i_cardID);
      }

      if(this.o_openCardLeftID === i_cardID) {
        this.o_openCardLeftID = undefined; //if this card was in the left spot, make that spot available and move the card to the right
      }
      this.o_openCardFullID = undefined; //if the card being sent to the right is currently full open, empty it from the full spot
      this.o_openCardRightID = i_cardID;
    }
  }

  a_launch_card_full(i_cardID) {
    if(i_cardID !== this.o_openCardFullID) { //no need for any action if the card requested is already in the full spot
      if(i_cardID !== this.o_openCardLeftID && i_cardID !== this.o_openCardRightID) {
        this.a_run_when_card_that_wasnt_open_is_opened(i_cardID);
      }

      this.o_openCardLeftID = undefined;
      this.o_openCardRightID = undefined;
      this.o_openCardFullID = i_cardID;
    }
  }

  a_close_card_left() {
    const cardLeftID = this.o_openCardLeftID;
    this.o_openCardLeftID = undefined;
    this.a_run_when_card_is_closed(cardLeftID);
  }
  a_close_card_right() {
    const cardRightID = this.o_openCardRightID;
    this.o_openCardRightID = undefined;
    this.a_run_when_card_is_closed(cardRightID);
  }
  a_close_card_full() {
    const cardFullID = this.o_openCardFullID;
    this.o_openCardFullID = undefined;
    this.a_run_when_card_is_closed(cardFullID);
  }

  a_run_when_card_that_wasnt_open_is_opened(i_cardID) {
    const openCaptureID = this.o_openCaptureID;

    if(i_cardID === DatabaseMobx.k_cardIDDealShaping) { //always reset the applied tags when closing/opening the deal shaping card
      this.o_dealShapingAppliedTagIDsArray = [];
    }
    else if(i_cardID === DatabaseMobx.k_cardIDTeammates) {
      //add a tbl_c_teammates record for 'our company' so that workshare and contracts can be filled out (still uses tbl_captures 'our_prime_sub_teammate_allocation' value in addition to the allocation_percent value in this fake teammate record)
      if(!this.c_teammatesUsMinus2TblCTeammatesRowExistsTF) { //ccid -2 teammate record for us has not been created for this capture yet, create it here in the database for this capture
        //insert a teammate record for this captureID with a contact_company_id of -2 if it does not already exist
        const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_run_when_card_that_wasnt_open_is_opened - teammates", ["i_cardID"], [i_cardID]);
        const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

        //get the tbl_c_teammates fields/idsb (including the admin extra fields)
        const tblCTeammatesFieldDbNamesAndIdsbObj = DatabaseMobx.get_tbl_info_field_db_names_array_and_idsb_array_from_tbl_name("tbl_c_teammates", ["id", "capture_id", "contact_company_id"]); //don't include captureID and contactCompanyID fields as those are used for the where fields when filtering to search for this row
        var teammateFieldNamesArray = tblCTeammatesFieldDbNamesAndIdsbObj.fieldDbNamesArray;
        var teammateIdsbArray = tblCTeammatesFieldDbNamesAndIdsbObj.idsbArray;
        var teammateBlankValuesArray = tblCTeammatesFieldDbNamesAndIdsbObj.blankValuesArray;

        const tblName = "tbl_c_teammates";
        const whereFieldNameOrNamesArray = ["capture_id", "contact_company_id"];
        const whereValueOrValuesArray = [openCaptureID, -2];
        const whereValueIdsbOrIdsbArray = ["i", "i"];
        var nonWhereFieldNamesArray = [];
        var nonWhereValuesArray = [];
        var nonWhereIdsbArray = [];
        for(let f = 0; f < teammateFieldNamesArray.length; f++) {
          var newUsMinus2TeammateValue = teammateBlankValuesArray[f];
          if(teammateFieldNamesArray[f] === "selected_01") {
            newUsMinus2TeammateValue = 1;
          }
          else if(teammateFieldNamesArray[f] === "allocation_percent") {
            newUsMinus2TeammateValue = this.c_captureOurPrimeSubTeammateAllocationPercent0to100;
          }

          nonWhereFieldNamesArray.push(teammateFieldNamesArray[f]);
          nonWhereValuesArray.push(newUsMinus2TeammateValue);
          nonWhereIdsbArray.push(teammateIdsbArray[f]);
        }
        const oldValueOrValuesArray = undefined;
        const performUpdateIfExistsTF = false; //do not perform the update if the -2 row already exists, could erase the data saved in this record if for some reason the -2 record did not load properly into local memory
        C_CallPhpTblUID.add_update_existing_otherwise_insert(tblName, whereFieldNameOrNamesArray, whereValueOrValuesArray, whereValueIdsbOrIdsbArray, nonWhereFieldNamesArray, nonWhereValuesArray, nonWhereIdsbArray, oldValueOrValuesArray, performUpdateIfExistsTF);

        C_CallPhpTblUID.execute();
      }
    }
    else if(i_cardID === DatabaseMobx.k_cardIDChangelog) {
      this.a_load_single_capture_changelog_data(openCaptureID); //load changelog data only when the changelog card is opened
    }
  }

  a_run_when_card_is_closed(i_cardID) {
    //clear the item being edited so that an edited item does not show up on a snapshot card
    CaptureExecMobx.a_close_item_editing();

    this.o_teammatesExpandedTeammateID = undefined;

    //if the changelog card was just closed, remove the changelog entries from memory
    if(i_cardID === DatabaseMobx.k_cardIDChangelog) {
      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_run_when_card_is_closed", ["i_cardID"], [i_cardID]);
      DatabaseMobx.a_clear_local_tbls_from_tbl_names_array(DatabaseMobx.c_openCaptureChangeLogCardTblNamesToLoadArray, jsDescription); //clear the changelog data when the changelog card is closed
    }
  }



  //top bar GCSS Connected To GovWin button action to load corresponding SAM.gov data for that record
  a_set_capture_connected_to_gcss_compare_floating_box_is_open_tf(i_newValueTF) {
    this.o_captureConnectedToGcssCompareFloatingBoxIsOpenTF = i_newValueTF;
  }

  a_set_capture_connected_to_gcss_fetched_single_sam_expanded_detail_search_results_obj(i_samExpandedDetailSearchResultsObjOrUndefined) {
    this.o_captureConnectedToGcssFetchedSingleSamExpandedDetailSearchResultsObjOrUndefined = i_samExpandedDetailSearchResultsObjOrUndefined;
  }

  a_set_capture_connected_to_gcss_loading_flag_or_error_message(i_loadingFlagOrErrorMessageOrUndefined) {
    this.o_captureConnectedToGcssLoadingFlagOrErrorMessageOrUndefined = i_loadingFlagOrErrorMessageOrUndefined;
  }

  a_capture_connected_to_gcss_fetch_single_sam_record_data(i_solicitationNumber) {
    this.a_set_capture_connected_to_gcss_fetched_single_sam_expanded_detail_search_results_obj(undefined);
    this.a_set_capture_connected_to_gcss_loading_flag_or_error_message("loading");

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_capture_connected_to_gcss_fetch_single_sam_record_data", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("gcssLoadSingleSamDetailOppFromSolicitationNumber", jsDescription);
    C_CallPhpScript.add_post_var("i_solicitationNumber", i_solicitationNumber);
    C_CallPhpScript.add_return_vars(["samSearchResultObj", "rawSamOppsHistoryMatrix", "gcssDetailResultErrorMessage"]);

    const functionOnSuccess = (i_parseResponse) => {
      var samExpandedDetailSearchResultsObj = undefined;
      var newErrorMessage = undefined;
      if(JSFUNC.string_is_filled_out_tf(i_parseResponse.gcssDetailResultErrorMessage)) { //controlled error message code from details fetch
        newErrorMessage = i_parseResponse.gcssDetailResultErrorMessage;
      }
      else { //successful single search result details fetch
        const gcssSam0GovWin1 = 0;
        const samExpandedSearchResultObj = GCSSMobx.create_gcss_expanded_search_result_obj_from_search_result_obj(i_parseResponse.samSearchResultObj, gcssSam0GovWin1);
        samExpandedDetailSearchResultsObj = GCSSMobx.create_gcss_expanded_detail_search_results_obj_from_expanded_search_result_obj_and_op_tbl_data_rows_arrayOfObjs(samExpandedSearchResultObj, i_parseResponse.rawSamOppsHistoryMatrix);
      }
      this.a_set_capture_connected_to_gcss_fetched_single_sam_expanded_detail_search_results_obj(samExpandedDetailSearchResultsObj);
      this.a_set_capture_connected_to_gcss_loading_flag_or_error_message(newErrorMessage);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      this.a_set_capture_connected_to_gcss_fetched_single_sam_expanded_detail_search_results_obj(undefined);
      this.a_set_capture_connected_to_gcss_loading_flag_or_error_message("jsPhpError");
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();
  }



  //indicator in top capture name bar of other users that have this capture open at the same time, undefined is a flag that no one else has this capture open
  a_set_user_per_email_ids_with_same_capture_open(i_userPerEmailIDsWithSameCaptureOpenComma) {
    if(this.o_userPerEmailIDsWithSameCaptureOpenComma !== i_userPerEmailIDsWithSameCaptureOpenComma) { //only update the field if it has changed from what it was
      this.o_userPerEmailIDsWithSameCaptureOpenComma = i_userPerEmailIDsWithSameCaptureOpenComma;
    }
  }



  //copy capture
  a_copy_capture_set_copy_state(i_newCopyCaptureState, i_newCopyCaptureOldNewNamesString) {
    this.o_copyCaptureState = i_newCopyCaptureState;
    this.o_copyCaptureOldNewNamesString = i_newCopyCaptureOldNewNamesString;
  }

  a_copy_capture_set_copy_stage_tf(i_newValueTF) { this.o_copyCaptureStageTF = i_newValueTF; }
  a_copy_capture_set_copy_dates_tf(i_newValueTF) { this.o_copyCaptureDatesTF = i_newValueTF; }
  a_copy_capture_set_copy_tasks_tf(i_newValueTF) { this.o_copyCaptureTasksTF = i_newValueTF; }
  a_copy_capture_set_copy_deal_shaping_tf(i_newValueTF) { this.o_copyCaptureDealShapingTF = i_newValueTF; }
  a_copy_capture_set_copy_teammates_tf(i_newValueTF) { this.o_copyCaptureTeammatesTF = i_newValueTF; }
  a_copy_capture_set_copy_teammates_surveys_tf(i_newValueTF) { this.o_copyCaptureTeammatesSurveysTF = i_newValueTF; }
  a_copy_capture_set_copy_competitors_tf(i_newValueTF) { this.o_copyCaptureCompetitorsTF = i_newValueTF; }
  a_copy_capture_set_copy_proposal_themes_tf(i_newValueTF) { this.o_copyCaptureProposalThemesTF = i_newValueTF; }
  a_copy_capture_set_copy_risks_tf(i_newValueTF) { this.o_copyCaptureRisksTF = i_newValueTF; }

  a_copy_currently_open_capture_close_then_open_copied_capture(i_newCaptureCodename, i_newCaptureOpportunityName) {
    const oldCaptureID = this.o_openCaptureID;
    const o_copyCaptureStageTF = this.o_copyCaptureStageTF;
    const o_copyCaptureDatesTF = this.o_copyCaptureDatesTF;
    const o_copyCaptureTasksTF = this.o_copyCaptureTasksTF;
    const o_copyCaptureDealShapingTF = this.o_copyCaptureDealShapingTF;
    const o_copyCaptureTeammatesTF = this.o_copyCaptureTeammatesTF;
    const o_copyCaptureTeammatesSurveysTF = this.o_copyCaptureTeammatesSurveysTF;
    const o_copyCaptureCompetitorsTF = this.o_copyCaptureCompetitorsTF;
    const o_copyCaptureProposalThemesTF = this.o_copyCaptureProposalThemesTF;
    const o_copyCaptureRisksTF = this.o_copyCaptureRisksTF;
    const c_openCaptureMap = this.c_openCaptureMap;
    const c_captureName = this.c_captureName;
    const c_captureTypeStagesArrayOfObjs = this.c_captureTypeStagesArrayOfObjs;
    const k_cardIDDetails = DatabaseMobx.k_cardIDDetails;
    const c_companyUsingCodenameTF = DatabaseMobx.c_companyUsingCodenameTF;

    if(c_openCaptureMap !== undefined) { //only perform the copy if the openCaptureMap is loaded (this should always be the case as you can only copy from an open capture)
      //set the copy state as copying to get the progress floating box
      this.a_copy_capture_set_copy_state("copying", "'" + c_captureName + "' -> '" + i_newCaptureOpportunityName + "'");

      //load all of the capture values for every field in tbl_captures
      var tblCapturesFieldNamesArray = [];
      var tblCapturesValuesArray = [];
      var tblCapturesIdsbArray = [];
      for(let expandedCaptureFieldMap of DatabaseMobx.c_tbl_captures_fields.values()) {
        if(expandedCaptureFieldMap.get("isTblCapturesColumnTF")) {
          var fieldDbName = expandedCaptureFieldMap.get("db_name");
          if(fieldDbName !== "id") { //don't need to copy id, the new insert will create a unique captureID number
            var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj"); //get the idsb of this field from the fieldTypeObj, verify that it exists in the expanded field map
            if(fieldTypeObj !== undefined) { //make sure the fieldTypeObj exists
              if(fieldTypeObj.idsbIsValidTF) {
                var captureValueRaw = undefined;
                if(c_companyUsingCodenameTF && (fieldDbName === "codename")) { //use the new codename that the user entered when initiating the copy
                  captureValueRaw = i_newCaptureCodename;
                }
                else if(fieldDbName === "opportunity_name") { //use the new opportunity name that the user entered when initiating the copy
                  captureValueRaw = i_newCaptureOpportunityName;
                }
                else if(JSFUNC.in_array(fieldDbName, ["added_date", "last_changed_date", "last_stage_date", "last_progress_date", "last_pwin_date", "last_rfp_date"])) { //use the new opportunity name that the user entered when initiating the copy
                  captureValueRaw = JSFUNC.now_date();
                }
                else if(!o_copyCaptureStageTF && (fieldDbName === "stage_id") && (c_captureTypeStagesArrayOfObjs.length > 0)) { //if the stage copy switch is turned off, reset the stage to the first active stage in the timeline (if there are any stages, otherwise just use the copy value)
                  captureValueRaw = c_captureTypeStagesArrayOfObjs[0].id; //first stage in timeline
                }
                else if(!o_copyCaptureDatesTF && (fieldTypeObj.dateTF || fieldTypeObj.dateTimeTF)) { //reset all dates (other than added/last changed) if the dates copy switch is turned off
                  captureValueRaw = fieldTypeObj.blankValue;
                }
                else if(!o_copyCaptureDealShapingTF && JSFUNC.in_array(fieldDbName, ["shaping_total_progress", "shaping_stage_progress", "pwin"])) { //if the deal shaping is reset, set the progress values and pwin to 0%
                  captureValueRaw = 0;
                }
                else if(!o_copyCaptureTeammatesTF && JSFUNC.in_array(fieldDbName, ["our_prime_sub_teammate_allocation", "req_sb_allocation", "total_lb_allocation", "total_sb_allocation", "num_lb_teammates", "num_sb_teammates"])) { //if the teammates are reset, set all teammate allocation values and counts to 0
                  if(fieldDbName === "our_prime_sub_teammate_allocation") {
                    captureValueRaw = 100; //100% is the initialized value for our allocation
                  }
                  else { //all other values are 0 or 0%
                    captureValueRaw = 0;
                  }
                }
                else { //all other tbl_captures column fields have their raw value taken from the capture map (textareas are loaded from the full textarea map for the open capture)
                  captureValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(c_openCaptureMap, expandedCaptureFieldMap);
                }

                tblCapturesFieldNamesArray.push(fieldDbName);
                tblCapturesValuesArray.push(captureValueRaw);
                tblCapturesIdsbArray.push(fieldTypeObj.idsb);
              }
            }
          }
        }
      }

      //call the insert operation to insert the copied tbl_captures capture record into the database, this will update every other user's 5 sec refresh to pick up this new copied capture and have it in local memory for this user to open it after the full data copy
      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_copy_currently_open_capture_close_then_open_copied_capture", ["i_newCaptureCodename", "i_newCaptureOpportunityName"], [i_newCaptureCodename, i_newCaptureOpportunityName]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      C_CallPhpTblUID.add_insert("tbl_captures", tblCapturesFieldNamesArray, tblCapturesValuesArray, tblCapturesIdsbArray);

      const functionOnSuccess = (i_parseResponse) => {
        //get the newly copied captureID returned from the php insert
        const newCaptureID = i_parseResponse.outputObj.i0;

        if(newCaptureID > 0) { //successful tblUID insert operation into tbl_captures
          //close the old capture
          this.a_close_single_capture();

          //call the php script to copy all other data into this capture
          const C_CallPhpScript = new JSPHP.ClassCallPhpScript("copyCaptureData", jsDescription);
          C_CallPhpScript.add_post_var("i_oldCaptureID", oldCaptureID);
          C_CallPhpScript.add_post_var("i_newCaptureID", newCaptureID);
          C_CallPhpScript.add_post_var("i_copyTasks01", ((o_copyCaptureTasksTF) ? ("1") : ("0")));
          C_CallPhpScript.add_post_var("i_copyDealShaping01", ((o_copyCaptureDealShapingTF) ? ("1") : ("0")));
          C_CallPhpScript.add_post_var("i_copyTeammates01", ((o_copyCaptureTeammatesTF) ? ("1") : ("0")));
          C_CallPhpScript.add_post_var("i_copyTeammatesSurveys01", ((o_copyCaptureTeammatesSurveysTF) ? ("1") : ("0")));
          C_CallPhpScript.add_post_var("i_copyCompetitors01", ((o_copyCaptureCompetitorsTF) ? ("1") : ("0")));
          C_CallPhpScript.add_post_var("i_copyProposalThemes01", ((o_copyCaptureProposalThemesTF) ? ("1") : ("0")));
          C_CallPhpScript.add_post_var("i_copyRisks01", ((o_copyCaptureRisksTF) ? ("1") : ("0")));
          C_CallPhpScript.add_return_vars("success01String");

          const functionOnSuccessCopyCaptureData = (i_parseResponse) => {
            //close the copying progress floating box
            this.a_copy_capture_set_copy_state(undefined, undefined);

            //load all teammates from the database to tbl_c_teammates so that the newly copied teammate records show up in memory for the new copied caputre
            if(o_copyCaptureTeammatesTF) {
              JSPHP.load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array("tbl_c_teammates");
            }

            //open the new capture locally for this user, this will go to the database to load all of the supplemental capture data that was just copied with the copyCaptureData php script
            this.a_open_single_capture(newCaptureID);

            //send another request to the database to insert a single entry into each changelog for the new capture as initialization messages and values
            const newCaptureMap = DatabaseMobx.o_tbl_captures.get(newCaptureID);
            if(newCaptureMap !== undefined) {
              const newCaptureInitialProgress = newCaptureMap.get("shaping_total_progress");
              const newCaptureInitialPwin = newCaptureMap.get("pwin");
              const newCaptureInitialStage = newCaptureMap.get("stage_id");

              const C_CallPhpTblUIDChangelogInits = new JSPHP.ClassCallPhpTblUID(jsDescription);
              C_CallPhpTblUIDChangelogInits.add_changelog_details(newCaptureID, k_cardIDDetails, -3, "--Copy Capture--", "--New Capture Copied from Original Capture ID " + oldCaptureID + "--");
              C_CallPhpTblUIDChangelogInits.add_changelog_pwin(newCaptureID, newCaptureInitialPwin);
              C_CallPhpTblUIDChangelogInits.add_changelog_shaping_initialize(newCaptureID, "--Copied from Capture ID " + oldCaptureID + "--", "--Initialize Shaping Progress from Copied Capture--", newCaptureInitialProgress);
              C_CallPhpTblUIDChangelogInits.add_changelog_stages(newCaptureID, newCaptureInitialStage);
              C_CallPhpTblUIDChangelogInits.execute();
            }
          }
          C_CallPhpScript.add_function("onSuccess", functionOnSuccessCopyCaptureData);

          const functionOnErrorCopyCaptureData = () => {
            this.a_copy_capture_set_copy_state("failedCaptureData", "'" + DatabaseMobx.capture_name_plaintext_from_capture_id(oldCaptureID) + "' -> '" + i_newCaptureOpportunityName + "'");
          }
          C_CallPhpScript.add_function("onError", functionOnErrorCopyCaptureData);

          const devSystemFakeParseResponse = {outputObj:{success01String:"1"}};
          C_CallPhpScript.execute(devSystemFakeParseResponse);
        }
        else { //error inserting new capture record
          this.a_copy_capture_set_copy_state("failedCaptureRow", "'" + DatabaseMobx.capture_name_plaintext_from_capture_id(oldCaptureID) + "' -> '" + i_newCaptureOpportunityName + "'");
        }
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      const functionOnError = () => {
        this.a_copy_capture_set_copy_state("failedCaptureRow", "'" + DatabaseMobx.capture_name_plaintext_from_capture_id(oldCaptureID) + "' -> '" + i_newCaptureOpportunityName + "'");
      }
      C_CallPhpTblUID.add_function("onError", functionOnError);

      C_CallPhpTblUID.execute();
    }
  }



  //delete capture
  a_delete_capture_set_delete_state(i_newDeleteCaptureState) {
    this.o_deleteCaptureState = i_newDeleteCaptureState;
  }

  a_delete_and_close_currently_open_capture() {
    const o_openCaptureID = this.o_openCaptureID;

    //close the open capture first while it still exists
    this.a_close_single_capture();

    //after deleting all capture data, record an admin changelog entry for deleting the capture
    const functionOnSuccess = () => {
      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_delete_and_close_currently_open_capture", [], []);
      const C_CallPhpTblUIDAdminChangelog = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const forceCaptureIDInNameTFU = true;
      const deletedCaptureNameWithIDString = DatabaseMobx.capture_name_plaintext_from_capture_id(o_openCaptureID, forceCaptureIDInNameTFU);
      const adminChangelogNewValueString = "Deleted Capture '"  + deletedCaptureNameWithIDString + "'";
      C_CallPhpTblUIDAdminChangelog.add_changelog_admin(100, adminChangelogNewValueString);

      C_CallPhpTblUIDAdminChangelog.execute();
    }

    JSPHP.delete_single_capture_with_capture_data_and_integration_opp_from_capture_id(o_openCaptureID, functionOnSuccess);
  }

  a_delete_capture_request_admin_delete_send_notification(i_deleteCaptureReasonIDsComma, i_deleteCaptureComment) {
    const userPerEmailIDsOfAllAdminsArray = DatabaseMobx.c_userPerEmailIDsOfAllAdminPowerUsersNotDeactivatedArray;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_delete_capture_request_admin_delete_send_notification", ["i_deleteCaptureReasonIDsComma", "i_deleteCaptureComment"], [i_deleteCaptureReasonIDsComma, i_deleteCaptureComment]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var message = "Delete Capture requested from " + UserMobx.c_userName + " to delete Capture '" + this.c_captureName + "' from the system (Capture ID: " + this.o_openCaptureID + ")";
    message += "\nReason(s): " + DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(i_deleteCaptureReasonIDsComma, DatabaseMobx.c_selectMultiAddDeleteCaptureReasonsFieldTypeObj);
    if(i_deleteCaptureComment !== "") {
      message += "\nComment: " + i_deleteCaptureComment;
    }
    const clickAction = "adminDeleteCapture:" + this.o_openCaptureID;
    C_CallPhpTblUID.add_notifications(userPerEmailIDsOfAllAdminsArray, true, message, clickAction);

    C_CallPhpTblUID.execute();
  }





  //Advance Stage card



  //Tasks card
  a_tasks_set_mobile_showing_active_tf(i_newValueTF) {
    this.o_tasksMobileShowingActiveTF = i_newValueTF;
  }




  //Deal Shaping card
  a_deal_shaping_apply_tag_id(i_tagIDToApply) {
    this.o_dealShapingAppliedTagIDsArray.push(i_tagIDToApply); //add the newly selected tag to the end
  }

  a_deal_shaping_remove_tag_id(i_tagIDToRemove) {
    this.o_dealShapingAppliedTagIDsArray = JSFUNC.remove_all_values_from_array(i_tagIDToRemove, this.o_dealShapingAppliedTagIDsArray); //remove the deleted tag from the array of applied tags
  }

  a_deal_shaping_update_answer(i_questionID, i_selectTrueTextareaFalse, i_newAnswerValue) {
    const captureID = this.o_openCaptureID; //can only update the open capture since the progress/pwin values are calculated using the answer data only loading into memory for this capture
    const [totalProgress, currentStageProgress, calculatedPwin] = this.calculate_shaping_progress_and_pwin_from_changed_stage_or_question_answer(this.c_captureStageID, i_questionID, i_newAnswerValue);

    //update the local existing answer record (or insert a new one if the question has never been answered with a fake id number) with the new answer value (raw value from the select, or both the text and score values from a scored_textarea)
    const dataShapingAnswersTblName = ((i_selectTrueTextareaFalse) ? ("tbl_c_shaping_answers_select") : ("tbl_c_shaping_answers_textarea"));
    const whereFieldNamesArray = ["capture_id", "question_id"];
    const whereValuesArray = [captureID, i_questionID];
    const whereValuesIdsbArray = ["i", "i"];
    const fieldNamesArray = ((i_selectTrueTextareaFalse) ? (["answer_id"]) : (["answer_text", "score0to100"]));
    const valuesArray = ((i_selectTrueTextareaFalse) ? ([i_newAnswerValue]) : ([i_newAnswerValue.textarea, i_newAnswerValue.score0to100]));
    const valuesIdsbArray = ((i_selectTrueTextareaFalse) ? (["i"]) : (["s", "i"]));

    //update the capture in tbl_captures for total progress, stage progress, and calculated pwin
    var tblCapturesFieldNamesArray = ["shaping_total_progress", "shaping_stage_progress"];
    var tblCapturesValuesArray = [totalProgress, currentStageProgress];
    var tblCapturesIdsbArray = ["d", "d"];
    var tblCapturesOldValuesArray = [this.c_captureShapingTotalProgress, this.c_captureShapingStageProgress];
    if(DatabaseMobx.c_companyPwinIsCalculatedTF) { //only update calculated pwin if the company has set the pwin calculated flag
      tblCapturesFieldNamesArray.push("pwin");
      tblCapturesValuesArray.push(calculatedPwin);
      tblCapturesIdsbArray.push("d");
      tblCapturesOldValuesArray.push(this.c_capturePwin);
    }

    //create the JSPHP update instance and execute it (deal shaping answer select/textarea, tbl_captures shaping progress, shaping changelog entry)
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_deal_shaping_update_answer", ["i_questionID", "i_selectTrueTextareaFalse", "i_newAnswerValue"], [i_questionID, i_selectTrueTextareaFalse, i_newAnswerValue]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_update_existing_otherwise_insert(dataShapingAnswersTblName, whereFieldNamesArray, whereValuesArray, whereValuesIdsbArray, fieldNamesArray, valuesArray, valuesIdsbArray);

    C_CallPhpTblUID.add_update("tbl_captures", captureID, tblCapturesFieldNamesArray, tblCapturesValuesArray, tblCapturesIdsbArray, tblCapturesOldValuesArray);

    if(i_selectTrueTextareaFalse) {
      C_CallPhpTblUID.add_changelog_shaping_select(captureID, i_questionID, i_newAnswerValue, totalProgress);
    }
    else {
      C_CallPhpTblUID.add_changelog_shaping_textarea(captureID, i_questionID, i_newAnswerValue.textarea, i_newAnswerValue.score0to100, totalProgress);
    }

    if(DatabaseMobx.c_companyPwinIsCalculatedTF) {
      C_CallPhpTblUID.add_changelog_pwin(captureID, calculatedPwin);
    }

    C_CallPhpTblUID.execute();
  }



  //Details card
  a_details_update_field_value(i_expandedCaptureFieldMap, i_newValueRaw, i_captureMapOrCaptureIDOrUndefined=undefined, i_cardIDOrUndefined=undefined, i_sendNotificationTF=true, i_updateIntegrationSystemLinkedFieldTF=true, i_functionOnVerifiedSuccess=undefined, i_functionOnVerifiedError=undefined) {
    //inputs:
    //  i_expandedCaptureFieldMap
    //  i_newValueRaw
    //  i_captureMapOrCaptureIDOrUndefined
    //  i_cardIDOrUndefined (can specify a cardID that this update was called from, undefined uses the default of the Details Card)
    //  i_sendNotificationTF
    //  i_updateIntegrationSystemLinkedFieldTF (keep on for all updates [only runs if integration is enabled by BIT and turned on in the 3rd Party Integrations tab], turn off when updating the Integraiton Unique ID field when creating a new capture as the field had to have been filled out already in the integration system to create the new opp in that system)
    //  i_functionOnVerifiedSuccess (Capture Table mass edit recursive updates) this prevents calling the functionOnSuccess teammate count recalculation in this function, thus if i_functionOnVerifiedSuccess is provided, pass the captureID as an output to that success that this needs recalculation at the end of the recursion
    //  i_functionOnVerifiedError (Capture Table mass edit recursive updates)
    //
    //output:
    //  madeUpdateTF

    const c_singleCaptureIsOpenTF = this.c_singleCaptureIsOpenTF;
    const c_openCaptureMap = this.c_openCaptureMap;
    const c_captureTypeExistsTF = this.c_captureTypeExistsTF;
    const c_captureTypeIsPrimeTF = this.c_captureTypeIsPrimeTF;
    const c_captureOurPrimeSubTeammateDivisionIsSmallBusinessTF = this.c_captureOurPrimeSubTeammateDivisionIsSmallBusinessTF;
    const k_cardIDDetails = DatabaseMobx.k_cardIDDetails;
    const o_tbl_captures = DatabaseMobx.o_tbl_captures;
    const o_tbl_a_capture_types = DatabaseMobx.o_tbl_a_capture_types;
    const c_tbl_a_users = DatabaseMobx.c_tbl_a_users;
    const c_tbl_a_divisions = DatabaseMobx.c_tbl_a_divisions;
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;
    const c_fieldMapOfCaptureManagers = DatabaseMobx.c_fieldMapOfCaptureManagers;
    const c_fieldMapOfCaptureType = DatabaseMobx.c_fieldMapOfCaptureType;
    const c_fieldMapOfStage = DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfContractsManager = DatabaseMobx.c_fieldMapOfContractsManager;
    const c_fieldMapOfPeriodOfPerformance = DatabaseMobx.c_fieldMapOfPeriodOfPerformance;
    const c_fieldMapOfCaptureFavorites = DatabaseMobx.c_fieldMapOfCaptureFavorites;
    const c_fieldMapOfContractStartDate = DatabaseMobx.c_fieldMapOfContractStartDate;
    const c_fieldMapOfContractEndDate = DatabaseMobx.c_fieldMapOfContractEndDate;
    const c_fieldMapOfArchiveDate = DatabaseMobx.c_fieldMapOfArchiveDate;
    const c_fieldMapOfTotalShapingProgress = DatabaseMobx.c_fieldMapOfTotalShapingProgress;
    const c_fieldMapOfStageShapingProgress = DatabaseMobx.c_fieldMapOfStageShapingProgress;
    const c_fieldMapOfOurPrimeSubTeammateDivisionID = DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateDivisionID;
    const o_userPerEmailID = UserMobx.o_userPerEmailID;
    const c_userName = UserMobx.c_userName;
    const c_userPerEmailFavoritedCaptureIDsArray = UserMobx.c_userPerEmailFavoritedCaptureIDsArray;

    //make sure the input i_expandedCaptureFieldMap is not undefined, if it is, do not do any update
    if(i_expandedCaptureFieldMap === undefined) {
      return(false);
    }

    //use the open capture (and computed mobx values for the open capture) by default unless i_captureMapOrCaptureIDOrUndefined is specified to update a different capture
    var captureMap = c_openCaptureMap; //if i_captureMapOrCaptureIDOrUndefined is undefined, use the currently open capture map
    var captureTypeExistsTF = c_captureTypeExistsTF;
    var captureTypeIsPrimeTF = c_captureTypeIsPrimeTF;
    var captureOurPrimeSubTeammateDivisionIsSmallBusinessTF = c_captureOurPrimeSubTeammateDivisionIsSmallBusinessTF;
    if(i_captureMapOrCaptureIDOrUndefined !== undefined) { //use a different capture as opposed to the currently open capture in memory
      if(JSFUNC.is_number(i_captureMapOrCaptureIDOrUndefined)) { //captureID was provided as input
        if(JSFUNC.is_number_not_nan_gt_0(i_captureMapOrCaptureIDOrUndefined)) { //potentially valid captureID was provided as input
          captureMap = o_tbl_captures.get(i_captureMapOrCaptureIDOrUndefined); //get the specified captureMap from i_captureMapOrCaptureIDOrUndefined
        }
        else { //negative captureID like -1 provided which can happen as an error sometimes
          captureMap = undefined;
        }
      }
      else if(JSFUNC.is_obj(i_captureMapOrCaptureIDOrUndefined)) { //capture map was provided as input (registers typerof() as an 'object' for a Map() in js)
        captureMap = i_captureMapOrCaptureIDOrUndefined;
      }
      else { //any other type of input other than captureID or captureMap, set as undefined so that no update is made
        captureMap = undefined;
      }

      if(captureMap !== undefined) {
        //recompute the mobx computed values for this capture (which is not the currently open capture, but an input captureMap or captureID)
        captureTypeExistsTF = o_tbl_a_capture_types.has(captureMap.get("capture_type_id"));
        captureTypeIsPrimeTF = DatabaseMobx.capture_type_is_prime_tf_from_capture_map(captureMap);
        const ourPrimeSubTeammateDivisionMap = c_tbl_a_divisions.get(captureMap.get("our_prime_sub_teammate_division_id"));
        if(ourPrimeSubTeammateDivisionMap !== undefined) {
          captureOurPrimeSubTeammateDivisionIsSmallBusinessTF = ourPrimeSubTeammateDivisionMap.get("divisionIsSmallBusinessTF");
        }
      }
    }

    //only perform the update (database and local update) if the captureMap is valid, nothing otherwise
    if(captureMap === undefined) {
      return(false);
    }

    //use the input i_cardID or the default k_cardIDDetails if undefined
    var cardID = i_cardIDOrUndefined;
    if(i_cardIDOrUndefined === undefined) {
      cardID = k_cardIDDetails;
    }

    //unpack the inputs needed
    const captureID = captureMap.get("id");

    const fieldID = i_expandedCaptureFieldMap.get("id");
    const fieldDbName = i_expandedCaptureFieldMap.get("db_name");
    const fieldDisplayName = i_expandedCaptureFieldMap.get("display_name");
    const fieldTypeObj = i_expandedCaptureFieldMap.get("fieldTypeObj");
    const fieldChangelogDetailsTF = i_expandedCaptureFieldMap.get("changelogDetailsTF");
    const fieldChangelogStageTF = i_expandedCaptureFieldMap.get("changelogStageTF");
    const fieldChangelogProgressTF = i_expandedCaptureFieldMap.get("changelogProgressTF");
    const fieldChangelogPwinTF = i_expandedCaptureFieldMap.get("changelogPwinTF");

    //determine which capture field is being updated to send notifications or update other dependent capture fields
    var updatedFieldIsCaptureManagersTF = false;
    var updatedFieldIsCaptureTypeTF = false;
    var updatedFieldIsStageTF = false;
    var updatedFieldIsContractsManagerTF = false;
    var updatedFieldIsPeriodOfPerformanceTF = false;
    var updatedFieldIsCaptureFavoritesUpeXTF = false; //capture favorites are saved per userPerEmail in tbl_a_users_per_email 'favorited_capture_ids_comma', not in a tbl_captures column (tbl_captures_fields dbName for this field is 'capture_favorites_upe_x' with upe x suffix to denote this behavior)
    var updatedFieldIsContractStartDateTF = false;
    var updatedFieldIsArchiveDateTF = false;
    var updatedFieldIsOurPrimeSubTeammateDivisionIDTF = false;
    if(fieldID === c_fieldMapOfCaptureManagers.get("id")) { updatedFieldIsCaptureManagersTF = true; }
    else if(fieldID === c_fieldMapOfCaptureType.get("id")) { updatedFieldIsCaptureTypeTF = true; }
    else if(fieldID === c_fieldMapOfStage.get("id")) { updatedFieldIsStageTF = true; }
    else if(fieldID === c_fieldMapOfContractsManager.get("id")) { updatedFieldIsContractsManagerTF = true; }
    else if(fieldID === c_fieldMapOfPeriodOfPerformance.get("id")) { updatedFieldIsPeriodOfPerformanceTF = true; }
    else if(fieldID === c_fieldMapOfCaptureFavorites.get("id")) { updatedFieldIsCaptureFavoritesUpeXTF = true; }
    else if(fieldID === c_fieldMapOfContractStartDate.get("id")) { updatedFieldIsContractStartDateTF = true; }
    else if(fieldID === c_fieldMapOfArchiveDate.get("id")) { updatedFieldIsArchiveDateTF = true; }
    else if(fieldID === c_fieldMapOfOurPrimeSubTeammateDivisionID.get("id")) { updatedFieldIsOurPrimeSubTeammateDivisionIDTF = true; }

    if(updatedFieldIsCaptureFavoritesUpeXTF) { //special handling of update to tbl_a_users_per_email so that users can mark capture favorites independently (no changelog since this is a per user change)
      //compute the new list of favorited captures as an array
      var updatedFavoritedCaptureIDsArray = [];
      if(i_newValueRaw) { //if the new value is true, the favorite icon was just turned on for this capture for this user
        updatedFavoritedCaptureIDsArray = JSFUNC.concat_arrays_or_values_into_new_array(c_userPerEmailFavoritedCaptureIDsArray, captureID);
      }
      else { //favorite icon turned off
        updatedFavoritedCaptureIDsArray = JSFUNC.remove_all_values_from_array(captureID, c_userPerEmailFavoritedCaptureIDsArray);
      }

      //convert array of captureIDs to a comma list
      var updatedFavoritedCaptureIDsComma = JSFUNC.convert_array_to_comma_list(updatedFavoritedCaptureIDsArray);

      //call UserMobx update function for tbl_a_users_per_email, include success/error functions after executing for mass edit
      UserMobx.a_update_user_per_email_field("favorited_capture_ids_comma", updatedFavoritedCaptureIDsComma, "s", i_functionOnVerifiedSuccess, i_functionOnVerifiedError);
    }
    else { //normal update to a tbl_captures column (column name is field db_name), followed by any email/notifications, and other follow on functions and updates (update 2nd related fields, recalculations, etc)
      //initialize flag that this update requires a teammate count recalculation for this capture
      var updateRequiresTeammateCountRecalculationTF = false;

      //call php to update the field
      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_details_update_field_value", ["i_expandedCaptureFieldMap", "i_newValueRaw", "i_captureMapOrCaptureIDOrUndefined", "i_sendNotificationTF"], [i_expandedCaptureFieldMap, i_newValueRaw, i_captureMapOrCaptureIDOrUndefined, i_sendNotificationTF]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //**************************************************************
      //update this capture with the new value for this field
      var tblCapturesFieldNamesArray = [fieldDbName];
      var tblCapturesValuesArray = [i_newValueRaw];
      var tblCapturesIdsbArray = [fieldTypeObj.idsb];

      //old value(s) to compare to would go here to compare to
      const tblCapturesOldValuesArray = undefined;

      //in the special case of stage, changing stage requires a recalculation of the total/stage progress percents, which are also updated in those tbl_captures columns for this capture
      if(updatedFieldIsStageTF) { //updating stage
        //recalculate total/shaping progress based on changed stage, all question/answers are the same and only the currentStageProgress value should change (the totalProgress and any manual or calculated PWin value should be the same in either stage)
        const [totalProgress, currentStageProgress, calculatedPwin] = this.calculate_shaping_progress_and_pwin_from_changed_stage_or_question_answer(i_newValueRaw, undefined, undefined);
        
        //shaping total progress field
        tblCapturesFieldNamesArray.push(c_fieldMapOfTotalShapingProgress.get("db_name"));
        tblCapturesValuesArray.push(totalProgress);
        tblCapturesIdsbArray.push(c_fieldMapOfTotalShapingProgress.get("fieldTypeObj").idsb);

        //shaping stage progress field
        tblCapturesFieldNamesArray.push(c_fieldMapOfStageShapingProgress.get("db_name"));
        tblCapturesValuesArray.push(currentStageProgress);
        tblCapturesIdsbArray.push(c_fieldMapOfStageShapingProgress.get("fieldTypeObj").idsb);
      }
      else { //not updating stage
        //textarea field input type, provide the tbl_captures UID update function with a different 'slim' value truncated at 100 chars with '...' for the local memory value, the full text still goes to the database
        var tblCapturesDifferentLocalValuesArray = undefined; //no different local values unless this updated field is a textarea and has more than 100 chars
        if(fieldTypeObj.textareaTF && JSFUNC.is_string(i_newValueRaw) && (i_newValueRaw.length > 100)) {
          const slimTextareaValueRawString = i_newValueRaw.substring(0, 100) + "...";
          tblCapturesDifferentLocalValuesArray = [slimTextareaValueRawString];
        }
      }

      C_CallPhpTblUID.add_update("tbl_captures", captureID, tblCapturesFieldNamesArray, tblCapturesValuesArray, tblCapturesIdsbArray, tblCapturesOldValuesArray, tblCapturesDifferentLocalValuesArray);
      //**************************************************************

      //**************************************************************
      //insert a new capture changelog entry (main details changelog for most fields, or some fields that have special separate changelogs)
      if(fieldChangelogDetailsTF || fieldChangelogProgressTF) {
        const newValueValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(i_newValueRaw, captureMap, i_expandedCaptureFieldMap, true); //mask the newValue for entry in the changelog (plainText)
        C_CallPhpTblUID.add_changelog_details(captureID, cardID, fieldID, fieldDisplayName, newValueValueMaskSortIfoCanEditObj.valueMaskChangelogPlainText); //cardID is the details card (even for dates as they could be on either card)
      }
      else if(fieldChangelogStageTF) {
        C_CallPhpTblUID.add_changelog_stages(captureID, i_newValueRaw); //cardID is advance stage from this stage changelog function
      }
      else if(fieldChangelogPwinTF) {
        C_CallPhpTblUID.add_changelog_pwin(captureID, i_newValueRaw);
      }
      //**************************************************************
      
      //send any notifications or update any latched calculated details fields whose uneditable values are dependent on the values of other details fields
      if(updatedFieldIsCaptureManagersTF) { //add notifications/emails to each capture manager (not logged in userPerEmailID) that was added, removed, or had their percentage changed
        const oldCaptureManagersValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, c_fieldMapOfCaptureManagers);
        const oldCaptureManagersUserIDsPercentsArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(oldCaptureManagersValueRaw, "userID", "percent0to100");
        const newCaptureManagersUserIDsPercentsArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(i_newValueRaw, "userID", "percent0to100");

        const oldCaptureManagerUserIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(oldCaptureManagersUserIDsPercentsArrayOfObjs, "userID");
        const newCaptureManagerUserIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(newCaptureManagersUserIDsPercentsArrayOfObjs, "userID");
        const oldAndNewUniqueCaptureManagerUserIDsArray = JSFUNC.merge_unique(oldCaptureManagerUserIDsArray, newCaptureManagerUserIDsArray);

        var addedUserIDsArray = [];
        var removedUserIDsArray = [];
        var percentChangedUsersArrayOfObjs = []; //arrayOfObjs to hold userID and old/new percent values for each changed user
        for(let userID of oldAndNewUniqueCaptureManagerUserIDsArray) {
          var matchingExpandedUserMap = c_tbl_a_users.get(userID);
          if(matchingExpandedUserMap !== undefined) { //do not notify users that do not exist in the system
            //get userPerEmailID from userID to determine if this is the same userPerEmailID as the currently logged in user
            if(matchingExpandedUserMap.get("user_per_email_id") !== o_userPerEmailID) { //never send a notification to the currently logged in user (no need to notify if they edited themselves)
              var userIDIsInOldTF = JSFUNC.in_array(userID, oldCaptureManagerUserIDsArray);
              var userIDIsInNewTF = JSFUNC.in_array(userID, newCaptureManagerUserIDsArray);
              if(userIDIsInNewTF && !userIDIsInOldTF) { //if the user is in the new value but not the old, it was just added
                addedUserIDsArray.push(userID);
              }
              else if(!userIDIsInNewTF && userIDIsInOldTF) { //if the user was in the old value but not the new, it was just removed
                removedUserIDsArray.push(userID);
              }
              else if(userIDIsInNewTF && userIDIsInOldTF) { //if the user is in both old and new, then check to see if the percent was changed between old and new
                var oldUserIDPercentObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(oldCaptureManagersUserIDsPercentsArrayOfObjs, "userID", userID);
                var newUserIDPercentObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(newCaptureManagersUserIDsPercentsArrayOfObjs, "userID", userID);
                if((oldUserIDPercentObj !== undefined) && newUserIDPercentObj !== undefined) {
                  if(oldUserIDPercentObj.percent0to100 !== newUserIDPercentObj.percent0to100) {
                    percentChangedUsersArrayOfObjs.push({
                      userID: userID,
                      oldPercent: oldUserIDPercentObj.percent0to100,
                      newPercent: newUserIDPercentObj.percent0to100
                    });
                  }
                }
              }
            }
          }
        }

        //send notification messages
        if(i_sendNotificationTF) {
          const userPerEmailTrueUserFalse = false; //added capture managers use userIDs (not userPerEmailIDs)
          const notificationClickActionString = "openCapture:" + captureID;
          const captureName = DatabaseMobx.capture_name_plaintext_from_capture_id(captureID);

          const addedNotificationMessage = "You have been added to Capture '" + captureName + "' by " + c_userName;
          C_CallPhpTblUID.add_notifications(addedUserIDsArray, userPerEmailTrueUserFalse, addedNotificationMessage, notificationClickActionString);

          const removedNotificationMessage = "You have been removed from Capture '" + captureName + "' by " + c_userName;
          C_CallPhpTblUID.add_notifications(removedUserIDsArray, userPerEmailTrueUserFalse, removedNotificationMessage, notificationClickActionString);

          for(let percentChangedUserObj of percentChangedUsersArrayOfObjs) {
            var percentChangedNotificationMessage = "Your percent ownership on Capture '" + captureName + "' has been changed from " + percentChangedUserObj.oldPercent + "% to " + percentChangedUserObj.newPercent + "% by " + c_userName;
            C_CallPhpTblUID.add_notifications(percentChangedUserObj.userID, userPerEmailTrueUserFalse, percentChangedNotificationMessage, notificationClickActionString);
          }
        }
      }
      else if(updatedFieldIsContractsManagerTF) { //create teammate process history logs and send notifications to old/new contracts managers regarding all affected teammates with active contracts under that capture
        const oldContractsManagerUserID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, c_fieldMapOfContractsManager);
        if(JSFUNC.select_int_is_filled_out_tf(oldContractsManagerUserID)) { //only send notifications and logs if changing from one contracts manager to a new one (don't send anything if initially changing from --No User Assigned-- to a new person)
          //add a teammate contracts process history log record for each active teammate/contract
          const teammatesWithActiveContractsArrayOfObjs = TeammateContractsMobx.get_all_teammates_with_active_contracts_for_single_capture_from_capture_id(captureID);

          const contractsManangerFieldTypeObj = c_fieldMapOfContractsManager.get("fieldTypeObj");
          const contractsManangerFieldDisplayName = c_fieldMapOfContractsManager.get("display_name");

          const oldContractsManagerNamePlainText = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(oldContractsManagerUserID, contractsManangerFieldTypeObj);
          const newContractsManagerNamePlainText = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_newValueRaw, contractsManangerFieldTypeObj);

          const logFieldText = "Changed Capture Assigned " + contractsManangerFieldDisplayName;
          const logValueText = contractsManangerFieldDisplayName + " changed from " + oldContractsManagerNamePlainText + " to " + newContractsManagerNamePlainText + " by " + c_userName;

          for(let teammateWithActiveContractsObj of teammatesWithActiveContractsArrayOfObjs) {
            for(let activeContractObj of teammateWithActiveContractsObj.activeContractsArrayOfObjs) {
              C_CallPhpTblUID.add_changelog_teammate_contracts(captureID, teammateWithActiveContractsObj.teammateID, activeContractObj.contractTypeID, logFieldText, logValueText);
            }
          }

          if(i_sendNotificationTF) {
            const captureName = DatabaseMobx.capture_name_plaintext_from_capture_id(captureID);

            const openAsContractsManagerTF = true;
            const notificationClickAction = TeammateContractsMobx.create_teammate_contracts_notification_click_action_string(openAsContractsManagerTF);

            var notificationBody = "Capture: " + captureName;
            notificationBody += "\nPrevious Contracts Manager: " + oldContractsManagerNamePlainText;
            notificationBody += "\nNew Contracts Manager: " + newContractsManagerNamePlainText;
            notificationBody += "\nChanged By: " + c_userName;
            for(let teammateWithActiveContractsObj of teammatesWithActiveContractsArrayOfObjs) {
              notificationBody += "\n\nTeammate: " + teammateWithActiveContractsObj.teammateContactCompanyNamePlainText;
              for(let activeContractObj of teammateWithActiveContractsObj.activeContractsArrayOfObjs) {
                notificationBody += "\n - " + activeContractObj.contractTypeShortNamePlainText + " needed by " + activeContractObj.needByDatePlainText + " [status: '" + activeContractObj.statusNamePlainText + "']";
              }
            }

            //notification to old contracts manager
            const notificationMessageToOld = "A Capture you were previously assigned to as " + contractsManangerFieldDisplayName + " has been reassigned.\n\n" + notificationBody;
            C_CallPhpTblUID.add_notifications(oldContractsManagerUserID, false, notificationMessageToOld, notificationClickAction);

            //notification to new contracts manager
            const notificationMessageToNew = "You have been assigned as a " + contractsManangerFieldDisplayName + " on a Capture previouly managed by " + oldContractsManagerNamePlainText + ".\n\n" + notificationBody;
            C_CallPhpTblUID.add_notifications(i_newValueRaw, false, notificationMessageToNew, notificationClickAction);
          }
        }
      }
      else if(updatedFieldIsContractStartDateTF || updatedFieldIsPeriodOfPerformanceTF) { //contract end date (dependent on contract start date and period of performance)
        var captureContractStartDateRaw = undefined;
        var capturePopMonthsRaw = undefined;
        if(updatedFieldIsContractStartDateTF) {
          captureContractStartDateRaw = i_newValueRaw;
          capturePopMonthsRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, c_fieldMapOfPeriodOfPerformance);
        }
        else if(updatedFieldIsPeriodOfPerformanceTF) {
          captureContractStartDateRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, c_fieldMapOfContractStartDate);
          capturePopMonthsRaw = i_newValueRaw;
        }
        const calculatedContractEndDate = JSFUNC.compute_end_date_from_start_date_and_num_months(captureContractStartDateRaw, capturePopMonthsRaw);
        C_CallPhpTblUID.add_update("tbl_captures", captureID, c_fieldMapOfContractEndDate.get("db_name"), calculatedContractEndDate, c_fieldMapOfContractEndDate.get("fieldTypeObj").idsb);
      }
      else if(updatedFieldIsCaptureTypeTF) { //capture type prime/sub for teammate count/allocation recalculation
        //could change capture type from prime to another prime type, check to see if it changed from prime to sub or sub to prime which warrants running the teammate count recalc
        const newCaptureTypeIsPrimeTF = DatabaseMobx.capture_type_is_prime_tf_from_capture_type_id(i_newValueRaw);
        updateRequiresTeammateCountRecalculationTF = ((newCaptureTypeIsPrimeTF !== captureTypeIsPrimeTF) || (!captureTypeExistsTF)); //update if going from prime to sub or sub to prime, or if going from --dne-- invalid to either prime/sub
      }
      else if(updatedFieldIsOurPrimeSubTeammateDivisionIDTF) { //if updating our division on teammates card (when capture type is sub) need to recalc capture teammate counts/allocations
        if(!captureTypeIsPrimeTF) { //only if deal is sub capture type will switching our division change the teammate counts
          var newOurDivisionIsSmallBusinessTF = false; //by default (even if the division is invalid) the business size of the division is large
          const newOurDivisionMap = c_tbl_a_divisions.get(i_newValueRaw);
          if(newOurDivisionMap !== undefined) {
            newOurDivisionIsSmallBusinessTF = newOurDivisionMap.get("divisionIsSmallBusinessTF");
          }

          //determine if the business type of the newly selected our division is different from the old one (lb to sb or sb to lb requires the recalc)
          updateRequiresTeammateCountRecalculationTF = (newOurDivisionIsSmallBusinessTF !== captureOurPrimeSubTeammateDivisionIsSmallBusinessTF);
        }
      }
      else if(updatedFieldIsArchiveDateTF) { //any user changing archive status, create an admin changelog
        const captureNameWithIDString = DatabaseMobx.capture_name_plaintext_from_capture_id(captureID, true);
        const adminChangelogNewValueString = "Archived Capture '" + captureNameWithIDString + "'";
        C_CallPhpTblUID.add_changelog_admin(201, adminChangelogNewValueString);
      }

      //for a textarea field, also update the full length textarea to the local memory "singleCaptureFullTextareaFieldsMap" tbl (ok to also update actual tbl_captures field value at full length instead of trucated to 100 chars for this user that updated it)
      if(c_singleCaptureIsOpenTF && fieldTypeObj.textareaTF) {
        const clearOldMapDataFirstTF = false;
        DatabaseMobx.a_insert_or_update_local_data_map("singleCaptureFullTextareaFieldsMap", {[fieldDbName]:i_newValueRaw}, clearOldMapDataFirstTF, jsDescription);
      }

      //functions on success/error
      if(JSFUNC.is_function(i_functionOnVerifiedSuccess)) { //for mass edits, return the captureID as an extra input if a recalculation is required (ignore all teammate recalculations)
        const functionOnSuccessAfterUpdateCallVerifiedSuccessWithCaptureID = (i_parseResponse) => {
          if((i_parseResponse.outputObj.u0 === "1") && (i_parseResponse.outputObj.i0 > 0)) { //success updating capture row and inserting new changelog entry
            //3rd party integration update (if needed)
            if(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF && i_updateIntegrationSystemLinkedFieldTF) {
              AdminIntegrationsMobx.update_integration_opp_field_from_ce_capture_id_field_id_and_new_value_raw(captureID, fieldID, i_newValueRaw);
            }

            //call i_functionOnVerifiedSuccess with captureID if teammates need to be recalculated, undefined otherwise
            const teammateCountsRecalculationRequiredCaptureIDOrUndefined = ((updateRequiresTeammateCountRecalculationTF) ? (captureID) : (undefined));
            i_functionOnVerifiedSuccess(teammateCountsRecalculationRequiredCaptureIDOrUndefined);
          }
          else { //error with update/insert
            if(JSFUNC.is_function(i_functionOnVerifiedError)) {
              i_functionOnVerifiedError();
            }
          }
        }
        C_CallPhpTblUID.add_function("onSuccess", functionOnSuccessAfterUpdateCallVerifiedSuccessWithCaptureID);

        C_CallPhpTblUID.add_function("onError", i_functionOnVerifiedError);
      }
      else { //if not doing a recursive mass edit, perform the recalculation if needed for this 1 capture update
        //teammate recalculation (if needed)
        if(updateRequiresTeammateCountRecalculationTF) {
          const functionOnSuccessAfterUpdateRecalcTeammates = (i_parseResponse) => {
            JSPHP.recalculate_and_update_capture_teammate_counts_and_allocations_from_capture_id_or_ids_array(captureID);
          }
          C_CallPhpTblUID.add_function("onSuccess", functionOnSuccessAfterUpdateRecalcTeammates);
        }
        
        //3rd party integration update (if needed)
        if(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF && i_updateIntegrationSystemLinkedFieldTF) {
          const functionOnSuccessAfterUpdateIntegrationSync = (i_parseResponse) => {
            AdminIntegrationsMobx.update_integration_opp_field_from_ce_capture_id_field_id_and_new_value_raw(captureID, fieldID, i_newValueRaw);
          }
          C_CallPhpTblUID.add_function("onSuccess", functionOnSuccessAfterUpdateIntegrationSync);
        }
      }

      //execute the update, changelog insert, and other calculated updates
      C_CallPhpTblUID.execute();
    }

    return(true);
  }





  //Teammates card
  a_teammates_set_left_tab_selected(i_selectedTabName) {
    this.o_teammatesLeftTabSelected = i_selectedTabName;
  }

  a_teammates_update_teammate_field(i_captureID, i_teammateObj, i_fieldDbName, i_fieldDisplayName, i_newValue, i_fieldIdsb) {
    const changeLogField = "Update " + i_fieldDisplayName + " for " + ContactsMobx.contact_name_plaintext_from_contact_obj(i_teammateObj.contactCompanyObj);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_update_teammate_field", ["i_captureID", "i_teammateObj", "i_fieldDbName", "i_fieldDisplayName", "i_newValue", "i_fieldIdsb"], [i_captureID, i_teammateObj, i_fieldDbName, i_fieldDisplayName, i_newValue, i_fieldIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //update the teammate field value
    C_CallPhpTblUID.add_update("tbl_c_teammates", i_teammateObj.id, i_fieldDbName, i_newValue, i_fieldIdsb, i_teammateObj[i_fieldDbName]);

    var updatedFieldIsSelected01TF = false;
    var updatedFieldIsAllocationPercentTF = false;
    if(i_fieldDbName === "selected_01") { updatedFieldIsSelected01TF = true; }
    else if(i_fieldDbName === "allocation_percent") { updatedFieldIsAllocationPercentTF = true; }

    //if this is our division, also update the doubled data into tbl_captures for this capture
    if(updatedFieldIsAllocationPercentTF && i_teammateObj.isUsTF) {
      C_CallPhpTblUID.add_update("tbl_captures", i_captureID, "our_prime_sub_teammate_allocation", i_newValue, "d");
    }

    //if updating a teammate's selected status or allocation, recompute and update the capture record counting the teammates and totaling the allocations
    if(updatedFieldIsSelected01TF || updatedFieldIsAllocationPercentTF) {
      var numLBTeammates = 0;
      var totalLBAllocation = 0;
      var numSBTeammates = 0;
      var totalSBAllocation = 0;
      if(updatedFieldIsSelected01TF) {
        var updatedTeammateFromUnselectedToSelectedTF = (i_newValue === 1);

        if(updatedTeammateFromUnselectedToSelectedTF) {
          if(i_teammateObj.businessTypeIsLargeTF) {
            numLBTeammates = 1;
            totalLBAllocation = i_teammateObj.allocation_percent;
          }
          else if(i_teammateObj.businessTypeIsSmallTF) {
            numSBTeammates = 1;
            totalSBAllocation = i_teammateObj.allocation_percent;
          }
        }

        for(let lbTeammateObj of this.c_selectedLargeBusinessTeammatesArrayOfObjs) {
          if(updatedTeammateFromUnselectedToSelectedTF || (lbTeammateObj.id !== i_teammateObj.id)) {
            numLBTeammates++;
            totalLBAllocation += lbTeammateObj.allocation_percent;
          }
        }

        for(let sbTeammateObj of this.c_selectedSmallBusinessTeammatesArrayOfObjs) {
          if(updatedTeammateFromUnselectedToSelectedTF || (sbTeammateObj.id !== i_teammateObj.id)) {
            numSBTeammates++;
            totalSBAllocation += sbTeammateObj.allocation_percent;
          }
        }
      }
      else if(updatedFieldIsAllocationPercentTF) {
        for(let lbTeammateObj of this.c_selectedLargeBusinessTeammatesArrayOfObjs) {
          numLBTeammates++;
          totalLBAllocation += ((lbTeammateObj.id !== i_teammateObj.id) ? (lbTeammateObj.allocation_percent) : (i_newValue));
        }

        for(let sbTeammateObj of this.c_selectedSmallBusinessTeammatesArrayOfObjs) {
          numSBTeammates++;
          totalSBAllocation += ((sbTeammateObj.id !== i_teammateObj.id) ? (sbTeammateObj.allocation_percent) : (i_newValue));
        }
      }

      //if the capture type is sub, add our division (which is either lb or sb) to the count and the allocation totals
      if(!this.c_captureTypeIsPrimeTF) {
        if(this.c_captureOurPrimeSubTeammateDivisionIsSmallBusinessTF) {
          numSBTeammates += 1;
          totalSBAllocation += this.c_captureOurPrimeSubTeammateAllocationPercent0to100;
        }
        else {
          numLBTeammates += 1;
          totalLBAllocation += this.c_captureOurPrimeSubTeammateAllocationPercent0to100;
        }
      }

      C_CallPhpTblUID.add_update("tbl_captures", i_captureID, ["num_lb_teammates", "num_sb_teammates", "total_lb_allocation", "total_sb_allocation"], [numLBTeammates, numSBTeammates, totalLBAllocation, totalSBAllocation], ["i", "i", "d", "d"]);
    }

    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, i_fieldDbName, changeLogField, i_newValue);
    C_CallPhpTblUID.execute();
  }


  a_teammates_insert_new_teammates(i_captureID, i_newContactCompanyIDsToAddComma, i_teammateIsPrimeOnSubCaptureTypeTF) {
    const newContactCompanyIDsToAddArray = JSFUNC.convert_comma_list_to_int_array(i_newContactCompanyIDsToAddComma);

    if(newContactCompanyIDsToAddArray.length > 0) {
      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_insert_new_teammates", ["i_captureID", "i_newContactCompanyIDsToAddComma", "i_teammateIsPrimeOnSubCaptureTypeTF"], [i_captureID, i_newContactCompanyIDsToAddComma, i_teammateIsPrimeOnSubCaptureTypeTF]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //get the tbl_c_teammates fields/idsb (including the admin extra fields)
      const tblCTeammatesFieldDbNamesAndIdsbObj = DatabaseMobx.get_tbl_info_field_db_names_array_and_idsb_array_from_tbl_name("tbl_c_teammates", "id");
      var teammateFieldNamesArray = tblCTeammatesFieldDbNamesAndIdsbObj.fieldDbNamesArray;
      var teammateIdsbArray = tblCTeammatesFieldDbNamesAndIdsbObj.idsbArray;
      var teammateBlankValuesArray = tblCTeammatesFieldDbNamesAndIdsbObj.blankValuesArray;

      //insert each contact company selected as a new teammate record
      var numLBRealTeammatesInserted = 0;
      var numSBRealTeammatesInserted = 0;
      for(let contactCompanyID of newContactCompanyIDsToAddArray) { //add each contactCompanyID as a separate insert operation
        var newTeammateValuesArray = [];
        for(let f = 0; f < teammateFieldNamesArray.length; f++) {
          var newTeammateValue = teammateBlankValuesArray[f];
          if(teammateFieldNamesArray[f] === "capture_id") {
            newTeammateValue = i_captureID; //use the input captureID
          }
          else if(teammateFieldNamesArray[f] === "contact_company_id") {
            newTeammateValue = contactCompanyID; //use the contactCompanyID for this loop over all contacts added
          }
          else if(teammateFieldNamesArray[f] === "prime_01") {
            newTeammateValue = ((i_teammateIsPrimeOnSubCaptureTypeTF) ? (1) : (0));
          }
          else if(teammateFieldNamesArray[f] === "selected_01") {
            newTeammateValue = 1; //default newly added teammates as selected on the team
          }

          newTeammateValuesArray.push(newTeammateValue);
        }
        C_CallPhpTblUID.add_insert("tbl_c_teammates", teammateFieldNamesArray, newTeammateValuesArray, teammateIdsbArray);

        //increment the total nunber of teammates for the capture record
        if(!i_teammateIsPrimeOnSubCaptureTypeTF) { //adding a prime company does not add to the teammate totals
          var contactCompanyObj = ContactsMobx.contact_company_or_person_obj_from_id(false, contactCompanyID);
          if(contactCompanyObj.businessTypeIsLargeTF) {
            numLBRealTeammatesInserted++;
          }
          else if(contactCompanyObj.businessTypeIsSmallTF) {
            numSBRealTeammatesInserted++;
          }
        }
      }

      if((numLBRealTeammatesInserted > 0) || (numSBRealTeammatesInserted > 0)) { //only increment the num teammates and write the changelog entry if at least 1 real teammate was inserted in this call
        //get the current number of LB/SB teammates and add the new teammates to those totals to update the capture record which tracks the number of teammates
        //no need to change allocation totals (total_sb_allocation, total_lb_allocation) since the initial value given to new teammates is 0
        var updatedNumLBTeammates = (this.c_selectedLargeBusinessTeammatesArrayOfObjs.length + numLBRealTeammatesInserted);
        var updatedNumSBTeammates = (this.c_selectedSmallBusinessTeammatesArrayOfObjs.length + numSBRealTeammatesInserted);

        //if the capture type is sub, add our division (which is either lb or sb) to the count and the allocation totals
        if(!this.c_captureTypeIsPrimeTF) {
          if(this.c_captureOurPrimeSubTeammateDivisionIsSmallBusinessTF) {
            updatedNumSBTeammates += 1;
          }
          else {
            updatedNumLBTeammates += 1;
          }
        }

        C_CallPhpTblUID.add_update("tbl_captures", i_captureID, ["num_lb_teammates", "num_sb_teammates"], [updatedNumLBTeammates, updatedNumSBTeammates], ["i", "i"]);
      }

      //when adding a prime teammate, also update the capture record to change the doubled data entry values
      if(i_teammateIsPrimeOnSubCaptureTypeTF) {
        C_CallPhpTblUID.add_update("tbl_captures", i_captureID, "prime_contact_company_id", newContactCompanyIDsToAddArray[0], "i");
      }

      //insert a details changelog entry
      const changelogTitle = ((i_teammateIsPrimeOnSubCaptureTypeTF) ? ("Add Prime Teammate") : ("Add Teammates"));
      const teammateNamesComma = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_newContactCompanyIDsToAddComma, DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj); //teammate names in comma list for changelog
      C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "at", changelogTitle, teammateNamesComma);

      C_CallPhpTblUID.execute();
    }
  }

  a_teammates_delete_teammate(i_captureID, i_teammateObj) {
    var recomputeContactCompanyTeammateRatingsTF = false;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_delete_teammate", ["i_captureID", "i_teammateObj"], [i_captureID, i_teammateObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //1. delete the teammate record from tbl_c_teammates
    C_CallPhpTblUID.add_delete("tbl_c_teammates", i_teammateObj.id); //teammates are sorted by sb/lb and percent ownership, no need to resort after delete

    //2. delete all other teammate tbls that have both capture_id and teammate_id columns
    const teammateTblNamesToDeleteArray = ["tbl_c_teammates_contracts", "tbl_c_teammates_contracts_filefoldersystem", "tbl_c_log_teammate_contracts", "tbl_c_teammates_ratings_questionnaire_submissions", "tbl_c_teammates_surveys_answers", "tbl_c_teammates_surveys_response_times"];
    for(let teammateTblNameToDelete of teammateTblNamesToDeleteArray) {
      var teammateTblRef = DatabaseMobx.tbl_ref_from_tbl_name(teammateTblNameToDelete);
      var teammateTblRowIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_multiple_fields_and_values(teammateTblRef, ["capture_id", "teammate_id"], [i_captureID, i_teammateObj.id], "id");
      if(teammateTblRowIDsToDeleteArray.length > 0) { //if there are any rows to delete in this tbl
        C_CallPhpTblUID.add_delete(teammateTblNameToDelete, teammateTblRowIDsToDeleteArray); //deleting every item for this teammate, no need to resort any of the tbls

        //when deleting the contract files records, delete all teammate contract uploaded files on server (only delete files, not folders)
        if(teammateTblNameToDelete === "tbl_c_teammates_contracts_filefoldersystem") {
          for(let teammateContractFileMap of teammateTblRef.values()) {
            var isFileTF = (teammateContractFileMap.get("folder0_file1") === 1);
            var isUploadServerFileTF = (teammateContractFileMap.get("fileupload0_onlinelink1") === 0);
            var matchesCaptureIDTF = (teammateContractFileMap.get("capture_id") === i_captureID);
            var matchesTeammateIDTF = (teammateContractFileMap.get("teammate_id") === i_teammateObj.id);
            if(isFileTF && isUploadServerFileTF && matchesCaptureIDTF && matchesTeammateIDTF) { //only delete files from server (folder and file database records are deleted above)
              C_CallPhpTblUID.add_file_delete(teammateContractFileMap.get("file_loc"));
            }
          }
        }
        else if(teammateTblNameToDelete === "tbl_c_teammates_ratings_questionnaire_submissions") {
          recomputeContactCompanyTeammateRatingsTF = true; //need to recompute contact company teammate ratings if this teammate on this capture had a record for answering the rating questionnaire
        }
      }
    }

    //3. update the capture record num teammate count and allocation percents by looping through all selected teammates except this deleted one and computing the totals
    var numLBTeammates = 0;
    var totalLBAllocation = 0;
    for(let lbTeammateObj of this.c_selectedLargeBusinessTeammatesArrayOfObjs) {
      if(lbTeammateObj.id !== i_teammateObj.id) {
        numLBTeammates++;
        totalLBAllocation += lbTeammateObj.allocation_percent;
      }
    }

    var numSBTeammates = 0;
    var totalSBAllocation = 0;
    for(let sbTeammateObj of this.c_selectedSmallBusinessTeammatesArrayOfObjs) {
      if(sbTeammateObj.id !== i_teammateObj.id) {
        numSBTeammates++;
        totalSBAllocation += sbTeammateObj.allocation_percent;
      }
    }

    //if the capture type is sub, add our division (which is either lb or sb) to the count and the allocation totals
    if(!this.c_captureTypeIsPrimeTF) {
      if(this.c_captureOurPrimeSubTeammateDivisionIsSmallBusinessTF) {
        numSBTeammates += 1;
        totalSBAllocation += this.c_captureOurPrimeSubTeammateAllocationPercent0to100;
      }
      else {
        numLBTeammates += 1;
        totalLBAllocation += this.c_captureOurPrimeSubTeammateAllocationPercent0to100;
      }
    }

    C_CallPhpTblUID.add_update("tbl_captures", i_captureID, ["num_lb_teammates", "num_sb_teammates", "total_lb_allocation", "total_sb_allocation"], [numLBTeammates, numSBTeammates, totalLBAllocation, totalSBAllocation], ["i", "i", "d", "d"]);

    //4. update the doubled data prime contact company id to -1 (no prime selected) in the tbl_captures record if this is a prime on a sub capture type
    if(i_teammateObj.isPrimeOnSubCaptureTypeTF) {
      C_CallPhpTblUID.add_update("tbl_captures", i_captureID, "prime_contact_company_id", -1, "i");
    }

    //5. changelog entry
    const contactCompanyNamePlainText = ContactsMobx.contact_name_plaintext_from_contact_obj(i_teammateObj.contactCompanyObj);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "dt", "Delete Teammate", contactCompanyNamePlainText);

    //6. recompute contact company teammate ratings (if needed)
    if(recomputeContactCompanyTeammateRatingsTF) {
      const functionOnSuccess = (i_parseResponse) => {
        this.a_teammates_ratings_recompute_contact_teammate_ratings_from_contact_company_id(i_teammateObj.contact_company_id);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    C_CallPhpTblUID.execute();

    //7. if this teammate was marked as incumbent, remove that contact company id from the list of incumbents
    this.a_competitors_remove_incumbent_marking_from_capture_id_and_contact_company_id(i_captureID, i_teammateObj.contact_company_id);
  }

  a_teammates_update_total_req_sb_allocation(i_captureID, i_newTotalSBAllocation, i_oldTotalSBAllocation) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_update_total_req_sb_allocation", ["i_captureID", "i_newTotalSBAllocation", "i_oldTotalSBAllocation"], [i_captureID, i_newTotalSBAllocation, i_oldTotalSBAllocation]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_captures", i_captureID, "req_sb_allocation", i_newTotalSBAllocation, "d", i_oldTotalSBAllocation);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "utsba", "Total Small Business Allocation", i_newTotalSBAllocation);
    C_CallPhpTblUID.execute();
  }

  a_teammates_update_sb_certification_allocation(i_captureID, i_sbCertificationAllocationObj, i_newCertAllocationValue) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_update_sb_certification_allocation", ["i_sbCertificationAllocationObj", "i_newCertAllocationValue"], [i_sbCertificationAllocationObj, i_newCertAllocationValue]);

    const oldAllocationValue = i_sbCertificationAllocationObj.allocation_percent;
    const changeLogField = "Update Allocation to SB Cert: " + i_sbCertificationAllocationObj.sbCertificationObj.name;

    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates_sb_certification_allocations", i_sbCertificationAllocationObj.id, "allocation_percent", i_newCertAllocationValue, "d", oldAllocationValue);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "usbca", changeLogField, i_newCertAllocationValue);
    C_CallPhpTblUID.execute();
  }

  a_teammates_insert_new_sb_certifications(i_captureID, i_selectedSBCertificationsBmSetAsideIDsComma) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_insert_new_sb_certifications", ["i_captureID", "i_selectedSBCertificationsBmSetAsideIDsComma"], [i_captureID, i_selectedSBCertificationsBmSetAsideIDsComma]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const selectedSBCertificationsBmSetAsideIDsArray = JSFUNC.convert_comma_list_to_int_array(i_selectedSBCertificationsBmSetAsideIDsComma);
    for(let sbCertificationBmSetAsideID of selectedSBCertificationsBmSetAsideIDsArray) {
      C_CallPhpTblUID.add_insert("tbl_c_teammates_sb_certification_allocations", ["capture_id", "sb_certification_bm_set_aside_id", "allocation_percent"], [i_captureID, sbCertificationBmSetAsideID, 0], ["i", "i", "d"]);
    }

    const changeLogNewCertificationNamesComma = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_selectedSBCertificationsBmSetAsideIDsComma, DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "asbc", "Add New Small Business Certification(s)", changeLogNewCertificationNamesComma);

    C_CallPhpTblUID.execute();
  }

  a_teammates_delete_sb_certification_allocation(i_captureID, i_sbCertificationAllocationObj) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_delete_sb_certification_allocation", ["i_captureID", "i_sbCertificationAllocationObj"], [i_captureID, i_sbCertificationAllocationObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_c_teammates_sb_certification_allocations", i_sbCertificationAllocationObj.id); //no sort column (sorted by percent alloc), no need to resort after delete
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "rsba", "Remove Small Business Certification Allocation", i_sbCertificationAllocationObj.sbCertificationObj.name);
    C_CallPhpTblUID.execute();
  }

  a_teammates_expand_collapse_teammate(i_teammateID) {
    if(this.o_teammatesExpandedTeammateID === i_teammateID) {
      this.o_teammatesExpandedTeammateID = undefined;
    }
    else {
      this.o_teammatesExpandedTeammateID = i_teammateID;
    }
  }

  a_teammates_add_contact_person(i_captureID, i_teammateID, i_teammateNamePlainText, i_teammateContactPersonIDsComma, i_newContactPersonIDsToAddComma) {
    const updatedContactPersonIDsComma = JSFUNC.add_value_to_comma_list(i_newContactPersonIDsToAddComma, i_teammateContactPersonIDsComma);

    const changeLogField = "Add Contact Person(s) to Teammate '" + i_teammateNamePlainText + "'";
    const addedContactNamesComma = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_newContactPersonIDsToAddComma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_add_contact_person", ["i_captureID", "i_teammateID", "i_teammateNamePlainText", "i_teammateContactPersonIDsComma", "i_newContactPersonIDsToAddComma"], [i_captureID, i_teammateID, i_teammateNamePlainText, i_teammateContactPersonIDsComma, i_newContactPersonIDsToAddComma]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates", i_teammateID, "contact_person_ids_comma", updatedContactPersonIDsComma, "s"); //update comma list to list with the id appended
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "acp", changeLogField, addedContactNamesComma);
    C_CallPhpTblUID.execute();
  }

  a_teammates_remove_contact_person(i_captureID, i_teammateID, i_teammateNamePlainText, i_teammateContactPersonIDsComma, i_contactPersonIDToRemove) {
    const updatedContactPersonIDsComma = JSFUNC.remove_all_values_from_comma_list(i_contactPersonIDToRemove, i_teammateContactPersonIDsComma);

    const changeLogField = "Remove Contact Person from Teammate '" + i_teammateNamePlainText + "'";
    const removedContactName = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_contactPersonIDToRemove, DatabaseMobx.c_selectContactPersonFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_remove_contact_person", ["i_captureID", "i_teammateID", "i_teammateNamePlainText", "i_teammateContactPersonIDsComma", "i_contactPersonIDToRemove"], [i_captureID, i_teammateID, i_teammateNamePlainText, i_teammateContactPersonIDsComma, i_contactPersonIDToRemove]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates", i_teammateID, ["contact_person_ids_comma"], [updatedContactPersonIDsComma], ["s"]); //update comma list to list with the id removed
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "rcp", changeLogField, removedContactName);
    C_CallPhpTblUID.execute();
  }

  a_teammates_ratings_update_or_insert_questionnaire_submission(i_localQuestionnaireSubmissionsRowObj, i_functionOnSuccess, i_functionOnError, i_functionUpdateLocalQuestionnaireSubmissionsRowID=undefined) {
    const teammatesRatingsQuestionsArrayOfObjs = DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;
    const o_userID = UserMobx.o_userID;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_ratings_update_or_insert_questionnaire_submission", ["i_localQuestionnaireSubmissionsRowObj"], [i_localQuestionnaireSubmissionsRowObj]);
    var C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const tblName = "tbl_c_teammates_ratings_questionnaire_submissions";
    const whereFieldNamesArray = ["capture_id", "teammate_id", "contact_company_id"];
    const whereValuesArray = [i_localQuestionnaireSubmissionsRowObj.capture_id, i_localQuestionnaireSubmissionsRowObj.teammate_id, i_localQuestionnaireSubmissionsRowObj.contact_company_id];
    const whereIdsbArray = ["i", "i", "i"];
    var fieldNamesArray = ["reviewed_by_user_id", "datetime_utc"];;
    var valuesArray = [o_userID, JSFUNC.now_datetime_utc()];
    var idsbArray = ["i", "s"];
    for(let teammatesRatingsQuestionObj of teammatesRatingsQuestionsArrayOfObjs) {
      var ratingColumnDbName = "q" + teammatesRatingsQuestionObj.id + "a";
      fieldNamesArray.push(ratingColumnDbName);
      valuesArray.push(i_localQuestionnaireSubmissionsRowObj[ratingColumnDbName]);
      idsbArray.push("i");

      var commentColumnDbName = "q" + teammatesRatingsQuestionObj.id + "c";
      fieldNamesArray.push(commentColumnDbName);
      valuesArray.push(i_localQuestionnaireSubmissionsRowObj[commentColumnDbName]);
      idsbArray.push("s");
    }
    C_CallPhpTblUID.add_update_existing_otherwise_insert(tblName, whereFieldNamesArray, whereValuesArray, whereIdsbArray, fieldNamesArray, valuesArray, idsbArray);

    C_CallPhpTblUID.add_function("onSuccess", i_functionOnSuccess);
    C_CallPhpTblUID.add_function("onError", i_functionOnError);

    C_CallPhpTblUID.execute();
  }

  a_teammates_ratings_recompute_contact_teammate_ratings_from_contact_company_id(i_contactCompanyID) {
    const teammatesRatingsQuestionsArrayOfObjs = DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_ratings_recompute_contact_teammate_ratings_from_contact_company_id", ["i_contactCompanyID"], [i_contactCompanyID]);

    var columnDbNamesToLoadArray = ["id"];
    for(let teammatesRatingsQuestionObj of teammatesRatingsQuestionsArrayOfObjs) {
      columnDbNamesToLoadArray.push("q" + teammatesRatingsQuestionObj.id + "a");
    }
    const columnDbNamesToLoadComma = JSFUNC.convert_array_to_comma_list(columnDbNamesToLoadArray);

    const functionOnSuccess = (i_parseResponse) => {
      const teammatesRatingsQuestionnaireSubmissionsArrayOfObjs = i_parseResponse.teammatesRatingsQuestionnaireSubmissionsMatrix;

      const cctrObj = this.a_teammates_ratings_compute_contact_company_teammate_ratings_from_filtered_questionnaire_submissions_arrayOfObjs(teammatesRatingsQuestionnaireSubmissionsArrayOfObjs);

      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_g_contacts_companies", i_contactCompanyID, cctrObj.contactCompanyFieldNamesArray, cctrObj.contactCompanyValuesArray, cctrObj.contactCompanyIdsbArray);
      C_CallPhpTblUID.execute();
    }

    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadTeammateRatingsFromContactCompanyID", jsDescription);
    C_CallPhpScript.add_post_var("i_contactCompanyID", i_contactCompanyID);
    C_CallPhpScript.add_post_var("i_columnDbNamesToLoadComma", columnDbNamesToLoadComma);
    C_CallPhpScript.add_return_vars("teammatesRatingsQuestionnaireSubmissionsMatrix");
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
    C_CallPhpScript.execute();
  }

  a_teammates_ratings_compute_contact_company_teammate_ratings_from_filtered_questionnaire_submissions_arrayOfObjs(i_teammatesRatingsQuestionnaireSubmissionsArrayOfObjs) {
    const teammatesRatingsQuestionsArrayOfObjs = DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    //compute the averages for each individual question as well as the total rating
    var contactCompanyFieldNamesArray = [];
    var contactCompanyValuesArray = [];
    var contactCompanyIdsbArray = [];
    var totalRatingAverage = -1; //default with N/A
    var totalRatingSumWithWeights = 0;
    var totalRatingMaxValueWithWeights = 0;
    for(let teammatesRatingsQuestionObj of teammatesRatingsQuestionsArrayOfObjs) {
      var contactCompanyAverageColumnDbName = "tr_q" + teammatesRatingsQuestionObj.id;
      var ratingColumnDbName = "q" + teammatesRatingsQuestionObj.id + "a";
      var questionRatingsAverage = -1; //default with N/A
      var questionRatingsSum = 0;
      var questionNumSubmissionsNonNA = 0;
      for(let trqsObj of i_teammatesRatingsQuestionnaireSubmissionsArrayOfObjs) {
        var rating = trqsObj[ratingColumnDbName];
        if((rating !== undefined) && (rating >= 1) && (rating <= 5)) {
          questionRatingsSum += rating;
          questionNumSubmissionsNonNA++;
        }
      }

      if(questionNumSubmissionsNonNA > 0) {
        questionRatingsAverage = (questionRatingsSum / questionNumSubmissionsNonNA);
        totalRatingSumWithWeights += (questionRatingsAverage * teammatesRatingsQuestionObj.weight);
        totalRatingMaxValueWithWeights += (5 * teammatesRatingsQuestionObj.weight); //max score of 5
      }

      contactCompanyFieldNamesArray.push(contactCompanyAverageColumnDbName);
      contactCompanyValuesArray.push(questionRatingsAverage);
      contactCompanyIdsbArray.push("d");
    }

    if(totalRatingMaxValueWithWeights > 0) {
      totalRatingAverage = ((totalRatingSumWithWeights / totalRatingMaxValueWithWeights) * 5);
    }

    contactCompanyFieldNamesArray.push("tr_total");
    contactCompanyValuesArray.push(totalRatingAverage);
    contactCompanyIdsbArray.push("d");
    contactCompanyFieldNamesArray.push("tr_num_reviews");
    contactCompanyValuesArray.push(i_teammatesRatingsQuestionnaireSubmissionsArrayOfObjs.length);
    contactCompanyIdsbArray.push("i");

    return({
      contactCompanyFieldNamesArray: contactCompanyFieldNamesArray,
      contactCompanyValuesArray: contactCompanyValuesArray,
      contactCompanyIdsbArray: contactCompanyIdsbArray
    });
  }

  a_teammates_surveys_add_contact_persons(i_captureID, i_teammateID, i_teammateNamePlainText, i_teammateSurveysContactPersonIDsComma, i_newContactPersonIDsToAddComma) {
    const updatedContactPersonIDsComma = JSFUNC.add_value_to_comma_list(i_newContactPersonIDsToAddComma, i_teammateSurveysContactPersonIDsComma);

    const changeLogField = "Add Contact Person(s) to receive surveys for Teammate '" + i_teammateNamePlainText + "'";
    const addedContactNamesComma = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_newContactPersonIDsToAddComma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_add_contact_persons", ["i_captureID", "i_teammateID", "i_teammateNamePlainText", "i_teammateSurveysContactPersonIDsComma", "i_newContactPersonIDsToAddComma"], [i_captureID, i_teammateID, i_teammateNamePlainText, i_teammateSurveysContactPersonIDsComma, i_newContactPersonIDsToAddComma]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates", i_teammateID, "surveys_contact_person_ids_comma", updatedContactPersonIDsComma, "s"); //update comma list to list with the id appended
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "ascp", changeLogField, addedContactNamesComma);
    C_CallPhpTblUID.execute();
  }

  a_teammates_surveys_remove_contact_person(i_captureID, i_teammateID, i_teammateNamePlainText, i_teammateSurveysContactPersonIDsComma, i_contactPersonIDToRemove) {
    const updatedContactPersonIDsComma = JSFUNC.remove_all_values_from_comma_list(i_contactPersonIDToRemove, i_teammateSurveysContactPersonIDsComma);

    const changeLogField = "Remove Contact Person to receive surveys from Teammate '" + i_teammateNamePlainText + "'";
    const removedContactName = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_contactPersonIDToRemove, DatabaseMobx.c_selectContactPersonFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_remove_contact_person", ["i_captureID", "i_teammateID", "i_teammateNamePlainText", "i_teammateSurveysContactPersonIDsComma", "i_contactPersonIDToRemove"], [i_captureID, i_teammateID, i_teammateNamePlainText, i_teammateSurveysContactPersonIDsComma, i_contactPersonIDToRemove]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates", i_teammateID, ["surveys_contact_person_ids_comma"], [updatedContactPersonIDsComma], ["s"]); //update comma list to list with the id removed
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "rscp", changeLogField, removedContactName);
    C_CallPhpTblUID.execute();
  }

  a_teammates_floating_box_survey_send_out_survey_email(i_captureID, i_surveyID, i_teammateID, i_dueDateTimeUTC, i_selectedFileIDsArray, i_teammateSurveysContactPersonIDsComma, i_invitationText, i_functionOnSuccess, i_functionOnError) {
    const c_bitJumbleKey = DatabaseMobx.c_bitJumbleKey;
    const c_topDivision00Name = DatabaseMobx.c_topDivision00Name;
    const c_userEmail = UserMobx.c_userEmail;
    const fullNameNoPower = UserMobx.c_combinedUserObj.fullNameNoPower;

    //get contact person emails from contactPersonIDs selected for this teammate
    const contactPersonIDsArray = JSFUNC.convert_comma_list_to_int_array(i_teammateSurveysContactPersonIDsComma);
    var contactPersonsArrayOfObjs = [];
    for(let contactPersonID of contactPersonIDsArray) {
      var contactPersonObj = ContactsMobx.contact_person_obj_from_id(contactPersonID);
      if(contactPersonObj.email !== "") {
        contactPersonsArrayOfObjs.push(contactPersonObj);
      }
    }

    if(JSFUNC.is_string(c_bitJumbleKey) && (contactPersonsArrayOfObjs.length > 0)) {
      const selectedFileIDsComma = JSFUNC.convert_array_to_comma_list(i_selectedFileIDsArray);

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_floating_box_survey_send_out_survey_email", ["i_captureID", "i_surveyID", "i_teammateID", "i_dueDateTimeUTC", "i_selectedFileIDsArray", "i_teammateSurveysContactPersonIDsComma", "i_invitationText"], [i_captureID, i_surveyID, i_teammateID, i_dueDateTimeUTC, i_selectedFileIDsArray, i_teammateSurveysContactPersonIDsComma, i_invitationText]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const tblName = "tbl_c_teammates_surveys_response_times";
      const whereFieldNameOrNamesArray = ["capture_id", "survey_id", "teammate_id"];
      const whereValueOrValuesArray = [i_captureID, i_surveyID, i_teammateID];
      const whereValueIdsbOrIdsbArray = ["i", "i", "i"];
      const fieldNameOrFieldNamesArray = ["sent_by_user_per_email_id", "file_ids_comma", "sent_datetime_utc", "due_datetime_utc", "first_opened_datetime_utc", "completed_datetime_utc", "not_interested_01"];
      const valueOrValuesArray = [UserMobx.o_userPerEmailID, selectedFileIDsComma, JSFUNC.now_datetime_utc(), i_dueDateTimeUTC, JSFUNC.blank_datetime(), JSFUNC.blank_datetime(), 0];
      const valueIdsbOrIdsbArray = ["i", "s", "s", "s", "s", "s", "i"];
      C_CallPhpTblUID.add_update_existing_otherwise_insert(tblName, whereFieldNameOrNamesArray, whereValueOrValuesArray, whereValueIdsbOrIdsbArray, fieldNameOrFieldNamesArray, valueOrValuesArray, valueIdsbOrIdsbArray);

      const functionOnSuccess = (i_parseResponse) => {
        const emailSubject = "Teaming Survey Invitation sent from " + c_topDivision00Name;
        
        const surveyAddress = this.teammates_build_teammate_survey_website_address(i_captureID, i_surveyID, i_teammateID);
        const emailSurveyLinkHtml = '<br /><br /><a href="' + surveyAddress + '">CaptureExec Teammate Survey Link</a><br />';

        //convert any [[codewords]] that may be in the invitation text to values from this open capture
        const codewordConvertedInvitationText = JSPHP.replace_all_codewords_in_xml_data_string(i_invitationText, this.c_openCaptureMap, undefined);

        const C_CallPhpTblUIDSendEmails = new JSPHP.ClassCallPhpTblUID(jsDescription);

        for(let contactPersonObj of contactPersonsArrayOfObjs) {
          var emailBodyHtml = "Hello " + contactPersonObj.first_name + ",<br /><br />";
          emailBodyHtml += codewordConvertedInvitationText;
          emailBodyHtml += emailSurveyLinkHtml;

          C_CallPhpTblUIDSendEmails.add_email(contactPersonObj.email, "", emailSubject, emailBodyHtml, c_userEmail, fullNameNoPower);
        }

        C_CallPhpTblUIDSendEmails.add_function("onSuccess", i_functionOnSuccess);
        C_CallPhpTblUIDSendEmails.add_function("onError", i_functionOnError);
        C_CallPhpTblUIDSendEmails.execute();
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      C_CallPhpTblUID.add_function("onError", i_functionOnError);

      C_CallPhpTblUID.execute();
    }
  }


  teammates_build_teammate_survey_website_address(i_captureID, i_surveyID, i_teammateID) {
    const c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined = CaptureExecMobx.c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined;
    const c_bitJumbleKey = DatabaseMobx.c_bitJumbleKey;

    const captureIDBase52String = JSFUNC.convert_int_to_base_52_string(i_captureID);
    const teammateIDBase52String = JSFUNC.convert_int_to_base_52_string(i_teammateID);
    const surveyIDBase52String = JSFUNC.convert_int_to_base_52_string(i_surveyID);

    var phpInputGet = "";
    phpInputGet += c_bitJumbleKey.substring(5,6); //6th jumble char
    phpInputGet += captureIDBase52String.substring(5,6);
    phpInputGet += surveyIDBase52String.substring(5,6);
    phpInputGet += teammateIDBase52String.substring(5,6);
    phpInputGet += c_bitJumbleKey.substring(4,5); //5th jumble char
    phpInputGet += captureIDBase52String.substring(4,5);
    phpInputGet += surveyIDBase52String.substring(4,5);
    phpInputGet += teammateIDBase52String.substring(4,5);
    phpInputGet += c_bitJumbleKey.substring(3,4); //4th jumble char
    phpInputGet += captureIDBase52String.substring(3,4);
    phpInputGet += surveyIDBase52String.substring(3,4);
    phpInputGet += teammateIDBase52String.substring(3,4);
    phpInputGet += c_bitJumbleKey.substring(2,3); //3rd jumble char
    phpInputGet += captureIDBase52String.substring(2,3);
    phpInputGet += surveyIDBase52String.substring(2,3);
    phpInputGet += teammateIDBase52String.substring(2,3);
    phpInputGet += c_bitJumbleKey.substring(1,2); //2nd jumble char
    phpInputGet += captureIDBase52String.substring(1,2);
    phpInputGet += surveyIDBase52String.substring(1,2);
    phpInputGet += teammateIDBase52String.substring(1,2);
    phpInputGet += c_bitJumbleKey.substring(0,1); //1st jumble char
    phpInputGet += captureIDBase52String.substring(0,1);
    phpInputGet += surveyIDBase52String.substring(0,1);
    phpInputGet += teammateIDBase52String.substring(0,1);
    
    var surveyAddress = "teamsurveyerror";
    if(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined !== undefined) {
      surveyAddress = c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined + "/team/?i=" + phpInputGet;
    }
    
    return(surveyAddress);
  }


  a_teammates_set_floating_box_survey_teammate_id_and_survey_id(i_teammateID, i_surveyID) {
    this.o_teammatesFloatingBoxSurveyTeammateID = i_teammateID;
    this.o_teammatesFloatingBoxSurveySurveyID = i_surveyID;
  }

  a_teammates_floating_box_survey_update_or_insert_answer_rating_and_comment(i_captureID, i_teammateID, i_questionID, i_newRating, i_newComment) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_floating_box_survey_update_or_insert_answer_rating_and_comment", ["i_captureID", "i_teammateID", "i_questionID", "i_newRating", "i_newComment"], [i_captureID, i_teammateID, i_questionID, i_newRating, i_newComment]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const tblName = "tbl_c_teammates_surveys_answers";
    const whereFieldNameOrNamesArray = ["capture_id", "teammate_id", "question_id"]
    const whereValueOrValuesArray = [i_captureID, i_teammateID, i_questionID];
    const whereValueIdsbOrIdsbArray = ["i", "i", "i"];
    const fieldNameOrFieldNamesArray = ["rating", "comment"];
    const valueOrValuesArray = [i_newRating, i_newComment];
    const valueIdsbOrIdsbArray = ["i", "s"];
    C_CallPhpTblUID.add_update_existing_otherwise_insert(tblName, whereFieldNameOrNamesArray, whereValueOrValuesArray, whereValueIdsbOrIdsbArray, fieldNameOrFieldNamesArray, valueOrValuesArray, valueIdsbOrIdsbArray);

    C_CallPhpTblUID.execute();
  }

  a_teammates_floating_box_survey_update_respone_times_completed_datetime_utc(i_responseTimeObj, i_completedDateTimeUTC) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_floating_box_survey_update_respone_times_completed_datetime_utc", ["i_responseTimeObj", "i_completedDateTimeUTC"], [i_responseTimeObj, i_completedDateTimeUTC]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //determine if the record for this response time exists already
    if((i_responseTimeObj !== undefined) && (i_responseTimeObj.id > 0) && DatabaseMobx.o_tbl_c_teammates_surveys_response_times.has(i_responseTimeObj.id)) {
      C_CallPhpTblUID.add_update("tbl_c_teammates_surveys_response_times", i_responseTimeObj.id, ["completed_datetime_utc"], [i_completedDateTimeUTC], ["s"]);
    }
    else { //insert a new record
      const fieldNamesArray = ["capture_id", "survey_id", "teammate_id", "sent_by_user_per_email_id", "sent_datetime_utc", "due_datetime_utc", "first_opened_datetime_utc", "completed_datetime_utc", "not_interested_01"];
      const valuesArray = [i_responseTimeObj.capture_id, i_responseTimeObj.survey_id, i_responseTimeObj.teammate_id, UserMobx.o_userPerEmailID, JSFUNC.now_datetime_utc(), JSFUNC.now_datetime_utc(), JSFUNC.blank_datetime(), i_completedDateTimeUTC, 0];
      const idsbArray = ["i", "i", "i", "i", "s", "s", "s", "s", "i"];
      C_CallPhpTblUID.add_insert("tbl_c_teammates_surveys_response_times", fieldNamesArray, valuesArray, idsbArray);
    }

    C_CallPhpTblUID.execute();
  }

  a_teammates_floating_box_survey_update_or_insert_unsaved_answer_text_responses(i_captureID, i_teammateID, i_questionTextResponsesArrayOfObjs) {
    if(i_questionTextResponsesArrayOfObjs.length > 0) {
      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_floating_box_survey_update_or_insert_unsaved_answer_text_responses", ["i_captureID", "i_teammateID", "i_questionTextResponsesArrayOfObjs"], [i_captureID, i_teammateID, i_questionTextResponsesArrayOfObjs]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      for(let questionTextResponseObj of i_questionTextResponsesArrayOfObjs) {
        const tblName = "tbl_c_teammates_surveys_answers";
        const whereFieldNameOrNamesArray = ["capture_id", "teammate_id", "question_id"]
        const whereValueOrValuesArray = [i_captureID, i_teammateID, questionTextResponseObj.id];
        const whereValueIdsbOrIdsbArray = ["i", "i", "i"];
        const fieldNameOrFieldNamesArray = ["comment"];
        const valueOrValuesArray = [questionTextResponseObj.text];
        const valueIdsbOrIdsbArray = ["s"];
        C_CallPhpTblUID.add_update_existing_otherwise_insert(tblName, whereFieldNameOrNamesArray, whereValueOrValuesArray, whereValueIdsbOrIdsbArray, fieldNameOrFieldNamesArray, valueOrValuesArray, valueIdsbOrIdsbArray);
      }

      C_CallPhpTblUID.execute();
    }
  }


  a_teammates_surveys_select_survey(i_surveyID) {
    this.o_teammatesSurveysSelectedSurveyID = i_surveyID;
  }

  a_teammates_surveys_select_capabilities_gap_analysis_subtab(i_subtabDbName) {
    this.o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab = i_subtabDbName;
  }

  a_teammates_surveys_create_new_empty_survey_from_new_title(i_captureID, i_newSurveyTitle) {
    const newSurveySort = (this.c_teammatesSurveysIDsArray.length + 1);
    const defaultInvitationText = "Please click the Survey Link below to answer questions we need from your Company to continue analyzing this opportunity. You may forward this invite to any additional people in your Company. Thank you for your time.";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_create_new_empty_survey_from_new_title", ["i_captureID", "i_newSurveyTitle"], [i_captureID, i_newSurveyTitle]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_insert("tbl_c_teammates_surveys", ["capture_id", "sort", "title", "invitation_text"], [i_captureID, newSurveySort, i_newSurveyTitle, defaultInvitationText], ["i", "i", "s", "s"]);

    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "tscs", "Create New Teammate Survey", i_newSurveyTitle);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyCreatedSurveyID = i_parseResponse.outputObj.i0;
      this.a_teammates_surveys_select_survey(newlyCreatedSurveyID); //when complete, set the selected survey as the newly created survey id
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  a_teammates_surveys_update_survey_title(i_captureID, i_surveyID, i_oldSurveyTitle, i_updatedSurveyTitle) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_update_survey_title", ["i_captureID", "i_surveyID", "i_oldSurveyTitle", "i_updatedSurveyTitle"], [i_captureID, i_surveyID, i_oldSurveyTitle, i_updatedSurveyTitle]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates_surveys", i_surveyID, ["title"], [i_updatedSurveyTitle], ["s"]);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "tsust", "Edit Teammate Survey Title", "Changed Survey title from '" + i_oldSurveyTitle + "' to '" + i_updatedSurveyTitle + "'");
    C_CallPhpTblUID.execute();
  }

  a_teammates_surveys_update_survey_invitation_text(i_captureID, i_surveyID, i_updatedInvitationText) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_update_survey_invitation_text", ["i_captureID", "i_surveyID", "i_updatedInvitationText"], [i_captureID, i_surveyID, i_updatedInvitationText]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates_surveys", i_surveyID, ["invitation_text"], [i_updatedInvitationText], ["s"]);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "tsusit", "Edit Teammate Survey Invitation Text", "Changed Survey invitation text to '" + i_updatedInvitationText + "'");
    C_CallPhpTblUID.execute();
  }

  a_teammates_surveys_delete_survey(i_teammatesSurveyObj) {
    //if the deleted survey is currently selected, select the first survey instead
    if(this.o_teammatesSurveysSelectedSurveyID === i_teammatesSurveyObj.id) {
      var newSelectedSurveyID = undefined;
      for(let teammatesSurveyID of this.c_teammatesSurveysIDsArray) {
        if(teammatesSurveyID !== i_teammatesSurveyObj.id) {
          newSelectedSurveyID = teammatesSurveyID;
          break;
        }
      }
      this.o_teammatesSurveysSelectedSurveyID = newSelectedSurveyID;
    }

    //initialize call to delete all parts of this survey
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_delete_survey", ["i_teammatesSurveyObj"], [i_teammatesSurveyObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //determine all questions on this capture/survey (questionsIDs used to delete answers), delete those questions
    const surveyQuestionIDsArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_multiple_fields_and_values(DatabaseMobx.o_tbl_c_teammates_surveys_questions, ["capture_id", "survey_id"], [i_teammatesSurveyObj.capture_id, i_teammatesSurveyObj.id], "id");
    C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys_questions", surveyQuestionIDsArray); //deleting all questions from this survey, no need to resort from delete

    //delete all answers from all teammateIDs for these questionIDs
    if(surveyQuestionIDsArray.length > 0) { //only check for answers if there were any questions
      const surveyAnswerIDsArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_array_of_values(DatabaseMobx.o_tbl_c_teammates_surveys_answers, "question_id", surveyQuestionIDsArray, "id");
      C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys_answers", surveyAnswerIDsArray); //no sort column for answers, no need to resort
    }

    //delete all response times from all teammateIDs for this capture/survey
    const surveyResponseTimeIDsArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_multiple_fields_and_values(DatabaseMobx.o_tbl_c_teammates_surveys_response_times, ["capture_id", "survey_id"], [i_teammatesSurveyObj.capture_id, i_teammatesSurveyObj.id], "id");
    C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys_response_times", surveyResponseTimeIDsArray); //no sort column for response times, no need to resort

    //delete all files attached to this capture/survey, get the fileLocs to also delete the files from the server
    const surveyFilesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_teammates_surveys_filefoldersystem, ["capture_id", "survey_id"], [i_teammatesSurveyObj.capture_id, i_teammatesSurveyObj.id]);
    const surveyFileIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(surveyFilesArrayOfObjs, "id");
    C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys_filefoldersystem", surveyFileIDsArray); //no sort column for files (sorted by file display name), no need to resort

    //delete the survey files from the server
    for(let surveyFileObj of surveyFilesArrayOfObjs) {
      if((surveyFileObj.folder0_file1 === 1) && (surveyFileObj.fileupload0_onlinelink1 === 0)) { //only delete uploaded files on server, not folders or onlinelinkdocs
        if(surveyFileObj.file_loc.substring(0, 40) === "tbl_c_teammates_surveys_filefoldersystem") { //only delete uploaded survey files, not files linked from the documents card ("tbl_c_teammates_surveys_filefoldersystem/capture_id22/survey_id63/20211105200257_book2.xlsx")
          C_CallPhpTblUID.add_file_delete(surveyFileObj.file_loc);
        }
      }
    }

    //delete the survey record
    C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys", i_teammatesSurveyObj.id, "sort", "capture_id", i_teammatesSurveyObj.capture_id);

    C_CallPhpTblUID.add_changelog_details(i_teammatesSurveyObj.capture_id, DatabaseMobx.k_cardIDTeammates, "tsds", "Delete Teammate Survey", i_teammatesSurveyObj.title);

    C_CallPhpTblUID.execute();
  }


  a_teammates_surveys_edit_questions_set_add_new_question_floating_box_is_open_tf(i_newValueTF) {
    this.o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF = i_newValueTF;
  }

  a_teammates_surveys_edit_questions_set_copy_questions_floating_box_is_open_tf(i_newValueTF) {
    this.o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF = i_newValueTF;
  }

  a_teammates_surveys_edit_questions_set_import_questions_floating_box_is_open_tf(i_newValueTF) {
    this.o_teammatesSurveysEditQuestionsImportQuestionsFloatingBoxIsOpenTF = i_newValueTF;
  }

  a_teammates_surveys_insert_csv_imported_new_questions(i_captureID, i_surveyID, i_surveyTitle, i_newSortValuesArray, i_newQuestionTypeIDsArray, i_newQuestionNamesArray, i_runThisFunctionUponSuccess, i_runThisFunctionUponError) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_insert_csv_imported_new_questions", ["i_captureID", "i_surveyID", "i_surveyTitle", "i_newSortValuesArray", "i_newQuestionTypeIDsArray", "i_newQuestionNamesArray"], [i_captureID, i_surveyID, i_surveyTitle, i_newSortValuesArray, i_newQuestionTypeIDsArray, i_newQuestionNamesArray]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["capture_id", "survey_id", "sort", "question_type_id", "name"];
    const idsbArray = ["i", "i", "i", "i", "s"];
    for(let i = 0; i < i_newSortValuesArray.length; i++) {
      var valuesArrayOfArrays = [i_captureID, i_surveyID, i_newSortValuesArray[i], i_newQuestionTypeIDsArray[i], i_newQuestionNamesArray[i]];
      C_CallPhpTblUID.add_insert("tbl_c_teammates_surveys_questions", fieldNamesArray, valuesArrayOfArrays, idsbArray);
    }

    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "tsmiq", "Import Questions to Teammate Survey", "(Survey '" + i_surveyTitle + "') # questions: " + i_newQuestionNamesArray.length);

    const functionOnSuccess = (i_parseResponse) => {
      i_runThisFunctionUponSuccess();
    };
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.add_function("onError", i_runThisFunctionUponError);

    C_CallPhpTblUID.execute();
  }

  a_teammates_surveys_add_new_question(i_captureID, i_surveyID, i_surveyTitle, i_newQuestionSort, i_newQuestionTypeID, i_newQuestionName, i_functionOnBeginWork, i_functionOnSuccess, i_functionOnError) {
    if(JSFUNC.is_function(i_functionOnBeginWork)) {
      i_functionOnBeginWork();
    }

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_add_new_question", ["i_captureID", "i_surveyID", "i_surveyTitle", "i_newQuestionSort", "i_newQuestionTypeID", "i_newQuestionName"], [i_captureID, i_surveyID, i_surveyTitle, i_newQuestionSort, i_newQuestionTypeID, i_newQuestionName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["capture_id", "survey_id", "sort", "question_type_id", "name"];
    const valuesArray = [i_captureID, i_surveyID, i_newQuestionSort, i_newQuestionTypeID, i_newQuestionName];
    const idsbArray = ["i", "i", "i", "i", "s"];
    C_CallPhpTblUID.add_insert("tbl_c_teammates_surveys_questions", fieldNamesArray, valuesArray, idsbArray);

    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTeammates, "tsaq", "Add New Question to Teammate Survey", "(Survey '" + i_surveyTitle + "') - " + i_newQuestionName);

    //when complete, set the selected survey as the newly created survey id
    const functionOnSuccess = (i_parseResponse) => {
      const newlyAddedQuestionID = i_parseResponse.outputObj.i0;
      if(newlyAddedQuestionID > 0) { //successful insert
        if(JSFUNC.is_function(i_functionOnSuccess)) {
          i_functionOnSuccess(newlyAddedQuestionID);
        }
      }
      else {
        if(JSFUNC.is_function(i_functionOnError)) {
          i_functionOnError();
        }
      }
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    const functionOnError = (i_parseResponse) => {
      if(JSFUNC.is_function(i_functionOnError)) {
        i_functionOnError();
      }
    }
    C_CallPhpTblUID.add_function("onError", functionOnError);

    C_CallPhpTblUID.execute();
  }

  a_teammates_surveys_delete_all_questions_from_currently_selected_survey() {
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.c_teammatesSurveysSelectedExpandedSurveyObj;

    if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      const captureID = c_teammatesSurveysSelectedExpandedSurveyObj.capture_id;
      const surveyTitle = c_teammatesSurveysSelectedExpandedSurveyObj.title;
      const questionsArrayOfObjs = c_teammatesSurveysSelectedExpandedSurveyObj.questionsArrayOfObjs;
      if(questionsArrayOfObjs.length > 0) { //if there is at least 1 question to delete
        const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_delete_all_questions_from_currently_selected_survey", [], []);
        const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

        //delete all questions from this capture survey
        var allSurveyQuestionIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(questionsArrayOfObjs, "id");
        C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys_questions", allSurveyQuestionIDsArray); //no need to resort, deleting every question

        //delete all the answers from all teammates to this questionID
        const surveyAnswerIDsArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_array_of_values(DatabaseMobx.o_tbl_c_teammates_surveys_answers, "question_id", allSurveyQuestionIDsArray, "id");
        C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys_answers", surveyAnswerIDsArray); //no sort column for answers, no need to resort

        //add a changelog entry for deleting all questions
        C_CallPhpTblUID.add_changelog_details(captureID, DatabaseMobx.k_cardIDTeammates, "tsdaq", "Delete All Questions in Teammate Survey", "Survey '" + surveyTitle + "'");

        C_CallPhpTblUID.execute();
      }
    }
  }

  a_teammates_surveys_update_question_type_id(i_surveyTitle, i_questionObj, i_updatedQuestionTypeID) {
    const oldQuestionTypeName = this.teammates_surveys_question_type_name_from_question_type_id(i_questionObj.question_type_id);
    const newQuestionTypeName = this.teammates_surveys_question_type_name_from_question_type_id(i_updatedQuestionTypeID);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_update_question_type_id", ["i_surveyTitle", "i_questionObj", "i_updatedQuestionTypeID"], [i_surveyTitle, i_questionObj, i_updatedQuestionTypeID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates_surveys_questions", i_questionObj.id, ["question_type_id"], [i_updatedQuestionTypeID], ["i"]); //update comma list to list with the id removed
    C_CallPhpTblUID.add_changelog_details(i_questionObj.capture_id, DatabaseMobx.k_cardIDTeammates, "tsuqti", "Edit Teammate Survey Question", "(Survey '" + i_surveyTitle + "') - changed Question Type from '" + oldQuestionTypeName + "' to '" + newQuestionTypeName + "'");
    C_CallPhpTblUID.execute();
  }

  a_teammates_surveys_update_question_text(i_surveyTitle, i_questionObj, i_updatedQuestionText) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_update_question_text", ["i_surveyTitle", "i_questionObj", "i_updatedQuestionText"], [i_surveyTitle, i_questionObj, i_updatedQuestionText]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates_surveys_questions", i_questionObj.id, ["name"], [i_updatedQuestionText], ["s"]); //update comma list to list with the id removed
    C_CallPhpTblUID.add_changelog_details(i_questionObj.capture_id, DatabaseMobx.k_cardIDTeammates, "tsuqt", "Edit Teammate Survey Question", "(Survey '" + i_surveyTitle + "') - changed Question from '" + i_questionObj.name + "' to '" + i_updatedQuestionText + "'");
    C_CallPhpTblUID.execute();
  }

  a_teammates_surveys_delete_question(i_captureID, i_surveyID, i_surveyTitle, i_questionObj) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_delete_question", ["i_questionObj"], [i_questionObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //delete the question from this capture survey (resort by captureID/surveyID)
    C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys_questions", i_questionObj.id, "sort", ["capture_id", "survey_id"], [i_captureID, i_surveyID]);

    //delete all the answers from all teammates to this questionID
    const surveyAnswerIDsArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_teammates_surveys_answers, "question_id", i_questionObj.id, "id");
    C_CallPhpTblUID.add_delete("tbl_c_teammates_surveys_answers", surveyAnswerIDsArray); //no sort column for answers, no need to resort

    C_CallPhpTblUID.add_changelog_details(i_questionObj.capture_id, DatabaseMobx.k_cardIDTeammates, "tsds", "Delete Teammate Survey Question", "(Survey '" + i_surveyTitle + "') - " + i_questionObj.name);

    C_CallPhpTblUID.execute();
  }

  teammates_surveys_question_type_name_from_question_type_id(i_questionTypeID) {
    if(i_questionTypeID === 1) { return("Yes/No"); }
    else if(i_questionTypeID === 2) { return("Scale 0-5"); }
    else if(i_questionTypeID === 3) { return("Text Response"); }
    else if(i_questionTypeID === 4) { return("Survey Instructions Block"); }
    return("--Invalid Question Type " + i_questionTypeID + "--");
  }

  a_teammates_surveys_documents_include_files_from_documents_card(i_selectedDocumentsCardFileIDsComma) {
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.c_teammatesSurveysSelectedExpandedSurveyObj;

    if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      const selectedDocumentsCardFileIDsArray = JSFUNC.convert_comma_list_to_int_array(i_selectedDocumentsCardFileIDsComma);
      var selectedDocumentsCardFilesArrayOfMaps = [];
      for(let selectedDocumentsCardFileID of selectedDocumentsCardFileIDsArray) {
        var documentsCardFileMap = this.c_documentsAllFilesMapOfMaps.get(selectedDocumentsCardFileID);
        if(documentsCardFileMap !== undefined) {
          selectedDocumentsCardFilesArrayOfMaps.push(documentsCardFileMap);
        }
      }

      if(selectedDocumentsCardFilesArrayOfMaps.length > 0) {
        const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_teammates_surveys_documents_include_files_from_documents_card", ["i_selectedDocumentsCardFileIDsComma"], [i_selectedDocumentsCardFileIDsComma]);
        const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

        var fieldNamesArray = ["capture_id", "survey_id", "folder0_file1", "parent_folder_id", "fileupload0_onlinelink1", "file_loc", "display_name", "keywords", "notes", "content_unique_lowercase", "upload_date", "upload_user_id", "upload_user_name", "access", "xml_type"];
        var idsbArray = ["i", "i", "i", "i", "i", "s", "s", "s", "s", "s", "s", "i", "s", "s", "s"];
        for(let documentsCardFileMap of selectedDocumentsCardFilesArrayOfMaps) {
          var valuesArray = [];
          valuesArray.push(c_teammatesSurveysSelectedExpandedSurveyObj.capture_id); //capture_id
          valuesArray.push(c_teammatesSurveysSelectedExpandedSurveyObj.id); //survey_id
          valuesArray.push(1); //folder0_file1 [always a file]
          valuesArray.push(-1); //parent_folder_id [always uploaded to the root folder in the survey filefoldersystem]
          valuesArray.push(documentsCardFileMap.get("fileupload0_onlinelink1")); //fileupload0_onlinelink1
          valuesArray.push(documentsCardFileMap.get("file_loc")); //file_loc
          valuesArray.push(documentsCardFileMap.get("display_name")); //display_name
          valuesArray.push(documentsCardFileMap.get("keywords")); //keywords
          valuesArray.push(documentsCardFileMap.get("notes")); //notes
          valuesArray.push(documentsCardFileMap.get("content_unique_lowercase")); //content_unique_lowercase
          valuesArray.push(JSFUNC.now_date()); //upload_date
          valuesArray.push(UserMobx.o_userID); //upload_user_id
          valuesArray.push(UserMobx.c_userName); //upload_user_name
          valuesArray.push(documentsCardFileMap.get("access")); //access
          valuesArray.push(documentsCardFileMap.get("xml_type")); //xml_type

          C_CallPhpTblUID.add_insert("tbl_c_teammates_surveys_filefoldersystem", fieldNamesArray, valuesArray, idsbArray);
        }

        C_CallPhpTblUID.execute();
      }
    }
  }

  a_teammates_set_surveys_working_on_sending_survey_tf(i_newValueTF) {
    this.o_teammatesSurveysWorkingOnSendingSurveyTF = i_newValueTF;
  }

  a_teammates_surveys_survey_results_matrix_set_prepare_survey_to_multiple_teammates_floating_box_is_open_tf(i_newValueTF) {
    this.o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF = i_newValueTF;
  }

  a_teammates_surveys_results_matrix_set_filter_ids_comma(i_resultsMatrixFilterIDsComma) {
    this.o_teammatesSurveysResultsMatrixFilterIDsComma = i_resultsMatrixFilterIDsComma;
  }

  a_teammates_surveys_survey_results_matrix_export_survey_matrix_to_excel_xml(i_DEBUGONLY_singleQuestionRowNumberOrFalse=false) {
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.c_teammatesSurveysSelectedExpandedSurveyObj;
    const c_teammatesSurveysResultsMatrixObj = this.c_teammatesSurveysResultsMatrixObj;

    const allFilterOptionsSelectedTF = c_teammatesSurveysResultsMatrixObj.allFilterOptionsSelectedTF;
    const usAndTeammatesArrayOfObjs = c_teammatesSurveysResultsMatrixObj.usAndTeammatesArrayOfObjs;
    const questionResponsesArrayOfObjs = c_teammatesSurveysResultsMatrixObj.questionResponsesArrayOfObjs;
    const atLeast1TeammateHasAnswered1QuestionTF = c_teammatesSurveysResultsMatrixObj.atLeast1TeammateHasAnswered1QuestionTF;
    const teamStrengthScore = c_teammatesSurveysResultsMatrixObj.teamStrengthScore;
    const teamStrengthBgColor = c_teammatesSurveysResultsMatrixObj.teamStrengthBgColor;

    const debugSingleQuestionTestModeTF = JSFUNC.is_number_not_nan_gte_0(i_DEBUGONLY_singleQuestionRowNumberOrFalse);

    const nowDate = JSFUNC.now_date();

    const srmDatesArrayOfObjs = [
      {name:"Sent Date", dbName:"sent_datetime_utc"},
      {name:"Due Date", dbName:"due_datetime_utc"},
      {name:"First Opened Date", dbName:"first_opened_datetime_utc"},
      {name:"Completed Date", dbName:"completed_datetime_utc"}
    ];

    const numCompanies = usAndTeammatesArrayOfObjs.length;
    const numQuestions = questionResponsesArrayOfObjs.length;
    const expandedColumnCount = (1 + (2 * numCompanies));
    var expandedRowCount = (6 + numQuestions);
    if(debugSingleQuestionTestModeTF) {
      expandedRowCount = 6;
      if(i_DEBUGONLY_singleQuestionRowNumberOrFalse > 0) {
        expandedRowCount = 7;
      }
    }
    const cornerTitle = JSFUNC.htmlspecialchars("Teammate Survey Results Matrix for Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "', generated on " + JSFUNC.now_date());

    var srmXml = '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?>';
    srmXml += '<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:html="http://www.w3.org/TR/REC-html40">';
    srmXml += '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>CaptureExec</Author><LastAuthor>CaptureExec</LastAuthor><Created>' + nowDate + 'T12:00:00Z</Created><Version>12.00</Version></DocumentProperties>';
    srmXml += '<ExcelWorkbook xmlns="urn:schemas-microsoft-com:office:excel"><WindowHeight>11511</WindowHeight><WindowWidth>23520</WindowWidth><WindowTopX>480</WindowTopX><WindowTopY>94</WindowTopY><ProtectStructure>False</ProtectStructure><ProtectWindows>False</ProtectWindows></ExcelWorkbook>';
    srmXml += '<Styles><Style ss:ID="Default" ss:Name="Normal"><Alignment ss:Vertical="Bottom"/><Borders/><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="11" ss:Color="#000000"/><Interior/><NumberFormat/><Protection/></Style><Style ss:ID="s62"><NumberFormat ss:Format="Short Date"/></Style><Style ss:ID="s63"><NumberFormat ss:Format="Short Date"/></Style><Style ss:ID="s64"><NumberFormat ss:Format="d\-mmm"/></Style><Style ss:ID="s65"><NumberFormat/></Style></Styles>';
    srmXml += '<Worksheet ss:Name="' + JSFUNC.htmlspecialchars(c_teammatesSurveysSelectedExpandedSurveyObj.title) + '">';
    srmXml += '<Table ss:ExpandedColumnCount="' + expandedColumnCount + '" ss:ExpandedRowCount="' + expandedRowCount + '" x:FullColumns="1" x:FullRows="1" ss:DefaultColumnWidth="53" ss:DefaultRowHeight="14.571428571428571">';

    //headers
    srmXml += '<Row ss:AutoFitHeight="0">';
    srmXml += '<Cell><Data ss:Type="String">' + cornerTitle + '</Data></Cell>';
    for(let teammateObj of usAndTeammatesArrayOfObjs) {
      srmXml += '<Cell><Data ss:Type="String">' + JSFUNC.htmlspecialchars(teammateObj.name) + '</Data></Cell>';
      srmXml += '<Cell><Data ss:Type="String">[comments]</Data></Cell>';
    }
    srmXml += '</Row>';

    //status
    srmXml += '<Row ss:AutoFitHeight="0">';
    srmXml += '<Cell><Data ss:Type="String">Survey Status</Data></Cell>';
    for(let teammateObj of usAndTeammatesArrayOfObjs) {
      srmXml += '<Cell ss:StyleID="s64"><Data ss:Type="String">' + JSFUNC.htmlspecialchars(teammateObj.selectedSurveyResponseTimeObj.responseStatus) + '</Data></Cell>';
      srmXml += '<Cell ss:StyleID="s64"><Data ss:Type="String"></Data></Cell>';
    }
    srmXml += '</Row>';

    //dates
    if(!debugSingleQuestionTestModeTF) {
      for(let srmDateObj of srmDatesArrayOfObjs) {
        srmXml += '<Row ss:AutoFitHeight="0">';
        srmXml += '<Cell><Data ss:Type="String">' + srmDateObj.name + '</Data></Cell>';
        for(let teammateObj of usAndTeammatesArrayOfObjs) {
          if(teammateObj.responseExistsAndSurveyIsSentTF) {
            var srmDateValueRaw = teammateObj.selectedSurveyResponseTimeObj[srmDateObj.dbName];
            if(JSFUNC.datetime_is_filled_out_tf(srmDateValueRaw)) {
              var excelDateTimeLocal = JSFUNC.convert_natural_mysqldatetimeutc_to_excel_datetime_local(srmDateValueRaw, false);
              srmXml += '<Cell ss:StyleID="s62"><Data ss:Type="DateTime">' + excelDateTimeLocal + '</Data></Cell>';
            }
            else {
              srmXml += '<Cell ss:StyleID="s64"><Data ss:Type="String">-</Data></Cell>';
            }
          }
          else {
            srmXml += '<Cell ss:StyleID="s64"><Data ss:Type="String">unsent</Data></Cell>';
          }
          
          srmXml += '<Cell ss:StyleID="s64"><Data ss:Type="String"></Data></Cell>';
        }
        srmXml += '</Row>';
      }
    }

    //questions
    var q = 1;
    for(let questionResponseObj of questionResponsesArrayOfObjs) {
      if((!debugSingleQuestionTestModeTF) || (q === i_DEBUGONLY_singleQuestionRowNumberOrFalse)) {
        srmXml += '<Row ss:AutoFitHeight="0">';
        srmXml += '<Cell><Data ss:Type="String">' + JSFUNC.htmlspecialchars(questionResponseObj.name) + '</Data></Cell>';
        for(let teammateAnswerObj of questionResponseObj.teammateAnswersArrayOfObjs) {
          var score = "";
          var comment = "";
          if(teammateAnswerObj !== undefined) {
            if(questionResponseObj.typeIsYesNoTF) {
              if(teammateAnswerObj.rating === 0) {
                score = "N";
              }
              else if(teammateAnswerObj.rating === 1) {
                score = "Y";
              }
              else {
                score = "-";
              }
              comment = teammateAnswerObj.comment;
            }
            else if(questionResponseObj.typeIsScale05TF) {
              if(JSFUNC.is_number(teammateAnswerObj.rating) && (teammateAnswerObj.rating >= 0) && (teammateAnswerObj.rating <= 5)) {
                score = teammateAnswerObj.rating;
              }
              else {
                score = "-";
              }
              comment = teammateAnswerObj.comment;
            }
            else if(questionResponseObj.typeIsTextResponseTF) {
              score = teammateAnswerObj.comment;
              comment = "";
            }
          }
          srmXml += '<Cell ss:StyleID="s64"><Data ss:Type="String">' + JSFUNC.htmlspecialchars(JSFUNC.trim_excel_cell_max_chars(score)) + '</Data></Cell>';
          srmXml += '<Cell ss:StyleID="s64"><Data ss:Type="String">' + JSFUNC.htmlspecialchars(JSFUNC.trim_excel_cell_max_chars(comment)) + '</Data></Cell>';
        }
        srmXml += '</Row>';
      }
      q++
    }

    srmXml += '</Table>';
    srmXml += '<WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">';
    srmXml += '<PageSetup><Header x:Margin="0.3"/><Footer x:Margin="0.3"/><PageMargins x:Bottom="0.75" x:Left="0.7" x:Right="0.7" x:Top="0.75"/></PageSetup>';
    srmXml += '<Unsynced/>';
    srmXml += '<Print><ValidPrinterInfo/><HorizontalResolution>600</HorizontalResolution><VerticalResolution>600</VerticalResolution></Print>';
    srmXml += '<Selected/>';
    srmXml += '<ProtectObjects>False</ProtectObjects><ProtectScenarios>False</ProtectScenarios>';
    srmXml += '</WorksheetOptions>';
    srmXml += '</Worksheet>';
    srmXml += '</Workbook>';
    
    var downloadFileNameAndExt = "Survey Results Matrix";
    if(debugSingleQuestionTestModeTF) {
      downloadFileNameAndExt += " row" + JSFUNC.zero_pad_integer_from_left(i_DEBUGONLY_singleQuestionRowNumberOrFalse, 4);
    }
    downloadFileNameAndExt += " " + JSFUNC.now_date() + " - Survey " + c_teammatesSurveysSelectedExpandedSurveyObj.title + ".xml";
    
    JSFUNC.browser_offer_file_download_from_file_data_string(srmXml, downloadFileNameAndExt);
  }








  //Competitors card
  a_set_expanded_competitor_id(i_competitorExpandedID) {
    if(this.o_competitorExpandedID === i_competitorExpandedID) {
      this.o_competitorExpandedID = undefined;
    }
    else {
      this.o_competitorExpandedID = i_competitorExpandedID;
    }
  }

  a_competitors_update_changed_field_value(i_captureID, i_competitorObj, i_fieldDbName, i_fieldDisplayName, i_fieldIdsb, i_newValue) {
    const competitorName = ContactsMobx.contact_name_plaintext_from_contact_obj(i_competitorObj.contactCompanyObj);
    const changeLogField = "Update " + i_fieldDisplayName + " for " + competitorName;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_competitors_update_changed_field_value", ["i_captureID", "i_competitorObj", "i_fieldDbName", "i_fieldDisplayName", "i_fieldIdsb", "i_newValue"], [i_captureID, i_competitorObj, i_fieldDbName, i_fieldDisplayName, i_fieldIdsb, i_newValue]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_competitors", i_competitorObj.id, i_fieldDbName, i_newValue, i_fieldIdsb);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDCompetitors, i_fieldDbName, changeLogField, i_newValue);
    C_CallPhpTblUID.execute();
  }

  a_competitors_insert_new_competitors(i_captureID, i_selectedContactCompanyIDsComma) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_competitors_insert_new_competitors", ["i_captureID", "i_selectedContactCompanyIDsComma"], [i_captureID, i_selectedContactCompanyIDsComma]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const selectedContactCompanyIDsArray = JSFUNC.convert_comma_list_to_int_array(i_selectedContactCompanyIDsComma);
    var sortNum = (this.c_competitorsArrayOfObjs.length + 1);
    for(let contactCompanyID of selectedContactCompanyIDsArray) {
      var fieldNamesArray = ["capture_id", "sort", "contact_company_id", "teammates_contact_company_ids_comma", "strengths", "weaknesses", "opportunities", "threats"];
      var valuesArray = [i_captureID, sortNum, contactCompanyID, "", "", "", "", ""];
      var valuesIdsbArray = ["i", "i", "i", "s", "s", "s", "s", "s"];

      //add the competitor extra fields to initialize those values
      for(let competitorExtraFieldObj of this.c_competitorsExtraFieldsArrayOfObjs) {
        fieldNamesArray.push(competitorExtraFieldObj.db_name);
        valuesArray.push("");
        valuesIdsbArray.push(competitorExtraFieldObj.fieldTypeObj.idsb);
      }

      C_CallPhpTblUID.add_insert("tbl_c_competitors", fieldNamesArray, valuesArray, valuesIdsbArray);
      sortNum++;
    }

    const selectedCompetitorContactCompanyNames = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_selectedContactCompanyIDsComma, DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDCompetitors, "ac", "Add New Competitor(s)", selectedCompetitorContactCompanyNames);

    C_CallPhpTblUID.execute();
  }

  a_competitors_delete_competitor(i_captureID, i_competitorObj) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_competitors_delete_competitor", ["i_captureID", "i_competitorObj"], [i_captureID, i_competitorObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_c_competitors", i_competitorObj.id, "sort", "capture_id", i_captureID);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDCompetitors, "rc", "Remove Competitor", ContactsMobx.contact_name_plaintext_from_contact_obj(i_competitorObj.contactCompanyObj));
    C_CallPhpTblUID.execute();

    //if this competitor was marked as incumbent, remove that contact company id from the list of incumbents
    this.a_competitors_remove_incumbent_marking_from_capture_id_and_contact_company_id(i_captureID, i_competitorObj.contact_company_id);
  }

  a_competitors_remove_incumbent_marking_from_capture_id_and_contact_company_id(i_captureID, i_contactCompanyID) {
    const c_captureIncumbentContactCompanyIDsArray = this.c_captureIncumbentContactCompanyIDsArray;
    if(JSFUNC.in_array(i_contactCompanyID, c_captureIncumbentContactCompanyIDsArray)) {
      const updatedIncumbentContactCompanyIDsArray = JSFUNC.remove_all_values_from_array(i_contactCompanyID, c_captureIncumbentContactCompanyIDsArray);
      const updatedIncumbentContactCompanyIDsComma = JSFUNC.convert_array_to_comma_list(updatedIncumbentContactCompanyIDsArray);
      this.a_details_update_field_value(DatabaseMobx.c_fieldMapOfIncumbentCompanies, updatedIncumbentContactCompanyIDsComma, i_captureID);
    }
  }

  a_competitors_add_teammate_contact_companies(i_captureID, i_competitorObj, i_newContactCompanyIDsToAddComma) {
    const updatedContactPersonIDsComma = JSFUNC.add_value_to_comma_list(i_newContactCompanyIDsToAddComma, i_competitorObj.teammates_contact_company_ids_comma);

    const changeLogField = "Add teammates for competitor '" + ContactsMobx.contact_name_plaintext_from_contact_obj(i_competitorObj.contactCompanyObj) + "'";
    const addedContactNamesComma = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_newContactCompanyIDsToAddComma, DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_competitors_add_teammate_contact_companies", ["i_captureID", "i_competitorObj", "i_newContactCompanyIDsToAddComma"], [i_captureID, i_competitorObj, i_newContactCompanyIDsToAddComma]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_competitors", i_competitorObj.id, "teammates_contact_company_ids_comma", updatedContactPersonIDsComma, "s"); //update comma list to list with the id appended
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDCompetitors, "act", changeLogField, addedContactNamesComma);
    C_CallPhpTblUID.execute();
  }

  a_competitors_remove_teammate_contact_company(i_captureID, i_competitorObj, i_companyContactIDToRemove) {
    const updatedContactPersonIDsComma = JSFUNC.remove_all_values_from_comma_list(i_companyContactIDToRemove, i_competitorObj.teammates_contact_company_ids_comma);

    const changeLogField = "Remove teammates for competitor '" + ContactsMobx.contact_name_plaintext_from_contact_obj(i_competitorObj.contactCompanyObj) + "'";
    const contactName = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_companyContactIDToRemove, DatabaseMobx.c_selectContactCompanyFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_competitors_remove_teammate_contact_company", ["i_captureID", "i_competitorObj", "i_companyContactIDToRemove"], [i_captureID, i_competitorObj, i_companyContactIDToRemove]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_competitors", i_competitorObj.id, "teammates_contact_company_ids_comma", updatedContactPersonIDsComma, "s"); //update comma list to list with the id removed
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDCompetitors, "rct", changeLogField, contactName);
    C_CallPhpTblUID.execute();
  }




  //Proposal Themes card
  a_pt_differentiators_set_id_adding_or_editing(i_idAddingOrEditing) {
    this.o_ptDifferentiatorIDAddingOrEditing = i_idAddingOrEditing;
  }
  a_pt_win_themes_set_id_adding_or_editing(i_idAddingOrEditing) {
    this.o_ptWinThemeIDAddingOrEditing = i_idAddingOrEditing;
  }
  a_pt_ghost_themes_set_id_adding_or_editing(i_idAddingOrEditing) {
    this.o_ptGhostThemeIDAddingOrEditing = i_idAddingOrEditing;
  }

  a_differentiators_insert_new_differentiator(i_captureID, i_differentiator, i_justification) {
    const numExistingCaptureDifferentiators = this.c_ptDifferentiatorsArrayOfObjs.length;

    var fieldNamesArray = ["capture_id", "sort", "differentiator_id", "justification"];
    var valuesArray = [i_captureID, (numExistingCaptureDifferentiators + 1), i_differentiator, i_justification];
    var valuesIdsbArray = ["i", "i", "i", "s"];

    const differentiatorName = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(i_differentiator, DatabaseMobx.c_selectAddPTDifferentiatorsFieldTypeObj);
    const insertDifferentiatorChangeLogString = "Differentiator: '" + differentiatorName + "', Justification: '" + i_justification + "'";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_differentiators_insert_new_differentiator", ["i_captureID", "i_differentiator", "i_justification"], [i_captureID, i_differentiator, i_justification]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert("tbl_c_pt_differentiators", fieldNamesArray, valuesArray, valuesIdsbArray);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDProposalThemes, "and", "Add New Differentiator", insertDifferentiatorChangeLogString);
    C_CallPhpTblUID.execute();
  }

  a_differentiators_update_differentiator(i_captureID, i_differentiatorID, i_differentiator, i_justification) {
    var fieldNamesArray = [];
    var valuesArray = [];
    var valuesIdsbArray = [];
    var updateDifferentiatorChangeLogArray = [];

    if(i_differentiator !== undefined) {
      fieldNamesArray.push("differentiator_id");
      valuesArray.push(i_differentiator);
      valuesIdsbArray.push("i");

      const differentiatorName = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(i_differentiator, DatabaseMobx.c_selectAddPTDifferentiatorsFieldTypeObj);
      updateDifferentiatorChangeLogArray.push("Differentiator: '" + differentiatorName);
    }

    if(i_justification !== undefined) {
      fieldNamesArray.push("justification");
      valuesArray.push(i_justification);
      valuesIdsbArray.push("s");
      updateDifferentiatorChangeLogArray.push("Justification: '" + i_justification);
    }

    if(fieldNamesArray.length > 0) { //if none of the fields have been changed, do not do an update/changelog operation
      const  updateDifferentiatorChangeLogString = updateDifferentiatorChangeLogArray.join(", ");

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_differentiators_update_differentiator", ["i_captureID", "i_differentiatorID", "i_differentiator", "i_justification"], [i_captureID, i_differentiatorID, i_differentiator, i_justification]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_c_pt_differentiators", i_differentiatorID, fieldNamesArray, valuesArray, valuesIdsbArray);
      C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDProposalThemes, "ud", "Update Differentiator", updateDifferentiatorChangeLogString);
      C_CallPhpTblUID.execute();
    }
  }

  a_differentiators_delete_differentiator(i_captureID, i_differentiatorObj) {
    const deleteDifferentiatorChangeLogString = "Differentiator: '" + i_differentiatorObj.differentiatorName + "', Justification: '" + i_differentiatorObj.justification + "'";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_differentiators_delete_differentiator", ["i_captureID", "i_differentiatorObj"], [i_captureID, i_differentiatorObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_c_pt_differentiators", i_differentiatorObj.id, "sort", "capture_id", i_captureID);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDProposalThemes, "rd", "Remove Differentiator", deleteDifferentiatorChangeLogString);
    C_CallPhpTblUID.execute();
  }

  a_themes_insert_new_theme(i_captureID, i_winTrueGhostFalse, i_theme, i_justification) {
    const themesArrayOfObjs = ((i_winTrueGhostFalse) ? (this.c_ptWinThemesArrayOfObjs) : (this.c_ptGhostThemesArrayOfObjs));
    const numExistingCaptureThemes = themesArrayOfObjs.length;
    const tblName = ((i_winTrueGhostFalse) ? ("tbl_c_pt_win_themes") : ("tbl_c_pt_ghost_themes"));

    var fieldNamesArray = ["capture_id", "sort", "theme", "justification"];
    var valuesArray = [i_captureID, (numExistingCaptureThemes + 1), i_theme, i_justification];
    var valuesIdsbArray = ["i", "i", "s", "s"];

    const insertThemeChangeLogString = "Theme: '" + i_theme + "', Justification: '" + i_justification + "'";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_themes_insert_new_theme", ["i_captureID", "i_theme", "i_justification"], [i_captureID, i_theme, i_justification]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert(tblName, fieldNamesArray, valuesArray, valuesIdsbArray);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDProposalThemes, "ant", "Add New Theme", insertThemeChangeLogString);
    C_CallPhpTblUID.execute();
  }

  a_themes_update_theme(i_captureID, i_winTrueGhostFalse, i_themeID, i_theme, i_justification) {
    const tblName = ((i_winTrueGhostFalse) ? ("tbl_c_pt_win_themes") : ("tbl_c_pt_ghost_themes"));

    var fieldNamesArray = [];
    var valuesArray = [];
    var valuesIdsbArray = [];
    var updateThemeChangeLogArray = [];

    if(i_theme !== undefined) {
      fieldNamesArray.push("theme");
      valuesArray.push(i_theme);
      valuesIdsbArray.push("s");
      updateThemeChangeLogArray.push("Theme: '" + i_theme);
    }

    if(i_justification !== undefined) {
      fieldNamesArray.push("justification");
      valuesArray.push(i_justification);
      valuesIdsbArray.push("s");
      updateThemeChangeLogArray.push("Justification: '" + i_justification);
    }

    if(fieldNamesArray.length > 0) { //if none of the fields have been changed, do not do an update/changelog operation
      const  updateThemeChangeLogString = updateThemeChangeLogArray.join(", ");

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_themes_update_theme", ["i_captureID", "i_themeID", "i_theme", "i_justification"], [i_captureID, i_themeID, i_theme, i_justification]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update(tblName, i_themeID, fieldNamesArray, valuesArray, valuesIdsbArray);
      C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDProposalThemes, "ut", "Update Theme", updateThemeChangeLogString);
      C_CallPhpTblUID.execute();
    }
  }

  a_themes_delete_theme(i_captureID, i_winTrueGhostFalse, i_themeObj) {
    const tblName = ((i_winTrueGhostFalse) ? ("tbl_c_pt_win_themes") : ("tbl_c_pt_ghost_themes"));
    const deleteThemeChangeLogString = "Theme: '" + i_themeObj.theme + "', Justification: '" + i_themeObj.justification + "'";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_themes_delete_theme", ["i_captureID", "i_themeObj"], [i_captureID, i_themeObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete(tblName, i_themeObj.id, "sort", "capture_id", i_captureID);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDProposalThemes, "rt", "Remove Theme", deleteThemeChangeLogString);
    C_CallPhpTblUID.execute();
  }





  //Risk Assessment card
  a_risks_insert_new_risk(i_captureID, i_categoryID, i_probability, i_impact, i_risk, i_mitigation) {
    const numExistingCaptureRisks = this.c_risksArrayOfObjs.length;

    var fieldNamesArray = ["capture_id", "sort", "risk_category_id", "probability_risk_level_id", "impact_risk_level_id", "identified_risk", "mitigation_approach"];
    var valuesArray = [i_captureID, (numExistingCaptureRisks + 1), i_categoryID, i_probability, i_impact, i_risk, i_mitigation];
    var valuesIdsbArray = ["i", "i", "i", "i", "i", "s", "s"];

    var categoryName = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(i_categoryID, DatabaseMobx.c_selectRisksCategoriesFieldTypeObj, true);
    var probabilityRiskLevelNameColorObj = this.mask_risk_level_name_and_color_obj_from_id(i_probability, true);
    var impactRiskLevelNameColorObj = this.mask_risk_level_name_and_color_obj_from_id(i_impact, false);
    const insertRiskChangeLogString = "Category: '" + categoryName + "', Probability: '" + probabilityRiskLevelNameColorObj.name + "', Impact: '" + impactRiskLevelNameColorObj.name + "', Risk: '" + i_risk + "', Mitigation: '" + i_mitigation + "'";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_risks_insert_new_risk", ["i_captureID", "i_categoryID", "i_probability", "i_impact", "i_risk", "i_mitigation"], [i_captureID, i_categoryID, i_probability, i_impact, i_risk, i_mitigation]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert("tbl_c_risks", fieldNamesArray, valuesArray, valuesIdsbArray);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDRiskAssessment, "anri", "Add New Risk Item", insertRiskChangeLogString);
    C_CallPhpTblUID.execute();
  }

  a_risks_update_risk(i_captureID, i_riskID, i_categoryID, i_probability, i_impact, i_risk, i_mitigation) {
    var fieldNamesArray = [];
    var valuesArray = [];
    var valuesIdsbArray = [];
    var updateRiskChangeLogArray = [];

    if(i_categoryID !== undefined) {
      fieldNamesArray.push("risk_category_id");
      valuesArray.push(i_categoryID);
      valuesIdsbArray.push("i");

      const categoryName = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(i_categoryID, DatabaseMobx.c_selectRisksCategoriesFieldTypeObj, true);
      updateRiskChangeLogArray.push("Category: '" + categoryName);
    }

    if(i_probability !== undefined) {
      fieldNamesArray.push("probability_risk_level_id");
      valuesArray.push(i_probability);
      valuesIdsbArray.push("i");

      const probabilityRiskLevelNameColorObj = this.mask_risk_level_name_and_color_obj_from_id(i_probability, true);
      updateRiskChangeLogArray.push("Probability: '" + probabilityRiskLevelNameColorObj.name);
    }

    if(i_impact !== undefined) {
      fieldNamesArray.push("impact_risk_level_id");
      valuesArray.push(i_impact);
      valuesIdsbArray.push("i");

      const impactRiskLevelNameColorObj = this.mask_risk_level_name_and_color_obj_from_id(i_impact, false);
      updateRiskChangeLogArray.push("Impact: '" + impactRiskLevelNameColorObj.name);
    }

    if(i_risk !== undefined) {
      fieldNamesArray.push("identified_risk");
      valuesArray.push(i_risk);
      valuesIdsbArray.push("s");
      updateRiskChangeLogArray.push("Risk: '" + i_risk);
    }

    if(i_mitigation !== undefined) {
      fieldNamesArray.push("mitigation_approach");
      valuesArray.push(i_mitigation);
      valuesIdsbArray.push("s");
      updateRiskChangeLogArray.push("Mitigation: '" + i_mitigation);
    }

    if(fieldNamesArray.length > 0) { //if none of the fields have been changed, do not do an update/changelog operation
      const  updateRiskChangeLogString = updateRiskChangeLogArray.join(", ");

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_risks_update_risk", ["i_captureID", "i_riskID", "i_categoryID", "i_probability", "i_impact", "i_risk", "i_mitigation"], [i_captureID, i_riskID, i_categoryID, i_probability, i_impact, i_risk, i_mitigation]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_c_risks", i_riskID, fieldNamesArray, valuesArray, valuesIdsbArray);
      C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDRiskAssessment, "uri", "Update Risk Item", updateRiskChangeLogString);
      C_CallPhpTblUID.execute();
    }
  }

  a_risks_delete_risk(i_captureID, i_riskObj) {
    const deleteRiskChangeLogString = "Probability: '" + i_riskObj.probabilityRiskLevelName + "', Impact: '" + i_riskObj.impactRiskLevelName + "', Risk: '" + i_riskObj.identified_risk + "', Mitigation: '" + i_riskObj.mitigation_approach + "'";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_risks_delete_risk", ["i_captureID", "i_riskObj"], [i_captureID, i_riskObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_c_risks", i_riskObj.id, "sort", "capture_id", i_captureID);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDRiskAssessment, "rri", "Remove Risk Item", deleteRiskChangeLogString);
    C_CallPhpTblUID.execute();
  }





  //Budget card
  a_budget_set_selected_budget_category_id(i_budgetCategoryID) {
    this.o_budgetSelectedBudgetCategoryID = i_budgetCategoryID;
  }

  a_budget_set_mobile_page(i_mobilePage) {
    this.o_budgetMobilePage = i_mobilePage;
  }

  a_budget_set_editing_funding_request_id(i_fundingRequestID) {
    this.o_budgetEditingFundingRequestID = i_fundingRequestID;
  }

  a_budget_create_new_empty_funding_request(i_captureID, i_budgetCategoryID, i_functionOnSuccess=undefined, i_functionOnError=undefined) {
    const nowDate = JSFUNC.now_date();
    const blankDate = JSFUNC.blank_date();
    const fieldNamesArray = ["capture_id", "budget_category_id", "nr0_rq1_rj2_rs3_ap4", "rq_date", "rq_user_id", "rj_date", "rj_user_id", "rs_date", "rs_user_id", "ap_date", "ap_user_id", "cm_notes", "bm_notes"];
    const valuesArray = [i_captureID, i_budgetCategoryID, 0, nowDate, UserMobx.o_userID, blankDate, -1, blankDate, -1, blankDate, -1, "", ""];
    const idsbArray = ["i", "i", "i", "s", "i", "s", "i", "s", "i", "s", "i", "s", "s"];

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_create_new_empty_funding_request", ["i_captureID", "i_budgetCategoryID"], [i_captureID, i_budgetCategoryID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert("tbl_c_budget_funding_requests", fieldNamesArray, valuesArray, idsbArray);

    if(i_functionOnSuccess !== undefined) {
      const functionOnSuccessNewInsertIDInput = (i_parseResponse) => {
        const newlyInsertedEntryID = i_parseResponse.outputObj.i0;
        if(newlyInsertedEntryID > 0) { //successful tblUID insert
          i_functionOnSuccess(newlyInsertedEntryID);
        }
        else {
          if(i_functionOnError !== undefined) {
            i_functionOnError();
          }
        }
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccessNewInsertIDInput);
    }

    if(i_functionOnError !== undefined) {
      C_CallPhpTblUID.add_function("onError", i_functionOnError);
    }

    C_CallPhpTblUID.execute();
  }

  a_budget_create_new_funding_request_return_unspent_funding(i_captureID, i_budgetCategoryID, i_totalRemaining) {
    const nowDate = JSFUNC.now_date();
    const blankDate = JSFUNC.blank_date();
    const fieldNamesArray = ["capture_id", "budget_category_id", "nr0_rq1_rj2_rs3_ap4", "rq_date", "rq_user_id", "rj_date", "rj_user_id", "rs_date", "rs_user_id", "ap_date", "ap_user_id", "cm_notes", "bm_notes"];
    const valuesArray = [i_captureID, i_budgetCategoryID, 4, nowDate, UserMobx.o_userID, blankDate, -1, blankDate, -1, nowDate, UserMobx.o_userID, "Returning unspent funding", ""];
    const idsbArray = ["i", "i", "i", "s", "i", "s", "i", "s", "i", "s", "i", "s", "s"];

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_create_new_funding_request_return_unspent_funding", ["i_captureID", "i_budgetCategoryID", "i_totalRemaining"], [i_captureID, i_budgetCategoryID, i_totalRemaining]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert("tbl_c_budget_funding_requests", fieldNamesArray, valuesArray, idsbArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedEntryID = i_parseResponse.outputObj.i0;

      const negativeTotalRemaining = (-1 * i_totalRemaining);

      const expenseFieldNamesArray = ["capture_id", "budget_category_id", "funding_request_id", "date", "expense_type_id", "description", "value"];
      const expenseValuesArray = [i_captureID, i_budgetCategoryID, newlyInsertedEntryID, nowDate, -1, "Returning unspent funding", negativeTotalRemaining];
      const expenseIdsbArray = ["i", "i", "i", "s", "i", "s", "i"];

      const changeLogField = "Funding Request";
      const totalRemainingMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_totalRemaining, DatabaseMobx.c_genericMoneyFieldTypeObj);
      const selectedBudgetCategoryObj = this.c_budgetSelectedExpandedBudgetCategoryObj;
      const changeLogValue = "Returning " + totalRemainingMaskPlainText + " of unspent funding for '" + selectedBudgetCategoryObj.name + "' budget";

      const captureMap = DatabaseMobx.o_tbl_captures.get(i_captureID);
      const captureBudgetManagerUserID = ((captureMap !== undefined) ? (captureMap.get("budget_manager_user_id")) : (-1));
      const captureName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);
      const notificationMessage = UserMobx.c_userName + " has returned " + totalRemainingMaskPlainText + " of unspent funding to the '" + selectedBudgetCategoryObj.name + "' budget regarding Capture '" + captureName + "'";
      const notificationClickActionString = "openFundingRequestAsBudgetManager:" + newlyInsertedEntryID;

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_create_new_funding_request_return_unspent_funding", ["i_captureID", "i_budgetCategoryID", "i_totalRemaining"], [i_captureID, i_budgetCategoryID, i_totalRemaining]);
      const C_CallPhpTblUIDInsertExpense = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUIDInsertExpense.add_insert("tbl_c_budget_expenses", expenseFieldNamesArray, expenseValuesArray, expenseIdsbArray);
      C_CallPhpTblUIDInsertExpense.add_changelog_budget(i_captureID, newlyInsertedEntryID, changeLogField, changeLogValue);
      if(captureBudgetManagerUserID > 0) {
        C_CallPhpTblUID.add_notifications(captureBudgetManagerUserID, false, notificationMessage, notificationClickActionString);
      }
      C_CallPhpTblUIDInsertExpense.execute();
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  a_budget_update_funding_request_field(i_fundingRequestID, i_fieldName, i_value, i_idsb) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_update_funding_request_field", ["i_fundingRequestID", "i_fieldName", "i_value", "i_idsb"], [i_fundingRequestID, i_fieldName, i_value, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_budget_funding_requests", i_fundingRequestID, i_fieldName, i_value, i_idsb);
    C_CallPhpTblUID.execute();
  }

  a_budget_delete_funding_request(i_fundingRequestObj) {
    var fundingRequestExpenseIDsArray = [];
    for(let expenseMap of DatabaseMobx.o_tbl_c_budget_expenses.values()) {
      if(expenseMap.get("funding_request_id") === i_fundingRequestObj.id) {
        fundingRequestExpenseIDsArray.push(expenseMap.get("id"));
      }
    }

    const changeLogField = "Funding Request";
    const totalExpensesMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_fundingRequestObj.totalExpenses, DatabaseMobx.c_genericMoneyFieldTypeObj);
    const selectedBudgetCategoryObj = this.c_budgetSelectedExpandedBudgetCategoryObj;
    const changeLogValue = "Deleted funding request for " + totalExpensesMaskPlainText + " from '" + selectedBudgetCategoryObj.name + "' budget";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_delete_funding_request", ["i_fundingRequestObj"], [i_fundingRequestObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_c_budget_expenses", fundingRequestExpenseIDsArray); //no sort column (budget expenses sorted by date), no need for resort after delete
    C_CallPhpTblUID.add_delete("tbl_c_budget_funding_requests", i_fundingRequestObj.id); //no sort column (budget funding requests sorted by date), no need for resort after delete
    C_CallPhpTblUID.add_changelog_budget(i_fundingRequestObj.capture_id, i_fundingRequestObj.id, changeLogField, changeLogValue);
    C_CallPhpTblUID.execute();
  }





  //Conversations card
  a_conversations_insert_new_conversation(i_captureID, i_datetimeUTC, i_contactPersonIDsComma, i_notes) {
    var fieldNamesArray = ["capture_id", "datetime_utc", "contact_person_ids_comma", "notes"];
    var valuesArray = [i_captureID, i_datetimeUTC, i_contactPersonIDsComma, i_notes];
    var valuesIdsbArray = ["i", "s", "s", "s"];

    var dateTimeMask = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_datetimeUTC, DatabaseMobx.c_genericDateTimeFieldTypeObj);
    var contactPersonNamesComma = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_contactPersonIDsComma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj);
    const insertConversationChangeLogString = "Date/Time: '" + dateTimeMask + "', Contacts: '" + contactPersonNamesComma + "', Notes: '" + i_notes + "'";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_conversations_insert_new_conversation", ["i_captureID", "i_datetimeUTC", "i_contactPersonIDsComma", "i_notes"], [i_captureID, i_datetimeUTC, i_contactPersonIDsComma, i_notes]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert("tbl_c_conversations", fieldNamesArray, valuesArray, valuesIdsbArray);
    C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDConversations, "cnc", "Create New Conversation", insertConversationChangeLogString);
    C_CallPhpTblUID.execute();
  }

  a_conversations_update_conversation(i_captureID, i_conversationID, i_datetimeUTC, i_contactPersonIDsComma, i_notes) {
    var fieldNamesArray = [];
    var valuesArray = [];
    var valuesIdsbArray = [];
    var updateConversationChangeLogArray = [];

    if(i_datetimeUTC !== undefined) {
      fieldNamesArray.push("datetime_utc");
      valuesArray.push(i_datetimeUTC);
      valuesIdsbArray.push("s");
      updateConversationChangeLogArray.push("Date/Time: '" + DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_datetimeUTC, DatabaseMobx.c_genericDateTimeFieldTypeObj));
    }

    if(i_contactPersonIDsComma !== undefined) {
      fieldNamesArray.push("contact_person_ids_comma");
      valuesArray.push(i_contactPersonIDsComma);
      valuesIdsbArray.push("s");
      updateConversationChangeLogArray.push("Contacts: '" + DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_contactPersonIDsComma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj));
    }

    if(i_notes !== undefined) {
      fieldNamesArray.push("notes");
      valuesArray.push(i_notes);
      valuesIdsbArray.push("s");
      updateConversationChangeLogArray.push("Notes: '" + i_notes);
    }

    if(fieldNamesArray.length > 0) { //if none of the fields have been changed, do not do an update/changelog operation
      const  updateConversationChangeLogString = updateConversationChangeLogArray.join(", ");

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_risks_update_risk", ["i_captureID", "i_conversationID", "i_datetimeUTC", "i_contactPersonIDsComma", "i_notes"], [i_captureID, i_conversationID, i_datetimeUTC, i_contactPersonIDsComma, i_notes]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_c_conversations", i_conversationID, fieldNamesArray, valuesArray, valuesIdsbArray);
      C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDConversations, "uc", "Update Conversation", updateConversationChangeLogString);
      C_CallPhpTblUID.execute();
    }
  }

  a_conversations_delete_conversation(i_conversationObj) {
    var dateTimeMask = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_conversationObj.datetime_utc, DatabaseMobx.c_genericDateTimeFieldTypeObj);
    var contactPersonNamesComma = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_conversationObj.contact_person_ids_comma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj);
    const deleteConversationChangeLogString = "Date/Time: '" + dateTimeMask + "', Contacts: '" + contactPersonNamesComma + "', Notes: '" + i_conversationObj.notes + "'";

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_conversations_delete_conversation", ["i_conversationObj"], [i_conversationObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_c_conversations", i_conversationObj.id); //no sort column (conversations sorted by date), no need for resort after delete
    C_CallPhpTblUID.add_changelog_details(i_conversationObj.capture_id, DatabaseMobx.k_cardIDConversations, "dc", "Delete Conversation", deleteConversationChangeLogString);
    C_CallPhpTblUID.execute();
  }



  //Templates card





  //Notepad card
  a_notepad_set_note_stamps_new_edit_read_floating_box_flag(i_noteStampsFloatingBoxFlagString) {
    this.o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined = i_noteStampsFloatingBoxFlagString;
  }

  a_notepad_set_note_stamps_editing_or_reading_note_stamp_obj_or_undefined(i_noteStampsObjOrUndefined) {
    this.o_notepadNoteStampsEditingOrReadingNoteStampObjOrUndefined = i_noteStampsObjOrUndefined;
  }

  a_notepad_insert_new_note_stamp(i_title, i_body) {
    const o_openCaptureID = this.o_openCaptureID;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_notepad_insert_new_note_stamp", ["i_title", "i_body"], [i_title, i_body]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const nowDateTimeUtc = JSFUNC.now_datetime_utc();
    const fieldNamesArray = ["capture_id", "pinned_01", "sort", "created_datetime_utc", "created_by_user_id", "created_by_user_name", "last_edited_datetime_utc", "last_edited_by_user_id", "last_edited_by_user_name", "title", "body"];
    const valuesArray = [o_openCaptureID, 0, 99, nowDateTimeUtc, o_userID, c_userName, nowDateTimeUtc, o_userID, c_userName, i_title, i_body];
    const valuesIdsbArray = ["i", "i", "i", "s", "i", "s", "s", "i", "s", "s", "s"];
    C_CallPhpTblUID.add_insert("tbl_c_notepad_note_stamps", fieldNamesArray, valuesArray, valuesIdsbArray);

    var insertNoteStampChangeLogValue = "";
    if(JSFUNC.string_is_filled_out_tf(i_title)) {
      insertNoteStampChangeLogValue += i_title;
    }

    if(JSFUNC.string_is_filled_out_tf(i_body)) {
      if(insertNoteStampChangeLogValue != "") { insertNoteStampChangeLogValue += " - "; }
      insertNoteStampChangeLogValue += "'" + i_body + "'";
    }

    C_CallPhpTblUID.add_changelog_details(o_openCaptureID, DatabaseMobx.k_cardIDNotepad, "inns", "Create New Note Stamp", insertNoteStampChangeLogValue);

    C_CallPhpTblUID.execute();
  }

  a_notepad_update_note_stamp(i_updatedTitle, i_updatedBody, i_oldNoteStampObj) {
    const o_openCaptureID = this.o_openCaptureID;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;
    const k_cardIDNotepad = DatabaseMobx.k_cardIDNotepad;

    var fieldNamesArray = [];
    var valuesArray = [];
    var valuesIdsbArray = [];

    if(i_updatedTitle !== i_oldNoteStampObj.title) {
      fieldNamesArray.push("title");
      valuesArray.push(i_updatedTitle);
      valuesIdsbArray.push("s");
    }

    if(i_updatedBody !== i_oldNoteStampObj.body) {
      fieldNamesArray.push("body");
      valuesArray.push(i_updatedBody);
      valuesIdsbArray.push("s");
    }

    if(fieldNamesArray.length > 0) { //if none of the fields have been changed (title or body), do not do an update/changelog operation
      fieldNamesArray.push("last_edited_datetime_utc"); //last edited datetime will always be different, no need to compare to previous value to include as an updated column
      valuesArray.push(JSFUNC.now_datetime_utc());
      valuesIdsbArray.push("s");

      if(o_userID !== i_oldNoteStampObj.last_edited_by_user_id) {
        fieldNamesArray.push("last_edited_by_user_id");
        valuesArray.push(o_userID);
        valuesIdsbArray.push("i");
      }
      
      if(c_userName !== i_oldNoteStampObj.last_edited_by_user_name) {
        fieldNamesArray.push("last_edited_by_user_name");
        valuesArray.push(c_userName);
        valuesIdsbArray.push("s");
      }

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_notepad_update_note_stamp", ["i_updatedTitle", "i_updatedBody", "i_oldNoteStampObj"], [i_updatedTitle, i_updatedBody, i_oldNoteStampObj]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      C_CallPhpTblUID.add_update("tbl_c_notepad_note_stamps", i_oldNoteStampObj.id, fieldNamesArray, valuesArray, valuesIdsbArray);

      var updateNoteStampChangeLogString = "[Title: " + i_updatedTitle + "] '" + i_updatedBody + "'";
      C_CallPhpTblUID.add_changelog_details(o_openCaptureID, k_cardIDNotepad, "unns", "Edit Note Stamp", updateNoteStampChangeLogString);

      C_CallPhpTblUID.execute();
    }
  }

  a_notepad_delete_note_stamp(i_noteStampObj) {
    const o_openCaptureID = this.o_openCaptureID;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_notepad_delete_note_stamp", ["i_noteStampObj"], [i_noteStampObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_c_notepad_note_stamps", i_noteStampObj.id); //no need to re-sort after delete, no sort column
    C_CallPhpTblUID.add_changelog_details(o_openCaptureID, DatabaseMobx.k_cardIDNotepad, "dnns", "Delete Note Stamp", i_noteStampObj.infoBlockPlainText);
    C_CallPhpTblUID.execute();
  }

  a_notepad_pin_or_unpin_note_stamp(i_noteStampID, i_updatedPinned01) {
    const o_openCaptureID = this.o_openCaptureID;
    const c_notepadNoteStampsArrayOfObjs = this.c_notepadNoteStampsArrayOfObjs;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_notepad_pin_or_unpin_note_stamp", ["i_noteStampID", "i_updatedPinned01"], [i_noteStampID, i_updatedPinned01]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const updatedSortNumber = ((i_updatedPinned01 === 1) ? (1) : (99));
    const fieldNamesArray = ["pinned_01", "sort"];
    const valuesArray = [i_updatedPinned01, updatedSortNumber];
    const valuesIdsbArray = ["i", "i"];
    C_CallPhpTblUID.add_update("tbl_c_notepad_note_stamps", i_noteStampID, fieldNamesArray, valuesArray, valuesIdsbArray);
    
    var lowestSortNumberNoteStampID = JSFUNC.get_obj_id_or_m1_where_field_has_lowest_value(c_notepadNoteStampsArrayOfObjs, "id", "sort"); //-1 is a flag that there are no note stamps, thus resorting is not necessary
    if(lowestSortNumberNoteStampID > 0) {
      const tblSortFieldDbName = "sort";
      const filterFieldNamesArray = ["capture_id", "pinned_01"];
      const filterValuesArray = [o_openCaptureID, 1];
      const draggedItemID = i_noteStampID;
      const droppedOnItemID = lowestSortNumberNoteStampID; //'drop' newly pinned item on current item with the lowest sort number, thus this new pinned item will be #1 after all resorting calculations
      C_CallPhpTblUID.add_resort("tbl_c_notepad_note_stamps", tblSortFieldDbName, filterFieldNamesArray, filterValuesArray, draggedItemID, droppedOnItemID);
    }

    C_CallPhpTblUID.execute();
  }




  //Change Log card
  a_load_single_capture_changelog_data(i_captureID) {
    const openCaptureChangeLogCardTblNamesToLoadComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_openCaptureChangeLogCardTblNamesToLoadArray);

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_load_single_capture_changelog_data", ["i_captureID"], [i_captureID]);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadSingleCaptureChangeLogData", jsDescription);
    C_CallPhpScript.add_post_var("i_captureID", i_captureID);
    C_CallPhpScript.add_post_var("i_captureDataTblNamesComma", openCaptureChangeLogCardTblNamesToLoadComma);
    C_CallPhpScript.add_return_vars("captureDataTables");

    const functionOnSuccess = (parseResponse) => {
      const clearOldMapDataFirstTF = true;
      DatabaseMobx.a_insert_or_update_multiple_local_data_maps_with_uncompression_from_data_tbls_obj(parseResponse.captureDataTables, clearOldMapDataFirstTF, jsDescription);
    };
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
  }

  a_set_changelog_items_per_page(i_newValueInt) {
    this.o_changelogNumItemsPerPage = i_newValueInt;
  }

  a_set_changelog_current_page_number(i_newValueInt) {
    this.o_changelogCurrentPageNumber = i_newValueInt;
  }

  a_set_changelog_sort_column_db_name(i_newValue) {
    this.o_changelogSortColumnDbName = i_newValue;
  }

  a_set_changelog_sort_is_asc_tf(i_newValueTF) {
    this.o_changelogSortIsAscTF = i_newValueTF;
  }

  a_set_changelog_filter_capture_card_ids_comma(i_newValueStringCommaList) {
    this.o_changelogFilterCaptureCardIDsComma = i_newValueStringCommaList;
  }

  a_set_changelog_filter_capture_field_id(i_newValueInt) {
    this.o_changelogFilterCaptureFieldID = i_newValueInt;
  }

  a_set_changelog_filter_user_id(i_newValueInt) {
    this.o_changelogFilterUserID = i_newValueInt;
  }

  a_set_changelog_filter_date_min(i_newValueDateYmd) {
    this.o_changelogFilterDateMin = i_newValueDateYmd;
  }

  a_set_changelog_filter_date_max(i_newValueDateYmd) {
    this.o_changelogFilterDateMax = i_newValueDateYmd;
  }

  a_changelog_increment_current_page_number() {
    if(this.c_changelogCanIncrementCurrentPageNumberTF) {
      this.a_set_changelog_current_page_number(this.o_changelogCurrentPageNumber + 1);
    }
  }

  a_changelog_decrement_current_page_number() {
    if(this.c_changelogCanDecrementCurrentPageNumberTF) {
      this.a_set_changelog_current_page_number(this.o_changelogCurrentPageNumber - 1);
    }
  }






  //--------------------------------------------------------------------------------------------------------------------------------------

  ce_edit_item_string(i_cardID, i_itemID) {
    return(this.o_openCaptureID + "-" + i_cardID + "-" + i_itemID);
  }


  //o_mediaQueryFlag layout for capture cards
  snapshot_num_cards_per_row(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,2,3,4,5,6], 1)); }
    else if(i_fullLeftRightFlag === "left") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,1,1,1,2], 1)); }
    else if(i_fullLeftRightFlag === "right") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,2,3,3], 1)); }
    return(1);
  }
  advance_stage_timeline_is_horizontal_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    else if(i_fullLeftRightFlag === "left") { return(false); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 5); }
    return(false);
  }
  advance_stage_timeline_allow_drag_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    else if(i_fullLeftRightFlag === "left") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); } //can still drag/drop when timeline is vertical in 1/3 side
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    return(false);
  }
  advance_stage_close_debrief_num_groups_per_row(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,2,3,3], 1)); }
    else if(i_fullLeftRightFlag === "left") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,1,1,1,1], 1)); }
    else if(i_fullLeftRightFlag === "right") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,2,2,3], 1)); }
    return(1);
  }
  dates_num_groups_per_row(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,3,3,3], 1)); }
    else if(i_fullLeftRightFlag === "left") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,1,1,1,2], 1)); }
    else if(i_fullLeftRightFlag === "right") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,2,3,3], 1)); }
    return(1);
  }
  dates_item_fixed_field_width_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 2); }
    else if(i_fullLeftRightFlag === "left") { return(CaptureExecMobx.o_mediaQueryFlag >= 4); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    return(true);
  }
  tasks_active_completed_is_split_screen_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    else if(i_fullLeftRightFlag === "left") { return(false); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 5); }
    return(false);
  }
  tasks_item_fields_left_description_right_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 2); }
    else if(i_fullLeftRightFlag === "left") { return(CaptureExecMobx.o_mediaQueryFlag >= 6); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    return(false);
  }
  details_num_groups_per_row(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,2,3,4], 1)); }
    else if(i_fullLeftRightFlag === "left") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,1,1,1,1], 1)); }
    else if(i_fullLeftRightFlag === "right") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,2,2,3], 1)); }
    return(1);
  }
  details_item_fixed_field_width_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 2); }
    else if(i_fullLeftRightFlag === "left") { return(CaptureExecMobx.o_mediaQueryFlag >= 4); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    return(1);
  }
  deal_shaping_pwin_wraps_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag <= 3); }
    else if(i_fullLeftRightFlag === "left") { return(true); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag <= 4); }
    return(true);
  }
  deal_shaping_tags_wraps_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag <= 2); }
    else if(i_fullLeftRightFlag === "left") { return(true); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag <= 3); }
    return(true);
  }
  deal_shaping_num_columns_per_stage(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,2,3,4], 1)); }
    else if(i_fullLeftRightFlag === "left") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,1,1,1,2], 1)); }
    else if(i_fullLeftRightFlag === "right") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,2,2,2,3], 1)); }
    return(1);
  }
  teammates_is_split_screen_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    else if(i_fullLeftRightFlag === "left") { return(false); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 5); }
    return(false);
  }
  teammates_collapsed_contents_wrap_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    else if(i_fullLeftRightFlag === "left") { return(false); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 5); }
    return(false);
  }
  teammates_expanded_num_tiles_per_row(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,1,1,2,2], 1)); }
    else if(i_fullLeftRightFlag === "left") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,1,1,1,1], 1)); }
    else if(i_fullLeftRightFlag === "right") { return(JSFUNC.value_from_array_indexed_1toN(CaptureExecMobx.o_mediaQueryFlag, [1,1,1,1,1,1], 1)); }
    return(1);
  }
  competitors_we_are_incumbent_box_is_full_width_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag <= 3); }
    else if(i_fullLeftRightFlag === "left") { return(true); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag <= 5); }
    return(false);
  }
  competitors_we_are_incumbent_box_labels_are_wrapped_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag <= 1); }
    else if(i_fullLeftRightFlag === "left") { return(CaptureExecMobx.o_mediaQueryFlag <= 4); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag <= 2); }
    return(false);
  }
  competitors_collapsed_show_swot_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 2); }
    else if(i_fullLeftRightFlag === "left") { return(CaptureExecMobx.o_mediaQueryFlag >= 6); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 4); }
    return(false);
  }
  competitors_expanded_single_column_true_two_columns_false(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag <= 2); }
    else if(i_fullLeftRightFlag === "left") { return(true); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag <= 4); }
    return(1);
  }
  proposal_themes_is_vertical_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag <= 3); }
    else if(i_fullLeftRightFlag === "left") { return(true); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag <= 5); }
    return(true);
  }
  risks_is_split_screen_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    else if(i_fullLeftRightFlag === "left") { return(false); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 5); }
    return(false);
  }
  risk_item_is_compact_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag <= 1); }
    else if(i_fullLeftRightFlag === "left") { return(true); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag <= 3); }
    return(true);
  }
  budget_uses_small_single_switched_screens_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag <= 3); }
    else if(i_fullLeftRightFlag === "left") { return(true); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag <= 5); }
    return(false);
  }
  conversations_upcoming_completed_is_split_screen_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    else if(i_fullLeftRightFlag === "left") { return(false); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 5); }
    return(false);
  }
  conversations_item_fields_left_description_right_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 4); }
    else if(i_fullLeftRightFlag === "left") { return(CaptureExecMobx.o_mediaQueryFlag >= 6); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 3); }
    return(false);
  }
  documents_ffs_is_wide_tf(i_fullLeftRightFlag) {
    if(i_fullLeftRightFlag === "full") { return(CaptureExecMobx.o_mediaQueryFlag >= 4); }
    else if(i_fullLeftRightFlag === "left") { return(true); }
    else if(i_fullLeftRightFlag === "right") { return(CaptureExecMobx.o_mediaQueryFlag >= 5); }
    return(false);
  }

}
export default new OpenCaptureMobx();
