import { makeObservable, observable, computed, action } from 'mobx';
import * as JSFUNC from "../../Library/JSFUNC.js";
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";
import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';

class SamGovTrackerMobx {
  //========================================================================================
  //constant values
  k_maxNumUsersAvailableToSelect = 15;
  

  //observable values
  o_testimonialsArrayOfObjs = [];
  o_buyProcessState = "info"; //"info", "cc"
  o_nextButtonPushedAtLeastOnceTF = false;
  o_allUsedCompanyCodesComma = "";
  o_sgtNoDocsPriceAmount = -1;
  o_sgtNoDocsPriceCurrency = "";
  o_sgtWithDocsPriceAmount = -1;
  o_sgtWithDocsPriceCurrency = "";
  o_customerCompanyName = "";
  o_customerCompanyCode = "";
  o_customerFirstName = "";
  o_customerLastName = "";
  o_customerEmail = "";
  o_purchaseSGTNumUsers = 1;
  o_purchaseSGTProduct = "sgtNoDocs"; //"sgtNoDocs", "sgtWithDocs"

  constructor() {
    makeObservable(this, {
      o_testimonialsArrayOfObjs: observable,
      o_buyProcessState: observable,
      o_nextButtonPushedAtLeastOnceTF: observable,
      o_allUsedCompanyCodesComma: observable,
      o_sgtNoDocsPriceAmount: observable,
      o_sgtNoDocsPriceCurrency: observable,
      o_sgtWithDocsPriceAmount: observable,
      o_sgtWithDocsPriceCurrency: observable,
      o_customerCompanyName: observable,
      o_customerCompanyCode: observable,
      o_customerFirstName: observable,
      o_customerLastName: observable,
      o_customerEmail: observable,
      o_purchaseSGTNumUsers: observable,
      o_purchaseSGTProduct: observable,

      c_buyProcessStateIsInfoTF: computed,
      c_buyProcessStateIsCCTF: computed,
      c_allUsedCompanyCodesUppercaseArray: computed,
      c_customerCompanyNameErrorMessageObj: computed,
      c_customerCompanyCodeErrorMessageObj: computed,
      c_customerFirstNameErrorMessageObj: computed,
      c_customerLastNameErrorMessageObj: computed,
      c_customerEmailErrorMessageObj: computed,
      c_selectSgtProductNumUsersFieldTypeObj: computed,
      c_selectedNumUsersValueMask: computed,
      c_selectedProductSgtNoDocsTF: computed,
      c_selectedProductSgtWithDocsTF: computed,
      c_selectedSgtProductPriceAmount: computed,
      c_selectedSgtProductPriceCurrency: computed,
      c_selectedSgtProductTotalPayAmount: computed,
      c_selectedSgtProductTotalPayFormattedPrice: computed,
      c_priceIncreasePerUserForWithDocsFormattedPrice: computed,
      c_companyInfoFormCanContinueNoErrorsTF: computed,

      a_log_sgt_ad_client_action: action,
      a_load_testimonials_arrayOfObjs: action,
      a_set_testimonials_arrayOfObjs: action,
      a_set_buy_process_state: action,
      a_set_next_button_pushed_at_least_once_tf: action,
      a_set_all_used_company_codes_comma: action,
      a_set_all_sgt_product_prices: action,
      a_set_customer_company_name: action,
      a_set_customer_company_code: action,
      a_set_customer_first_name: action,
      a_set_customer_last_name: action,
      a_set_customer_email: action,
      a_set_purchase_sgt_num_users: action,
      a_set_purchase_sgt_product: action
    });
  }


  //========================================================================================
  //computed values
  get c_allUsedCompanyCodesUppercaseArray() {
    const o_allUsedCompanyCodesComma = this.o_allUsedCompanyCodesComma;

    var allUsedCompanyCodesUppercaseComma = "";
    if(JSFUNC.is_string(o_allUsedCompanyCodesComma)) {
      allUsedCompanyCodesUppercaseComma = o_allUsedCompanyCodesComma.toUpperCase();
    }

    const allUsedCompanyCodesUppercaseArray = JSFUNC.convert_comma_list_to_array(allUsedCompanyCodesUppercaseComma);
    return(allUsedCompanyCodesUppercaseArray);
  }

  get c_buyProcessStateIsInfoTF() {
    const o_buyProcessState = this.o_buyProcessState;
    return(o_buyProcessState === "info");
  }

  get c_buyProcessStateIsCCTF() {
    const o_buyProcessState = this.o_buyProcessState;
    return(o_buyProcessState === "cc");
  }

  get c_customerCompanyNameErrorMessageObj() {
    const o_nextButtonPushedAtLeastOnceTF = this.o_nextButtonPushedAtLeastOnceTF;
    const o_customerCompanyName = this.o_customerCompanyName;

    var errorMessageOrUndefined = undefined;
    var showErrorMessageTF = false;
    if(o_customerCompanyName === "") {
      errorMessageOrUndefined = "Comnpany Name must be filled out";
      showErrorMessageTF = o_nextButtonPushedAtLeastOnceTF;
    }

    return({
      errorMessageOrUndefined: errorMessageOrUndefined,
      showErrorMessageTF: showErrorMessageTF
    });
  }

  get c_customerCompanyCodeErrorMessageObj() {
    const o_nextButtonPushedAtLeastOnceTF = this.o_nextButtonPushedAtLeastOnceTF;
    const o_customerCompanyCode = this.o_customerCompanyCode;
    const c_allUsedCompanyCodesUppercaseArray = this.c_allUsedCompanyCodesUppercaseArray;

    const enteredCompanyCodeLength = o_customerCompanyCode.length;

    var errorMessageOrUndefined = undefined;
    var showErrorMessageTF = false;
    if(enteredCompanyCodeLength === 0) {
      errorMessageOrUndefined = "Comnpany Code must be filled out";
      showErrorMessageTF = o_nextButtonPushedAtLeastOnceTF;
    }
    else if(enteredCompanyCodeLength === 1) {
      errorMessageOrUndefined = "Your Comnpany Code must have at least 2 letters";
      showErrorMessageTF = o_nextButtonPushedAtLeastOnceTF;
    }
    else { //at least 2 characters check if company code is unique among existing customers
      const enteredCompanyCodeUppercase = o_customerCompanyCode.toUpperCase();
      if(JSFUNC.in_array(enteredCompanyCodeUppercase, c_allUsedCompanyCodesUppercaseArray)) {
        errorMessageOrUndefined = "The Company Code '" + o_customerCompanyCode + "' is already taken and can't be used";
        showErrorMessageTF = true; //always show this error even before trying to click the continue buttons
      }
    }

    return({
      errorMessageOrUndefined: errorMessageOrUndefined,
      showErrorMessageTF: showErrorMessageTF
    });
  }

  get c_customerFirstNameErrorMessageObj() {
    const o_nextButtonPushedAtLeastOnceTF = this.o_nextButtonPushedAtLeastOnceTF;
    const o_customerFirstName = this.o_customerFirstName;

    var errorMessageOrUndefined = undefined;
    var showErrorMessageTF = false;
    if(o_customerFirstName === "") {
      errorMessageOrUndefined = "New system Admin's First Name must be filled out";
      showErrorMessageTF = o_nextButtonPushedAtLeastOnceTF;
    }

    return({
      errorMessageOrUndefined: errorMessageOrUndefined,
      showErrorMessageTF: showErrorMessageTF
    });
  }

  get c_customerLastNameErrorMessageObj() {
    const o_nextButtonPushedAtLeastOnceTF = this.o_nextButtonPushedAtLeastOnceTF;
    const o_customerLastName = this.o_customerLastName;

    var errorMessageOrUndefined = undefined;
    var showErrorMessageTF = false;
    if(o_customerLastName === "") {
      errorMessageOrUndefined = "New system Admin's Last Name must be filled out";
      showErrorMessageTF = o_nextButtonPushedAtLeastOnceTF;
    }

    return({
      errorMessageOrUndefined: errorMessageOrUndefined,
      showErrorMessageTF: showErrorMessageTF
    });
  }

  get c_customerEmailErrorMessageObj() {
    const o_nextButtonPushedAtLeastOnceTF = this.o_nextButtonPushedAtLeastOnceTF;
    const o_customerEmail = this.o_customerEmail;

    const enteredEmailInvalidErrorMessageStringOrUndefined = JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(o_customerEmail);

    var errorMessageOrUndefined = undefined;
    var showErrorMessageTF = false;
    if(enteredEmailInvalidErrorMessageStringOrUndefined !== undefined) {
      errorMessageOrUndefined = "Email is not valid";
      showErrorMessageTF = o_nextButtonPushedAtLeastOnceTF;
    }

    return({
      errorMessageOrUndefined: errorMessageOrUndefined,
      showErrorMessageTF: showErrorMessageTF
    });
  }

  get c_selectSgtProductNumUsersFieldTypeObj() {
    const k_maxNumUsersAvailableToSelect = this.k_maxNumUsersAvailableToSelect;

    var numUsersValueArray = [];
    var numUsersDisplayArray = [];
    for(let u = 1; u <= k_maxNumUsersAvailableToSelect; u++) {
      var numUsersDisplay = u + " " + JSFUNC.plural(u, "User", "Users");
      numUsersValueArray.push(u);
      numUsersDisplayArray.push(numUsersDisplay);
    }

    const fieldInputType = "select";
    const valuesAreStringsTF = false;
    const swsOptionsObj = undefined;
    const sgtProductNumUsersSwsDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("SGT # Users", numUsersValueArray, valuesAreStringsTF, numUsersDisplayArray, swsOptionsObj);
    const sgtProductNumUsersFieldTypeObj = DatabaseMobx.create_field_type_obj(fieldInputType, sgtProductNumUsersSwsDataObj);
    return(sgtProductNumUsersFieldTypeObj);
  }

  get c_selectedNumUsersValueMask() {
    const o_purchaseSGTNumUsers = this.o_purchaseSGTNumUsers;
    const c_selectSgtProductNumUsersFieldTypeObj = this.c_selectSgtProductNumUsersFieldTypeObj;
    return(DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(o_purchaseSGTNumUsers, c_selectSgtProductNumUsersFieldTypeObj));
  }

  get c_selectedProductSgtNoDocsTF() {
    const o_purchaseSGTProduct = this.o_purchaseSGTProduct;
    return(o_purchaseSGTProduct !== "sgtWithDocs");
  }

  get c_selectedProductSgtWithDocsTF() {
    const o_purchaseSGTProduct = this.o_purchaseSGTProduct;
    return(o_purchaseSGTProduct === "sgtWithDocs");
  }

  get c_selectedSgtProductPriceAmount() {
    const o_sgtNoDocsPriceAmount = this.o_sgtNoDocsPriceAmount;
    const o_sgtWithDocsPriceAmount = this.o_sgtWithDocsPriceAmount;
    const c_selectedProductSgtNoDocsTF = this.c_selectedProductSgtNoDocsTF;
    const c_selectedProductSgtWithDocsTF = this.c_selectedProductSgtWithDocsTF;

    if(c_selectedProductSgtNoDocsTF) { return(o_sgtNoDocsPriceAmount); }
    else if(c_selectedProductSgtWithDocsTF) { return(o_sgtWithDocsPriceAmount); }
    return(2500);
  }

  get c_selectedSgtProductPriceCurrency() {
    const o_sgtNoDocsPriceCurrency = this.o_sgtNoDocsPriceCurrency;
    const o_sgtWithDocsPriceCurrency = this.o_sgtWithDocsPriceCurrency;
    const c_selectedProductSgtNoDocsTF = this.c_selectedProductSgtNoDocsTF;
    const c_selectedProductSgtWithDocsTF = this.c_selectedProductSgtWithDocsTF;

    if(c_selectedProductSgtNoDocsTF) { return(o_sgtNoDocsPriceCurrency); }
    else if(c_selectedProductSgtWithDocsTF) { return(o_sgtWithDocsPriceCurrency); }
    return("usd");
  }

  get c_selectedSgtProductTotalPayAmount() {
    const o_purchaseSGTNumUsers = this.o_purchaseSGTNumUsers;
    const c_selectedSgtProductPriceAmount = this.c_selectedSgtProductPriceAmount;
    return(o_purchaseSGTNumUsers * c_selectedSgtProductPriceAmount)
  }

  get c_selectedSgtProductTotalPayFormattedPrice() {
    const c_selectedSgtProductPriceCurrency = this.c_selectedSgtProductPriceCurrency;
    const c_selectedSgtProductTotalPayAmount = this.c_selectedSgtProductTotalPayAmount;
    return(JSFUNC.stripe_formatted_price_from_amount_and_currency(c_selectedSgtProductTotalPayAmount, c_selectedSgtProductPriceCurrency));
  }

  get c_priceIncreasePerUserForWithDocsFormattedPrice() {
    const o_sgtNoDocsPriceAmount = this.o_sgtNoDocsPriceAmount;
    const o_sgtNoDocsPriceCurrency = this.o_sgtNoDocsPriceCurrency;
    const o_sgtWithDocsPriceAmount = this.o_sgtWithDocsPriceAmount;
    const priceIncreasePerUserForWithDocsAmount = (o_sgtWithDocsPriceAmount - o_sgtNoDocsPriceAmount);
    return(JSFUNC.stripe_formatted_price_from_amount_and_currency(priceIncreasePerUserForWithDocsAmount, o_sgtNoDocsPriceCurrency));
  }

  get c_companyInfoFormCanContinueNoErrorsTF() {
    const c_customerCompanyNameErrorMessageObj = this.c_customerCompanyNameErrorMessageObj;
    const c_customerCompanyCodeErrorMessageObj = this.c_customerCompanyCodeErrorMessageObj;
    const c_customerFirstNameErrorMessageObj = this.c_customerFirstNameErrorMessageObj;
    const c_customerLastNameErrorMessageObj = this.c_customerLastNameErrorMessageObj;
    const c_customerEmailErrorMessageObj = this.c_customerEmailErrorMessageObj;

    if(c_customerCompanyNameErrorMessageObj.errorMessageOrUndefined !== undefined) { return(false); }
    if(c_customerCompanyCodeErrorMessageObj.errorMessageOrUndefined !== undefined) { return(false); }
    if(c_customerFirstNameErrorMessageObj.errorMessageOrUndefined !== undefined) { return(false); }
    if(c_customerLastNameErrorMessageObj.errorMessageOrUndefined !== undefined) { return(false); }
    if(c_customerEmailErrorMessageObj.errorMessageOrUndefined !== undefined) { return(false); }
    return(true);
  }


  //========================================================================================
  //action methods
  a_log_sgt_ad_client_action(i_page, i_customerInfo="") {
    const o_mediaQueryFlag = CaptureExecMobx.o_mediaQueryFlag;

    const timezoneString = JSFUNC.get_user_browser_timezone_string();

    const jsDescription = JSFUNC.js_description_from_action("SamGovTrackerMobx", "a_log_sgt_ad_client_action", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("logSGTAdClientAction", jsDescription);
    C_CallPhpScript.add_post_var("i_page", i_page);
    C_CallPhpScript.add_post_var("i_timezone", timezoneString);
    C_CallPhpScript.add_post_var("i_mqf", o_mediaQueryFlag);
    C_CallPhpScript.add_post_var("i_customerInfo", i_customerInfo);
    C_CallPhpScript.add_return_vars("success01String");
    C_CallPhpScript.execute();
  }


  a_load_testimonials_arrayOfObjs() {
    const jsDescription = JSFUNC.js_description_from_action("SamGovTrackerMobx", "a_load_testimonials_arrayOfObjs", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadTestimonials", jsDescription);
    C_CallPhpScript.add_return_vars("ceCompressedTestimonialsMatrix");

    const functionOnSuccess = (i_parseResponse) => {
      const uncompressedDataWithIDArrayOfObjs = DatabaseMobx.uncompress_ce_compressed_data_table_arrayOfObjs(i_parseResponse.ceCompressedTestimonialsMatrix);
      JSFUNC.sort_arrayOfObjs(uncompressedDataWithIDArrayOfObjs, "sort", true);
      this.a_set_testimonials_arrayOfObjs(uncompressedDataWithIDArrayOfObjs);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
    
  }

  a_set_testimonials_arrayOfObjs(i_arrayOfObjs) { this.o_testimonialsArrayOfObjs = i_arrayOfObjs; }
  a_set_buy_process_state(i_newValueString) { this.o_buyProcessState = i_newValueString; }
  a_set_next_button_pushed_at_least_once_tf(i_newValueTF) { this.o_nextButtonPushedAtLeastOnceTF = i_newValueTF; }
  a_set_all_used_company_codes_comma(i_commaListString) { this.o_allUsedCompanyCodesComma = i_commaListString; }
  a_set_all_sgt_product_prices(i_sgtNoDocsPriceAmount, i_sgtNoDocsPriceCurrency, i_sgtWithDocsPriceAmount, i_sgtWithDocsPriceCurrency) {
    this.o_sgtNoDocsPriceAmount = i_sgtNoDocsPriceAmount;
    this.o_sgtNoDocsPriceCurrency = i_sgtNoDocsPriceCurrency;
    this.o_sgtWithDocsPriceAmount = i_sgtWithDocsPriceAmount;
    this.o_sgtWithDocsPriceCurrency = i_sgtWithDocsPriceCurrency;
  }
  a_set_customer_company_name(i_newValueString) { this.o_customerCompanyName = i_newValueString; }
  a_set_customer_company_code(i_newValueString) { this.o_customerCompanyCode = i_newValueString; }
  a_set_customer_first_name(i_newValueString) { this.o_customerFirstName = i_newValueString; }
  a_set_customer_last_name(i_newValueString) { this.o_customerLastName = i_newValueString; }
  a_set_customer_email(i_newValueString) { this.o_customerEmail = i_newValueString; }
  a_set_purchase_sgt_num_users(i_newValueInt) { this.o_purchaseSGTNumUsers = i_newValueInt; }
  a_set_purchase_sgt_product(i_newValueString) { this.o_purchaseSGTProduct = i_newValueString; }

}
export default new SamGovTrackerMobx();
