//js code packages
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//generic components and js functions
import * as LibraryReact from "../../Library/LibraryReact.js";
import * as JSFUNC from "../../Library/JSFUNC.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";

import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";
import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';


export const IntegrationsTab = inject("AdminIntegrationsMobx")(observer(
class IntegrationsTab extends Component {
  onclick_integrations_tab = (i_selectedTabDbName) => {
    this.props.AdminIntegrationsMobx.a_set_selected_tab_db_name(i_selectedTabDbName);
  }

  render() {
    const o_selectedTabDbName = this.props.AdminIntegrationsMobx.o_selectedTabDbName;
    const c_integrationSystemDisplayName = this.props.AdminIntegrationsMobx.c_integrationSystemDisplayName;
    
    return(
      <>
        <div className="flex00a displayFlexRow borderB1bbb bgLighterGreen" style={{flexBasis:"4em"}}>
          <div className="flex00a displayFlexRowVc lrMedPad" style={{flexBasis:"20em"}}>
            <font className="font13 fontBold fontDarkOrange">
              {"Integration with " + c_integrationSystemDisplayName}
            </font>
          </div>
          <div className="flex11a displayFlexColumnHcVc">
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={["setup", "mapping", "specialHandling",  "log"]}
              p_tabDisplayNamesArray={["Setup", "Mapping", "Special Handling", "Log"]}
              p_selectedTabDbName={o_selectedTabDbName}
              p_tabHeight="2.5em"
              p_textMaxHeight="2.5em"
              p_tabWidth="10em"
              p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
              p_unselectedFontClass="font11 fontBold fontTextLight"
              p_selectedBgClass="bgDarkOrangeGradient"
              p_selectedFontClass="font11 fontBold fontWhite"
              f_onSelect={this.onclick_integrations_tab}
            />
          </div>
          <div className="flex00a" style={{flexBasis:"20em"}} />
        </div>
        {(o_selectedTabDbName === "setup") && <IntegrationsSetup />}
        {(o_selectedTabDbName === "mapping") && <IntegrationsMapping />}
        {(o_selectedTabDbName === "specialHandling") && <IntegrationsSpecialHandling />}
        {(o_selectedTabDbName === "log") && <IntegrationsChangelog />}
      </>
    );
  }
}));



const IntegrationsSetup = inject("CaptureExecMobx", "AdminIntegrationsMobx", "DatabaseMobx")(observer(
class IntegrationsSetup extends Component {
  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_setupAllIntegrationApiConnectionFieldsArrayOfObjs = this.props.AdminIntegrationsMobx.c_setupAllIntegrationApiConnectionFieldsArrayOfObjs;
    const c_companyIntegrationOnTF = this.props.DatabaseMobx.c_companyIntegrationOnTF;
    const c_companyIntegrationIntegrationUniqueIDCEFieldID = this.props.DatabaseMobx.c_companyIntegrationIntegrationUniqueIDCEFieldID;
    const c_companyIntegrationLogRecordSuccessesTF = this.props.DatabaseMobx.c_companyIntegrationLogRecordSuccessesTF;

    return(
      <div className="flex11a displayFlexColumnHc bgLightGray yScroll">
        <IntegrationsSetupBox p_label="Control">
          <IntegrationsSetupSingleField
            p_fieldDisplayName="Integration On/Off"
            p_fieldDbName="integration_on_01"
            p_valueRaw={c_companyIntegrationOnTF}
            p_inputType="switch"
            p_wideTF={true}
          />
          <IntegrationsSetupSingleField
            p_fieldDisplayName="Record Successful Updates in Log"
            p_fieldDbName="integration_log_record_successes_01"
            p_valueRaw={c_companyIntegrationLogRecordSuccessesTF}
            p_inputType="switch"
            p_wideTF={true}
          />
        </IntegrationsSetupBox>
        <IntegrationsSetupBox p_label="API Connection">
          {c_setupAllIntegrationApiConnectionFieldsArrayOfObjs.map((m_integrationApiConnectionFieldObj) =>
            <IntegrationsSetupSingleField
              key={m_integrationApiConnectionFieldObj.fieldDbName}
              p_valueRaw={m_integrationApiConnectionFieldObj.valueRaw}
              p_fieldDbName={m_integrationApiConnectionFieldObj.fieldDbName}
              p_fieldDisplayName={m_integrationApiConnectionFieldObj.fieldDisplayName}
              p_inputType={m_integrationApiConnectionFieldObj.inputType}
              p_scrambleTF={m_integrationApiConnectionFieldObj.scrambleTF}
            />
          )}
        </IntegrationsSetupBox>
        <IntegrationsSetupBox p_label="Unique ID Field Identification">
          <IntegrationsSetupSingleField
            p_valueRaw={c_companyIntegrationIntegrationUniqueIDCEFieldID}
            p_fieldDbName="integration_integration_unique_id_ce_field_id"
            p_fieldDisplayName={"Unique ID Field Created in " + c_productStylingObj.productName}
            p_inputType="captureField"
            p_wideTF={true}
          />
        </IntegrationsSetupBox>
        <div className="flex00a hugeTopMargin">
          <SetupVerifyApiCredentials />
        </div>
      </div>
    );
  }
}));

function IntegrationsSetupBox(props) { //props: p_label, children
  const p_label = props.p_label;
  return(
    <div className="flex00a hugeTopMargin border bevelBorderColorBlue bgLighterBlue medFullPad" style={{width:"100%", maxWidth:"60em"}}>
      <div className="displayFlexColumnHcVc smallBottomMargin textCenter">
        <font className="font15 fontBold fontTextLight">
          {p_label}
        </font>
      </div>
      {props.children}
    </div>
  )
}

const SetupVerifyApiCredentials = inject("AdminIntegrationsMobx")(observer(
class SetupVerifyApiCredentials extends Component {
  onclick_verify_api_credentials_button = () => {
    this.props.AdminIntegrationsMobx.a_setup_verify_api_credentials();
  }

  render() {
    const o_setupVerifyApiCredentialsFlag = this.props.AdminIntegrationsMobx.o_setupVerifyApiCredentialsFlag;
    
    return(
      <div className="border bevelBorderColorBlue bgLighterBlue medFullPad" style={{width:"30em"}}>
        {(o_setupVerifyApiCredentialsFlag !== "working") &&
          <div className="displayFlexColumnHcVc smallBottomMargin">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Verify Credentials"
              f_onClick={this.onclick_verify_api_credentials_button}
            />
          </div>
        }
        <VerifyApiWorkingOrErrorBox p_statusFlag={o_setupVerifyApiCredentialsFlag} />
      </div>
    );
  }
}));

const VerifyApiWorkingOrErrorBox = inject("AdminIntegrationsMobx")(observer(
class VerifyApiWorkingOrErrorBox extends Component { //props: p_statusFlag
  render() {
    const p_statusFlag = this.props.p_statusFlag;

    const c_integrationSystemDisplayName = this.props.AdminIntegrationsMobx.c_integrationSystemDisplayName;
    
    if(p_statusFlag === "start") {
      return(
        <div className="textCenter">
          <font className="fontTextLight">
            {"Click this 'Verify Credentials' button to verify API connections"}
          </font>
        </div>
      );
    }
    
    if(p_statusFlag === "working") {
      return(
        <div className="displayFlexColumnHcVc">
          <div className="textCenter">
            <font className="font11 fontItalic fontTextLight">
              {"Verifying Credentials"}
            </font>
          </div>
          <div className="displayFlexColumnHcVc smallTopMargin">
            <CEGeneralReact.LoadingAnimation />
          </div>
        </div>
      );
    }
    
    if(p_statusFlag === "verified") {
      return(
        <div className="displayFlexColumnHcVc">
          <div className="flex00a border1bbb bgLightGreen smallFullPad textCenter" style={{maxWidth:"30em"}}>
            <font className="fontDarkGreen">
              {"Successfully connected to " + c_integrationSystemDisplayName + " database"}
            </font>
          </div>
        </div>
      );
      }
    
    if(p_statusFlag === "credentialsFailed") {
      return(
        <div className="displayFlexColumnHcVc">
          <div className="flex00a border1bbb bgLightRed smallFullPad textCenter" style={{maxWidth:"30em"}}>
            <font className="fontDarkRed">
              {"Failed to connect to " + c_integrationSystemDisplayName + " database using credentials"}
            </font>
          </div>
        </div>
      );
    }

    if(p_statusFlag === "ceError") {
      return(
        <div className="displayFlexColumnHcVc">
          <div className="flex00a border1bbb bgLightOrange smallFullPad textCenter" style={{maxWidth:"30em"}}>
            <font className="fontDarkRed">
              {"Please try this request again"}
            </font>
          </div>
        </div>
      );
    }

    return(null);
  }
}));


const IntegrationsSetupSingleField = inject("AdminIntegrationsMobx", "DatabaseMobx")(observer(
class IntegrationsSetupSingleField extends Component { //props: p_valueRaw, p_fieldDbName, p_fieldDisplayName, p_inputType, p_scrambleTF, p_wideTF
  onsave_integrations_setup_company_field = (i_newValueRaw) => {
    const p_valueRaw = this.props.p_valueRaw;
    const p_fieldDbName = this.props.p_fieldDbName;
    const p_inputType = this.props.p_inputType;
    const p_scrambleTF = JSFUNC.prop_value(this.props.p_scrambleTF, false);

    var newValueRawScrambledIfNeeded = i_newValueRaw;
    if(p_scrambleTF) {
      newValueRawScrambledIfNeeded = JSFUNC.rfr_scramble_or_unscramble_password(i_newValueRaw);
    }

    var idsb = "s";
    if(p_inputType === "captureField") {
      idsb = "i";
    }
    else if(p_inputType === "switch") {
      idsb = "i";
      newValueRawScrambledIfNeeded = ((p_valueRaw) ? (0) : (1)); //new switch 0 or 1 value depending on input valueRaw which is tf flag of isOnTF
    }
    
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsReact - IntegrationsSetupSingleField", "onsave_integrations_setup_company_field", ["i_newValueRaw"], [i_newValueRaw]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //update tbl_a_company integration setup field (username, password, client secret, etc), also wipe out any stored 12-hour OAuth token stored from a previous connection in case the integration database was changed
    const fieldNamesArray = [p_fieldDbName, "integration_oauth_json_access_token", "integration_oauth_json_expiration_datetime_utc"];
    const valuesArray = [newValueRawScrambledIfNeeded, "", JSFUNC.blank_datetime()];
    const idsbArray = [idsb, "s", "s"];
    C_CallPhpTblUID.add_update("tbl_a_company", 1, fieldNamesArray, valuesArray, idsbArray);

    C_CallPhpTblUID.execute();
  }

  render() {
    const p_valueRaw = this.props.p_valueRaw;
    const p_fieldDbName = this.props.p_fieldDbName;
    const p_fieldDisplayName = this.props.p_fieldDisplayName;
    const p_inputType = this.props.p_inputType;
    const p_scrambleTF = JSFUNC.prop_value(this.props.p_scrambleTF, false);
    const p_wideTF = JSFUNC.prop_value(this.props.p_wideTF, false);

    var fieldTypeObj = undefined;
    if(p_inputType === "captureField") {
      fieldTypeObj = this.props.DatabaseMobx.c_selectCaptureFieldFieldTypeObj;
    }
    else {
      fieldTypeObj = this.props.DatabaseMobx.c_genericTextFieldTypeObj;
    }

    var valueRawUnscrambled = p_valueRaw;
    if(p_scrambleTF) {
      valueRawUnscrambled = JSFUNC.rfr_scramble_or_unscramble_password(p_valueRaw);
    }

    const fieldClass = "font11 fontBold fontBlue";

    var fieldWidthEm = 12;
    if(p_wideTF) {
      fieldWidthEm = 22;
    }

    if(p_inputType === "switch") {
      return(
        <div className="displayFlexRowVc" style={{height:"1.8em"}}>
          <div className="flex00a" style={{flexBasis:fieldWidthEm + "em"}}>
            <font className={fieldClass}>
              {p_fieldDisplayName}
            </font>
          </div>
          <div className="flex11a">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={valueRawUnscrambled}
              p_sizeEm={2.8}
              p_onText="On"
              p_offText="Off"
              p_onTextFontClass="font11 fontBold"
              p_offTextFontClass="font11 fontBold"
              f_onSwitch={this.onsave_integrations_setup_company_field}
            />
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={p_fieldDbName}
        p_fieldDisplayName={p_fieldDisplayName}
        p_fieldTypeObj={fieldTypeObj}
        p_valueRaw={valueRawUnscrambled}
        p_valueIsEditableTFU={true}
        p_containerClass="tbMicroPad"
        p_fieldClass={fieldClass}
        p_fieldWidth={fieldWidthEm + "em"}
        p_fieldTitle={undefined}
        p_valueClass="font11"
        f_onSaveChanged={this.onsave_integrations_setup_company_field}
      />
    );
  }
}));



const IntegrationsMapping = inject("CaptureExecMobx", "AdminIntegrationsMobx", "DatabaseMobx")(observer(
class IntegrationsMapping extends Component {
  onclick_open_close_fetch_integration_opp_panel = () => {
    const o_mappingExampleFieldsPanelOpenTF = this.props.AdminIntegrationsMobx.o_mappingExampleFieldsPanelOpenTF;
    this.props.AdminIntegrationsMobx.a_set_mapping_example_fields_panel_open_tf(!o_mappingExampleFieldsPanelOpenTF);
  }

  onconfirm_add_new_linked_fields_pairing = (i_integrationFieldDbName) => {
    this.props.AdminIntegrationsMobx.a_create_new_integration_linked_fields_pairing_from_integration_field_db_name(i_integrationFieldDbName);
  }

  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_mappingExampleFieldsPanelOpenTF = this.props.AdminIntegrationsMobx.o_mappingExampleFieldsPanelOpenTF;
    const c_integrationSystemDisplayName = this.props.AdminIntegrationsMobx.c_integrationSystemDisplayName;
    const c_linkedFieldsWithValuesArrayOfObjs = this.props.AdminIntegrationsMobx.c_linkedFieldsWithValuesArrayOfObjs;
    const c_fieldMapOfOpportunityName = this.props.DatabaseMobx.c_fieldMapOfOpportunityName;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfDivisionOwners = this.props.DatabaseMobx.c_fieldMapOfDivisionOwners;
    
    const numLinkedFields = c_linkedFieldsWithValuesArrayOfObjs.length;

    return(
      <div className="flex11a displayFlexRow">
        <div className={((o_mappingExampleFieldsPanelOpenTF) ? ("flex11a") : ("flex00a")) + " displayFlexColumn bgLighterGray"} style={{flexBasis:((o_mappingExampleFieldsPanelOpenTF) ? ("100em") : (undefined)), borderRight:"solid 2px #888"}}>
          <div className="flex00a displayFlexColumnHcVc medFullPad">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text={((o_mappingExampleFieldsPanelOpenTF) ? ("Close") : ("Open"))}
              f_onClick={this.onclick_open_close_fetch_integration_opp_panel}
            />
          </div>
          {(o_mappingExampleFieldsPanelOpenTF) ? (
            <FetchExampleFields />
          ) : (
            <div className="smallTopMargin textCenter">
              <font className="fontItalic fontTextLight">
                <div>{"Click"}</div>
                <div>{"'Open'"}</div>
                <div>{"to View"}</div>
                <div>{"Field"}</div>
                <div>{"Names"}</div>
              </font>
            </div>
          )}
        </div>
        <div className="flex11a displayFlexColumn bgLightGray" style={{flexBasis:"200em"}}>
          <div className="flex00a displayFlexRow borderB1ddd bgGray" style={{flexBasis:"6.2em"}}>
            <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
              <CEGeneralReact.ButtonWithConfirmBox
                p_buttonType="add"
                p_buttonText="Add New 'Linked Fields' Pairing to the Integration"
                p_buttonFontClass={undefined}
                p_tabIndex={undefined}
                p_title={undefined}
                p_confirmType="inputText"
                p_confirmMessage={"Enter the name of the field used by " + c_integrationSystemDisplayName + " when the data column is fetched from the database"}
                p_button1Name="Create New Field Pairing"
                p_textMustBeFilledOutTF={true}
                f_onClickConfirm={this.onconfirm_add_new_linked_fields_pairing}
              />
            </div>
            <div className="flex00a displayFlexColumnVc" style={{flexBasis:"40em"}}>
              <div className="flex00a microBottomPad">
                <font className="fontWhite">
                  <div className="microBottomMargin textCenter"><font className="fontUnderline">{"For " + c_integrationSystemDisplayName + ", creating a new opportunity requires the following fields to be mapped:"}</font></div>
                  <font className="font09">
                    <div><font className="fontBold">{"'key', 'WBSNumber', 'WBS1'"}</font><font className="fontItalic">{" [all mapped to the '" + c_integrationSystemDisplayName + " Unique ID' field in "+  c_productStylingObj.productName + "]"}</font></div>
                    <div><font className="fontBold">{"'Name', 'LongName', 'Level1Name'"}</font><font className="fontItalic">{" [all mapped to the '" + c_fieldMapOfOpportunityName.get("display_name") + "' field in "+  c_productStylingObj.productName + "]"}</font></div>
                    <div><font className="fontBold">{"'Stage'"}</font><font className="fontItalic">{" [mapped to the '" + c_fieldMapOfStage.get("display_name") + "' field in "+  c_productStylingObj.productName + "]"}</font></div>
                    <div><font className="fontBold">{"'Org'"}</font><font className="fontItalic">{" [mapped to the '" + c_fieldMapOfDivisionOwners.get("display_name") + "' field in "+  c_productStylingObj.productName + "]"}</font></div>
                  </font>
                </font>
              </div>
            </div>
          </div>
          <div className="flex11a displayFlexColumnHc yScroll" style={{flexBasis:"200em"}}>
            <div className="flex00a smallTopMargin" style={{width:"45em"}}>
              <font className="font09">
                <div className=""><font className="fontUnderline">{"Notes:"}</font></div>
                <div className=""><font className="">{"1) A '--not set--' or blank value in CE will insert " + '""' + " into the VP database (not NULL or 0)"}</font></div>
                <div className=""><font className="">{"2) Entering {ignore} for a VP mapped value will prevent any update action (like " + '""' + ") if that CE value is encountered"}</font></div>
              </font>
            </div>
            <div className="flex00a medTopMargin" style={{width:"100%", maxWidth:"85em"}}>
              {c_linkedFieldsWithValuesArrayOfObjs.map((m_linkedFieldWithValuesObj, m_index) =>
                <IntegrationsLinkedFieldsItem
                  key={m_linkedFieldWithValuesObj.id}
                  p_linkedFieldWithValuesObj={m_linkedFieldWithValuesObj}
                  p_isOnlyItemTF={(numLinkedFields === 1)}
                  p_isLastItemTF={(m_index === (numLinkedFields - 1))}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}));


const FetchExampleFields = inject("AdminIntegrationsMobx")(observer(
class FetchExampleFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_previouslySearchedIntegrationOppIDString: "",
      s_fieldNameSearchText: ""
    };
  }

  onchange_integration_opp_id_string = (i_newValue) => {
    this.props.AdminIntegrationsMobx.a_set_mapping_example_fields_integration_opp_id_string(i_newValue);
  }

  onclick_search_for_integration_opp_by_id_string = () => {
    const o_mappingExampleFieldsIntegrationOppIDString = this.props.AdminIntegrationsMobx.o_mappingExampleFieldsIntegrationOppIDString;
    this.props.AdminIntegrationsMobx.a_mapping_example_fields_search_for_integration_opp_by_id_string(o_mappingExampleFieldsIntegrationOppIDString);
    this.setState({s_previouslySearchedIntegrationOppIDString:o_mappingExampleFieldsIntegrationOppIDString});
  }

  onchange_field_name_search_text = (i_newValueString) => {
    this.setState({s_fieldNameSearchText:i_newValueString});
  }

  render() {
    const s_previouslySearchedIntegrationOppIDString = this.state.s_previouslySearchedIntegrationOppIDString;
    const s_fieldNameSearchText = this.state.s_fieldNameSearchText;

    const o_mappingExampleFieldsIntegrationOppIDString = this.props.AdminIntegrationsMobx.o_mappingExampleFieldsIntegrationOppIDString;
    const o_mappingExampleFieldsFlag = this.props.AdminIntegrationsMobx.o_mappingExampleFieldsFlag;
    const c_integrationSystemDisplayName = this.props.AdminIntegrationsMobx.c_integrationSystemDisplayName;
    const c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined = this.props.AdminIntegrationsMobx.c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined;

    var findProjectTextTabIndex = 1;

    const fieldNameSearchTextIsBlankTF = (s_fieldNameSearchText === "");
    var fieldNameSearchTextLower = "";
    if(!fieldNameSearchTextIsBlankTF) {
      fieldNameSearchTextLower = s_fieldNameSearchText.toLowerCase();
    }

    var mappingExampleResultComponent = null;
    if(JSFUNC.in_array(o_mappingExampleFieldsFlag, ["working", "credentialsFailed", "ceError"])) {
      mappingExampleResultComponent = (
        <VerifyApiWorkingOrErrorBox p_statusFlag={o_mappingExampleFieldsFlag} />
      );
    }
    else if(o_mappingExampleFieldsFlag === "oppDoesNotExist") {
      mappingExampleResultComponent = (
        <div className="displayFlexColumnHcVc">
          <div className="flex00a border1bbb bgLightYellow smallFullPad textCenter" style={{maxWidth:"30em"}}>
            <font className="fontDarkRed">
              {"Opp '" + s_previouslySearchedIntegrationOppIDString + "' does not exist in " + c_integrationSystemDisplayName}
            </font>
          </div>
        </div>
      );
    }
    else if(o_mappingExampleFieldsFlag === "verified") {
      if(!JSFUNC.is_array(c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined)) {
        mappingExampleResultComponent = (
          <div className="flex00a displayFlexColumnHcVc">
            <div className="flex00a border1bbb bgYellow smallFullPad textCenter" style={{maxWidth:"30em"}}>
              <font className="">
                {"Fetched opportunity was in an unexpected format"}
              </font>
            </div>
          </div>
        );
      }
      else {
        if(c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined.length === 0) {
          mappingExampleResultComponent = (
            <div className="flex00a displayFlexColumnHcVc">
              <div className="flex00a border1bbb bgYellow smallFullPad textCenter" style={{maxWidth:"30em"}}>
                <font className="">
                  {"0 projects matched '" + o_mappingExampleFieldsIntegrationOppIDString + "'"}
                </font>
              </div>
            </div>
          );
        }
        else {
          findProjectTextTabIndex = 2;

          mappingExampleResultComponent = (
            <>
              <div className="flex00a displayFlexColumnHcVc borderT1bbb borderB1bbb bgGray tbMedPad">
                <LibraryReact.Text
                  p_value={s_fieldNameSearchText}
                  p_class="lrMedPad"
                  p_styleObj={{width:"75%", height:"2em", borderRadius:"1em"}}
                  p_tabIndex={1}
                  p_placeholder="Search/Filter Fields"
                  f_onChange={this.onchange_field_name_search_text}
                />
              </div>
              <div className="flex11a medFullPad yScroll">
                {c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined.map((m_oppFieldObj, m_index) =>
                  (fieldNameSearchTextIsBlankTF || JSFUNC.input_string_converted_to_lowercase_contains_lowercase_search_term_string_tf(m_oppFieldObj.integrationFieldDbName, fieldNameSearchTextLower)) &&
                  <div className={"displayFlexRow " + ((m_index === 0) ? ("borderT1bbb") : ("")) + " borderB1bbb borderL1bbb borderR1bbb bgLightesterGray"}>
                    <div className="flex11a borderR1bbb tbMicroPad lrPad" style={{flexBasis:"100em"}}>
                      <font className="">
                        {m_oppFieldObj.integrationFieldDbName}
                      </font>
                    </div>
                    <div className="flex11a tbMicroPad lrPad" style={{flexBasis:"100em"}}>
                      <font className="fontItalic fontTextLight">
                        {m_oppFieldObj.integrationValueString}
                      </font>
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        }
      }
    }

    return(
      <>
        <div className="flex00a displayFlexRow smallFullPad">
          <div className="flex11a displayFlexRowVc lrPad">
            <LibraryReact.Text
              p_value={o_mappingExampleFieldsIntegrationOppIDString}
              p_class="lrPad"
              p_styleObj={{width:"100%", height:"2.2em"}}
              p_tabIndex={findProjectTextTabIndex}
              p_placeholder="Enter 'WSBNumber' (e.g. SEC-1234567890)"
              f_onChange={this.onchange_integration_opp_id_string}
              f_onKeyDownEnter={this.onclick_search_for_integration_opp_by_id_string}
            />
          </div>
          <div className="flex00a displayFlexColumnHcVc lrPad">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Find Project"
              f_onClick={this.onclick_search_for_integration_opp_by_id_string}
            />
          </div>
        </div>
        {mappingExampleResultComponent}
      </>
    );
  }
}));


const IntegrationsLinkedFieldsItem = inject("CaptureExecMobx", "AdminIntegrationsMobx", "DatabaseMobx")(observer(
class IntegrationsLinkedFieldsItem extends Component { //props: p_linkedFieldWithValuesObj, p_isOnlyItemTF, p_isLastItemTF
  onsave_integration_field_db_name = (i_updatedIntegrationFieldDbName) => {
    const p_linkedFieldWithValuesObj = this.props.p_linkedFieldWithValuesObj;
    this.props.AdminIntegrationsMobx.a_update_linked_fields_field(p_linkedFieldWithValuesObj, "integration_field_db_name", i_updatedIntegrationFieldDbName, "s");
  }

  onswitch_integration_field_using_default_value = () => {
    const p_linkedFieldWithValuesObj = this.props.p_linkedFieldWithValuesObj;
    const updatedIntegrationFieldUsingDefaultValue01 = ((p_linkedFieldWithValuesObj.integrationFieldUsingDefaultValueTF) ? (0) : (1));
    this.props.AdminIntegrationsMobx.a_update_linked_fields_field(p_linkedFieldWithValuesObj, "integration_field_using_default_value_01", updatedIntegrationFieldUsingDefaultValue01, "i");
  }

  onsave_integration_field_default_value_string = (i_newValueString) => {
    const p_linkedFieldWithValuesObj = this.props.p_linkedFieldWithValuesObj;
    this.props.AdminIntegrationsMobx.a_update_linked_fields_field(p_linkedFieldWithValuesObj, "integration_field_default_value_string", i_newValueString, "s");
  }

  onsave_ce_field_db_name = (i_updatedCEFieldID) => {
    const p_linkedFieldWithValuesObj = this.props.p_linkedFieldWithValuesObj;
    this.props.AdminIntegrationsMobx.a_update_linked_fields_field(p_linkedFieldWithValuesObj, "capture_field_id", i_updatedCEFieldID, "i");
  }

  onconfirm_add_new_value = (i_newIntegrationValueString) => {
    const p_linkedFieldWithValuesObj = this.props.p_linkedFieldWithValuesObj;
    this.props.AdminIntegrationsMobx.a_create_new_value_pair_under_field_pairing(p_linkedFieldWithValuesObj, i_newIntegrationValueString);
  }

  ondelete_linked_fields_and_all_mapped_values = () => {
    const p_linkedFieldWithValuesObj = this.props.p_linkedFieldWithValuesObj;
    this.props.AdminIntegrationsMobx.a_delete_linked_fields_and_all_mapped_values(p_linkedFieldWithValuesObj);
  }

  render() {
    const p_linkedFieldWithValuesObj = this.props.p_linkedFieldWithValuesObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_integrationSystemDisplayName = this.props.AdminIntegrationsMobx.c_integrationSystemDisplayName;

    const numMappedValues = p_linkedFieldWithValuesObj.mappedValuesArrayOfObjs.length;
    const linkedPairsUnicodeArrowCode = "\u21D0"; //"\u21D0" for <= single arrow, "\u21D4" for double <=> arrow

    var defaultValueTitle = "Use this switch to enable a custom default value assigned to " + c_integrationSystemDisplayName + " field '" + p_linkedFieldWithValuesObj.integration_field_db_name + "' when a new Capture is created or imported in " + c_productStylingObj.productName + ".";
    defaultValueTitle += "\nThis can help to fix integration systems that require certain fields to have a value when a new Capture is created.";
    defaultValueTitle += "\n[Note: This should be used only for fields that exist in " + c_integrationSystemDisplayName + " and not in " + c_productStylingObj.productName + ". If the field is mapped to a matching " + c_productStylingObj.productName + " field, a default value should be set for both systems using the '7. Details/Dates Fields' Admin Setup subtab.]";

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="integrationsLinkedField"
        p_itemID={p_linkedFieldWithValuesObj.id}
        p_itemSort={p_linkedFieldWithValuesObj.sort}
        p_itemSortColumnWidth="3em"
        p_itemSortNumberContainerClass={undefined}
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="tbMicroPad"
        p_itemClass="border1bbb bgWhite"
        p_itemStyleObj={undefined}
        p_lastItemExtraDropZoneHeight="4em"
        p_dragEntireItemTrueOnlySortNumberFalse={false}
        p_tblName="tbl_a_integrations_linked_fields"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={[]}
        p_filterValuesArray={[]}
        f_onDropForeignItem={undefined}>
        <div className="flex11a displayFlexColumn">
          <div className="flex00a displayFlexRowVc" style={{minHeight:"4.4em"}}>
            <div className="flex11a smallFullPad" style={{flexBasis:"100em"}}>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"linkedFieldIntegrationFieldDbName" + p_linkedFieldWithValuesObj.id}
                p_fieldDisplayName={undefined}
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={p_linkedFieldWithValuesObj.integration_field_db_name}
                p_valueIsEditableTFU={true}
                p_containerClass=""
                p_valueClass="font11 fontBold fontDarkOrange"
                p_valueTitleTF={undefined}
                f_onSaveChanged={this.onsave_integration_field_db_name}
              />
              {(p_linkedFieldWithValuesObj.integrationFieldDbNameContainsAnySpacesTF) &&
                <div className="microTopMargin lrMargin">
                  <CEGeneralReact.ErrorText p_text={"This " + c_integrationSystemDisplayName + " field shouldn't contain spaces. Use the 'Find Project' tool to the left to look up the correct field name spelling used by the " + c_integrationSystemDisplayName + " database."} />
                </div>
              }
              <div
                className="displayFlexRow smallTopMargin lrPad"
                title={defaultValueTitle}>
                <div className="flex00a displayFlexRowVc" style={{flexBasis:"6em"}}>
                  <font className="font09 fontTextLighter">
                    {"Default Value"}
                  </font>
                </div>
                <div className="flex00a displayFlexRowVc" style={{flexBasis:"6em"}}>
                  <CEGeneralReact.SwitchWithTextAndConfirmBox
                    p_isOnTF={p_linkedFieldWithValuesObj.integrationFieldUsingDefaultValueTF}
                    p_sizeEm={2.6}
                    p_onColor="d95"
                    p_offColor="bbb"
                    p_onText="On"
                    p_offText="none"
                    p_onTextFontClass="font09 fontItalic"
                    p_offTextFontClass="font09 fontItalic fontTextLighter"
                    f_onSwitch={this.onswitch_integration_field_using_default_value}
                  />
                </div>
                {(p_linkedFieldWithValuesObj.integrationFieldUsingDefaultValueTF) &&
                  <div className="flex11a displayFlexRowVc">
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString={"integrationFieldDefaultValue" + p_linkedFieldWithValuesObj.id}
                      p_fieldDisplayName={undefined}
                      p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                      p_valueRaw={p_linkedFieldWithValuesObj.integration_field_default_value_string}
                      p_valueIsEditableTFU={true}
                      p_containerClass=""
                      p_fieldClass={undefined}
                      p_fieldWidth={undefined}
                      f_onSaveChanged={this.onsave_integration_field_default_value_string}
                    />
                  </div>
                }
              </div>
              {(p_linkedFieldWithValuesObj.integrationFieldUsingDefaultValueTF && p_linkedFieldWithValuesObj.ceFieldIsValidTF) &&
                <div className="microTopMargin lrMargin">
                  <CEGeneralReact.ErrorText p_text={"Turning on this Default Value setting should only be done for " + c_integrationSystemDisplayName + " fields mapped to '--No Capture Field Selected--' (meaning the field exists in " + c_integrationSystemDisplayName + " and not in " + c_productStylingObj.productName + "). If the field is mapped to a " + c_productStylingObj.productName + " field, a default value should be set for both systems using the '7. Details/Dates Fields' Admin Setup subtab."} />
                </div>
              }
            </div>
            <div className="flex00a" style={{flexBasis:"5em"}}>
              <font className="font21" style={{color:"#999"}}>
                {linkedPairsUnicodeArrowCode}
              </font>
            </div>
            <div className={"flex11a smallFullPad " + ((!JSFUNC.select_int_is_filled_out_tf(p_linkedFieldWithValuesObj.capture_field_id)) ? ("bgLightRed") : (""))} style={{flexBasis:"115em"}}>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"linkedFieldCEField" + p_linkedFieldWithValuesObj.id}
                p_fieldDisplayName={undefined}
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureFieldFieldTypeObj}
                p_valueRaw={p_linkedFieldWithValuesObj.capture_field_id}
                p_valueIsEditableTFU={true}
                p_containerClass=""
                p_valueClass="font11 fontBold fontBlue"
                p_valueTitleTF={undefined}
                f_onSaveChanged={this.onsave_ce_field_db_name}
              />
            </div>
          </div>
          {(p_linkedFieldWithValuesObj.canMapValuesTF) &&
            <div className="medBottomMargin lrMedMargin borderRadius05 bgLightestGray smallFullPad">
              {p_linkedFieldWithValuesObj.mappedValuesArrayOfObjs.map((m_mappedValueObj, m_index) =>
                <IntegrationsMappedValueItem
                  key={m_mappedValueObj.id}
                  p_linkedFieldWithValuesObj={p_linkedFieldWithValuesObj}
                  p_mappedValueObj={m_mappedValueObj}
                  p_isOnlyItemTF={(numMappedValues === 1)}
                  p_isLastItemTF={(m_index === (numMappedValues - 1))}
                />
              )}
              <div className="displayFlexColumnHcVc smallTopMargin">
                <CEGeneralReact.ButtonWithConfirmBox
                  p_buttonType="add"
                  p_buttonText="Add New Value Pairing"
                  p_buttonFontClass={undefined}
                  p_tabIndex={undefined}
                  p_title={undefined}
                  p_confirmType="inputText"
                  p_confirmMessage={"Enter the name of a value used by " + c_integrationSystemDisplayName + " to map to its equivalent value in " + c_productStylingObj.productName}
                  p_button1Name="Add New Value"
                  p_textMustBeFilledOutTF={false}
                  f_onClickConfirm={this.onconfirm_add_new_value}
                />
              </div>
            </div>
          }
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <CEGeneralReact.DeleteMenu
            p_message={"Are you sure you want to delete this integration field pairing item '" + p_linkedFieldWithValuesObj.integration_field_db_name + "'?"}
            p_menuItemText={undefined}
            p_dotsFontClass={undefined}
            f_onDelete={this.ondelete_linked_fields_and_all_mapped_values}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinDbTbl> 
    );
  }
}));


const IntegrationsMappedValueItem = inject("AdminIntegrationsMobx", "DatabaseMobx")(observer(
class IntegrationsMappedValueItem extends Component { //props: p_linkedFieldWithValuesObj, p_mappedValueObj, p_isOnlyItemTF, p_isLastItemTF
  onsave_integration_value_string = (i_updatedIntegrationValueString) => {
    const p_mappedValueObj = this.props.p_mappedValueObj;
    this.props.AdminIntegrationsMobx.a_update_linked_field_values_field(p_mappedValueObj, "integration_value_string", i_updatedIntegrationValueString, "s");
  }

  onsave_selected_ce_value = (i_selectedCEValueRaw) => {
    const p_mappedValueObj = this.props.p_mappedValueObj;
    const selectedCEValueString = JSFUNC.num2str(i_selectedCEValueRaw);
    this.props.AdminIntegrationsMobx.a_update_linked_field_values_field(p_mappedValueObj, "capture_field_value_string", selectedCEValueString, "s");
  }

  ondelete_mapped_values_pair = () => {
    const p_mappedValueObj = this.props.p_mappedValueObj;
    this.props.AdminIntegrationsMobx.a_delete_mapped_values_pair(p_mappedValueObj);
  }

  render() {
    const p_linkedFieldWithValuesObj = this.props.p_linkedFieldWithValuesObj;
    const p_mappedValueObj = this.props.p_mappedValueObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const mappedValueRowMaxHeightEm = 1.8;

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="integrationsMappedValue"
        p_itemID={p_mappedValueObj.id}
        p_itemSort={p_mappedValueObj.sort}
        p_itemSortColumnWidth="3em"
        p_itemSortNumberContainerClass={undefined}
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="tbMicroPad"
        p_itemClass="border1bbb borderRadius10 bgLighterOrange"
        p_itemStyleObj={{minHeight:"1.4em"}}
        p_lastItemExtraDropZoneHeight="1em"
        p_tblName="tbl_a_integrations_linked_field_values"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={["linked_field_id"]}
        p_filterValuesArray={[p_linkedFieldWithValuesObj.id]}
        f_onDropForeignItem={undefined}>
        <div className="flex11a displayFlexColumnVc" style={{flexBasis:"100em"}}>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"mappedValueIntegrationValueString" + p_mappedValueObj.id}
            p_fieldDisplayName={undefined}
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
            p_valueRaw={p_mappedValueObj.integration_value_string}
            p_valueIsEditableTFU={true}
            p_containerClass=""
            p_valueClass="fontDarkOrange"
            p_valueMaxHeight={mappedValueRowMaxHeightEm + "em"}
            p_valueTitleTF={p_mappedValueObj.integration_value_string}
            f_onSaveChanged={this.onsave_integration_value_string}
          />
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
          <font className="font19" style={{color:"#999"}}>
            {"\u2248"}
          </font>
        </div>
        <div className={"flex11a displayFlexColumnVc " + ((p_mappedValueObj.captureFieldValueMaskSortIfoObj.isFilledOutTF) ? ("") : (""))} style={{flexBasis:"135em"}}>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"mappedValueCEValue" + p_mappedValueObj.id}
            p_fieldDisplayName={undefined}
            p_fieldTypeObj={p_linkedFieldWithValuesObj.ceFieldFieldTypeObjOrUndefined}
            p_valueRaw={p_mappedValueObj.captureFieldValueRaw}
            p_valueIsEditableTFU={true}
            p_containerClass=""
            p_valueClass=""
            p_valueMaxHeight={mappedValueRowMaxHeightEm + "em"}
            p_valueTitleTF={p_mappedValueObj.captureFieldValueMaskSortIfoObj.valueMaskPlainText}
            f_onSaveChanged={this.onsave_selected_ce_value}
          />
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <div className="flex00a displayFlexColumnHcVc" style={{height:mappedValueRowMaxHeightEm + "em"}}>
            <CEGeneralReact.DeleteMenu
              p_message={"Are you sure you want to delete this mapped value item '" + p_mappedValueObj.integration_value_string + "'?"}
              p_menuItemText={undefined}
              p_dotsFontClass={undefined}
              f_onDelete={this.ondelete_mapped_values_pair}
            />
          </div>
        </div>
      </CEGeneralReact.CEDragToResortItemWithinDbTbl> 
    );
  }
}));



const IntegrationsSpecialHandling = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class IntegrationsSpecialHandling extends Component {
  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_companyIntegrationSpecialIntegrationBlankDate = this.props.DatabaseMobx.c_companyIntegrationSpecialIntegrationBlankDate;
    const c_companyIntegrationSpecialIntegrationBlankDateTime = this.props.DatabaseMobx.c_companyIntegrationSpecialIntegrationBlankDateTime;

    return(
      <div className="flex11a displayFlexColumnHc bgLightGray yScroll">
        <IntegrationsSetupBox p_label="Special Handling of Blank Values">
          <div className="smallBottomMargin" style={{margin:"0 5em"}}>
            <font className="fontItalic fontTextLight">
              {"The " + c_productStylingObj.productName + " database expects date type fields that are not filled out to be in the format '0000-00-00' (and '0000-00-00 00:00:00' for date/time fields). If the integration database expects a different format (such as an empty string " + '""' + ") to represent a blank date, enter that here."}
            </font>
          </div>
          <IntegrationsSetupSingleField
            key="integrationSpecialHandlingBlankDate"
            p_valueRaw={c_companyIntegrationSpecialIntegrationBlankDate}
            p_fieldDbName="integration_special_integration_blank_date"
            p_fieldDisplayName="Blank Date"
            p_inputType="text"
            p_scrambleTF={false}
          />
          <IntegrationsSetupSingleField
            key="integrationSpecialHandlingBlankDateTime"
            p_valueRaw={c_companyIntegrationSpecialIntegrationBlankDateTime}
            p_fieldDbName="integration_special_integration_blank_datetime"
            p_fieldDisplayName="Blank Date/Time"
            p_inputType="text"
            p_scrambleTF={false}
          />
        </IntegrationsSetupBox>
      </div>
    );
  }
}));








const IntegrationsChangelog = inject("AdminIntegrationsMobx")(observer(
class IntegrationsChangelog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_clickedIntegrationsChangelogRowKey: -1
    };
  }

  componentDidMount() {
    this.props.AdminIntegrationsMobx.a_load_integrations_changelog_data();
  }

  onclick_column_header = (i_columnDbName) => {
    const o_integrationsChangelogFilterObj = this.props.AdminIntegrationsMobx.o_integrationsChangelogFilterObj;

    if(i_columnDbName === o_integrationsChangelogFilterObj.sortColumnDbName) { //clicked column already sorted, flip the direction of the sort
      const updatedSortIsAscTF = (!o_integrationsChangelogFilterObj.sortIsAscTF);
      this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("sortIsAscTF", updatedSortIsAscTF);
    }
    else {
      this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("sortColumnDbName", i_columnDbName);
      this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("sortIsAscTF", true);
    }
  }
  
  onclick_integrations_changelog_row = (i_changelogIDClicked) => {
    const s_clickedIntegrationsChangelogRowKey = this.state.s_clickedIntegrationsChangelogRowKey;

    var updatedClickedChangelogRowID = -1;
    if(i_changelogIDClicked !== s_clickedIntegrationsChangelogRowKey) {
      updatedClickedChangelogRowID = i_changelogIDClicked;
    }
    this.setState({s_clickedIntegrationsChangelogRowKey:updatedClickedChangelogRowID});
  }

  render() {
    const s_clickedIntegrationsChangelogRowKey = this.state.s_clickedIntegrationsChangelogRowKey;

    const o_integrationsChangelogLoadingDataOrErrorUndefinedTFU = this.props.AdminIntegrationsMobx.o_integrationsChangelogLoadingDataOrErrorUndefinedTFU;
    const o_integrationsChangelogFilterObj = this.props.AdminIntegrationsMobx.o_integrationsChangelogFilterObj;
    const c_filteredSortedItemsOnSelectedPageExpandedIntegrationsChangelogArrayOfObjs = this.props.AdminIntegrationsMobx.c_filteredSortedItemsOnSelectedPageExpandedIntegrationsChangelogArrayOfObjs;

    const flex01Array = [0,1,0,1,1,1,1,1,1,1];
    const flexBasisEmArray = [11.5,100,7,100,100,100,100,100,100,200];
    const columnKeysArray = ["dt", "ui", "iud", "cci", "ii", "cfi", "if", "cv", "iv", "em"];
    var columnDbNamesArray = ["dateTimeLocalMask", "userNameMask", "iudActionStringMask", "captureNameMask", "integration_unique_id_string", "ceFieldDisplayNameMask", "integration_field_db_name", "new_ce_value_string", "new_integration_value_string", "successErrorMask"];
    var columnDisplayNamesArray = ["Date", "CE User", "Action", "CE Capture Name", "Int. Unique ID", "CE Field", "Int. Field", "CE New Value", "Int. New Value", "Success/Error"];
    for(let c = 0; c < columnDbNamesArray.length; c++) {
      if(columnDbNamesArray[c] === o_integrationsChangelogFilterObj.sortColumnDbName) {
        columnDisplayNamesArray[c] += " " + ((o_integrationsChangelogFilterObj.sortIsAscTF) ? (" \u25BC") : (" \u25B2"));
      }
    }

    var changelogContentComponent = null;
    if(o_integrationsChangelogLoadingDataOrErrorUndefinedTFU === true) {
      changelogContentComponent = (
        <div className="displayFlexColumnHcVc medFullPad">
          <div className="flex00a smallBottomMargin textCenter">
            <font className="font11 fontItalic fontTextLight">
              {"Loading the Integrations Changelog data..."}
            </font>
          </div>
          <div className="flex00a">
            <CEGeneralReact.LoadingAnimation />
          </div>
        </div>
      );
    }
    else if(o_integrationsChangelogLoadingDataOrErrorUndefinedTFU === undefined) {
      changelogContentComponent = (
        <div className="displayFlexColumnHcVc">
          <div className="displayFlexColumnHcVc medFullMargin border1bbb borderRadius05 bgLightRed medFullPad" style={{width:"100%", maxWidth:"40em"}}>
            <div className="flex00a smallBottomMargin textCenter">
              <font className="font11 fontItalic fontRed">
                <div>{"There was an issue loading the Integrations Changelog data."}</div>
                <div>{"Switch tabs and come back to this one to try reloading."}</div>
              </font>
            </div>
          </div>
        </div>
      );
    }
    else {
      changelogContentComponent = (
        <>
          <IntegrationsChangelogFilterControls />
          <CEGeneralReact.ColumnHeaderRow
            p_flex01Array={flex01Array}
            p_flexBasisEmArray={flexBasisEmArray}
            p_columnKeysArray={columnKeysArray}
            p_columnDbNamesArray={columnDbNamesArray}
            p_columnDisplayNamesArray={columnDisplayNamesArray}
            f_onClickHeader={this.onclick_column_header}
          />
          <div className="flex11a yScroll yScrollBottomPad">
            {c_filteredSortedItemsOnSelectedPageExpandedIntegrationsChangelogArrayOfObjs.map((m_expandedIntegrationsChangelogObj) =>
              <CEGeneralReact.DataRow
                key={m_expandedIntegrationsChangelogObj.id}
                p_itemID={m_expandedIntegrationsChangelogObj.id}
                p_flex01Array={flex01Array}
                p_flexBasisEmArray={flexBasisEmArray}
                p_columnKeysArray={columnKeysArray}
                p_dataArray={JSFUNC.create_array_from_obj_and_array_of_property_names(m_expandedIntegrationsChangelogObj, columnDbNamesArray)}
                p_class={((m_expandedIntegrationsChangelogObj.id === s_clickedIntegrationsChangelogRowKey) ? ("bgLighterBlue") : (undefined))}
                p_heightLimit={((m_expandedIntegrationsChangelogObj.id === s_clickedIntegrationsChangelogRowKey) ? (undefined) : ("2em"))}
                p_title="[Click to view all content in this data row]"
                f_onClick={this.onclick_integrations_changelog_row}
              />
            )}
          </div>
        </>
      );
    }

    return (
      <div className="flex11a displayFlexColumn bgWhite">
        {changelogContentComponent}
      </div>
    );
  }
}));


const IntegrationsChangelogFilterControls = inject("AdminIntegrationsMobx", "DatabaseMobx")(observer(
class IntegrationsChangelogFilterControls extends Component { //props:
  onclick_increment_current_page_number = () => {
    const o_integrationsChangelogFilterObj = this.props.AdminIntegrationsMobx.o_integrationsChangelogFilterObj;
    const c_integrationsChangelogCanIncrementCurrentPageNumberTF = this.props.AdminIntegrationsMobx.c_integrationsChangelogCanIncrementCurrentPageNumberTF;

    if(c_integrationsChangelogCanIncrementCurrentPageNumberTF) {
      this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", o_integrationsChangelogFilterObj.currentPageNumber + 1);
    }
  }

  onclick_decrement_current_page_number = () => {
    const o_integrationsChangelogFilterObj = this.props.AdminIntegrationsMobx.o_integrationsChangelogFilterObj;
    const c_integrationsChangelogCanDecrementCurrentPageNumberTF = this.props.AdminIntegrationsMobx.c_integrationsChangelogCanDecrementCurrentPageNumberTF;

    if(c_integrationsChangelogCanDecrementCurrentPageNumberTF) {
      this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", o_integrationsChangelogFilterObj.currentPageNumber - 1);
    }
  }

  onchange_integrations_changelog_filter_date_min = (i_selectedDate) => {
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("dateMin", i_selectedDate);
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_integrations_changelog_filter_date_max = (i_selectedDate) => {
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("dateMax", i_selectedDate);
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_integrations_changelog_filter_user_id = (i_selectedUserID) => {
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("userID", i_selectedUserID);
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_integrations_changelog_filter_action = (i_selectedActionDbNamesComma) => {
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("actionDbNamesComma", i_selectedActionDbNamesComma);
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_integrations_changelog_filter_ce_capture = (i_selectedCECaptureIDsComma) => {
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("ceCaptureIDsComma", i_selectedCECaptureIDsComma);
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_integrations_changelog_filter_integration_unique_id = (i_inputIntegrationUniqueIDSearchText) => {
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("integrationUniqueIDSearchText", i_inputIntegrationUniqueIDSearchText);
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_integrations_changelog_filter_ce_field = (i_selectedCEFieldIDsComma) => {
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("ceFieldIDsComma", i_selectedCEFieldIDsComma);
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_integrations_changelog_filter_integration_field = (i_selectedIntegrationFieldDbNamesComma) => {
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("integrationFieldDbNamesComma", i_selectedIntegrationFieldDbNamesComma);
    this.props.AdminIntegrationsMobx.a_set_integrations_changelog_filter_field("currentPageNumber", 1);
  }

  render() {
    const o_integrationsChangelogFilterObj = this.props.AdminIntegrationsMobx.o_integrationsChangelogFilterObj; //numItemsPerPage, currentPageNumber, dateMin, dateMax, userID, actionDbNamesComma, ceCaptureIDsComma, integrationUniqueIDSearchText, ceFieldIDsComma, integrationFieldDbNamesComma, sortColumnDbName, sortIsAscTF
    const c_integrationsChangelogFilteredTotalNumItems = this.props.AdminIntegrationsMobx.c_integrationsChangelogFilteredTotalNumItems;
    const c_integrationsChangelogCurrentPageFirstItemNumber = this.props.AdminIntegrationsMobx.c_integrationsChangelogCurrentPageFirstItemNumber;
    const c_integrationsChangelogCurrentPageLastItemNumber = this.props.AdminIntegrationsMobx.c_integrationsChangelogCurrentPageLastItemNumber;
    const c_integrationsChangelogCanIncrementCurrentPageNumberTF = this.props.AdminIntegrationsMobx.c_integrationsChangelogCanIncrementCurrentPageNumberTF;
    const c_integrationsChangelogCanDecrementCurrentPageNumberTF = this.props.AdminIntegrationsMobx.c_integrationsChangelogCanDecrementCurrentPageNumberTF;
    const c_selectIntegrationsLogActionsFieldTypeObj = this.props.AdminIntegrationsMobx.c_selectIntegrationsLogActionsFieldTypeObj;
    const c_selectMultiIntegrationFieldDbNamesFieldTypeObj = this.props.AdminIntegrationsMobx.c_selectMultiIntegrationFieldDbNamesFieldTypeObj;
    const c_selectUserWithAdminPowerFieldTypeObj = this.props.DatabaseMobx.c_selectUserWithAdminPowerFieldTypeObj;
    const c_selectMultiAllCapturesFieldTypeObj = this.props.DatabaseMobx.c_selectMultiAllCapturesFieldTypeObj;
    const c_selectMultiCaptureFieldsFieldTypeObj = this.props.DatabaseMobx.c_selectMultiCaptureFieldsFieldTypeObj;

    const pageButtonWidthEm = 2.2;
    const pageButtonHeightEm = 1.4;
    const pageButtonCanClickBgClass = "bgDarkOrangeGradient hoverLightOrangeGradient";
    const pageButtonCanClickFontClass = "fontWhite";
    const pageButtonCantClickBgClass = "bgLightestBlue";
    const pageButtonCantClickFontClass = "fontTextLighter";

    return(
      <div className="flex00a displayFlexRow flexWrap borderB1bbb bgLightesterGray tbPad">
        <IntegrationsChangelogControlContainer p_label="Pages of Integration Changelog Entries" p_width="18em">
          <div className="displayFlexRowVc bgAlmostWhite border bevelBorderColors tbMicroPad lrMedPad">
            <div className="flex00a">
              <CEGeneralReact.PageIncrementOrDecrementButton
                p_incTrueDecFalse={false}
                p_canClickTF={c_integrationsChangelogCanDecrementCurrentPageNumberTF}
                p_widthEm={pageButtonWidthEm}
                p_heightEm={pageButtonHeightEm}
                p_canClickBgClass={pageButtonCanClickBgClass}
                p_canClickFontClass={pageButtonCanClickFontClass}
                p_cantClickBgClass={pageButtonCantClickBgClass}
                p_cantClickFontClass={pageButtonCantClickFontClass}
                f_onClick={this.onclick_decrement_current_page_number}
              />
            </div>
            <div className="flex11a lrPad textCenter">
              <font className="">
                {c_integrationsChangelogCurrentPageFirstItemNumber + " - " + c_integrationsChangelogCurrentPageLastItemNumber + " of " + c_integrationsChangelogFilteredTotalNumItems}
              </font>
            </div>
            <div className="flex00a">
              <CEGeneralReact.PageIncrementOrDecrementButton
                p_incTrueDecFalse={true}
                p_canClickTF={c_integrationsChangelogCanIncrementCurrentPageNumberTF}
                p_widthEm={pageButtonWidthEm}
                p_heightEm={pageButtonHeightEm}
                p_canClickBgClass={pageButtonCanClickBgClass}
                p_canClickFontClass={pageButtonCanClickFontClass}
                p_cantClickBgClass={pageButtonCantClickBgClass}
                p_cantClickFontClass={pageButtonCantClickFontClass}
                f_onClick={this.onclick_increment_current_page_number}
              />
            </div>
          </div>
        </IntegrationsChangelogControlContainer>
        <IntegrationsChangelogControlContainer p_label="Date Min" p_width={undefined} p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_integrationsChangelogFilterObj.dateMin, this.props.DatabaseMobx.c_genericDateFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
            p_valueRaw={o_integrationsChangelogFilterObj.dateMin}
            p_tabIndex={1}
            f_onChangeOrOnSelect={this.onchange_integrations_changelog_filter_date_min}
          />
        </IntegrationsChangelogControlContainer>
        <IntegrationsChangelogControlContainer p_label="Date Max" p_width={undefined} p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_integrationsChangelogFilterObj.dateMax, this.props.DatabaseMobx.c_genericDateFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
            p_valueRaw={o_integrationsChangelogFilterObj.dateMax}
            p_tabIndex={2}
            f_onChangeOrOnSelect={this.onchange_integrations_changelog_filter_date_max}
          />
        </IntegrationsChangelogControlContainer>
        <IntegrationsChangelogControlContainer p_label="Filter by User" p_width="18em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_integrationsChangelogFilterObj.userID, this.props.DatabaseMobx.c_selectUserFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_selectUserWithAdminPowerFieldTypeObj}
            p_valueRaw={o_integrationsChangelogFilterObj.userID}
            p_tabIndex={3}
            f_onChangeOrOnSelect={this.onchange_integrations_changelog_filter_user_id}
          />
        </IntegrationsChangelogControlContainer>
        <IntegrationsChangelogControlContainer p_label="Filter by Action(s)" p_width="14em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_integrationsChangelogFilterObj.actionDbNamesComma, c_selectIntegrationsLogActionsFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_selectIntegrationsLogActionsFieldTypeObj}
            p_valueRaw={o_integrationsChangelogFilterObj.actionDbNamesComma}
            p_tabIndex={4}
            f_onChangeOrOnSelect={this.onchange_integrations_changelog_filter_action}
          />
        </IntegrationsChangelogControlContainer>
        <IntegrationsChangelogControlContainer p_label="Filter by CE Capture" p_width="14em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_integrationsChangelogFilterObj.ceCaptureIDsComma, c_selectMultiAllCapturesFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_selectMultiAllCapturesFieldTypeObj}
            p_valueRaw={o_integrationsChangelogFilterObj.ceCaptureIDsComma}
            p_tabIndex={5}
            f_onChangeOrOnSelect={this.onchange_integrations_changelog_filter_ce_capture}
          />
        </IntegrationsChangelogControlContainer>
        <IntegrationsChangelogControlContainer p_label="Filter by Int. Unique ID" p_width="14em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_integrationsChangelogFilterObj.integrationUniqueIDSearchText, this.props.DatabaseMobx.c_genericTextFieldTypeObj)}>
          <CEGeneralReact.SearchInputTextWithClearX
            p_inputText={o_integrationsChangelogFilterObj.integrationUniqueIDSearchText}
            p_placeholderText="Integration Unique ID"
            p_includeSearchIconTF={false}
            p_heightEm={2}
            p_bgHashColor={undefined}
            p_tabIndex={6}
            f_onChange={this.onchange_integrations_changelog_filter_integration_unique_id}
            f_onClick={undefined}
            f_onKeyDownEsc={undefined}
          />
        </IntegrationsChangelogControlContainer>
        <IntegrationsChangelogControlContainer p_label="Filter by CE Field" p_width="14em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_integrationsChangelogFilterObj.ceFieldIDsComma, c_selectMultiCaptureFieldsFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_selectMultiCaptureFieldsFieldTypeObj}
            p_valueRaw={o_integrationsChangelogFilterObj.ceFieldIDsComma}
            p_tabIndex={7}
            f_onChangeOrOnSelect={this.onchange_integrations_changelog_filter_ce_field}
          />
        </IntegrationsChangelogControlContainer>
        <IntegrationsChangelogControlContainer p_label="Filter by Int. Field" p_width="14em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_integrationsChangelogFilterObj.integrationFieldDbNamesComma, c_selectMultiIntegrationFieldDbNamesFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_selectMultiIntegrationFieldDbNamesFieldTypeObj}
            p_valueRaw={o_integrationsChangelogFilterObj.integrationFieldDbNamesComma}
            p_tabIndex={8}
            f_onChangeOrOnSelect={this.onchange_integrations_changelog_filter_integration_field}
          />
        </IntegrationsChangelogControlContainer>
      </div>
    );
  }
}));

function IntegrationsChangelogControlContainer(props) { //props: p_label, p_width, p_isFilledOutTF, children
  const p_label = props.p_label;
  const p_width = props.p_width;
  const p_isFilledOutTF = props.p_isFilledOutTF;

  return(
    <div
      className={"flex00a lrMargin " + ((p_isFilledOutTF) ? ("bgLightPurpleGradient") : ("")) + " tbMicroPad lrPad"}
      style={{width:p_width}}>
      <div className="microBottomMargin textCenter">
        <font className="font09 fontItalic fontTextLight">
          {p_label}
        </font>
      </div>
      <div>
        {props.children}
      </div>
    </div>
  );
}