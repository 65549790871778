import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import * as JSFUNC from "../../Library/JSFUNC.js";
import * as LibraryReact from "../../Library/LibraryReact.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";


//-----------------------------------------------------------------------------------------------
//External facing components to run and edit the Contacts System
//-----------------------------------------------------------------------------------------------
export const ContactsTabWithNoSelection = inject("ContactsMobx")(observer(
class ContactsTabWithNoSelection extends Component { //props:
  render() {
    return(
      <ContactsSystem
        p_selectMode="no_select"
      />
    );
  }
}));




export const SelectOrSelectMultiContactsFloatingBox = inject("ContactsMobx")(observer(
class SelectOrSelectMultiContactsFloatingBox extends Component { //props: p_selectMode, p_selectedContactIDOrContactIDsComma, p_contactIDsAlreadyAddedArray, p_title, p_openContactIsPersonTF, p_openContactID, f_onSaveChanged, f_onCancel
  //p_selectMode                            "select_contact_person", "selectmulti_contact_persons", "select_contact_company", "selectmulti_contact_companies", "no_select"
  //p_selectedContactIDOrContactIDsComma    (essentially "valueRaw") the contact or contacts that are selected
  //p_contactIDsAlreadyAddedArray           contacts that had already been added to whatever subsystem this is serving, these contacts will show up gray and cannot be added to the selection
  //p_openContactIsPersonTF                 can supply these 2 inputs together to initialize the contacts system with a contact already opened on the right side
  //p_openContactID
  //p_initialZoomedCompanyID                (optional) provide to zoom in the left side tree on one org with all divisions expanded
  //p_initialZoomedPersonID                 (optional) provide to zoom in the left side tree on one org containing this contact person

  constructor(props) {
    super(props);

    this.props.ContactsMobx.a_initialize_contacts_system();
    this.props.ContactsMobx.a_initialize_initial_and_current_selected_contact_ids_arrays(this.props.p_selectMode, this.props.p_selectedContactIDOrContactIDsComma);

    //if the input p_initialZoomedCompanyID is provided, set the filter to only show that company fully expanded
    if(this.props.p_initialZoomedCompanyID !== undefined) {
      this.props.ContactsMobx.a_set_left_side_zoomed_org_id_from_company_id(this.props.p_initialZoomedCompanyID);
    }
    else if(this.props.p_initialZoomedPersonID !== undefined) {
      this.props.ContactsMobx.a_set_left_side_zoomed_org_id_from_person_id(this.props.p_initialZoomedPersonID);
    }
  }

  onclick_floating_box_save_selected_contacts = () => {
    const outputSelectedContactIDOrContactIDsComma = this.props.ContactsMobx.get_changed_output_selected_value_or_unchanged_undefined(this.props.p_selectMode);
    if(outputSelectedContactIDOrContactIDsComma !== undefined) { //the current output has been changed from the initial input value, call the external save function
      if(JSFUNC.is_function(this.props.f_onSaveChanged)) {
        this.props.f_onSaveChanged(outputSelectedContactIDOrContactIDsComma);
      }
    }
    else { //if the current output is the same as the original input, call a cancel operation
      if(JSFUNC.is_function(this.props.f_onCancel)) {
        this.props.f_onCancel();
      }
    }
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_selectedContactIDOrContactIDsComma = this.props.p_selectedContactIDOrContactIDsComma;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;
    const p_title = this.props.p_title;
    const p_openContactIsPersonTF = this.props.p_openContactIsPersonTF;
    const p_openContactID = this.props.p_openContactID;

    const o_currentSelectedContactIDsArray = this.props.ContactsMobx.o_currentSelectedContactIDsArray;

    const [isSelectingPersonsTFU, isMultiSelectTFU] = this.props.ContactsMobx.is_person_and_is_multiselect_tfu_from_select_mode(p_selectMode);
    const personCompanyLabel = this.props.ContactsMobx.person_or_company_string_from_is_person_and_is_multi_tf(isSelectingPersonsTFU, isMultiSelectTFU); //Person/Persons/Company/Companies

    var title = p_title;
    if(title === undefined) {
      title = "Select Contact " + personCompanyLabel;
    }

    var selectedContactsArrayOfObjs = [];
    for(let contactID of o_currentSelectedContactIDsArray) {
      var contactObj = this.props.ContactsMobx.contact_company_or_person_obj_from_id(isSelectingPersonsTFU, contactID);
      selectedContactsArrayOfObjs.push(contactObj);
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="contactsSystemSelect"
        p_title={title}
        p_bgColorClass="bgContactsLightestYellow"
        p_shadowColor="#886"
        p_floatingLayerNumber={2}
        f_onClickSave={((this.props.f_onSaveChanged && this.props.f_onCancel) ? (this.onclick_floating_box_save_selected_contacts) : (undefined))}
        f_onClickCancel={this.props.f_onCancel}>
        <div className="flex11a displayFlexColumn">
          {(isMultiSelectTFU !== undefined) &&
            <div className="flex00a" style={{borderBottom:"solid 1px #999"}}>
              <div className="displayFlexRowVc flexWrap smallFullPad bgContactsYellow">
                <div className="flex00a displayFlexRowVc rMargin microTopMargin microBottomMargin" style={{height:"2em"}}>
                  <font className="font13 fontBold">
                    {"Contact " + personCompanyLabel + " Selected" + ((isMultiSelectTFU) ? (" (" + o_currentSelectedContactIDsArray.length + ")") : ("")) + ":"}
                  </font>
                </div>
                {selectedContactsArrayOfObjs.map((m_contactObj) =>
                  <ContactsFloatingBoxSelectedContactsBubbleList
                    key={m_contactObj.id}
                    p_contactObj={m_contactObj}
                  />
                )}
              </div>
            </div>
          }
          <ContactsSystem
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_openContactIsPersonTF={p_openContactIsPersonTF}
            p_openContactID={p_openContactID}
          />
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const ContactsFloatingBoxSelectedContactsBubbleList = inject("ContactsMobx")(observer(
class ContactsFloatingBoxSelectedContactsBubbleList extends Component { //props: p_contactObj
  onclick_selected_contact_bubble_open_contact_on_right_side = () => {
    const p_contactObj = this.props.p_contactObj;
    this.props.ContactsMobx.a_open_contact(p_contactObj.isPersonTF, p_contactObj.id); //onclick bubble open contact for view/edit on right side of contact system
  }

  onclick_remove_contact_from_selection = () => {
    this.props.ContactsMobx.a_remove_contact_from_selection(this.props.p_contactObj.id);
  }

  render() {
    const p_contactObj = this.props.p_contactObj;

    const contactName = this.props.ContactsMobx.contact_name_plaintext_from_contact_obj(p_contactObj);

    return(
      <div className="flex00a lrMargin microTopMargin microBottomMargin" style={{flexBasis:"20em"}}>
        <LibraryReact.InteractiveDiv
          p_class="positionRelative displayFlexRowVc lrPad microTopPad microBottomPad border bevelBorderColors borderRadius05 bgContactsLightestYellow cursorPointer"
          f_onClick={this.onclick_selected_contact_bubble_open_contact_on_right_side}>
          <div className="flex00a rMargin">
            <CompanyOrPersonIcon p_isPersonTF={p_contactObj.isPersonTF} p_sizeEm={1.25} />
          </div>
          <div className="flex11a">
            <LibraryReact.Nowrap>
              {contactName}
              {(p_contactObj.isPersonTF) &&
                <font className="fontItalic fontTextLighter">{" | " + p_contactObj.title}</font>
              }
            </LibraryReact.Nowrap>
          </div>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove '" + contactName + "' as a selected Contact"}
            f_onClick={this.onclick_remove_contact_from_selection}
          />
        </LibraryReact.InteractiveDiv>
      </div>
    );
  }
}));



export const ContactBubble = inject("ContactsMobx", "DatabaseMobx")(observer(
class ContactBubble extends Component { //props: p_isPersonTF, p_contactID, p_iconSizeEm, p_fontClass, p_personMarkInvalidEmailTF, f_onClickRemove
  //used as the display for the name of a teammate or competitor so that it is interactive and quickly opens the contact system (and cannot be changed, the entire teammate would have to be deleted to change which contact this one is pointing to)
  //clicking on a contact bubble by default opens the contact system for that particular contact in a floating box
  constructor(props) {
    super(props);
    this.state = {
      s_hoverOpenTF: false,
      s_floatingContactBoxOpenTF: false
    };

    this.iconContainerElementRef = React.createRef();
  }

  onhover_bubble = (i_hoverTF) => {
    this.setState({s_hoverOpenTF:i_hoverTF});
  }

  onclick_bubble = () => {
    this.setState({s_floatingContactBoxOpenTF:true});
  }

  onclose_contacts_floating_box = () => {
    this.setState({s_floatingContactBoxOpenTF:false});
  }

  onclick_remove_x = () => {
    if(this.props.f_onClickRemove) {
      this.props.f_onClickRemove(this.props.p_contactID);
    }
  }

  render() {
    const s_hoverOpenTF = this.state.s_hoverOpenTF;
    const s_floatingContactBoxOpenTF = this.state.s_floatingContactBoxOpenTF;

    const p_isPersonTF = this.props.p_isPersonTF;
    const p_contactID = this.props.p_contactID;
    const p_iconSizeEm = JSFUNC.prop_value(this.props.p_iconSizeEm, 1.25);
    const p_fontClass = JSFUNC.prop_value(this.props.p_fontClass, "");
    const p_personMarkInvalidEmailTF = JSFUNC.prop_value(this.props.p_personMarkInvalidEmailTF, false);

    const contactObj = this.props.ContactsMobx.contact_company_or_person_obj_from_id(p_isPersonTF, p_contactID);
    const contactName = this.props.ContactsMobx.contact_name_plaintext_from_contact_obj(contactObj);
    const contactPersonEmail = this.props.ContactsMobx.contact_person_email_from_contact_person_obj(contactObj);

    //hover box calculations
    var contactInfoHoverBoxComponent = null;
    if(s_hoverOpenTF) {
      var fieldsArrayOfObjs = [];
      if(contactObj.isPersonTF) { //person info fields
        fieldsArrayOfObjs = [
          {id:-1, display_name:"Name", valueMask:contactObj.fullName},
          {id:-2, display_name:"Company", valueMask:contactObj.parentCompanyAbbrOrLegalName},
          {id:-3, display_name:"Title", valueMask:contactObj.title},
          {id:-4, display_name:"Email", valueMask:contactObj.email},
          {id:-5, display_name:"Phone", valueMask:contactObj.phone}
        ];
        for(let extraFieldObj of this.props.DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs) {
          fieldsArrayOfObjs.push({id:extraFieldObj.id, display_name:extraFieldObj.display_name, valueMask:contactObj[extraFieldObj.db_name]});
        }
      }
      else { //company info fields
        fieldsArrayOfObjs = [{id:-1, display_name:"Name", valueMask:contactObj.abbrOrLegalName}];
        if(JSFUNC.text_or_number_is_filled_out_tf(contactObj.organizationAbbrOrLegalName) && (contactObj.abbrOrLegalName !== contactObj.organizationAbbrOrLegalName)) {
          fieldsArrayOfObjs.push({id:-2, display_name:"Organization", valueMask:contactObj.organizationAbbrOrLegalName});
        }
        fieldsArrayOfObjs.push({id:-3, display_name:"Business Type", valueMask:this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(contactObj.business_type_id, this.props.DatabaseMobx.c_selectBusinessTypeFieldTypeObj)});
        fieldsArrayOfObjs.push({id:-4, display_name:"Small Business Certification(s)", valueMask:this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(contactObj.sb_certifications_bm_set_aside_ids_comma, this.props.DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj)});
        fieldsArrayOfObjs.push({id:-5, display_name:"Capabilities", valueMask:this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(contactObj.capability_ids_comma, this.props.DatabaseMobx.c_selectMultiCapabilitiesFieldTypeObj)});
        fieldsArrayOfObjs.push({id:-6, display_name:"NAICS Code(s)", valueMask:this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(contactObj.naics_code_ids_comma, this.props.DatabaseMobx.c_selectMultiBitMasterNaicsCodesCombinedNamesFieldTypeObj)});
      }

      //compute correct positioning of hover box relative to proximity to client window edges
      var hoverBoxTop = ((p_iconSizeEm / 1.18) + 0.7) + "em";
      var hoverBoxBottom = undefined;
      const iconContainerElementRefCurrentOrNull = this.iconContainerElementRef.current;
      if(iconContainerElementRefCurrentOrNull !== null) {
        const iconContainerClientRectObj = iconContainerElementRefCurrentOrNull.getBoundingClientRect();
        const windowHeightPx = window.innerHeight;
        const iconHeightFromTopPx = iconContainerClientRectObj.y;
        if(iconHeightFromTopPx > (windowHeightPx * 0.66)) { //more than 2/3 way down the screen
          hoverBoxTop = undefined;
          hoverBoxBottom = ((p_iconSizeEm / 1.18) + 0.9) + "em";
        }
      }

      contactInfoHoverBoxComponent = (
        <div
          className="positionAbsolute displayFlexColumn microTopMargin bgAlmostWhite lrMedPad smallTopPad smallBottomPad"
          style={{zIndex:"8888", top:hoverBoxTop, bottom:hoverBoxBottom, width:"30em", border:"solid 1px #888", boxShadow:"0.25em 0.25em 0.25em #888"}}>
          {fieldsArrayOfObjs.map((m_fieldObj) =>
            <div key={m_fieldObj.id} className="displayFlexRow tbMicroPad">
              <div className="flex00a" style={{flexBasis:"10em"}}>
                <font className="fontItalic" style={{color:"#663"}}>
                  {m_fieldObj.display_name + ":"}
                </font>
              </div>
              <div className="flex11a">
                <font className="">
                  {m_fieldObj.valueMask}
                </font>
              </div>
            </div>
          )}
        </div>
      );
    }

    const contactBubbleComponent = (
      <>
        <LibraryReact.InteractiveDiv
          p_class="positionRelative displayFlexRowVc lrPad microTopPad microBottomPad border bevelBorderColors borderRadius05 bgContactsLightestYellow cursorPointer"
          f_onClick={this.onclick_bubble}
          f_onHover={this.onhover_bubble}>
          <div ref={this.iconContainerElementRef} className="flex00a rMargin">
            <CompanyOrPersonIcon p_isPersonTF={p_isPersonTF} p_sizeEm={p_iconSizeEm} />
          </div>
          <div className="flex11a" style={{flexBasis:"150em"}}>
            <LibraryReact.Nowrap p_fontClass={p_fontClass}>
              {contactName}
              {(p_isPersonTF && JSFUNC.text_or_number_is_filled_out_tf(contactObj.title)) &&
                <font className="fontItalic fontTextLighter">{" | " + contactObj.title}</font>
              }
            </LibraryReact.Nowrap>
          </div>
          {(p_personMarkInvalidEmailTF) &&
            <div className="flex11a" style={{flexBasis:"100em"}}>
              <LibraryReact.Nowrap p_fontClass={p_fontClass + " fontTextLighter"}>
                {contactPersonEmail}
              </LibraryReact.Nowrap>
            </div>
          }
          {(JSFUNC.is_function(this.props.f_onClickRemove)) &&
            <CEGeneralReact.RemoveItemButton
              p_tabIndex={-1}
              p_title={undefined}
              f_onClick={this.onclick_remove_x}
            />
          }
          {contactInfoHoverBoxComponent}
        </LibraryReact.InteractiveDiv>
        {(s_floatingContactBoxOpenTF) &&
          <SelectOrSelectMultiContactsFloatingBox
            p_selectMode="noSelect"
            p_title={"Contact Details for '" + contactName + "'"}
            p_openContactIsPersonTF={p_isPersonTF}
            p_openContactID={p_contactID}
            p_initialZoomedCompanyID={((p_isPersonTF) ? (undefined) : (p_contactID))}
            p_initialZoomedPersonID={((p_isPersonTF) ? (p_contactID) : (undefined))}
            f_onCancel={this.onclose_contacts_floating_box}
          />
        }
      </>
    );

    if(p_personMarkInvalidEmailTF) {
      const contactPersonEmailErrorMessage = JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(contactPersonEmail);
      if(contactPersonEmailErrorMessage !== undefined) {
        return(
          <div className="border borderColorRed" style={{borderWidth:"2px"}}>
            {contactBubbleComponent}
            <div className="tbMicroPad lrPad">
              <CEGeneralReact.ErrorText p_text={contactPersonEmailErrorMessage} />
            </div>
          </div>
        );
      }
    }

    return(contactBubbleComponent);
  }
}));



export const MultiAddContactBubblesVerticalList = inject("DatabaseMobx")(observer(
class MultiAddContactBubblesVerticalList extends Component { //props: p_canEditTF, p_isPersonTF, p_addedContactIDsComma, p_addButtonType, p_addButtonText, p_addInstructions, p_tabIndex, p_errorIfEmptyTF, p_personMarkInvalidEmailTF, p_initialZoomedCompanyID, f_onAddContacts, f_onRemoveContact, f_onChange
  //have a choice of using f_onAddContacts/f_onRemoveContact together independently, or using f_onChange which combines both add and remove and returns the new updated comma list of contactIDs
  onadd_selected_contacts = (i_newContactIDsToAddComma) => {
    if(this.props.f_onAddContacts) {
      this.props.f_onAddContacts(i_newContactIDsToAddComma);
    }

    if(this.props.f_onChange) {
      const updatedContactPersonIDsComma = JSFUNC.add_value_to_comma_list(i_newContactIDsToAddComma, this.props.p_addedContactIDsComma);
      this.props.f_onChange(updatedContactPersonIDsComma);
    }
  }

  onremove_single_contact = (i_contactIDToRemove) => {
    if(this.props.f_onRemoveContact) {
      this.props.f_onRemoveContact(i_contactIDToRemove);
    }

    if(this.props.f_onChange) {
      const updatedContactPersonIDsComma = JSFUNC.remove_all_values_from_comma_list(i_contactIDToRemove, this.props.p_addedContactIDsComma);
      this.props.f_onChange(updatedContactPersonIDsComma);
    }
  }

  render() {
    const p_canEditTF = JSFUNC.prop_value(this.props.p_canEditTF, true);
    const p_isPersonTF = this.props.p_isPersonTF;
    const p_addedContactIDsComma = this.props.p_addedContactIDsComma;
    const p_addButtonType = this.props.p_addButtonType;
    const p_addButtonText = this.props.p_addButtonText;
    const p_addInstructions = this.props.p_addInstructions;
    const p_tabIndex = this.props.p_tabIndex;
    const p_errorIfEmptyTF = this.props.p_errorIfEmptyTF;
    const p_personMarkInvalidEmailTF = this.props.p_personMarkInvalidEmailTF;
    const p_initialZoomedCompanyID = this.props.p_initialZoomedCompanyID;

    const addedContactIDsArray = JSFUNC.convert_comma_list_to_int_array(p_addedContactIDsComma);
    const numAddedContacts = addedContactIDsArray.length;
    const fieldTypeObj = ((p_isPersonTF) ? (this.props.DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj) : (this.props.DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj));

    return(
      <>
        {(p_canEditTF) &&
          <>
            <CEGeneralReact.CaptureExecFieldAddNewItemsButton
              p_buttonType={p_addButtonType}
              p_buttonText={p_addButtonText}
              p_addInstructions={p_addInstructions}
              p_fieldTypeObj={fieldTypeObj}
              p_valuesToNotIncludeArray={addedContactIDsArray}
              p_contactsZoomedCompanyID={p_initialZoomedCompanyID}
              p_tabIndex={p_tabIndex}
              f_onAddSelectedItems={this.onadd_selected_contacts}
            />
            <div className="smallBottomMargin" />
          </>
        }
        {(numAddedContacts > 0) ? (
          addedContactIDsArray.map((m_contactID) =>
            <MultiAddContactBubbleVertical
              key={m_contactID}
              p_canEditTF={p_canEditTF}
              p_isPersonTF={p_isPersonTF}
              p_contactID={m_contactID}
              p_personMarkInvalidEmailTF={p_personMarkInvalidEmailTF}
              f_onRemoveContact={this.onremove_single_contact}
            />
          )
        ) : (
          <div className={"textCenter " + ((p_errorIfEmptyTF) ? ("border2 borderColorRed") : (""))}>
            <font className="fontItalic fontTextLighter">
              {"--No Contact " + ((p_isPersonTF) ? ("Persons") : ("Companies")) + " added yet--"}
            </font>
          </div>
        )}
      </>
    );
  }
}));

class MultiAddContactBubbleVertical extends Component { //props: p_canEditTF, p_isPersonTF, p_contactID, p_personMarkInvalidEmailTF, f_onRemoveContact
  onremove_contact = () => {
    if(this.props.f_onRemoveContact) {
      this.props.f_onRemoveContact(this.props.p_contactID);
    }
  }

  render() {
    const p_canEditTF = this.props.p_canEditTF;

    return(
      <div className="tbMicroMargin">
        <ContactBubble
          p_isPersonTF={this.props.p_isPersonTF}
          p_contactID={this.props.p_contactID}
          p_personMarkInvalidEmailTF={this.props.p_personMarkInvalidEmailTF}
          f_onClickRemove={((p_canEditTF) ? (this.onremove_contact) : (undefined))}
        />
      </div>
    );
  }
}





export const CompanyOrPersonIcon = inject("UserMobx")(observer(
class CompanyOrPersonIcon extends Component { //props: p_isPersonTF, p_sizeEm
  render() {
    const contactIconColor = "996";
    const sizePx = Math.round(this.props.UserMobx.c_userFontSizePx * this.props.p_sizeEm);
    if(this.props.p_isPersonTF) {
      return(<CEGeneralReact.SvgPerson p_sizePx={sizePx} p_color={contactIconColor} />);
    }
    return(<CEGeneralReact.SvgCompany p_sizePx={sizePx} p_color={contactIconColor} />);
  }
}));







//-----------------------------------------------------------------------------------------------
//Contacts System
//-----------------------------------------------------------------------------------------------
const ContactsSystem = inject("CaptureExecMobx", "ContactsMobx")(observer(
class ContactsSystem extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray, p_openContactIsPersonTF, p_openContactID

  // This is the main Contacts System where all contacts can be searched, created, edited, and deleted. It is used:
  //    1. when the "Contacts" tab is opened from the left nav (p_selectMode is "no_select", adding/editing/deleting is possible, no selection function)
  //    2. when selecting 1 or more persons or companies for some other subsystem using SelectOrSelectMultiContactsFloatingBox which presents this inside the floating box with selection
  // p_selectMode: "select_contact_person", "selectmulti_contact_persons", "select_contact_company", "selectmulti_contact_companies", "no_select"
  // p_contactIDsAlreadyAddedArray: contactIDs that are not available for selection
  // p_openContactIsPersonTF/p_openContactID: can supply these 2 inputs together to initialize the contacts system with a contact already opened on the right side

  componentDidMount() {
    if((this.props.p_openContactIsPersonTF !== undefined) && (this.props.p_openContactID !== undefined)) {
      this.props.ContactsMobx.a_open_contact(this.props.p_openContactIsPersonTF, this.props.p_openContactID);
    }
  }

  render() {
    const p_selectMode = JSFUNC.prop_value(this.props.p_selectMode, "no_select");
    const p_contactIDsAlreadyAddedArray = JSFUNC.prop_value(this.props.p_contactIDsAlreadyAddedArray, []);

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_leftSideState = this.props.ContactsMobx.o_leftSideState;
    const o_rightSideState = this.props.ContactsMobx.o_rightSideState;

    const leftSideTreeComponent = (
      <ContactsSystemLeftTree
        p_selectMode={p_selectMode}
        p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
      />
    );

    const rightSideSingleContactComponent = (
      <ContactsSystemRightSingleContact
        p_selectMode={p_selectMode}
        p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
      />
    );

    //for mobile, determine which single screen to show based on the left/right states
    if(c_isMobileTF) {
      if((o_rightSideState === "empty") || (o_leftSideState === "selectParentDivision")) {
        return(leftSideTreeComponent);
      }
      return(rightSideSingleContactComponent);
    }

    //for larger screens, always show both left and right sides regardless of their states
    return(
      <div className="flex11a displayFlexRow bgContactsLightestYellow">
        <div className="flex11a displayFlexColumn" style={{flexBasis:"120em", borderRight:"solid 1px #999"}}>
          {leftSideTreeComponent}
        </div>
        <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
          {rightSideSingleContactComponent}
        </div>
      </div>
    );
  }
}));



const ContactsSystemLeftTree = inject("CaptureExecMobx", "ContactsMobx", "UserMobx")(observer(
class ContactsSystemLeftTree extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray
  onclick_create_new_contact_company = () => {
    this.props.ContactsMobx.a_initialize_new_contact(false); //initialize a new contact company (p_isPersonTF is false)
    this.props.ContactsMobx.a_set_right_side_state("createNewContact");
  }

  onclick_create_new_contact_person = () => {
    this.props.ContactsMobx.a_initialize_new_contact(true); //initialize a new contact person (p_isPersonTF is true)
    this.props.ContactsMobx.a_set_right_side_state("createNewContact");
  }

  onchange_search_contacts = (i_changedValue) => {
    this.props.ContactsMobx.a_set_search_text(i_changedValue);
  }

  onclick_open_advanced_search = () => {
    this.props.ContactsMobx.a_open_contacts_advanced_search_left_side();
  }

  onclick_open_export = () => {
    this.props.ContactsMobx.a_set_left_side_state("exportContactsToCsv");
  }

  onclick_close_export = () => {
    this.props.ContactsMobx.a_set_left_side_state("treeWithSearch");
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;

    const o_loadingThroughPhase2CompleteTF = this.props.CaptureExecMobx.o_loadingThroughPhase2CompleteTF;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_leftSideState = this.props.ContactsMobx.o_leftSideState;
    const o_searchText = this.props.ContactsMobx.o_searchText;
    const o_rightSideState = this.props.ContactsMobx.o_rightSideState;
    const c_userCanCreateContactsTF = this.props.UserMobx.c_userCanCreateContactsTF;

    if(!o_loadingThroughPhase2CompleteTF) {
      return(
        <div className="flex11a displayFlexColumnHcVc textCenter">
          <div className="smallBottomMargin">
            <font className="font11 fontItalic fontTextLight">
              {"CaptureExec is loading your system Contacts"}
            </font>
          </div>
          <CEGeneralReact.LoadingAnimation />
        </div>
      );
    }

    var leftSideIsTreeWithSearchTF = false;
    var leftSideIsAdvancedCompanySearchTF = false;
    var leftSideIsExportContactsToCsvTF = false;
    var leftSideIsSelectParentDivisionTF = false;
    if(o_leftSideState === "treeWithSearch") { leftSideIsTreeWithSearchTF = true; }
    else if(o_leftSideState === "advancedCompanySearch") { leftSideIsAdvancedCompanySearchTF = true; }
    else if(o_leftSideState === "exportContactsToCsv") { leftSideIsExportContactsToCsvTF = true; }
    else if(o_leftSideState === "selectParentDivision") { leftSideIsSelectParentDivisionTF = true; }

    var rightSideIsEmptyTF = false;
    var rightSideIsCreateNewContactTF = false;
    if(o_rightSideState === "empty") { rightSideIsEmptyTF = true; }
    else if(o_rightSideState === "createNewContact") { rightSideIsCreateNewContactTF = true; }

    if(leftSideIsAdvancedCompanySearchTF) {
      return(
        <ContactsAdvancedSearch
          p_selectMode={p_selectMode}
          p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
        />
      );
    }

    const searchTextIsEmptyTF = (o_searchText.length === 0);

    return(
      <>
        <div className="flex00a displayFlexRowVc" style={{height:"4em", borderBottom:"solid 1px #bb7"}}>
          {(c_userCanCreateContactsTF && !leftSideIsSelectParentDivisionTF && !rightSideIsCreateNewContactTF) &&
            <>
              <CreateNewPersonOrCompanyContactButton p_isPersonTF={false} f_onClick={this.onclick_create_new_contact_company} />
              <CreateNewPersonOrCompanyContactButton p_isPersonTF={true} f_onClick={this.onclick_create_new_contact_person} />
            </>
          }
          <div className="flex11a lrPad">
            <CEGeneralReact.SearchInputTextWithClearX
              p_inputText={o_searchText}
              p_placeholderText="Find Persons/Companies by Name..."
              p_includeSearchIconTF={true}
              p_heightEm={2.2}
              p_tabIndex={1}
              f_onChange={this.onchange_search_contacts}
            />
          </div>
          <div className="flex00a lrPad">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text={((c_isMobileOrTabletTF) ? ("Adv") : ("Advanced Search"))}
              p_title="Find potential Teammates by filtering Contact Companies by Business Type, SB Certs, Capabilities, NAICS, etc. View past wins and losses by Prime and Sub in previous teaming situations."
              f_onClick={this.onclick_open_advanced_search}
            />
          </div>
          <div className="flex00a lrPad">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text={((c_isMobileOrTabletTF) ? ("Exp") : ("Export"))}
              p_title="Use this tool to select which parts of the Contacts system to export to a .csv file"
              f_onClick={this.onclick_open_export}
            />
          </div>
        </div>
        {(leftSideIsSelectParentDivisionTF) &&
          <LeftTreeBottomChooseParentDivisionUnassignedButtons />
        }
        {(searchTextIsEmptyTF) ? (
          <AllExpandableOrgsContainingAllPersons p_selectMode={p_selectMode} p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray} />
        ) : (
          <SearchResultsCompaniesAndPersons p_selectMode={p_selectMode} p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray} />
        )}
        {(leftSideIsExportContactsToCsvTF) &&
          <ExportContactsToCsvFloatingBox
            f_onClickCancel={this.onclick_close_export}
          />
        }
      </>
    );
  }
}));

const CreateNewPersonOrCompanyContactButton = inject("UserMobx")(observer(
class CreateNewPersonOrCompanyContactButton extends Component { //props: p_isPersonTF, f_onClick
  render() {
    const p_isPersonTF = this.props.p_isPersonTF;
    
    const c_svgPerson24CreateNew = this.props.UserMobx.c_svgPerson24CreateNew;
    const c_svgCompany24CreateNew = this.props.UserMobx.c_svgCompany24CreateNew;

    const addNewPersonOrCompanySvgIconComponent = ((p_isPersonTF) ? (c_svgPerson24CreateNew) : (c_svgCompany24CreateNew));
    return(
      <div className="flex00a lrPad">
        <div
          class="displayFlexColumnHcVc border bevelBorderColors borderRadius05 bgContactsAddNewButtonWithHover cursorPointer"
          style={{height:"3.4em", width:"3.4em"}}
          title={"Create a new " + ((p_isPersonTF) ? ("person") : ("company")) + " in the contacts system"}
          onClick={this.props.f_onClick}>
          {addNewPersonOrCompanySvgIconComponent}
        </div>
      </div>
    );
  }
}));

const ExportContactsToCsvFloatingBox = inject("ContactsMobx")(observer(
class ExportContactsToCsvFloatingBox extends Component { //props: f_onClickCancel
  constructor(props) {
    super(props);
    this.state = {
      s_exportTopLevelContactCompaniesTF: true,
      s_exportSubdivisionContactCompaniesTF: true,
      s_exportContactPersonsTF: true,
      s_exportIsRunningTF: false
    };
  }

  onswitch_export_top_level_contact_companies = () => {
    this.setState({s_exportTopLevelContactCompaniesTF:!this.state.s_exportTopLevelContactCompaniesTF});
  }

  onswitch_export_subdivision_contact_companies = () => {
    this.setState({s_exportSubdivisionContactCompaniesTF:!this.state.s_exportSubdivisionContactCompaniesTF});
  }

  onswitch_export_contact_persons = () => {
    this.setState({s_exportContactPersonsTF:!this.state.s_exportContactPersonsTF});
  }



  onclick_export_contacts_to_csv_file = () => {
    const s_exportTopLevelContactCompaniesTF = this.state.s_exportTopLevelContactCompaniesTF;
    const s_exportSubdivisionContactCompaniesTF = this.state.s_exportSubdivisionContactCompaniesTF;
    const s_exportContactPersonsTF = this.state.s_exportContactPersonsTF;

    this.setState({s_exportIsRunningTF:true});

    const functionOnFinish = () => {
      this.setState({s_exportIsRunningTF:false});
    }
    this.props.ContactsMobx.a_export_contacts_to_csv_for_download(s_exportTopLevelContactCompaniesTF, s_exportSubdivisionContactCompaniesTF, s_exportContactPersonsTF, functionOnFinish);
  }

  render() {
    const s_exportTopLevelContactCompaniesTF = this.state.s_exportTopLevelContactCompaniesTF;
    const s_exportSubdivisionContactCompaniesTF = this.state.s_exportSubdivisionContactCompaniesTF;
    const s_exportContactPersonsTF = this.state.s_exportContactPersonsTF;
    const s_exportIsRunningTF = this.state.s_exportIsRunningTF;

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title="Export All Contacts to .csv File"
        f_onClickCancel={this.props.f_onClickCancel}>
        <div className="flex11a yScroll medFullPad">
          <div className="bigBottomMargin">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={s_exportTopLevelContactCompaniesTF}
              p_onText="Include Top Level Contact Companies"
              p_offText="Don't Include Top Level Contact Companies"
              f_onSwitch={this.onswitch_export_top_level_contact_companies}
            />
          </div>
          <div className="bigBottomMargin">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={s_exportSubdivisionContactCompaniesTF}
              p_onText="Include Subdivision Contact Companies"
              p_offText="Don't Include Subdivision Contact Companies"
              f_onSwitch={this.onswitch_export_subdivision_contact_companies}
            />
          </div>
          <div className="bigBottomMargin">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={s_exportContactPersonsTF}
              p_onText="Include Contact Persons"
              p_offText="Don't Include Contact Persons"
              f_onSwitch={this.onswitch_export_contact_persons}
            />
          </div>
        </div>
        <div className="flex00a displayFlexRowHcVc flexWrap borderT1bbb" style={{flexBasis:"3.5em"}}>
          {(s_exportIsRunningTF) ? (
            <div className="flex00a displayFlexColumnHcVc">
              <div className="flex00a textCenter">
                <font className="fontItalic fontTextLighter">
                  {"Exporting Contacts to .csv File"}
                </font>
              </div>
              <CEGeneralReact.LoadingAnimation />
            </div>
          ) : (
            <>
              <div className="flex00a lrMedPad">
                <CEGeneralReact.CEButton
                  p_type={((!s_exportTopLevelContactCompaniesTF && !s_exportSubdivisionContactCompaniesTF && !s_exportContactPersonsTF) ? ("blueDisabled") : ("blue"))}
                  p_text="Export Contacts to .csv"
                  f_onClick={this.onclick_export_contacts_to_csv_file}
                />
              </div>
              <div className="flex00a lrMedPad">
                <CEGeneralReact.CEButton
                  p_type="gray"
                  p_text="Cancel"
                  f_onClick={this.props.f_onClickCancel}
                />
              </div>
            </>
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));



const ContactsSystemRightSingleContact = inject("ContactsMobx")(observer(
class ContactsSystemRightSingleContact extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray
  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;

    const o_rightSideState = this.props.ContactsMobx.o_rightSideState;
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;
    const o_newContactObj = this.props.ContactsMobx.o_newContactObj;

    if((o_rightSideState === "savingNewContact") && (o_newContactObj !== undefined)) {
      const personOrCompanyString = this.props.ContactsMobx.person_or_company_string_from_is_person_and_is_multi_tf(o_newContactObj.isPersonTF, false);
      const newContactName = ((o_newContactObj.isPersonTF) ? (o_newContactObj.first_name + " " + o_newContactObj.last_name) : (o_newContactObj.legal_name));
      return(
        <div className="flex11a displayFlexColumnHcVc lrMedMargin textCenter">
          <div>
            <font className="fontItalic fontTextLighter">
              {"Creating new Contact " + personOrCompanyString + " '" + newContactName + "'"}
            </font>
          </div>
          <div className="microTopMargin">
            <CEGeneralReact.LoadingAnimation />
          </div>
        </div>
      );
    }

    //open a single contact to view/edit each field
    if(((o_rightSideState === "viewEditSingleContact") || (o_rightSideState === "advancedSearchCapturesAnalysis")) && (c_openContactObj !== undefined)) {
      return(
        <SingleContactViewEdit p_selectMode={p_selectMode} p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray} />
      );
    }

    //this component can be the create new contact form where all fields are available for edit all at once
    if((o_rightSideState === "createNewContact") && (o_newContactObj !== undefined)) {
      return(
        <NewContactForm />
      );
    }

    //o_rightSideState is "empty" or any other invalid value, no contact is currently being viewed or added, so the right side is blank with a message
    return(
      <div className="flex11a displayFlexColumnHcVc lrMedMargin textCenter">
        <font className="fontItalic fontTextLighter">
          <div>
            {"Click any Contact Person or Company on the left to view/edit their information"}
          </div>
          <div className="smallTopMargin">
            {"Click the [Add Company] or [Add Person] buttons to add a new contact to the system"}
          </div>
        </font>
      </div>
    );
  }
}));





//-----------------------------------------------------------------------------------------------
//Left Side Sub Components
//-----------------------------------------------------------------------------------------------
const LeftTreeBottomChooseParentDivisionUnassignedButtons = inject("ContactsMobx")(observer(
class LeftTreeBottomChooseParentDivisionUnassignedButtons extends Component { //props:
  onclick_cancel_select_parent_division = () => {
    this.props.ContactsMobx.a_set_left_side_state("treeWithSearch");
  }

  onclick_select_top_level_as_parent_division = () => {
    const o_rightSideState = this.props.ContactsMobx.o_rightSideState;
    const o_newContactObj = this.props.ContactsMobx.o_newContactObj;
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;

    const newParentCompanyID = -1; //set the new parent companyID as -1 for selecting it as top level

    if(o_rightSideState === "createNewContact") {
      const parentCompanyFieldDbName = this.props.ContactsMobx.parent_company_field_db_name(o_newContactObj.isPersonTF);
      this.props.ContactsMobx.a_update_new_contact_field_local(parentCompanyFieldDbName, newParentCompanyID);
    }
    else {
      const fieldDbName = this.props.ContactsMobx.parent_company_field_db_name(c_openContactObj.isPersonTF);
      this.props.ContactsMobx.a_update_contact_field(c_openContactObj.isPersonTF, c_openContactObj.id, fieldDbName, newParentCompanyID, "i");
    }

    this.props.ContactsMobx.a_set_left_side_state("treeWithSearch");
  }

  render() {
    const openContactIsPersonTF = this.props.ContactsMobx.o_openContactIsPersonTF;

    return(
      <div className="displayFlexColumnHcVc borderB1bbb lrPad" style={{height:"3.5em", background:"#aaa"}}>
        <div className="flex00a displayFlexRowVc flexWrap">
          <div className="flex00a lrMedMargin">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text={((openContactIsPersonTF) ? ("Set Person as Unassigned") : ("Set as Top Level Company"))}
              p_tabIndex={-1}
              f_onClick={this.onclick_select_top_level_as_parent_division}
            />
          </div>
          <div className="flex00a">
            <font className="">
              {"Selecting a Parent Company/Division"}
            </font>
          </div>
          <div className="flex00a lrMedMargin">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Cancel Selection"
              p_tabIndex={-1}
              f_onClick={this.onclick_cancel_select_parent_division}
            />
          </div>
        </div>
      </div>
    );
  }
}));


const AllExpandableOrgsContainingAllPersons = inject("ContactsMobx")(observer(
class AllExpandableOrgsContainingAllPersons extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray
  onclick_exit_zoomed_org = () => {
    this.props.ContactsMobx.a_set_left_side_zoomed_org_id_from_org_id(undefined);
  }

  onclick_show_all_contact_companies = () => {
    this.props.ContactsMobx.a_set_left_side_num_orgs_drawn_to_unlimited();
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;

    const leftSideNumOrgsDrawnLimit = this.props.ContactsMobx.o_leftSideNumOrgsDrawnLimit;
    const leftSideZoomedCompanyID = this.props.ContactsMobx.o_leftSideZoomedCompanyID;
    const allOrgsArrayOfObjs = this.props.ContactsMobx.c_allOrgsArrayOfObjs;

    const gcssImportContactPersonsContactCompanyObj = {id:-2,  abbrOrLegalName:"Persons Created from GCSS Import"};
    const unassignedCompanyObj = {id:-1,  abbrOrLegalName:"Persons Not Assigned to a Company"};
    const numOrgs = allOrgsArrayOfObjs.length;
    const showOnlyZoomedOrgTF = (leftSideZoomedCompanyID !== undefined);

    return(
      <div className="flex11a yScroll yScrollBottomPad">
        {(showOnlyZoomedOrgTF) &&
          <div className="medFullPad">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="View all Contact Companies"
              f_onClick={this.onclick_exit_zoomed_org}
            />
          </div>
        }
        {allOrgsArrayOfObjs.map((m_companyObj, m_index) => (((m_index < leftSideNumOrgsDrawnLimit) || showOnlyZoomedOrgTF) && (!showOnlyZoomedOrgTF || (leftSideZoomedCompanyID === m_companyObj.id))) &&
          <ExpandableOrganization
            key={m_companyObj.id}
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_contactCompanyObj={m_companyObj}
          />
        )}
        {(!showOnlyZoomedOrgTF && (numOrgs > leftSideNumOrgsDrawnLimit)) &&
          <div className="displayFlexRow tbMedPad bgContactsDarkYellow">
            <div className="flex00a lrPad">
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text={"Show all " + numOrgs + " Contact Companies"}
                f_onClick={this.onclick_show_all_contact_companies}
              />
            </div>
            <div className="flex11a lrPad" />
          </div>
        }
        {(!showOnlyZoomedOrgTF) &&
          <>
            {(false) &&
              <ExpandableOrganization
                p_selectMode={p_selectMode}
                p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
                p_contactCompanyObj={gcssImportContactPersonsContactCompanyObj}
              />
            }
            <ExpandableOrganization
              p_selectMode={p_selectMode}
              p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
              p_contactCompanyObj={unassignedCompanyObj}
            />
          </>
        }
      </div>
    );
  }
}));

const ExpandableOrganization = inject("ContactsMobx")(observer(
class ExpandableOrganization extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray, p_contactCompanyObj
  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;
    const p_contactCompanyObj = this.props.p_contactCompanyObj;

    const orgIsExpandedTF = this.props.ContactsMobx.company_item_is_expanded_tf(p_contactCompanyObj.id);

    var numDivisionsInOrg = undefined;
    var numPersonsInOrg = undefined;
    if(p_contactCompanyObj.id <= 0) { //persons not assigned to company has a person count at the 'org' level
      const unassignedOrGcssPersonsArrayOfObjs = this.props.ContactsMobx.all_persons_within_company_id_arrayOfObjs(p_contactCompanyObj.id);
      numPersonsInOrg = unassignedOrGcssPersonsArrayOfObjs.length;
    }
    else { //all other actual orgs get their precomputed division/person counts from the fields inside the company obj
      numDivisionsInOrg = p_contactCompanyObj.numOrgDivisions;
      numPersonsInOrg = p_contactCompanyObj.numPersons;
    }

    return(
      <div className="bgContactsDarkYellow" style={{borderBottom:"solid 1px #888"}}>
        <ContactItem
          p_selectMode={p_selectMode}
          p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
          p_contactObj={p_contactCompanyObj}
          p_numDivisions={numDivisionsInOrg}
          p_numPersons={numPersonsInOrg}
        />
        {(orgIsExpandedTF) &&
          <OrgDivisionsAndPersonsTree
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_orgCompanyObj={p_contactCompanyObj}
          />
        }
      </div>
    );
  }
}));


const OrgDivisionsAndPersonsTree = inject("ContactsMobx")(observer(
class OrgDivisionsAndPersonsTree extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray, p_orgCompanyObj
  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;
    const p_orgCompanyObj = this.props.p_orgCompanyObj;

    const orgCompanyID = p_orgCompanyObj.id;
    const orgAbbrOrLegalName = this.props.ContactsMobx.contact_name_plaintext_from_contact_obj(p_orgCompanyObj);
    const allDivisionsInOrgArrayOfObjs = this.props.ContactsMobx.all_companies_within_org_id_arrayOfObjs(orgCompanyID);
    const allExpandedDivisionTreeIDsArray = this.props.ContactsMobx.all_expanded_companies_within_org_tree_id_array(orgCompanyID);

    var divisionAndPersonComponentsArray = []; //all divisions in tree_id asc order with persons in each division placed below the subdivisions

    //persons directly assigned to top org at the top
    const orgPersonsArrayOfObjs = this.props.ContactsMobx.all_persons_within_company_id_arrayOfObjs(orgCompanyID);
    for(let personObj of orgPersonsArrayOfObjs) {
      divisionAndPersonComponentsArray.push(
        <ContactItem
          key={"p" + personObj.id}
          p_selectMode={p_selectMode}
          p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
          p_contactObj={personObj}
          p_leftIndent={1}
        />
      );
    }

    for(let orgDivisionContactCompanyObj of allDivisionsInOrgArrayOfObjs) {
      var divisionIsExpandedTF = this.props.ContactsMobx.company_item_is_expanded_tf(orgDivisionContactCompanyObj.id);

      //division treeID e.g. "01020101"
      var orgDivisionTreeID = orgDivisionContactCompanyObj.tree_id;
      var orgDivisionTreeIDLength = orgDivisionTreeID.length;
      var leftIndent = Math.ceil(orgDivisionTreeIDLength / 2);

      //only draw a division if its direct parent is open
      var divisionParentIsExpandedTF = true;
      if(orgDivisionTreeIDLength >= 4) {
        var divisionParentTreeID = orgDivisionTreeID.substring(0, orgDivisionTreeIDLength - 2);
        divisionParentIsExpandedTF = JSFUNC.in_array(divisionParentTreeID, allExpandedDivisionTreeIDsArray);
      }

      var divisionPersonsArrayOfObjs = []; //do not calculate this if it is not used in an expanded division
      if(divisionParentIsExpandedTF || divisionIsExpandedTF) {
        divisionPersonsArrayOfObjs = this.props.ContactsMobx.all_persons_within_company_id_arrayOfObjs(orgDivisionContactCompanyObj.id);
      }

      //draw this division (if the parent division that contains it is currently expanded)
      if(divisionParentIsExpandedTF) {
        var numDivisions = 0; //compute how many divisions are wihtin this division's tree of subdivisions
        var numPersons = divisionPersonsArrayOfObjs.length; //compute how many persons are wihtin this division's tree of subdivisions (including and starting with num persons assigned to this current division)
        for(let subDivisionObj of allDivisionsInOrgArrayOfObjs) {
          if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(orgDivisionContactCompanyObj.tree_id, subDivisionObj.tree_id, false)) {
            numDivisions++;

            var subDivisionPersonsArrayOfObjs = this.props.ContactsMobx.all_persons_within_company_id_arrayOfObjs(subDivisionObj.id);
            numPersons += subDivisionPersonsArrayOfObjs.length;
          }
        }

        //draw this particular division if its parent division is expanded
        divisionAndPersonComponentsArray.push(
          <ContactItem
            key={"d" + orgDivisionContactCompanyObj.id}
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_contactObj={orgDivisionContactCompanyObj}
            p_numDivisions={numDivisions}
            p_numPersons={numPersons}
            p_leftIndent={leftIndent}
          />
        );
      }

      //only add the persons for this division if the folder is open
      if(divisionIsExpandedTF) {
        for(let personObj of divisionPersonsArrayOfObjs) {
          divisionAndPersonComponentsArray.push(
            <ContactItem
              key={"p" + personObj.id}
              p_selectMode={p_selectMode}
              p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
              p_contactObj={personObj}
              p_leftIndent={leftIndent + 1}
            />
          );
        }
      }
    }

    //if there are no divisions or contacts under this organization, display a message saying this org is empty
    if(divisionAndPersonComponentsArray.length === 0) {
      var message = "";
      if(orgCompanyID > 0) {
        message = "No Contacts or Divisions have been added to Organization '" + orgAbbrOrLegalName + "'";
      }
      else if(orgCompanyID === -2) {
        message = "No Contact Persons have been imported from the GCSS system";
      }
      else {
        message = "No Contact Persons have an unassigned Company";
      }

      divisionAndPersonComponentsArray = (
        <div className="displayFlexColumnHcVc medFullMargin">
          <font className="fontItalic fontWhite">
            {message}
          </font>
        </div>
      );
    }

    return(
      <div className="bigBottomMargin">
        {divisionAndPersonComponentsArray}
      </div>
    );
  }
}));


const ContactItem = inject("CaptureExecMobx", "ContactsMobx", "UserMobx")(observer(
class ContactItem extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray, p_contactObj, p_isSearchResultTF, p_numDivisions, p_numPersons, p_leftIndent
  onclick_contact_item = () => {
    this.props.ContactsMobx.a_open_contact(this.props.p_contactObj.isPersonTF, this.props.p_contactObj.id);
  }

  onclick_expand_collapse_org = () => {
    this.props.ContactsMobx.a_expand_collapse_company(this.props.p_contactObj.id);
  }

  ondelete_contact = () => {
    this.props.ContactsMobx.a_delete_contact(this.props.p_contactObj.isPersonTF, this.props.p_contactObj.id);
  }

  onclick_select_company_as_parent_division = () => {
    const newSelectedParentCompanyID = this.props.p_contactObj.id;
    const o_rightSideState = this.props.ContactsMobx.o_rightSideState;
    if(o_rightSideState === "createNewContact") { //update only local memory o_newContactObj when creating a new contact
      this.props.ContactsMobx.a_update_new_contact_parent_company_field_local(newSelectedParentCompanyID);
    }
    else { //update the database when editing an existing contact
      this.props.ContactsMobx.a_update_contact_parent_company_field(newSelectedParentCompanyID);
    }
    this.props.ContactsMobx.a_set_left_side_state("treeWithSearch");
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;
    const p_contactObj = this.props.p_contactObj;
    const isSearchResultTF = JSFUNC.prop_value(this.props.p_isSearchResultTF, false);
    const leftIndent = JSFUNC.prop_value(this.props.p_leftIndent, 0);
    const numDivisions = this.props.p_numDivisions; //if provided for a company, draw the tiny company icon with this count number, otherwise not drawn at all for undefined
    const numPersons = this.props.p_numPersons; //if provided for a company, draw the tiny person icon with this count number, otherwise not drawn at all for undefined

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_leftSideState = this.props.ContactsMobx.o_leftSideState;
    const o_rightSideState = this.props.ContactsMobx.o_rightSideState;
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;
    const c_openContactAllChildrenIDsArray = this.props.ContactsMobx.c_openContactAllChildrenIDsArray;
    const c_userCanDeleteContactsTF = this.props.UserMobx.c_userCanDeleteContactsTF;

    const isSelectingParentDivisionTF = (o_leftSideState === "selectParentDivision");

    const contactID = p_contactObj.id;
    const isValidContactTF = (contactID > 0);
    const isPersonTF = p_contactObj.isPersonTF;
    const isTopCompanyTF = this.props.ContactsMobx.contact_is_top_org_tf(p_contactObj);
    const personCompanyString = this.props.ContactsMobx.person_or_company_string_from_is_person_and_is_multi_tf(isPersonTF, false);

    //person contact item row has title on the far right as a fixed width nowrap box
    var personTitleContainerWidth = ((isSearchResultTF) ? ("14em") : ("17em")); //mediaQueryFlag 5/6
    if(this.props.CaptureExecMobx.o_mediaQueryFlag === 1 || this.props.CaptureExecMobx.o_mediaQueryFlag === 2) { personTitleContainerWidth = ((isSearchResultTF) ? ("11em") : ("12em")); }
    else if(this.props.CaptureExecMobx.o_mediaQueryFlag === 3) { personTitleContainerWidth = ((isSearchResultTF) ? ("12em") : ("13em")); }
    else if(this.props.CaptureExecMobx.o_mediaQueryFlag === 4) { personTitleContainerWidth = ((isSearchResultTF) ? ("13em") : ("15em")); }

    //contact full name from person or company
    const contactName = this.props.ContactsMobx.contact_name_plaintext_from_contact_obj(p_contactObj);

    var isExpandedTF = false; //persons cannot be expanded
    if(!isPersonTF) { //company
      isExpandedTF = this.props.ContactsMobx.company_item_is_expanded_tf(contactID); //determine if this contact company has been expanded
    }

    //company/person icon
    var CompanyOrPersonSvgComponent = null;
    if(isPersonTF) {
      CompanyOrPersonSvgComponent = this.props.UserMobx.c_svgPerson15;
    }
    else {
      CompanyOrPersonSvgComponent = ((isTopCompanyTF) ? (this.props.UserMobx.c_svgCompany20) : (this.props.UserMobx.c_svgCompany15)); //icon sizing (larger for top company)
    }


    var contactNameFontClass = "";
    var contactBottomBorder = "solid 1px #ccb";
    if(isTopCompanyTF) { //style changes if the contact is a top org
      contactNameFontClass = "font12 " + ((!isValidContactTF) ? ("fontItalic") : ("fontBold"));
      if(!isExpandedTF) {
        contactBottomBorder = "none";
      }
    }

    //determine background color for persons/subdivisions/orgs
    var showDeleteDotsTF = true;
    var bgColor = "transparent";
    if(isSelectingParentDivisionTF) {
      showDeleteDotsTF = false;
      if(isPersonTF) { bgColor = "#aaa"; }
      else if(isTopCompanyTF) { bgColor = "#ccd"; }
      else { bgColor = "#ccd"; }
    }
    else {
      if(isPersonTF) { bgColor = "#f5f5a2"; }
      else if(isTopCompanyTF) { bgColor = "#f1f1c1" }
      else { bgColor = "#f1f1c1" }
    }

    //determine if a company/division/org can be deleted (must have 0 subdivisions under it to be possible)
    var deleteContactMenuItemsArrayOfObjs = [];
    if(isPersonTF) {
      deleteContactMenuItemsArrayOfObjs.push({
        displayName: "Delete Contact Person",
        confirmType: "confirmDelete",
        confirmTitle: "Delete Contact Person",
        confirmMessage: "Are you sure you want to delete Contact Person '" + contactName + "' from the CaptureExec Contacts system?",
        functionOnClickConfirmButton: this.ondelete_contact
      });
    }
    else {
      if(numDivisions > 0) { //cannot delete a company if it has subdivisions still under it, they must all be removed first
        deleteContactMenuItemsArrayOfObjs.push({
          displayName: "Delete Contact Company",
          confirmType: "cancel",
          confirmTitle: "Delete Contact Company",
          confirmMessage: "Contact company '" + contactName + "' cannot be deleted until all divisions (" + numDivisions + ") beneath it are deleted first.",
          functionOnClickConfirmButton: this.ondelete_contact
        });
      }
      else {
        deleteContactMenuItemsArrayOfObjs.push({
          displayName: "Delete Contact Company",
          confirmType: "confirmDelete",
          confirmTitle: "Delete Contact Company",
          confirmMessage: "Are you sure you want to delete contact company '" + contactName + "' from the CaptureExec Contacts system?",
          functionOnClickConfirmButton: this.ondelete_contact
        });
      }
    }

    //can't view/edit a different existing contact when trying to create a new one
    const canOpenContactViewEditWithRowClickTF = (isValidContactTF && (o_rightSideState !== "createNewContact") && !isSelectingParentDivisionTF);

    //selecting a parent
    var showSelectAsParentButtonTF = false;
    if(isSelectingParentDivisionTF && !isPersonTF && isValidContactTF) { //when selecting a parent, the button is only next to valid company contacts
      if(o_rightSideState === "viewEditSingleContact") { //changing the parent company for an existing contact
        if(c_openContactObj) {
          if(c_openContactObj.isPersonTF) { //can select any company to be the parent of a person
            showSelectAsParentButtonTF = true;
          }
          else { //selecting parent for an existing contact that is currently being viewed/edited, don't allow selection of itself or its children
            showSelectAsParentButtonTF = ((contactID !== c_openContactObj.id) && !JSFUNC.in_array(contactID, c_openContactAllChildrenIDsArray));
          }
        }
      }
      else { //currently selecting a parent for a new contact being created, any company can be selected as a parent
        showSelectAsParentButtonTF = true;
      }
    }

    const contactItemComponent = (
      <div
        className={"displayFlexRowVc lrMedPad " + ((canOpenContactViewEditWithRowClickTF) ? ("hoverLighterBlueGradient cursorPointer") : (""))}
        style={{height:"2.8em", backgroundColor:bgColor, borderBottom:contactBottomBorder}}
        title={"View/Edit info about this Contact " + personCompanyString}
        onClick={((canOpenContactViewEditWithRowClickTF) ? (this.onclick_contact_item) : (undefined))}>
        {(!isSelectingParentDivisionTF) &&
          <SelectContactCheckBox
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_contactObj={p_contactObj}
            p_sizeEm={1.2}
            p_styleObj={{marginRight:"0.8em"}}
          />
        }
        {(showSelectAsParentButtonTF) &&
          <div style={{marginRight:"0.8em"}}>
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Parent"
              f_onClick={this.onclick_select_company_as_parent_division}
            />
          </div>
        }
        <div className="flex00a rMargin">
          {CompanyOrPersonSvgComponent}
        </div>
        {(!isPersonTF && isValidContactTF) &&
          <div className="flex00a rMargin">
            <CEGeneralReact.RatingScoreBox1to5 p_rating={p_contactObj.tr_total} p_sizeEm={2.2} />
          </div>
        }
        <div className="flex11a" style={{flexBasis:"150em"}}>
          <LibraryReact.Nowrap p_fontClass={contactNameFontClass}>
            {contactName}
          </LibraryReact.Nowrap>
        </div>
        {(isPersonTF) &&
          <div className="flex00a" style={{flexBasis:personTitleContainerWidth}}>
            <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
              {p_contactObj.title}
            </LibraryReact.Nowrap>
          </div>
        }
        {(isSearchResultTF) &&
          <div className="flex11a" style={{flexBasis:((isPersonTF) ? ("125em") : ("100em"))}}>
            <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
              {((isPersonTF) ? (p_contactObj.organizationAbbrOrLegalName) : (p_contactObj.organizationAbbrOrLegalName))}
            </LibraryReact.Nowrap>
          </div>
        }
        {(!isPersonTF && numDivisions !== undefined) &&
          <div className="flex00a displayFlexRowVc" style={{marginRight:"0.6em"}}>
            <div className="flex11a" />
            <div className="flex00a rMargin">
              {numDivisions}
            </div>
            <div key="icon" className="flex00a">
              {this.props.UserMobx.c_svgCompany10}
            </div>
          </div>
        }
        {(!isPersonTF && numPersons !== undefined) &&
          <div className="flex00a displayFlexRowVc" style={{marginRight:"0.6em"}}>
            <div className="flex11a" />
            <div className="flex00a rMargin">
              {numPersons}
            </div>
            <div key="icon" className="flex00a">
              {this.props.UserMobx.c_svgPerson10}
            </div>
          </div>
        }
        {(!isPersonTF && !isSearchResultTF) &&
          <div className="flex00a" style={{flexBasis:"2em", marginRight:"0.8em"}}>
            <CEGeneralReact.ButtonExpandCollapse
              p_isExpandedTF={isExpandedTF}
              p_sizeEm={2.2}
              f_onClick={this.onclick_expand_collapse_org}
            />
          </div>
        }
        {(c_userCanDeleteContactsTF) &&
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em"}}>
            {(showDeleteDotsTF && isValidContactTF) &&
              <CEGeneralReact.VerticalDotsMenu
                p_menuItemsArrayOfObjs={deleteContactMenuItemsArrayOfObjs}
              />
            }
          </div>
        }
      </div>
    );

    if(leftIndent === 0) {
      return(contactItemComponent);
    }

    //create left indent spacing vertical lines
    const leftIndentMargin = ((c_isMobileTF) ? ("0.7em") : ("1.5em"));
    var leftIndexComponentsArray = [];
    for(let t = 0; t < leftIndent; t++) {
      leftIndexComponentsArray.push(
        <div className="flex00a" style={{flexBasis:"0.2em", marginLeft:leftIndentMargin, borderLeft:"solid 1px #999"}} />
      );
    }

    return(
      <div className="displayFlexRow">
        {leftIndexComponentsArray}
        <div className="flex11a">
          {contactItemComponent}
        </div>
      </div>
    );
  }
}));



const SearchResultsCompaniesAndPersons = inject("ContactsMobx")(observer(
class SearchResultsCompaniesAndPersons extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray
  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;

    const o_searchText = this.props.ContactsMobx.o_searchText;
    const c_searchResultsCompaniesArrayOfObjs = this.props.ContactsMobx.c_searchResultsCompaniesArrayOfObjs;
    const c_searchResultsPersonsArrayOfObjs = this.props.ContactsMobx.c_searchResultsPersonsArrayOfObjs;

    const numCompanyResults = c_searchResultsCompaniesArrayOfObjs.length;
    const numPersonResults = c_searchResultsPersonsArrayOfObjs.length;

    if(o_searchText.length < 2) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          <div>{"Search: '" + o_searchText + "'"}</div>
          <div className="smallTopMargin">{"Search must be at least 2 characters long."}</div>
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    return(
      <div className="flex11a displayFlexColumn yScroll yScrollBottomPad lrPad">
        <div className="flex00a smallFullMargin textCenter">
          {numCompanyResults + " " + JSFUNC.plural(numCompanyResults, "Company", "Companies") + " matching '" + o_searchText + "'"}
        </div>
        {c_searchResultsCompaniesArrayOfObjs.map((m_companyObj) =>
          <ContactItem
            key={"c" + m_companyObj.id}
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_contactObj={m_companyObj}
            p_isSearchResultTF={true}
          />
        )}
        <div className="flex00a smallFullMargin textCenter">
          {numPersonResults + " " + JSFUNC.plural(numPersonResults, "Person", "Persons") + " matching '" + o_searchText + "'"}
        </div>
        {c_searchResultsPersonsArrayOfObjs.map((m_personObj) =>
          <ContactItem
            key={"p" + m_personObj.id}
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_contactObj={m_personObj}
            p_isSearchResultTF={true}
          />
        )}
      </div>
    );
  }
}));





const ContactsAdvancedSearch = inject("CaptureExecMobx", "ContactsMobx")(observer(
class ContactsAdvancedSearch extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray
  onclick_close_advanced_search = () => {
    this.props.ContactsMobx.a_initialize_contacts_system();
  }

  onselect_advanced_search_companies_or_persons_subtab = (i_newLeftSideState) => {
    this.props.ContactsMobx.a_set_left_side_state(i_newLeftSideState);
  }

  onclick_adv_search_results_download_csv_of_results_data = () => {
    this.props.ContactsMobx.a_contacts_advanced_search_download_csv_of_matching_companies_data();
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_leftSideState = this.props.ContactsMobx.o_leftSideState;
    const o_advSearchGeneratingCsvOfMatchingCompaniesDataTF = this.props.ContactsMobx.o_advSearchGeneratingCsvOfMatchingCompaniesDataTF;
    const advSearchFilteredContactCompaniesArrayOfObjs = this.props.ContactsMobx.c_advSearchFilteredContactCompaniesArrayOfObjs;

    const numFilteredCompanies = advSearchFilteredContactCompaniesArrayOfObjs.length;

    var advSearchResultsBarMenuItemsArrayOfObjs = [];
    advSearchResultsBarMenuItemsArrayOfObjs.push({
      displayName: "Download .csv of Results Data",
      functionOnClickMenuItem: this.onclick_adv_search_results_download_csv_of_results_data
    });

    return(
      <>
        <div className="flex00a displayFlexRowVc tbPad borderB1bbb">
          <div className="flex11a lrPad">
            <LibraryReact.Nowrap p_fontClass="font14 fontItalic fontTextLight">
              {"Advanced Contact Search"}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a lrPad">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text={((c_isMobileOrTabletTF) ? ("Exit") : ("Exit Advanced Search"))}
              f_onClick={this.onclick_close_advanced_search}
            />
          </div>
        </div>
        <div className="flex00a smallFullPad bgContactsLighterYellow">
          <ContactsAdvancedSearchCompanyFilters />
        </div>
        <div className="flex00a displayFlexRow borderT1bbb borderB1bbb bgContactsDarkYellow" style={{flexBasis:"3.2em"}}>
          <div className="flex00a displayFlexRowVc lrMedPad">
            <font className="font11 fontWhite">
              {numFilteredCompanies + " matching " + JSFUNC.plural(numFilteredCompanies, "Company", "Companies")}
            </font>
          </div>
          <div className="flex11a" />
          {(o_advSearchGeneratingCsvOfMatchingCompaniesDataTF) ? (
            <>
              <div className="flex00a displayFlexRowVc" style={{flexBasis:"8em"}}>
                <font className="fontItalic fontAlmostWhite">
                  {"Generating .csv"}
                </font>
              </div>
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"4em"}}>
                <CEGeneralReact.LoadingAnimation />
              </div>
            </>
          ) : (
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
              <CEGeneralReact.VerticalDotsMenu
                p_menuItemsArrayOfObjs={advSearchResultsBarMenuItemsArrayOfObjs}
                p_dotsFontClass="fontWhite"
              />
            </div>
          )}
        </div>
        <div className="flex11a displayFlexColumn yScroll" style={{flexBasis:"125em"}}>
          <ContactsAdvancedSearchFilteredContactResultsList
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
          />
        </div>
      </>
    );
  }
}));


const ContactsAdvancedSearchCompanyFilters = inject("CaptureExecMobx", "ContactsMobx", "DatabaseMobx")(observer(
class ContactsAdvancedSearchCompanyFilters extends Component { //props:
  onselect_company_business_types = (i_newValue) => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_company_filter_selected_business_type_ids_comma(i_newValue);
  }

  onselect_company_sb_certifications = (i_newValue) => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_company_filter_selected_sb_certifications_bm_set_aside_ids_comma(i_newValue);
  }

  onselect_company_capabilities = (i_newValue) => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_company_filter_selected_capability_ids_comma(i_newValue);
  }

  onselect_company_naics_codes = (i_newValue) => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_company_filter_selected_naics_code_ids_comma(i_newValue);
  }

  onselect_company_contact_company_extra_field = (i_selectedContactCompanyExtraFieldID) => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_company_filter_selected_contact_company_extra_field_id(i_selectedContactCompanyExtraFieldID);
  }

  onchange_company_contact_company_extra_field_search_text = (i_updatedSearchText) => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_company_filter_contact_company_extra_field_search_text(i_updatedSearchText);
  }

  onselect_capture_date_field = (i_newValue) => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_capture_filter_selected_date_field_id(i_newValue);
  }

  onchange_capture_earliest_date = (i_newValue) => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_capture_filter_selected_earliest_date(i_newValue);
  }

  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;

    const o_advSearchCompanyFilterSelectedBusinessTypeIDsComma = this.props.ContactsMobx.o_advSearchCompanyFilterSelectedBusinessTypeIDsComma;
    const o_advSearchCompanyFilterSelectedSBCertificationIDsComma = this.props.ContactsMobx.o_advSearchCompanyFilterSelectedSBCertificationIDsComma;
    const o_advSearchCompanyFilterSelectedCapabilityIDsComma = this.props.ContactsMobx.o_advSearchCompanyFilterSelectedCapabilityIDsComma;
    const o_advSearchCompanyFilterSelectedNaicsCodeIDsComma = this.props.ContactsMobx.o_advSearchCompanyFilterSelectedNaicsCodeIDsComma;
    const o_advSearchCompanyFilterSelectedContactCompanyExtraFieldID = this.props.ContactsMobx.o_advSearchCompanyFilterSelectedContactCompanyExtraFieldID;
    const o_advSearchCompanyFilterContactCompanyExtraFieldSearchText = this.props.ContactsMobx.o_advSearchCompanyFilterContactCompanyExtraFieldSearchText;
    const o_advSearchCaptureFilterSelectedDateFieldID = this.props.ContactsMobx.o_advSearchCaptureFilterSelectedDateFieldID;
    const o_advSearchCaptureFilterSelectedEarliestDate = this.props.ContactsMobx.o_advSearchCaptureFilterSelectedEarliestDate;

    const c_advSearchBusinessTypeFilterIsActiveTF = this.props.ContactsMobx.c_advSearchBusinessTypeFilterIsActiveTF;
    const c_advSearchSBCertificationsFilterIsActiveTF = this.props.ContactsMobx.c_advSearchSBCertificationsFilterIsActiveTF;
    const c_advSearchCapabilitiesFilterIsActiveTF = this.props.ContactsMobx.c_advSearchCapabilitiesFilterIsActiveTF;
    const c_advSearchNaicsCodesFilterIsActiveTF = this.props.ContactsMobx.c_advSearchNaicsCodesFilterIsActiveTF;
    const c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF = this.props.ContactsMobx.c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF;
    const c_advSearchCaptureDateFilterIsActiveTF = this.props.ContactsMobx.c_advSearchCaptureDateFilterIsActiveTF;

    const filterBusinessTypesComponent = (
      <ContactsAdvancedSearchFilterShell p_label="Business Types" p_filterIsActiveTF={c_advSearchBusinessTypeFilterIsActiveTF}>
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiBusinessTypesFieldTypeObj}
          p_valueRaw={o_advSearchCompanyFilterSelectedBusinessTypeIDsComma}
          f_onChangeOrOnSelect={this.onselect_company_business_types}
        />
      </ContactsAdvancedSearchFilterShell>
    );

    const filterSBCertificationsComponent = (
      <ContactsAdvancedSearchFilterShell p_label="Small Business Certifications" p_filterIsActiveTF={c_advSearchSBCertificationsFilterIsActiveTF}>
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj}
          p_valueRaw={o_advSearchCompanyFilterSelectedSBCertificationIDsComma}
          f_onChangeOrOnSelect={this.onselect_company_sb_certifications}
        />
      </ContactsAdvancedSearchFilterShell>
    );

    const filterCapabilitiesComponent = (
      <ContactsAdvancedSearchFilterShell p_label="Capabilities" p_filterIsActiveTF={c_advSearchCapabilitiesFilterIsActiveTF}>
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiCapabilitiesFieldTypeObj}
          p_valueRaw={o_advSearchCompanyFilterSelectedCapabilityIDsComma}
          f_onChangeOrOnSelect={this.onselect_company_capabilities}
        />
      </ContactsAdvancedSearchFilterShell>
    );

    const filterNaicsCodesComponent = (
      <ContactsAdvancedSearchFilterShell p_label="NAICS Codes" p_filterIsActiveTF={c_advSearchNaicsCodesFilterIsActiveTF}>
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiBitMasterNaicsCodesCombinedNamesFieldTypeObj}
          p_valueRaw={o_advSearchCompanyFilterSelectedNaicsCodeIDsComma}
          f_onChangeOrOnSelect={this.onselect_company_naics_codes}
        />
      </ContactsAdvancedSearchFilterShell>
    );

    var companyFiltersComponent = null;
    if(o_mediaQueryFlag >= 4) {
      companyFiltersComponent = (
        <>
          <div className="displayFlexRow microBottomMargin">
            <div className="flex11a lrMargin" style={{flexBasis:"100em"}}>
              {filterBusinessTypesComponent}
            </div>
            <div className="flex11a lrMargin" style={{flexBasis:"100em"}}>
              {filterCapabilitiesComponent}
            </div>
          </div>
          <div className="displayFlexRow microBottomMargin">
            <div className="flex11a lrMargin" style={{flexBasis:"100em"}}>
              {filterSBCertificationsComponent}
            </div>
            <div className="flex11a lrMargin" style={{flexBasis:"100em"}}>
              {filterNaicsCodesComponent}
            </div>
          </div>
        </>
      );
    }
    else {
      companyFiltersComponent = (
        <div className="lrMargin">
          {filterBusinessTypesComponent}
          {filterSBCertificationsComponent}
          {filterCapabilitiesComponent}
          {filterNaicsCodesComponent}
        </div>
      );
    }

    return(
      <>
        <div className="microBottomMargin">
          <font className="font12 fontTextLighter">
            {"Filters for Contact Companies"}
          </font>
        </div>
        {companyFiltersComponent}
        <div className="lrMargin">
          <ContactsAdvancedSearchFilterShell p_label="Custom Field Text Search" p_filterIsActiveTF={c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF}>
            <div className="flex11a displayFlexRowVc">
              <div className="flex11a" style={{flexBasis:"125em"}}>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectContactCompanyExtraFieldFieldTypeObj}
                  p_valueRaw={o_advSearchCompanyFilterSelectedContactCompanyExtraFieldID}
                  f_onChangeOrOnSelect={this.onselect_company_contact_company_extra_field}
                />
              </div>
              <div className="flex00a" style={{flexBasis:"3.5em", marginLeft:"1em"}}>
                <font className="fontItalic fontTextLighter">
                  {"Search:"}
                </font>
              </div>
              <div className="flex11a lrMargin" style={{flexBasis:"100em"}}>
                <LibraryReact.Text
                  p_value={o_advSearchCompanyFilterContactCompanyExtraFieldSearchText}
                  p_class="borderRadius10 lrMedPad"
                  p_styleObj={{width:"100%"}}
                  f_onChange={this.onchange_company_contact_company_extra_field_search_text}
                />
              </div>
            </div>
          </ContactsAdvancedSearchFilterShell>
        </div>
        <div className="bigTopMargin smallBottomMargin">
          <font className="font12 fontTextLighter">
            {"Filter Captures by Date Field"}
          </font>
        </div>
        <ContactsAdvancedSearchFilterShell p_filterIsActiveTF={c_advSearchCaptureDateFilterIsActiveTF}>
          <div className="displayFlexRow">
            <div className="flex11a">
              <div className="microBottomMargin">
                <font className="fontItalic fontTextLighter">
                  {"Capture Date Field:"}
                </font>
              </div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureDateFieldFieldTypeObj}
                p_valueRaw={o_advSearchCaptureFilterSelectedDateFieldID}
                f_onChangeOrOnSelect={this.onselect_capture_date_field}
              />
            </div>
            <div className="flex00a lrMedPad">
              <div className="microBottomMargin">
                <font className="fontItalic fontTextLighter">
                  {"Earliest Date to Include:"}
                </font>
              </div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
                p_valueRaw={o_advSearchCaptureFilterSelectedEarliestDate}
                f_onChangeOrOnSelect={this.onchange_capture_earliest_date}
              />
            </div>
          </div>
        </ContactsAdvancedSearchFilterShell>
      </>
    );
  }
}));

function ContactsAdvancedSearchFilterShell(props) { //props: p_label, p_filterIsActiveTF, children
  const label = props.p_label;
  const filterIsActiveTF = props.p_filterIsActiveTF;

  return(
    <div className="displayFlexRow bgLightestGray border1bbb">
      <div className={"flex00a borderR1bbb " + ((filterIsActiveTF) ? ("bgBlueGradient") : ("bgLightGrayGradient"))} style={{flexBasis:"1em"}} />
      {(label !== undefined) &&
        <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:"8em"}}>
          <font className="fontItalic fontTextLighter">
            {label}
          </font>
        </div>
      }
      <div className="flex11a displayFlexColumnVc tbPad lrPad">
        {props.children}
      </div>
    </div>
  );
}


const ContactsAdvancedSearchFilteredContactResultsList = inject("ContactsMobx")(observer(
class ContactsAdvancedSearchFilteredContactResultsList extends Component { //props: p_selectMode
  onclick_show_all_adv_search_results = () => {
    this.props.ContactsMobx.a_contacts_advanced_search_set_num_results_drawn_to_unlimited();
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;

    const advSearchNumResultsDrawnLimit = this.props.ContactsMobx.o_advSearchNumResultsDrawnLimit;
    const advSearchAnyFilterIsActiveTF = this.props.ContactsMobx.c_advSearchAnyFilterIsActiveTF;
    const advSearchFilteredContactCompaniesArrayOfObjs = this.props.ContactsMobx.c_advSearchFilteredContactCompaniesArrayOfObjs;

    if(!advSearchAnyFilterIsActiveTF) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"Use the Filters above to view matching Contact search results here"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    const numResults = advSearchFilteredContactCompaniesArrayOfObjs.length;

    return(
      <>
        {advSearchFilteredContactCompaniesArrayOfObjs.map((m_advSearchFilteredContactObj, m_index) =>
          (m_index < advSearchNumResultsDrawnLimit) &&
          <ContactsAdvancedSearchResultContactItem
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_contactObj={m_advSearchFilteredContactObj}
            p_isSearchResultTF={true}
          />
        )}
        {(numResults > advSearchNumResultsDrawnLimit) &&
          <div className="displayFlexRow tbMedPad bgContactsDarkYellow">
            <div className="flex00a lrPad">
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text={"Show all " + numResults + " Contact Companies"}
                f_onClick={this.onclick_show_all_adv_search_results}
              />
            </div>
            <div className="flex11a lrPad" />
          </div>
        }
      </>
    );
  }
}));

const ContactsAdvancedSearchResultContactItem = inject("ContactsMobx", "UserMobx")(observer(
class ContactsAdvancedSearchResultContactItem extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray, p_contactObj
  onclick_contact_item = () => {
    this.props.ContactsMobx.a_open_contact(this.props.p_contactObj.isPersonTF, this.props.p_contactObj.id);
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;
    const p_contactObj = this.props.p_contactObj;

    const c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined = this.props.ContactsMobx.c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined;

    const isValidContactTF = (p_contactObj.id > 0);
    const isPersonTF = p_contactObj.isPersonTF;
    const contactName = this.props.ContactsMobx.contact_name_plaintext_from_contact_obj(p_contactObj);
    const isTopCompanyTF = this.props.ContactsMobx.contact_is_top_org_tf(p_contactObj);
    const selectedContactCompanyExtraFieldIsValidTF = (c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined !== undefined);

    var selectedContactCompanyExtraFieldDbName = "";
    var selectedContactCompanyExtraFieldDisplayName = "";
    if(selectedContactCompanyExtraFieldIsValidTF) {
      selectedContactCompanyExtraFieldDbName = c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined.get("db_name");
      selectedContactCompanyExtraFieldDisplayName = c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined.get("display_name");
    }

    var contactNameFontClass = "";
    if(isTopCompanyTF) { //style changes if the contact is a top org
      contactNameFontClass = "font12 " + ((!isValidContactTF) ? ("fontItalic") : ("fontBold"));
    }

    return(
      <div
        className={"displayFlexColumnVc lrMedPad bgContactsYellow " + ((isValidContactTF) ? ("hoverLighterBlueGradient cursorPointer") : (""))}
        style={{height:"6.2em", borderBottom:"solid 1px #ccb"}}
        title={"View/Edit info for  '" + contactName + "'"}
        onClick={((isValidContactTF) ? (this.onclick_contact_item) : (undefined))}>
        <div className="displayFlexRowVc">
          <SelectContactCheckBox
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_contactObj={p_contactObj}
            p_sizeEm={1.2}
            p_styleObj={{marginRight:"0.8em"}}
          />
          <div className="flex00a rMargin">
            {((isTopCompanyTF) ? (this.props.UserMobx.c_svgCompany20) : (this.props.UserMobx.c_svgCompany15))}
          </div>
          <div className="flex11a" style={{flexBasis:"150em"}}>
            <LibraryReact.Nowrap p_fontClass={contactNameFontClass}>
              {contactName}
            </LibraryReact.Nowrap>
          </div>
          {(!p_contactObj.isTopLevelCompanyTF) &&
            <div className="flex11a" style={{flexBasis:((isPersonTF) ? ("125em") : ("100em"))}}>
              <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                {p_contactObj.organizationAbbrOrLegalName}
              </LibraryReact.Nowrap>
            </div>
          }
          {(!isPersonTF && selectedContactCompanyExtraFieldIsValidTF) &&
            <div className="flex00a displayFlexColumn lrPad" style={{flexBasis:"11em"}}>
              <div className="flex00a">
                <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                  {selectedContactCompanyExtraFieldDisplayName + ":"}
                </LibraryReact.Nowrap>
              </div>
              <div className="flex00a">
                <LibraryReact.Nowrap p_fontClass="fontTextLight">
                  {p_contactObj[selectedContactCompanyExtraFieldDbName]}
                </LibraryReact.Nowrap>
              </div>
            </div>
          }
        </div>
        <div className="displayFlexRowVc microTopMargin" style={{height:"1.6em"}}>
          <div className="flex00a lrMedPad" style={{flexBasis:"12em"}}>
            <font className="fontItalic fontTextLight">
              {"We are the Prime:"}
            </font>
          </div>
          <ContactsAdvancedSearchResultWinLossCount p_teamingCategory="theirAsOurWins" p_label="Wins" p_count={p_contactObj.numWonCapturesWePrimeTheySub} p_captureIDsArray={p_contactObj.wonCaptureIDsWePrimeTheySubArray} p_contactCompanyID={p_contactObj.id} />
          <ContactsAdvancedSearchResultWinLossCount p_teamingCategory="theirAsOurActive" p_label="Active" p_count={p_contactObj.numActiveCapturesWePrimeTheySub} p_captureIDsArray={p_contactObj.activeCaptureIDsWePrimeTheySubArray} p_contactCompanyID={p_contactObj.id} />
          <ContactsAdvancedSearchResultWinLossCount p_teamingCategory="theirAsOurLosses" p_label="Losses" p_count={p_contactObj.numLostCapturesWePrimeTheySub} p_captureIDsArray={p_contactObj.lostCaptureIDsWePrimeTheySubArray} p_contactCompanyID={p_contactObj.id} />
        </div>
        <div className="displayFlexRowVc microTopMargin" style={{height:"1.6em"}}>
          <div className="flex00a lrMedPad" style={{flexBasis:"12em"}}>
            <font className="fontItalic fontTextLight">
              {"We are a Sub:"}
            </font>
          </div>
          <ContactsAdvancedSearchResultWinLossCount p_teamingCategory="usAsTheirWins" p_label="Wins" p_count={p_contactObj.numWonCapturesTheyPrimeWeSub} p_captureIDsArray={p_contactObj.wonCaptureIDsTheyPrimeWeSubArray} p_contactCompanyID={p_contactObj.id} />
          <ContactsAdvancedSearchResultWinLossCount p_teamingCategory="usAsTheirActive" p_label="Active" p_count={p_contactObj.numActiveCapturesTheyPrimeWeSub} p_captureIDsArray={p_contactObj.activeCaptureIDsTheyPrimeWeSubArray} p_contactCompanyID={p_contactObj.id} />
          <ContactsAdvancedSearchResultWinLossCount p_teamingCategory="usAsTheirLosses" p_label="Losses" p_count={p_contactObj.numLostCapturesTheyPrimeWeSub} p_captureIDsArray={p_contactObj.lostCaptureIDsTheyPrimeWeSubArray} p_contactCompanyID={p_contactObj.id} />
        </div>
      </div>
    );
  }
}));

const ContactsAdvancedSearchResultWinLossCount = inject("ContactsMobx")(observer(
class ContactsAdvancedSearchResultWinLossCount extends Component { //props: p_teamingCategory, p_label, p_count, p_captureIDsArray, p_contactCompanyID
  onclick_view_captures_in_search_results_box = () => {
    const teamingCategory = this.props.p_teamingCategory;
    const captureIDsArray = this.props.p_captureIDsArray;
    const contactCompanyID = this.props.p_contactCompanyID;
    this.props.ContactsMobx.a_contacts_advanced_search_open_captures_analysis(contactCompanyID, teamingCategory, captureIDsArray);
  }

  render() {
    const label = this.props.p_label;
    const count = this.props.p_count;
    const captureIDsArray = this.props.p_captureIDsArray;
    const contactCompanyID = this.props.p_contactCompanyID;

    return(
      <div className="flex11a displayFlexRowVc borderL1ddd" style={{flexBasis:"100em"}}>
        <div className="flex00a lrPad">
          <font className="fontItalic fontTextLight">
            {label}
          </font>
        </div>
        <div className="flex00a lrPad">
          {(count > 0) ? (
            <LibraryReact.ButtonNowrap
              p_value={count}
              p_class="tbMicroPad lrMedPad border bevelBorderColors borderRadius10 bgBlueGradient hoverLightBlueGradient cursorPointer"
              p_fontClass="fontBold fontWhite"
              p_title="Click to View Capture(s)"
              f_onClick={this.onclick_view_captures_in_search_results_box}
            />
          ) : (
            <font className="fontItalic fontTextLighter">
              {count}
            </font>
          )}
        </div>
        <div className="flex11a displayFlexRowVc" />
      </div>
    );
  }
}));











//-----------------------------------------------------------------------------------------------
//Right Side Sub Components
//-----------------------------------------------------------------------------------------------
const SingleContactViewEdit = inject("CaptureExecMobx", "ContactsMobx")(observer(
class SingleContactViewEdit extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray
  onclick_close_contact = () => {
    this.props.ContactsMobx.a_close_contact();
  }

  onselect_contact_info_subtab = (i_selectedTabDbName) => {
    this.props.ContactsMobx.a_set_open_contact_view_edit_single_contact_selected_tab_db_name(i_selectedTabDbName);
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;

    const openContactID = this.props.ContactsMobx.o_openContactID;
    const o_rightSideState = this.props.ContactsMobx.o_rightSideState;
    const openContactLoadingDocsTF = this.props.ContactsMobx.o_openContactLoadingDocsTF;
    const openContactViewEditSingleContactSelectedTabDbName = this.props.ContactsMobx.o_openContactViewEditSingleContactSelectedTabDbName;
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;
    const openContactName = this.props.ContactsMobx.c_openContactName;
    const openContactCompanyFileFolderSystemMapOfMaps = this.props.ContactsMobx.c_openContactCompanyFileFolderSystemMapOfMaps;
    const openContactCompanyFileFolderSystemNumFiles = this.props.ContactsMobx.c_openContactCompanyFileFolderSystemNumFiles;
    const openContactPersonFileFolderSystemMapOfMaps = this.props.ContactsMobx.c_openContactPersonFileFolderSystemMapOfMaps;
    const openContactPersonFileFolderSystemNumFiles = this.props.ContactsMobx.c_openContactPersonFileFolderSystemNumFiles;

    const isPersonTF = c_openContactObj.isPersonTF;

    const openContactFileFolderSystemMapOfMaps = ((isPersonTF) ? (openContactPersonFileFolderSystemMapOfMaps) : (openContactCompanyFileFolderSystemMapOfMaps));
    const openContactFileFolderSystemNumFiles = ((isPersonTF) ? (openContactPersonFileFolderSystemNumFiles) : (openContactCompanyFileFolderSystemNumFiles));

    const contactsFfsTblName = ((isPersonTF) ? ("tbl_g_contacts_persons_filefoldersystem") : ("tbl_g_contacts_companies_filefoldersystem"));
    const contactsFfsTblContactIDColumnName = ((isPersonTF) ? ("contact_person_id") : ("contact_company_id"));
    const companyPersonString = ((isPersonTF) ? ("Person") : ("Company"));

    const contactNameAndCloseButtonTopBarComponent = (
      <div className="flex00a smallFullPad" style={{borderBottom:"solid 1px #ccc"}}>
        <div className="displayFlexRowVc">
          <SelectContactCheckBox
            p_selectMode={p_selectMode}
            p_contactIDsAlreadyAddedArray={p_contactIDsAlreadyAddedArray}
            p_contactObj={c_openContactObj}
            p_sizeEm={1.6}
            p_styleObj={{marginLeft:"0.3em", marginRight:"0.8em"}}
          />
          <div className="flex00a rMargin">
            <CompanyOrPersonIcon p_isPersonTF={isPersonTF} p_sizeEm={3} />
          </div>
          {(!isPersonTF) &&
            <div className="flex00a rMargin">
              <CEGeneralReact.RatingScoreBox1to5 p_rating={c_openContactObj.tr_total} p_sizeEm={2.8} />
            </div>
          }
          <div className="flex11a rMargin">
            <font className="font15">
              {openContactName}
            </font>
          </div>
          <div className="flex00a lrMargin">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text="Close"
              f_onClick={this.onclick_close_contact}
            />
          </div>
        </div>
      </div>
    );

    var rightSideContentComponent = null;
    if(o_rightSideState === "advancedSearchCapturesAnalysis") {
      rightSideContentComponent = (
        <div className="flex11a lrPad yScroll yScrollBottomPad">
          <AdvancedSearchCapturesAnalysis p_selectMode={p_selectMode} />
        </div>
      );
    }
    else {
      var contactInfoSubtabDbNamesArray = ["contactInfo"];
      var contactInfoSubtabDisplayNamesArray = ["Contact Info"];
      if(!isPersonTF) {
        var teammateRatingTabDisplayName = "Teammate Rating";
        if(JSFUNC.is_number_not_nan(c_openContactObj.tr_num_reviews)) {
          teammateRatingTabDisplayName += " (" + c_openContactObj.tr_num_reviews + ")";
        }
        contactInfoSubtabDbNamesArray.push("teammateRating");
        contactInfoSubtabDisplayNamesArray.push(teammateRatingTabDisplayName);
      }
      contactInfoSubtabDbNamesArray.push("viewDocs");
      contactInfoSubtabDisplayNamesArray.push((openContactLoadingDocsTF) ? ("Loading Docs...") : ("View Docs (" + openContactFileFolderSystemNumFiles + ")"));

      const contactInfoSubtabsComponent = (
        <div className="flex00a smallFullPad">
          <CEGeneralReact.TabsList
            p_tabDbNamesArray={contactInfoSubtabDbNamesArray}
            p_tabDisplayNamesArray={contactInfoSubtabDisplayNamesArray}
            p_selectedTabDbName={openContactViewEditSingleContactSelectedTabDbName}
            p_tabHeight="1.8em"
            p_textMaxHeight="1.8em"
            p_tabWidth={undefined}
            p_selectedBgClass="bgGoldGradient"
            p_selectedFontClass=""
            p_unselectedBgClass="bgLighterGrayGradient"
            p_unselectedFontClass="fontTextLighter"
            p_canEditTF={true}
            p_rowFlexWrapTF={false}
            f_onSelect={this.onselect_contact_info_subtab}
          />
        </div>
      );

      if(openContactViewEditSingleContactSelectedTabDbName === "contactInfo") {
        rightSideContentComponent = (
          <>
            {contactInfoSubtabsComponent}
            <div className="flex11a lrPad yScroll yScrollBottomPad">
              <ContactFieldsListEditSaveCancel />
              <div className="hugeBottomMargin" />
              <EditParentCompanyDivision p_contactObj={c_openContactObj} />
              <div className="hugeBottomMargin" />
            </div>
          </>
        );
      }
      else if(openContactViewEditSingleContactSelectedTabDbName === "teammateRating") {
        rightSideContentComponent = (
          <>
            {contactInfoSubtabsComponent}
            <div className="flex11a lrPad yScroll yScrollBottomPad">
              <ContactCompanyTeammatesRatings />
              <div className="hugeBottomMargin" />
            </div>
          </>
        );
      }
      else if(openContactViewEditSingleContactSelectedTabDbName === "viewDocs") {
        rightSideContentComponent = (
          <>
            {contactInfoSubtabsComponent}
            <CEGeneralReact.FileFolderSystem
              p_tblName={contactsFfsTblName}
              p_tblMapOfMaps={openContactFileFolderSystemMapOfMaps}
              p_tblFilterValuesObj={{[contactsFfsTblContactIDColumnName]:openContactID}}
              p_title={undefined}
              p_defaultXmlType={undefined}
              p_allowedExtsArray={undefined}
              p_disableUploadFilesTF={false}
              p_disableCreateOnlineLinkDocsTF={false}
              p_disableCreateFoldersTF={false}
              p_viewWideTrueNarrowFalse={false}
              p_navigateToFolderID={this.props.CaptureExecMobx.o_navigateToFolderIDTblGContactsFileFolderSystem}
              f_onPerformFFSAction={undefined}
              f_onNavigateToFolder={this.props.CaptureExecMobx.a_set_navigate_to_folder_id_tbl_g_contacts_filefoldersystem}
            />
          </>
        );
      }
    }

    return(
      <div className="flex11a displayFlexColumn bgContactsLighterYellow">
        {contactNameAndCloseButtonTopBarComponent}
        {rightSideContentComponent}
      </div>
    );
  }
}));

const ContactFieldsListEditSaveCancel = inject("ContactsMobx")(observer(
class ContactFieldsListEditSaveCancel extends Component { //props:
  render() {
    return(
      <>
        <div className="microBottomMargin">
          <font className="font12 fontBold fontTextLight">
            {"Details"}
          </font>
        </div>
        {this.props.ContactsMobx.c_openContactFieldsArrayOfObjs.map((m_contactFieldObj) =>
          (!JSFUNC.in_array(m_contactFieldObj.db_name, ["contact_company_id", "org_topdiv_id", "tree_id"])) &&
          <ContactFieldEditSaveCancel
            key={m_contactFieldObj.id}
            p_contactFieldObj={m_contactFieldObj}
          />
        )}
      </>
    );
  }
}));

const ContactFieldEditSaveCancel = inject("CaptureExecMobx", "ContactsMobx", "DatabaseMobx", "UserMobx")(observer(
class ContactFieldEditSaveCancel extends Component { //props: p_contactFieldObj
  onsave_changed_field_value = (i_newValue) => {
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;
    const p_contactFieldObj = this.props.p_contactFieldObj;
    this.props.ContactsMobx.a_update_contact_field(c_openContactObj.isPersonTF, c_openContactObj.id, p_contactFieldObj.db_name, i_newValue, p_contactFieldObj.fieldTypeObj.idsb);
  }

  render() {
    const p_contactFieldObj = this.props.p_contactFieldObj; //expanded field obj of hardcoded and custom contact fields mixed together

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;
    const c_userCanEditContactsTF = this.props.UserMobx.c_userCanEditContactsTF;

    const isPersonTF = c_openContactObj.isPersonTF;
    const fieldDbName = p_contactFieldObj.db_name;
    const fieldDisplayName = p_contactFieldObj.display_name;
    const fieldTypeObj = p_contactFieldObj.fieldTypeObj;

    var valueRaw = c_openContactObj[fieldDbName];

    var valueMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(valueRaw, fieldTypeObj);
    var valueIsEditableTFU = true;
    if(!c_openContactObj.existsTF || !c_userCanEditContactsTF) { //cannot edit any fields if the contact does not exist or this user cannot edit the contacts
      valueIsEditableTFU = false;
    }
    else if(!isPersonTF && (fieldDbName === "sb_certifications_bm_set_aside_ids_comma") && !c_openContactObj.businessTypeIsSmallTF) { //if this is a large business, mask the sb certs to read N/A and be uneditable
      valueMask = this.props.DatabaseMobx.not_filled_out_font_html("N/A");
      valueIsEditableTFU = false;
    }

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={"contactField" + p_contactFieldObj.id}
        p_isContactFieldTF={true}
        p_fieldDisplayName={fieldDisplayName}
        p_fieldTypeObj={fieldTypeObj}
        p_valueRaw={valueRaw}
        p_valueMask={valueMask}
        p_valueIsEditableTFU={valueIsEditableTFU}
        p_containerClass="microTopMargin microBottomMargin"
        p_fieldClass="fontBlue"
        p_fieldWidth={((c_isMobileTF) ? ("30%") : ("14em"))}
        p_fieldNowrapTF={false}
        p_fieldValueVerticallyAlignedTF={false}
        p_floatingBoxTitle={"Editing Contact " + fieldDisplayName}
        f_onSaveChanged={this.onsave_changed_field_value}
      />
    );
  }
}));

const EditParentCompanyDivision = inject("ContactsMobx", "UserMobx")(observer(
class EditParentCompanyDivision extends Component { //props: p_contactObj
  onclick_edit_parent_division = () => {
    this.props.ContactsMobx.a_set_left_side_state("selectParentDivision");
  }

  onclick_cancel_selection = () => {
    this.props.ContactsMobx.a_set_left_side_state("treeWithSearch");
  }

  render() {
    const contactObjCopy = JSFUNC.copy_obj(this.props.p_contactObj);
    const openContactName = this.props.ContactsMobx.contact_name_plaintext_from_contact_obj(contactObjCopy);

    const o_leftSideState = this.props.ContactsMobx.o_leftSideState;
    const c_userCanEditContactsTF = this.props.UserMobx.c_userCanEditContactsTF;

    const isSelectingParentDivisionTF = (o_leftSideState === "selectParentDivision");

    const isPersonTF = contactObjCopy.isPersonTF;
    const parentCompanyIDFieldDbName = this.props.ContactsMobx.parent_company_field_db_name(isPersonTF);
    var parentCompanyID = contactObjCopy[parentCompanyIDFieldDbName];

    var parentCompanyName = undefined;
    var parentCompanyNameFontClasses = undefined;
    if(isSelectingParentDivisionTF) {
      parentCompanyName = "--Select a 'Parent' button on the left to set a new Parent Company/Division for " + openContactName + "--";
      parentCompanyNameFontClasses = "fontItalic fontTextLighter";
    }
    else if(!JSFUNC.select_int_is_filled_out_tf(parentCompanyID)) {
      if(isPersonTF) {
        parentCompanyName = "--Person Not Assigned to a Company/Division--";
      }
      else {
        parentCompanyName = "--This is a Top Level Company--";
      }
      parentCompanyNameFontClasses = "fontItalic fontTextLighter";
    }
    else {
      if(!isPersonTF) {
        parentCompanyID = this.props.ContactsMobx.parent_company_id_from_company_obj(contactObjCopy);
      }
      const parentCompanyObj = this.props.ContactsMobx.contact_company_or_person_obj_from_id(false, parentCompanyID);
      parentCompanyName = this.props.ContactsMobx.contact_name_plaintext_from_contact_obj(parentCompanyObj);
    }

    var editCancelSelectionButtonsComponent = null;
    if(contactObjCopy.existsTF && c_userCanEditContactsTF) {
      if(isSelectingParentDivisionTF) {
        editCancelSelectionButtonsComponent = (
          <div className="flex00a">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Cancel Selection"
              p_tabIndex={-1}
              f_onClick={this.onclick_cancel_selection}
            />
          </div>
        );
      }
      else {
        editCancelSelectionButtonsComponent = (
          <CEGeneralReact.EditSaveCancelIcon
            p_iconType="edit"
            f_onClick={this.onclick_edit_parent_division}
          />
        );
      }
    }

    return(
      <>
        <div className="displayFlexRowVc microBottomMargin">
          <div className="flex00a rMargin">
            <font className="font12 fontBold fontTextLight">
              {"Parent Company/Division"}
            </font>
          </div>
          {editCancelSelectionButtonsComponent}
          <div className="flex11a" />
        </div>
        <div>
          <font className={parentCompanyNameFontClasses}>
            {parentCompanyName}
          </font>
        </div>
      </>
    );
  }
}));


const ContactCompanyTeammatesRatings = inject("ContactsMobx", "DatabaseMobx")(observer(
class ContactCompanyTeammatesRatings extends Component { //props:
  onclick_view_teammate_rating_captures = () => {
    this.props.ContactsMobx.a_set_open_contact_teammates_ratings_captures_floating_box_open_tf(true);
  }

  render() {
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;
    const openContactTeammatesRatingsCapturesFloatingBoxOpenTF = this.props.ContactsMobx.o_openContactTeammatesRatingsCapturesFloatingBoxOpenTF;
    const openContactCompanyTeammateCaptureIDsArray = this.props.ContactsMobx.c_openContactCompanyTeammateCaptureIDsArray;
    const c_teammatesRatingsQuestionsArrayOfObjs = this.props.DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    if(c_openContactObj.isPersonTF) {
      return(null);
    }

    const numTeammateCaptures = openContactCompanyTeammateCaptureIDsArray.length;

    const numTeammateCapturesText = numTeammateCaptures + " " + JSFUNC.plural(numTeammateCaptures, "Capture", "Captures");
    const numReviewsTimesText = c_openContactObj.tr_num_reviews + " " + JSFUNC.plural(c_openContactObj.tr_num_reviews, "time", "times");

    return(
      <>
        <div className="">
          <font className="font12 fontBold fontTextLight">
            {"Teammate Rating"}
          </font>
        </div>
        <div className="displayFlexColumnHcVc tbMedPad lrMedPad">
          <div className="flex00a microBottomMargin">
            <font className="fontItalic fontTextLight">
              {"'" + c_openContactObj.abbrOrLegalName + "' is a Teammate on " + numTeammateCapturesText + " and has been reviewed " + numReviewsTimesText}
            </font>
          </div>
          <div className="flex00a">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="View Captures as Teammate"
              p_title={"Click to view Captures '" + c_openContactObj.abbrOrLegalName + "' has been a Teammate on and the ratings given"}
              f_onClick={this.onclick_view_teammate_rating_captures}
            />
          </div>
        </div>
        <div className="" style={{maxWidth:"30em"}}>
          <ContactCompanyTeammateRatingItem p_label="Overall Rating" p_rating={c_openContactObj.tr_total} p_isOverallTF={true} />
          <div className="smallTopMargin" />
          {c_teammatesRatingsQuestionsArrayOfObjs.map((m_teammatesRatingsQuestionObj) =>
            <ContactCompanyTeammateRatingItem p_label={m_teammatesRatingsQuestionObj.name} p_rating={c_openContactObj["tr_q" + m_teammatesRatingsQuestionObj.id]} />
          )}
        </div>
        {(openContactTeammatesRatingsCapturesFloatingBoxOpenTF) &&
          <ContactCompanyTeammatesRatingsCapturesFloatingBox />
        }
      </>
    );
  }
}));

function ContactCompanyTeammateRatingItem(props) { //props: p_label, p_rating, p_isOverallTF
  const label = props.p_label;
  const rating = props.p_rating;
  const isOverallTF = JSFUNC.prop_value(props.p_isOverallTF, false);

  return(
    <div className="displayFlexRowVc tbMicroPad hoverLighterBlueGradient">
      {(!isOverallTF) &&
        <div className="flex00a" style={{flexBasis:"1em"}} />
      }
      <div className="flex11a lrPad" title={label}>
        <font className={((isOverallTF) ? ("font13") : ("fontItalic"))}>
          {label}
        </font>
      </div>
      <div className="flex00a lrMedPad">
        <font className={((isOverallTF) ? ("font13 fontBold fontBlue") : ("fontItalic"))}>
          <CEGeneralReact.RatingNA1To5OutOf5Display p_rating1to5NAm1={rating} />
        </font>
      </div>
    </div>
  );
}

const ContactCompanyTeammatesRatingsCapturesFloatingBox = inject("ContactsMobx")(observer(
class ContactCompanyTeammatesRatingsCapturesFloatingBox extends Component { //props:
  onclick_close_teammate_rating_captures = () => {
    this.props.ContactsMobx.a_set_open_contact_teammates_ratings_captures_floating_box_open_tf(false);
  }

  render() {
    const o_openContactTeammatesRatingsLoadingSubmissionsTF = this.props.ContactsMobx.o_openContactTeammatesRatingsLoadingSubmissionsTF;
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;
    const c_openContactCompanyTeammateArchivedCaptureIDsArray = this.props.ContactsMobx.c_openContactCompanyTeammateArchivedCaptureIDsArray;
    const c_openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs = this.props.ContactsMobx.c_openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs;
    const c_openContactCompanyTeammatesRatingsCapturesNotReviewedArrayOfObjs = this.props.ContactsMobx.c_openContactCompanyTeammatesRatingsCapturesNotReviewedArrayOfObjs;

    const numTeammateOnArchivedCaptures = c_openContactCompanyTeammateArchivedCaptureIDsArray.length;
    const numCapturesReviewed = c_openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs.length;
    const numCapturesNotYetReviewed = c_openContactCompanyTeammatesRatingsCapturesNotReviewedArrayOfObjs.length;

    if(c_openContactObj === undefined) {
      return(null);
    }

    var insideFloatingBoxComponent = null;
    if(o_openContactTeammatesRatingsLoadingSubmissionsTF) {
      insideFloatingBoxComponent = (
        <CEGeneralReact.EmptyScreenGrayWithLoadingAnimation>
          {"Loading Teammate Reviews"}
        </CEGeneralReact.EmptyScreenGrayWithLoadingAnimation>
      );
    }
    else if((numCapturesReviewed === 0) && (numCapturesNotYetReviewed === 0)) { //if this contact company has never been a teammate on any capture
      insideFloatingBoxComponent = (
        <CEGeneralReact.EmptyScreenWhite>
          {"'" + c_openContactObj.abbrOrLegalName + "' has not been added as a Teammate to any Captures yet"}
        </CEGeneralReact.EmptyScreenWhite>
      );
    }
    else {
      insideFloatingBoxComponent = (
        <div className="flex11a yScroll medFullPad">
          {(numCapturesReviewed > 0) &&
            <>
              <div className="smallBottomMargin">
                <font className="font14 fontBold fontTextLighter">
                  {"Reviewed as Teammate (" + numCapturesReviewed + ")"}
                </font>
              </div>
              {c_openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs.map((m_teammatesRatingsCaptureObj) =>
                <ContactCompanyTeammatesRatingsSingleCapture p_teammatesRatingsCaptureObj={m_teammatesRatingsCaptureObj} />
              )}
              <div className="hugeBottomMargin" />
            </>
          }
          {(numCapturesNotYetReviewed > 0) &&
            <>
              <div className="smallBottomMargin">
                <font className="font14 fontBold fontTextLighter">
                  {"Not Yet Reviewed as Teammate (" + numCapturesNotYetReviewed + ")"}
                </font>
              </div>
              {c_openContactCompanyTeammatesRatingsCapturesNotReviewedArrayOfObjs.map((m_teammatesRatingsCaptureObj) =>
                <ContactCompanyTeammatesRatingsSingleCapture p_teammatesRatingsCaptureObj={m_teammatesRatingsCaptureObj} />
              )}
              <div className="hugeBottomMargin" />
            </>
          }
          {(numTeammateOnArchivedCaptures > 0) &&
            <div className="">
              <font className="font12 fontItalic fontTextLight">
                {"'" + c_openContactObj.abbrOrLegalName + "' has also been a Teammate on " + numTeammateOnArchivedCaptures + " " + JSFUNC.plural(numTeammateOnArchivedCaptures, "Capture", "Captures") + " that are archived and currently not loaded in the system"}
              </font>
            </div>
          }
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="medium"
        p_zIndex={2}
        p_title={"Captures '" + c_openContactObj.abbrOrLegalName + "' has been a Teammate on"}
        f_onClickCancel={this.onclick_close_teammate_rating_captures}>
        {insideFloatingBoxComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const ContactCompanyTeammatesRatingsSingleCapture = inject("CaptureExecMobx", "ContactsMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class ContactCompanyTeammatesRatingsSingleCapture extends Component { //props: p_teammatesRatingsCaptureObj
  onclick_teammate_rating_capture_open_capture = () => {
    const p_teammatesRatingsCaptureObj = this.props.p_teammatesRatingsCaptureObj;
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;

    const functionOnSuccess = (i_parseResponse) => {
      this.props.OpenCaptureMobx.a_launch_card_full(this.props.DatabaseMobx.k_cardIDTeammates);

      //get teammate_id finding first capture teammate record using openContactID and capture_id
      const teammateMap = JSFUNC.get_first_map_matching_field_value(this.props.DatabaseMobx.o_tbl_c_teammates, ["capture_id", "contact_company_id"], [p_teammatesRatingsCaptureObj.captureID, c_openContactObj.id]);
      if(teammateMap !== undefined) {
        this.props.OpenCaptureMobx.a_teammates_expand_collapse_teammate(teammateMap.get("id"));
      }
    }
    this.props.OpenCaptureMobx.a_open_single_capture(p_teammatesRatingsCaptureObj.captureID, functionOnSuccess);
  }

  render() {
    const p_teammatesRatingsCaptureObj = this.props.p_teammatesRatingsCaptureObj;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_teammatesRatingsQuestionsArrayOfObjs = this.props.DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    if(!p_teammatesRatingsCaptureObj.reviewedTF) {
      return(
        <div
          className="tbMicroPad lrPad microBottomMargin bgLightesterGray border bevelBorderColors borderRadius10 cursorPointer"
          title={"Click to open Capture '" + p_teammatesRatingsCaptureObj.captureNamePlainText + "'"}
          onClick={this.onclick_teammate_rating_capture_open_capture}>
          <font className="">
            {p_teammatesRatingsCaptureObj.captureNamePlainText}
          </font>
        </div>
      );
    }

    return(
      <div
        className={"medBottomMargin smallFullPad " + c_productStylingObj.openCaptureTopBarBgClass + " border1bbb cursorPointer"}
        title={"Click to open Capture '" + p_teammatesRatingsCaptureObj.captureNamePlainText + "'"}
        onClick={this.onclick_teammate_rating_capture_open_capture}>
        <div className="smallBottomMargin">
          <font className="font11 fontWhite">
            {p_teammatesRatingsCaptureObj.captureNamePlainText}
          </font>
        </div>
        <div className="tbPad lrMedPad bgLightesterGray">
          <div className="smallBottomMargin">
            <font className="fontTextLight">
              {"Reviewed on "}
              {p_teammatesRatingsCaptureObj.reviewDate}
              {" by "}
              {p_teammatesRatingsCaptureObj.reviewedByUserNameMask}
            </font>
          </div>
          {c_teammatesRatingsQuestionsArrayOfObjs.map((m_teammatesRatingsQuestionObj) =>
            <div className="smallBottomMargin smallFullPad bgLighterGray border1ddd borderRadius05">
              <div className="displayFlexRow">
                <div className="flex11a lrPad">
                  {m_teammatesRatingsQuestionObj.name}
                </div>
                <div className="flex00a lrPad">
                  <CEGeneralReact.RatingNA1To5OutOf5Display p_rating1to5NAm1={p_teammatesRatingsCaptureObj["q" + m_teammatesRatingsQuestionObj.id + "a"]} />
                </div>
              </div>
              {(p_teammatesRatingsCaptureObj["q" + m_teammatesRatingsQuestionObj.id + "c"] !== "") &&
                <div className="microTopMargin lrMedPad">
                  <font className="fontTextLight">
                    {"Comments: " + p_teammatesRatingsCaptureObj["q" + m_teammatesRatingsQuestionObj.id + "c"]}
                  </font>
                </div>
              }
            </div>
          )}
        </div>
      </div>
    );
  }
}));







const NewContactForm = inject("ContactsMobx")(observer(
class NewContactForm extends Component { //props:
  constructor(props) {
    super(props);
    this.state = {
      s_triedFirstSaveTF: false //don't show any errors unless you tried to save and fields that cannot be blank were blank
    };
  }

  onclick_save_new_contact = () => {
    const o_newContactObj = this.props.ContactsMobx.o_newContactObj;

    var newContactFieldsAreValidTF = false;
    if(o_newContactObj.isPersonTF) { //person
      newContactFieldsAreValidTF = (this.new_person_first_name_valid_tf() && this.new_person_last_name_valid_tf() && this.new_person_email_valid_tf());
    }
    else { //company
      newContactFieldsAreValidTF = (this.new_company_legal_name_valid_tf() && this.new_company_business_type_valid_tf());
    }

    if(!newContactFieldsAreValidTF) {
      this.setState({s_triedFirstSaveTF:true});
    }
    else { //successful save with minimum number of required fields filled out
      this.props.ContactsMobx.a_insert_new_contact_from_contacts_system_creation_and_open_on_success();
    }
  }

  onclick_cancel_new_contact = () => {
    this.props.ContactsMobx.a_close_contact();
  }

  new_person_first_name_valid_tf = () => { return(JSFUNC.text_or_number_is_filled_out_tf(this.props.ContactsMobx.o_newContactObj.first_name)); }
  new_person_last_name_valid_tf = () => { return(JSFUNC.text_or_number_is_filled_out_tf(this.props.ContactsMobx.o_newContactObj.last_name)); }
  new_person_email_valid_tf = () => { return((this.props.ContactsMobx.o_newContactObj.email === "") || (JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(this.props.ContactsMobx.o_newContactObj.email) === undefined)); }

  new_company_legal_name_valid_tf = () => { return(JSFUNC.text_or_number_is_filled_out_tf(this.props.ContactsMobx.o_newContactObj.legal_name)); }
  new_company_business_type_valid_tf = () => { return(JSFUNC.select_int_is_filled_out_tf(this.props.ContactsMobx.o_newContactObj.business_type_id)); }

  render() {
    const s_triedFirstSaveTF = this.state.s_triedFirstSaveTF;

    const o_newContactObj = this.props.ContactsMobx.o_newContactObj;

    const isPersonTF = o_newContactObj.isPersonTF;
    const personCompanyString = this.props.ContactsMobx.person_or_company_string_from_is_person_and_is_multi_tf(isPersonTF, false);
    const contactFieldsArrayOfObjs = this.props.ContactsMobx.contact_fields_arrayOfObjs(isPersonTF);

    var errorMessageObj = {
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      legal_name: undefined,
      business_type_id: undefined
    };

    if(s_triedFirstSaveTF) {
      if(isPersonTF) { //person field errors
        if(!this.new_person_first_name_valid_tf()) {
          errorMessageObj.first_name = "The First Name must be filled out";
        }

        if(!this.new_person_last_name_valid_tf()) {
          errorMessageObj.last_name = "The Last Name must be filled out";
        }

        if(!this.new_person_email_valid_tf()) {
          errorMessageObj.email = JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(o_newContactObj.email);
        }
      }
      else { //company field errors
        if(!this.new_company_legal_name_valid_tf()) {
          errorMessageObj.legal_name = "The new company Legal Name must be filled out";
        }

        if(!this.new_company_business_type_valid_tf()) {
          errorMessageObj.business_type_id = "A Business Type must be selected";
        }
      }
    }

    return(
      <div className="flex11a displayFlexColumn smallFullPad bgContactsLighterYellow">
        <div className="flex00a smallBottomPad" style={{borderBottom:"solid 1px #ccc"}}>
          <div className="displayFlexRow flexWrap">
            <div className="flex11a displayFlexRowVc">
              <div className="flex00a rMargin">
                <CompanyOrPersonIcon p_isPersonTF={isPersonTF} p_sizeEm={3} />
              </div>
              <div className="flex11a rMargin">
                <font className="font15 fontItalic">
                  {"Creating a New Contact " + personCompanyString}
                </font>
              </div>
            </div>
            <div className="flex00a displayFlexRowVc">
              <div className="flex00a rMargin">
                <CEGeneralReact.CEButton
                  p_type="blue"
                  p_text="Save"
                  p_tabIndex={contactFieldsArrayOfObjs.length + 2}
                  f_onClick={this.onclick_save_new_contact}
                />
              </div>
              <div className="flex00a">
                <CEGeneralReact.ButtonWithConfirmBox
                  p_buttonType="gray"
                  p_buttonText="Cancel"
                  p_confirmType="confirm"
                  p_confirmMessage={"Are you sure you want to cancel creating this new Contact " + personCompanyString + "?"}
                  p_tabIndex={contactFieldsArrayOfObjs.length + 3}
                  f_onClickConfirm={this.onclick_cancel_new_contact}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex11a yScroll yScrollBottomPad">
          <div className="smallTopMargin microBottomMargin">
            <font className="font12 fontBold fontTextLight">
              {"Details"}
            </font>
          </div>
          {contactFieldsArrayOfObjs.map((m_contactFieldObj, m_index) =>
            (!JSFUNC.in_array(m_contactFieldObj.db_name, ["contact_company_id", "org_topdiv_id", "tree_id"])) &&
            <NewContactInputField
              p_contactFieldObj={m_contactFieldObj}
              p_tabIndex={m_index + 1}
              p_errorMessageObj={errorMessageObj}
            />
          )}
          <div className="hugeTopMargin" />
          <EditParentCompanyDivision p_contactObj={o_newContactObj} />
        </div>
      </div>
    );
  }
}));


const NewContactInputField = inject("ContactsMobx", "DatabaseMobx")(observer(
class NewContactInputField extends Component { //props: p_isPersonTF, p_contactFieldObj, p_tabIndex, p_errorMessageObj
  onchange_field_value = (i_newValue) => {
    this.props.ContactsMobx.a_update_new_contact_field_local(this.props.p_contactFieldObj.db_name, i_newValue);
  }

  render() {
    const p_contactFieldObj = this.props.p_contactFieldObj; //each p_contactFieldObj (hardcoded and custom fields together) is an expanded field map converted to an obj
    const p_errorMessageObj = this.props.p_errorMessageObj;

    const o_newContactObj = this.props.ContactsMobx.o_newContactObj;

    const fieldDbName = p_contactFieldObj.db_name;
    const fieldDisplayName = p_contactFieldObj.display_name;
    const fieldTypeObj = p_contactFieldObj.fieldTypeObj;

    const value = o_newContactObj[fieldDbName];

    const fieldErrorMessage = p_errorMessageObj[fieldDbName];
    const errorTF = (fieldErrorMessage !== undefined);

    var fieldIsSbCertsAndTypeIsLargeTF = false;
    if(fieldDbName === "sb_certifications_bm_set_aside_ids_comma") {
      const businessTypeMap = this.props.DatabaseMobx.tbl_row_map_from_id("tbl_a_business_types", o_newContactObj.business_type_id);
      if(businessTypeMap !== undefined) {
        if(businessTypeMap.get("is_sb_type_01") !== 1) {
          fieldIsSbCertsAndTypeIsLargeTF = true;
        }
      }
    }



    return(
      <div className="displayFlexRowVc lrMargin">
        <div className="flex00a rMargin" style={{flexBasis:"10em"}}>
          <font className="fontBlue">
            {fieldDisplayName}
          </font>
        </div>
        <div className="flex11a smallTopMargin smallBottomMargin">
          {(fieldIsSbCertsAndTypeIsLargeTF) ? (
            <font className="fontItalic fontTextLighter">
              {"N/A for Large Business size"}
            </font>
          ) : (
            <>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={fieldTypeObj}
                p_valueRaw={value}
                p_tabIndex={this.props.p_tabIndex}
                p_errorTF={errorTF}
                f_onChangeOrOnSelect={this.onchange_field_value}
              />
              {(errorTF) &&
                <CEGeneralReact.ErrorText
                  p_class="microTopMargin"
                  p_text={fieldErrorMessage}
                />
              }
            </>
          )}
        </div>
      </div>
    );
  }
}));





const SelectContactCheckBox = inject("ContactsMobx")(observer(
class SelectContactCheckBox extends Component { //props: p_selectMode, p_contactIDsAlreadyAddedArray, p_contactObj, p_sizeEm, p_styleObj
  onclick_select_contact = () => {
    this.props.ContactsMobx.a_select_contact(this.props.p_contactObj.id, this.props.p_selectMode);
  }

  render() {
    const p_selectMode = this.props.p_selectMode;
    const p_contactIDsAlreadyAddedArray = this.props.p_contactIDsAlreadyAddedArray;
    const p_contactObj = this.props.p_contactObj;
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1.2);
    const styleObj = JSFUNC.prop_value(this.props.p_styleObj, {});

    const [isSelectingPersonsTFU, isMultiSelectTFU] = this.props.ContactsMobx.is_person_and_is_multiselect_tfu_from_select_mode(p_selectMode);
    const isPersonTF = p_contactObj.isPersonTF;
    const contactID = p_contactObj.id;

    if(isPersonTF !== isSelectingPersonsTFU || (p_contactObj.id <= 0)) { //if this contact is not the type being selected, do not draw a checkbox or a blank space for a checkbox
      return(null);
    }

    //determine if the checkbox is checked
    const isAlreadyAddedTF = JSFUNC.in_array(contactID, p_contactIDsAlreadyAddedArray);
    const isSelectedTF = this.props.ContactsMobx.contact_is_selected_tf(contactID);
    var checkedFlag = 0; //unchecked
    if(isAlreadyAddedTF) {
      checkedFlag = 4; //disabled and checked
    }
    else if(isSelectedTF) {
      checkedFlag = 1; //checked
    }

    const personCompanyString = this.props.ContactsMobx.person_or_company_string_from_is_person_and_is_multi_tf(isPersonTF, false);
    var title = undefined;
    if(isAlreadyAddedTF) {
      title = "This Contact " + personCompanyString + " has already been added and is not available for selection";
    }
    else {
      title = "Select this Contact " + personCompanyString;
    }

    return(
      <div className="flex00a" style={styleObj}>
        <LibraryReact.CheckBox
          p_u0_s1_p2_du3_ds4={checkedFlag}
          p_sizeEm={sizeEm}
          p_tabIndex={-1}
          p_title={title}
          f_onClick={this.onclick_select_contact}
        />
      </div>
    );
  }
}));






const AdvancedSearchCapturesAnalysis = inject("ContactsMobx", "DatabaseMobx")(observer(
class AdvancedSearchCapturesAnalysis extends Component { //props: p_selectMode
  constructor(props) {
    super(props);
    this.state = {
      s_clickedDotCaptureIDToHighlight: undefined
    };
  }

  onclick_scatter_dot = (i_captureID) => {
    this.setState((i_state, i_props) => ({
      s_clickedDotCaptureIDToHighlight: ((i_state.s_clickedDotCaptureIDToHighlight === i_captureID) ? (undefined) : (i_captureID)) //clicking the same dot turns off all highlight
    }));
  }

  render() {
    const clickedDotCaptureIDToHighlight = this.state.s_clickedDotCaptureIDToHighlight;

    const p_selectMode = this.props.p_selectMode;

    const advSearchTeamingCategory = this.props.ContactsMobx.o_advSearchTeamingCategory;
    const advSearchCaptureIDsArray = this.props.ContactsMobx.o_advSearchCaptureIDsArray;
    const c_openContactObj = this.props.ContactsMobx.c_openContactObj;
    const openContactName = this.props.ContactsMobx.c_openContactName;
    const advSearchAnalysisCapturesArrayOfObjs = this.props.ContactsMobx.c_advSearchAnalysisCapturesArrayOfObjs;
    const advSearchAnalysisScatterDataObj = this.props.ContactsMobx.c_advSearchAnalysisScatterDataObj;

    const fieldMapOfContractOverallValue = this.props.DatabaseMobx.c_fieldMapOfContractOverallValue;
    const fieldMapOfAwardDate = this.props.DatabaseMobx.c_fieldMapOfAwardDate;

    var graphTitle = "'" + openContactName + "' ";
    if(advSearchTeamingCategory === "theirAsOurWins") { graphTitle += "where we are the Prime (Won Captures)"; }
    else if(advSearchTeamingCategory === "theirAsOurActive") { graphTitle += "where we are the Prime (Active Captures)"; }
    else if(advSearchTeamingCategory === "theirAsOurLosses") { graphTitle += "where we are the Prime (Lost Captures)"; }
    else if(advSearchTeamingCategory === "usAsTheirWins") { graphTitle += "where we are a Sub (Won Captures)"; }
    else if(advSearchTeamingCategory === "usAsTheirActive") { graphTitle += "where we are a Sub (Active Captures)"; }
    else if(advSearchTeamingCategory === "usAsTheirLosses") { graphTitle += "where we are a Sub (Lost Captures)"; }

    const canOpenCaptureTF = (p_selectMode === "no_select");
    const numCaptures = advSearchAnalysisCapturesArrayOfObjs.length;

    var capturesListTitle = numCaptures + " " + JSFUNC.plural(numCaptures, "Capture", "Captures");

    return(
      <>
        <div className="flex00a tbPad">
          <font className="font14">
            {"Advanced Search Analysis"}
          </font>
        </div>
        <div className="medFullPad bgWhite border1ddd">
          <LibraryReact.ScatterPlot
            p_dataObjOrDataArrayOfObjs={advSearchAnalysisScatterDataObj}
            p_xMin={undefined}
            p_xMax={advSearchAnalysisScatterDataObj.maxXValue * 1.09}
            p_xValueFormat={undefined}
            p_xLabel={"# Months Until " + fieldMapOfAwardDate.get("display_name")}
            p_yMin={undefined}
            p_yMax={undefined}
            p_yValueFormat="moneyShort"
            p_yLabel={fieldMapOfContractOverallValue.get("display_name")}
            p_yLogScaleTF={true}
            p_zSizePercentOfHeight={3}
            p_zMin={undefined}
            p_zMax={undefined}
            p_dataAverageVerticalLinesTF={false}
            p_title={graphTitle}
            p_heightPx={300}
            p_fontSizePx={undefined}
            p_legendTF={false}
            f_onClickDot={this.onclick_scatter_dot}
          />
        </div>
        <div className="flex00a tbPad">
          <font className="font12 fontBold fontItalic fontTextLighter">
            {capturesListTitle}
          </font>
        </div>
        <div className="flex11a yScroll lrPad hugeBottomPad">
          {advSearchAnalysisCapturesArrayOfObjs.map((m_advSearchAnalysisCaptureObj) =>
            <AdvancedSearchAnalysisSingleCaptureRow
              key={"c" + m_advSearchAnalysisCaptureObj.captureID + "cc" + c_openContactObj.id}
              p_advSearchAnalysisCaptureObj={m_advSearchAnalysisCaptureObj}
              p_clickedDotCaptureIDToHighlight={clickedDotCaptureIDToHighlight}
              p_canOpenCaptureTF={canOpenCaptureTF}
            />
          )}
        </div>
      </>
    );
  }
}));

const AdvancedSearchAnalysisSingleCaptureRow = inject("ContactsMobx", "OpenCaptureMobx", "TeammateContractsMobx", "DatabaseMobx")(observer(
class AdvancedSearchAnalysisSingleCaptureRow extends Component { //props: p_advSearchAnalysisCaptureObj, p_clickedDotCaptureIDToHighlight, p_canOpenCaptureTF
  constructor(props) {
    super(props);
    this.state = {
      s_expandedTF: false
    };
  }

  onclick_expand_collapse_adv_search_capture_row = () => {
    this.setState((i_state, i_props) => ({
      s_expandedTF: (!i_state.s_expandedTF)
    }));
  }

  onclick_capture = () => {
    this.props.OpenCaptureMobx.a_open_single_capture(this.props.p_advSearchAnalysisCaptureObj.captureID);
  }

  render() {
    const expandedTF = this.state.s_expandedTF;

    const advSearchAnalysisCaptureObj = this.props.p_advSearchAnalysisCaptureObj;
    const clickedDotCaptureIDToHighlight = this.props.p_clickedDotCaptureIDToHighlight;
    const canOpenCaptureTF = this.props.p_canOpenCaptureTF;

    const openContactName = this.props.ContactsMobx.c_openContactName;
    const advSearchExpandedSelectedTeammatesArrayOfObjs = this.props.ContactsMobx.c_advSearchExpandedSelectedTeammatesArrayOfObjs;
    const c_teammatesAllocOfCovFieldDisplayName = this.props.DatabaseMobx.c_teammatesAllocOfCovFieldDisplayName;
    const c_teammatesWorkshareTypeFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareTypeFieldDisplayName;
    const c_teammatesWorkshareNumFtesFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareNumFtesFieldDisplayName;
    const c_teammatesWorkshareNotesFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareNotesFieldDisplayName;

    const isHighlightedTF = (advSearchAnalysisCaptureObj.captureID === clickedDotCaptureIDToHighlight);

    return(
      <div className="displayFlexRow bgWhite border bevelBorderColors">
        <div className={"flex00a " + ((isHighlightedTF) ? ("bgGoldGradient") : ("bgLighterGray"))} style={{flexBasis:"1.5em"}} />
        <div className="flex11a">
          <div className="displayFlexRow">
            <div
              className={"flex11a displayFlexRow tbPad " + ((canOpenCaptureTF) ? ("hoverLighterBlueGradient cursorPointer") : (""))}
              title={((canOpenCaptureTF) ? ("Click to open Capture '" + advSearchAnalysisCaptureObj.captureName + "'") : (undefined))}
              onClick={((canOpenCaptureTF) ? (this.onclick_capture) : (undefined))}>
              <div className="flex11a">
                <div className="displayFlexRow" style={{height:"2.8em"}}>
                  <div className="flex11a displayFlexRowVc lrMedPad" style={{flexBasis:"300em"}}>
                    <LibraryReact.MaxHeightWrap p_maxHeight="2.5em" p_fontClass="font11 fontBold fontBlue">
                      {advSearchAnalysisCaptureObj.captureName}
                    </LibraryReact.MaxHeightWrap>
                  </div>
                  <div className="flex11a displayFlexRowVc lrMedPad" style={{flexBasis:"100em"}}>
                    <LibraryReact.Nowrap p_fontClass="">
                      {advSearchAnalysisCaptureObj.stageValueMask}
                    </LibraryReact.Nowrap>
                  </div>
                  <div className="flex00a displayFlexRowVc textRight" style={{flexBasis:"10em"}}>
                    <font className="">
                      {advSearchAnalysisCaptureObj.covValueMask}
                    </font>
                  </div>
                  <div className="flex00a displayFlexRowVc" style={{flexBasis:"8em"}}>
                    <font className="">
                      {advSearchAnalysisCaptureObj.awardDateValueMask}
                    </font>
                  </div>
                </div>
                <div className="displayFlexRowVc" style={{height:"1.3em"}}>
                  <div className="flex11a lrMedPad" style={{flexBasis:"200em"}}>
                    <LibraryReact.Nowrap p_fontClass="fontBold fontTextLighter">
                      <font className="fontItalic fontTextLight">
                        {((advSearchAnalysisCaptureObj.captureTypeIsPrimeTF) ? (openContactName) : (advSearchAnalysisCaptureObj.ourPrimeSubTeammateDivisionValueMaskSortIfoCanEditObj.valueMask))}
                      </font>
                    </LibraryReact.Nowrap>
                  </div>
                  <div className="flex11a lrMedPad" style={{flexBasis:"100em"}}>
                    <LibraryReact.Nowrap p_fontClass="">
                      <font className="fontItalic fontTextLighter">
                        {c_teammatesAllocOfCovFieldDisplayName + ": "}
                      </font>
                      {((advSearchAnalysisCaptureObj.captureTypeIsPrimeTF) ? (
                        advSearchAnalysisCaptureObj.expandedTeammateObj.allocationPercentMask + " (" + advSearchAnalysisCaptureObj.expandedTeammateObj.allocationOfCOVMask + ")"
                      ) : (
                        advSearchAnalysisCaptureObj.ourPrimeSubTeammateAllocationValueMaskSortIfoCanEditObj.valueMask + " (" + advSearchAnalysisCaptureObj.ourAllocationMoneyShort + ")"
                      ))}
                    </LibraryReact.Nowrap>
                  </div>
                </div>
                <div className="displayFlexRowVc" style={{height:"1.3em"}}>
                  <div className="flex11a lrMedPad" style={{flexBasis:"200em"}}>
                    <LibraryReact.Nowrap p_fontClass="">
                      <font className="fontItalic fontTextLighter">
                        {c_teammatesWorkshareTypeFieldDisplayName + ": "}
                      </font>
                      {((advSearchAnalysisCaptureObj.captureTypeIsPrimeTF) ? (
                        advSearchAnalysisCaptureObj.expandedTeammateObj.workshareTypeMask
                      ) : (
                        advSearchAnalysisCaptureObj.ourDivisionMinus2TeammateObj.workshareTypeMask
                      ))}
                    </LibraryReact.Nowrap>
                  </div>
                  <div className="flex11a lrMedPad" style={{flexBasis:"100em"}}>
                    <font className="fontItalic fontTextLighter">
                      {c_teammatesWorkshareNumFtesFieldDisplayName + ": "}
                    </font>
                    {((advSearchAnalysisCaptureObj.captureTypeIsPrimeTF) ? (
                      advSearchAnalysisCaptureObj.expandedTeammateObj.workshare_number_of_ftes
                    ) : (
                      advSearchAnalysisCaptureObj.ourDivisionMinus2TeammateObj.workshare_number_of_ftes
                    ))}
                  </div>
                </div>
                <div className="displayFlexRowVc" style={{height:"1.3em"}}>
                  <div className="flex00a lrMedPad" style={{flexBasis:"10em"}}>
                    <font className="fontItalic fontTextLighter">
                      {c_teammatesWorkshareNotesFieldDisplayName + ": "}
                    </font>
                  </div>
                  <div className="flex11a lrMedPad" title={((advSearchAnalysisCaptureObj.captureTypeIsPrimeTF) ? (advSearchAnalysisCaptureObj.expandedTeammateObj.workshare_notes) : (advSearchAnalysisCaptureObj.ourDivisionMinus2TeammateObj.workshare_notes))}>
                    <LibraryReact.Nowrap p_fontClass="">
                      {((advSearchAnalysisCaptureObj.captureTypeIsPrimeTF) ? (
                        advSearchAnalysisCaptureObj.expandedTeammateObj.workshare_notes
                      ) : (
                        advSearchAnalysisCaptureObj.ourDivisionMinus2TeammateObj.workshare_notes
                      ))}
                    </LibraryReact.Nowrap>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex00a displayFlexColumnHcVc lrMedPad">
              <CEGeneralReact.ButtonExpandCollapse
                p_isExpandedTF={expandedTF}
                p_sizeEm={2}
                p_title="Expand to view Capture/Teammate details"
                f_onClick={this.onclick_expand_collapse_adv_search_capture_row}
              />
            </div>
          </div>
          {(expandedTF) &&
            <div className="bgLightestGray smallFullMargin smallFullPad border1ddd">
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfCaptureID} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfCaptureType} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfCaptureManagers} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfDivisionOwners} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfContractType} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfPeriodOfPerformance} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfAddedDate} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfAwardDate} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfContractStartDate} />
              <AdvSearchCaptureField p_captureMap={advSearchAnalysisCaptureObj.captureMap} p_expandedFieldMap={this.props.DatabaseMobx.c_fieldMapOfContractEndDate} />
              <div className="medTopMargin tbPad">
                <font className="font11 fontBold fontItalic fontTextLighter">
                  {"Teammates on this Capture"}
                </font>
              </div>
              <div className="displayFlexRow">
                <div className="flex00a" style={{flexBasis:"1em"}} />
                <div className="flex11a">
                  {advSearchExpandedSelectedTeammatesArrayOfObjs.map((m_expandedTeammateObj) =>
                    ((m_expandedTeammateObj.capture_id === advSearchAnalysisCaptureObj.captureID) && (m_expandedTeammateObj.contact_company_id > 0)) &&
                    <div className="displayFlexRow tbMicroPad hoverLightestBlueGradient">
                      <div className="flex11a lrPad" style={{flexBasis:"200em"}} title={m_expandedTeammateObj.teammateNamePlainText}>
                        <LibraryReact.Nowrap p_fontClass="">
                          {m_expandedTeammateObj.teammateNamePlainText}
                        </LibraryReact.Nowrap>
                      </div>
                      <div className="flex00a lrPad" style={{flexBasis:"3em"}}>
                        {m_expandedTeammateObj.businessSizeLabelShort}
                      </div>
                      <div className="flex00a lrPad" style={{flexBasis:"6em"}}>
                        {m_expandedTeammateObj.allocationPercentMask}
                      </div>
                      <div className="flex11a lrPad" style={{flexBasis:"100em"}}>
                        {m_expandedTeammateObj.allocationOfCOVMask}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}));


const AdvSearchCaptureField = inject("DatabaseMobx")(observer(
class AdvSearchCaptureField extends Component { //props: p_captureMap, p_expandedFieldMap
  render() {
    const captureMap = this.props.p_captureMap;
    const expandedFieldMap = this.props.p_expandedFieldMap;

    const valueMask = this.props.DatabaseMobx.value_mask_from_capture_map_and_expanded_capture_field_map(captureMap, expandedFieldMap);

    return(
      <div className="displayFlexRowVc tbMicroPad hoverLightestBlueGradient">
        <div className="flex11a lrMedPad" style={{flexBasis:"100em"}}>
          <font className="fontBlue">
            {expandedFieldMap.get("display_name")}
          </font>
        </div>
        <div className="flex11a lrMedPad" style={{flexBasis:"300em"}}>
          <font className="">
            {valueMask}
          </font>
        </div>
      </div>
    );
  }
}));
