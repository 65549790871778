import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import * as JSFUNC from "../../Library/JSFUNC.js";
import * as LibraryReact from "../../Library/LibraryReact.js";

import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";


export const BudgetExecWorkload = inject("BudgetMobx", "DatabaseMobx")(observer(
class BudgetExecWorkload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_reassignErrorMessage: undefined
    };
  }

  onswitch_include_rejected_and_approved = () => {
    this.props.BudgetMobx.a_budget_exec_toggle_include_rejected_and_approved_tf();
  }

  onselect_reassign_budget_manager_user_id = (i_selectedBudgetManagerUserID) => {
    this.setState({s_reassignErrorMessage:undefined});
    this.props.BudgetMobx.a_budget_exec_set_selected_reassign_budget_manager_user_id(i_selectedBudgetManagerUserID);
  }

  onclick_reassign_funding_requests = () => {
    const o_budgetExecSelectedReassignBudgetManagerUserID = this.props.BudgetMobx.o_budgetExecSelectedReassignBudgetManagerUserID;
    const o_budgetExecCheckedCaptureIDsArray = this.props.BudgetMobx.o_budgetExecCheckedCaptureIDsArray;

    var updatedReassignErrorMessage = undefined;
    if(!JSFUNC.select_int_is_filled_out_tf(o_budgetExecSelectedReassignBudgetManagerUserID)) {
      updatedReassignErrorMessage = "Select a Budget Manager to reassign selected Funding Request(s) to";
    }
    else {
      const captureIDsNotAccessibleByNewBudgetManagerArray = this.props.BudgetMobx.a_budget_exec_reassign_checked_captures_to_selected_budget_manager_user(o_budgetExecCheckedCaptureIDsArray, o_budgetExecSelectedReassignBudgetManagerUserID);
      const numCaptureIDsNotAccessibleByNewBudgetManager = captureIDsNotAccessibleByNewBudgetManagerArray.length;
      if(numCaptureIDsNotAccessibleByNewBudgetManager > 0) {
        const newBudgetManagerName = this.props.DatabaseMobx.user_name_mask_plaintext_from_user_id(o_budgetExecSelectedReassignBudgetManagerUserID);
        updatedReassignErrorMessage = newBudgetManagerName + " does not have the firewalling permissions to access " + numCaptureIDsNotAccessibleByNewBudgetManager + " " + JSFUNC.plural(numCaptureIDsNotAccessibleByNewBudgetManager, "Capture", "Captures") + " in this reassignment";
      }
    }
    this.setState({s_reassignErrorMessage:updatedReassignErrorMessage});
  }

  render() {
    const s_reassignErrorMessage = this.state.s_reassignErrorMessage;

    const selectedReassignBudgetManagerUserID = this.props.BudgetMobx.o_budgetExecSelectedReassignBudgetManagerUserID;
    const includeRejectedAndApprovedTF = this.props.BudgetMobx.o_budgetExecIncludeRejectedAndApprovedTF;
    const budgetExecDataObj = this.props.BudgetMobx.c_budgetExecDataObj;

    const budgetUsersArrayOfObjs = budgetExecDataObj.budgetUsersArrayOfObjs;
    const userIDsToNotIncludeArray = budgetExecDataObj.userIDsToNotIncludeArray;

    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a displayFlexRowVc flexWrap tbMedPad" style={{borderBottom:"solid 1px #ccc"}}>
          <div className="flex11a lrMedPad">
            <font className="font14 fontBold fontTextLighter">
              {"Budget Executive Workload Management"}
            </font>
          </div>
          <div className="flex11a displayFlexRowVc lrMedPad">
            <div className="flex00a rMargin">
              <font className="fontItalic fontTextLighter">
                {"Include Approved and Rejected Funding Requests?"}
              </font>
            </div>
            <div className="flex00a smallFullMargin">
              <LibraryReact.Switch
                p_isOnTF={includeRejectedAndApprovedTF}
                p_title="When switched on, all Funding Requests listed for each user will include those already completed ('Approved') and those that are sent back to the capture manager to be edited and resubmitted ('Rejected')"
                f_onClick={this.onswitch_include_rejected_and_approved}
              />
            </div>
          </div>
          <div className="flex11a lrMedPad">
            <div style={{width:"28em"}}>
              <div className="displayFlexRowVc">
                <div className="flex11a rMargin">
                  <font className="fontItalic fontTextLighter">
                    {"Reassign all checked Funding Requests to:"}
                  </font>
                </div>
                <div className="flex00a">
                  <CEGeneralReact.CEButton
                    p_type="add"
                    p_text="Reassign"
                    f_onClick={this.onclick_reassign_funding_requests}
                  />
                </div>
              </div>
              <div className="smallTopMargin">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectUserWithBudgetPowerFieldTypeObj}
                  p_valueRaw={selectedReassignBudgetManagerUserID}
                  p_valuesToNotIncludeArray={userIDsToNotIncludeArray}
                  p_errorTF={(s_reassignErrorMessage !== undefined)}
                  f_onChangeOrOnSelect={this.onselect_reassign_budget_manager_user_id}
                />
              </div>
              {(s_reassignErrorMessage !== undefined) &&
                <CEGeneralReact.ErrorText
                  p_class="microTopMargin"
                  p_text={s_reassignErrorMessage}
                />
              }
            </div>
          </div>
        </div>
        <div className="flex11a yScroll yScrollBottomPad">
          {budgetUsersArrayOfObjs.map((m_budgetUserObj) =>
            <ContractsExecWorkloadUserItem
              key={m_budgetUserObj.user_id}
              p_budgetUserObj={m_budgetUserObj}
            />
          )}
        </div>
      </div>
    );
  }
}));

const ContractsExecWorkloadUserItem = inject("BudgetMobx")(observer(
class ContractsExecWorkloadUserItem extends Component { //props: p_budgetUserObj
  onclick_user_checkbox = () => {
    this.props.BudgetMobx.a_budget_exec_single_user_check_or_uncheck_all_captures(this.props.p_budgetUserObj.capturesArrayOfObjs);
  }

  onclick_expand_collapse_user = () => {
    this.props.BudgetMobx.a_budget_exec_toggle_expand_collapse_user(this.props.p_budgetUserObj.user_id);
  }

  render() {
    const budgetUserObj = this.props.p_budgetUserObj;

    const checkedCaptureIDsArray = this.props.BudgetMobx.o_budgetExecCheckedCaptureIDsArray;
    const expandedUserIDsArray = this.props.BudgetMobx.o_budgetExecExpandedUserIDsArray;

    const capturesArrayOfObjs = budgetUserObj.capturesArrayOfObjs;
    const userNumCaptures = capturesArrayOfObjs.length;
    const numFundingRequestsArray = [budgetUserObj.numFundingRequestsOverdue, budgetUserObj.numFundingRequestsDueWithinWeek, budgetUserObj.numFundingRequestsDueLonger, budgetUserObj.numFundingRequestsRejectedOrApproved];
    const numFundingRequestLabelsArray = ["Overdue", "Due Within a Week", "Due Longer than a Week", "Approved / Rejected"];
    const numFundingRequestFontClassesArray = ["fontRed", "", "", "fontTextLight"];

    const isExpandedTF = JSFUNC.in_array(budgetUserObj.user_id, expandedUserIDsArray);

    var numCheckedCaptures = 0;
    for(let captureObj of capturesArrayOfObjs) {
      if(JSFUNC.in_array(captureObj.id, checkedCaptureIDsArray)) {
        numCheckedCaptures++;
      }
    }

    var userChecked012 = 0; //unchecked (no contracts checked)
    if(numCheckedCaptures === userNumCaptures) {
      userChecked012 = 1; //checked (all contracts checked)
    }
    else if(numCheckedCaptures > 0) {
      userChecked012 = 2; //partial
    }

    return(
      <>
        <div key="user" className="displayFlexRowVc lrMedMargin" style={{height:"4.5em", borderBottom:"solid 1px #d5d5d8"}}>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
            {(userNumCaptures > 0) &&
              <LibraryReact.CheckBox
                p_u0_s1_p2_du3_ds4={userChecked012}
                p_sizeEm={1.2}
                p_title={"Check or uncheck all Funding Requests currently assigned to " + budgetUserObj.fullName}
                f_onClick={this.onclick_user_checkbox}
              />
            }
          </div>
          <div className="flex11a lrMargin" style={{flexBasis:"100em"}}>
            <LibraryReact.Nowrap>
              {budgetUserObj.fullName}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex11a lrMedPad textCenter" style={{flexBasis:"100em", borderLeft:"solid 1px #ddd"}}>
            <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
              {"Assigned to"}
            </LibraryReact.Nowrap>
            <LibraryReact.Nowrap p_fontClass={((userNumCaptures > 0) ? ("font11") : ("fontItalic fontTextLighter"))}>
              {userNumCaptures + " " + JSFUNC.plural(userNumCaptures, "capture", "captures")}
            </LibraryReact.Nowrap>
          </div>
          {numFundingRequestsArray.map((m_numContracts, m_index) =>
            <div className="flex11a lrMedPad textCenter" style={{flexBasis:"100em", borderLeft:"solid 1px #ddd"}}>
              <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                {numFundingRequestLabelsArray[m_index]}
              </LibraryReact.Nowrap>
              <LibraryReact.Nowrap p_fontClass={"font14 " + ((m_numContracts > 0) ? ("fontBold " + numFundingRequestFontClassesArray[m_index]) : ("fontItalic fontTextLighter"))}>
                {m_numContracts}
              </LibraryReact.Nowrap>
            </div>
          )}
          <div className="flex00a displayFlexRowHcVc lrMedPad" style={{flexBasis:"9em", borderLeft:"solid 1px #eee"}}>
            {(userNumCaptures > 0) ? (
              <div className="displayFlexRowVc">
                <div className="flex00a rMargin textCenter">
                  <font className="fontItalic fontTextLighter">
                    <div>{"View"}</div>
                    <div>{"Funding"}</div>
                    <div>{"Requests"}</div>
                  </font>
                </div>
                <div className="flex00a">
                  <CEGeneralReact.ButtonExpandCollapse
                    p_isExpandedTF={isExpandedTF}
                    p_sizeEm={2.2}
                    p_tabIndex={-1}
                    p_title={undefined}
                    f_onClick={this.onclick_expand_collapse_user}
                  />
                </div>
              </div>
            ) : (
              <div className="flex00a textCenter">
                <font className="fontItalic fontTextLighter">
                  <div>{"0 Captures"}</div>
                  <div>{"Assigned"}</div>
                </font>
              </div>
            )}
          </div>
        </div>
        {(isExpandedTF) &&
          capturesArrayOfObjs.map((m_captureObj) =>
            <BudgetExecWorkloadCaptureWithFundingRequests
              key={m_captureObj.id}
              p_captureObj={m_captureObj}
            />
          )
        }
      </>
    );
  }
}));

const BudgetExecWorkloadCaptureWithFundingRequests = inject("BudgetMobx")(observer(
class BudgetExecWorkloadCaptureWithFundingRequests extends Component { //props: p_captureObj
  onclick_contract_checkbox = () => {
    this.props.BudgetMobx.a_budget_exec_check_or_uncheck_single_capture(this.props.p_captureObj.id);
  }

  render() {
    const captureObj = this.props.p_captureObj;
    const fundingRequestsArrayOfObjs = captureObj.fundingRequestsArrayOfObjs;

    const includeRejectedAndApprovedTF = this.props.BudgetMobx.o_budgetExecIncludeRejectedAndApprovedTF;
    const isCheckedTF = JSFUNC.in_array(captureObj.id, this.props.BudgetMobx.o_budgetExecCheckedCaptureIDsArray.slice());

    return(
      <div className="displayFlexRow" style={{marginLeft:"1.5em", borderBottom:"solid 1px #aab", backgroundColor:"#f2eeee"}}>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
          <LibraryReact.CheckBox
            p_u0_s1_p2_du3_ds4={(isCheckedTF) ? (1) : (0)}
            p_sizeEm={1.2}
            p_title="Check this box to reassign this contract to the contracts manager selected above"
            f_onClick={this.onclick_contract_checkbox}
          />
        </div>
        <div className="flex11a">
          <div className="smallFullMargin">
            <font className="font11 fontItalic">{"Capture: "}</font>
            <font className="font11 fontBold">{captureObj.captureFullName}</font>
          </div>
          <div className="lrMargin tbMedMargin" style={{backgroundColor:"#fff"}}>
            {fundingRequestsArrayOfObjs.map((m_fundingRequestObj) =>
              (includeRejectedAndApprovedTF || !JSFUNC.in_array(m_fundingRequestObj.nr0_rq1_rj2_rs3_ap4, [2, 4])) &&
              <BudgetTodoFundingRequestItem
                key={m_fundingRequestObj.id}
                p_fundingRequestObj={m_fundingRequestObj}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}));



//===================================================================================================================================================================================


export const BudgetsTodo = inject("BudgetMobx")(observer(
class BudgetsTodo extends Component {
  onselect_todo_table_view = (i_newValue) => {
    this.props.BudgetMobx.a_budget_todo_set_table_selected_view(i_newValue);
  }

  render() {
    const tableSelectedView = this.props.BudgetMobx.o_budgetTodoTableSelectedView;
    const selectedTodoObj = this.props.BudgetMobx.c_budgetTodoSelectedTodoObj;
    const selectTableViewFieldTypeObj = this.props.BudgetMobx.c_budgetTodoSelectTableViewFieldTypeObj;

    const numFundingRequests = selectedTodoObj.numFundingRequests;
    const sectionNamesArray = selectedTodoObj.sectionNamesArray;
    const allSectionedFundingRequestsArrayOfArrayOfObjs = selectedTodoObj.allSectionedFundingRequestsArrayOfArrayOfObjs;

    return(
      <>
        <div className="flex00a displayFlexRowVc flexWrap tbMedPad" style={{borderBottom:"solid 1px #ccc"}}>
          <div className="flex00a lrMedPad" style={{borderRight:"solid 1px #ddd"}}>
            <div>
              <font className="font14 fontBold fontTextLighter">
                {"Budget Manager To Do Table"}
              </font>
            </div>
            <div>
              <font className="font12 fontTextLight">
                {numFundingRequests + " " + JSFUNC.plural(numFundingRequests, "Funding Request", "Funding Requests")}
              </font>
            </div>
          </div>
          <div className="flex00a lrMedPad" style={{flexBasis:"27em", borderRight:"solid 1px #ddd"}}>
            <div className="textCenter microBottomMargin">
              <font className="fontBold fontItalic fontTextLight">
                {"To Do Table View"}
              </font>
            </div>
            <div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={selectTableViewFieldTypeObj}
                p_valueRaw={tableSelectedView}
                f_onChangeOrOnSelect={this.onselect_todo_table_view}
              />
            </div>
          </div>
        </div>
        {(numFundingRequests === 0) ? (
          <CEGeneralReact.EmptyScreenGray>
            {(tableSelectedView === "awaitingApproval") ? (
              "No Funding Requests have been assigned to you at this time"
            ) : (
              "No Funding Requests to show"
            )}
          </CEGeneralReact.EmptyScreenGray>
        ) : (
          <div className="flex11a yScroll yScrollBottomPad">
            {allSectionedFundingRequestsArrayOfArrayOfObjs.map((m_sectionFundingRequestsArrayOfObjs, m_index) =>
              (m_sectionFundingRequestsArrayOfObjs.length > 0) &&
              <>
                <div className={"lrMedPad tbPad " + ((m_index > 0) ? ("hugeTopMargin") : (""))} style={{backgroundColor:"#e3e3e3"}}>
                  <LibraryReact.Nowrap p_fontClass="font13 fontBold fontItalic fontTextLight">
                    {sectionNamesArray[m_index] + " (" + m_sectionFundingRequestsArrayOfObjs.length + ")"}
                  </LibraryReact.Nowrap>
                </div>
                {m_sectionFundingRequestsArrayOfObjs.map((m_fundingRequestObj) =>
                  <BudgetTodoFundingRequestItem
                    key={m_fundingRequestObj.id}
                    p_fundingRequestObj={m_fundingRequestObj}
                  />
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
}));





export const BudgetTodoFundingRequestItem = inject("BudgetMobx")(observer(
class BudgetTodoFundingRequestItem extends Component { //props: p_fundingRequestObj
  onclick_open_funding_request_edit = () => {
    this.props.BudgetMobx.a_budget_todo_set_open_funding_request_id(this.props.p_fundingRequestObj.id);
  }

  render() {
    const fundingRequestObj = this.props.p_fundingRequestObj;

    const fieldDisplayNamesArray = ["Budget Category", "Capture", "Requested By"];
    const valuesArray = [fundingRequestObj.budgetCategoryName, fundingRequestObj.captureFullName, fundingRequestObj.requestedByUserName];

    return(
      <div
        className="displayFlexRowVc smallFullPad border bevelBorderColors hoverLighterBlueGradient cursorPointer"
        onClick={this.onclick_open_funding_request_edit}>
        <div className="flex00a" style={{flexBasis:"17em"}}>
          <div className="">
            <font className="fontItalic fontTextLight">
              {"Need By Date"}
            </font>
          </div>
          <div>
            {fundingRequestObj.needByDateMask}
          </div>
        </div>
        <div className="flex11a" style={{flexBasis:"100em"}}>
          <div className="">
            <font className="fontItalic fontTextLight">
              {"Status"}
            </font>
          </div>
          <div className="displayFlexRowVc">
            <div className="flex00a rMargin" style={{flexBasis:"0.6em", height:"1.1em", backgroundColor:"#" + fundingRequestObj.statusColor}} />
            <div className="flex11a">
              <LibraryReact.Nowrap>
                {fundingRequestObj.statusLabel}
              </LibraryReact.Nowrap>
            </div>
          </div>
        </div>
        {fieldDisplayNamesArray.map((m_fieldDisplayName, m_index) =>
          <div key={m_fieldDisplayName} className="flex11a lrMargin" style={{flexBasis:"100em"}}>
            <div>
              <font className="fontItalic fontTextLight">
                {m_fieldDisplayName}
              </font>
            </div>
            <div>
              <LibraryReact.Nowrap>
                {valuesArray[m_index]}
              </LibraryReact.Nowrap>
            </div>
          </div>
        )}
      </div>
    );
  }
}));



export const BudgetTodoOpenFundingRequestFloatingBox = inject("BudgetMobx", "DatabaseMobx")(observer(
class BudgetTodoOpenFundingRequestFloatingBox extends Component { //props: p_captureID
  constructor(props) {
    super(props);
    this.state = {
      s_enteringRejectionMessageTF: false,
      s_bmNotes: "",
      s_showErrorsTF: false
    }
  }

  onclick_approve_funding_request = () => {
    const fundingRequestObj = this.props.BudgetMobx.c_budgetTodoOpenFundingRequestObj;
    this.props.BudgetMobx.a_budget_update_funding_request_status(fundingRequestObj, 4, "", this.onclick_close_open_funding_request);
  }

  onclick_open_reject_edit_bm_notes = () => {
    const fundingRequestObj = this.props.BudgetMobx.c_budgetTodoOpenFundingRequestObj;
    this.setState({
      s_enteringRejectionMessageTF:true,
      s_bmNotes: fundingRequestObj.bm_notes
    });
  }

  onchange_bm_notes = (i_newValue) => {
    this.setState({s_bmNotes:i_newValue});
  }

  onclick_cancel_reject = () => {
    this.setState({s_enteringRejectionMessageTF:false});
  }

  onclick_reject_funding_request_with_comment = () => {
    const bmNotes = this.state.s_bmNotes;
    if(!JSFUNC.text_or_number_is_filled_out_tf(bmNotes)) {
      this.setState({s_showErrorsTF:true});
    }
    else {
      const fundingRequestObj = this.props.BudgetMobx.c_budgetTodoOpenFundingRequestObj;
      this.props.BudgetMobx.a_budget_update_funding_request_status(fundingRequestObj, 2, bmNotes, this.onclick_close_open_funding_request);
    }
  }

  onclick_close_open_funding_request = () => {
    this.props.BudgetMobx.a_budget_todo_set_open_funding_request_id(undefined);

    this.setState({
      s_enteringRejectionMessageTF: false,
      s_bmNotes: "",
      s_showErrorsTF: false
    });
  }

  render() {
    const enteringRejectionMessageTF = this.state.s_enteringRejectionMessageTF;
    const bmNotes = this.state.s_bmNotes;
    const showErrorsTF = this.state.s_showErrorsTF;

    const fundingRequestObj = this.props.BudgetMobx.c_budgetTodoOpenFundingRequestObj;

    if(fundingRequestObj === undefined) {
      return(null);
    }

    const budgetManagerCanApproveRejectTF = JSFUNC.in_array(fundingRequestObj.nr0_rq1_rj2_rs3_ap4, [1, 3]);

    const captureFullName = this.props.DatabaseMobx.capture_name_plaintext_from_capture_id(fundingRequestObj.capture_id);

    const expensesArrayOfObjs = fundingRequestObj.expensesArrayOfObjs;
    const fundingRequestLabelColorObj = this.props.BudgetMobx.funding_request_label_and_color_obj_from_status_number(fundingRequestObj.nr0_rq1_rj2_rs3_ap4);

    const requestedDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(fundingRequestObj.rq_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const rejectedDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(fundingRequestObj.rj_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const resubmittedDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(fundingRequestObj.rs_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const approvalDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(fundingRequestObj.ap_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);

    const expenseTotalMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(fundingRequestObj.totalExpenses, this.props.DatabaseMobx.c_genericMoneyFieldTypeObj);

    const bmNotesErrorTF = (showErrorsTF && !JSFUNC.text_or_number_is_filled_out_tf(bmNotes));

    const topLabelWidths = "9em";
    const topLabelFontClass = "fontItalic fontTextLighter";

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="budgetFundingRequest"
        p_title="Reviewing Funding Request"
        f_onClickCancel={this.onclick_close_open_funding_request}>
        <div className="flex11a yScroll smallFullPad">
          <div className="displayFlexRowVc">
            <div className="flex00a" style={{flexBasis:topLabelWidths}}>
              <font className={topLabelFontClass}>
                {"Capture:"}
              </font>
            </div>
            <div className="flex11a">
              {captureFullName}
            </div>
          </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:topLabelWidths}}>
              <font className={topLabelFontClass}>
                {"Budget Category:"}
              </font>
            </div>
            <div className="flex11a">
              {fundingRequestObj.budgetCategoryName}
            </div>
          </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:topLabelWidths}}>
              <font className={topLabelFontClass}>
                {"Status:"}
              </font>
            </div>
            <div className="flex00a displayFlexColumnVc" style={{flexBasis:"1.5em"}}>
              <div className="border bevelBorderColors" style={{height:"1.1em", width:"0.9em", background:"#" + fundingRequestLabelColorObj.color}} />
            </div>
            <div className="flex11a">
              <font className="fontBold fontItalic fontTextLighter">
                {fundingRequestLabelColorObj.label}
              </font>
            </div>
          </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:topLabelWidths}}>
              <font className={topLabelFontClass}>
                {"Requested:"}
              </font>
            </div>
            <div className="flex00a">
              {requestedDateMaskSortIfoObj.valueMask}
            </div>
            <div className="flex11a lrMedPad">
              {(JSFUNC.select_int_is_filled_out_tf(fundingRequestObj.rq_user_id)) &&
                <font className="fontItalic fontTextLight">
                  {"by "}
                  {this.props.DatabaseMobx.user_name_mask_from_user_id(fundingRequestObj.rq_user_id)}
                </font>
              }
            </div>
          </div>
          {(rejectedDateMaskSortIfoObj.isFilledOutTF) &&
            <div className="displayFlexRowVc smallTopMargin">
              <div className="flex00a" style={{flexBasis:topLabelWidths}}>
                <font className={topLabelFontClass}>
                  {"Rejected:"}
                </font>
              </div>
              <div className="flex00a">
                {rejectedDateMaskSortIfoObj.valueMask}
              </div>
              <div className="flex11a lrMedPad">
                {(JSFUNC.select_int_is_filled_out_tf(fundingRequestObj.rj_user_id)) &&
                  <font className="fontItalic fontTextLight">
                    {"by "}
                    {this.props.DatabaseMobx.user_name_mask_from_user_id(fundingRequestObj.rj_user_id)}
                  </font>
                }
              </div>
            </div>
          }
          {(resubmittedDateMaskSortIfoObj.isFilledOutTF) &&
            <div className="displayFlexRowVc smallTopMargin">
              <div className="flex00a" style={{flexBasis:topLabelWidths}}>
                <font className={topLabelFontClass}>
                  {"Resubmitted:"}
                </font>
              </div>
              <div className="flex00a">
                {resubmittedDateMaskSortIfoObj.valueMask}
              </div>
              <div className="flex11a lrMedPad">
                {(JSFUNC.select_int_is_filled_out_tf(fundingRequestObj.rs_user_id)) &&
                  <font className="fontItalic fontTextLight">
                    {"by "}
                    {this.props.DatabaseMobx.user_name_mask_from_user_id(fundingRequestObj.rs_user_id)}
                  </font>
                }
              </div>
            </div>
          }
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:topLabelWidths}}>
              <font className={topLabelFontClass}>
                {"Approved:"}
              </font>
            </div>
            <div className="flex00a">
              {approvalDateMaskSortIfoObj.valueMask}
            </div>
            <div className="flex11a lrMedPad">
              {(JSFUNC.select_int_is_filled_out_tf(fundingRequestObj.ap_user_id)) &&
                <font className="fontItalic fontTextLight">
                  {"by "}
                  {this.props.DatabaseMobx.user_name_mask_from_user_id(fundingRequestObj.ap_user_id)}
                </font>
              }
            </div>
          </div>
          {(fundingRequestObj.cm_notes !== "") &&
            <div className="displayFlexRowVc smallTopMargin">
              <div className="flex00a" style={{flexBasis:"14em"}}>
                <font className={topLabelFontClass}>
                  {"Notes from Capture Manager:"}
                </font>
              </div>
              <div className="flex11a">
                {fundingRequestObj.cm_notes}
              </div>
            </div>
          }
          <div className="displayFlexColumnHcVc microTopMargin">
            <font className="font20 fontBold fontTextLighter">
              {expenseTotalMaskSortIfoObj.valueMask}
            </font>
          </div>
          <div className="displayFlexColumnHcVc smallTopMargin">
            <font className="font13 fontTextLighter">
              {"Requested from Estimated Expenses Entered Below"}
            </font>
          </div>
          <div className="medFullMargin">
            {expensesArrayOfObjs.map((m_expenseObj) =>
              <BudgetExpenseItemWithEdit
                key={m_expenseObj.id}
                p_expenseObj={m_expenseObj}
                p_label="Funding Request Expense Estimate"
                p_canEditDeleteTF={false}
              />
            )}
          </div>
        </div>
        {(budgetManagerCanApproveRejectTF) &&
          <div className="flex00a displayFlexRow tbPad borderT1ddd">
            {(enteringRejectionMessageTF) ? (
              <>
                <div className="flex11a lrPad">
                  <div className="microBottomMargin">
                    <font className="fontItalic fontTextLighter">
                      {"Enter below any guidance for resubmission or cancellation of this funding request"}
                    </font>
                  </div>
                  <div className="">
                    <LibraryReact.Textarea
                      p_value={bmNotes}
                      p_styleObj={{width:"100%", height:"5em"}}
                      p_tabIndex={1}
                      p_errorTF={bmNotesErrorTF}
                      f_onChange={this.onchange_bm_notes}
                    />
                  </div>
                  {(bmNotesErrorTF) &&
                    <CEGeneralReact.ErrorText
                      p_class="microTopMargin"
                      p_text="Enter a reason for rejecting this funding request"
                    />
                  }
                </div>
                <div className="flex00a displayFlexColumnHcVc lrPad">
                  <div className="tbPad">
                    <CEGeneralReact.CEButton
                      p_type="red"
                      p_text="Reject Funding Request"
                      p_tabIndex={2}
                      f_onClick={this.onclick_reject_funding_request_with_comment}
                    />
                  </div>
                  <div className="tbPad">
                    <CEGeneralReact.CEButton
                      p_type="gray"
                      p_text="Cancel"
                      p_tabIndex={3}
                      f_onClick={this.onclick_cancel_reject}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
                  <CEGeneralReact.ButtonWithConfirmBox
                    p_buttonType="blue"
                    p_buttonText={"Approve " + expenseTotalMaskSortIfoObj.valueMaskPlainText}
                    p_confirmType="confirm"
                    p_confirmMessage={"Confirm approval for " + expenseTotalMaskSortIfoObj.valueMaskPlainText + " for budget '" + fundingRequestObj.budgetCategoryName + "' on capture '" + captureFullName + "'?"}
                    f_onClickConfirm={this.onclick_approve_funding_request}
                  />
                </div>
                <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
                  <CEGeneralReact.CEButton
                    p_type="red"
                    p_text="Reject with Comments"
                    f_onClick={this.onclick_open_reject_edit_bm_notes}
                  />
                </div>
              </>
            )}
          </div>
        }
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));



export const BudgetAddExpenseButtonWithInlineAdd = inject("BudgetMobx", "DatabaseMobx")(observer(
class BudgetAddExpenseButtonWithInlineAdd extends Component { //props: p_buttonText, p_captureID, p_budgetCategoryID, p_fundingRequestID
  constructor(props) {
    super(props);
    this.state = {
      s_addingNewExpenseTF: false,
      s_newExpenseTypeID: -1,
      s_newExpenseValue: 0,
      s_newExpenseDescription: "",
      s_showErrorsTF: false
    };
  }

  onclick_open_add_new_expense_inline_edit = () => {
    this.setState({
      s_addingNewExpenseTF: true,
      s_newExpenseTypeID: -1,
      s_newExpenseValue: 0,
      s_newExpenseDescription: "",
      s_showErrorsTF: false
    });
  }

  onchange_new_expense_type = (i_newValue) => {
    this.setState({s_newExpenseTypeID:i_newValue});
  }

  onchange_new_expense_value = (i_newValue) => {
    this.setState({s_newExpenseValue:i_newValue});
  }

  onchange_new_expense_description = (i_newValue) => {
    this.setState({s_newExpenseDescription:i_newValue});
  }

  onclick_insert_new_expense = () => {
    const newExpenseTypeID = this.state.s_newExpenseTypeID;
    const newExpenseValue = this.state.s_newExpenseValue;
    const newExpenseDescription = this.state.s_newExpenseDescription;
    if(!JSFUNC.select_int_is_filled_out_tf(newExpenseTypeID) || !JSFUNC.text_or_number_is_filled_out_tf(newExpenseDescription)) {
      this.setState({s_showErrorsTF:true});
    }
    else {
      const captureID = this.props.p_captureID;
      const budgetCategoryID = this.props.p_budgetCategoryID;
      const fundingRequestID = this.props.p_fundingRequestID;
      this.props.BudgetMobx.a_budget_insert_new_expense(captureID, budgetCategoryID, fundingRequestID, newExpenseTypeID, newExpenseValue, newExpenseDescription);

      this.setState({s_addingNewExpenseTF:false});
    }
  }

  onclick_cancel_expense = () => {
    this.setState({s_addingNewExpenseTF:false});
  }

  render() {
    const addingNewExpenseTF = this.state.s_addingNewExpenseTF;
    const newExpenseTypeID = this.state.s_newExpenseTypeID;
    const newExpenseValue = this.state.s_newExpenseValue;
    const newExpenseDescription = this.state.s_newExpenseDescription;
    const showErrorsTF = this.state.s_showErrorsTF;

    const buttonText = this.props.p_buttonText;

    const expenseTypeErrorTF = (showErrorsTF && !JSFUNC.select_int_is_filled_out_tf(newExpenseTypeID));
    const expenseDescriptionErrorTF = (showErrorsTF && !JSFUNC.text_or_number_is_filled_out_tf(newExpenseDescription));

    if(addingNewExpenseTF) {
      return(
        <div className="bgCaptureItemEditing medFullPad">
          <div className="textCenter">
            <font className="font12 fontBold fontItalic fontTextLighter">
              {"Add a New Expense"}
            </font>
          </div>
          <div className="microBottomMargin">
            <font className="font11 fontItalic fontTextLighter">
              {"Expense Type"}
            </font>
          </div>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_selectBudgetExpenseTypeFieldTypeObj}
            p_valueRaw={newExpenseTypeID}
            p_tabIndex={1}
            p_errorTF={expenseTypeErrorTF}
            f_onChangeOrOnSelect={this.onchange_new_expense_type}
          />
          {(expenseTypeErrorTF) &&
            <CEGeneralReact.ErrorText p_class="microTopMargin" p_text="Select an Expense Type for this new expense" />
          }
          <div className="displayFlexRow smallTopMargin">
            <div className="flex00a" style={{flexBasis:"15em"}}>
              <div className="microBottomMargin">
                <font className="font11 fontItalic fontTextLighter">
                  {"Value"}
                </font>
              </div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericMoneyCentsFieldTypeObj}
                p_valueRaw={newExpenseValue}
                p_tabIndex={2}
                f_onChangeOrOnSelect={this.onchange_new_expense_value}
              />
            </div>
            <div className="flex11a" />
          </div>
          <div className="smallTopMargin">
            <font className="font11 fontItalic fontTextLighter">
              {"Expense Description"}
            </font>
          </div>
          <div className="microTopMargin">
            <LibraryReact.Textarea
              p_value={newExpenseDescription}
              p_styleObj={{height:"5em"}}
              p_tabIndex={3}
              p_errorTF={expenseDescriptionErrorTF}
              f_onChange={this.onchange_new_expense_description}
            />
          </div>
          {(expenseDescriptionErrorTF) &&
            <CEGeneralReact.ErrorText p_class="microTopMargin" p_text="Select an Expense Type for this new expense" />
          }
          <div className="displayFlexRowHcVc flexWrap smallTopMargin">
            <div className="flex00a lrMedMargin">
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text="Add Expense"
                p_tabIndex={4}
                f_onClick={this.onclick_insert_new_expense}
              />
            </div>
            <div className="flex00a lrMedMargin">
              <CEGeneralReact.CEButton
                p_type="gray"
                p_text="Cancel"
                p_tabIndex={5}
                f_onClick={this.onclick_cancel_expense}
              />
            </div>
          </div>
        </div>
      );
    }

    return(
      <div className="displayFlexColumnHcVc">
        <CEGeneralReact.CEButton
          p_type="blue"
          p_text={buttonText}
          f_onClick={this.onclick_open_add_new_expense_inline_edit}
        />
      </div>
    );
  }
}));




export const BudgetExpenseItemWithEdit = inject("BudgetMobx", "DatabaseMobx")(observer(
class BudgetExpenseItemWithEdit extends Component { //props: p_expenseObj, p_label, p_canEditDeleteTF
  constructor(props) {
    super(props);
    this.state = {
      s_editingExpenseTF: false
    };
  }

  onclick_open_expense_edit = () => {
    this.setState({s_editingExpenseTF:true});
  }

  onsave_expense_type = (i_newValue) => {
    this.props.BudgetMobx.a_budget_update_expense_field(this.props.p_expenseObj, "expense_type_id", i_newValue, "i");
  }

  onsave_expense_description = (i_newValue) => {
    this.props.BudgetMobx.a_budget_update_expense_field(this.props.p_expenseObj, "description", i_newValue, "s");
  }

  onsave_expense_value = (i_newValue) => {
    this.props.BudgetMobx.a_budget_update_expense_field(this.props.p_expenseObj, "value", i_newValue, "i");
  }

  onclick_confirm_delete_expense = () => {
    this.props.BudgetMobx.a_budget_delete_expense(this.props.p_expenseObj);
  }

  onclick_close_expense_edit = () => {
    this.setState({s_editingExpenseTF:false});
  }

  render() {
    const s_editingExpenseTF = this.state.s_editingExpenseTF;

    const p_expenseObj = this.props.p_expenseObj;
    const p_label = this.props.p_label;
    const p_canEditDeleteTF = this.props.p_canEditDeleteTF;

    const expenseDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_expenseObj.date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const expenseTypeMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_expenseObj.expense_type_id, this.props.DatabaseMobx.c_selectBudgetExpenseTypeFieldTypeObj);
    const expenseValueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_expenseObj.value, this.props.DatabaseMobx.c_genericMoneyFieldTypeObj);

    return(
      <>
        <div
          className="displayFlexRow border bevelBorderColors hoverLighterBlueGradient cursorPointer"
          style={{height:"3em"}}
          onClick={this.onclick_open_expense_edit}>
          <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:"8em"}}>
            <font className="fontItalic fontTextLight">
              {expenseDateMaskSortIfoObj.valueMask}
            </font>
          </div>
          <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}} title={expenseTypeMaskSortIfoObj.valueMaskPlainText}>
            <LibraryReact.MaxHeightWrap p_maxHeight="2.6em" p_fontClass="fontItalic fontTextLight">
              {expenseTypeMaskSortIfoObj.valueMask}
            </LibraryReact.MaxHeightWrap>
          </div>
          <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"200em"}} title={p_expenseObj.description}>
            <LibraryReact.MaxHeightWrap p_maxHeight="2.6em">
              {p_expenseObj.description}
            </LibraryReact.MaxHeightWrap>
          </div>
          <div className="flex00a displayFlexColumnVc lrPad textRight" style={{flexBasis:"8em"}}>
            <font className="">
              {expenseValueMaskSortIfoObj.valueMask}
            </font>
          </div>
          {(p_canEditDeleteTF) ? (
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
              <CEGeneralReact.DeleteMenu
                p_message={"Delete the following " + p_label + "?\n\nExpense Type: " + expenseTypeMaskSortIfoObj.valueMaskPlainText + "\nDescription: " + p_expenseObj.description + "\nValue: " + expenseValueMaskSortIfoObj.valueMaskPlainText}
                f_onDelete={this.onclick_confirm_delete_expense}
              />
            </div>
          ) : (
            <div className="flex00a" style={{flexBasis:"0.8em"}} />
          )}
        </div>
        {(s_editingExpenseTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="addEditDeleteTableEditItem"
            p_title={"Editing " + p_label}
            f_onClickSave={this.onclick_close_expense_edit}>
            <div className="flex11a yScroll smallFullPad">
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"budgetExpense" + p_expenseObj.id + "expenseType"}
                p_fieldDisplayName="Expense Type"
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectBudgetExpenseTypeFieldTypeObj}
                p_valueRaw={p_expenseObj.expense_type_id}
                p_valueIsEditableTFU={p_canEditDeleteTF}
                p_containerClass="smallBottomMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="10em"
                f_onSaveChanged={this.onsave_expense_type}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"budgetExpense" + p_expenseObj.id + "description"}
                p_fieldDisplayName="Description"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
                p_valueRaw={p_expenseObj.description}
                p_valueIsEditableTFU={p_canEditDeleteTF}
                p_containerClass="smallBottomMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="10em"
                p_floatingBoxTitle="Editing Expense Description"
                f_onSaveChanged={this.onsave_expense_description}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"budgetExpense" + p_expenseObj.id + "value"}
                p_fieldDisplayName="Value"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericMoneyFieldTypeObj}
                p_valueRaw={p_expenseObj.value}
                p_valueIsEditableTFU={p_canEditDeleteTF}
                p_containerClass="smallBottomMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="10em"
                f_onSaveChanged={this.onsave_expense_value}
              />
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));




//==================================================================================================================================


export function BudgetBarGraph(props) { //props: p_initialBudget, p_amountSpent, p_heightEm, p_fontSizeClass
  const initialBudget = JSFUNC.prop_value(props.p_initialBudget, 0);
  const amountSpent = JSFUNC.prop_value(props.p_amountSpent, 0);
  const heightEm = JSFUNC.prop_value(props.p_heightEm, 2.4);
  const fontSizeClass = JSFUNC.prop_value(props.p_fontSizeClass, "");

  const remainingBudget = initialBudget - amountSpent;
  const percentBudgetSpent = Math.round((amountSpent / initialBudget) * 100);

  var color2 = "#f7f7f7";
  var color2Percent = 100;
  var message = "No Funding Approved Yet";
  var fontClass = "fontTextLightester";
  if(initialBudget > 0) {
    if(remainingBudget >= 0) {
      color2 = "#beb";
      color2Percent = Math.round((remainingBudget / initialBudget) * 100);
      message = "Used " + percentBudgetSpent + "% of " + JSFUNC.money_short(initialBudget) + " Budget (" + JSFUNC.money_short(remainingBudget) + " Remaining)";
      fontClass = "";
    }
    else {
      color2 = "#ebb";
      color2Percent = Math.round((-1 * remainingBudget / initialBudget) * 100); //percent overspent
      message = "Used " + percentBudgetSpent + "% of " + JSFUNC.money_short(initialBudget) + " Budget (" + JSFUNC.money_short(-1 * remainingBudget) + " Over)";
      fontClass = "";
    }
  }

  return(
    <LibraryReact.TwoColorDiv
      p_bgColor="#fff"
      p_color2={color2}
      p_color2Percent={color2Percent}
      p_color2Direction={90}
      p_class="displayFlexRowVc border bevelBorderColors textCenter"
      p_styleObj={{height:heightEm + "em"}}>
      <LibraryReact.MaxHeightWrap p_maxHeight={heightEm + "em"} p_fontClass={fontSizeClass + " " + fontClass}>
        {message}
      </LibraryReact.MaxHeightWrap>
    </LibraryReact.TwoColorDiv>
  );
}
