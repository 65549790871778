//library
import * as JSFUNC from "../Library/JSFUNC.js";

import DatabaseMobx from './DatabaseMobx';
import UserMobx from './UserMobx';

import AdminMobx from '../CaptureExecReactMobxPairs/Admin/AdminMobx';
import BudgetMobx from '../CaptureExecReactMobxPairs/Budget/BudgetMobx';
import CaptureExecMobx from '../CaptureExecReactMobxPairs/CaptureExec/CaptureExecMobx';
import CapturesMobx from '../CaptureExecReactMobxPairs/Captures/CapturesMobx';
import ContactsMobx from '../CaptureExecReactMobxPairs/Contacts/ContactsMobx';
import DivexecMobx from '../CaptureExecReactMobxPairs/Divexec/DivexecMobx';
import OpenCaptureMobx from '../CaptureExecReactMobxPairs/OpenCapture/OpenCaptureMobx';
import TeammateContractsMobx from '../CaptureExecReactMobxPairs/TeammateContracts/TeammateContractsMobx';
import AdminIntegrationsMobx from "../CaptureExecReactMobxPairs/AdminIntegrations/AdminIntegrationsMobx.js";

//====================================================================================================================

export class ClassCallPhpScript {
  constructor(i_api, i_jsDescription) {
    //create the CallPhpScript fields in class memory needed for execute()
    this.jsDescription = i_jsDescription;
    this.relativePathToPhpFile = undefined;
    this.postVarNamesArray = [];
    this.postVarValuesArray = [];
    this.expectedPhpReturnVariableNamesArray = [];
    this.functionsOnSuccessArray = [];
    this.functionsOnErrorArray = [];
    this.functionsOnFinishArray = [];

    //set up post vars and return vars for each qpi type
    if(i_api === "login") {
      this.relativePathToPhpFile = "php/login.php"; //call login.php
    }
    else if(i_api === "single_capture_direct_access_link_bypass_login_load_all_data") {
      this.relativePathToPhpFile = "php/single_capture_direct_access_link_bypass_login_load_all_data.php"; //call single_capture_direct_access_link_bypass_login_load_all_data.php
    }
    else if(i_api === "sgt_stripe_api") {
      this.relativePathToPhpFile = "php/sgt_stripe_api.php"; //call sgt_stripe_api.php
    }
    else {
      this.relativePathToPhpFile = "php/database_api.php"; //call database_api.php
      this.add_post_var("i_api", i_api); //every database_api.php api starts with the post var "i_api"
      this.add_post_var("i_cc", UserMobx.o_userCompanyCode); //add the company code as a post var to ensure that it matches the session var
    }
  }

  //--------------------------------------------------------------------------------------------------------------------------

  add_post_var(i_postVarName, i_postVarValue) {
    this.postVarNamesArray.push(i_postVarName);
    this.postVarValuesArray.push(i_postVarValue);
  }

  add_return_vars(i_returnVarNameOrNamesArray) {
    const returnVarNamesArray = JSFUNC.convert_single_or_array_to_array(i_returnVarNameOrNamesArray);
    this.expectedPhpReturnVariableNamesArray = this.expectedPhpReturnVariableNamesArray.concat(returnVarNamesArray);
  }

  add_function(i_functionFlag, i_functionOrArrayOfFunctions, i_injectFunctionAsFirstArrayItemTF=false) {
    var functionsArrayName = undefined;
    if(i_functionFlag === "onSuccess") { functionsArrayName = "functionsOnSuccessArray"; }
    else if(i_functionFlag === "onError") { functionsArrayName = "functionsOnErrorArray"; }
    else if(i_functionFlag === "onFinish") { functionsArrayName = "functionsOnFinishArray"; }

    if(functionsArrayName !== undefined) {
      var arrayOfFunctions = [];
      if(JSFUNC.is_array(i_functionOrArrayOfFunctions)) { //input is array of functions
        for(let singleFunction of i_functionOrArrayOfFunctions) {
          if(JSFUNC.is_function(singleFunction)) {
            arrayOfFunctions.push(singleFunction);
          }
        }
      }
      else { //input is single function
        if(JSFUNC.is_function(i_functionOrArrayOfFunctions)) {
          arrayOfFunctions.push(i_functionOrArrayOfFunctions);
        }
      }

      if(arrayOfFunctions.length > 0) {
        if(i_injectFunctionAsFirstArrayItemTF) { //inject these function(s) as the first item to be executed first
          this[functionsArrayName] = arrayOfFunctions.concat(this[functionsArrayName]);
        }
        else { //add these function(s) to the end of the functions array to be executed last
          this[functionsArrayName] = this[functionsArrayName].concat(arrayOfFunctions);
        }
      }
    }
  }

  execute(i_devSystemFakeParseResponse=null) {
    //error checking for all variables to run the xhr on the php script
    var errorMessageArray = [];
    if(!JSFUNC.text_or_number_is_filled_out_tf(this.jsDescription)) { errorMessageArray.push("jsDescription undefined"); }
    if(!JSFUNC.text_or_number_is_filled_out_tf(this.relativePathToPhpFile)) { errorMessageArray.push("relativePathToPhpFile undefined"); }
    if(!JSFUNC.is_array(this.postVarNamesArray)) { errorMessageArray.push("postVarNamesArray is not an array"); }
    if(!JSFUNC.is_array(this.postVarValuesArray)) { errorMessageArray.push("postVarValuesArray is not an array"); }
    if(!JSFUNC.is_array(this.expectedPhpReturnVariableNamesArray)) { errorMessageArray.push("expectedPhpReturnVariableNamesArray is not an array"); }
    if(!JSFUNC.is_array(this.functionsOnSuccessArray)) { errorMessageArray.push("functionsOnSuccessArray is not an array"); }
    if(!JSFUNC.is_array(this.functionsOnErrorArray)) { errorMessageArray.push("functionsOnErrorArray is not an array"); }
    if(!JSFUNC.is_array(this.functionsOnFinishArray)) { errorMessageArray.push("functionsOnFinishArray is not an array"); }
    if(this.postVarNamesArray.length !== this.postVarValuesArray.length) { errorMessageArray.push("post var names/values arrays not the same length"); }
    if(errorMessageArray.length > 0) {
      this.record_formatted_php_script_z_error("a_xhr_php_call_from_php_script_obj phpScriptObj error checks", JSFUNC.print(errorMessageArray));
      return; //do not proceed with the php call if there is an issue with these variables
    }

    //turn on the loading animation
    CaptureExecMobx.a_set_php_file_is_currently_running_tf(true);

    const functionExecuteAllFunctions = (i_parseResponse) => {
      const parseSuccessfulTF = (i_parseResponse !== null);

      //if there was no error and some js code is to be run based on the returned values from php grouped together in the variable "parseResponse" obj (with properties named according to the phpScriptObj "expectedPhpReturnVariableNamesArray")
      if(parseSuccessfulTF) {
        for(let functionOnSuccess of this.functionsOnSuccessArray) {
          if(JSFUNC.is_function(functionOnSuccess)) {
            functionOnSuccess(i_parseResponse); //parseResponse is passed as the only input
          }
        }
      }

      //if there was an error and some js code is to be run because of it (setting a warning message, etc)
      if(!parseSuccessfulTF) {
        for(let functionOnError of this.functionsOnErrorArray) {
          if(JSFUNC.is_function(functionOnError)) {
            functionOnError(); //execute the function
          }
        }
      }

      //js code to be run regardless of whether the php code had an error
      for(let functionOnFinish of this.functionsOnFinishArray) {
        if(JSFUNC.is_function(functionOnFinish)) {
          functionOnFinish(); //execute the function
        }
      }

      //loading is turned off whether there was success or an error
      CaptureExecMobx.a_set_php_file_is_currently_running_tf(false);
    }

    //define the function to run when the php script has completed running (xhr onreadystatechange 4)
    const functionOnReadyState4ParseResponseAndExecuteFunctions = (i_xmlHttpRequest) => {
      //parse the response echoed from the called php script to determine if there was an error (php syntax error or controlled code error regarding inputs/outputs/database) or missing expected return variables (sends an error message to the database if there was an error)
      const parseResponse = this.a_xhr4_parse_fetch_response(i_xmlHttpRequest);

      //execute all success/error/finish functions after the response is received
      functionExecuteAllFunctions(parseResponse);
    }

    //call php script, then run all success/error/finish functions when the response is received
    if(CaptureExecMobx.o_isLocalhost3000SystemTF) { //in the localhost system, only execute the success/error/finish functions without calling any php, the fake constructed parseResponse to use has been passed as input to this execute() function
      functionExecuteAllFunctions(i_devSystemFakeParseResponse);
    }
    else { //call the php script sending the post var names/values through form data with an onreadystate4 function to parse the response
      const returnsArrayBufferFileDataTF = false;
      JSFUNC.xmlhttprequest_send_form_data_post_to_php_script_with_response_function(this.relativePathToPhpFile, this.postVarNamesArray, this.postVarValuesArray, functionOnReadyState4ParseResponseAndExecuteFunctions, returnsArrayBufferFileDataTF);
    }
  }

  //---------------------------------------------------------------------------------------------------------------

  a_xhr4_parse_fetch_response(i_xmlHttpRequest) {
    //returns parseResponse obj containing all expectedPhpReturnVariableNames, null if there was an error in the response after recording the error in the z_errors tbl
    const xhrStatus = i_xmlHttpRequest.status;
    const xhrResponse = i_xmlHttpRequest.response;

    //xhr status errors
    if(xhrStatus !== 200) {
      var requestStatusError = "Unknown Error";
      if(xhrStatus === 301) { requestStatusError = "Permanently Moved"; }
      else if(xhrStatus === 304) { requestStatusError = "Not Modified"; }
      else if(xhrStatus === 401) { requestStatusError = "Unauthorized"; }
      else if(xhrStatus === 403) { requestStatusError = "Forbidden"; }
      else if(xhrStatus === 404) { requestStatusError = "Not Found"; }
      this.record_formatted_php_script_z_error("XHR Status", "XHR Status Error Code " + xhrStatus + ": " + requestStatusError);
      return(null);
    }

    //xhr response is blank
    if(!xhrResponse) {
      this.record_formatted_php_script_z_error("XHR No Response", "XHR response property is empty, undefined, null, or false");
      return(null);
    }

    //php fatal errors (uncontrolled errors not caught by intended code error handling) always start with "{" when returned from the XHR (errors like syntax errors, var/function doesn't exist, divide by 0, index out of bounds, etc)
    if(String(xhrResponse).charAt(0) !== "{") {
      this.record_formatted_php_script_z_error("PHP Fatal Error", xhrResponse);
      return(null);
    }

    //use JSON parse() method to parse the xhr response from php
    const parseResponse = JSON.parse(xhrResponse);

    //parse response is blank
    if(!parseResponse) {
      this.record_formatted_php_script_z_error("XHR Response Empty", "Parsed XHR response is empty, undefined, null, or false");
      return(null);
    }

    //SESSIONS seen as expired on the php side, no error message, but the system processes the logout on the JS side
    if(parseResponse.hasOwnProperty("logout")) {
      CaptureExecMobx.a_logout(parseResponse.logout); //call logout, which switches the CaptureExecMobx o_loginState to the intiial login screen and clears all local memory Maps
      return(null);
    }

    //json_error sent from php file code
    if(parseResponse.hasOwnProperty("error")) {
      this.record_formatted_php_script_z_error("PHP Controlled Error echo(json_error(Message))", parseResponse.error);
      return(null);
    }

    //check all variable names in the intended output of the JSON response to make sure they exist
    var missingRetVarNamesArray = [];
    for(let retVarName of this.expectedPhpReturnVariableNamesArray) {
      if(!parseResponse.hasOwnProperty(retVarName)) {
        missingRetVarNamesArray.push(retVarName);
      }
    }

    if(missingRetVarNamesArray.length > 0) {
      this.record_formatted_php_script_z_error("PHP Missing Expected Return Variables", "Parse Response missing expected return variables: " + JSFUNC.print(missingRetVarNamesArray));
      return(null);
    }

    //return the parsed response from php which is guaranteed to contain the expected return variables
    return(parseResponse);
  }


  record_formatted_php_script_z_error(i_errorType, i_errorMessage) {
    //build multi line error message detailing each variable in CallPhpScript and the input error type and message
    var phpVarsAndErrorMessage = "***XHR to call php file '" + this.relativePathToPhpFile + "'\r\n";
    phpVarsAndErrorMessage += "PHP var names/values:\r\n";
    for(let v = 0; v < this.postVarNamesArray.length; v++) {
      phpVarsAndErrorMessage += " - " + JSFUNC.print(this.postVarNamesArray[v]) + ": " + JSFUNC.print(this.postVarValuesArray[v]) + "\r\n";
    }
    phpVarsAndErrorMessage += "PHP ret names: " + JSFUNC.print(this.expectedPhpReturnVariableNamesArray) + "\r\n";
    phpVarsAndErrorMessage += "functionsOnSuccess: " + this.functionsOnSuccessArray.length + ", functionsOnError: " + this.functionsOnErrorArray.length + ", functionOnsFinish: " + this.functionsOnFinishArray.length + "\r\n";
    phpVarsAndErrorMessage += "---ERROR-(" + i_errorType + ")---\r\n";
    phpVarsAndErrorMessage += i_errorMessage;

    record_z_error(this.jsDescription, phpVarsAndErrorMessage);
  }
}




//***********************************************************************************************************************************************************************************
//***********************************************************************************************************************************************************************************
//***********************************************************************************************************************************************************************************
//***********************************************************************************************************************************************************************************




export class ClassCallPhpTblUID {
  constructor(i_jsDescription, i_onlyExecuteLocalChangesTF=false) {
    this.C_CallPhpScript = new ClassCallPhpScript("tblUID", i_jsDescription);

    this.jsDescription = i_jsDescription;
    this.onlyExecuteLocalChangesTF = i_onlyExecuteLocalChangesTF; //manual switch (usually false), when set to true, only run the operations on the local database tbls in memory then stop execution before calling any php xhr scripts (used to update local memory that trigger mobx computed values using that new data)

    //data for each UID operation in a format easier to make local js changes to in DatabaseMobx (arrays of field names and values)
    this.localUpdatesArrayOfObjs = [];
    this.localInsertsArrayOfObjs = [];
    this.localMultiInsertsArrayOfObjs = [];
    this.localDeletesArrayOfObjs = [];
    this.localExistingsArrayOfObjs = []; //update existing otherwise insert new
    this.localResortsArrayOfObjs = []; //shuffle the "sort" column in a given tbl, optional inputs to say a given id number was just dragged on top of another to reorder the dragged one on top of the ordered one, pushing all others down one, can also call this after a delete with no inputs to reshuffle the sort column accounting for the new deleted hole
    this.localAlterInsertsArrayOfObjs = [];
    this.localAlterDeletesArrayOfObjs = [];
    this.localCreateTblCapsArrayOfObjs = [];
    this.localDeleteTblCapsArrayOfObjs = [];

    //data for each UID operation converted to the format read by php (individual variables for "f0", "v0", "i0", "f1", etc)
    this.phpUpdatesArrayOfObjs = [];
    this.phpInsertsArrayOfObjs = [];
    this.phpMultiInsertsArrayOfObjs = [];
    this.phpDeletesArrayOfObjs = [];
    this.phpExistingsArrayOfObjs = [];
    this.phpResortsArrayOfObjs = [];
    this.phpAlterInsertsArrayOfObjs = [];
    this.phpAlterDeletesArrayOfObjs = [];
    this.phpCreateTblCapsArrayOfObjs = [];
    this.phpDeleteTblCapsArrayOfObjs = [];
    this.phpFileUploadsArrayOfObjs = []; //only php needed for file uploads, no local data update (inserts are used separately to update filefoldersystem entries regarding the new file)
    this.phpFileDeletesArrayOfObjs = []; //only php needed, file is unlinked from the server
    this.phpEmailsArrayOfObjs = []; //only php needed, email sent from php with no local data changed
  }

  //----------------------------------------------------------------
  get numUpdates() { return(this.phpUpdatesArrayOfObjs.length); }
  get numInserts() { return(this.phpInsertsArrayOfObjs.length); }
  get numMultiInserts() { return(this.phpMultiInsertsArrayOfObjs.length); }
  get numDeletes() { return(this.phpDeletesArrayOfObjs.length); }
  get numExistings() { return(this.phpExistingsArrayOfObjs.length); }
  get numResorts() { return(this.phpResortsArrayOfObjs.length); }
  get numAlterInserts() { return(this.phpAlterInsertsArrayOfObjs.length); }
  get numAlterDeletes() { return(this.phpAlterDeletesArrayOfObjs.length); }
  get numCreateTblCaps() { return(this.phpCreateTblCapsArrayOfObjs.length); }
  get numDeleteTblCaps() { return(this.phpDeleteTblCapsArrayOfObjs.length); }
  get numFileUploads() { return(this.phpFileUploadsArrayOfObjs.length); }
  get numFileDeletes() { return(this.phpFileDeletesArrayOfObjs.length); }
  get numEmails() { return(this.phpEmailsArrayOfObjs.length); }

  get totalNumOperations() {
    return(this.numUpdates + this.numInserts + this.numMultiInserts + this.numDeletes + this.numExistings + this.numResorts + this.numAlterInserts + this.numAlterDeletes + this.numCreateTblCaps + this.numDeleteTblCaps + this.numFileUploads + this.numFileDeletes + this.numEmails);
  }

  //----------------------------------------------------------------

  add_update(i_tblName, i_rowsToUpdateIDOrIDsArray, i_fieldNameOrFieldNamesArray, i_valueOrValuesArray, i_valueIdsbOrIdsbArray, i_oldValueOrValuesArray=undefined, i_differentLocalValueOrValuesArray=undefined) {
    const rowIDsToUpdateArray = JSFUNC.convert_single_or_array_to_array(i_rowsToUpdateIDOrIDsArray);
    if(rowIDsToUpdateArray.length === 0) {
      return; //do not add an update if there are no rowIDs to update
    }
    const rowIDsToUpdateComma = JSFUNC.convert_array_to_comma_list(rowIDsToUpdateArray);

    //only add fields if the new values do not match the old values
    const [fieldNamesArray, valuesArray, valuesIdsbArray, localValuesArray] = this.process_field_value_inputs_against_old_values(i_fieldNameOrFieldNamesArray, i_valueOrValuesArray, i_valueIdsbOrIdsbArray, i_oldValueOrValuesArray, i_differentLocalValueOrValuesArray);
    const numFields = fieldNamesArray.length;
    const numValues = valuesArray.length;
    const numIdsb = valuesIdsbArray.length;
    const numLocalValues = localValuesArray.length;
    if(numFields === 0 || numValues === 0 || numIdsb === 0 || numLocalValues === 0) {
      return; //do not add an update if there are no fields being updated
    }

    //create the local update obj
    this.localUpdatesArrayOfObjs.push({
      tblName: i_tblName,
      rowIDsComma: rowIDsToUpdateComma,
      rowIDsArray: rowIDsToUpdateArray,
      numFields: numFields,
      fieldNamesArray: fieldNamesArray,
      valuesArray: valuesArray,
      valuesIdsbArray: valuesIdsbArray,
      localValuesArray: localValuesArray
    });

    //create the php update obj
    const uPrefix = "u" + this.numUpdates;
    var updateObj = {};
    updateObj[uPrefix + "_tbl"] = i_tblName;
    updateObj[uPrefix + "_idc"] = rowIDsToUpdateComma;
    updateObj[uPrefix + "_nf"] = numFields;
    for(let f = 0; f < numFields; f++) {
      updateObj[uPrefix + "_f" + f] = fieldNamesArray[f];
      updateObj[uPrefix + "_v" + f] = valuesArray[f];
      updateObj[uPrefix + "_i" + f] = valuesIdsbArray[f];
    }

    this.phpUpdatesArrayOfObjs.push(updateObj);
  }


  add_insert(i_tblName, i_fieldNameOrFieldNamesArray, i_valueOrValuesArray, i_valueIdsbOrIdsbArray, i_resortSortColumnName=undefined, i_resortFilterFieldNameOrFieldNamesArray=undefined, i_resortFilterValueOrValuesArray=undefined) {
    const oldValueOrValuesArray = undefined;
    const differentLocalValueOrValuesArray = undefined;
    const [fieldNamesArray, valuesArray, valuesIdsbArray, localValuesArray] = this.process_field_value_inputs_against_old_values(i_fieldNameOrFieldNamesArray, i_valueOrValuesArray, i_valueIdsbOrIdsbArray, oldValueOrValuesArray, differentLocalValueOrValuesArray);
    const numFields = fieldNamesArray.length;
    const numValues = valuesArray.length;
    const numIdsb = valuesIdsbArray.length;
    if(numFields === 0 || numValues === 0 || numIdsb === 0) {
      return; //do not add an insert if there are no fields specified
    }

    //create the local insert obj
    this.localInsertsArrayOfObjs.push({
      tblName: i_tblName,
      numFields: numFields,
      fieldNamesArray: fieldNamesArray,
      valuesArray: valuesArray,
      valuesIdsbArray: valuesIdsbArray,
      resortSortColumnName: i_resortSortColumnName,
      resortFilterFieldNameOrFieldNamesArray: i_resortFilterFieldNameOrFieldNamesArray,
      resortFilterValueOrValuesArray: i_resortFilterValueOrValuesArray
    });

    //create the php insert obj
    const iPrefix = "i" + this.numInserts;
    var insertObj = {};
    insertObj[iPrefix + "_tbl"] = i_tblName;
    insertObj[iPrefix + "_nf"] = numFields;
    for(let f = 0; f < numFields; f++) {
      insertObj[iPrefix + "_f" + f] = fieldNamesArray[f];
      insertObj[iPrefix + "_v" + f] = valuesArray[f];
      insertObj[iPrefix + "_i" + f] = valuesIdsbArray[f];
    }

    //add php post vars for resorting the tbl if the 3 optional resort inputs are filled out
    if((i_resortSortColumnName !== undefined) && (i_resortFilterFieldNameOrFieldNamesArray !== undefined) && (i_resortFilterValueOrValuesArray !== undefined)) {
      const filterFieldNamesArray = JSFUNC.convert_single_or_array_to_array(i_resortFilterFieldNameOrFieldNamesArray);
      const filterValuesArray = JSFUNC.convert_single_or_array_to_array(i_resortFilterValueOrValuesArray);
      const numFilterFields = filterFieldNamesArray.length;
      insertObj[iPrefix + "_scn"] = i_resortSortColumnName;
      insertObj[iPrefix + "_nff"] = numFilterFields;
      for(let f = 0; f < numFilterFields; f++) {
        insertObj[iPrefix + "_ff" + f] = filterFieldNamesArray[f];
        insertObj[iPrefix + "_fv" + f] = filterValuesArray[f];
      }
    }

    this.phpInsertsArrayOfObjs.push(insertObj);
  }


  add_multi_insert(i_tblName, i_fieldNamesArray, i_valuesArrayOfArrays, i_valueIdsbArray, i_resortSortColumnName=undefined, i_resortFilterFieldNameOrFieldNamesArray=undefined, i_resortFilterValueOrValuesArray=undefined) {
    //insert 6 rows with the same preset_id of 64
    //
    //i_fieldNamesArray:      ["preset_id", "operator", "value"]
    //i_valuesArrayOfArrays:  [64, ["e","e","e","e","e","e"], ["1,2,3", "1", "", "4", "12,3", "1"]]
    //i_valueIdsbArray:       ["i", "s", "s"]
    //
    //mi0   mi0_tbl   mi0_nf = 3
    //mi0_f0 = "preset_id"  mi0_v0 = "64;64;64;64;64;64"  mi0_i0 = "i"
    //mi0_f1 = "operator"   mi0_v1 = "e;e;e;e;e;e"        mi0_i1 = "s"
    //mi0_f2 = "value"      mi0_v2 = "1,2,3;1;;4;12,3;1"  mi0_i2 = "s"

    const numFields = i_fieldNamesArray.length;
    const numValues = i_valuesArrayOfArrays.length;
    const numIdsb = i_valueIdsbArray.length;
    if(numFields === 0 || numValues === 0 || numIdsb === 0) {
      return; //do not add an insert if there are no fields specified
    }

    //expand all values single values into arrays the same length as other value arrays, if any found arrays are empty, return and stop the whole process (empty array of values signifies there are 0 rows to insert on this operation)
    var numRowsToMultiInsert = undefined;
    for(let f = 0; f < numFields; f++) { //use the number of fields to loop through the valuesArrayOfArrays, which must be the same length
      if(JSFUNC.is_array(i_valuesArrayOfArrays[f])) {
        numRowsToMultiInsert = i_valuesArrayOfArrays[f].length;
        if(numRowsToMultiInsert === 0) {
          return; //no need to do a multi insert operation on this data as it has empty values arrays meaning there are 0 rows to insert
        }
      }
    }

    //must have at least 1 array within in the i_valuesArrayOfArrays to do a multi insert
    if(numRowsToMultiInsert === undefined) {
      return;
    }

    //go again through the i_valuesArrayOfArrays now knowing the number of rows to insert and expand any non-array single values into arrays of the same length with the single value repeated
    var expandedValuesArrayOfArrays = []; //copy of the i_valuesArrayOfArrays but with single values expanded into repeated arrays (in the example, this expanded array becomes [[64,64,64,64,64,64], ["e","e","e","e","e","e"], ["1,2,3", "1", "", "4", "12,3", "1"]])
    for(let f = 0; f < numFields; f++) {
      if(JSFUNC.is_array(i_valuesArrayOfArrays[f])) { //copy valueArrays
        expandedValuesArrayOfArrays.push(i_valuesArrayOfArrays[f]);
      }
      else { //expand single values into arrays
        expandedValuesArrayOfArrays.push(JSFUNC.array_fill(numRowsToMultiInsert, i_valuesArrayOfArrays[f]));
      }
    }

    //create the local mutli insert obj
    this.localMultiInsertsArrayOfObjs.push({
      tblName: i_tblName,
      numFields: numFields,
      numRowsToMultiInsert: numRowsToMultiInsert,
      fieldNamesArray: i_fieldNamesArray,
      expandedValuesArrayOfArrays: expandedValuesArrayOfArrays,
      valuesIdsbArray: i_valueIdsbArray,
      resortSortColumnName: i_resortSortColumnName,
      resortFilterFieldNameOrFieldNamesArray: i_resortFilterFieldNameOrFieldNamesArray,
      resortFilterValueOrValuesArray: i_resortFilterValueOrValuesArray
    });

    //create the php insert obj
    const miPrefix = "mi" + this.numMultiInserts;
    var multiInsertObj = {};
    multiInsertObj[miPrefix + "_tbl"] = i_tblName;
    multiInsertObj[miPrefix + "_nf"] = numFields;
    for(let f = 0; f < numFields; f++) {
      multiInsertObj[miPrefix + "_f" + f] = i_fieldNamesArray[f];
      multiInsertObj[miPrefix + "_v" + f] = expandedValuesArrayOfArrays[f].join(";");
      multiInsertObj[miPrefix + "_i" + f] = i_valueIdsbArray[f];
    }

    //add php post vars for resorting the tbl if the 3 optional resort inputs are filled out
    if((i_resortSortColumnName !== undefined) && (i_resortFilterFieldNameOrFieldNamesArray !== undefined) && (i_resortFilterValueOrValuesArray !== undefined)) {
      const filterFieldNamesArray = JSFUNC.convert_single_or_array_to_array(i_resortFilterFieldNameOrFieldNamesArray);
      const filterValuesArray = JSFUNC.convert_single_or_array_to_array(i_resortFilterValueOrValuesArray);
      const numFilterFields = filterFieldNamesArray.length;
      multiInsertObj[miPrefix + "_scn"] = i_resortSortColumnName;
      multiInsertObj[miPrefix + "_nff"] = numFilterFields;
      for(let f = 0; f < numFilterFields; f++) {
        multiInsertObj[miPrefix + "_ff" + f] = filterFieldNamesArray[f];
        multiInsertObj[miPrefix + "_fv" + f] = filterValuesArray[f];
      }
    }

    this.phpMultiInsertsArrayOfObjs.push(multiInsertObj);
  }


  add_delete(i_tblName, i_rowIDsToDeleteIntOrArray, i_resortSortColumnName=undefined, i_resortFilterFieldNameOrFieldNamesArray=undefined, i_resortFilterValueOrValuesArray=undefined) {
    const rowIDsToDeleteArray = JSFUNC.convert_single_or_array_to_array(i_rowIDsToDeleteIntOrArray);
    if(rowIDsToDeleteArray.length === 0) {
      return; //do not add this operation if there are no rowIDs to delete
    }

    const rowIDsToDeleteComma = JSFUNC.convert_array_to_comma_list(rowIDsToDeleteArray);

    //create the local delete obj
    this.localDeletesArrayOfObjs.push({
      tblName: i_tblName,
      rowIDsComma: rowIDsToDeleteComma,
      rowIDsArray: rowIDsToDeleteArray,
      resortSortColumnName: i_resortSortColumnName,
      resortFilterFieldNameOrFieldNamesArray: i_resortFilterFieldNameOrFieldNamesArray,
      resortFilterValueOrValuesArray: i_resortFilterValueOrValuesArray
    });

    //create the php delete obj
    const dPrefix = "d" + this.numDeletes;
    var deleteObj = {};
    deleteObj[dPrefix + "_tbl"] = i_tblName;
    deleteObj[dPrefix + "_idc"] = rowIDsToDeleteComma;

    //add php post vars for resorting the tbl if the 3 optional resort inputs are filled out
    if(i_resortSortColumnName !== undefined && i_resortFilterFieldNameOrFieldNamesArray !== undefined && i_resortFilterValueOrValuesArray !== undefined) {
      const filterFieldNamesArray = JSFUNC.convert_single_or_array_to_array(i_resortFilterFieldNameOrFieldNamesArray);
      const filterValuesArray = JSFUNC.convert_single_or_array_to_array(i_resortFilterValueOrValuesArray);
      const numFilterFields = filterFieldNamesArray.length;
      deleteObj[dPrefix + "_scn"] = i_resortSortColumnName;
      deleteObj[dPrefix + "_nff"] = numFilterFields;
      for(let f = 0; f < numFilterFields; f++) {
        deleteObj[dPrefix + "_ff" + f] = filterFieldNamesArray[f];
        deleteObj[dPrefix + "_fv" + f] = filterValuesArray[f];
      }
    }

    this.phpDeletesArrayOfObjs.push(deleteObj);
  }


  //used for deal shaping answers (where there's only 1 answer matching a given capture_id/question_id), entry only inserted new when answered for the first time, otherwise the existing entry is updated when the answer is changed
  add_update_existing_otherwise_insert(i_tblName, i_whereFieldNameOrNamesArray, i_whereValueOrValuesArray, i_whereValueIdsbOrIdsbArray, i_fieldNameOrFieldNamesArray, i_valueOrValuesArray, i_valueIdsbOrIdsbArray, i_oldValueOrValuesArray=undefined, i_performUpdateIfExistsTF=true) {
    //the where fields should not be duplicated in the fields list, otherwise they would be removed checking the old vs new values and all fields are needed when doing an insert, the where fields are thus appended to the field list after checking the old/new values
    const whereFieldNamesArray = JSFUNC.convert_single_or_array_to_array(i_whereFieldNameOrNamesArray);
    const whereValuesArray = JSFUNC.convert_single_or_array_to_array(i_whereValueOrValuesArray);
    const whereValuesIdsbArray = JSFUNC.convert_single_or_array_to_array(i_whereValueIdsbOrIdsbArray);
    const numWhereFields = whereFieldNamesArray.length;
    const numWhereValues = whereValuesArray.length;
    const numWhereIdsb = whereValuesIdsbArray.length;

    //only add fields if the new values do not match the old values
    const differentLocalValueOrValuesArray = undefined;
    var [fieldNamesArray, valuesArray, valuesIdsbArray, localValuesArray] = this.process_field_value_inputs_against_old_values(i_fieldNameOrFieldNamesArray, i_valueOrValuesArray, i_valueIdsbOrIdsbArray, i_oldValueOrValuesArray, differentLocalValueOrValuesArray);
    const numInitialFields = fieldNamesArray.length;
    const numInitialValues = valuesArray.length;
    const numInitialIdsb = valuesIdsbArray.length;

    //do not add an update if there are no fields with changed data being updated or no where fields provided
    if(numInitialFields === 0 || numInitialValues === 0 || numInitialIdsb === 0 || numWhereFields === 0 || numWhereValues === 0 || numWhereIdsb === 0) {
      return;
    }

    //the where fields/values are merged into the provided fields/values to update or insert
    fieldNamesArray = fieldNamesArray.concat(whereFieldNamesArray);
    valuesArray = valuesArray.concat(whereValuesArray);
    valuesIdsbArray = valuesIdsbArray.concat(whereValuesIdsbArray);

    const numFields = fieldNamesArray.length;
    const numValues = valuesArray.length;
    const numIdsb = valuesIdsbArray.length;

    //create the local update obj
    this.localExistingsArrayOfObjs.push({
      tblName: i_tblName,
      numWhereFields: numWhereFields,
      whereFieldNamesArray: whereFieldNamesArray,
      whereValuesArray: whereValuesArray,
      whereValuesIdsbArray: whereValuesIdsbArray,
      numFields: numFields,
      fieldNamesArray: fieldNamesArray,
      valuesArray: valuesArray,
      valuesIdsbArray: valuesIdsbArray,
      performUpdateIfExistsTF: i_performUpdateIfExistsTF
    });

    //create the php update obj
    const ePrefix = "e" + this.numExistings;
    var existingObj = {};
    existingObj[ePrefix + "_tbl"] = i_tblName;
    existingObj[ePrefix + "_nwf"] = numWhereFields;
    for(let w = 0; w < numWhereFields; w++) {
      existingObj[ePrefix + "_wf" + w] = whereFieldNamesArray[w];
      existingObj[ePrefix + "_wv" + w] = whereValuesArray[w];
      existingObj[ePrefix + "_wi" + w] = whereValuesIdsbArray[w];
    }
    existingObj[ePrefix + "_nf"] = numFields;
    for(let f = 0; f < numFields; f++) {
      existingObj[ePrefix + "_f" + f] = fieldNamesArray[f];
      existingObj[ePrefix + "_v" + f] = valuesArray[f];
      existingObj[ePrefix + "_i" + f] = valuesIdsbArray[f];
    }
    existingObj[ePrefix + "_puie01"] = ((i_performUpdateIfExistsTF) ? (1) : (0));

    this.phpExistingsArrayOfObjs.push(existingObj);
  }


  add_resort(i_tblName, i_sortColumnName, i_filterFieldNamesArray, i_filterValuesArray, i_draggedItemID=-1, i_droppedOnItemID=-1) {
    //if draggedItemID > 0 and droppedOnItemID > 0, the dragged item is resorted in the list to be above the dropped item
    //if draggedItemID > 0 and droppedOnItemID = -1, the dragged item is resorted to the last item in the list
    //if draggedItemID = -1 and droppedOnItemID = -1, reshuffle all the sorts in the list so that sort is 1-N, but no relative item positions were changed
    const filterFieldNamesArray = JSFUNC.convert_single_or_array_to_array(i_filterFieldNamesArray);
    const filterValuesArray = JSFUNC.convert_single_or_array_to_array(i_filterValuesArray);
    const numFilterFields = filterFieldNamesArray.length;

    //create the local resort obj
    this.localResortsArrayOfObjs.push({
      tblName: i_tblName,
      sortColumnName: i_sortColumnName,
      numFilterFields: numFilterFields,
      filterFieldNamesArray: filterFieldNamesArray,
      filterValuesArray: filterValuesArray,
      draggedItemID: i_draggedItemID,
      droppedOnItemID: i_droppedOnItemID
    });

    //create the php resort obj
    const rPrefix = "r" + this.numResorts;
    var resortObj = {};
    resortObj[rPrefix + "_tbl"] = i_tblName;
    resortObj[rPrefix + "_scn"] = i_sortColumnName;
    resortObj[rPrefix + "_drag"] = i_draggedItemID;
    resortObj[rPrefix + "_drop"] = i_droppedOnItemID;
    resortObj[rPrefix + "_nff"] = numFilterFields;
    for(let f = 0; f < numFilterFields; f++) {
      resortObj[rPrefix + "_ff" + f] = filterFieldNamesArray[f];
      resortObj[rPrefix + "_fv" + f] = filterValuesArray[f];
    }

    this.phpResortsArrayOfObjs.push(resortObj);
  }


  add_alter_db_tbl_insert_column(i_tblName, i_columnDbName, i_mysqlColumnDataType, i_blankValue, i_initialValueForAllExistingRows=undefined) {
    //create the local alter insert obj
    this.localAlterInsertsArrayOfObjs.push({
      tblName: i_tblName,
      columnDbName: i_columnDbName,
      blankValue: i_blankValue,
      initialValueForAllExistingRows: i_initialValueForAllExistingRows
    });

    //create the php alter insert obj
    const aiPrefix = "ai" + this.numAlterInserts;
    var alterInsertObj = {};
    alterInsertObj[aiPrefix + "_tbl"] = i_tblName;
    alterInsertObj[aiPrefix + "_cn"] = i_columnDbName;
    alterInsertObj[aiPrefix + "_dt"] = i_mysqlColumnDataType;
    if(i_initialValueForAllExistingRows !== undefined) {
      alterInsertObj[aiPrefix + "_iv"] = i_initialValueForAllExistingRows;
    }

    this.phpAlterInsertsArrayOfObjs.push(alterInsertObj);
  }


  add_alter_db_tbl_delete_column(i_tblName, i_columnDbName) {
    //create the local alter delete obj
    this.localAlterDeletesArrayOfObjs.push({
      tblName: i_tblName,
      columnDbName: i_columnDbName
    });

    //create the php alter delete obj
    const adPrefix = "ad" + this.numAlterDeletes;
    var alterDeleteObj = {};
    alterDeleteObj[adPrefix + "_tbl"] = i_tblName;
    alterDeleteObj[adPrefix + "_cn"] = i_columnDbName;

    this.phpAlterDeletesArrayOfObjs.push(alterDeleteObj);
  }


  add_db_create_tbl_cap_table(i_tblCapName, i_hasSortTF) {
    //create the local create tbl cap obj
    this.localCreateTblCapsArrayOfObjs.push({
      tblCapName: i_tblCapName,
      hasSortTF: i_hasSortTF
    });

    //create the php create tbl cap obj
    const ctcPrefix = "ctc" + this.numCreateTblCaps;
    var createTblCapObj = {};
    createTblCapObj[ctcPrefix + "_tbl"] = i_tblCapName;
    createTblCapObj[ctcPrefix + "_n0s1"] = ((i_hasSortTF) ? (1) : (0));

    this.phpCreateTblCapsArrayOfObjs.push(createTblCapObj);
  }


  add_db_delete_tbl_cap_table(i_tblCapName) {
    //create the local delete tbl cap obj
    this.localDeleteTblCapsArrayOfObjs.push({
      tblCapName: i_tblCapName
    });

    //create the php delete tbl cap obj
    const dtcPrefix = "dtc" + this.numDeleteTblCaps;
    var deleteTblCapObj = {};
    deleteTblCapObj[dtcPrefix + "_tbl"] = i_tblCapName;

    this.phpDeleteTblCapsArrayOfObjs.push(deleteTblCapObj);
  }


  add_file_upload(i_uploadedFileData, i_foldersPathFromCompanyUploadsFolder, i_fileNameAndExtLowerCaseWithUnderscores) {
    //create the php file upload obj
    const fuPrefix = "fu" + this.numFileUploads;
    var fileUploadObj = {};
    fileUploadObj[fuPrefix + "_file"] = i_uploadedFileData;
    fileUploadObj[fuPrefix + "_fldr"] = i_foldersPathFromCompanyUploadsFolder;
    fileUploadObj[fuPrefix + "_fne"] = i_fileNameAndExtLowerCaseWithUnderscores;
    this.phpFileUploadsArrayOfObjs.push(fileUploadObj);
  }


  add_file_delete(i_fileLocFromCompanyUploadsFolder) {
    //create the php file delete obj
    const fdPrefix = "fd" + this.numFileDeletes;
    var fileDeleteObj = {};
    fileDeleteObj[fdPrefix + "_loc"] = i_fileLocFromCompanyUploadsFolder;
    this.phpFileDeletesArrayOfObjs.push(fileDeleteObj);
  }


  add_email(i_toEmailsComma, i_ccEmailsComma, i_subject, i_bodyHtml, i_fromEmail="", i_fromFullName="") {
    const bodyHtmlSlashNRtoBrTags = JSFUNC.string_replace_new_lines_with_br_tags(i_bodyHtml);

    const fromEmailValidUndefinedOrErrorMessage = JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(i_fromEmail);
    var fromEmail = CaptureExecMobx.c_productStylingObj.productAutomatedEmailFromEmailAddress;
    var fromFullName = CaptureExecMobx.c_productStylingObj.productName + " Automated Email";
    if(fromEmailValidUndefinedOrErrorMessage === undefined) { //if the provided from email is a valid email format
      const atSignSplitArray = i_fromEmail.split("@");
      if(atSignSplitArray.length === 2) {
        const fromEmailDomain = atSignSplitArray[1]; //the domain of the provided from email after "@"
        if(JSFUNC.in_array(fromEmailDomain, DatabaseMobx.c_bitCompanyDomainsDotExtsArray)) {
          fromEmail = i_fromEmail; //use the provided input email if it is valid and matches one of the company domains
          if(i_fromFullName === "") {
            fromFullName = i_fromEmail;
          }
          else {
            fromFullName = i_fromFullName;
          }
        }
      }
    }

    //create the php email obj
    const emPrefix = "em" + this.numEmails;
    var emailObj = {};
    emailObj[emPrefix + "_to"] = i_toEmailsComma;
    emailObj[emPrefix + "_cc"] = i_ccEmailsComma;
    emailObj[emPrefix + "_s"] = i_subject;
    emailObj[emPrefix + "_b"] = bodyHtmlSlashNRtoBrTags;
    emailObj[emPrefix + "_fe"] = fromEmail; //if not filled out, sends from
    emailObj[emPrefix + "_ffn"] = fromFullName;
    this.phpEmailsArrayOfObjs.push(emailObj);
  }


  //----------------------------------------------------------------

  add_notifications(i_toUserOrUserPerEmailIDOrIDsArrayOrComma, i_userPerEmailTrueUserFalse, i_notificationMessage, i_notificationClickActionString) {
    var toUserOrUserPerEmailIDsArray = [];
    if(JSFUNC.is_string(i_toUserOrUserPerEmailIDOrIDsArrayOrComma)) { //comma list of user/upe ids string
      toUserOrUserPerEmailIDsArray = JSFUNC.convert_comma_list_to_int_array(i_toUserOrUserPerEmailIDOrIDsArrayOrComma);
    }
    else if(JSFUNC.is_number_not_nan_gte_0(i_toUserOrUserPerEmailIDOrIDsArrayOrComma)) { //single user/upe id int
      toUserOrUserPerEmailIDsArray = [i_toUserOrUserPerEmailIDOrIDsArrayOrComma];
    }
    else if(JSFUNC.is_array(i_toUserOrUserPerEmailIDOrIDsArrayOrComma)) { //array of ids
      toUserOrUserPerEmailIDsArray = i_toUserOrUserPerEmailIDOrIDsArrayOrComma;
    }

    if(toUserOrUserPerEmailIDsArray.length === 0) {
      return;
    }

    var userPerEmailIDsArray = [];
    if(i_userPerEmailTrueUserFalse) { //input i_toUserOrUserPerEmailIDOrIDsArrayOrComma has userPerEmailIDs
      userPerEmailIDsArray = toUserOrUserPerEmailIDsArray;
    }
    else { //input i_toUserOrUserPerEmailIDOrIDsArrayOrComma has userIDs that need to be looked up to get each user's userPerEmailID
      for(let userID of toUserOrUserPerEmailIDsArray) {
        var combinedUserMap = DatabaseMobx.c_tbl_a_users.get(userID);
        if(combinedUserMap !== undefined) { //verify that each userID exists as a valid user in the system
          userPerEmailIDsArray.push(combinedUserMap.get("user_per_email_id"));
        }
      }
    }

    //ensure the userPerEmailIDs to send to are unique (in case the input was userIDs that pointed to the same userPerEmailID)
    userPerEmailIDsArray = JSFUNC.unique(userPerEmailIDsArray);

    //loop over each provided userPerEmailID and send them notifications
    for(let userPerEmailID of userPerEmailIDsArray) {
      var userPerEmailMap = DatabaseMobx.o_tbl_a_users_per_email.get(userPerEmailID);
      if(userPerEmailMap !== undefined) { //verify that each userPerEmailID exists as a valid userPerEmail in the system
        //insert the notification record
        const fieldNamesArray = ["user_per_email_id", "date", "message", "click_action", "read_01"];
        const valuesArray = [userPerEmailID, JSFUNC.now_date(), i_notificationMessage, i_notificationClickActionString, 0];
        const valuesIdsbArray = ["i", "s", "s", "s", "i"];
        this.add_insert("tbl_u_notifications", fieldNamesArray, valuesArray, valuesIdsbArray);

        //send the email based on this user's preference setting
        var sendEmailTF = (userPerEmailMap.get("notification_email_01") === 1);
        if(sendEmailTF) {
          var userEmailAddress = userPerEmailMap.get("email");
          var notificationEmailSubject = CaptureExecMobx.c_productStylingObj.productName + " Notification";
          var notificationEmailBody = i_notificationMessage;
          this.add_email(userEmailAddress, "", notificationEmailSubject, notificationEmailBody);
        }
      }
    }
  }

  add_changelog_admin(i_adminActionCodeIntOrFieldString, i_newValueString) {
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    const nowDateTimeUtc = JSFUNC.now_datetime_utc();

    var adminActionCode = 0;
    var fieldString = i_adminActionCodeIntOrFieldString; //input is custom field string
    if(JSFUNC.is_number_not_nan_gt_0(i_adminActionCodeIntOrFieldString)) { //input is an admin action code number, which have standard field strings that are not injected into the changelog
      const expandedActionCodeObj = AdminMobx.admin_changelog_compute_expanded_action_code_obj_from_action_code(i_adminActionCodeIntOrFieldString);
      adminActionCode = i_adminActionCodeIntOrFieldString;
      fieldString = expandedActionCodeObj.categoryDescription;
    }

    const fieldNamesArray = ["datetime_utc", "user_id", "user", "admin_action_code", "field", "value"];
    const valuesArray = [nowDateTimeUtc, o_userID, c_userName, adminActionCode, fieldString, i_newValueString];
    const valuesIdsbArray = ["s", "i", "s", "i", "s", "s"];
    this.add_insert("tbl_a_log_admin", fieldNamesArray, valuesArray, valuesIdsbArray);
  }

  add_changelog_budget(i_captureID, i_fundingRequestIdOrExpenseMinus1, i_fieldString, i_newValue, i_updateCaptureLastChangedDateToTodayTF=true) {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    if(c_productStylingObj.openCaptureChangelogRecordChangesTF) {
      const fieldNamesArray = ["capture_id", "funding_request_id__or_expm1", "datetime_utc", "user_id", "user", "field", "value"];
      const valuesArray = [i_captureID, i_fundingRequestIdOrExpenseMinus1, JSFUNC.now_datetime_utc(), o_userID, c_userName, i_fieldString, i_newValue];
      const valuesIdsbArray = ["i", "i", "s", "i", "s", "s", "s"];
      this.add_insert("tbl_c_log_budget", fieldNamesArray, valuesArray, valuesIdsbArray);
    }

    //update the capture's last_changed_date every time the changelog for teammate contracts is updated (from capture or contracts side)
    if(i_updateCaptureLastChangedDateToTodayTF) {
      this.add_update("tbl_captures", i_captureID, "last_changed_date", JSFUNC.now_date(), "s");
    }
  }

  add_changelog_details(i_captureID, i_cardID, i_fieldIDOrDbName, i_fieldString, i_newValueString, i_updateCaptureLastChangedDateToTodayTF=true) {
    //i_fieldIDOrDbName - use the fieldID for real tbl_captures_fields fields, for all other types of details log entries (teammate field changes, risks, comp, etc) use a special string 'dbName' like "usbca" and "workshare_number_of_ftes"
    //i_fieldString - this is the field display_name for normal tbl_captures_fields fields, more specific strings for other entries on cards
    //i_newValueString - a string of the valueMaskPlainText for the new value
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const c_fieldMapOfRFPDate = DatabaseMobx.c_fieldMapOfRFPDate;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    if(c_productStylingObj.openCaptureChangelogRecordChangesTF) {
      const fieldNamesArray = ["capture_id", "datetime_utc", "user_id", "user", "card_id", "field_id", "field", "value"];
      const valuesArray = [i_captureID, JSFUNC.now_datetime_utc(), o_userID, c_userName, i_cardID, i_fieldIDOrDbName, i_fieldString, i_newValueString];
      const valuesIdsbArray = ["i", "s", "i", "s", "i", "s", "s", "s"]; //details changelog column field_id is a string (varchar(191))
      this.add_insert("tbl_c_log_details", fieldNamesArray, valuesArray, valuesIdsbArray);
    }

    //update the capture's last_changed_date every time the changelog details is updated (specifically update last_rfp_date if this fieldID matches rfp_date (hardcoded field in system))
    if(i_updateCaptureLastChangedDateToTodayTF) {
      var captureFieldNamesArray = ["last_changed_date"];
      var captureValuesArray = [JSFUNC.now_date()];
      var captureValueIdsbArray = ["s"];
      if(i_fieldIDOrDbName === c_fieldMapOfRFPDate.get("id")) { //check if the field being updated is rfp_date, if so, update the last_rfp_date field
        captureFieldNamesArray.push("last_rfp_date");
        captureValuesArray.push(JSFUNC.now_date());
        captureValueIdsbArray.push("s");
      }
      this.add_update("tbl_captures", i_captureID, captureFieldNamesArray, captureValuesArray, captureValueIdsbArray);
    }
  }

  add_changelog_pwin(i_captureID, i_pwin, i_updateCaptureLastChangedAndLastPwinDatesToTodayTF=true) {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const c_companyPwinIsCalculatedTF = DatabaseMobx.c_companyPwinIsCalculatedTF;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    if(c_productStylingObj.openCaptureChangelogRecordChangesTF) {
      const roundedPwin = Math.round(i_pwin);
      const pwinIsCalc01 = ((c_companyPwinIsCalculatedTF) ? (1) : (0));
      const fieldNamesArray = ["capture_id", "datetime_utc", "user_id", "user", "pwin", "is_calc_01"];
      const valuesArray = [i_captureID, JSFUNC.now_datetime_utc(), o_userID, c_userName, roundedPwin, pwinIsCalc01];
      const valuesIdsbArray = ["i", "s", "i", "s", "i", "i"];
      this.add_insert("tbl_c_log_pwin", fieldNamesArray, valuesArray, valuesIdsbArray);
    }

    //update the capture's last_changed_date every time the changelog pwin is updated
    if(i_updateCaptureLastChangedAndLastPwinDatesToTodayTF) {
      this.add_update("tbl_captures", i_captureID, ["last_changed_date", "last_pwin_date"], [JSFUNC.now_date(), JSFUNC.now_date()], ["s", "s"]);
    }
  }

  add_changelog_shaping_initialize(i_captureID, i_initializeTextForQuestionText, i_initializeTextForAnswerText, i_totalProgress, i_updateCaptureLastChangedAndLastProgressDatesToTodayTF=true) {
    this.add_changelog_shaping(i_captureID, -3, i_initializeTextForQuestionText, -3, i_initializeTextForAnswerText, i_totalProgress, i_updateCaptureLastChangedAndLastProgressDatesToTodayTF); //-3 question and answer ids for initialization
  }

  add_changelog_shaping_select(i_captureID, i_questionID, i_answerID, i_totalProgress, i_updateCaptureLastChangedAndLastProgressDatesToTodayTF=true) {
    const questionMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shaping_questions_pool", i_questionID);
    const questionText = questionMap.get("name");
    const selectAnswerMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shaping_select_answers", i_answerID);
    const selectAnswerText = selectAnswerMap.get("name");
    this.add_changelog_shaping(i_captureID, i_questionID, questionText, i_answerID, selectAnswerText, i_totalProgress, i_updateCaptureLastChangedAndLastProgressDatesToTodayTF);
  }

  add_changelog_shaping_textarea(i_captureID, i_questionID, i_answerText, i_answerScore0to100, i_totalProgress, i_updateCaptureLastChangedAndLastProgressDatesToTodayTF=true) {
    const questionMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shaping_questions_pool", i_questionID);
    const questionText = questionMap.get("name");
    const textareaAnswerID = -2; //-2 is the flag used in the shaping changelog for answer_id to denote textarea answer types
    const answerScoreAndText = "[" + i_answerScore0to100 + "%] " + i_answerText;
    this.add_changelog_shaping(i_captureID, i_questionID, questionText, textareaAnswerID, answerScoreAndText, i_totalProgress, i_updateCaptureLastChangedAndLastProgressDatesToTodayTF);
  }

  add_changelog_shaping(i_captureID, i_questionID, i_questionText, i_answerID, i_answerText, i_totalProgress, i_updateCaptureLastChangedAndLastProgressDatesToTodayTF=true) {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    if(c_productStylingObj.openCaptureChangelogRecordChangesTF) {
      const roundedTotalProgress = Math.round(i_totalProgress);

      const fieldNamesArray = ["capture_id", "datetime_utc", "user_id", "user", "question_id", "question", "answer_id", "answer", "total_progress"];
      const valuesArray = [i_captureID, JSFUNC.now_datetime_utc(), o_userID, c_userName, i_questionID, i_questionText, i_answerID, i_answerText, roundedTotalProgress];
      const valuesIdsbArray = ["i", "s", "i", "s", "i", "s", "i", "s", "i"];
      this.add_insert("tbl_c_log_shaping", fieldNamesArray, valuesArray, valuesIdsbArray);
    }

    //update the capture's last_changed_date every time the changelog shaping is updated
    if(i_updateCaptureLastChangedAndLastProgressDatesToTodayTF) {
      this.add_update("tbl_captures", i_captureID, ["last_changed_date", "last_progress_date"], [JSFUNC.now_date(), JSFUNC.now_date()], ["s", "s"]);
    }
  }

  add_changelog_stages(i_captureID, i_stageID, i_updateCaptureLastChangedAndLastStageDatesToTodayTF=true) {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const c_fieldMapOfStage = DatabaseMobx.c_fieldMapOfStage;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    if(c_productStylingObj.openCaptureChangelogRecordChangesTF) {
      const stageName = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_stageID, c_fieldMapOfStage.get("fieldTypeObj"));

      const fieldNamesArray = ["capture_id", "datetime_utc", "user_id", "user", "stage_id", "stage_name"];
      const valuesArray = [i_captureID, JSFUNC.now_datetime_utc(), o_userID, c_userName, i_stageID, stageName];
      const valuesIdsbArray = ["i", "s", "i", "s", "i", "s"];
      this.add_insert("tbl_c_log_stages", fieldNamesArray, valuesArray, valuesIdsbArray);
    }

    //update the capture's last_changed_date every time the changelog stage is updated (prevent it for newly created captures to not affect Hot BITs)
    if(i_updateCaptureLastChangedAndLastStageDatesToTodayTF) {
      this.add_update("tbl_captures", i_captureID, ["last_changed_date", "last_stage_date"], [JSFUNC.now_date(), JSFUNC.now_date()], ["s", "s"]);
    }
  }

  add_changelog_teammate_contracts(i_captureID, i_teammateID, i_contractTypeID, i_fieldString, i_newValue, i_updateCaptureLastChangedDateToTodayTF=true) {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    if(c_productStylingObj.openCaptureChangelogRecordChangesTF) {
      const fieldNamesArray = ["capture_id", "teammate_id", "contract_type_id", "datetime_utc", "user_id", "user", "field", "value"];
      const valuesArray = [i_captureID, i_teammateID, i_contractTypeID, JSFUNC.now_datetime_utc(), o_userID, c_userName, i_fieldString, i_newValue];
      const valuesIdsbArray = ["i", "i", "i", "s", "i", "s", "s", "s"];
      this.add_insert("tbl_c_log_teammate_contracts", fieldNamesArray, valuesArray, valuesIdsbArray);
    }

    //update the capture's last_changed_date every time the changelog for teammate contracts is updated (from capture or contracts side)
    if(i_updateCaptureLastChangedDateToTodayTF) {
      this.add_update("tbl_captures", i_captureID, "last_changed_date", JSFUNC.now_date(), "s");
    }
  }

  add_all_new_capture_changelogs_from_new_capture_field_and_log_arrays_obj(i_newCaptureFieldAndLogArrayObj, i_newCaptureID, i_detailsChangelogCardID, i_shapingLogNewCaptureText) {
    const c_fieldMapOfCaptureID = DatabaseMobx.c_fieldMapOfCaptureID;

    //unpack input i_newCaptureFieldAndLogArrayObj
    const changelogInsertsArrayOfObjs = i_newCaptureFieldAndLogArrayObj.changelogInsertsArrayOfObjs;

    //since multiple changelog inserts are happening when a capture is inserted, do not call 'update last changed date' on any of them, all of the 'last' dates are initialized within the computation of i_newCaptureFieldAndLogArrayObj
    const updateCaptureLastChangedDateToTodayTF = false;

    //create a 0% initialization entry (using -3 as flags) in the shaping changelog
    this.add_changelog_shaping_initialize(i_newCaptureID, "--" + i_shapingLogNewCaptureText + "--", "--Initialize Shaping Progress for Progress Chart--", 0, updateCaptureLastChangedDateToTodayTF);

    //add the new captureID number changelog record first, then all fields that were filled out from the new capture form plus default values from the capture type
    const captureIDFieldID = c_fieldMapOfCaptureID.get("id");
    const captureIDFieldDisplayName = c_fieldMapOfCaptureID.get("display_name");
    this.add_changelog_details(i_newCaptureID, i_detailsChangelogCardID, captureIDFieldID, captureIDFieldDisplayName, i_newCaptureID, updateCaptureLastChangedDateToTodayTF);

    //loop through all other tbl_captures fields
    for(let changelogInsertObj of changelogInsertsArrayOfObjs) {
      if(changelogInsertObj.changelogStageTF) {
        this.add_changelog_stages(i_newCaptureID, changelogInsertObj.newValueRaw, updateCaptureLastChangedDateToTodayTF);
      }
      else if(changelogInsertObj.changelogPwinTF) {
        this.add_changelog_pwin(i_newCaptureID, changelogInsertObj.newValueRaw, updateCaptureLastChangedDateToTodayTF);
      }
      else if(changelogInsertObj.changelogDetailsTF || changelogInsertObj.changelogProgressTF) { //the shaping progress fields cannot be edited, and the shaping logs really record answers to deal shaping questions, put changes to total/stage progress in the details log if it ever happens
        this.add_changelog_details(i_newCaptureID, i_detailsChangelogCardID, changelogInsertObj.fieldID, changelogInsertObj.fieldDisplayName, changelogInsertObj.newValueMaskChangelogPlainText, updateCaptureLastChangedDateToTodayTF);
      }
    }
  }

  //----------------------------------------------------------------

  add_function(i_functionFlag, i_functionOrArrayOfFunctions, i_injectFunctionAsFirstArrayItemTF=false) { //"onSuccess", "onError", "onFinish"
    this.C_CallPhpScript.add_function(i_functionFlag, i_functionOrArrayOfFunctions, i_injectFunctionAsFirstArrayItemTF);
  }

  //----------------------------------------------------------------
  execute() {
    //set up the post vars expected by "tblUID" in database_api.php
    //set up the onSuccess function for "tblUID" to perform a variable number of update/insert/delete operations on both local memory (DatabaseMobx maps)
    //  - for each update:
    //      1. (live/dev) update local memory (gives instantaneous response onscreen when updating data don't have to wait for server)
    //      2. (live) call xhr to perform updates to the database
    //  - for each insert:
    //      1. (dev) insert into local memory with fake id numbers (max id found in tbl + 1), perform optional tbl resort if specified
    //      2. (live) call xhr to perform inserts to the database
    //      3. (live) wait for successful response to insert local memory (use php returned insert id number for the new local memory row so that the ids in local/db match), optional local memory tbl resort if specified
    //  - for each multi insert:
    //      1. (dev) multi insert into local memory with fake id numbers (max id found in tbl + 1-R)
    //      2. (live) call xhr to perform multi inserts to the database
    //      3. (live) wait for successful response to multi insert local memory (use php returned insert ids comma list for the new local memory rows so that the ids in local/db match)
    //  - for each delete:
    //      1. (dev) delete rows matching ids from local memory, perform optional tbl resort if specified
    //      2. (live) call xhr to perform deletes in the database
    //      3. (live) wait for successful response to delete local memory rows, optional local memory tbl resort if specified
    //  - for each existing:
    //      1. (live/dev) check local memory if the record matching the filter values exists, update if it exists, insert with a fakeID otherwise (ids for this table are not important since that data is loaded by the filter columns)
    //      2. (live) call xhr to perform updates/inserts to the database
    //  - for each resort:
    //      1. (live/dev) update local memory reshuffling the sort values in the tbl
    //      2. (live) call xhr to perform the same reshuffle in the database tbl
    //  - for each alter insert:
    //      1. (live/dev) update local memory inserting a new column of appropriate blank data (based on fieldInputType) for each row into the tbl
    //      2. (live) call xhr to perform the same database alter tbl insert column operation with the new column name and blank data
    //  - for each alter delete:
    //      1. (live/dev) update local memory deleting the column of data (assign all column values for all rows to undefined) in the tbl
    //      2. (live) call xhr to perform the same database alter tbl delete column operation on the specified column
    //  - for each create tbl cap:
    //      1. (live/dev) update local memory inserting a new tbl_cap table into the o_tbl_cap Map
    //      2. (live) call xhr to perform the same database create statement to make the new table
    //  - for each delete tbl cap:
    //      1. (live/dev) update local memory deleting tbl_cap table from the o_tbl_cap Map
    //      2. (live) call xhr to perform the same database drop table operation
    //  - for each file upload:
    //      1. (live) call xhr to write the file data to the server
    //  - for each file delete:
    //      1. (live) call xhr to delete the file from the server
    //  - for each email:
    //      1. (live) call xhr to send a php email
    //execute the php call using the CallPhpScript class

    //if there are no UID operations to perform, do not run execute to call php, this is considered a successful run, so call all success and finish functions
    if(this.totalNumOperations === 0) {
      //call all success functions
      const noOperationsFakeParseResponse = {outputObj: {}};
      for(let functionOnSuccess of this.C_CallPhpScript.functionsOnSuccessArray) {
        if(JSFUNC.is_function(functionOnSuccess)) {
          functionOnSuccess(noOperationsFakeParseResponse); //parseResponse is passed as the only input
        }
      }

      //call all finish functions
      for(let functionOnFinish of this.C_CallPhpScript.functionsOnFinishArray) {
        if(JSFUNC.is_function(functionOnFinish)) {
          functionOnFinish(); //execute the function
        }
      }

      return;
    }

    //combine all php operations into a single array, loop through all of them and add each one of their number of operations and specific operation post vars to the CallPhpScript class instance
    var allPhpOperationsArrayOfObjs = [];

    if(this.numUpdates > 0) {
      this.C_CallPhpScript.add_post_var("nu", this.numUpdates);
      for(let phpOperationObj of this.phpUpdatesArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numInserts > 0) {
      this.C_CallPhpScript.add_post_var("ni", this.numInserts);
      for(let phpOperationObj of this.phpInsertsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numMultiInserts > 0) {
      this.C_CallPhpScript.add_post_var("nmi", this.numMultiInserts);
      for(let phpOperationObj of this.phpMultiInsertsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numDeletes > 0) {
      this.C_CallPhpScript.add_post_var("nd", this.numDeletes);
      for(let phpOperationObj of this.phpDeletesArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numExistings > 0) {
      this.C_CallPhpScript.add_post_var("ne", this.numExistings);
      for(let phpOperationObj of this.phpExistingsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numResorts > 0) {
      this.C_CallPhpScript.add_post_var("nr", this.numResorts);
      for(let phpOperationObj of this.phpResortsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numAlterInserts > 0) {
      this.C_CallPhpScript.add_post_var("nai", this.numAlterInserts);
      for(let phpOperationObj of this.phpAlterInsertsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numAlterDeletes > 0) {
      this.C_CallPhpScript.add_post_var("nad", this.numAlterDeletes);
      for(let phpOperationObj of this.phpAlterDeletesArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numCreateTblCaps > 0) {
      this.C_CallPhpScript.add_post_var("nctc", this.numCreateTblCaps);
      for(let phpOperationObj of this.phpCreateTblCapsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numDeleteTblCaps > 0) {
      this.C_CallPhpScript.add_post_var("ndtc", this.numDeleteTblCaps);
      for(let phpOperationObj of this.phpDeleteTblCapsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numFileUploads > 0) {
      this.C_CallPhpScript.add_post_var("nfu", this.numFileUploads);
      for(let phpOperationObj of this.phpFileUploadsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numFileDeletes > 0) {
      this.C_CallPhpScript.add_post_var("nfd", this.numFileDeletes);
      for(let phpOperationObj of this.phpFileDeletesArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    if(this.numEmails > 0) {
      this.C_CallPhpScript.add_post_var("nem", this.numEmails);
      for(let phpOperationObj of this.phpEmailsArrayOfObjs) {
        allPhpOperationsArrayOfObjs.push(phpOperationObj);
      }
    }

    for(let phpOperationObj of allPhpOperationsArrayOfObjs) {
      for(var key in phpOperationObj) {
        if(phpOperationObj.hasOwnProperty(key)) {
          this.C_CallPhpScript.add_post_var(key, phpOperationObj[key]);
        }
      }
    }

    //add the expected return var names sent from the php file
    this.C_CallPhpScript.add_return_vars("outputObj");

    //(live and dev system) perform local memory update operations before calling the php database update so that updates in the system are instantaneous onscreen while the xhr is sending/working
    const clearOldMapDataFirstTF = false; //in all update and insert operations, the local data mapOfMaps will not be cleared before updating/inserting the new data rows
    for(let updateObj of this.localUpdatesArrayOfObjs) {
      var dataArrayOfObjs = JSFUNC.create_data_arrayOfObjs_from_idOrIdArray_fieldName_and_value_arrays(updateObj.rowIDsArray, updateObj.fieldNamesArray, updateObj.localValuesArray);
      DatabaseMobx.a_insert_or_update_local_data_map(updateObj.tblName, dataArrayOfObjs, clearOldMapDataFirstTF, this.jsDescription);
    }

    //(live and dev system) perform local memory existing operations (check if the record already exists, update if it does, insert with fakeID if it does not)
    for(let existingObj of this.localExistingsArrayOfObjs) {
      DatabaseMobx.a_update_local_data_mapOfMaps_if_existing_otherwise_insert_with_fake_id(existingObj.tblName, existingObj.whereFieldNamesArray, existingObj.whereValuesArray, existingObj.fieldNamesArray, existingObj.valuesArray, existingObj.performUpdateIfExistsTF, this.jsDescription);
    }

    //(live and dev system) perform local memory resort operations
    for(let resortObj of this.localResortsArrayOfObjs) {
      DatabaseMobx.a_resort_tbl(resortObj.tblName, resortObj.sortColumnName, resortObj.filterFieldNamesArray, resortObj.filterValuesArray, resortObj.draggedItemID, resortObj.droppedOnItemID, this.jsDescription);
    }

    //(live and dev system) perform local memory alter insert operations
    for(let alterInsertObj of this.localAlterInsertsArrayOfObjs) {
      var initialValue = ((alterInsertObj.initialValueForAllExistingRows !== undefined) ? (alterInsertObj.initialValueForAllExistingRows) : (alterInsertObj.blankValue));
      DatabaseMobx.a_local_alter_tbl_insert_column(alterInsertObj.tblName, alterInsertObj.columnDbName, initialValue, this.jsDescription);
    }

    //(live and dev system) perform local memory alter delete operations
    for(let alterDeleteObj of this.localAlterDeletesArrayOfObjs) {
      DatabaseMobx.a_local_alter_tbl_delete_column(alterDeleteObj.tblName, alterDeleteObj.columnDbName, this.jsDescription);
    }

    //(live and dev system) perform local memory create tbl cap operations
    for(let createTblCapObj of this.localCreateTblCapsArrayOfObjs) {
      DatabaseMobx.a_local_create_new_tbl_cap_table(createTblCapObj.tblCapName, createTblCapObj.hasSortTF, this.jsDescription);
    }

    //(live and dev system) perform local memory create tbl cap operations
    for(let deleteTblCapObj of this.localDeleteTblCapsArrayOfObjs) {
      DatabaseMobx.a_local_delete_tbl_cap_table(deleteTblCapObj.tblCapName, this.jsDescription);
    }

    //define the onSuccess function executed when the xhr parseResponse has been successfully received and verified and passed to this success function as an input containing all of the expectedPhpReturnVariableNamesArray
    const functionOnSuccessAllUID = (i_parseResponse) => {
       const outputObj = i_parseResponse.outputObj; //the expected output variable from php tblUID call containing output data for each UID operation

       var failedPrefixesArray = []; //fill this will prefix names that have unsuccessful or nonexistant output

       //verify all database update operations were successful with a "1" returned for each uPrefix
       for(let u = 0; u < this.numUpdates; u++) {
         var uPrefix = "u" + u;
         var outputUpdateSuccess01 = outputObj[uPrefix];
         if(outputUpdateSuccess01 !== "1") {
           failedPrefixesArray.push(uPrefix);
         }
       }

       //perform all local insert operations using the new id number given through the output
       for(let i = 0; i < this.numInserts; i++) {
         var iPrefix = "i" + i;
         var outputInsertRowID = outputObj[iPrefix];
         if(outputInsertRowID > 0) { //php insert successful, perform local insert using new output id number
           var insertObj = this.localInsertsArrayOfObjs[i];
           var dataArrayOfObjs = JSFUNC.create_data_arrayOfObjs_from_idOrIdArray_fieldName_and_value_arrays(outputInsertRowID, insertObj.fieldNamesArray, insertObj.valuesArray);
           DatabaseMobx.a_insert_or_update_local_data_map(insertObj.tblName, dataArrayOfObjs, clearOldMapDataFirstTF, this.jsDescription);

           if((insertObj.resortSortColumnName !== undefined) && (insertObj.resortFilterFieldNameOrFieldNamesArray !== undefined) && (insertObj.resortFilterValueOrValuesArray !== undefined)) {
             DatabaseMobx.a_resort_tbl(insertObj.tblName, insertObj.resortSortColumnName, insertObj.resortFilterFieldNameOrFieldNamesArray, insertObj.resortFilterValueOrValuesArray, -1, -1, this.jsDescription);
           }
         }
         else { //unsuccessful insert
           failedPrefixesArray.push(iPrefix);
         }
       }

       //perform all local multi insert operations using the new id numbers given in a comma list through the output
       for(let mi = 0; mi < this.numMultiInserts; mi++) {
         var miPrefix = "mi" + mi;
         var outputMultiInsertRowIDsComma = outputObj[miPrefix];
         var outputMultiInsertRowIDsArray = JSFUNC.convert_comma_list_to_int_array(outputMultiInsertRowIDsComma);
         var multiInsertObj = this.localMultiInsertsArrayOfObjs[mi];
         var dataArrayOfObjs = [];
         for(let r = 0; r < outputMultiInsertRowIDsArray.length; r++) {
           var outputMultiInsertRowID = outputMultiInsertRowIDsArray[r];
           if(outputMultiInsertRowID > 0) { //php multi insert for this row successful, perform local multi insert using new output id number
             var rowDataObj = {id:outputMultiInsertRowID};
             for(let f = 0; f < multiInsertObj.numFields; f++) {
               rowDataObj[multiInsertObj.fieldNamesArray[f]] = multiInsertObj.expandedValuesArrayOfArrays[f][r];
             }
             dataArrayOfObjs.push(rowDataObj);
           }
           else { //unsuccessful insert
             failedPrefixesArray.push(miPrefix + "_row" + r);
           }
         }
         DatabaseMobx.a_insert_or_update_local_data_map(multiInsertObj.tblName, dataArrayOfObjs, clearOldMapDataFirstTF, this.jsDescription);

         if((multiInsertObj.resortSortColumnName !== undefined) && (multiInsertObj.resortFilterFieldNameOrFieldNamesArray !== undefined) && (multiInsertObj.resortFilterValueOrValuesArray !== undefined)) {
           DatabaseMobx.a_resort_tbl(multiInsertObj.tblName, multiInsertObj.resortSortColumnName, multiInsertObj.resortFilterFieldNameOrFieldNamesArray, multiInsertObj.resortFilterValueOrValuesArray, -1, -1, this.jsDescription);
         }
       }

      //perform all local delete operations and verify the database operations were successful
      for(let d = 0; d < this.numDeletes; d++) {
        var dPrefix = "d" + d;
        var outputDeleteSuccess01 = outputObj[dPrefix];
        if(outputDeleteSuccess01 !== "1") { //php delete unsuccessful
          failedPrefixesArray.push(dPrefix);
        }
        else { //php delete successful, perform local delete (also option tbl resort if specified)
          var deleteObj = this.localDeletesArrayOfObjs[d];
          DatabaseMobx.a_delete_local_data_mapOfMaps_item_from_id_or_id_array(deleteObj.tblName, deleteObj.rowIDsArray, this.jsDescription);

          if((deleteObj.resortSortColumnName !== undefined) && (deleteObj.resortFilterFieldNameOrFieldNamesArray !== undefined) && (deleteObj.resortFilterValueOrValuesArray !== undefined)) {
            DatabaseMobx.a_resort_tbl(deleteObj.tblName, deleteObj.resortSortColumnName, deleteObj.resortFilterFieldNameOrFieldNamesArray, deleteObj.resortFilterValueOrValuesArray, -1, -1, this.jsDescription);
          }
        }
      }

      //verify all database existing operations were successful with a "1" returned for each ePrefix
      for(let e = 0; e < this.numExistings; e++) {
        var ePrefix = "e" + e;
        var outputExistingSuccess01 = outputObj[ePrefix];
        if(outputExistingSuccess01 !== "1") {
          failedPrefixesArray.push(ePrefix);
        }
      }

      //verify all database resort operations were successful with a "1" returned for each rPrefix
      for(let r = 0; r < this.numResorts; r++) {
        var rPrefix = "r" + r;
        var outputResortSuccess01 = outputObj[rPrefix];
        if(outputResortSuccess01 !== "1") {
          failedPrefixesArray.push(rPrefix);
        }
      }

      //verify all database alter insert operations were successful with a "1" returned for each aiPrefix
      for(let ai = 0; ai < this.numAlterInserts; ai++) {
        var aiPrefix = "ai" + ai;
        var outputAlterInsertSuccess01 = outputObj[aiPrefix];
        if(outputAlterInsertSuccess01 !== "1") {
          failedPrefixesArray.push(aiPrefix);
        }
      }

      //verify all database alter delete operations were successful with a "1" returned for each adPrefix
      for(let ad = 0; ad < this.numAlterDeletes; ad++) {
        var adPrefix = "ad" + ad;
        var outputAlterDeleteSuccess01 = outputObj[adPrefix];
        if(outputAlterDeleteSuccess01 !== "1") {
          failedPrefixesArray.push(adPrefix);
        }
      }

      //verify all database create tbl cap operations were successful with a "1" returned for each ctcPrefix
      for(let ctc = 0; ctc < this.numCreateTblCaps; ctc++) {
        var ctcPrefix = "ctc" + ctc;
        var outputCreateTblCapSuccess01 = outputObj[ctcPrefix];
        if(outputCreateTblCapSuccess01 !== "1") {
          failedPrefixesArray.push(ctcPrefix);
        }
      }

      //verify all database delete tbl cap operations were successful with a "1" returned for each dtcPrefix
      for(let dtc = 0; dtc < this.numDeleteTblCaps; dtc++) {
        var dtcPrefix = "dtc" + dtc;
        var outputDeleteTblCapSuccess01 = outputObj[dtcPrefix];
        if(outputDeleteTblCapSuccess01 !== "1") {
          failedPrefixesArray.push(dtcPrefix);
        }
      }

      //verify all database file upload operations were successful with a "1" returned for each fuPrefix
      for(let fu = 0; fu < this.numFileUploads; fu++) {
        var fuPrefix = "fu" + fu;
        var outputFileUploadSuccess01 = outputObj[fuPrefix];
        if(outputFileUploadSuccess01 !== "1") {
          failedPrefixesArray.push(fuPrefix);
        }
      }

      //verify all database file delete operations were successful with a "1" returned for each fdPrefix
      for(let fd = 0; fd < this.numFileDeletes; fd++) {
        var fdPrefix = "fd" + fd;
        var outputFileDeleteSuccess01 = outputObj[fdPrefix];
        if(outputFileDeleteSuccess01 !== "1") {
          failedPrefixesArray.push(fdPrefix);
        }
      }

      //verify all database email operations were successful with a "1" returned for each emPrefix
      for(let em = 0; em < this.numEmails; em++) {
        var emPrefix = "em" + em;
        var outputEmailSuccess01 = outputObj[emPrefix];
        if(outputEmailSuccess01 !== "1") {
          failedPrefixesArray.push(emPrefix);
        }
      }

      //send an error if any of the output from the prefixes were not successful
      if(failedPrefixesArray.length > 0) {
        this.C_CallPhpScript.record_formatted_php_script_z_error("TblUID outputObj failed some operations", "Failed Prefixes: " + failedPrefixesArray.join(", "));
      }
    }

    //inject the onSuccess function to the phpScriptObj class to be executed first before user defined functions
    const injectFunctionAsFirstArrayItemTF = true;
    this.C_CallPhpScript.add_function("onSuccess", functionOnSuccessAllUID, injectFunctionAsFirstArrayItemTF);

    //in the development system, run the local insert and delete operations by calling the functionOnSuccessAllUID() function above with a fake insertID for the inserts spoofing the i_parseResponse.outputObj
    var devSystemFakeParseResponse = null;
    if(CaptureExecMobx.o_isLocalhost3000SystemTF || this.onlyExecuteLocalChangesTF) {
      devSystemFakeParseResponse = {outputObj: {}};

      for(let x = 0; x < this.numUpdates; x++) { devSystemFakeParseResponse.outputObj["u" + x] = "1"; }
      for(let x = 0; x < this.numDeletes; x++) { devSystemFakeParseResponse.outputObj["d" + x] = "1"; }
      for(let x = 0; x < this.numExistings; x++) { devSystemFakeParseResponse.outputObj["e" + x] = "1"; }
      for(let x = 0; x < this.numResorts; x++) { devSystemFakeParseResponse.outputObj["r" + x] = "1"; }
      for(let x = 0; x < this.numAlterInserts; x++) { devSystemFakeParseResponse.outputObj["ai" + x] = "1"; }
      for(let x = 0; x < this.numAlterDeletes; x++) { devSystemFakeParseResponse.outputObj["ad" + x] = "1"; }
      for(let x = 0; x < this.numCreateTblCaps; x++) { devSystemFakeParseResponse.outputObj["ctc" + x] = "1"; }
      for(let x = 0; x < this.numDeleteTblCaps; x++) { devSystemFakeParseResponse.outputObj["dtc" + x] = "1"; }
      for(let x = 0; x < this.numFileUploads; x++) { devSystemFakeParseResponse.outputObj["fu" + x] = "1"; }
      for(let x = 0; x < this.numFileDeletes; x++) { devSystemFakeParseResponse.outputObj["fd" + x] = "1"; }
      for(let x = 0; x < this.numEmails; x++) { devSystemFakeParseResponse.outputObj["em" + x] = "1"; }

      //dev system local memory insert operations with fake id
      for(let i = 0; i < this.numInserts; i++) {
        var insertObj = this.localInsertsArrayOfObjs[i];
        var fakeLocalID = (DatabaseMobx.tbl_max_id_number(insertObj.tblName) + i + 1); //fakeLocalID is the highest id number currently in the tbl + 1 (also add i so that each insert is unique because the table does not change until later, so tbl_max() returns the same maxID value each loop)
        var iPrefix = "i" + i;
        devSystemFakeParseResponse.outputObj[iPrefix] = fakeLocalID;
      }

      //dev system local memory multi insert operations with fake ids in comma list
      var mir = 1; //fake increment to add to every item being multi inserted (per multi insert, per row inserted)
      for(let mi = 0; mi < this.numMultiInserts; mi++) {
        var multiInsertObj = this.localMultiInsertsArrayOfObjs[mi];
        var tblMaxID = DatabaseMobx.tbl_max_id_number(multiInsertObj.tblName);
        var fakeLocalIDsComma = "";
        for(let r = 0; r < multiInsertObj.numRowsToMultiInsert; r++) {
          if(r > 0) { fakeLocalIDsComma += ","; }
          fakeLocalIDsComma += (tblMaxID + mir);
          mir++;
        }
        var miPrefix = "mi" + mi;
        devSystemFakeParseResponse.outputObj[miPrefix] = fakeLocalIDsComma;
      }
    }

    //call the xhr php script using the class execute() method
    this.C_CallPhpScript.execute(devSystemFakeParseResponse);
  }

  //-------------------------------------------------------------------------------

  process_field_value_inputs_against_old_values(i_fieldNameOrFieldNamesArray, i_valueOrValuesArray, i_valueIdsbOrIdsbArray, i_oldValueOrValuesArray=undefined, i_differentLocalValueOrValuesArray=undefined) {
    //fieldNames/values/valuesIdsb can be singles (update 1 field) or arrays of the same length (update multiple fields)
    var fieldNamesArray = JSFUNC.convert_single_or_array_to_array(i_fieldNameOrFieldNamesArray);
    var valuesArray = JSFUNC.convert_single_or_array_to_array(i_valueOrValuesArray);
    var valuesIdsbArray = JSFUNC.convert_single_or_array_to_array(i_valueIdsbOrIdsbArray);

    //optional different local values can be provided, if not provided the local values match the database values which is standard (which are potentially filtered below by any matching old values)
    var localValuesArray = valuesArray;
    if(i_differentLocalValueOrValuesArray !== undefined) {
      localValuesArray = JSFUNC.convert_single_or_array_to_array(i_differentLocalValueOrValuesArray);
    }

    //optional old values array input only includes values to be updated if the new value does not match the old value, if all values match, no update is added
    if(i_oldValueOrValuesArray !== undefined) {
      const oldValuesArray = JSFUNC.convert_single_or_array_to_array(i_oldValueOrValuesArray);
      var nonMatchingFieldNamesArray = [];
      var nonMatchingValuesArray = [];
      var nonMatchingValuesIdsbArray = [];
      var nonMatchingLocalValuesArray = [];
      for(let f = 0; f < fieldNamesArray.length; f++) {
        if(oldValuesArray[f] !== valuesArray[f]) { //add fields where the new value is different from the old value
          nonMatchingFieldNamesArray.push(fieldNamesArray[f]);
          nonMatchingValuesArray.push(valuesArray[f]);
          nonMatchingValuesIdsbArray.push(valuesIdsbArray[f]);
          nonMatchingLocalValuesArray.push(localValuesArray[f]);
        }
      }
      fieldNamesArray = nonMatchingFieldNamesArray;
      valuesArray = nonMatchingValuesArray;
      valuesIdsbArray = nonMatchingValuesIdsbArray;
      localValuesArray = nonMatchingLocalValuesArray;
    }

    return([fieldNamesArray, valuesArray, valuesIdsbArray, localValuesArray]);
  }
}





//***********************************************************************************************************************************************************************************
//***********************************************************************************************************************************************************************************
//***********************************************************************************************************************************************************************************
//***********************************************************************************************************************************************************************************

export function record_z_error(i_jsDescription, i_errorMessage) {
  var formattedErrorMessage = "xxxxxxxxxxxxxxxxxx [" + window.location.href  + "] JSPHP record_z_error() " + JSFUNC.now_datetime() + " xxxxxxxxxxxxxxxxxx\r\n";
  formattedErrorMessage += "k_userBrowserLiveCodeVersion: " + CaptureExecMobx.k_userBrowserLiveCodeVersion + ", k_userBrowserDevCodeVersion: " + CaptureExecMobx.k_userBrowserDevCodeVersion + "\r\n";
  formattedErrorMessage += "o_userCompanyCode: " + UserMobx.o_userCompanyCode + ", c_userName: " + UserMobx.c_userName + "\r\n";
  formattedErrorMessage += "---jsDescription----------------------------------------------------------------------------\r\n";
  formattedErrorMessage += i_jsDescription + "\r\n";
  formattedErrorMessage += "---errorMessage-----------------------------------------------------------------------------\r\n";
  formattedErrorMessage += i_errorMessage + "\r\n";
  formattedErrorMessage += "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx";

  call_php_record_ce_error_in_bit_z_error_tbl(formattedErrorMessage);
}


function call_php_record_ce_error_in_bit_z_error_tbl(i_errorMessage) {
  if(CaptureExecMobx.o_isLocalhost3000SystemTF) { //in dev system, show the error message on the screen
    CaptureExecMobx.a_set_error_message(i_errorMessage);
  }
  else { //in the live system, call php api "ceErrorEmail" which writes to a database z_errors in the companies tbl
    JSFUNC.xmlhttprequest_send_form_data_post_to_php_script_with_response_function("php/record_ce_error.php", "i_errorMessage", i_errorMessage, undefined, false);
  }
}





//=====================================================================================================================
//Server Files and Template Generation
//=====================================================================================================================
export function load_server_file_data_as_string(i_fileLocFromCompanyUploadsFolder, i_functionOnSuccessfulLoad, i_functionOnErrorMessage, i_jsDescriptionFromHigherCaller) {
  //i_functionOnSuccessfulLoad(i_fileDataString)    successful file read and data transfer, data as a string pass through single input
  //i_functionOnErrorMessage(i_errorMessage)        accounts for all error messages (php error, file doesn't exist)
  const jsDescription = JSFUNC.js_description_from_action("StorePhpInterface", "load_server_file_data_as_string", ["i_fileLocFromCompanyUploadsFolder"], [i_fileLocFromCompanyUploadsFolder], i_jsDescriptionFromHigherCaller);
  const C_CallPhpScript = new ClassCallPhpScript("loadServerFileDataAsString", jsDescription);

  C_CallPhpScript.add_post_var("i_fileLocRootUploadsOrWeb", "uploads");
  C_CallPhpScript.add_post_var("i_fileLocRelativeFromRoot", i_fileLocFromCompanyUploadsFolder);

  C_CallPhpScript.add_return_vars(["fileExisted01", "fileDataString"]);

  //php success, file either existed or did not
  const functionOnSuccess = (i_parseResponse) => {
    if(i_parseResponse.fileExisted01 === "1") {
      if(JSFUNC.is_function(i_functionOnSuccessfulLoad)) {
        i_functionOnSuccessfulLoad(i_parseResponse.fileDataString);
      }
    }
    else {
      if(JSFUNC.is_function(i_functionOnErrorMessage)) {
        var filePartsObj = JSFUNC.file_parts_obj(i_fileLocFromCompanyUploadsFolder);
        i_functionOnErrorMessage("File could not be found (" + filePartsObj.fileNameAndExt + ")");
      }
    }
  }
  C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

  //php failure
  const functionOnError = () => {
    if(JSFUNC.is_function(i_functionOnErrorMessage)) {
      var filePartsObj = JSFUNC.file_parts_obj(i_fileLocFromCompanyUploadsFolder);
      i_functionOnErrorMessage("Error loading file data (" + filePartsObj.fileNameAndExt + ")");
    }
  }
  C_CallPhpScript.add_function("onError", functionOnError);

  //execute
  C_CallPhpScript.execute();
}



export function check_file_exists_on_server_call_download_php_and_offer_download(i_fileLocRootUploadsOrWeb, i_fileLocRelativeFromRoot, i_downloadSaveAsFileName, i_functionDownloadState, i_jsDescriptionFromHigherCaller) {
  //i_fileLocRootUploadsOrWeb                 "uploads" - fileLoc root is from server uploads location ("/v/s/up/CC"), "web" - fileLoc root is on public server location ("/v/w/h")
  //i_fileLocRelativeFromRoot                 full path from "uploads" company code folder (or "web" folder), all subfolders, and full file name with extension (don't include slash as first character)
  //i_downloadSaveAsFileName                  save as download initial new file name, input can have the extension or not and can be very long, this function will trim it and make sure the extension matches the real file
  //i_functionDownloadState(i_downloadState)  function with 1 input i_downloadState ("working", "error", "fileDoesNotExist", "success") that is called when the state changes

  //verify the input function for changing the download state is a function
  const functionDownloadStateIsFunctionTF = JSFUNC.is_function(i_functionDownloadState);

  //set that the download is working fetching the data from the server
  if(functionDownloadStateIsFunctionTF) {
    i_functionDownloadState("working");
  }

  //call database_api.php verifyFileExistsOnServer to check if the file exists before fetching its data and offering it for download
  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "check_file_exists_on_server_call_download_php_and_offer_download", ["i_fileLocRootUploadsOrWeb", "i_fileLocRelativeFromRoot", "i_downloadSaveAsFileName"], [i_fileLocRootUploadsOrWeb, i_fileLocRelativeFromRoot, i_downloadSaveAsFileName], i_jsDescriptionFromHigherCaller);
  const C_CallPhpScript = new ClassCallPhpScript("verifyFileExistsOnServer", jsDescription);
  C_CallPhpScript.add_post_var("i_fileLocRootUploadsOrWeb", i_fileLocRootUploadsOrWeb);
  C_CallPhpScript.add_post_var("i_fileLocRelativeFromRoot", i_fileLocRelativeFromRoot);
  C_CallPhpScript.add_return_vars("fileExists01String");

  const functionOnError = () => { //error trying to verify if file exists on server
    if(functionDownloadStateIsFunctionTF) {
      i_functionDownloadState("error");
    }
    record_z_error(jsDescription, "error trying to verify if file exists on server");
  }
  C_CallPhpScript.add_function("onError", functionOnError);

  const functionOnSuccess = (i_parseResponse) => {
    if(i_parseResponse.fileExists01String !== "1") { //file does not exist on server
      if(functionDownloadStateIsFunctionTF) {
        i_functionDownloadState("fileDoesNotExist");
      }
      record_z_error(jsDescription, "file does not exist on server");
    }
    else { //file does exist
      const postVariableNamesArray = ["i_fileLocRootUploadsOrWeb", "i_fileLocRelativeFromRoot"];
      const postVariableValuesArray = [i_fileLocRootUploadsOrWeb, i_fileLocRelativeFromRoot];

      //function to offer the server file data string for download after calling download.php
      const functionOnReadyState4OfferLoadedFileDataForDownload = (i_xmlHttpRequest) => {
        var fileDataString = i_xmlHttpRequest.response;

        if(functionDownloadStateIsFunctionTF) {
          i_functionDownloadState("success");
        }

        //trim the download save as filename to a maximum of 100 characters
        const maxDownloadSaveAsFileNameNumChars = 100;
        const downloadSaveAsFileNameString = JSFUNC.num2str(i_downloadSaveAsFileName); //ensure the given download save as filename is a string
        const downloadSaveAsFileNameFilePartsObj = JSFUNC.file_parts_obj(downloadSaveAsFileNameString); //split input name to remove extension
        var downloadSaveAsFileNameAndExt = downloadSaveAsFileNameFilePartsObj.fileName; //display name without any added extension
        if(downloadSaveAsFileNameAndExt.length > maxDownloadSaveAsFileNameNumChars) { //trim the length of the save as file name if it exceeds the maximum
          downloadSaveAsFileNameAndExt = downloadSaveAsFileNameAndExt.substring(0, maxDownloadSaveAsFileNameNumChars);
        }
        const fileLocFilePartsObj = JSFUNC.file_parts_obj(i_fileLocRelativeFromRoot);
        downloadSaveAsFileNameAndExt += "." + fileLocFilePartsObj.fileExt; //add back the correct extension from the real fileLoc

        //offer the file for download
        JSFUNC.browser_offer_file_download_from_file_data_string(fileDataString, downloadSaveAsFileNameAndExt);
      }
      
      //download.php returns an arraybuffer file data string
      const returnsArrayBufferFileDataTF = true;

      //call to download.php to get the file data string from the server
      JSFUNC.xmlhttprequest_send_form_data_post_to_php_script_with_response_function("php/download.php", postVariableNamesArray, postVariableValuesArray, functionOnReadyState4OfferLoadedFileDataForDownload, returnsArrayBufferFileDataTF);
    }
  }
  C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

  C_CallPhpScript.execute();
}






//=====================================================================================================================
//Call php api functions in database_api.php
//=====================================================================================================================
export function load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array(i_tblNameOrTblNamesArray, i_functionOnFinish=undefined) {
  var tblNamesComma = i_tblNameOrTblNamesArray;
  if(JSFUNC.is_array(i_tblNameOrTblNamesArray)) {
    tblNamesComma = JSFUNC.convert_array_to_comma_list(i_tblNameOrTblNamesArray);
  }

  if(tblNamesComma !== "") {
    const jsDescription = JSFUNC.js_description_from_action("JSPHP", "load_db_data_to_local_memory_from_tbl_name_or_tbl_names_array", ["i_tblNameOrTblNamesArray"], [i_tblNameOrTblNamesArray]);
    const C_CallPhpScript = new ClassCallPhpScript("loadTbls", jsDescription); //call database_api.php "loadTbls" to load the requested tbls from the database
    C_CallPhpScript.add_post_var("i_tblNamesComma", tblNamesComma);
    C_CallPhpScript.add_return_vars("dataTables");

    //successful fetch of the tbl(s), update local map(s) of tbl(s) with uncompressed fetched data, call i_functionOnFinish if given with true as the input
    const functionOnSuccess = (i_parseResponse) => {
      const clearOldMapDataFirstTF = true;
      DatabaseMobx.a_insert_or_update_multiple_local_data_maps_with_uncompression_from_data_tbls_obj(i_parseResponse.dataTables, clearOldMapDataFirstTF, jsDescription);

      if(JSFUNC.is_function(i_functionOnFinish)) {
        i_functionOnFinish(true);
      }
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    //error fetch of the tbl(s), if i_functionOnFetch is given, call it was false as the input
    if(JSFUNC.is_function(i_functionOnFinish)) {
      const functionOnError = () => {
        i_functionOnFinish(false);
      }
      C_CallPhpScript.add_function("onError", functionOnError);
    }

    C_CallPhpScript.execute();
  }
}


export function load_db_data_to_local_memory_from_filtered_capture_tbl_names_comma(i_tblNamesComma, i_forceLoadArchivedCapturesTF, i_singleCaptureIDOrM1AllCaptures, i_functionOnFinish=undefined) {
  if(i_tblNamesComma !== "") {
    const o_userID = UserMobx.o_userID;
    const c_userArchivedCapturesAlwaysLoadArchivedTF = UserMobx.c_userArchivedCapturesAlwaysLoadArchivedTF;

    const loadArchivedCaptures01String = ((c_userArchivedCapturesAlwaysLoadArchivedTF || i_forceLoadArchivedCapturesTF) ? ("1") : ("0"));

    const jsDescription = JSFUNC.js_description_from_action("JSPHP", "load_db_data_to_local_memory_from_filtered_capture_tbl_names_comma", ["i_tblNamesComma", "i_forceLoadArchivedCapturesTF", "i_singleCaptureIDOrM1AllCaptures"], [i_tblNamesComma, i_forceLoadArchivedCapturesTF, i_singleCaptureIDOrM1AllCaptures]);
    const C_CallPhpScript = new ClassCallPhpScript("loadFilteredCaptureTbls", jsDescription);

    C_CallPhpScript.add_post_var("i_tblNamesComma", i_tblNamesComma);
    C_CallPhpScript.add_post_var("i_userID", o_userID); //slimCST accessible captures for logged in userID
    C_CallPhpScript.add_post_var("i_loadArchivedCaptures01", loadArchivedCaptures01String);
    C_CallPhpScript.add_post_var("i_singleCaptureIDOrM1AllCaptures", i_singleCaptureIDOrM1AllCaptures); //-1 normal filtering for all user accessible captures, if a single captureID is provided only that single capture will be loaded (if allowed by firewalling, otherwise 0 returned)

    C_CallPhpScript.add_return_vars(["dataTables", "archivedCaptureIDsComma"]);

    const functionOnSuccess = (i_parseResponse) => {
      const clearOldMapDataFirstTF = true;
      DatabaseMobx.a_insert_or_update_multiple_local_data_maps_with_uncompression_from_data_tbls_obj(i_parseResponse.dataTables, clearOldMapDataFirstTF, jsDescription);

      //update the archived captureIDs
      DatabaseMobx.a_set_archived_capture_ids_comma(i_parseResponse.archivedCaptureIDsComma);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.add_function("onFinish", i_functionOnFinish);

    C_CallPhpScript.execute();
  }
}


export function load_db_single_data_raw_value_or_undefined_from_tbl_name_and_row_id_and_field_db_name(i_tblName, i_rowID, i_fieldDbName, i_functionOnFinish=undefined) {
  if(JSFUNC.is_function(i_functionOnFinish)) {
    const jsDescription = JSFUNC.js_description_from_action("JSPHP", "load_db_single_data_raw_value_or_undefined_from_tbl_name_and_row_id_and_field_db_name", ["i_tblName", "i_rowID", "i_fieldDbName"], [i_tblName, i_rowID, i_fieldDbName]);
    const C_CallPhpScript = new ClassCallPhpScript("loadTblSingleValue", jsDescription);

    C_CallPhpScript.add_post_var("i_tblName", i_tblName);
    C_CallPhpScript.add_post_var("i_rowID", i_rowID);
    C_CallPhpScript.add_post_var("i_fieldDbName", i_fieldDbName);

    C_CallPhpScript.add_return_vars(["success01String", "valueRawString"]);

    const functionOnSuccess = (i_parseResponse) => {
      if(i_parseResponse.success01String === "1") {
        i_functionOnFinish(i_parseResponse.valueRawString);
      }
      else {
        i_functionOnFinish(undefined);
      }
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      i_functionOnFinish(undefined);
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();
  }
}

export function single_capture_load_supplementary_data(i_captureID, i_updatedRecentlyVisitedCaptureIDsComma="", i_functionOnSuccess=undefined) {
  //capture is already open, so the data is already loaded, do nothing and call the success function
  if(OpenCaptureMobx.o_openCaptureID === i_captureID) {
    if(i_functionOnSuccess !== undefined) {
      i_functionOnSuccess();
    }
    return;
  }

  //otherwise, load the capture data
  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "single_capture_load_supplementary_data", ["i_captureID", "i_updatedRecentlyVisitedCaptureIDsComma"], [i_captureID, i_updatedRecentlyVisitedCaptureIDsComma]);

  const functionOnSuccess = (i_parseResponse) => {
    //store all loaded capture supplementary data tbls from php into corresponding local memory tbls
    const clearOldMapDataFirstTF = true;
    for(let tblName of DatabaseMobx.c_openCaptureTblNamesToLoadArray) { //store each table loaded into local memory
      if(i_parseResponse.captureDataTables.hasOwnProperty(tblName)) {
        DatabaseMobx.a_insert_or_update_local_data_map(tblName, i_parseResponse.captureDataTables[tblName], clearOldMapDataFirstTF, jsDescription); //this also can handle the special case of loading and storing singleCaptureFullTextareaFieldsMap
      }
    }
  }

  //call database_api "openSingleCaptureLoadSupplementaryData" to save the recently visited captureIDs, save which captureID you currently have open, and to fetch all the supplemental capture data tables except for the changelogs
  const C_CallPhpScript = new ClassCallPhpScript("openSingleCaptureLoadSupplementaryData", jsDescription);
  C_CallPhpScript.add_post_var("i_captureID", i_captureID);
  C_CallPhpScript.add_post_var("i_captureDataTblNamesComma", JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_openCaptureTblNamesToLoadArray));
  C_CallPhpScript.add_post_var("i_updatedRecentlyVisitedCaptureIDsComma", i_updatedRecentlyVisitedCaptureIDsComma);
  C_CallPhpScript.add_return_vars("captureDataTables");
  C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
  if(i_functionOnSuccess !== undefined) {
    C_CallPhpScript.add_function("onSuccess", i_functionOnSuccess);
  }
  C_CallPhpScript.execute();
}


export function write_companies_login_load_times(i_loginTimeMs, i_phase1TimeMs, i_phase2TimeMs, i_numCaptures) {
  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "write_companies_login_load_times", ["i_loginTimeMs", "i_phase1TimeMs", "i_phase2TimeMs", "i_numCaptures"], [i_loginTimeMs, i_phase1TimeMs, i_phase2TimeMs, i_numCaptures]);
  const C_CallPhpScript = new ClassCallPhpScript("writeCompaniesLoginLoadTimes", jsDescription);
  C_CallPhpScript.add_post_var("i_userID", UserMobx.o_userID);
  C_CallPhpScript.add_post_var("i_userName", UserMobx.c_userName);
  C_CallPhpScript.add_post_var("i_login", i_loginTimeMs);
  C_CallPhpScript.add_post_var("i_phase1", i_phase1TimeMs);
  C_CallPhpScript.add_post_var("i_phase2", i_phase2TimeMs);
  C_CallPhpScript.add_post_var("i_numCaptures", i_numCaptures);
  C_CallPhpScript.add_return_vars("success01String");
  C_CallPhpScript.execute();
}


export function refresh_data_tables_from_user_per_email_flags() {
  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "refresh_data_tables_from_user_per_email_flags", [], []);

  //flash the refresh tbls blue light
  CaptureExecMobx.a_set_refresh_is_running_tf(true);

  //update local memory nowDate and nowDateTimeUTC
  CaptureExecMobx.a_update_now_date_and_now_datetime_to_current_date();

  const functionOnSuccess = (i_parseResponse) => {
    const outputObj = i_parseResponse.o;

    //loop through all returned tbl_cap tblNames that need to be created in local memory
    if(outputObj.hasOwnProperty("ctcTblNamesArray")) {
      if(JSFUNC.is_array(outputObj.ctcTblNamesArray)) {
        for(let tblCapName of outputObj.ctcTblNamesArray) {
          DatabaseMobx.a_local_create_new_tbl_cap_table(tblCapName, false, jsDescription);
        }
      }
    }

    //loop through all returned tblNames with data rows to update/insert
    if(outputObj.hasOwnProperty("refreshDataObj")) {
      const clearOldMapDataFirstTF = false;
      DatabaseMobx.a_insert_or_update_multiple_local_data_maps_with_uncompression_from_data_tbls_obj(outputObj.refreshDataObj, clearOldMapDataFirstTF, jsDescription);
    }

    //loop through all returned tblNames with data rows to delete
    if(outputObj.hasOwnProperty("deleteDataObj")) {
      for(var tblName in outputObj.deleteDataObj) {
        if(outputObj.deleteDataObj.hasOwnProperty(tblName)) {
          var rowIDsToDeleteArray = outputObj.deleteDataObj[tblName];
          DatabaseMobx.a_delete_local_data_mapOfMaps_item_from_id_or_id_array(tblName, rowIDsToDeleteArray, jsDescription);
        }
      }
    }

    //check to see if anyone else in tbl_a_users_per_email has your captureID open as well, return their userPerEmailIDs in a comma list if any have it open (js puts an alert on the capture red title bar when this happens)
    if(outputObj.hasOwnProperty("userPerEmailIDsWithCaptureOpenComma")) {
      OpenCaptureMobx.a_set_user_per_email_ids_with_same_capture_open(outputObj.userPerEmailIDsWithCaptureOpenComma);
    }

    CaptureExecMobx.a_set_refresh_is_running_tf(false);
  }

  const C_CallPhpScript = new ClassCallPhpScript("r", jsDescription);
  if(UserMobx.c_combinedUserObj.powerIsCaptureConsultantTF) {
    C_CallPhpScript.add_post_var("c", UserMobx.o_userID); //if the user is a capture consultant, send the POST var "c" each refresh as a flag to filter refresh captures (only captures they own can be brought to their browser)
  }
  C_CallPhpScript.add_return_vars("o");
  C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
  C_CallPhpScript.execute();
}





export function progress_chart_load_thin_log_tbls(i_filteredCaptureIDsComma, i_companyUsingPwinTF, i_functionOnFinish=undefined) {
  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "progress_chart_load_thin_log_tbls", ["i_filteredCaptureIDsComma", "i_companyUsingPwinTF"], [i_filteredCaptureIDsComma, i_companyUsingPwinTF]);

  const functionOnSuccess = (i_parseResponse) => {
    if(i_companyUsingPwinTF) { //only load pwin data if company is using it
      DatabaseMobx.a_overwrite_progress_chart_log_tbl_from_php_raw_data("pwin", i_parseResponse.progressChartLogPwin);
    }
    DatabaseMobx.a_overwrite_progress_chart_log_tbl_from_php_raw_data("progress", i_parseResponse.progressChartLogShaping);
    DatabaseMobx.a_overwrite_progress_chart_log_tbl_from_php_raw_data("stages", i_parseResponse.progressChartLogStages);
  }

  //call database_api "loadProgressChartData"
  const C_CallPhpScript = new ClassCallPhpScript("loadProgressChartData", jsDescription);
  C_CallPhpScript.add_post_var("i_filteredCaptureIDsComma", i_filteredCaptureIDsComma);
  C_CallPhpScript.add_post_var("i_companyUsingPwinTF", i_companyUsingPwinTF);
  C_CallPhpScript.add_return_vars(["progressChartLogPwin", "progressChartLogShaping", "progressChartLogStages"]);
  C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
  if(i_functionOnFinish !== undefined) {
    C_CallPhpScript.add_function("onFinish", i_functionOnFinish);
  }
  C_CallPhpScript.execute();
}





export function recalculate_and_update_capture_teammate_counts_and_allocations_from_contact_company_id(i_contactCompanyID) {
  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "recalculate_and_update_capture_teammate_counts_and_allocations_from_contact_company_id", ["i_contactCompanyID"], [i_contactCompanyID]);
  const C_CallPhpScript = new ClassCallPhpScript("recalculateTeammateCountsAndAllocations", jsDescription);
  C_CallPhpScript.add_post_var("i_optionalFilterSingleContactCompanyID", i_contactCompanyID);
  C_CallPhpScript.add_return_vars("success01String");
  C_CallPhpScript.execute();
}

export function recalculate_and_update_capture_teammate_counts_and_allocations_from_capture_id_or_ids_array(i_captureIDOrIDsArray) {
  const captureIDsArray = JSFUNC.convert_single_or_array_to_array(i_captureIDOrIDsArray);
  if(captureIDsArray.length > 0) {
    const captureIDsComma = JSFUNC.convert_array_to_comma_list(captureIDsArray);
    const jsDescription = JSFUNC.js_description_from_action("JSPHP", "recalculate_and_update_capture_teammate_counts_and_allocations_from_capture_id_or_ids_array", ["i_captureIDOrIDsArray"], [i_captureIDOrIDsArray]);
    const C_CallPhpScript = new ClassCallPhpScript("recalculateTeammateCountsAndAllocations", jsDescription);
    C_CallPhpScript.add_post_var("i_optionalFilterCaptureIDsComma", captureIDsComma);
    C_CallPhpScript.add_return_vars("success01String");
    C_CallPhpScript.execute();
  }
}

export function recalculate_and_update_capture_teammate_counts_and_allocations_from_division_id_or_ids_array(i_ourDivisionIDOrIDsArray) {
  var ourDivisionIDsComma = i_ourDivisionIDOrIDsArray;
  if(JSFUNC.is_array(i_ourDivisionIDOrIDsArray)) {
    ourDivisionIDsComma = JSFUNC.convert_array_to_comma_list(i_ourDivisionIDOrIDsArray);
  }

  if(ourDivisionIDsComma !== "") {
    const jsDescription = JSFUNC.js_description_from_action("JSPHP", "recalculate_and_update_capture_teammate_counts_and_allocations_from_division_id_or_ids_array", ["i_ourDivisionIDOrIDsArray"], [i_ourDivisionIDOrIDsArray]);
    const C_CallPhpScript = new ClassCallPhpScript("recalculateTeammateCountsAndAllocations", jsDescription);
    C_CallPhpScript.add_post_var("i_optionalFilterOurDivisionIDsComma", ourDivisionIDsComma);
    C_CallPhpScript.add_return_vars("success01String");
    C_CallPhpScript.execute();
  }
}






export function delete_single_capture_with_capture_data_and_integration_opp_from_capture_id(i_captureID, i_functionOnSuccess=undefined, i_functionOnError=undefined) {
  const o_tbl_captures = DatabaseMobx.o_tbl_captures;
  const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
  const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;

  var captureMapToDeleteOrUndefined = o_tbl_captures.get(i_captureID);
  if(captureMapToDeleteOrUndefined !== undefined) {
    captureMapToDeleteOrUndefined = JSFUNC.copy_map(captureMapToDeleteOrUndefined);
  }

  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "delete_single_capture_with_capture_data_and_integration_opp_from_capture_id", ["i_captureID"], [i_captureID]);
  const C_CallPhpTblUID = new ClassCallPhpTblUID(jsDescription);

  //delete the capture row from tbl_captures
  C_CallPhpTblUID.add_delete("tbl_captures", i_captureID); //no sort column for captures, no need to resort after delete

  //success with request to delete capture row
  const functionOnSuccess = (i_parseResponse) => {
    if(i_parseResponse.outputObj.d0 === "1") { //success deleting capture row "1" from php code
      //delete all capture data from all "tbl_c_" tbls
      const C_CallPhpScript = new ClassCallPhpScript("deleteCaptureData", jsDescription);
      C_CallPhpScript.add_post_var("i_captureID", i_captureID);
      C_CallPhpScript.add_return_vars("success01String");

      //call the input success function once this tbl_c_ data deletion is finished without verifying its success, it's enough that the tbl_captures row is gone, which hides the capture from anyone's logged in system
      if(JSFUNC.is_function(i_functionOnSuccess)) {
        C_CallPhpScript.add_function("onFinish", i_functionOnSuccess);
      }

      C_CallPhpScript.execute();

      //simultaneously make the call to php to delete the linked capture from the 3rd Party Integration system if it is turned on and this capture is linked by the integration unique ID to an opp in that system
      if(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF) {
        integration_delete_integration_opp(i_captureID, captureMapToDeleteOrUndefined);
      }
    }
    else { //error deleting capture row "0" from php
      if(JSFUNC.is_function(i_functionOnError)) {
        i_functionOnError();
      }
    }
  }
  C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

  //error with request to delete capture row
  C_CallPhpTblUID.add_function("onError", i_functionOnError);

  C_CallPhpTblUID.execute();
}



//----------------------------------------------------------------------------------------------------------------------------------------------------------
//3rd Party Integration js-php calls
//----------------------------------------------------------------------------------------------------------------------------------------------------------
export function integration_update_integration_opp_field_if_ce_field_is_paired(i_ceCaptureID, i_ceFieldID, i_ceNewValueRaw) {
  const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
  const c_integrationSystemDisplayName = AdminIntegrationsMobx.c_integrationSystemDisplayName;
  const c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined = AdminIntegrationsMobx.c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined;
  const c_linkedFieldsWithValuesArrayOfObjs = AdminIntegrationsMobx.c_linkedFieldsWithValuesArrayOfObjs;
  const o_tbl_captures = DatabaseMobx.o_tbl_captures;
  const c_companyIntegrationLogRecordSuccessesTF = DatabaseMobx.c_companyIntegrationLogRecordSuccessesTF;
  const c_fieldMapOfCaptureID = DatabaseMobx.c_fieldMapOfCaptureID;
  const o_userID = UserMobx.o_userID;

  //no need to calculate anything or attempt to make an insert if there are 0 linked pairs created on the 3rd Party Integrations 'Mapping' subtab, the new capture will find 0 matches and not make an insert anyway
  if(c_linkedFieldsWithValuesArrayOfObjs.length === 0) {
    return;
  }

  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "integration_update_integration_opp_field_if_ce_field_is_paired", ["i_ceCaptureID", "i_ceFieldID", "i_ceNewValueRaw"], [i_ceCaptureID, i_ceFieldID, i_ceNewValueRaw]);

  //initialize that this integration unique ID string is unknown until the verification below can load the value from the capture
  const iudActionString = "uCEuIN";
  var integrationOppUniqueIDString = "--";
  var changelogIntegrationFieldDbName = "--";
  var changelogNewCEValueString = "--";
  var changelogIntegrationNewValueString = "--";

  //check that the i_ceCaptureID provided is a valid CE capture (need this to extract the existing integration unique ID from the CE capture)
  const captureMapOrUndefined = o_tbl_captures.get(i_ceCaptureID);
  if(captureMapOrUndefined === undefined) {
    const captureIDNotAValidCaptureErrorMessage = c_productStylingObj.productName + " " + c_fieldMapOfCaptureID.get("display_name") + " '" + i_ceCaptureID + "' is not a valid capture";
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, captureIDNotAValidCaptureErrorMessage);
    return;
  }

  //verify if all Setup subtab fields are filled out
  const setupErrorMessageOrUndefined = integration_verify_all_setup_and_mapping_is_filled_out_undefined_or_error_message_string();
  if(setupErrorMessageOrUndefined !== undefined) { //an APi Connection or Unique ID field is not filled out, write an integration log message
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, setupErrorMessageOrUndefined);
    return;
  }

  //load the integration unique ID from this CE capture using the input i_ceCaptureID loaded captureMap
  const ceIntegrationUniqueIDValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined, true); //plainTextTF is true
  if(!ceIntegrationUniqueIDValueMaskSortIfoObj.isFilledOutTF) { //if this CE capture does not have the integration unique ID field filled out
    const ceIntegrationUniqueIDFieldIsNotFilledOutErrorMessage = c_productStylingObj.productName +  " Capture Field '" + c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined.get("display_name") + "' is not filled out with the " + c_integrationSystemDisplayName + " unique ID that links this opportunity across the 2 systems";
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, ceIntegrationUniqueIDFieldIsNotFilledOutErrorMessage);
    return;
  }

  //get the CE integration opp uniqueID string stored in the CE capture linking the CE capture to the integration system opp
  integrationOppUniqueIDString = ceIntegrationUniqueIDValueMaskSortIfoObj.valueMaskPlainText;

  //match the CE field to 0, 1, or multiple linked integration fields, then use the new CE value, or translate through a matching mapped value
  var singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj = integration_find_all_integration_field_db_names_and_mapped_values_matching_ce_field_id_and_ce_value_raw(iudActionString, i_ceCaptureID, i_ceFieldID, i_ceNewValueRaw, integrationOppUniqueIDString);
  var singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs = singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj.singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs;
  var ceFieldDebugString = singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj.ceFieldDebugString;
  //alert(ceFieldDebugString);

  //if 0 linked pairs matched the input i_ceFieldID, do not make an update to the integration system (no linked integration field to this CE field to update)
  const numIntegrationFieldValuePairs = singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs.length;
  if(numIntegrationFieldValuePairs === 0) {
    return;
  }
  
  //get the integration fieldDbName(s) linked to i_ceFieldID as a display comma list for the integrationFieldDbName column of the changelog
  changelogIntegrationFieldDbName = JSFUNC.convert_array_to_display_comma_list(JSFUNC.get_column_vector_from_arrayOfObjs(singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs, "integrationFieldDbName"));
  changelogNewCEValueString = singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs[0].ceNewValueChangelogString; //all new CE values are the same for this single ceFieldID, use the first one
  changelogIntegrationNewValueString = JSFUNC.convert_array_to_display_comma_list(JSFUNC.get_column_vector_from_arrayOfObjs(singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs, "integrationValueString"));

  //call a php function to update the same field to the equivalent value in the integration system to sync with the CE update
  const C_CallPhpScript = new ClassCallPhpScript("integrationUpdateIntegrationOppMultipleFieldsWithValues", jsDescription);

  C_CallPhpScript.add_post_var("i_userID", o_userID);
  C_CallPhpScript.add_post_var("i_integrationSystem", "vantagepoint");
  C_CallPhpScript.add_post_var("i_integrationOppUniqueIDString", integrationOppUniqueIDString);
  C_CallPhpScript.add_post_var("i_numIntegrationFieldValuePairs", numIntegrationFieldValuePairs);
  for(let i = 0; i < numIntegrationFieldValuePairs; i++) {
    var postVarFieldDbNameString = "i_integrationFieldDbName" + i;
    var postVarValueString = "i_integrationValueString" + i;
    var integrationFieldValueObj = singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs[i];
    C_CallPhpScript.add_post_var(postVarFieldDbNameString, integrationFieldValueObj.integrationFieldDbName);
    C_CallPhpScript.add_post_var(postVarValueString, integrationFieldValueObj.integrationValueString);
  }

  C_CallPhpScript.add_return_vars("integrationErrorMessageOrBlankSuccess");

  //update integrations log on success or error
  const functionOnSuccess = (i_parseResponse) => {
    //check i_parseResponse for errorMessages from the php call to OAuth and the API when updating the integration system
    var errorMessage = ""; //success has "" for the error message
    if(i_parseResponse.integrationErrorMessageOrBlankSuccess !== "") { //successful integration system field update
      errorMessage = i_parseResponse.integrationErrorMessageOrBlankSuccess;
    }

    //insert integrations changelog entry for php OAuth/API success or error
    if(c_companyIntegrationLogRecordSuccessesTF || (errorMessage !== "")) { //do not create a success integrations changelog entry if the 'record successes' switch is turned off
      integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, errorMessage);
    }
  }
  C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

  const functionOnError = () => {
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, "Unexpected issue handling the request");
  }
  C_CallPhpScript.add_function("onError", functionOnError);

  C_CallPhpScript.execute();
}


export function integration_insert_integration_new_opp(i_newCECaptureID, i_newCaptureAllCECaptureFieldIDsArray, i_newCaptureAllCERawValuesArray, i_newIntegrationOppUniqueIDString, i_integrationFieldDbNamesWithDefaultValuesArrayOfObjsOrUndefined=undefined) {
  const c_linkedFieldsWithValuesArrayOfObjs = AdminIntegrationsMobx.c_linkedFieldsWithValuesArrayOfObjs;
  const c_companyIntegrationLogRecordSuccessesTF = DatabaseMobx.c_companyIntegrationLogRecordSuccessesTF;
  const o_userID = UserMobx.o_userID;

  //no need to calculate anything or attempt to make an insert if there are 0 linked pairs created on the 3rd Party Integrations 'Mapping' subtab, the new capture will find 0 matches and not make an insert anyway
  if(c_linkedFieldsWithValuesArrayOfObjs.length === 0) {
    return;
  }

  const integrationSystem = "vantagepoint";

  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "integration_insert_integration_new_opp", ["i_newCECaptureID", "i_newCaptureAllCECaptureFieldIDsArray", "i_newCaptureAllCERawValuesArray", "i_newIntegrationOppUniqueIDString", "i_integrationFieldDbNamesWithDefaultValuesArrayOfObjsOrUndefined"], [i_newCECaptureID, i_newCaptureAllCECaptureFieldIDsArray, i_newCaptureAllCERawValuesArray, i_newIntegrationOppUniqueIDString, i_integrationFieldDbNamesWithDefaultValuesArrayOfObjsOrUndefined]);

  //changelog values
  const iudActionString = "iCEiIN";
  const changelogCaptureFieldID = -1;
  const changelogIntegrationFieldDbName = "{Insert New Opportunity}";
  var changelogNewCEFieldAndValueStringsArray = [];
  var changelogIntegrationNewFieldAndValueStringsArray = [];

  //intialize array to collect integration new field/value pairs converted from CE for this insert
  var integrationFieldsValuesArrayOfObjs = [];
  var debugStringsArray = [];

  //loop to assign the following new CE capture values (mapped equivalent value if necessary) to their integration fieldDbNames:
  //  "key"/"WBSNumber"/"WBS1" (with i_newIntegrationOppUniqueIDString)
  //  "Name"/"LongName"/"Level1Name" (with CE opportunity_name)
  //  "Stage" (with CE stage_id mapped integration value)
  //  "Org" (with CE division_owners_ids_colon_percent_comma mapped integration value)
  //plus all other CE fields found in i_newCaptureAllCECaptureFieldIDsArray (either the few standard fields from Create New Capture, or many more fields defined from a Capture Import)
  for(let f = 0; f < i_newCaptureAllCECaptureFieldIDsArray.length; f++) { //loop through every field/value pair within i_newCaptureAllCECaptureFieldIDsArray/i_newCaptureAllCERawValuesArray (all fields filled out in create new capture process)
    var ceFieldID = i_newCaptureAllCECaptureFieldIDsArray[f];
    var ceNewValueRaw = i_newCaptureAllCERawValuesArray[f];

    //match the CE field to 0, 1, or multiple linked integration fields, then use the new CE value, or translate through a matching mapped value
    var singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj = integration_find_all_integration_field_db_names_and_mapped_values_matching_ce_field_id_and_ce_value_raw(iudActionString, i_newCECaptureID, ceFieldID, ceNewValueRaw, i_newIntegrationOppUniqueIDString)
    var singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs = singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj.singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs;
    var ceFieldDebugString = singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj.ceFieldDebugString;

    //loop over all linked pair(s) for this CE fieldID (should be 0 or 1 matches, but can be multiple integration fields linked to the same CE field)
    for(let matchingIntegrationFieldValueObj of singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs) {
      //add matching linked pair(s) from single CE fieldID to collection of all CE fields
      integrationFieldsValuesArrayOfObjs.push(matchingIntegrationFieldValueObj);

      //record changelog pieces for CE and integration for this one field that will be inserted into the integration new opp
      changelogNewCEFieldAndValueStringsArray.push(matchingIntegrationFieldValueObj.ceFieldDisplayName + ": '" + matchingIntegrationFieldValueObj.ceNewValueChangelogString + "'");
      changelogIntegrationNewFieldAndValueStringsArray.push(matchingIntegrationFieldValueObj.integrationFieldDbName + ": '" + matchingIntegrationFieldValueObj.integrationValueString + "'");
    }

    //add debug string from single CE fieldID to array (include f index number)
    debugStringsArray.push(f + " - " + ceFieldDebugString);
  }

  //if integration fields that have default values were provided as an arrayOfObjs input, add those here (that are not defined with real values in the mappings above) to the full list on integration insert field dbNames/values
  if(JSFUNC.is_array(i_integrationFieldDbNamesWithDefaultValuesArrayOfObjsOrUndefined)) {
    for(let integrationFieldDbNameWithDefaultValueObj of i_integrationFieldDbNamesWithDefaultValuesArrayOfObjsOrUndefined) { //loop through each integration field with a default value defined
      var matchingIntegrationFieldsValuesObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(integrationFieldsValuesArrayOfObjs, "integrationFieldDbName", integrationFieldDbNameWithDefaultValueObj.integrationFieldDbName);
      if(matchingIntegrationFieldsValuesObjOrUndefined === undefined) { //if the field with a set default value does not already exist in the full list of mapped fields with set values, append this field with its default value
        integrationFieldsValuesArrayOfObjs.push({
          integrationFieldDbName: integrationFieldDbNameWithDefaultValueObj.integrationFieldDbName,
          integrationValueString: integrationFieldDbNameWithDefaultValueObj.defaultValueString
        });

        //add debug string from single CE fieldID to array (include f index number)
        debugStringsArray.push("##default - " + ceFieldDebugString + "##");
      }
    }
  }

  //count the total fields to be inserted into the integration system
  const numIntegrationFieldValuePairs = integrationFieldsValuesArrayOfObjs.length;

  //create the changelog strings
  const changelogNewCEValueString = changelogNewCEFieldAndValueStringsArray.join("\n");
  const changelogIntegrationNewValueString = changelogIntegrationNewFieldAndValueStringsArray.join("\n");

  //build up to call the php that inserts an integration system opp with success/error functions for integration log entries
  const C_CallPhpScriptInsertIntegrationOpp = new ClassCallPhpScript("integrationInsertNewIntegrationOpp", jsDescription);

  C_CallPhpScriptInsertIntegrationOpp.add_post_var("i_userID", o_userID);
  C_CallPhpScriptInsertIntegrationOpp.add_post_var("i_integrationSystem", integrationSystem);
  C_CallPhpScriptInsertIntegrationOpp.add_post_var("i_numIntegrationFieldValuePairs", numIntegrationFieldValuePairs);
  for(let i = 0; i < numIntegrationFieldValuePairs; i++) {
    var postVarFieldDbNameString = "i_integrationFieldDbName" + i;
    var postVarValueString = "i_integrationValueString" + i;
    var integrationFieldValueObj = integrationFieldsValuesArrayOfObjs[i];
    C_CallPhpScriptInsertIntegrationOpp.add_post_var(postVarFieldDbNameString, integrationFieldValueObj.integrationFieldDbName);
    C_CallPhpScriptInsertIntegrationOpp.add_post_var(postVarValueString, integrationFieldValueObj.integrationValueString);
  }

  C_CallPhpScriptInsertIntegrationOpp.add_return_vars("integrationErrorMessageOrBlankSuccess");

  const functionOnSuccessIntegrationInsert = (i_parseResponse) => {
    var integrationChangelogErrorMessage = ""; //blank changelog error message value for success
    if(i_parseResponse.integrationErrorMessageOrBlankSuccess !== "") {
      integrationChangelogErrorMessage = i_parseResponse.integrationErrorMessageOrBlankSuccess;
    }

    if(c_companyIntegrationLogRecordSuccessesTF || (integrationChangelogErrorMessage !== "")) {
      integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_newCECaptureID, i_newIntegrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, integrationChangelogErrorMessage);
    }
  }
  C_CallPhpScriptInsertIntegrationOpp.add_function("onSuccess", functionOnSuccessIntegrationInsert);

  const functionOnErrorIntegrationInsert = () => {
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_newCECaptureID, i_newIntegrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, "Unexpected issue handling the request");
  }
  C_CallPhpScriptInsertIntegrationOpp.add_function("onError", functionOnErrorIntegrationInsert);

  C_CallPhpScriptInsertIntegrationOpp.execute();

  //record_z_error(jsDescription, debugStringsArray.join("\n"));
}


export function integration_delete_integration_opp(i_ceCaptureID, i_ceDeletedCaptureMapOrUndefined) {
  const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
  const c_integrationSystemDisplayName = AdminIntegrationsMobx.c_integrationSystemDisplayName;
  const c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined = AdminIntegrationsMobx.c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined;
  const c_companyIntegrationLogRecordSuccessesTF = DatabaseMobx.c_companyIntegrationLogRecordSuccessesTF;
  const c_fieldMapOfCaptureID = DatabaseMobx.c_fieldMapOfCaptureID;
  const o_userID = UserMobx.o_userID;

  const integrationSystem = "vantagepoint";

  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "integration_delete_integration_opp", ["i_ceCaptureID", "i_ceDeletedCaptureMapOrUndefined"], [i_ceCaptureID, i_ceDeletedCaptureMapOrUndefined]);

  //changelog values
  const iudActionString = "dCEdIN";
  var integrationOppUniqueIDString = "--";
  const changelogCaptureFieldID = -1;
  const changelogIntegrationFieldDbName = "{Delete Existing Opportunity}";
  const changelogNewCEValueString = "--";
  const changelogIntegrationNewValueString = "--";

  //check that the i_ceCaptureID provided is a valid CE capture (need this to extract the existing integration unique ID from the CE capture)
  if(i_ceDeletedCaptureMapOrUndefined === undefined) {
    const captureIDNotAValidCaptureErrorMessage = c_productStylingObj.productName + " " + c_fieldMapOfCaptureID.get("display_name") + " '" + i_ceCaptureID + "' is not a valid capture";
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, captureIDNotAValidCaptureErrorMessage);
    return;
  }

  //verify if all Setup subtab fields are filled out
  const setupErrorMessageOrUndefined = integration_verify_all_setup_and_mapping_is_filled_out_undefined_or_error_message_string();
  if(setupErrorMessageOrUndefined !== undefined) { //an APi Connection or Unique ID field is not filled out, write an integration log message
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, setupErrorMessageOrUndefined);
    return;
  }

  //load the integration unique ID from this CE capture using the input i_ceCaptureID loaded captureMap
  const ceIntegrationUniqueIDValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_ceDeletedCaptureMapOrUndefined, c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined, true); //plainTextTF is true
  if(!ceIntegrationUniqueIDValueMaskSortIfoObj.isFilledOutTF) { //if this CE capture does not have the integration unique ID field filled out
    const ceIntegrationUniqueIDFieldIsNotFilledOutErrorMessage = c_productStylingObj.productName +  " Capture Field '" + c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined.get("display_name") + "' is not filled out with the " + c_integrationSystemDisplayName + " unique ID that links this opportunity across the 2 systems";
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, ceIntegrationUniqueIDFieldIsNotFilledOutErrorMessage);
    return;
  }

  //get the CE integration opp uniqueID string stored in the CE capture linking the CE capture to the integration system opp
  integrationOppUniqueIDString = ceIntegrationUniqueIDValueMaskSortIfoObj.valueMaskPlainText;

  const C_CallPhpScriptDeleteIntegrationOpp = new ClassCallPhpScript("integrationDeleteIntegrationOpp", jsDescription);

  C_CallPhpScriptDeleteIntegrationOpp.add_post_var("i_userID", o_userID);
  C_CallPhpScriptDeleteIntegrationOpp.add_post_var("i_integrationSystem", integrationSystem);
  C_CallPhpScriptDeleteIntegrationOpp.add_post_var("i_integrationOppUniqueIDString", integrationOppUniqueIDString);

  C_CallPhpScriptDeleteIntegrationOpp.add_return_vars("integrationErrorMessageOrBlankSuccess");

  const functionOnSuccessIntegrationDelete = (i_parseResponse) => {
    var integrationChangelogErrorMessage = ""; //blank changelog error message value for success
    if(i_parseResponse.integrationErrorMessageOrBlankSuccess !== "") {
      integrationChangelogErrorMessage = i_parseResponse.integrationErrorMessageOrBlankSuccess;
    }
    
    if(c_companyIntegrationLogRecordSuccessesTF || (integrationChangelogErrorMessage !== "")) {
      integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, integrationChangelogErrorMessage);
    }
  }
  C_CallPhpScriptDeleteIntegrationOpp.add_function("onSuccess", functionOnSuccessIntegrationDelete);

  const functionOnErrorIntegrationDelete = () => {
    integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, "Unexpected issue handling the request");
  }
  C_CallPhpScriptDeleteIntegrationOpp.add_function("onError", functionOnErrorIntegrationDelete);

  C_CallPhpScriptDeleteIntegrationOpp.execute();
}


function integration_verify_all_setup_and_mapping_is_filled_out_undefined_or_error_message_string() {
  const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
  const c_setupAllIntegrationApiConnectionFieldsArrayOfObjs = AdminIntegrationsMobx.c_setupAllIntegrationApiConnectionFieldsArrayOfObjs;
  const c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined = AdminIntegrationsMobx.c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined;

  //check that every 'API Connection' field on the integration Setup tab is filled out
  const notFilledOutApiConnectionFieldDisplayNamesArray = JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(c_setupAllIntegrationApiConnectionFieldsArrayOfObjs, "fieldDisplayName", "isFilledOutTF", false);
  if(notFilledOutApiConnectionFieldDisplayNamesArray.length > 0) {
    return("'API Connection' field(s) on the Setup tab are not filled out: " + JSFUNC.convert_array_to_display_comma_list(notFilledOutApiConnectionFieldDisplayNamesArray));
  }

  //check that 
  if(c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined === undefined) {
    return("'Unique ID Field Created in " + c_productStylingObj.productName + "' field on the Setup tab is not filled out");
  }

  return(undefined);
}


function integration_find_all_integration_field_db_names_and_mapped_values_matching_ce_field_id_and_ce_value_raw(i_iudActionString, i_ceCaptureID, i_ceFieldID, i_ceValueRaw, i_newIntegrationOppUniqueIDString) {
  const c_tbl_captures_fields = DatabaseMobx.c_tbl_captures_fields;
  const c_linkedFieldsWithValuesArrayOfObjs = AdminIntegrationsMobx.c_linkedFieldsWithValuesArrayOfObjs;

  //initialize output obj vars
  var singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs = [];
  var ceFieldDebugString = "";

  const jsDescription = JSFUNC.js_description_from_action("JSPHP", "integration_find_all_integration_field_db_names_and_mapped_values_matching_ce_field_id_and_ce_value_raw", ["i_iudActionString", "i_ceCaptureID", "i_ceFieldID", "i_ceValueRaw", "i_newIntegrationOppUniqueIDString"], [i_iudActionString, i_ceCaptureID, i_ceFieldID, i_ceValueRaw, i_newIntegrationOppUniqueIDString]);

  ceFieldDebugString += "[ceCID" + i_ceCaptureID + ", ceFID" + i_ceFieldID + "] [[" + i_ceValueRaw + "]]";

  //find the integration linked fields pair(s) containing this i_ceFieldID
  var ceFieldMatchingLinkedFieldsWithValuesArrayOfObjs = JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(c_linkedFieldsWithValuesArrayOfObjs, "capture_field_id", i_ceFieldID);
  if(ceFieldMatchingLinkedFieldsWithValuesArrayOfObjs.length === 0) {
    ceFieldDebugString += " (no 'linked fields' created that includes capture fieldID " + i_ceFieldID + ")";
  }
  else {
    //verify CE field and get its properties, mask the new raw CE value for changelogs
    var insertCECaptureFieldExpandedCaptureFieldMapOrUndefined = c_tbl_captures_fields.get(i_ceFieldID);
    if(insertCECaptureFieldExpandedCaptureFieldMapOrUndefined === undefined) { //CE capture field does not exist, changelog error and leave this field off of the integration insert list
      var ceFieldMatchingIntegrationFieldDbNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(ceFieldMatchingLinkedFieldsWithValuesArrayOfObjs, "integration_field_db_name");
      var ceFieldMatchingIntegrationFieldDbNamesCommaDisplayList = JSFUNC.convert_array_to_display_comma_list(ceFieldMatchingIntegrationFieldDbNamesArray);
      var noCEFieldMatchingCEFieldIDErrorMessage = "CE Capture Field ID '" + i_ceFieldID + "' does not exist in CE";
      integration_insert_integration_changelog_entry(jsDescription, i_iudActionString, i_ceCaptureID, i_newIntegrationOppUniqueIDString, i_ceFieldID, ceFieldMatchingIntegrationFieldDbNamesCommaDisplayList, i_ceValueRaw, "", noCEFieldMatchingCEFieldIDErrorMessage);
      ceFieldDebugString += " !!!!! No CE Field matching i_ceFieldID !!!!!";
    }
    else { //CE field is valid
      //get masked and ifo values for CE capture raw value
      var ceFieldDisplayName = insertCECaptureFieldExpandedCaptureFieldMapOrUndefined.get("display_name");
      var ceFieldFieldTypeObj = insertCECaptureFieldExpandedCaptureFieldMapOrUndefined.get("fieldTypeObj");
      var ceValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_ceValueRaw, ceFieldFieldTypeObj);

      //create the "i_ceValueRaw {ceValueMaskPlainText}" changelog entry string from the CE raw value
      var ceValueRawString = JSFUNC.num2str(i_ceValueRaw);
      var ceValueTrueRawIntegrationsString = JSFUNC.num2str(ceValueMaskSortIfoObj.valueTrueRawIntegrations);
      var ceNewValueMaskPlainTextString = JSFUNC.num2str(ceValueMaskSortIfoObj.valueMaskPlainText);
      var ceNewValueChangelogString = ceValueRawString;
      if(ceValueRawString !== ceNewValueMaskPlainTextString) { //if the mask value of this updated field value is different from the raw value, display both for the CE changelog column
        ceNewValueChangelogString = ceValueRawString + " {" + ceNewValueMaskPlainTextString + "}";
      }
      ceFieldDebugString += " [ceFieldDisplayName: '" + ceFieldDisplayName + "'] [[" + ceNewValueChangelogString + "]]";

      //loop over each integration field linked to this CE field (can have multiple different integration fields mapped to the same CE field)
      for(let linkedFieldsWithValuesObj of ceFieldMatchingLinkedFieldsWithValuesArrayOfObjs) {
        //unpack the integration fieldDbName and the mapped values between CE and the integration system
        var integrationFieldDbName = linkedFieldsWithValuesObj.integration_field_db_name; //"key","WBSNumber","WBS1","Name","LongName","Level1Name","Stage","Org",etc (also special "{ignore}" to skip mapped value from going into update)
        var canMapValuesTF = linkedFieldsWithValuesObj.canMapValuesTF;
        var mappedValuesArrayOfObjs = linkedFieldsWithValuesObj.mappedValuesArrayOfObjs;
        var hasAtLeast1MappedValueTF = linkedFieldsWithValuesObj.hasAtLeast1MappedValueTF;
        ceFieldDebugString += " {integrationFieldDbName: '" + integrationFieldDbName + "'}";

        //initialize the new integration value for this field as ""
        var matchingIntegrationValueString = "";

        //if there's mapped matching values between CE and the integration system, find the matching CE value raw and get its mapped integration value
        var matchingCEMappedValueIntegrationIgnoreTF = false;
        var noMatchingCEMappedValueTF = false;
        if(!(canMapValuesTF && hasAtLeast1MappedValueTF)) { //if this CE field can't map values (text/textarea/number/date/etc type fields), or can and has 0 mapped values, use the true raw value for integrations (converted num2str() above) directly
          matchingIntegrationValueString = ceValueTrueRawIntegrationsString;
          ceFieldDebugString += " {{direct '" + matchingIntegrationValueString + "'}}";
        }
        else { //select/multiselect/sharedpercent field that needs value mapping, match the CE value with the mapped integration value string
          var matchingCEValueFoundTF = false; //loop through all mapped values to match input CE value (converted to string or known isFilledOutTF) to the mapped CE raw value string to get its mapped integration value
          for(let mappedValueObj of mappedValuesArrayOfObjs) {
            if((mappedValueObj.capture_field_value_string === ceValueRawString) || (!mappedValueObj.captureFieldValueMaskSortIfoObj.isFilledOutTF && !ceValueMaskSortIfoObj.isFilledOutTF)) { //either string values match exactly, or both are considered not filled out (might be -1 vs 0 or a select raw value which are both not filled out)
              matchingCEValueFoundTF = true; //found a mapped CE raw value string that matched the input CE value

              if(mappedValueObj.integration_value_string === "{ignore}") { //special CE code for integration value mapping to ignore a selected value from CE, don't include this CE field/value as part of the output arrayOfObjs of integration fields/values to update
                matchingIntegrationValueString = "--"; //not used if ignored
                matchingCEMappedValueIntegrationIgnoreTF = true;
                ceFieldDebugString += " {{ignored}}";
              }
              else { //use mapped integration value string found matching input CE value
                matchingIntegrationValueString = mappedValueObj.integration_value_string;
                ceFieldDebugString += " {{matched '" + mappedValueObj.integration_value_string + "'}}";
              }

              break;
            }
          }

          if(!matchingCEValueFoundTF) { //no matching CE raw value string among mapped CE values
            if(!ceValueMaskSortIfoObj.isFilledOutTF) { //CE valueRaw is not filled out, use "" empty string for the integration value (this is done here because the Admin may have mapped a CE value of "" or 0 or -1 to an integration value that is not "", like "empty" or "1" or something they need specifically)
              matchingIntegrationValueString = "";
              ceFieldDebugString += ' {{ce not filled out, "" used}}';
            }
            else { //CE value was filled out, but no matching mapping was found, still continue but write a changelog entry and use "" emptry string for the integration value (changelog error message has "[skipped]" in front to give a different color to the message in the Log)
              matchingIntegrationValueString = "";
              noMatchingCEMappedValueTF = true; //integration field will not be included as matching the CE field (will be essentially skipped)
              var ceValueNotMappedToIntegrationValueErrorMessage = "[skipped] CE value '" + ceNewValueMaskPlainTextString + "' is not mapped to an Integration value, this field was not set or modified in the Integration database";
              integration_insert_integration_changelog_entry(jsDescription, i_iudActionString, i_ceCaptureID, i_newIntegrationOppUniqueIDString, i_ceFieldID, integrationFieldDbName, ceNewValueChangelogString, "{No Mapped Integration Value}", ceValueNotMappedToIntegrationValueErrorMessage);
              ceFieldDebugString += " @@@@@ CE value is not mapped to an integration value @@@@@";
            }
          }
        }

        //apply any special handling steps on each integration value string before sending to the integration database
        if(ceFieldFieldTypeObj.valueDisplayIsDateOrDateTimeTF && !ceValueMaskSortIfoObj.isFilledOutTF) { //any of the 5 date types and not filled out
          if(ceFieldFieldTypeObj.valueDisplayIsDateTF) {
            matchingIntegrationValueString = DatabaseMobx.c_companyIntegrationSpecialIntegrationBlankDate;
          }
          else if(ceFieldFieldTypeObj.valueDisplayIsDateTimeTF) {
            matchingIntegrationValueString = DatabaseMobx.c_companyIntegrationSpecialIntegrationBlankDateTime;
          }
          ceFieldDebugString += " {{specialhandling '" + matchingIntegrationValueString + "'}}";
        }

        //add integration fieldDbName and new value string to the collection array (if not ignored), also computed ce field/value display for changelog entries
        if(!matchingCEMappedValueIntegrationIgnoreTF && !noMatchingCEMappedValueTF) {
          singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs.push({
            ceFieldDisplayName: ceFieldDisplayName,
            ceNewValueChangelogString: ceNewValueChangelogString,
            integrationFieldDbName: integrationFieldDbName,
            integrationValueString: matchingIntegrationValueString
          });
        }
      }
    }
  }

  return({
    singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs: singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs,
    ceFieldDebugString: ceFieldDebugString
  });
}


export function integration_insert_integration_changelog_entry(i_jsDescription, i_iudActionString, i_ceCaptureID, i_integrationOppUniqueIDString, i_changelogCaptureFieldID, i_changelogIntegrationFieldDbName, i_changelogNewCEValueString, i_changelogIntegrationNewValueString, i_integrationsChangelogErrorMessageOrUndefined) {
  const o_userID = UserMobx.o_userID;

  const nowDateTimeUtc = JSFUNC.now_datetime_utc();

  const changelogFieldNamesArray = ["datetime_utc", "user_id", "iud_action_string", "ce_capture_id", "integration_unique_id_string", "ce_field_id", "integration_field_db_name", "new_ce_value_string", "new_integration_value_string", "error_message"];
  const changelogValuesArray = [nowDateTimeUtc, o_userID, i_iudActionString, i_ceCaptureID, i_integrationOppUniqueIDString, i_changelogCaptureFieldID, i_changelogIntegrationFieldDbName, i_changelogNewCEValueString, i_changelogIntegrationNewValueString, i_integrationsChangelogErrorMessageOrUndefined];
  const changelogIdsbArray = ["s", "i", "s", "i", "s", "i", "s", "s", "s", "s"];

  const C_CallPhpTblUID = new ClassCallPhpTblUID(i_jsDescription);
  C_CallPhpTblUID.add_insert("tbl_a_integrations_log", changelogFieldNamesArray, changelogValuesArray, changelogIdsbArray);
  C_CallPhpTblUID.execute();
}





//=====================================================================================================================
//Template Codeword Replacement
//=====================================================================================================================
export function replace_all_codewords_in_xml_data_string(i_fileDataString, i_captureMap=undefined, i_teammateContractID=undefined) {
  //example i_fileDataString: "<t><t><t>[[codeword]]<t><t>[[code<t>word]]<t><t>"
  var replacedArray = []; //final resulting chunks with replaced codewords to be joined at the bottom into 1 replaced string
  const leftBracketSplitArray = i_fileDataString.split("[["); //0 - "<t><t><t>", 1 - "codeword]]<t><t>", 2 - "code<t>word]]<t><t>"
  for(let l = 0; l < leftBracketSplitArray.length; l++) {
    var leftBracketString = leftBracketSplitArray[l];
    var rightBracketSplitArray = leftBracketString.split("]]");
    var numRightBracketStrings = rightBracketSplitArray.length;
    if(numRightBracketStrings === 1) { //chunk does not have a codeword in it (either first chunk or there were two [[ in a row)
      replacedArray.push(leftBracketString);
    }
    else if(numRightBracketStrings > 1) { //chunk contains at least 1 "]]"
      if(l === 0) { //if this is the first chunk, the file had a "]]" before any left ones which means the template was mistyped
        replacedArray.push(leftBracketString);
      }
      else { //valid codeword in first part of this chunk
        //replace codeword and add it to the output array
        var codewordNoTags = JSFUNC.strip_tags(rightBracketSplitArray[0]);
        var replacement = get_codeword_replacement_plaintext_from_capture_map(codewordNoTags, i_captureMap, i_teammateContractID);
        replacedArray.push(replacement);

        //loop through all other right subchunks and add them to the output with no change
        for(let r = 1; r < numRightBracketStrings; r++) {
          replacedArray.push(rightBracketSplitArray[r]);
        }
      }
    }
  }
  return(replacedArray.join(""));
}

function get_codeword_replacement_plaintext_from_capture_map(i_codeword, i_captureMap=undefined, i_teammateContractID=undefined) {
  var codewordReplacementPlaintext = "";

  //ensure the input codeword string (already extracted from the "[[]]") is a string so that split() and substring() can be called
  if(!JSFUNC.is_string(i_codeword)) {
    codewordReplacementPlaintext ="--Codeword is not a string (" + i_codeword + ")--";
  }
  else {
    //get the codeword in lowercase
    const codewordAndModifiersLowercase = i_codeword.toLowerCase();

    //look for any modifiers after the codeword, all separated by "|"
    const modifierBarSplitArray = codewordAndModifiersLowercase.split("|");
    const codewordLowercase = modifierBarSplitArray[0];
    var codewordModifiersArray = [];
    for(let modifierString of modifierBarSplitArray) {
      codewordModifiersArray.push(modifierString);
    }

    var expandedCaptureFieldMap = undefined;
    var codewordCaptureValueRaw = undefined;
    var applyModifiersTF = true; //do not apply modifiers if there is an error message as that message should be printed in full

    //get the value based on the codeword
    if(codewordLowercase.substring(0, 1) === "#") { //hardcoded constant codewords (don't require a captureMap)
      codewordReplacementPlaintext = get_hardcoded_codeword_replacement_plaintext(codewordLowercase);
    }
    else { //other codword types require the captureMap be provided
      if(i_captureMap === undefined) {
        codewordReplacementPlaintext = "--No captureMap provided (" + codewordLowercase + ")--";
        applyModifiersTF = false;
      }
      else {
        //codeword types
        if(codewordLowercase.substring(0, 5) === "@ds_q") {
          codewordReplacementPlaintext = get_deal_shaping_codeword_replacement_plaintext_from_capture_map(codewordLowercase, i_captureMap);
        }
        else if(codewordLowercase.substring(0, 6) === "@team_") {
          codewordReplacementPlaintext = get_team_codeword_replacement_plaintext_from_capture_map(codewordLowercase, i_captureMap, i_teammateContractID);
        }
        else if(codewordLowercase.substring(0, 5) === "@comp") {
          codewordReplacementPlaintext = get_comp_codeword_replacement_plaintext_from_capture_map(codewordLowercase, i_captureMap);
        }
        else if(codewordLowercase.substring(0, 7) === "@theme_") {
          codewordReplacementPlaintext = get_theme_codeword_replacement_plaintext_from_capture_map(codewordLowercase, i_captureMap);
        }
        else if(codewordLowercase.substring(0, 5) === "@risk") { //"@risk1_...", "@risk2_..."
          codewordReplacementPlaintext = get_risk_codeword_replacement_plaintext_from_capture_map(codewordLowercase, i_captureMap);
        }
        else if(codewordLowercase.substring(0, 8) === "@budget_") {
          codewordReplacementPlaintext = get_budget_codeword_replacement_plaintext_from_capture_map(codewordLowercase, i_captureMap);
        }
        else if(codewordLowercase.substring(0, 5) === "@conv") {
          codewordReplacementPlaintext = get_conversations_codeword_replacement_plaintext_from_capture_map(codewordLowercase, i_captureMap);
        }
        else if(codewordLowercase.substring(0, 9) === "@notepad_") {
          codewordReplacementPlaintext = get_notepad_codeword_replacement_plaintext_from_capture_map(codewordLowercase, i_captureMap);
        }
        else { //all details field codewords
          //try to fetch a valid capture field map from the given codeword
          expandedCaptureFieldMap = expanded_capture_field_map_from_lowercase_codeword_and_expanded_captures_fields_mapOfMaps(codewordLowercase, DatabaseMobx.c_tbl_captures_fields);
          if(expandedCaptureFieldMap === undefined) {
            codewordReplacementPlaintext = "--Invalid codeword field (" + codewordLowercase + ")--";
            applyModifiersTF = false;
          }
          else {
            var codewordReplacementValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, expandedCaptureFieldMap, true); //plainTextTF is true
            codewordCaptureValueRaw = codewordReplacementValueMaskSortIfoCanEditObj.valueRaw; //capture raw value used in some capture related codeword modifier applications
            codewordReplacementPlaintext = codewordReplacementValueMaskSortIfoCanEditObj.valueMaskPlainText;
          }
        }
      }
    }

    //apply any codeword modifiers
    if(applyModifiersTF) {
      codewordReplacementPlaintext = apply_codeword_modifiers_to_codeword_replacement_plaintext_string(codewordReplacementPlaintext, codewordModifiersArray, expandedCaptureFieldMap, codewordCaptureValueRaw);
    }
  }

  //replace any special html chars with their "&qt;" replacements to avoid xml language issues
  var codewordReplacementPlaintext = JSFUNC.htmlspecialchars(codewordReplacementPlaintext);

  return(codewordReplacementPlaintext);
}


function apply_codeword_modifiers_to_codeword_replacement_plaintext_string(i_codewordReplacementPlaintext, i_codewordModifiersArray, i_expandedCaptureFieldMap=undefined, i_codewordCaptureValueRaw=undefined) {
  var codewordReplacementPlaintext = i_codewordReplacementPlaintext;

  //apply any capture field modifiers that completely change the codeword replacement
  if(JSFUNC.is_array(i_codewordModifiersArray) && (i_codewordModifiersArray.length > 0)) {
    if((i_expandedCaptureFieldMap !== undefined) && (i_codewordCaptureValueRaw !== undefined)) {
      const modifierWebsiteMaskTF = JSFUNC.in_array("websitemask", i_codewordModifiersArray);
      const modifierWebsiteAddressTF = JSFUNC.in_array("websiteaddress", i_codewordModifiersArray);
      const modifierNaicsCodeOnlyTF = JSFUNC.in_array("naicscodeonly", i_codewordModifiersArray);
      const modifierNaicsCodeDescOnlyTF = JSFUNC.in_array("naicscodedesconly", i_codewordModifiersArray);
      if(modifierWebsiteMaskTF || modifierWebsiteAddressTF) { //website modifiers
        const fieldTypeObj = i_expandedCaptureFieldMap.get("fieldTypeObj");
        if(fieldTypeObj !== undefined) {
          if(fieldTypeObj.websiteTF) { //if that codeword is a capture field that is a website type
            const [websiteMask, websiteWebAddress] = JSFUNC.website_determine_mask_website(i_codewordCaptureValueRaw);
            if(modifierWebsiteMaskTF) {
              codewordReplacementPlaintext = websiteMask;
            }
            else if(modifierWebsiteAddressTF) {
              codewordReplacementPlaintext = websiteWebAddress;
            }
          }
        }
      }
      else if(modifierNaicsCodeOnlyTF || modifierNaicsCodeDescOnlyTF) { //naics code modifiers
        if(i_expandedCaptureFieldMap.get("selectTblNameIsBITMasterNaicsCodesTF")) { //if the codeword is a select capture field using BIT Master Naics data as the reference data
          //construct a new select or multiselect fieldTypeObj for either of the NAICS code styles
          var modifiedNaicsValueDisplayArraysObj = undefined;
          if(modifierNaicsCodeOnlyTF) { modifiedNaicsValueDisplayArraysObj = DatabaseMobx.c_valueDisplayArraysObjBitMasterNaicsCodesCodes; }
          else if(modifierNaicsCodeDescOnlyTF) { modifiedNaicsValueDisplayArraysObj = DatabaseMobx.c_valueDisplayArraysObjBitMasterNaicsCodesCodeDescOnlys; }

          var naicsFieldIsMultiSelectTF = false;
          const fieldTypeObj = i_expandedCaptureFieldMap.get("fieldTypeObj");
          if(fieldTypeObj !== undefined) {
            if(fieldTypeObj.selectWithSearchDataObj !== undefined) {
              naicsFieldIsMultiSelectTF = fieldTypeObj.selectWithSearchDataObj.isMultiSelectTF;
            }
          }

          const swsOptionsObj = {isMultiSelectTF:naicsFieldIsMultiSelectTF, hasSearchTF:true, hasClearSelectionTF:true};
          const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_display_arrays_obj(modifiedNaicsValueDisplayArraysObj, swsOptionsObj);
          const modifiedNaicsSelectOrMultiSelectFieldTypeObj = DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

          codewordReplacementPlaintext = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_codewordCaptureValueRaw, modifiedNaicsSelectOrMultiSelectFieldTypeObj); //raw capture value is the BIT Master tbl row id or row ids comma (captureValueRawBmNaicsRowIDOrIDsComma)
        }
      }
    }

    //apply any modifiers that were found that cut or alter the final replacement plaintext output
    for(let codewordModifier of i_codewordModifiersArray) {
      if(codewordModifier.substring(0, 8) === "maxchars") {
        if(!JSFUNC.is_string(codewordReplacementPlaintext)) { //if the field is numeric data, convert the output to a string before trimming the char length
          codewordReplacementPlaintext = JSFUNC.num2str(codewordReplacementPlaintext);
        }
        var maxCharsString = codewordModifier.substring(8);
        var maxCharsInt = JSFUNC.str2int(maxCharsString);
        if(JSFUNC.is_number(maxCharsInt) && (maxCharsInt > 0)) {
          codewordReplacementPlaintext = codewordReplacementPlaintext.substring(0, maxCharsInt);
        }
      }
      else if(codewordModifier === "blankifblank") {
        if(JSFUNC.is_string(codewordReplacementPlaintext)) {
          if(codewordReplacementPlaintext.substring(0, 2) === "--") {
            if(codewordReplacementPlaintext.substring(codewordReplacementPlaintext.length - 2) === "--") {
              codewordReplacementPlaintext = "";
            }
          }
        }
      }
    }
  }

  return(codewordReplacementPlaintext);
}


function get_hardcoded_codeword_replacement_plaintext(i_codewordLowercase) {
  if(i_codewordLowercase === "#today_date") {
    return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(JSFUNC.now_date(), DatabaseMobx.c_genericDateFieldTypeObj));
  }
  return("--Invalid hardcoded codeword field (" + i_codewordLowercase + ")--");
}


function get_deal_shaping_codeword_replacement_plaintext_from_capture_map(i_codewordLowercase, i_captureMap) {
  //"@ds_q15"    get question text for questionID 15
  //"@ds_q15a"   get answer to questionID 15
  //"@ds_q15p"   get percent to questionID 15
  const dealShapingCodewordComponentsObjOrUndefined = get_deal_shaping_codeword_components_obj_or_undefined(i_codewordLowercase);
  if(dealShapingCodewordComponentsObjOrUndefined !== undefined) {
    const questionMap = DatabaseMobx.o_tbl_a_shaping_questions_pool.get(dealShapingCodewordComponentsObjOrUndefined.questionID);
    if(questionMap === undefined) {
      return("--Question Does Not Exist (ID: " + dealShapingCodewordComponentsObjOrUndefined.questionID + ")--");
    }

    if(dealShapingCodewordComponentsObjOrUndefined.requestingQuestionTextTF) { //get the question text
      return(questionMap.get("name"));
    }
    else { //get the answer to the question
      const captureID = i_captureMap.get("id");
      const answerType123 = questionMap.get("answer_select1_text2_textarea3");
      if(answerType123 === 1) { //select question
        const captureShapingAnswerSelectMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_c_shaping_answers_select, ["capture_id", "question_id"], [captureID, dealShapingCodewordComponentsObjOrUndefined.questionID]);
        if(captureShapingAnswerSelectMap === undefined) { return("--Not Yet Answered--"); }
        const selectedAnswerID = captureShapingAnswerSelectMap.get("answer_id");
        if(!JSFUNC.select_int_is_filled_out_tf(selectedAnswerID)) {
          return("--No Answer Selected--");
        }

        const selectedAnswerPoolMap = DatabaseMobx.o_tbl_a_shaping_select_answers.get(selectedAnswerID);
        if(selectedAnswerPoolMap === undefined) {
          return("--Answer Does Not Exist (ID: " + selectedAnswerID + ")--");
        }
        
        //requesting select option answer percent only
        if(dealShapingCodewordComponentsObjOrUndefined.requestingAnswerPercentTF) {
          const answerPercent = selectedAnswerPoolMap.get("score0to100");
          return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(answerPercent, DatabaseMobx.c_genericPercentIntFieldTypeObj));
        }

        //requesting selected answer text only
        return(selectedAnswerPoolMap.get("name"));
      }
      else { //text/textarea question (splits score or text for "p" and "a" requests, no combined option of "[30%] Answer here." like in the system)
        const captureShapingAnswerTextareaMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_c_shaping_answers_textarea, ["capture_id", "question_id"], [captureID, dealShapingCodewordComponentsObjOrUndefined.questionID]);
        if(captureShapingAnswerTextareaMap === undefined) {
          return("--Not Yet Answered--");
        }

        //requesting answer percent only
        if(dealShapingCodewordComponentsObjOrUndefined.requestingAnswerPercentTF) {
          const answerPercent = captureShapingAnswerTextareaMap.get("score0to100");
          return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(answerPercent, DatabaseMobx.c_genericPercentIntFieldTypeObj));
        }

        //requesting answer text only
        return(captureShapingAnswerTextareaMap.get("answer_text"));
      }
    }
  }
  return("--Invalid deal shaping codeword field (" + i_codewordLowercase + ")--");
}

function get_deal_shaping_codeword_components_obj_or_undefined(i_codewordLowercase) {
  var requestingQuestionTextTF = false;
  var requestingAnswerTextTF = false;
  var requestingAnswerPercentTF = false;
  var questionID = -1;
  if(i_codewordLowercase.substring(0, 5) === "@ds_q") {
    const questionIDapString = i_codewordLowercase.substring(5); //"15" or "15a" or "15p"
    const questionIDapNumChars = questionIDapString.length;
    const lastChar = questionIDapString.substring(questionIDapNumChars-1); //"5" or "a" or "p"

    if(lastChar === "a") { requestingAnswerTextTF = true; }
    else if(lastChar === "p") { requestingAnswerPercentTF = true; }
    else { requestingQuestionTextTF = true; }

    const questionIDString = ((requestingAnswerTextTF || requestingAnswerPercentTF) ? (questionIDapString.substring(0, questionIDapNumChars-1)) : (questionIDapString));
    questionID = JSFUNC.str2int(questionIDString); //this will also handle any strings that are not "a" or "p" removing any non numbers, so @ds_q15x would return the question text for question id 15

    return({
      requestingQuestionTextTF: requestingQuestionTextTF,
      requestingAnswerTextTF: requestingAnswerTextTF,
      requestingAnswerPercentTF: requestingAnswerPercentTF,
      questionID: questionID
    });
  }

  //not a deal shaping codeword, return undefined
  return(undefined);
}


export function get_team_codeword_replacement_plaintext_from_capture_map(i_codewordLowercase, i_captureMap, i_teammateContractID=undefined) {
  //teammates replacement
  // 1. specify multiple teammates to put in a list
  //  - @team_all_selected_teammates_list_names_sort_alphabetic
  //  - @team_all_selected_teammates_list_names_sort_allocation
  //  - @team_all_selected_teammates_list_names_sort_lb_sb_allocation
  //  - @team_all_selected_teammates_list_names_sort_lb_allocation
  //  - @team_all_selected_teammates_list_names_sort_sb_allocation
  //  - @team_all_selected_teammates_list_names_and_allocation_sort_allocation
  //  - @team_all_selected_teammates_list_names_and_allocation_sort_lb_sb_allocation
  //  - @team_all_selected_teammates_list_names_and_allocation_sort_lb_allocation
  //  - @team_all_selected_teammates_list_names_and_allocation_sort_sb_allocation
  //
  // 2. specify a single teammate by referring to its order number (generic capture templates) (these return "" when the teammates run out (requested sort number is higher than total # fo teammates) so that a table with 5 rows only prints out 3 rows of data and 2 blank rows)
  //  - @team_lb1_field
  //  - @team_sb1_field
  //
  //  - @team_lb1_contactcompany_[contact_company_field]
  //  - @team_lb1_contactperson1_[contact_person_field]
  //  - @team_lb1_contractsmanager_[contact_person_field]
  //  - @team_lb1_workshare_type
  //  - @team_lb1_agreement1_[agreement_field] (current status stage name, agreement length)
  //
  // 3. or specifically contract template codeword replacement where you know 1. capture, 2. teammate, 3. agreement type
  //  - @team_con_contactcompany_[contact_company_field]
  //  - @team_con_contactperson1_[contact_person_field]
  //  - @team_con_contractsmanager_[contact_person_field]
  //  - @team_con_workshare_type
  //  - @team_con_agreement_[agreement_field] (current status stage name, agreement length)

  //determine if codeword is requesting a multi teammate list
  var mutliTeamSortAlphabeticTF = false;
  var multiTeamSortAllocationTF = false;
  var multiTeamSortLbSbAllocationTF = false;
  var multiTeamSortLbAllocationTF = false;
  var multiTeamSortSbAllocationTF = false;
  var showAllocationPercentTF = false;
  if(i_codewordLowercase === "@team_all_selected_teammates_list_names_sort_alphabetic") { mutliTeamSortAlphabeticTF = true; }
  else if(i_codewordLowercase === "@team_all_selected_teammates_list_names_sort_allocation") { multiTeamSortAllocationTF = true; }
  else if(i_codewordLowercase === "@team_all_selected_teammates_list_names_sort_lb_sb_allocation") { multiTeamSortLbSbAllocationTF = true; }
  else if(i_codewordLowercase === "@team_all_selected_teammates_list_names_sort_lb_allocation") { multiTeamSortLbAllocationTF = true; }
  else if(i_codewordLowercase === "@team_all_selected_teammates_list_names_sort_sb_allocation") { multiTeamSortSbAllocationTF = true; }
  else if(i_codewordLowercase === "@team_all_selected_teammates_list_names_and_allocation_sort_allocation") { multiTeamSortAllocationTF = true; showAllocationPercentTF = true; }
  else if(i_codewordLowercase === "@team_all_selected_teammates_list_names_and_allocation_sort_lb_sb_allocation") { multiTeamSortLbSbAllocationTF = true; showAllocationPercentTF = true; }
  else if(i_codewordLowercase === "@team_all_selected_teammates_list_names_and_allocation_sort_lb_allocation") { multiTeamSortLbAllocationTF = true; showAllocationPercentTF = true; }
  else if(i_codewordLowercase === "@team_all_selected_teammates_list_names_and_allocation_sort_sb_allocation") { multiTeamSortSbAllocationTF = true; showAllocationPercentTF = true; }

  if(mutliTeamSortAlphabeticTF || multiTeamSortAllocationTF || multiTeamSortLbSbAllocationTF || multiTeamSortLbAllocationTF || multiTeamSortSbAllocationTF) {
    const captureID = i_captureMap.get("id");
    var allSelectedTeammatesArrayOfObjs = [];
    for(let teammateMap of DatabaseMobx.o_tbl_c_teammates.values()) {
      if(teammateMap.get("capture_id") === captureID) {
        if(teammateMap.get("selected_01") === 1) {
          var teammateContactCompanyID = teammateMap.get("contact_company_id");
          var teammateAllocationPercent = teammateMap.get("allocation_percent");

          var teammateContactCompanyName = get_contact_codeword_replacement(i_captureMap, false, teammateContactCompanyID, "name", "--");
          if(showAllocationPercentTF && JSFUNC.is_number_not_nan_gte_0(teammateAllocationPercent)) {
            teammateContactCompanyName += " (" + JSFUNC.round_percent_to_num_decimals_if_needed_but_show_all_for_less_than_1(teammateAllocationPercent, 0) + ")";
          }
          var teammateContactCompanyNameLowercase = teammateContactCompanyName.toLowerCase();

          var contactCompanyObj = TeammateContractsMobx.teammate_contact_company_obj_from_contact_company_id_and_capture_map(teammateContactCompanyID, i_captureMap);
          var businessTypeSort012 = 2; //sort for invalid type
          if(contactCompanyObj.businessTypeIsLargeTF) { businessTypeSort012 = 0; }
          else if(contactCompanyObj.businessTypeIsSmallTF) { businessTypeSort012 = 1; }

          if(mutliTeamSortAlphabeticTF || multiTeamSortAllocationTF || multiTeamSortLbSbAllocationTF || (multiTeamSortLbAllocationTF && contactCompanyObj.businessTypeIsLargeTF) || (multiTeamSortSbAllocationTF && contactCompanyObj.businessTypeIsSmallTF)) { //only large for lb and small for sb, otherwise all sizes included (including 'invalid')
            allSelectedTeammatesArrayOfObjs.push({
              name: teammateContactCompanyName,
              nameLowercase: teammateContactCompanyNameLowercase,
              allocation_percent: teammateAllocationPercent,
              businessTypeSort012: businessTypeSort012
            });
          }
        }
      }
    }

    if(mutliTeamSortAlphabeticTF) {
      JSFUNC.sort_arrayOfObjs(allSelectedTeammatesArrayOfObjs, "nameLowercase", true);
    }
    else if(multiTeamSortAllocationTF) {
      JSFUNC.sort_arrayOfObjs(allSelectedTeammatesArrayOfObjs, "allocation_percent", false);
    }
    else {
      JSFUNC.sort_arrayOfObjs(allSelectedTeammatesArrayOfObjs, ["businessTypeSort012", "allocation_percent"], [true, false]);
    }

    const allSelectedTeammateNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(allSelectedTeammatesArrayOfObjs, "name");
    return(JSFUNC.convert_array_to_display_comma_list(allSelectedTeammateNamesArray));
  }

  //for single teammate first determine which teammate to load based on either lb1, sb1, or con
  var teammateObj = undefined;
  var teammateContractObj = undefined;
  var fieldString = undefined; //the string after "@team_con_" or "@team_lb1_"
  var agreementFieldString = undefined; //the string after "@team_con_agreement_" or "@team_lb1_agreement1_"
  var agreementErrorString = undefined; //if the requested agreement number was outside of the agreement table or for the specified agreement the process has not been started, this is filled out and returned for all agreement fields (otherwise the teammateContractObj is filled out)

  const codewordFirst10Chars = i_codewordLowercase.substring(0, 10);
  if(codewordFirst10Chars === "@team_con_") {
    if(i_teammateContractID === undefined) {
      return("--Codewords starting with '@team_con_' can only be used in Teammate Contract templates (" + i_codewordLowercase + ")--");
    }

    //load the teammate and contract using the provided id
    const teammateContractMap = DatabaseMobx.o_tbl_c_teammates_contracts.get(i_teammateContractID);
    if(teammateContractMap === undefined) {
      return("--Capture Teammate Contract Record Does Not Exist (ID: " + i_teammateContractID + ")--");
    }
    teammateContractObj = JSFUNC.obj_from_map(teammateContractMap);

    const teammateMap = DatabaseMobx.o_tbl_c_teammates.get(teammateContractObj.teammate_id);
    if(teammateMap === undefined) {
      return("--Capture Teammate Record Does Not Exist (ID: " + teammateContractObj.teammate_id + ")--");
    }
    teammateObj = JSFUNC.obj_from_map(teammateMap);

    //get the field string after @team_con_
    fieldString = i_codewordLowercase.substring(10);

    //check if this is an agreement field
    if(fieldString.substring(0, 10) === "agreement_") {
      agreementFieldString = fieldString.substring(10);
    }
  }
  else {
    const codewordFirst8Chars = i_codewordLowercase.substring(0, 8);
    if(codewordFirst8Chars === "@team_lb" || codewordFirst8Chars === "@team_sb") {
      //determine the teammateObj from the number after lb or sb
      const underscoreSplitArray = i_codewordLowercase.split("_");
      const numChunks = underscoreSplitArray.length;
      if(numChunks >= 3) { //must have at least 3 parts, "@team", "lb1", and "fieldname", can have more for long fieldnames
        const lbSbChunk = underscoreSplitArray[1];
        const lbOrSb = lbSbChunk.substring(0, 2);
        const teammateOrderNumber = JSFUNC.str2int(lbSbChunk.substring(2));
        const lbTrueSbFalse = (lbOrSb === "lb");

        //get teammate based on captureID, selected===1, business_type_id, and order number
        const captureID = i_captureMap.get("id");
        var captureTeammatesLbOrSbArrayOfObjs = [];
        for(let teammateMap of DatabaseMobx.o_tbl_c_teammates.values()) {
          if(teammateMap.get("capture_id") === captureID) {
            if(teammateMap.get("selected_01") === 1) {
              var teammateContactCompanyID = teammateMap.get("contact_company_id");
              var contactCompanyObj = TeammateContractsMobx.teammate_contact_company_obj_from_contact_company_id_and_capture_map(teammateContactCompanyID, i_captureMap);

              if((lbTrueSbFalse && contactCompanyObj.businessTypeIsLargeTF) || (!lbTrueSbFalse && contactCompanyObj.businessTypeIsSmallTF)) {
                captureTeammatesLbOrSbArrayOfObjs.push(JSFUNC.obj_from_map(teammateMap));
              }
            }
          }
        }
        JSFUNC.sort_arrayOfObjs(captureTeammatesLbOrSbArrayOfObjs, "allocation_percent", false);

        if(captureTeammatesLbOrSbArrayOfObjs.length < teammateOrderNumber) { //if the order number requested was greater than the total number of teammates on the team for this capture (matching lb/sb), return "" so that a template table can have empty cells
          return("");
        }

        //get the requested teammate by highest percent allocation order number
        var teammateArrayIndex = (teammateOrderNumber - 1);
        teammateObj = captureTeammatesLbOrSbArrayOfObjs[teammateArrayIndex];

        //put together the field string
        var fieldStringArray = [];
        for(let c = 2; c < numChunks; c++) {
          fieldStringArray.push(underscoreSplitArray[c]);
        }
        fieldString = fieldStringArray.join("_");

        //determine the teammateContractObj and agreement string using the number after agreement
        if(fieldString.substring(0, 9) === "agreement") {
          const agreementUnderscoreSplitArray = fieldString.split("_");
          const numAgreementChunks = agreementUnderscoreSplitArray.length;
          if(numAgreementChunks >= 2) { //must have at least 2 parts, "agreement1" and "fieldname"
            const agreementChunk = agreementUnderscoreSplitArray[0];
            const agreementOrderNumber = JSFUNC.str2int(agreementChunk.substring(9));

            //determine which contract type was specified using the agreement order number
            const numTeammateContractTypes = DatabaseMobx.c_teammatesContractTypesArrayOfObjs.length;
            if(numTeammateContractTypes < agreementOrderNumber) {
              agreementErrorString = "--Cannot get teammate contract type #" + agreementOrderNumber + ", only " + numTeammateContractTypes + " " + JSFUNC.plural(numTeammateContractTypes, "contract", "contracts") + " in the system (" + i_codewordLowercase + ")--";
            }
            else {
              const teammateContractTypeObj = DatabaseMobx.c_teammatesContractTypesArrayOfObjs[(agreementOrderNumber - 1)];
              const teammateContractTypeID = teammateContractTypeObj.id;

              //search the teammate contract records table to match the capture_id, teammate_id, and teammate_contract_type_id
              const teammateContractMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_c_teammates_contracts, ["capture_id", "teammate_id", "teammate_contract_type_id"], [captureID, teammateObj.id, teammateContractTypeID]);
              if(teammateContractMap === undefined) { //teammate contract record does not exist, the process has not yet been started for this teammate
                agreementErrorString = "(" + teammateContractTypeObj.short_name + ") Contract process has not been started yet";
              }
              else {
                teammateContractObj = JSFUNC.obj_from_map(teammateContractMap);
              }
            }

            //put togehter the agreement field string
            var agreementFieldStringArray = [];
            for(let c = 1; c < numAgreementChunks; c++) {
              agreementFieldStringArray.push(agreementUnderscoreSplitArray[c]);
            }
            agreementFieldString = agreementFieldStringArray.join("_");
          }
        }
      }
    }
  }

  if(teammateObj === undefined) {
    return("--Invalid teammate codeword (" + i_codewordLowercase + ")--");
  }

  //get fields from the teammate contract based on the agreement string
  if(agreementFieldString !== undefined) {
    //if the lb1/sb1 agreement has resulted in finding a teammate contract record does not exist (process has not beens started), then teammateContractObj set as undefined, return a PNS message for all fields
    if(agreementErrorString !== undefined) {
      if(JSFUNC.in_array(agreementFieldString, ["contract_type_name", "contract_type_short_name", "agreement_type", "status", "requested_by_user", "date_requested", "date_needed", "date_completed"])) {
        return(agreementErrorString);
      }
    }
    else {
      if(agreementFieldString === "contract_type_name" || agreementFieldString === "contract_type_short_name") {
        const contractTypeMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_types", teammateContractObj.teammate_contract_type_id);
        if(agreementFieldString === "contract_type_short_name") {
          return(contractTypeMap.get("short_name"));
        }
        return(contractTypeMap.get("name"));
      }
      else if(agreementFieldString === "agreement_type") {
        return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(teammateContractObj.agreement_type_id, DatabaseMobx.c_selectTeammatesContractAgreementTypeFieldTypeObj));
      }
      else if(agreementFieldString === "status") {
        return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(teammateContractObj.status_id, DatabaseMobx.c_selectTeammatesContractStatusTypeFieldTypeObj));
      }
      else if(agreementFieldString === "requested_by_user") {
        return(DatabaseMobx.user_name_mask_plaintext_from_user_id(teammateContractObj.requested_by_user_id));
      }
      else if(agreementFieldString === "date_requested") {
        return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(teammateContractObj.date_requested, DatabaseMobx.c_genericDateFieldTypeObj));
      }
      else if(agreementFieldString === "date_needed") {
        return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(teammateContractObj.date_needed, DatabaseMobx.c_genericDateFieldTypeObj));
      }
      else if(agreementFieldString === "date_completed") {
        return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(teammateContractObj.date_completed, DatabaseMobx.c_genericDateFieldTypeObj));
      }
    }
    return("--Invalid teammate agreement codeword field (" + i_codewordLowercase + ")--");
  }

  //use the extracted fieldString to get the masked value from teammateObj
  if(fieldString === "allocation_percent") {
    return(JSFUNC.round_number_to_num_decimals_if_needed(teammateObj.allocation_percent, DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateAllocation.get("num_decimals")) + "%");
  }
  else if(fieldString === "allocation_cov") {
    const allocationOfCOV = i_captureMap.get("contract_overall_value") * (teammateObj.allocation_percent / 100);
    return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(allocationOfCOV, DatabaseMobx.c_genericMoneyFieldTypeObj));
  }
  else if(fieldString === "allocation_cov_short") {
    return(JSFUNC.money_short(i_captureMap.get("contract_overall_value") * (teammateObj.allocation_percent / 100)));
  }
  else if(fieldString === "workshare_type") {
    return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(teammateObj.workshare_type_id, DatabaseMobx.c_selectTeammatesWorkshareTypeFieldTypeObj));
  }
  else if(fieldString === "workshare_number_of_ftes") {
    return(teammateObj.workshare_number_of_ftes);
  }
  else if(fieldString === "workshare_notes") {
    return(teammateObj.workshare_notes);
  }
  else if(fieldString === "contact_persons_names_comma") {
    return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(teammateObj.contact_person_ids_comma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj));
  }

  //check contact company fields
  const fieldStringFirst15Chars = fieldString.substring(0, 15);
  if(fieldStringFirst15Chars === "contactcompany_") {
    const contactCompanyFieldString = fieldString.substring(15);
    return(get_contact_codeword_replacement(i_captureMap, false, teammateObj.contact_company_id, contactCompanyFieldString, "--Invalid teammate contact company codeword field (" + i_codewordLowercase + ")--"));
  }

  //check contracts manager contact person fields
  const fieldStringFirst17Chars = fieldString.substring(0, 17);
  if(fieldStringFirst17Chars === "contractsmanager_") {
    const contactPersonFieldString = fieldString.substring(17);
    return(get_contact_codeword_replacement(i_captureMap, true, teammateObj.teammate_contracts_manager_contact_person_id, contactPersonFieldString, "--Invalid teammate contracts manager contact person codeword field (" + i_codewordLowercase + ")--"));
  }

  //check the extra teammate custom fields
  const matchingTeammateExtraFieldObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(DatabaseMobx.c_teammatesExtraFieldsArrayOfObjs, "db_name", fieldString);
  if(matchingTeammateExtraFieldObjOrUndefined !== undefined) {
    return(teammateObj[matchingTeammateExtraFieldObjOrUndefined.db_name]);
  }

  return("--Invalid teammate codeword field (" + i_codewordLowercase + ")--");
}


function get_comp_codeword_replacement_plaintext_from_capture_map(i_codewordLowercase, i_captureMap) {
  //@comp1_field_name
  //@comp1_contactcompany_field_name

  const underscoreSplitArray = i_codewordLowercase.split("_");
  const numChunks = underscoreSplitArray.length;
  if(numChunks >= 2) {
    const compChunk = underscoreSplitArray[0];
    const competitorOrderNumber = JSFUNC.str2int(compChunk.substring(5));

    //get competitor based on order number
    const captureID = i_captureMap.get("id");
    const captureCompetitorsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_competitors, "capture_id", captureID, "sort", true);
    if(captureCompetitorsArrayOfObjs.length < competitorOrderNumber) { //if the order number requested was greater than the total number of competitors for this capture, return "" so that a template table can have empty cells
      return("");
    }

    //get the requested competitor
    const competitorObj = captureCompetitorsArrayOfObjs[(competitorOrderNumber - 1)];

    //put togehter the field string
    var fieldStringArray = [];
    for(let c = 1; c < numChunks; c++) {
      fieldStringArray.push(underscoreSplitArray[c]);
    }
    const fieldString = fieldStringArray.join("_");

    //check competitor fields
    if(fieldString === "teammate_names_comma") {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(competitorObj.teammates_contact_company_ids_comma, DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj));
    }
    else if(fieldString === "strengths") {
      return(competitorObj.strengths);
    }
    else if(fieldString === "weaknesses") {
      return(competitorObj.weaknesses);
    }
    else if(fieldString === "opportunities") {
      return(competitorObj.opportunities);
    }
    else if(fieldString === "threats") {
      return(competitorObj.threats);
    }

    //check contact company fields
    const fieldStringFirst15Chars = fieldString.substring(0, 15);
    if(fieldStringFirst15Chars === "contactcompany_") {
      const contactCompanyFieldString = fieldString.substring(15);
      return(get_contact_codeword_replacement(i_captureMap, false, competitorObj.contact_company_id, contactCompanyFieldString, "--Invalid competitor contact company codeword field (" + i_codewordLowercase + ")--"));
    }

    //check the extra teammate custom fields
    for(let competitorExtraFieldMap of DatabaseMobx.o_tbl_a_competitors_extra_fields.values()) {
      var extraFieldDbName = competitorExtraFieldMap.get("db_name");
      if(fieldString === extraFieldDbName) {
        return(competitorObj[extraFieldDbName]);
      }
    }
  }

  return("--Invalid theme codeword field (" + i_codewordLowercase + ")--");
}


function get_contact_codeword_replacement(i_captureMap, i_isPersonTF, i_contactID, i_fieldString, i_invalidFieldMessage) {
  //special handling of data when contactID is -2 (special teammates flag referring to a particular division in our company based on our_prime_sub_teammate_division_id and our_prime_sub_teammate_allocation)
  if(i_contactID === -2) {
    if(JSFUNC.in_array(i_fieldString, ["name", "legal_name", "abbreviated_name"])) {
      return(DatabaseMobx.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(i_captureMap, DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateDivisionID));
    }
    else if(i_fieldString === "business_type_id") {
      const ourPrimeSubTeammateDivisionID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateDivisionID);
      const divisionMap = DatabaseMobx.c_tbl_a_divisions.get(ourPrimeSubTeammateDivisionID);
      if(divisionMap !== undefined) {
        return(divisionMap.get("businessTypeMaskPlainText"));
      }
    }
    else if(i_fieldString === "sb_certifications_bm_set_aside_ids_comma") {
      const ourPrimeSubTeammateDivisionID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateDivisionID);
      const divisionMap = DatabaseMobx.c_tbl_a_divisions.get(ourPrimeSubTeammateDivisionID);
      if(divisionMap !== undefined) {
        return(divisionMap.get("calcPrimeSBCertificationsMaskPlainText"));
      }
    }
    return(""); //for all other normal contact fields (capabilities, naics, cage, email, phone, custom fields, etc) return "" for our division
  }

  //normal contact field masking
  const contactObj = ContactsMobx.contact_company_or_person_obj_from_id(i_isPersonTF, i_contactID);

  if(i_fieldString === "name") {
    return(ContactsMobx.contact_name_plaintext_from_contact_obj(contactObj));
  }

  const contactFieldsArrayOfObjs = ContactsMobx.contact_fields_arrayOfObjs(i_isPersonTF);
  for(let contactFieldObj of contactFieldsArrayOfObjs) {
    if(i_fieldString === contactFieldObj.db_name) {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(contactObj[i_fieldString], contactFieldObj.fieldTypeObj));
    }
  }
  return(i_invalidFieldMessage);
}


function get_theme_codeword_replacement_plaintext_from_capture_map(i_codewordLowercase, i_captureMap) {
  //@theme_diff1_differentiator, @theme_diff1_justification
  //@theme_win1_theme, @theme_win1_justification
  //@theme_ghost1_theme, @theme_ghost1_justification

  const underscoreSplitArray = i_codewordLowercase.split("_");
  const numChunks = underscoreSplitArray.length;
  if(numChunks >= 3) {
    const themeTypeChunk = underscoreSplitArray[1]; //0 - @theme, 1 - diff1/win1/ghost1, 2 - field_name

    var themeLocalTblName = undefined;
    var themeOrderNumber = undefined;
    if(themeTypeChunk.substring(0, 4) === "diff") {
      themeLocalTblName = "o_tbl_c_pt_differentiators";
      themeOrderNumber = JSFUNC.str2int(themeTypeChunk.substring(4));
    }
    else if(themeTypeChunk.substring(0, 3) === "win") {
      themeLocalTblName = "o_tbl_c_pt_win_themes";
      themeOrderNumber = JSFUNC.str2int(themeTypeChunk.substring(3));
    }
    else if(themeTypeChunk.substring(0, 5) === "ghost") {
      themeLocalTblName = "o_tbl_c_pt_ghost_themes";
      themeOrderNumber = JSFUNC.str2int(themeTypeChunk.substring(5));
    }

    if(themeLocalTblName !== undefined) {
      //get competitor based on order number
      const captureID = i_captureMap.get("id");
      const captureThemesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx[themeLocalTblName], "capture_id", captureID, "sort", true);
      if(captureThemesArrayOfObjs.length < themeOrderNumber) { //if the order number requested was greater than the total number of themes for this capture, return "" so that a template table can have empty cells
        return("");
      }
      const themeObj = captureThemesArrayOfObjs[(themeOrderNumber - 1)];

      //put togehter the field string
      var fieldStringArray = [];
      for(let c = 2; c < numChunks; c++) {
        fieldStringArray.push(underscoreSplitArray[c]);
      }
      const fieldString = fieldStringArray.join("_");

      //return theme fields
      if(fieldString === "differentiator") {
        return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(themeObj.differentiator_id, DatabaseMobx.c_selectAddPTDifferentiatorsFieldTypeObj));
      }
      else if(fieldString === "theme") {
        return(themeObj.theme);
      }
      else if(fieldString === "justification") {
        return(themeObj.justification);
      }
    }
  }

  return("--Invalid theme codeword field (" + i_codewordLowercase + ")--");
}


function get_risk_codeword_replacement_plaintext_from_capture_map(i_codewordLowercase, i_captureMap) {
  //@risk1_category
  //@risk1_probability
  //@risk1_impact
  //@risk1_risk
  //@risk1_mitigation

  const underscoreSplitArray = i_codewordLowercase.split("_"); //0-"@risk", 1-"category"
  const numChunks = underscoreSplitArray.length;
  if(numChunks >= 2) {
    const riskChunk = underscoreSplitArray[0];
    const riskOrderNumber = JSFUNC.str2int(riskChunk.substring(5));

    //get competitor based on order number
    const captureID = i_captureMap.get("id");
    const captureRisksArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_risks, "capture_id", captureID, "sort", true);
    if(captureRisksArrayOfObjs.length < riskOrderNumber) { //if the order number requested was greater than the total number of risks for this capture, return "" so that a template table can have empty cells
      return("");
    }
    const riskObj = captureRisksArrayOfObjs[(riskOrderNumber - 1)];

    //put togehter the field string
    var fieldStringArray = [];
    for(let c = 1; c < numChunks; c++) {
      fieldStringArray.push(underscoreSplitArray[c]);
    }
    const fieldString = fieldStringArray.join("_");

    //return risk fields
    if(fieldString === "category") {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(riskObj.risk_category_id, DatabaseMobx.c_selectRisksCategoriesFieldTypeObj));
    }
    else if(fieldString === "probability") {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(riskObj.probability_risk_level_id, DatabaseMobx.c_selectRisksProbabilityLevelFieldTypeObj));
    }
    else if(fieldString === "impact") {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(riskObj.impact_risk_level_id, DatabaseMobx.c_selectRisksImpactLevelFieldTypeObj));
    }
    else if(fieldString === "risk") {
      return(riskObj.identified_risk);
    }
    else if(fieldString === "mitigation") {
      return(riskObj.mitigation_approach);
    }
  }

  return("--Invalid risk codeword field (" + i_codewordLowercase + ")--");
}


function get_budget_codeword_replacement_plaintext_from_capture_map(i_codewordLowercase, i_captureMap) {
  //@budget_b_p_total_approved_funding  (@budget_capture_total_approved_funding, @budget_custom_category_total_approved_funding, etc for custom budget category names converting the name 'B&P' to a dbName 'b_p')
  //@budget_b_p_remaining
  //@budget_b_p_pending_approval
  //@budget_b_p_spent

  var matchingBudgetCategoryObj = undefined; //from matching "b_p", "capture", "custom_category"
  var budgetFieldString = ""; //"total_approved_funding", "remaining", "pending_approval", "spent"
  for(let budgetCategoryMap of DatabaseMobx.c_tbl_a_budget_categories_pool.values()) { //example i_codewordLowercase is "@budget_b_p_total_approved_funding"
    var budgetCategoryDbName = budgetCategoryMap.get("dbNameFromName"); //"b_p"
    if(JSFUNC.is_string(budgetCategoryDbName)) {
      var budgetCategoryDbNameNumChars = budgetCategoryDbName.length; //3
      var codewordMiddleSegment = i_codewordLowercase.substring(8, 8 + budgetCategoryDbNameNumChars); //"b_p"
      if(codewordMiddleSegment === budgetCategoryDbName) { //"b_p" === "b_p"
        matchingBudgetCategoryObj = JSFUNC.obj_from_map(budgetCategoryMap); //budget category tbl row obj matching dbName of name "b_p"
        budgetFieldString = i_codewordLowercase.substring(9 + budgetCategoryDbNameNumChars); //"total_approved_funding"
      }
    }
  }

  if(matchingBudgetCategoryObj !== undefined) {
    const captureID = i_captureMap.get("id");
    var expandedBudgetCategoryObj = BudgetMobx.expanded_budget_category_obj_from_budget_category_obj(captureID, matchingBudgetCategoryObj, DatabaseMobx.o_tbl_c_budget_funding_requests, DatabaseMobx.o_tbl_c_budget_expenses);

    if(budgetFieldString === "total_approved_funding") {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(expandedBudgetCategoryObj.totalApproved, DatabaseMobx.c_genericMoneyFieldTypeObj));
    }
    else if(budgetFieldString === "remaining") {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(expandedBudgetCategoryObj.totalRemaining, DatabaseMobx.c_genericMoneyFieldTypeObj));
    }
    else if(budgetFieldString === "pending_approval") {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(expandedBudgetCategoryObj.totalPending, DatabaseMobx.c_genericMoneyFieldTypeObj));
    }
    else if(budgetFieldString === "spent") {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(expandedBudgetCategoryObj.totalSpent, DatabaseMobx.c_genericMoneyFieldTypeObj));
    }
  }

  return("--Invalid budget codeword field (" + i_codewordLowercase + ")--");
}


function get_conversations_codeword_replacement_plaintext_from_capture_map(i_codewordLowercase, i_captureMap) {
  //prints multiple conversation records separated by newlines between a specified fixed or relative date range
  //date1 must be earlier than date2

  //@conv_date_notes:td-90:td
  //@conv_datetime_notes:td-90:td
  //@conv_date_persons_notes:td-90:td
  //@conv_datetime_persons_notes:td-90:td
  //@conv_date_notes:2019-01-01:2019-06-06

  //every conv codeword must have 2 colons in it [[fields:date1:date2]]
  const colonSplitArray = i_codewordLowercase.split(":");
  if(colonSplitArray.length === 3) {
    const convFieldsString = colonSplitArray[0];
    const date1String = colonSplitArray[1];
    const date2String = colonSplitArray[2];

    //split the convFieldsString by underscores to determine what fields are printed
    var dateDateTimeString = undefined; //"date" or "datetime"
    var personsString = undefined; //"persons" or undefined if not included
    var notesString = undefined; //"notes"
    const underscoreSplitArray = convFieldsString.split("_");
    const numUnderscoreChunks = underscoreSplitArray.length;
    if(numUnderscoreChunks === 3) { //0-conv, 1-date, 2-notes
      dateDateTimeString = underscoreSplitArray[1];
      notesString = underscoreSplitArray[2];
    }
    else if(numUnderscoreChunks === 4) { //0-conv, 1-date, 2-persons, 3-notes
      dateDateTimeString = underscoreSplitArray[1];
      personsString = underscoreSplitArray[2];
      notesString = underscoreSplitArray[3];
    }

    //"date" or "datetime"
    var dateTrueDateTimeFalse = undefined;
    if(dateDateTimeString === "date") {
      dateTrueDateTimeFalse = true;
    }
    else if(dateDateTimeString === "datetime") {
      dateTrueDateTimeFalse = false;
    }

    //"persons"
    var personsTF = undefined;
    if(personsString === "persons") {
      personsTF = true;
    }
    else if(personsString === undefined) {
      personsTF = false;
    }

    //"notes"
    var notesTF = undefined;
    if(notesString === "notes") {
      notesTF = true
    }

    //check if all fields provided were valid
    if((dateTrueDateTimeFalse !== undefined) && (personsTF !== undefined) && (notesTF !== undefined)) {
      //determine if date1 and date2 are valid
      const date1Raw = convert_conv_date_string_to_raw_date(date1String);
      const date2Raw = convert_conv_date_string_to_raw_date(date2String);
      if((date1Raw !== undefined) && (date2Raw !== undefined) && (date1Raw <= date2Raw)) {
        const captureID = i_captureMap.get("id");
        var conversationsString = "";
        for(let conversationsMap of DatabaseMobx.o_tbl_c_conversations.values()) {
          if(conversationsMap.get("capture_id") === captureID) {
            const conversationDateTimeUtc = conversationsMap.get("datetime_utc");
            const conversationDateTimeJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(conversationDateTimeUtc);
            const conversationDateLocalYmd = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(conversationDateTimeJsDateObj, false);
            if((conversationDateLocalYmd >= date1Raw) && (conversationDateLocalYmd < date2Raw)) {
              //print a new line
              if(conversationsString !== "") {
                conversationsString += "\r\n"; //&#10; &#13; &#A; &#39;
              }

              //format the date/datetime to print
              if(dateTrueDateTimeFalse) {
                conversationsString += DatabaseMobx.get_company_date_format_from_Ymd_date(conversationDateLocalYmd);
              }
              else {
                conversationsString += DatabaseMobx.get_company_date_format_from_Ymd_date(conversationDateLocalYmd);
                conversationsString += " " + JSFUNC.date_hap(conversationDateTimeJsDateObj) + ":" + JSFUNC.date_ii(conversationDateTimeJsDateObj) + " " + JSFUNC.date_ampm(conversationDateTimeJsDateObj);
              }

              //include contact persons list if filled out
              const conversationContactPersonIDsComma = conversationsMap.get("contact_person_ids_comma");
              if(personsTF && JSFUNC.selectmulti_is_filled_out_tf(conversationContactPersonIDsComma)) {
                conversationsString += " - [" + DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(conversationContactPersonIDsComma, DatabaseMobx.c_selectMultiContactPersonsFieldTypeObj) + "]";
              }

              conversationsString += " - " + conversationsMap.get("notes");
            }
          }
        }
        return(conversationsString);
      }
    }
  }

  return("--Invalid conversations codeword field (" + i_codewordLowercase + ")--");
}

function convert_conv_date_string_to_raw_date(i_convDateString) {
  //"td" (today's date)
  //"td-90" (today's date minus 90 days)
  //"td+180" (today's date plus 180 days)
  //"2019-04-04" (fixed date)

  if(JSFUNC.is_string(i_convDateString)) {
    const tdString = i_convDateString.substring(0, 2);
    if(tdString === "td") { //"td" is the first 2 letters of this date string
      if(i_convDateString === "td") { //the entire string is just "td"
        return(JSFUNC.now_date()); //return today's date
      }
      else { //string is td-90 or td+180
        const relativeNumDaysString = i_convDateString.substring(2);
        if(!isNaN(relativeNumDaysString)) { //string is a valid number
          const relativeNumDaysInt = JSFUNC.str2int(relativeNumDaysString);
          const todayDate = JSFUNC.now_date();
          return(JSFUNC.date_add_days(todayDate, relativeNumDaysInt)); //return today's date +/- relative offset number of days
        }
      }
    }

    //date is a fixed date
    const translatedFixedDate = JSFUNC.convert_any_style_string_to_ymd_date(i_convDateString);
    if(JSFUNC.date_is_filled_out_tf(translatedFixedDate)) {
      return(translatedFixedDate);
    }
  }
  return(undefined); //undefined returned if the input string is not formatted correctly
}




function get_notepad_codeword_replacement_plaintext_from_capture_map(i_codewordLowercase, i_captureMap) {
  //@notepad_notestamps_firstpinnedorrecent - either 1st pinned (if any) or most recently created
  //@notepad_notestamps_mostrecentlycreated - most recently created (ignoring pinned)
  //@notepad_notestamps_mostrecentlyedited - most recently edited (ignoring pinned)
  //@notepad_notestamps - prints all data from all notestamps
  if(JSFUNC.in_array(i_codewordLowercase, ["@notepad_notestamps_firstpinnedorrecent", "@notepad_notestamps_mostrecentlycreated", "@notepad_notestamps_mostrecentlyedited"])) {
    const captureID = i_captureMap.get("id");

    var sortFieldNamesArray = ["created_datetime_utc"];
    var sortIsAscTFArray = [false];
    if(i_codewordLowercase === "@notepad_notestamps_firstpinnedorrecent") {
      sortFieldNamesArray = ["pinned_01", "sort", "created_datetime_utc"];
      sortIsAscTFArray = [false, true, false];
    }
    else if(i_codewordLowercase === "@notepad_notestamps_mostrecentlycreated") {
      sortFieldNamesArray = ["created_datetime_utc"];
      sortIsAscTFArray = [false];
    }
    else if(i_codewordLowercase === "@notepad_notestamps_mostrecentlyedited") {
      sortFieldNamesArray = ["last_edited_datetime_utc"];
      sortIsAscTFArray = [false];
    }

    const filteredSortedCaptureNotestampsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_c_notepad_note_stamps, "capture_id", captureID, sortFieldNamesArray, sortIsAscTFArray);
    if(filteredSortedCaptureNotestampsArrayOfObjs.length > 0) {
      var firstNotestampObj = filteredSortedCaptureNotestampsArrayOfObjs[0];
      var firstNotestampDateYmdLocal = DatabaseMobx.get_company_date_format_from_Ymdhis_datetimeutc_natural(firstNotestampObj.created_datetime_utc);
      var firstNotestampContentString = "[" + firstNotestampDateYmdLocal + "] ";
      if(JSFUNC.string_is_filled_out_tf(firstNotestampObj.title)) {
        firstNotestampContentString += firstNotestampObj.title + ": ";
      }
      firstNotestampContentString += "'" + firstNotestampObj.body + "'";
      return(firstNotestampContentString);
    }
    return("--No Note Stamps created yet--");
  }
  else if(i_codewordLowercase === "@notepad_notestamps") {
    const captureID = i_captureMap.get("id");
    const captureExpandedSortedNoteStampsArrayOfObjs = OpenCaptureMobx.compute_expanded_sorted_note_stamps_arrayOfObjs_from_capture_id(captureID);
  
    var allNotepadNoteStampsText = "";
    for(let noteStampObj of captureExpandedSortedNoteStampsArrayOfObjs) {
      if(allNotepadNoteStampsText !== "") {
        allNotepadNoteStampsText += "\n\n----------------------------------------------------------------------------------------------------\n\n";
      }

      allNotepadNoteStampsText += noteStampObj.infoBlockPlainText;
    }

    if(allNotepadNoteStampsText === "") {
      return("--No Note Stamps created yet--");
    }
    return(allNotepadNoteStampsText);
  }

  return("--Invalid notepad codeword field (" + i_codewordLowercase + ")--");
}






function expanded_capture_field_map_from_lowercase_codeword_and_expanded_captures_fields_mapOfMaps(i_lowercaseCodeword, i_expandedCaptureFieldsMapOfMaps) {
  for(let expandedCaptureFieldMap of i_expandedCaptureFieldsMapOfMaps.values()) {
    if(JSFUNC.in_array(i_lowercaseCodeword, expandedCaptureFieldMap.get("lowercaseCodewordsArray"))) {
      return(expandedCaptureFieldMap);
    }
  }
  return(undefined);
}












//=====================================================================================================================
//Excel Report Writer from Excel Report Template
//=====================================================================================================================
export function write_excel_report_xml_from_template_xml_data_string(i_excelReportTemplateXml, i_functionOnFinish) {
  //xObj: {
  //  xmlPreamble: "preamble xml before first VCR",
  //  vcrArrayOfObjs: [
  //    {
  //      vcrPreamble: "preamble in codeword row from beginning of <Row up to the first [[codeword]]",
  //      codewordsArrayOfObjs: [
  //        {
  //          codewordCellStart: xml from the closest <Cell tag up to the codeword,
  //          codeword: "codeword",
  //          codewordExpandedCaptureFieldMap: Map() (or undefined if codeword is not a valid capture field),
  //          dealShapingCodewordComponentsObjOrUndefined: deal shaping codeword components obj if codeword starts with "@ds_" to indicate a value from the deal shaping system, otherwise undefined that the codeword is some other type
  //          codewordIsAtTeamTF: true if codeword starts with "@team_" to indicate a value from the teammate system
  //          codewordModifiersArray: array of all modifier strings found after the codeword  [[website_Field|websitemask|maxchars20]] -> ["websitemask", "maxchars20"]
  //          codewordModifierIsSplitDivisionTF: if the codeword is a money field with the modifier "splitdivision", for example [[contract_overall_value|splitdivision]]
  //          codewordCellEnd: xml after the codeword until the next </Cell> tag,
  //          codewordConclusion: all xml from the end of this codeword after the cell end to the beginning of the next codeword
  //        }
  //      ],
  //      sortedFilteredCapturesArrayOfMaps: [array of all filtered captures from filter preset, sorted based on the provided sort preset],
  //      expandedFiltersArrayOfObjs: array of filters used to filter the captures
  //      totalBlocksArrayOfObjs: [
  //        {
  //          expandedCaptureFieldsArrayOfMaps: [array of expanded capture field maps that were found after the total: with a comma list of field codewords, false if any of the fields do not match a capture field],
  //          numRows: 111,
  //          xml: "xml",
  //          xCodewordsArray: []
  //        }
  //      ],
  //      vcrConclusion: "conclusion to VCR block which includes the VCR and all totalBlock rows, up to the beginning of the next VCR"
  //    }
  //  ],
  //  reportUniqueTextareaCaptureFieldDbNamesArray: example ["description_of_work", "notes"],   array of every unique tbl_captures column db_name of a textarea field that is used anywhere in the report, if empty, then no data needs to be loaded with php
  //  reportUniqueCaptureDealShapingQuestionIDsArray: example [2, 15, 4, 29],   array of every unique deal shaping questionID (question, answer text, or answer percent) used anywhere in the report, if empty or undefined, then no data needs to be loaded with php
  //  reportUniqueCaptureIDsArray: [array of all unique captureIDs resulting from every filter/VCR in the report, this determines which textareas to load from the database if needed]
  //}
  //
  //excel_xml_get_first_filter_sort_total_codeword_obj_from_xml_string()
  //  - foundCodewordTF
  //  - xmlLeft
  //  - codewordContent
  //  - xmlRight

  /*
  ***************************************************************************
  EXAMPLE CODEWORD ROW XML
  Raw xml for this codeword row
  --------------------------
  <Row ss:AutoFitHeight="0" ss:StyleID="s92">
    <Cell><Data ss:Type="String">nothing</Data></Cell>
    <Cell><Data ss:Type="String">[[date1]]</Data></Cell>
    <Cell><Data ss:Type="String">[[date2]]</Data></Cell>
    <Cell><Data ss:Type="String">also nothing</Data></Cell>
    <Cell><Data ss:Type="String">[[opp_name]]</Data></Cell>
    <Cell><Data ss:Type="String">more nothing</Data></Cell>
  </Row>
  --------------------------

  vcrObj:
    vcrPreamble: '<Row ss:AutoFitHeight="0" ss:StyleID="s92"><Cell><Data ss:Type="String">nothing</Data></Cell>',
    codewordsArrayOfObjs: [
      {
        codewordCellStart: '<Cell><Data ss:Type="String">'
        codeword: 'date1'
        codewordExpandedCaptureFieldMap: date1 field map
        codewordCellEnd: '</Data></Cell>'
        codewordConclusion: ''
      },
      {
        codewordCellStart: '<Cell><Data ss:Type="String">'
        codeword: 'date2'
        codewordExpandedCaptureFieldMap: date2 field map
        codewordCellEnd: '</Data></Cell>'
        codewordConclusion: '<Cell><Data ss:Type="String">also nothing</Data></Cell>'
      },
      {
        codewordCellStart: '<Cell><Data ss:Type="String">'
        codeword: 'opp_name'
        codewordExpandedCaptureFieldMap: opp_name field map
        codewordCellEnd: '</Data></Cell>'
        codewordConclusion: '<Cell><Data ss:Type="String">more nothing</Data></Cell></Row>'
      }
    ],
    sortedFilteredCapturesArrayOfMaps: array of 300 captures that match the specified filter and sort of this VCR

  loop over 300 captures to print, capture #1 has date1 and date2 filled in, capture #2 has date1 and date2 as "0000-00-00" and "1884-08-12" (anything prior to 1900 is considered a bad date by excel formulas and must be handled by putting a <Cell/> blank cell in)
  --------------------------
  <Row ss:AutoFitHeight="0" ss:StyleID="s92">
    <Cell><Data ss:Type="String">nothing</Data></Cell>
    <Cell><Data ss:Type="String">2020-10-10</Data></Cell>
    <Cell><Data ss:Type="String">2016-09-23</Data></Cell>
    <Cell><Data ss:Type="String">also nothing</Data></Cell>
    <Cell><Data ss:Type="String">Capture #1 Opportunity Name</Data></Cell>
    <Cell><Data ss:Type="String">more nothing</Data></Cell>
  </Row>
  <Row ss:AutoFitHeight="0" ss:StyleID="s92">
    <Cell><Data ss:Type="String">nothing</Data></Cell>
    <Cell/>
    <Cell/>
    <Cell><Data ss:Type="String">also nothing</Data></Cell>
    <Cell><Data ss:Type="String">Capture #2 Opportunity Name</Data></Cell>
    <Cell><Data ss:Type="String">more nothing</Data></Cell>
  </Row>
  --------------------------
  ***************************************************************************
  */

  //error check the input string
  if(!JSFUNC.is_string(i_excelReportTemplateXml)) {
    const xmlOut = "--write_excel_report_xml_from_template_xml_data_string input is not a string (i_excelReportTemplateXml (" + typeof(i_excelReportTemplateXml) + ") - " + i_excelReportTemplateXml + ")--";
    i_functionOnFinish(xmlOut);
    return;
  }

  //preload the DatabaseMobx computed expandedCaptureFieldsMapOfMaps (c_tbl_captures_fields) so that it only recomputes once at the top of this function (Mobx recomputes an entire computed every time it is accessed (like within loops), even if only for read only purposes for single rows)
  const c_tbl_captures_fields = DatabaseMobx.c_tbl_captures_fields;

  //insert blank row tags
  var xmlIn = excel_xml_insert_blank_row_tags(i_excelReportTemplateXml); //[1ms]

  //break up entire xml into an array by finding <Row></Row> tags
  const rowsObj = excel_xml_break_xml_by_rows_into_rows_obj(xmlIn); //[2ms]

  //create the xObj that holds data for each uniquely identified "valid codeword row" ("VCR") and all the rows below it before the next codeword row, also determine if any textarea fields are needed from which captureIDs which requires a database call to load those values
  const xObj = excel_xml_break_rows_obj_into_preamble_and_vcr_arrayOfObjs(rowsObj, c_tbl_captures_fields); //[20s]

  //determine if any textarea codewords were in the report for any captures, if so they need to be loaded from the database for each capture in the report
  if((xObj.reportUniqueCaptureIDsArray.length > 0) && (JSFUNC.is_array_not_empty(xObj.reportUniqueTextareaCaptureFieldDbNamesArray) || JSFUNC.is_array_not_empty(xObj.reportUniqueCaptureDealShapingQuestionIDsArray))) { //need to load capture textarea fields from database
    const jsDescription = JSFUNC.js_description_from_action("JSPHP", "write_excel_report_xml_from_template_xml_data_string", [], []);
    const C_CallPhpScript = new ClassCallPhpScript("loadMultipleCapturesFullTextareaDataAndDealShapingData", jsDescription);
    C_CallPhpScript.add_post_var("i_captureIDsComma", JSFUNC.convert_array_to_comma_list(xObj.reportUniqueCaptureIDsArray));
    C_CallPhpScript.add_post_var("i_captureFieldDbNamesComma", JSFUNC.convert_array_to_comma_list(xObj.reportUniqueTextareaCaptureFieldDbNamesArray));
    C_CallPhpScript.add_post_var("i_captureDealShapingQuestionIDsComma", JSFUNC.convert_array_to_comma_list(xObj.reportUniqueCaptureDealShapingQuestionIDsArray));
    C_CallPhpScript.add_return_vars(["capturesTextareaDataOr0String", "capturesDealShapingDataOr0String"]);

    const functionOnSuccess = (i_parseResponse) => {
      var capturesTextareaDataArrayOfObjsOrUndefined = undefined;
      if(JSFUNC.is_array(i_parseResponse.capturesTextareaDataOr0String)) {
        capturesTextareaDataArrayOfObjsOrUndefined = i_parseResponse.capturesTextareaDataOr0String;
      }

      var capturesDealShapingDataArrayOfObjsOrUndefined = undefined;
      if(JSFUNC.is_array(i_parseResponse.capturesDealShapingDataOr0String)) {
        capturesDealShapingDataArrayOfObjsOrUndefined = i_parseResponse.capturesDealShapingDataOr0String;
      }

      const xmlOut = excel_xml_build_xml_out_from_preamble_and_vcr_arrayOfObjs(xObj, capturesTextareaDataArrayOfObjsOrUndefined, capturesDealShapingDataArrayOfObjsOrUndefined); //[5s]
      i_functionOnFinish(xmlOut);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      const xmlOut = excel_xml_build_xml_out_from_preamble_and_vcr_arrayOfObjs(xObj, undefined, undefined); //[5s]
      i_functionOnFinish(xmlOut);
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();
  }
  else { //no need to load textarea data from database
    //create xmlOut from xmlPreamble and vcrArrayOfObjs, debugArray is used as a debug storage for each written row
  	const xmlOut = excel_xml_build_xml_out_from_preamble_and_vcr_arrayOfObjs(xObj, undefined, undefined); //[5s]

    //call the input provided function when finished to pass the completed xmlOut to
    i_functionOnFinish(xmlOut);
  }
}

function excel_xml_insert_blank_row_tags(i_xmlIn) {
	//excel xml skips blank rows by using "index" inside of <Row> tags to jump to the next row that has information, if the index number is lower than the actual row that it is on (like in the case of inserting rows artificially in this code), the xml will not open
	//here, all row tags are counted with a counter, if "index" is found inside a tag, it is removed, and any skipped rows before it are inserted into the xml as <Row></Row> empty tags
	//<Row ss:Index="13" ... > ... </Row>

	var indexOfPrevRowFound = 0; //counter for how many row tags are found in the excel xml to align it with the "index" values in the row tags
	var xmlRowOut = ""; //initialize the fixed output to blank, then build it piece by piece
	var xmlRowInRemaining = i_xmlIn; //initialize the 'remaining' variable and slowly take pieces off of it
	var rowSearchIsDoneFlag = false;
	while(rowSearchIsDoneFlag == false) {
		var startRowTagPos = xmlRowInRemaining.indexOf("<Row");
		var endRowTagPos = xmlRowInRemaining.indexOf("</Row>");
		if(startRowTagPos < 0 || endRowTagPos < 0) { //if there are no more row tags in the file
			xmlRowOut = xmlRowOut + xmlRowInRemaining; //concatenate that last of the string with no replacements
			rowSearchIsDoneFlag = true;
		}
		else {
			var stringBeforeStartRow = xmlRowInRemaining.substring(0, startRowTagPos); //"..."<Row>...</Row>...
			var stringAfterIncludingStartRowToEndRow = xmlRowInRemaining.substring(startRowTagPos, endRowTagPos); //..."<Row>..."</Row>...
			var stringEndRowTag = xmlRowInRemaining.substring(endRowTagPos, endRowTagPos+6); //...<Row>..."</Row>"...
			var stringAfterEndRow = xmlRowInRemaining.substring(endRowTagPos+6); //...<Row>...</Row>"..."

			var rowTagStartRightBracketPos = stringAfterIncludingStartRowToEndRow.indexOf(">");
			var stringStartRowTag = stringAfterIncludingStartRowToEndRow.substring(0, rowTagStartRightBracketPos+1); //..."<Row>"...</Row>...
			var stringAfterStartRowToEndRow = stringAfterIncludingStartRowToEndRow.substring(rowTagStartRightBracketPos+1); //...<Row>"..."</Row>...

			if(stringBeforeStartRow.indexOf("</Worksheet>") >= 0) {
				indexOfPrevRowFound = 0; //row index counter is reset to 0 if the previous string had a change of worksheets in it
			}

			indexOfPrevRowFound++; //a row was found, increment the counter by 1

			//search the start <Row> tag for an "index" sring  <Row ss:Index="3" ss:Style="72">
			var startRowSSIndexPos = stringStartRowTag.indexOf(' ss:Index="'); //the "index" string is 11 character long
			if(startRowSSIndexPos >= 0) { //if the index string is found, remove it from the tag, insert blank rows before the row tag to equalize the 'rowCount' to that index number
				var indexCloseQuotePos = stringStartRowTag.indexOf('"', startRowSSIndexPos+11);
				var stringBeforeIndex = stringStartRowTag.substring(0, startRowSSIndexPos); //|<Row| ss:Index="3" ss:Style="72">
				var indexNumber = stringStartRowTag.substring(startRowSSIndexPos+11, indexCloseQuotePos); //<Row ss:Index="|3|" ss:Style="72">
				var stringAfterIndex = stringStartRowTag.substring(indexCloseQuotePos+1); //<Row ss:Index="3"| ss:Style="72">|

				//compute the extra blank row tags needed
				var stringBlankRows = ""; // initialize the string of blank row tags
				var numBlankRowsNeeded = indexNumber - indexOfPrevRowFound; //formula based on the expected row number and the jump index number of how many blank rows are needed
				for(let b = 0; b < numBlankRowsNeeded; b++) {
					stringBlankRows += "<Row></Row>";
				}
				indexOfPrevRowFound = indexNumber; //update the row counter to this index number to restart the counting from the correct row

				stringStartRowTag = stringBlankRows + stringBeforeIndex + stringAfterIndex;
			}

			//append the fixed row to xmlRowOut, save xmlRowInRemaining as the end portion after the row for the next loop to search
			xmlRowOut += stringBeforeStartRow + stringStartRowTag + stringAfterStartRowToEndRow + stringEndRowTag;
			xmlRowInRemaining = stringAfterEndRow;
		}
	}
	return(xmlRowOut); //replace the input xml data with the adjusted one that includes blank <Row></Row> tags and removed the "index" field from row tags
}

function excel_xml_break_xml_by_rows_into_rows_obj(i_xmlIn) {
  //example i_xmlIn: "preamble<Row style>content1</Row>mid<Row>content2</Row>conclusion"
  //rowsObj: {
  //  rowsPreamble: "preamble"
  //  rowsArrayOfObjs: [
  //    {rowString: "<Row style>content1</Row>", rowConclusion: "mid"},
  //    {rowString: "<Row>content2</Row>", rowConclusion: "conclusion"}
  //  ]
  //}

  var rowsPreamble = "";
  var rowsArrayOfObjs = [];

  //split on a string always returns an array with length of at least 1
  const rowStartSplitArray = i_xmlIn.split("<Row"); //0 - "preamble", 1 - " style>content1</Row>mid", 2 - ">content2</Row>conclusion"

  //preamble is the first split entry (it's the entire string if 0 rows were found), if there was no preamble and the xml starts with "<Row", the split array does have a first element that is ""
  rowsPreamble = rowStartSplitArray[0];

  //loop through any rows found in the xml after the preamble starting with index 1
  for(let s = 1; s < rowStartSplitArray.length; s++) {
    var rowAndConclusionString = "<Row" + rowStartSplitArray[s]; //"<Row style>content1</Row>mid"     (add the "<Row" back to the front of the string)
    var rowEndSplitArray = rowAndConclusionString.split("</Row>");

    var rowString = undefined;
    var rowConclusion = undefined;
    if(rowEndSplitArray.length === 2) { //most common case with 1 </Row>
      rowString = rowEndSplitArray[0] + "</Row>";
      rowConclusion = rowEndSplitArray[1];
    }
    else { //row does not have </Row> or has multiple </Row>
      rowString = rowAndConclusionString;
      rowConclusion = "";
    }

    rowsArrayOfObjs.push({
      rowString: rowString,
      rowConclusion: rowConclusion
    });
  }

  return({
    rowsPreamble: rowsPreamble,
    rowsArrayOfObjs: rowsArrayOfObjs
  });
}

function excel_xml_break_rows_obj_into_preamble_and_vcr_arrayOfObjs(i_rowsObj, i_expandedCaptureFieldsMapOfMaps) {
  //constants used from other files
  const expandedFilterPresetsArrayOfObjs = DatabaseMobx.c_expandedFilterPresetsArrayOfObjs;
  const expandedSortPresetsArrayOfObjs = DatabaseMobx.c_expandedSortPresetsArrayOfObjs;

  //extract the rowsObj
  const rowsPreamble = i_rowsObj.rowsPreamble;
  const rowsArrayOfObjs = i_rowsObj.rowsArrayOfObjs;

  //initialize the output vars
  var xmlPreamble = rowsPreamble; //initialize with the preamble found in the rowsObj
  var vcrArrayOfObjs = [];
  var reportUniqueTextareaCaptureFieldDbNamesArray = [];
  var reportUniqueCaptureDealShapingQuestionIDsArray = [];
  var reportUniqueCaptureIDsArray = [];

  var vcrIndex = -1; //index for each valid codeword row found
  for(let rowObj of rowsArrayOfObjs) { //loop through each "<Row></Row>" found in the excel xml
    var rowString = rowObj.rowString;
    var rowConclusion = rowObj.rowConclusion;

    //to be a valid codeword row, the row must 1. have a valid [[+filter:capture_filter]] specified at the end of the row, 2. have a valid [[+sort:capture_sort]] specified at the end of the row
    var rowIsValidCodewordRowTF = false;

    //search for a [[+filter:
    var filterCodewordObj = excel_xml_get_first_filter_sort_total_codeword_obj_from_xml_string(rowString, "filter");
    if(filterCodewordObj.foundCodewordTF && JSFUNC.is_string(filterCodewordObj.codewordContent)) { //a [[+filter: was found within this <Row>
      var filterPresetID = -1; //if the filter provided is "" (full codeword would be [[+filter:]]), use a filterPresetID of -1 so that no filters are found and all captures are returned
      if(filterCodewordObj.codewordContent !== "") {
        var filterCodewordLowerCase = filterCodewordObj.codewordContent.toLowerCase();
        var matchingExpandedFilterPresetObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(expandedFilterPresetsArrayOfObjs, "excelReportWriterCodewordLowercase", filterCodewordLowerCase);
        if(matchingExpandedFilterPresetObj === undefined) {
          filterPresetID = undefined; //no filter preset matches the requested filter name in the xml, replace the filter codeword with an error message in the output xml
        }
        else {
          filterPresetID = matchingExpandedFilterPresetObj.id;
        }
      }


      if(filterPresetID === undefined) { //the found filter did not match any in the system, replace the codeword with an error message to be placed into the output xml
        rowString = filterCodewordObj.xmlLeft + "[[--Filter Preset codeword '" + filterCodewordObj.codewordContent + "' does not match any filter presets in the system--]]" + filterCodewordObj.xmlRight;
      }
      else { //the filter preset is valid
        //remove the [[+filter: codeword from the rowString
        rowString = filterCodewordObj.xmlLeft + filterCodewordObj.xmlRight;

        //search for a [[+sort:
        var sortCodewordObj = excel_xml_get_first_filter_sort_total_codeword_obj_from_xml_string(rowString, "sort");
        if(sortCodewordObj.foundCodewordTF && JSFUNC.is_string(sortCodewordObj.codewordContent)) { //a [[+sort: was found within this <Row>
          var sortPresetID = -1; //if the sort provided is "" (full codeword would be [[+sort:]]), use a sortPresetID of -1 so that no sorts are found and all captures are returned
          if(sortCodewordObj.codewordContent !== "") {
            var sortCodewordLowerCase = sortCodewordObj.codewordContent.toLowerCase();
            var matchingExpandedSortPresetObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(expandedSortPresetsArrayOfObjs, "excelReportWriterCodewordLowercase", sortCodewordLowerCase);
            if(matchingExpandedSortPresetObj === undefined) {
              sortPresetID = undefined; //no sort preset matches the requested sort name in the xml, replace the sort codeword with an error message in the output xml
            }
            else {
              sortPresetID = matchingExpandedSortPresetObj.id;
            }
          }

          if(sortPresetID === undefined) { //the found sort did not match any in the system, replace the codeword with an error message to be placed into the output xml
            rowString = sortCodewordObj.xmlLeft + "[[--Sort Preset codeword '" + sortCodewordObj.codewordContent + "' does not match any sort presets in the system--]]" + sortCodewordObj.xmlRight;
          }
          else { //the sort preset is valid
            //remove the [[+sort: codeword from the rowString
            rowString = sortCodewordObj.xmlLeft + sortCodewordObj.xmlRight;

            //mark this row as a valid codeword
            rowIsValidCodewordRowTF = true;
          }
        }
      }
    }

    //create a new vcrObj if the codeword row is valid, otherwise, append this rowObj to the previous VCR or to the preamble if the first one hasn't been created yet
    if(rowIsValidCodewordRowTF) { //create new valid VCR
      //increment the VCR index for this new VCR (and all subsequent rows after this row that may be total rows for this VCR, thus this increment is done prior to using the index)
      vcrIndex++;

      //find all valid [[codewords]] within this potential codeword row
      var vcrObj = excel_xml_get_vcr_preamble_and_codewords_arrayOfObjs_from_vcr_xml(rowString, filterPresetID, sortPresetID, i_expandedCaptureFieldsMapOfMaps); //[>99% of runtime is in this function]
      vcrArrayOfObjs[vcrIndex] = {
        vcrPreamble: vcrObj.vcrPreamble,
        codewordsArrayOfObjs: vcrObj.codewordsArrayOfObjs,
        sortedFilteredCapturesArrayOfMaps: vcrObj.sortedFilteredCapturesArrayOfMaps,
        expandedFiltersArrayOfObjs: vcrObj.expandedFiltersArrayOfObjs,
        totalBlocksArrayOfObjs: [], //initialize the total blocks array
        vcrConclusion: rowConclusion //set the conclusion for this VCR block, this will accrue rows below this VCR until a total row is encountered, where this conclusion will be taken to make that total row
      };

      //loop through all codewords to find either textarea capture fields or deal shaping questions to add them to their unique collection arrays
      for(let codewordObj of vcrObj.codewordsArrayOfObjs) {
        if(codewordObj.codewordExpandedCaptureFieldMap !== undefined) { //textarea codeword capture field dbName added to the unique textarea dbNames array
          var fieldTypeObj = codewordObj.codewordExpandedCaptureFieldMap.get("fieldTypeObj");
          if(fieldTypeObj.textareaTF) {
            reportUniqueTextareaCaptureFieldDbNamesArray.push(codewordObj.codewordExpandedCaptureFieldMap.get("db_name"));
          }
        }
        else if(codewordObj.dealShapingCodewordComponentsObjOrUndefined !== undefined) { //deal shaping question codeword added to the unique question IDs array
          if(codewordObj.dealShapingCodewordComponentsObjOrUndefined.requestingAnswerTextTF || codewordObj.dealShapingCodewordComponentsObjOrUndefined.requestingAnswerPercentTF) { //only need to fetch data from the database if requesting a dsq answer text or percent from this captureID (which is not loaded into memory when doing multiple captures for an excel report)
            reportUniqueCaptureDealShapingQuestionIDsArray.push(codewordObj.dealShapingCodewordComponentsObjOrUndefined.questionID);
          }
        }
      }

      


      //add all the filtered captureIDs to the unique captureID array
      for(let captureMap of vcrObj.sortedFilteredCapturesArrayOfMaps) {
        reportUniqueCaptureIDsArray.push(captureMap.get("id"));
      }
    }
    else { //this row is either a total row or a normal non special excel row, append this row to the previous VCR, or to the preamble if no VCR has been found yet
      //search for a [[+total:
      var totalCodewordObj = excel_xml_get_first_filter_sort_total_codeword_obj_from_xml_string(rowString, "total");
      if(totalCodewordObj.foundCodewordTF && (vcrIndex >= 0)) { //this row is a total row, and it is below a valid codeword row (not above it, which would not work)
        //get the array of expanded field maps from the total codeword fields [~1% of this function's runtime in this captureFieldCodewordsArray for loop]
        var captureFieldCodewordsComma = JSFUNC.remove_whitespace_from_string(totalCodewordObj.codewordContent); //"[[+total:field1, field2]]" -> "field1,field2"
        var expandedCaptureFieldsArrayOfMaps = [];
        var allFieldsValidTF = true;
        var captureFieldCodewordsArray = JSFUNC.convert_comma_list_to_array(captureFieldCodewordsComma); //["field1", "field2"]
        for(let captureFieldCodeword of captureFieldCodewordsArray) {
          if(JSFUNC.is_string(captureFieldCodeword)) {
            var captureFieldCodewordLowerCase = captureFieldCodeword.toLowerCase();
            var expandedCaptureFieldMap = expanded_capture_field_map_from_lowercase_codeword_and_expanded_captures_fields_mapOfMaps(captureFieldCodewordLowerCase, i_expandedCaptureFieldsMapOfMaps);
            if(expandedCaptureFieldMap === undefined) {
              allFieldsValidTF = false;
            }
            else {
              expandedCaptureFieldsArrayOfMaps.push(expandedCaptureFieldMap);
            }
          }
        }

        //put the totalBlock together based on the validity of the capture fields
        var totalBlockXml = undefined;
        var numRows = undefined;
        if(!allFieldsValidTF) { //capture fields are found to be invalid
          expandedCaptureFieldsArrayOfMaps = false;
          var totalRowErrorMessage = "[[+total:" + totalCodewordObj.codewordContent + " --ERROR capture field(s) are invalid--]]";
          totalBlockXml = vcrArrayOfObjs[vcrIndex].vcrConclusion + totalCodewordObj.xmlLeft + totalRowErrorMessage + totalCodewordObj.xmlRight; //insert an error message with the [[+total: codeword
          numRows = -1; //another flag (not needed) that this total block was invalid
        }
        else {
          var totalBlockXmlTotalCodewordRemoved = vcrArrayOfObjs[vcrIndex].vcrConclusion + totalCodewordObj.xmlLeft + totalCodewordObj.xmlRight; //all previous rows from conclusion plus this current total row without the [[+total:]] codeword
          totalBlockXml = excel_xml_total_block_move_subtotals_into_cell_formula(totalBlockXmlTotalCodewordRemoved);
          numRows = (totalBlockXml.split("<Row").length - 1);
        }

        //get all of the xCodewords anywhere in this totalBlock of rows (an xCodeword is any field codeword with "!" in front, like [[!stage_id]])
        var xCodewordsArray = [];
        var leftBracketSplitArray = totalBlockXml.split("[[!");
        for(let x = 1; x < leftBracketSplitArray.length; x++) { //skip the first entry before the first [[! occurance
          var rightBracketSplitArray = leftBracketSplitArray[x].split("]]");
          if(rightBracketSplitArray.length === 2) {
            var xCodeword = rightBracketSplitArray[0];
            if(xCodeword !== "subtotalFormula") {
              xCodewordsArray.push(xCodeword);
            }
          }
        }

        //create and append a new total block obj to the VCR totalBlocksArrayOfObjs, using any xml that is currently in the VCR conclusion
        vcrArrayOfObjs[vcrIndex].totalBlocksArrayOfObjs.push({
          expandedCaptureFieldsArrayOfMaps: expandedCaptureFieldsArrayOfMaps,
          xml: totalBlockXml,
          numRows: numRows,
          xCodewordsArray: xCodewordsArray
        });

        //reset the VCR conclusion as the conclusion of this current total row
        vcrArrayOfObjs[vcrIndex].vcrConclusion = rowConclusion; //new conclusion is this current row's conclusion
      }
      else {
        if(vcrIndex >= 0) { //if at least 1 VCR has been created, append this row to the conclusion of that VCR
          vcrArrayOfObjs[vcrIndex].vcrConclusion += rowString + rowConclusion;
        }
        else { //no VCR has been seen yet, append this row to the preamble
          xmlPreamble += rowString + rowConclusion;
        }
      }
    }
  }

  //only keep unique textarea field db_names collected from all of the VCR columns
  reportUniqueTextareaCaptureFieldDbNamesArray = JSFUNC.unique(reportUniqueTextareaCaptureFieldDbNamesArray);

  //only keep unique deal shaping questionIDs collected from all of the VCR columns
  reportUniqueCaptureDealShapingQuestionIDsArray = JSFUNC.unique(reportUniqueCaptureDealShapingQuestionIDsArray);

  //only keep unique captureIDs collected from all of the VCR filters
  reportUniqueCaptureIDsArray = JSFUNC.unique(reportUniqueCaptureIDsArray);

  //return the xObj, which contains the following 4 fields (preamble, validCodewordRows, uniqueFields, uniqueCaptureIDs)
  return({
    xmlPreamble: xmlPreamble,
    vcrArrayOfObjs: vcrArrayOfObjs,
    reportUniqueTextareaCaptureFieldDbNamesArray: reportUniqueTextareaCaptureFieldDbNamesArray,
    reportUniqueCaptureDealShapingQuestionIDsArray: reportUniqueCaptureDealShapingQuestionIDsArray,
    reportUniqueCaptureIDsArray: reportUniqueCaptureIDsArray
  });
}

function excel_xml_get_first_filter_sort_total_codeword_obj_from_xml_string(i_xmlIn, i_filterSortTotalString) {
  //finds the first occurance of [[+filter:filter_name]] or [[+sort:sort_name]] or [[+total:field1,field2,field3]]

  //example i_xmlIn: "leftxml[[+filter:filter_name]]rightxml"
  var foundCodewordTF = false; //flag returned in output obj that indicates if the special codeword was found in the xml (true)
  var xmlLeft = undefined; //xml string to the left of the found codeword ("leftxml")
  var codewordContent = undefined; //the content of the found codeword ("filter_name")
  var xmlRight = undefined;  //xml string to the right of the found codeword ("rightxml")

  const codewordLeftString = "[[+" + i_filterSortTotalString + ":"; //"[[+filter:" or "[[+sort:" or "[[+total:"
  const codewordLeftStringNumChars = codewordLeftString.length;

  //find the first occurance of the left part of the special codeword
  var codewordLeftPos = i_xmlIn.indexOf(codewordLeftString);
  if(codewordLeftPos >= 0) {
    //separate the leftXml from the codeword and rightXml
    xmlLeft = i_xmlIn.substring(0, codewordLeftPos);
    var codewordAndXmlRight = i_xmlIn.substring(codewordLeftPos);

    //find the right brackets that end this codeword after the left part of the codeword
    var codewordRightPos = codewordAndXmlRight.indexOf("]]");
    if(codewordLeftPos >= 0) {
      foundCodewordTF = true; //once the left and right brackets are found, the search was successful
      codewordContent = codewordAndXmlRight.substring(codewordLeftStringNumChars, codewordRightPos);
      xmlRight = codewordAndXmlRight.substring(codewordRightPos + 2);
    }
  }

  return({
    foundCodewordTF: foundCodewordTF,
    xmlLeft: xmlLeft,
    codewordContent: codewordContent,
    xmlRight: xmlRight
  });
}

function excel_xml_get_vcr_preamble_and_codewords_arrayOfObjs_from_vcr_xml(i_vcrXml, i_filterPresetID, i_sortPresetID, i_expandedCaptureFieldsMapOfMaps) {
  //output vcrObj: {
  //  vcrPreamble: "preamble in codeword row from beginning of <Row up to the first [[codeword]]",
  //  codewordsArrayOfObjs: [
  //    {
  //      codewordCellStart: xml from the closest <Cell tag up to the codeword,
  //      codeword: "codeword",
  //      codewordLowercase: "codeword",
  //      codewordExpandedCaptureFieldMap: Map() (or undefined if codeword is not a valid capture field),
  //      codewordModifiersArray: array of string modifiers applied to this codeword
  //      codewordModifierIsSplitDivisionTF: if one of the modifiers was "splitdivision"
  //      codewordCellEnd: xml after the codeword until the next </Cell> tag,
  //      codewordConclusion: all xml from the end of this codeword after the cell end to the beginning of the next codeword
  //    }
  //  ],
  //  sortedFilteredCapturesArrayOfMaps: [array of all filtered captures from filter preset, sorted based on the provided sort preset, filled into the specified columns]
  //}
  //
  //example i_vcrXml: "preamble[[codeword1]]mid[[codeword2]]conclusion"
  //the i_filterPresetID is needed to filter all system captures based on the specified filters for this VCR
  //the i_sortPresetID is needed to create the allUniqueSortCaptureFieldsArrayOfObjs needed to create the sortedFilteredCapturesArrayOfMaps

  //use the filter preset and the sort preset to create the filtered/sorted array of capture maps for this VCR
  //compute the filtered captures
  var expandedFiltersArrayOfObjs = [];
  if(i_filterPresetID > 0) { //a filterPresetID of -1 is a flag that no filter was specified and all captures should be returned
    const filtersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_f_filters, "filter_preset_id", i_filterPresetID);
    expandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(filtersArrayOfObjs, i_expandedCaptureFieldsMapOfMaps);
  }
  const filteredCapturesMapOfMaps = CapturesMobx.get_filtered_captures_mapOfMaps_from_captures_mapOfMaps_and_expanded_filters_arrayOfObjs(DatabaseMobx.o_tbl_captures, expandedFiltersArrayOfObjs);

  //compute the sort for the captures
  var sortsArrayOfObjs = [];
  if(i_sortPresetID > 0) { //a i_sortPresetID of -1 is a flag that no sort was specified and no sorting is required
    sortsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_f_sorts, "sort_preset_id", i_sortPresetID, "sort", true);
  }
  const selectedSortFieldsAndDirectionsObj = CapturesMobx.create_sort_fields_and_directions_obj_from_sorts_arrayOfObjs(sortsArrayOfObjs);

  //compute the unique fields used in the sort
  const sortCaptureFieldIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(sortsArrayOfObjs, "capture_field_id");
  const allUniqueSortCaptureFieldsArrayOfObjs = CapturesMobx.create_cst_column_and_sort_capture_fields_arrayOfObjs_from_field_ids_array(sortCaptureFieldIDsArray, i_expandedCaptureFieldsMapOfMaps);

  //for each filtered capture, creates objs with at least id and captureFullName, plus computes the MASK and SORT values for each sort field
  const plainTextTF = true; //not using the mask value, so can simply use plaintext
  const valueMasksAlteredForCsvOrXmlTF = false;
  const cstFilteredMaskedCaptureValuesArrayOfObjs = CapturesMobx.compute_cst_filtered_masked_capture_values_arrayOfObjs(filteredCapturesMapOfMaps, allUniqueSortCaptureFieldsArrayOfObjs, plainTextTF, valueMasksAlteredForCsvOrXmlTF);

  //sort the filtered captures by the specified sort fields
  if(selectedSortFieldsAndDirectionsObj.propertyNamesArray.length > 0) { //only need to sort if there are any fields to sort by
    JSFUNC.sort_arrayOfObjs(cstFilteredMaskedCaptureValuesArrayOfObjs, selectedSortFieldsAndDirectionsObj.propertyNamesArray, selectedSortFieldsAndDirectionsObj.sortIsAscTFArray);
  }

  //fill in the array of captureMaps in the sorted order
  var sortedFilteredCapturesArrayOfMaps = [];
  for(let cstFilteredMaskedCaptureValueObj of cstFilteredMaskedCaptureValuesArrayOfObjs) {
    var captureMap = DatabaseMobx.o_tbl_captures.get(cstFilteredMaskedCaptureValueObj.id);
    if(captureMap !== undefined) {
      sortedFilteredCapturesArrayOfMaps.push(captureMap);
    }
  }

  //extract all codewords from the vcrXml input string and create an array of codewordObjs
  //split on a string always returns an array with length of at least 1
  const codewordStartSplitArray = i_vcrXml.split("[["); //0 - "preamble", 1 - "codeword1]]mid", 2 - "codeword2]]conclusion"

  //preamble is the first split entry (it's the entire string if 0 rows were found), if there was no preamble and the xml starts with "<Row", the split array does have a first element that is ""
  var vcrPreamble = codewordStartSplitArray[0];

  //loop through any rows found in the xml after the preamble starting with index 1
  var codewordsArrayOfObjs = [];
  var codewordIndex = 0;
  for(let s = 1; s < codewordStartSplitArray.length; s++) {
    var codewordAndConclusionString = codewordStartSplitArray[s]; //"codeword1]]mid" on first loop

    var codewordIsValidTF = false; //initialize as false
    var codewordEndSplitArray = codewordAndConclusionString.split("]]");
    if(codewordEndSplitArray.length === 2) { //most common case with 1 "]]"
      var codewordFirstChar = codewordAndConclusionString.substring(0, 1); //make sure the codeword does not start with "+" or "!" (special codewords for these excel xml operations)
      if((codewordFirstChar !== "+") && (codewordFirstChar !== "!")) {
        codewordIsValidTF = true;
      }
    }

    if(codewordIsValidTF) { //codeword was valid between [[ ]] without a "+" or "!"
      var codewordCellStart = ""; //initialize start/end as blank strings so that if the searching is invalid, the entire codeword row can still be put together correctly using just the previous conclusion, this codeword, and this conclusion
      var codeword = codewordEndSplitArray[0];
      var codewordLowercase = codeword.toLowerCase();
      var codewordLowercaseWithoutModifiers = codewordLowercase;
      var codewordExpandedCaptureFieldMap = undefined;
      var dealShapingCodewordComponentsObjOrUndefined = undefined;
      var codewordIsAtTeamTF = false;
      var codewordModifiersArray = [];
      var codewordModifierIsSplitDivisionTF = false;
      var codewordCellEnd = "";
      var codewordConclusion = codewordEndSplitArray[1];

      //determine if the codeword has a modifier [[codeword|modifier]] using "|" between them (used for excel xml for splitting division percents for money values using [[contract_overall_value|splitdivision]])
      var modifierBarSplitArray = codewordLowercase.split("|");
      var numModifiersPlusCodeword = modifierBarSplitArray.length;
      if(numModifiersPlusCodeword > 1) {
        codewordLowercaseWithoutModifiers = modifierBarSplitArray[0];
        for(let m = 1; m < numModifiersPlusCodeword; m++) {
          codewordModifiersArray.push(modifierBarSplitArray[m]);
        }
      }

      //matching capture field map if this codeword is a valid capture field, otherwise (team, comp, invalid, etc) this is undefined (this is a shortcut to save time when computing mask values in the report)
      codewordExpandedCaptureFieldMap = expanded_capture_field_map_from_lowercase_codeword_and_expanded_captures_fields_mapOfMaps(codewordLowercaseWithoutModifiers, i_expandedCaptureFieldsMapOfMaps);

      if(codewordExpandedCaptureFieldMap === undefined) {
        dealShapingCodewordComponentsObjOrUndefined = get_deal_shaping_codeword_components_obj_or_undefined(codewordLowercaseWithoutModifiers);
        if(dealShapingCodewordComponentsObjOrUndefined === undefined) { //if not a deal shaping codeword
          if(codewordLowercaseWithoutModifiers.substring(0, 6) === "@team_") { //check if it is a team codewords
            codewordIsAtTeamTF = true;
          }
        }
      }

      //determine if the codeword is a money field and the modifier is "splitdivision"
      if(codewordExpandedCaptureFieldMap !== undefined) {
        var fieldTypeObj = codewordExpandedCaptureFieldMap.get("fieldTypeObj");
        if(fieldTypeObj !== undefined) {
          if(fieldTypeObj.moneyTF) {
            codewordModifierIsSplitDivisionTF = JSFUNC.in_array("splitdivision", codewordModifiersArray);
          }
        }
      }

      //for excel dates (where blank dates need <Cell/> to be computed properly), find the cellStart and cellEnd surrounding this codeword
      var prevConclusion = ""; //get the previous conclusion to find the start cell
      if(codewordIndex === 0) { //if this is the first codeword, the prev conclusion is in the preamble
        prevConclusion = vcrPreamble;
      }
      else { //otherwise, the prev conclusion is in the previous codeword conclusion
        prevConclusion = codewordsArrayOfObjs[codewordIndex - 1].codewordConclusion;
      }
      var prevConclusionLastStartCellPos = prevConclusion.lastIndexOf("<Cell"); //last occurance of "<Cell"
      if(prevConclusionLastStartCellPos >= 0) { //ensure that "<Cell" exists at least once in the previous conclusion
        var currentConclusionFirstEndCellPos = codewordConclusion.indexOf("</Cell>"); //first occurance of "</Cell>"
        if(currentConclusionFirstEndCellPos >= 0) { //ensure that </Cell> exists at least once in this codeword conclusion
          codewordCellStart = prevConclusion.substring(prevConclusionLastStartCellPos); //cellStart is everything from last <Cell to the end
          if(codewordIndex === 0) {
            vcrPreamble = prevConclusion.substring(0, prevConclusionLastStartCellPos); //remove the cellStart contents from the previous conclusion
          }
          else {
            codewordsArrayOfObjs[codewordIndex - 1].codewordConclusion = prevConclusion.substring(0, prevConclusionLastStartCellPos); //remove the cellStart contents from the previous conclusion
          }
          codewordCellEnd = codewordConclusion.substring(0, currentConclusionFirstEndCellPos+7); //cellEnd is this conclusion up to and including the first </Cell>
          codewordConclusion = codewordConclusion.substring(currentConclusionFirstEndCellPos+7); //this codeword conclusion now has the cellEnd content removed from the front
        }
      }

      //build the codewordObj
      var codewordObj = {
        codewordCellStart: codewordCellStart,
        codeword: codeword,
        codewordLowercase: codewordLowercase,
        codewordLowercaseWithoutModifiers: codewordLowercaseWithoutModifiers,
        codewordExpandedCaptureFieldMap: codewordExpandedCaptureFieldMap,
        dealShapingCodewordComponentsObjOrUndefined: dealShapingCodewordComponentsObjOrUndefined,
        codewordIsAtTeamTF: codewordIsAtTeamTF,
        codewordModifiersArray: codewordModifiersArray,
        codewordModifierIsSplitDivisionTF: codewordModifierIsSplitDivisionTF,
        codewordCellEnd: codewordCellEnd,
        codewordConclusion: codewordConclusion
      };

      //append the codewordObj into the arrayOfObjs, increment the codewordIndex
      codewordsArrayOfObjs[codewordIndex] = codewordObj;
      codewordIndex++;
    }
    else { //row does not have "]]" or has multiple "]]", append this string to the conclusion of the previous codeword (or the preamble if this is the first codeword)
      if(codewordIndex === 0) { //a codeword has not been found yet, append this string to the preamble
        vcrPreamble += "[[" + codewordAndConclusionString;
      }
      else { //append this string to the previous codeword conclusion
        codewordsArrayOfObjs[codewordIndex - 1].codewordConclusion += "[[" + codewordAndConclusionString;
      }
    }
  }

  return({
    vcrPreamble: vcrPreamble,
    codewordsArrayOfObjs: codewordsArrayOfObjs,
    sortedFilteredCapturesArrayOfMaps: sortedFilteredCapturesArrayOfMaps,
    expandedFiltersArrayOfObjs: expandedFiltersArrayOfObjs
  });
}

function excel_xml_total_block_move_subtotals_into_cell_formula(i_xmlIn) {
	//search for all [[!subtotal]], add ss:Formula="[!subtotalFormula]]" to the cell tag, remove any non-tag contents in that cell surrounding [[!subtotal]] between the cell tags <Cell>here[[!subtotal]]andhere</Cell>
  const subtotalSplitArray = i_xmlIn.split("[[!subtotal]]");
  const numSubtotalSplits = subtotalSplitArray.length;

  //no [[!subtotal]] strings anywhere in this i_xmlIn
  if(numSubtotalSplits <= 1) {
    return(i_xmlIn);
  }

  var xmlOut = "";
  for(let s = 0; s < numSubtotalSplits; s++) {
    var subtotalSplit = subtotalSplitArray[s];

    //operate after the last "<Cell" at the end of this split segment for every index except the last one
    if(s < (numSubtotalSplits - 1)) {
      var lastCellPos = subtotalSplit.lastIndexOf("<Cell"); //last occurance of "<Cell"
      if(lastCellPos >= 0) {
        var endOfLastCellPos = subtotalSplit.indexOf(">", lastCellPos);
        if(endOfLastCellPos >= 0) {
          //insert the subtotal formula into the cell tag
          var leftStringUpThroughCellTag = subtotalSplit.substring(0, (lastCellPos + 5)) + ' ss:Formula="[[!subtotalFormula]]"' + subtotalSplit.substring((lastCellPos + 5), (endOfLastCellPos + 1));

          //remove any non-tag cell contents from the left side of the codeword (could have an example like "<Cell>extratextbeforesubtotal[[!subtotal]]</Cell>", and this would remove extratextbeforesubtotal from inside the cell)
          var leftStringAfterCellTagToEnd = xml_remove_non_tags(subtotalSplit.substring(endOfLastCellPos + 1));

          //put the altered left side back together
          subtotalSplit = leftStringUpThroughCellTag + leftStringAfterCellTagToEnd;
        }
      }
    }

    //operate before the </Cell> at the beginning of every segment except the first one
    if(s > 0) {
      //need to remove all data in the cell up to the </Cell> tag to look through in the next loop
      var firstSlashCellPos = subtotalSplit.indexOf("</Cell>"); //first occurance of </Cell>
      if(firstSlashCellPos >= 0) {
        //remove and nonn-tag cell contents from the right side of the codeword
        var rightStringBeginToSlashCellTag = xml_remove_non_tags(subtotalSplit.substring(0, firstSlashCellPos));
        var rightSlashCellTagToEnd = subtotalSplit.substring(firstSlashCellPos);

        //put the altered right side back together
        subtotalSplit = rightStringBeginToSlashCellTag + rightSlashCellTagToEnd;
      }
    }


    xmlOut += subtotalSplit; //append the changed left and/or right side to the output
  }
  return(xmlOut);
}

function xml_remove_non_tags(i_xmlIn) {
	var xmlOut = "";
	var searchIsFinished = false;
	while(!searchIsFinished) {
		var leftAnglePos = i_xmlIn.indexOf("<");
		if(leftAnglePos < 0) { //if another tag does not open after this, then the rest is plain text to not include in the output
			searchIsFinished = true;
		}
		else {
			var rightAnglePos = i_xmlIn.indexOf(">", leftAnglePos);
			if(rightAnglePos < 0) { //there is no angle to close this tag, so the rest of the string will not be included in the output
				searchIsFinished = true;
			}
			else {
				xmlOut += i_xmlIn.substring(leftAnglePos, (rightAnglePos + 1)); //add the found tag to the output
				i_xmlIn = i_xmlIn.substring(rightAnglePos + 1); //cut xmlIn to after the tag just found for the next search
			}
		}
	}
	return(xmlOut);
}

//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

function excel_xml_build_xml_out_from_preamble_and_vcr_arrayOfObjs(i_xObj, i_capturesTextareaDataArrayOfObjsOrUndefined=undefined, i_capturesDealShapingDataArrayOfObjsOrUndefined=undefined) {
  //create debug array
  const debugTF = false;
  var debugArray = [];

  //initialize xmlOut with the preamble
  var xmlOut = i_xObj.xmlPreamble;
  if(debugTF) { debugArray.push(i_xObj.xmlPreamble); }

  var extraRows = 0; //counter of extra inserted rows from captures, used to update ExpandedRowCount, all sheets get the combined number of extra rows from every sheet added to their original count, so it's way overestimated
  for(let vcrObj of i_xObj.vcrArrayOfObjs) {
    //determine if this VCR contains a special codword that creates custom calculation tables
    var finprojColonUserranktableCodewordFoundTF = false;
    var finprojColonDivisionfyrtableCodewordFoundTF = false;
    for(let codewordObj of vcrObj.codewordsArrayOfObjs) {
      if(codewordObj.codeword === "finproj:userranktable") { finprojColonUserranktableCodewordFoundTF = true; }
      else if(codewordObj.codeword === "finproj:fyrtable") { finprojColonDivisionfyrtableCodewordFoundTF = true; }
    }

    //VCR contains special custom calculation table xml
    if(finprojColonUserranktableCodewordFoundTF || finprojColonDivisionfyrtableCodewordFoundTF) {
      var xmlAndNumRowsAndColumnsObj = {xmlString:"", numRowsAdded:0, numColumnsRequired:0};
      if(finprojColonUserranktableCodewordFoundTF) {
        xmlAndNumRowsAndColumnsObj = excel_xml_finproj_userranktable_compute_full_table_xml_and_num_rows_and_columns_obj(vcrObj);
      }
      else if(finprojColonDivisionfyrtableCodewordFoundTF) {
        xmlAndNumRowsAndColumnsObj = excel_xml_finproj_fyrtable_compute_full_table_xml_and_num_rows_and_columns_obj(vcrObj);
      }

      //alter the preamble to change the ss:ExpandedColumnCount="3" to at least 4 so that the table fits
      var eccPos = xmlOut.lastIndexOf('ExpandedColumnCount="');
      if(eccPos >= 0) {
        var eccEndQuotePos = xmlOut.indexOf('"', eccPos+21);
        var origColumnCountString = xmlOut.substring(eccPos+21, eccEndQuotePos);
        var origColumnCount = JSFUNC.str2int(origColumnCountString);
        if(origColumnCount < xmlAndNumRowsAndColumnsObj.numColumnsRequired) {
          xmlOut = xmlOut.substring(0, eccPos+21) + xmlAndNumRowsAndColumnsObj.numColumnsRequired + xmlOut.substring(eccEndQuotePos);
        }
      }

      xmlOut += xmlAndNumRowsAndColumnsObj.xmlString;
      if(debugTF) { debugArray.push(xmlAndNumRowsAndColumnsObj.xmlString); }
      extraRows += xmlAndNumRowsAndColumnsObj.numRowsAdded;
    }
    else { //VCR is normal VCR with regular capture [[codewords]] in a single row that are replaced with a list of all captures matching the filter
      //count each variable for this Variable Codeword Row
      var numCodewords = vcrObj.codewordsArrayOfObjs.length;
      var numCaptures = vcrObj.sortedFilteredCapturesArrayOfMaps.length;
      var numTotalBlocks = vcrObj.totalBlocksArrayOfObjs.length;

      //intialize temporary array variables that hold row index numbers for the total row subtotal formulas
      var subtotalRowCount = [];
      var subtotalRelativeCaptureRowIndexArray = [];
      var xCodewordTempValueArrays = [];
      for(let t = 0; t < numTotalBlocks; t++) {
        subtotalRowCount[t] = 0; //the row count per each total block increments for each capture and the #Rows of other printed total blocks, when it draws its own total block the #Rows is added in, then counter is reset to 0
        subtotalRelativeCaptureRowIndexArray[t] = []; //for each capture, the subtotal row count for this particular total block is appended to the array

        //xCodeword [[!db_name]] replacement capture data tracker arrays
        xCodewordTempValueArrays[t] = [];
        for(let x = 0; x < vcrObj.totalBlocksArrayOfObjs[t].xCodewordsArray.length; x++) {
          xCodewordTempValueArrays[t][x] = []; //initialize each [t] total block [x] xCodeword data array as blank, will be filled with capture display data from the "[[!db_name]]" field until the corresponding [t] total block is drawn with this data inserted as a comma list, then this array will be reinitialized to array()
        }
      }

      //algorithm to load "curr" current and "next" capture rows in order from the captureIDs array while ensuring that they both exist in the database, row_captures_next="F" on the last capture as a flag that the last total rows can be drawn
      var currCaptureMap = undefined;
      var nextCaptureMap = undefined; //initialize the "next" capture to the -1 invalid flag, indicating that the current capture must be manually loaded from the database rather than copied from the next capture from the previous loop
      for(let q = 0; q < numCaptures; q++) {
        //get the current capture and the next capture
        currCaptureMap = vcrObj.sortedFilteredCapturesArrayOfMaps[q];
        nextCaptureMap = ((q < (numCaptures - 1)) ? (vcrObj.sortedFilteredCapturesArrayOfMaps[q + 1]) : (undefined)); //the next capture is undefined if working on the last capture in the array

        //==========================================CAPTURE ROW (draw the current capture row with replaced values)==============================================
        //initialize the replaced row xml with the VCR preamble xml before the first codeword
        var replacedCodewordRow = vcrObj.vcrPreamble;

        //loop through each codewordObj to get its replacement value and put together the codeword row string
        for(let c = 0; c < numCodewords; c++) {
          var codewordObj = vcrObj.codewordsArrayOfObjs[c];
          var expandedFiltersArrayOfObjs = vcrObj.expandedFiltersArrayOfObjs;

          //load the parts of the codewordObj
          var codewordCellStart = codewordObj.codewordCellStart;
          var codeword = codewordObj.codeword;
          var codewordLowercase = codewordObj.codewordLowercase;
          var codewordLowercaseWithoutModifiers = codewordObj.codewordLowercaseWithoutModifiers;
          var codewordExpandedCaptureFieldMap = codewordObj.codewordExpandedCaptureFieldMap;
          var dealShapingCodewordComponentsObjOrUndefined = codewordObj.dealShapingCodewordComponentsObjOrUndefined;
          var codewordIsAtTeamTF = codewordObj.codewordIsAtTeamTF;
          var codewordModifiersArray = codewordObj.codewordModifiersArray;
          var codewordModifierIsSplitDivisionTF = codewordObj.codewordModifierIsSplitDivisionTF;
          var codewordCellEnd = codewordObj.codewordCellEnd;
          var codewordConclusion = codewordObj.codewordConclusion;

          //get the codeword replacement
          var codewordReplacementObj = get_excel_xml_codeword_replacement_with_modifiers_applied_obj_from_codeword_lowercase_and_capture_map_and_expanded_capture_field_map(codewordLowercaseWithoutModifiers, dealShapingCodewordComponentsObjOrUndefined, codewordIsAtTeamTF, codewordModifiersArray, codewordModifierIsSplitDivisionTF, currCaptureMap, codewordExpandedCaptureFieldMap, i_capturesTextareaDataArrayOfObjsOrUndefined, i_capturesDealShapingDataArrayOfObjsOrUndefined, expandedFiltersArrayOfObjs);
          var codewordReplacement = codewordReplacementObj.codewordReplacement;
          var codewordReplacementIsNumericTF = codewordReplacementObj.codewordReplacementIsNumericTF;
          var codewordReplacementDisplayIsDateOrDateTimeTF = codewordReplacementObj.codewordReplacementDisplayIsDateOrDateTimeTF;
          var codewordReplacementIsInvalidExcelDateTF = codewordReplacementObj.codewordReplacementIsInvalidExcelDateTF;

          if(codewordReplacementIsInvalidExcelDateTF) { //if the flag is true for an invalid date or datetime
            replacedCodewordRow += "<Cell/>"; //close cell to become empty <Cell></Cell> instead of <Cell>--Date Not Set--</Cell>
          }
          else { //normal replacement, put back together the original xml with the replaced codeword
            //append cellStart to the replaced codeword row
            replacedCodewordRow += codewordCellStart;

            //determine if the <Data ss:Type="String"> should be changed to <Data ss:Type="Number"> (or "DateTime") at the very end of replacedCodewordRow, overwrites replacedCodewordRow with a copy of itself that has String possibly changed to Number
	          if(codewordReplacementIsNumericTF || codewordReplacementDisplayIsDateOrDateTimeTF) {
              var replacementExcelDataTagTypeStringNumberDateTime = "String";
              if(codewordReplacementIsNumericTF) { //numeric type codeword replacement needs "String" changed to "Number" in the excel data tag
                replacementExcelDataTagTypeStringNumberDateTime = "Number";
              }
              else if(codewordReplacementDisplayIsDateOrDateTimeTF) { //CE date or datetime type codeword needs "String" changed to "DateTime" in the excel data tag
                replacementExcelDataTagTypeStringNumberDateTime = "DateTime";
              }
              replacedCodewordRow = excel_xml_cell_data_type_string_to_number_or_datetime(replacedCodewordRow, (codewordCellEnd + codewordConclusion), replacementExcelDataTagTypeStringNumberDateTime);
            }

            //append the replaced codeword and right data up to the next codeword or the end of the row
            replacedCodewordRow += codewordReplacement;

            //append cellEnd to the replaced codeword row
            replacedCodewordRow += codewordCellEnd;
          }

          //add the codeword conclusion to the replaced codeword row for this codeword
          replacedCodewordRow += codewordConclusion;
        }

        //append the full replaced codeword row with all codewords replaced to xmlOut
        xmlOut += replacedCodewordRow;
        if(debugTF) { debugArray.push(replacedCodewordRow); }

        //add 1 to the extra rows counter for the codeword row just added
        extraRows++;

        //update the unique row count and capture indices per each total block by 1 capture
        for(let t = 0; t < numTotalBlocks; t++) {
          if(vcrObj.totalBlocksArrayOfObjs[t].expandedCaptureFieldsArrayOfMaps !== false) {
            subtotalRowCount[t]++;
            subtotalRelativeCaptureRowIndexArray[t].push(subtotalRowCount[t]);
          }
        }
        //===================================================================================================

        //==========================================TOTAL BLOCKS=============================================
        //draw any total blocks that go after this capture based on the "next" capture total field values changing compared to the values in "curr"
        for(let t = 0; t < numTotalBlocks; t++) {
          var totalBlockObj = vcrObj.totalBlocksArrayOfObjs[t];

          if(totalBlockObj.expandedCaptureFieldsArrayOfMaps !== false) { //only draw a total block if the capture fields listed were all valid (this array is false if it is invalid)

            //xCodewords [[!db_name]] data, append newly inserted capture row data into each of the (valid) total block data arrays at the beginning of each one of these t loops
            for(let x = 0; x < totalBlockObj.xCodewordsArray.length; x++) {
              var xCodewordReplacement = get_codeword_replacement_plaintext_from_capture_map(totalBlockObj.xCodewordsArray[x], currCaptureMap, undefined);
              xCodewordTempValueArrays[t][x].push(xCodewordReplacement);
            }

            //determine if this total block is ready to be drawn
            var drawTotalBlock = false; //initialize to false, if any of the total capture field values have changed, set this to true
            if(nextCaptureMap === undefined) { //if this is the last capture, draw all of the total blocks in order at the bottom
              drawTotalBlock = true;
            }
            else {
              for(let f = 0; f < totalBlockObj.expandedCaptureFieldsArrayOfMaps.length; f++) {
                var expandedCaptureFieldMap = totalBlockObj.expandedCaptureFieldsArrayOfMaps[f];
                var currValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(currCaptureMap, expandedCaptureFieldMap);
                var nextValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(nextCaptureMap, expandedCaptureFieldMap);
                if(currValueRaw !== nextValueRaw) {
                  drawTotalBlock = true;
                }
              }
            }

            //draw the total block with the subtotal formulas replaced
            if(drawTotalBlock) {
              //update the unique row count for every total block by the number of total rows just drawn for this total block
              for(let tttt = 0; tttt < numTotalBlocks; tttt++) {
                if(vcrObj.totalBlocksArrayOfObjs[tttt].expandedCaptureFieldsArrayOfMaps !== false) {
                  subtotalRowCount[tttt] += totalBlockObj.numRows; //increment all total block counts "tttt" by the the numRows found in "t"
                }
              }

              //replace the total block [[!subtotalFormula]] placeholders with the calculated formula for this total row
              var numCapturesReferenced = subtotalRelativeCaptureRowIndexArray[t].length;
              var subtotalFormula = undefined;
              if(numCapturesReferenced == 0) { //this should never happen, because a capture is always drawn before a total block can be drawn
                subtotalFormula = "=-999999";
              }
              else {
                subtotalFormula = "=SUM(";

                var prevNegRows = "S"; //start flag
                for(let r = 0; r < numCapturesReferenced; r++) {
                  var negativeRowsAboveTotalRow = subtotalRelativeCaptureRowIndexArray[t][r] - subtotalRowCount[t];
                  var rcSymbol = "R[" + negativeRowsAboveTotalRow + "]C";
                  if(prevNegRows === "S") {
                    subtotalFormula += rcSymbol; //always print the first one
                  }
                  else {
                    if(negativeRowsAboveTotalRow - prevNegRows == 1) { //if the current index is one larger than the prev index, allow the semicolon notation
                      if(subtotalFormula.substring(subtotalFormula.length - 1) !== ":") { //if a semicolon is not on the end of the string yet, then add one
                        subtotalFormula += ":";
                      }

                      if(r === (numCapturesReferenced - 1)) { //if this is the last capture index, print it after the colon
                        subtotalFormula += rcSymbol;
                      }
                    }
                    else { //the current index is two or more above the previous index, print the previous number and the current number
                      if(subtotalFormula.substring(subtotalFormula.length - 1) === ":") { //if a semicolon is on the end of the string, need to add prev RC before the comma and current RC
                        subtotalFormula += "R[" + prevNegRows + "]C";
                      }
                      subtotalFormula += "," + rcSymbol;
                    }
                  }
                  prevNegRows = negativeRowsAboveTotalRow; //move the current negative row number to the previous slot
                }

                subtotalFormula += ")";
              }
              var replacedTotalBlockXml = excel_xml_total_blocks_replace_subtotal_formulas(totalBlockObj.xml, subtotalFormula); //for each instance of [[!subtotalFormula]], figure out which total block row it is in and add a row offset (rom the bottom row) to all parts of the formula before replacing it

              //replace all xCodewords [[!db_name]] in this total block with a comma list of unique data values from the captures above this total block
              for(let x = 0; x < totalBlockObj.xCodewordsArray.length; x++) {
                var bracketXCodeword = "\\[\\[!" + totalBlockObj.xCodewordsArray[x] + "\\]\\]";
                var xCodewordReplacementCommaData = JSFUNC.convert_array_to_comma_list(JSFUNC.unique(xCodewordTempValueArrays[t][x])); //take all unique values from the accumulated data, convert the array to a printable oomma list of unique data from all the captures listed before this total block
                replacedTotalBlockXml = replacedTotalBlockXml.replace(new RegExp(bracketXCodeword, 'g'), xCodewordReplacementCommaData);
                xCodewordTempValueArrays[t][x] = []; //reset the data accumulator for this totalBlock/xCodeword combination
              }

              //append the replaced total block to the entire xmlOut
              xmlOut += replacedTotalBlockXml;
              if(debugTF) { debugArray.push(replacedTotalBlockXml); }

              //reset the capture row index collector for this total block to create the equation for the next total block
              subtotalRowCount[t] = 0;
              subtotalRelativeCaptureRowIndexArray[t] = [];

              //add the number or rows in the total block to the extra row counter for the codeword row just added
              extraRows += totalBlockObj.numRows;
            }

          }
        }
        //===================================================================================================

      } //for looping over captures

      //draw any total blocks that had invalid capture fields listed (contains the error message), do this for all total blocks if the filter/sort was not valid
      for(let t = 0; t < numTotalBlocks; t++) {
        var totalBlockObj = vcrObj.totalBlocksArrayOfObjs[t];
        if(totalBlockObj.expandedCaptureFieldsArrayOfMaps === false) {
          xmlOut += totalBlockObj.xml;
          if(debugTF) { debugArray.push(totalBlockObj.xml); }
        }
      }
    }

    //draw the conclusion leading up to the next Valid Codeword Row or to the end of the document
    xmlOut += vcrObj.vcrConclusion;
    if(debugTF) { debugArray.push(vcrObj.vcrConclusion); }
  } //for loop over VCRs


  //fix ExpandedRowCount on each tab of the xml
  var xmlInRemaining = xmlOut;
  xmlOut = "";
  var ercPos = xmlInRemaining.indexOf('ExpandedRowCount="');
  while(ercPos >= 0) {
    var ercEndQuotePos = xmlInRemaining.indexOf('"', ercPos+18);
    var origRowCountString = xmlInRemaining.substring(ercPos+18, ercEndQuotePos);
    var origRowCount = JSFUNC.str2int(origRowCountString);
    xmlOut += xmlInRemaining.substring(0, ercPos+18) + (origRowCount + extraRows);

    xmlInRemaining = xmlInRemaining.substring(ercEndQuotePos);
    ercPos = xmlInRemaining.indexOf('ExpandedRowCount="'); //search for the next one after this, PSS error point
  }
  xmlOut += xmlInRemaining; //append last remaining piece

  //replace all non-capture dependent codewords ( [[#today_date]] ) in xmlOut
  xmlOut = replace_all_codewords_in_xml_data_string(xmlOut, undefined, undefined);

  if(debugTF) { JSFUNC.browser_offer_file_download_from_file_data_string(JSFUNC.print_array(debugArray), "excelXmlDebugArray.txt"); }

  return(xmlOut);
}


export function get_excel_xml_codeword_replacement_with_modifiers_applied_obj_from_codeword_lowercase_and_capture_map_and_expanded_capture_field_map(i_codewordLowercaseWithoutModifiers, i_dealShapingCodewordComponentsObjOrUndefined, i_codewordIsAtTeamTF, i_codewordModifiersArray, i_codewordModifierIsSplitDivisionTF, i_captureMap, i_codewordExpandedCaptureFieldMap, i_capturesTextareaDataArrayOfObjsOrUndefined, i_capturesDealShapingDataArrayOfObjsOrUndefined, i_expandedFiltersArrayOfObjs) {
  //initialize output obj vars
  var codewordReplacement = undefined;
  var codewordReplacementIsNumericTF = false;
  var codewordReplacementDisplayIsDateOrDateTimeTF = false;
  var codewordReplacementIsInvalidExcelDateTF = false; //if the codeword is a date or datetime and the capture value is not set or earlier than year 1900, the replacement is just <Cell/> rather than <Cell><Data></Data></Cell>
  
  var codewordCaptureValueTrueRawIntegrations = undefined;
  if(i_dealShapingCodewordComponentsObjOrUndefined !== undefined) {
    if(i_dealShapingCodewordComponentsObjOrUndefined.requestingQuestionTextTF) { //question text, codeword is "@ds_q15", fetch the text of questionID 15 from the deal_shaping function which fetches from the shaping questions pool admin tbl, which is loaded at login for users
      codewordReplacement = get_deal_shaping_codeword_replacement_plaintext_from_capture_map(i_codewordLowercaseWithoutModifiers, i_captureMap);
    }
    else { //answer text or percent
      codewordReplacement = "--deal shaping codeword failed to fetch answer data (" + i_codewordLowercaseWithoutModifiers + ")--"; //initialize with a failed answer, overwrite with fetched value if successfully loaded
      if(JSFUNC.is_array(i_capturesDealShapingDataArrayOfObjsOrUndefined)) {
        const currCaptureDealShapingDataObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(i_capturesDealShapingDataArrayOfObjsOrUndefined, "id", i_captureMap.get("id"));
        if(currCaptureDealShapingDataObjOrUndefined !== undefined) { //this captureID of deal shaping data was successfully loaded from the database
          codewordReplacement = "--Not Yet Answered--"; //initialized shaping answer text or percent as "not yet answered" if "q15s" is not defined in the data for this captureID
          const selectAnswerRowIDOrM1StringOrUndefined = currCaptureDealShapingDataObjOrUndefined["q" + i_dealShapingCodewordComponentsObjOrUndefined.questionID + "s"];
          if(selectAnswerRowIDOrM1StringOrUndefined !== undefined) { //this "q15s" was defined in this deal shaping capture data row (undefined means the capture answer row was not found in the _c_ data tbl)
            if(selectAnswerRowIDOrM1StringOrUndefined === "-1") { //if the select answer rowID is "-1", it's a flag that this is a ds textarea question with answer text and percent already loaded from the textarea answers tbl
              if(i_dealShapingCodewordComponentsObjOrUndefined.requestingAnswerPercentTF) { //ds textarea answer percent
                const textareaAnswerPercent0to100StringOrUndefined = currCaptureDealShapingDataObjOrUndefined["q" + i_dealShapingCodewordComponentsObjOrUndefined.questionID + "p"];
                if(textareaAnswerPercent0to100StringOrUndefined !== undefined) {
                  codewordReplacement = (JSFUNC.str2int(textareaAnswerPercent0to100StringOrUndefined) / 100); //excel percent is decimal 0 to 1
                }
              }
              else { //ds textarea answer text
                const textareaAnswerTextOrUndefined = currCaptureDealShapingDataObjOrUndefined["q" + i_dealShapingCodewordComponentsObjOrUndefined.questionID + "t"];
                if(textareaAnswerTextOrUndefined !== undefined) {
                  codewordReplacement = textareaAnswerTextOrUndefined;
                }
              }
            }
            else { //this is a ds select question, look up the select answer row by id
              const selectAnswerRowID = JSFUNC.str2int(selectAnswerRowIDOrM1StringOrUndefined);
              if(JSFUNC.is_number_not_nan_gt_0(selectAnswerRowID)) {
                const selectAnswerMapOrUndefined = DatabaseMobx.o_tbl_a_shaping_select_answers.get(selectAnswerRowID);
                if(selectAnswerMapOrUndefined !== undefined) {
                  if(i_dealShapingCodewordComponentsObjOrUndefined.requestingAnswerPercentTF) { //ds select answer percent
                    codewordReplacement = (selectAnswerMapOrUndefined.get("score0to100") / 100); //excel percent is decimal 0 to 1
                  }
                  else { //ds select answer text
                    codewordReplacement = selectAnswerMapOrUndefined.get("name");
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  else if(i_codewordIsAtTeamTF) { //if this codeword is a teammate system codeword
    var teammateContractID = undefined; //this is not a teammate contract template and does not have a specific teammateContractID from that
    codewordReplacement = get_team_codeword_replacement_plaintext_from_capture_map(i_codewordLowercaseWithoutModifiers, i_captureMap, teammateContractID);
  }
  else if(i_codewordExpandedCaptureFieldMap === undefined) { //don't know the expandedCaptureFieldMap, might be hardcoded field, or is invalid (no other types like @sqa, @comp, etc are allowed in excel reports)
    //hardcoded replacement
    if(i_codewordLowercaseWithoutModifiers.substring(0, 1) === "#") {
      codewordReplacement = get_hardcoded_codeword_replacement_plaintext(i_codewordLowercaseWithoutModifiers);
    }
    else { //otherwise invalid codeword, only doing details/dates fields and hardcoded for excel reports for now (because supplemental capture data is not loaded for every capture)
      codewordReplacement = "--Invalid excel report codeword field (" + i_codewordLowercaseWithoutModifiers + ")--";
    }
  }
  else { //know that this codeword is a valid capture field, directly get the replacement mask value using the expanded capture field map
    var fieldTypeObj = i_codewordExpandedCaptureFieldMap.get("fieldTypeObj");

    //if this field is a textarea type
    if(fieldTypeObj.textareaTF) {
      if(i_capturesTextareaDataArrayOfObjsOrUndefined !== undefined) { //preloaded full textarea data for all captures used in this report
        var currCaptureTextareaDataObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(i_capturesTextareaDataArrayOfObjsOrUndefined, "id", i_captureMap.get("id"));
        if(currCaptureTextareaDataObjOrUndefined !== undefined) { //this captureID was successfully loaded from the database
          var fieldDbName = i_codewordExpandedCaptureFieldMap.get("db_name");
          codewordReplacement = currCaptureTextareaDataObjOrUndefined[fieldDbName];
        }
      }
    }

    //if not a textarea handled above
    if(codewordReplacement === undefined) {
      //get the excel template codeword replacement for this current capture, numbers/money/dates are raw and not set dates return ""
      var codewordReplacementValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_codewordExpandedCaptureFieldMap, true); //plainTextTF is true
      codewordCaptureValueTrueRawIntegrations = codewordReplacementValueMaskSortIfoCanEditObj.valueTrueRawIntegrations; //keep the capture raw value for date/datetime fields to check if they are empty or less than 1900 which is invalid in excel
      codewordReplacement = codewordReplacementValueMaskSortIfoCanEditObj.valueMaskCsvXmlPlainText;

      //if this codeword is a money field with a splitdivision modifier, compute the partialMultiplier0to1 based on the division filtering and multiply it by the raw money replacement value
      if(i_codewordModifierIsSplitDivisionTF) {
        var partialMultiplier0to1 = CapturesMobx.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(i_captureMap, i_expandedFiltersArrayOfObjs);
        codewordReplacement *= partialMultiplier0to1;
      }

      //check if the field is date or datetime and if the replacement value is valid
      if(fieldTypeObj.valueDisplayIsDateOrDateTimeTF) {
        codewordReplacementDisplayIsDateOrDateTimeTF = true;
        if((codewordCaptureValueTrueRawIntegrations === "") || (codewordCaptureValueTrueRawIntegrations < "1900-01-01")) { //"" is a result of a date not being filled out in the codeword replacement function above, greater than 1900 year is a requirement from excel calculations
          codewordReplacementIsInvalidExcelDateTF = true;
        }
      }
    }
  }

  //apply any modifiers to the codeword replacement
  codewordReplacement = apply_codeword_modifiers_to_codeword_replacement_plaintext_string(codewordReplacement, i_codewordModifiersArray, i_codewordExpandedCaptureFieldMap, codewordCaptureValueTrueRawIntegrations);

  //call htmlspecialchars to append the string safely into the xml
  codewordReplacement = JSFUNC.htmlspecialchars(codewordReplacement);

  //determine if codeword is numeric (used when changing data cell type from "String" to "Number")
  codewordReplacementIsNumericTF = JSFUNC.is_number(codewordReplacement);

  return({
    codewordReplacement: codewordReplacement,
    codewordReplacementIsNumericTF: codewordReplacementIsNumericTF,
    codewordReplacementDisplayIsDateOrDateTimeTF: codewordReplacementDisplayIsDateOrDateTimeTF,
    codewordReplacementIsInvalidExcelDateTF: codewordReplacementIsInvalidExcelDateTF
  });
}


function excel_xml_cell_data_type_string_to_number_or_datetime(i_inputXmlLeft, i_inputXmlRight, i_replacementExcelDataTagTypeStringNumberDateTime) {
	//converts and returns the i_inputXmlLeft xml data with cell data tag <Data ss:Type="String"> changed to <Data ss:Type="Number"> for Cells that match the following critera:
	//  1. the replacement capture value for the codeword matches is_numeric()
	//  2. xml inside the <Data> tag is empty except for the codeword replacement number
  //this function uses information from i_inputXmlRight, but does not return it in the output
  //i_replacementExcelDataTagTypeStringNumberDateTime: "String", "Number", "DateTime"

	var outputXmlLeft = i_inputXmlLeft;

  //check that the cell is empty
  const lastDataPos = i_inputXmlLeft.lastIndexOf("<Data");
  if(lastDataPos >= 0) {
    const endOfLastDataPos = i_inputXmlLeft.indexOf(">", lastDataPos);
    if(endOfLastDataPos >= 0) {
      const firstSlashDataPos = i_inputXmlRight.indexOf("</Data>");
      if(firstSlashDataPos >= 0) {
        const stringBetweenDataNotIncludingCodeword = i_inputXmlLeft.substring(endOfLastDataPos+1) + i_inputXmlRight.substring(0, firstSlashDataPos);
        const stringBetweenDataNotIncludingCodewordNoTags = JSFUNC.strip_tags(stringBetweenDataNotIncludingCodeword);
        if(stringBetweenDataNotIncludingCodewordNoTags == "") { //if there is nothing but tags between <Data ...> and </Data>
          //find the Type="String" in the Data tag and change it to number
          const inputLeftLeftOfData = i_inputXmlLeft.substring(0, lastDataPos);                     //"..."<Data>[[codeword]]</Data>...
          const inputLeftDataTagString = i_inputXmlLeft.substring(lastDataPos, endOfLastDataPos+1); //..."<Data>"[[codeword]]</Data>...

          //replace Type="String" with Type="Number"
          const inputLeftDataTagNumber = inputLeftDataTagString.replace(new RegExp('ss:Type="String"', 'g'), 'ss:Type="' + i_replacementExcelDataTagTypeStringNumberDateTime + '"');

          //create the new output xml
          outputXmlLeft = inputLeftLeftOfData + inputLeftDataTagNumber;
        }
      }
    }
	}

	return(outputXmlLeft);
}

function excel_xml_total_blocks_replace_subtotal_formulas(i_totalBlockXml, i_subtotalFormula) {
	//for each instance of [[!subtotalFormula]], figure out which total block row it is in and add a row offset (rom the bottom row) to all parts of the formula before replacing it
	var replacedTotalBlockXml = ""; //replaced output to be filled in
	var xmlRemaining = i_totalBlockXml;
	var xmlSearchIsDone = false;
	while(!xmlSearchIsDone) {
		var subtotalFormulaPos = xmlRemaining.indexOf("[[!subtotalFormula]]");
		if(subtotalFormulaPos < 0) { //no subtotals exist in the remaining xml
			replacedTotalBlockXml += xmlRemaining;
			xmlSearchIsDone = true;
		}
		else {
			var xmlLeft = xmlRemaining.substring(0, subtotalFormulaPos);
			var xmlRight = xmlRemaining.substring(subtotalFormulaPos+20);

			//determine how many rows above the bottom row of the total block this [[!subtotalFormula]] is, then apply that offset to the i_subtotalFormula numbers
			var xmlRightSlashRowExplode = xmlRight.split("</Row>");
			var slashRowCount = (xmlRightSlashRowExplode.length - 1);
      var shiftedSubtotalFormula = undefined;
			if(slashRowCount <= 1) { //there is only 1 </Row> tag, meaning this [[!subtotal]] was on the bottom row of the total block with the [[+total:]], thus the originally calculated formula values can stay the same
				shiftedSubtotalFormula = i_subtotalFormula;
			}
			else { //this [[!subtotal]] is 1 or more rows above the bottom row of the total block, thus the formula needs its values shifted by the number of rows (add # rows above bottom row to each negative number in the formula, moving the numbers closer to 0)
				var subtotalNumRowsAboveBottomRow = slashRowCount - 1;
				var formulaLeftBracketExplode = i_subtotalFormula.split("[");
				shiftedSubtotalFormula = formulaLeftBracketExplode[0];
				for(let e = 1; e < formulaLeftBracketExplode.length; e++) {
					var formulaSegment = formulaLeftBracketExplode[e];
					var rightBracketPos = formulaSegment.indexOf("]");
					if(rightBracketPos < 0) { //this should never happen, all formulas have a [ and a corresponding ]
						shiftedSubtotalFormula += "[" + formulaSegment; //put it back the way that it was originally
					}
					else { //right bracket found, extract the negative number and add the offset to it and put it back into the formula
						var originalNegativeNumber = formulaSegment.substring(0, rightBracketPos);
						var remainingSegment = formulaSegment.substring(rightBracketPos);
						var shiftedNegativeNumber = originalNegativeNumber + subtotalNumRowsAboveBottomRow;
						shiftedSubtotalFormula += "[" + shiftedNegativeNumber + remainingSegment;
					}
				}
			}


			//append the xml parts and changed formula to xmlOut and xmlRemaining
			replacedTotalBlockXml += xmlLeft + shiftedSubtotalFormula;
			xmlRemaining = xmlRight;
		}
	}

	return(replacedTotalBlockXml);
}


function excel_xml_finproj_userranktable_compute_full_table_xml_and_num_rows_and_columns_obj(i_vcrObj) {
  //setting up this computed table in the excel xml template requires 4 columns of data all in a single row
  //[[finproj:userranktable]]   [[contract_overall_value]]   [[+filter:custom_filter]]   [[+sort:custom_sort]]
  const allUsersMapOfMaps = DatabaseMobx.c_tbl_a_users;
  const expandedCaptureFieldsMapOfMaps = DatabaseMobx.c_tbl_captures_fields;
  const fieldMapOfContractOverallValue = DatabaseMobx.c_fieldMapOfContractOverallValue;

  //unpack the vcrObj
  const vcrPreamble = i_vcrObj.vcrPreamble;
  const codewordsArrayOfObjs = i_vcrObj.codewordsArrayOfObjs; //codewordCellStart, codeword, codewordExpandedCaptureFieldMap, codewordCellEnd, codewordConclusion
  const sortedFilteredCapturesArrayOfMaps = i_vcrObj.sortedFilteredCapturesArrayOfMaps;

  //initialize the xml string returned using the preamble to the first row the special codeword was found in
  var xmlString = vcrPreamble;

  //find an optional specified money field to base the $ calculations on, otherwise default to contract_overall_value
  var moneyFieldExpandedCaptureFieldMap = fieldMapOfContractOverallValue;
  for(let codewordObj of codewordsArrayOfObjs) {
    var codewordLowercase = codewordObj.codeword.toLowerCase();
    var codewordExpandedCaptureFieldMap = expanded_capture_field_map_from_lowercase_codeword_and_expanded_captures_fields_mapOfMaps(codewordLowercase, expandedCaptureFieldsMapOfMaps);
    if(codewordExpandedCaptureFieldMap !== undefined) {
      var codewordFieldTypeObj = codewordExpandedCaptureFieldMap.get("fieldTypeObj");
      if(codewordFieldTypeObj !== undefined) {
        if(codewordFieldTypeObj.moneyTF) {
          moneyFieldExpandedCaptureFieldMap = codewordExpandedCaptureFieldMap;
        }
      }
    }
  }

  //initialize a total $ for every user in the system to 0
  var finProjUserRankTableUsersArrayOfObjs = [];
  for(let userMap of allUsersMapOfMaps.values()) {
    finProjUserRankTableUsersArrayOfObjs.push({
      userID: userMap.get("user_id"),
      userFullName: userMap.get("fullName"),
      userDivisionName: userMap.get("divisionName"),
      numCaptures: 0,
      totalMoneyValue: 0
    });
  }
  const numUsers = finProjUserRankTableUsersArrayOfObjs.length;

  //loop through all filtered captures, giving each userID credit for capture ownership
  for(let captureMap of sortedFilteredCapturesArrayOfMaps) {
    var captureMoneyFieldValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, moneyFieldExpandedCaptureFieldMap);
    var captureManagersUserIDsPercentsArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(captureMap.get("capture_managers_ids_colon_percent_comma"), "userID", "percent");
    for(let u = 0; u < numUsers; u++) {
      for(let captureManagerUserIDPercentObj of captureManagersUserIDsPercentsArrayOfObjs) {
        if(captureManagerUserIDPercentObj.userID === finProjUserRankTableUsersArrayOfObjs[u].userID) {
          finProjUserRankTableUsersArrayOfObjs[u].numCaptures += (captureManagerUserIDPercentObj.percent / 100);
          finProjUserRankTableUsersArrayOfObjs[u].totalMoneyValue += (captureMoneyFieldValueRaw * (captureManagerUserIDPercentObj.percent / 100));
        }
      }
    }
  }

  //sort the users by money field total value desc
  JSFUNC.sort_arrayOfObjs(finProjUserRankTableUsersArrayOfObjs, "totalMoneyValue", false);

  //create the table in xml
  for(let finProjUserRankTableUserObj of finProjUserRankTableUsersArrayOfObjs) {
    xmlString += '<Cell><Data ss:Type="String">' + JSFUNC.htmlspecialchars(finProjUserRankTableUserObj.userFullName) + '</Data></Cell>';
    xmlString += '<Cell><Data ss:Type="String">' + JSFUNC.htmlspecialchars(finProjUserRankTableUserObj.userDivisionName) + '</Data></Cell>';
    xmlString += '<Cell><Data ss:Type="Number">' + finProjUserRankTableUserObj.numCaptures + '</Data></Cell>';
    xmlString += '<Cell><Data ss:Type="Number">' + finProjUserRankTableUserObj.totalMoneyValue + '</Data></Cell>';
    xmlString += '</Row>';
    xmlString += '<Row ss:AutoFitHeight="0">';
  }

  //place all the original xml from the finproj codeword row back at the bottom of the new table
  for(let codewordObj of codewordsArrayOfObjs) {
    xmlString += codewordObj.codewordCellStart;
    xmlString += codewordObj.codewordCellEnd;
    xmlString += codewordObj.codewordConclusion;
  }

  return({
    xmlString: xmlString,
    numRowsAdded: (numUsers + 1),
    numColumnsRequired: 4
  });
}


function excel_xml_finproj_fyrtable_compute_full_table_xml_and_num_rows_and_columns_obj(i_vcrObj) {
  //setting up this computed table in the excel xml template requires 5 columns of data all in a single row
  //[[finproj:fyrtable]]   [[header:division_owners_ids_colon_percent_comma]]   [[fyr:2012-2025]]   [[money:contract_overall_value]]   [[+filter:custom_filter]]   [[+sort:custom_sort]]
  const expandedCaptureFieldsMapOfMaps = DatabaseMobx.c_tbl_captures_fields;
  const fieldMapOfDivisionOwners = DatabaseMobx.c_fieldMapOfDivisionOwners;
  const fieldMapOfContractOverallValue = DatabaseMobx.c_fieldMapOfContractOverallValue;

  //unpack the vcrObj
  const vcrPreamble = i_vcrObj.vcrPreamble;
  const codewordsArrayOfObjs = i_vcrObj.codewordsArrayOfObjs; //codewordCellStart, codeword, codewordExpandedCaptureFieldMap, codewordCellEnd, codewordConclusion
  const sortedFilteredCapturesArrayOfMaps = i_vcrObj.sortedFilteredCapturesArrayOfMaps;

  //initialize the xml string returned using the preamble to the first row the special codeword was found in
  var xmlString = vcrPreamble;

  //find an optional specified money field to base the $ calculations on, otherwise default to contract_overall_value
  var headerFieldExpandedCaptureFieldMap = fieldMapOfDivisionOwners;
  var headerFieldSelectWithSearchDataObj = fieldMapOfDivisionOwners.get("fieldTypeObj").selectWithSearchDataObj;
  var moneyFieldExpandedCaptureFieldMap = fieldMapOfContractOverallValue; //if money field is unspecified, use contract_overall_value
  for(let codewordObj of codewordsArrayOfObjs) {
    var codewordLowercase = codewordObj.codeword.toLowerCase();

    if(codewordLowercase.substring(0, 7) === "header:") { //match the header field if the field is a select or sharedpercent type
      codewordLowercase = codewordLowercase.substring(7);
      var expandedCaptureFieldMap = expanded_capture_field_map_from_lowercase_codeword_and_expanded_captures_fields_mapOfMaps(codewordLowercase, expandedCaptureFieldsMapOfMaps);
      if(expandedCaptureFieldMap !== undefined) {
        var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");
        if(fieldTypeObj !== undefined) {
          if(fieldTypeObj.selectTF || fieldTypeObj.sharedPercentTF) {
            if(fieldTypeObj.selectWithSearchDataObj !== undefined) {
              headerFieldExpandedCaptureFieldMap = expandedCaptureFieldMap;
              headerFieldSelectWithSearchDataObj = fieldTypeObj.selectWithSearchDataObj;
            }
          }
        }
      }
    }
    else if(codewordLowercase.substring(0, 6) === "money:") { //match the money field if the field is a money type
      codewordLowercase = codewordLowercase.substring(6);
      var expandedCaptureFieldMap = expanded_capture_field_map_from_lowercase_codeword_and_expanded_captures_fields_mapOfMaps(codewordLowercase, expandedCaptureFieldsMapOfMaps);
      if(expandedCaptureFieldMap !== undefined) {
        var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");
        if(fieldTypeObj !== undefined) {
          if(fieldTypeObj.moneyTF) {
            moneyFieldExpandedCaptureFieldMap = expandedCaptureFieldMap;
          }
        }
      }
    }
  }

  var perfString = ""; //speed performance of the code
  //initialize a total $ for every user in the system to 0
  var finProjDivisionFyrTableDivisionsArrayOfObjs = [];
  for(let v = 0; v < headerFieldSelectWithSearchDataObj.valueArray.length; v++) {

    var t1 = performance.now();
    var singleDivisionArrayOfObjs = [
      {capture_field_id:headerFieldExpandedCaptureFieldMap.get("id"), operator:"e", value:headerFieldSelectWithSearchDataObj.valueArray[v]},
      {capture_field_id:DatabaseMobx.c_fieldMapOfContractStartDate.get("id"), operator:"gt", value:"1900-00-00"},
      {capture_field_id:DatabaseMobx.c_fieldMapOfPeriodOfPerformance.get("id"), operator:"gt", value:0}
    ];
    var singleDivisionExpandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(singleDivisionArrayOfObjs);
    perfString += Math.round(performance.now() - t1) + ",";

    //filter captures from full set
    var t2 = performance.now();
    var divisionFilteredCaptureMapsAndTcvMultipliersArrayOfObjs = [];
    for(let captureMap of sortedFilteredCapturesArrayOfMaps) {
      var partialMultiplier0to1 = CapturesMobx.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, singleDivisionExpandedFiltersArrayOfObjs);
      if(partialMultiplier0to1 > 0) {
        divisionFilteredCaptureMapsAndTcvMultipliersArrayOfObjs.push({
          captureMap: captureMap,
          partialMultiplier0to1: partialMultiplier0to1
        });
      }
    }
    perfString += Math.round(performance.now() - t2) + ",";

    var t3 = performance.now();
    var divisionFinProjObj = undefined;
    if(divisionFilteredCaptureMapsAndTcvMultipliersArrayOfObjs.length > 0) {
      divisionFinProjObj = DivexecMobx.compute_finproj_obj("Division FYR Table", "Division FYR Table", divisionFilteredCaptureMapsAndTcvMultipliersArrayOfObjs, moneyFieldExpandedCaptureFieldMap);
    }
    perfString += Math.round(performance.now() - t3) + ";";

    finProjDivisionFyrTableDivisionsArrayOfObjs.push({
      divisionName: headerFieldSelectWithSearchDataObj.displayArray[v],
      divisionFinProjObj: divisionFinProjObj
    });
  }
  const numDivisions = finProjDivisionFyrTableDivisionsArrayOfObjs.length;

  //find an optional lower/higher FYR specification codeword in the xml template [[fyr:2012-2025]]
  var tableLowestFyr = undefined;
  var tableHighestFyr = undefined;
  for(let codewordObj of codewordsArrayOfObjs) {
    var codewordLowercase = codewordObj.codeword.toLowerCase();
    if(codewordLowercase.substring(0,4) === "fyr:") {
      var tempLowestFyr = JSFUNC.str2int(codewordLowercase.substring(4,8));
      var tempHighestFyr = JSFUNC.str2int(codewordLowercase.substring(9,13));
      if((tempLowestFyr > 1900) && (tempLowestFyr < 3000) && (tempHighestFyr > 1900) && (tempHighestFyr < 3000) && (tempHighestFyr >= tempLowestFyr)) {
        tableLowestFyr = tempLowestFyr;
        tableHighestFyr = tempHighestFyr;
      }
      break;
    }
  }

  //if the FYR codeword is not found, default to finding the min/max fyr of all the computed data
  if((tableLowestFyr === undefined) || (tableHighestFyr === undefined)) {
    for(let finProjDivisionFyrTableDivisionObj of finProjDivisionFyrTableDivisionsArrayOfObjs) {
      var divisionFinProjObj = finProjDivisionFyrTableDivisionObj.divisionFinProjObj;
      if(divisionFinProjObj !== undefined) {
        var divisionLowestFyr = divisionFinProjObj.fyrArray[0]; //first year in fyrArray
        var divisionHighestFyr = divisionFinProjObj.fyrArray[divisionFinProjObj.numYears - 1]; //last year in fyrArray
        if((tableLowestFyr === undefined) || (tableHighestFyr === undefined)) {
          tableLowestFyr = divisionLowestFyr;
          tableHighestFyr = divisionHighestFyr;
        }
        else {
          if(divisionLowestFyr < tableLowestFyr) {
            tableLowestFyr = divisionLowestFyr;
          }

          if(divisionHighestFyr > tableHighestFyr) {
            tableHighestFyr = divisionHighestFyr;
          }
        }
      }
    }
  }

  //if the lowest/highest FYR is still not set (0 divisions have captures), set it to just the current year
  if((tableLowestFyr === undefined) || (tableHighestFyr === undefined)) {
    var todayFyrObj = DatabaseMobx.convert_date_Ymd_to_fyrYear_fyrMonth0to11_obj(JSFUNC.now_date());
    tableLowestFyr = todayFyrObj.fyrYear;
    tableHighestFyr = todayFyrObj.fyrYear;
  }

  //create the table in xml
  xmlString += '<Cell><Data ss:Type="String">FYR</Data></Cell>';
  for(let finProjDivisionFyrTableDivisionObj of finProjDivisionFyrTableDivisionsArrayOfObjs) {
    xmlString += '<Cell><Data ss:Type="String">' + JSFUNC.htmlspecialchars(finProjDivisionFyrTableDivisionObj.divisionName) + '</Data></Cell>';
  }
  xmlString += '</Row>';
  xmlString += '<Row ss:AutoFitHeight="0">';

  for(let fyr = tableLowestFyr; fyr <= tableHighestFyr; fyr++) {
    xmlString += '<Cell><Data ss:Type="Number">' + fyr + '</Data></Cell>';
    for(let finProjDivisionFyrTableDivisionObj of finProjDivisionFyrTableDivisionsArrayOfObjs) {
      var divisionFyrMoneyValue = 0;
      var divisionFinProjObj = finProjDivisionFyrTableDivisionObj.divisionFinProjObj;
      if(divisionFinProjObj !== undefined) { //if there was at least 1 capture in this division filter (otherwise value for this fyr is 0)
        //find the matching fyr in the totals array
        var matchingFyrIndex = -1;
        for(let f = 0; f < divisionFinProjObj.numYears; f++) {
          if(divisionFinProjObj.fyrArray[f] === fyr) {
            matchingFyrIndex = f;
            break;
          }
        }

        if(matchingFyrIndex >= 0) { //if a matching fyr was found, get the total value for that fyr from the totals array using the found index
          divisionFyrMoneyValue = divisionFinProjObj.totalMoneyValuePerFyrArray[matchingFyrIndex];
        }
      }
      xmlString += '<Cell><Data ss:Type="Number">' + divisionFyrMoneyValue + '</Data></Cell>';
    }
    xmlString += '</Row>';
    xmlString += '<Row ss:AutoFitHeight="0">';
  }

  //xmlString += '<Cell><Data ss:Type="String">' + perfString + '</Data></Cell>';

  //place all the original xml from the finproj codeword row back at the bottom of the new table
  for(let codewordObj of codewordsArrayOfObjs) {
    xmlString += codewordObj.codewordCellStart;
    xmlString += codewordObj.codewordCellEnd;
    xmlString += codewordObj.codewordConclusion;
  }

  return({
    xmlString: xmlString,
    numRowsAdded: ((tableHighestFyr - tableLowestFyr + 1) + 2),
    numColumnsRequired: (numDivisions + 1)
  });
}
