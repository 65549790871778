import React, { Component, useEffect, useState } from "react";
import { observer, inject } from 'mobx-react';

import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import * as LibraryReact from "../../Library/LibraryReact.js";
import * as JSFUNC from "../../Library/JSFUNC.js";
import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import * as CaptureExecReact from "../CaptureExec/CaptureExecReact.js";

//https://stripe.com/docs/payments/quickstart  (turn on the switches at the bottom to see code for automated receipt_email sent to buyer's email address, plus create a stripe customer to save customer details)

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//constant set to switch between using Stripe test mode and live mode keys/products/prices
const stripeLiveModeTrueTestModeFalse = true; //true, false
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

//Stripe public key (make sure to call loadStripe outside of a component’s render to avoid recreating the Stripe object on every render)
var stripeApiKeyPublic = "pk_live_51M7KSjFtGvcbRuR60T4IDPe3Jyjlc2fhZcF7j2OIl9HQu5BM5mOz9mpa9DsyaBIMcmhLWZ2rAQ1G81SWiRdqk8Ip00dTZfkY3i";
if(stripeLiveModeTrueTestModeFalse === false) { //Stripe test mode keys/products/prices
  stripeApiKeyPublic = "pk_test_51M7KSjFtGvcbRuR6vcQD0naZNPlUZibIuJjGk6DsvMX8QeGi1LM8HSmjoTmoIlrYjaXCnuWKrdL9sjyf0jhEZPWw00van70bW8";
}
const stripePromise = loadStripe(stripeApiKeyPublic);


export const SamGovTrackerHomepageAdWithLogin = inject("CaptureExecMobx", "SamGovTrackerMobx")(observer(
class SamGovTrackerHomepageAdWithLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_actionLogLoopIndex: 0,
      s_loginPanelIsOpenTF: false
    }
  }

  componentDidMount() {
    //after 5 sec on the homepage, trigger the log action command once, by this time, media query flag will have loaded its value
    this.actionLoopID = setInterval(() => this.log_sgt_action_on_first_loop(), 5000);

    if(document.location.href !== "http://localhost:3000/") { //manual check for localhost since this triggers at initial CE/SGT page load before o_isLocalhost3000SystemTF is determined/set in CaptureExecMobx (which blocks php API attempts for localhost)
      
      this.props.SamGovTrackerMobx.a_load_testimonials_arrayOfObjs();
    }
  }

  componentWillUnmount() {
    clearInterval(this.actionLoopID);
  }

  log_sgt_action_on_first_loop = () => {
    const s_actionLogLoopIndex = this.state.s_actionLogLoopIndex;
    if(s_actionLogLoopIndex === 1) {
      this.props.SamGovTrackerMobx.a_log_sgt_ad_client_action("sgtHome", "");
    }
    this.setState({s_actionLogLoopIndex:(s_actionLogLoopIndex + 1)});
  }

  onclick_open_login_panel = () => { this.setState({s_loginPanelIsOpenTF:true}); }
  onclick_close_login_panel = () => { this.setState({s_loginPanelIsOpenTF:false}); }

  render() {
    const s_loginPanelIsOpenTF = this.state.s_loginPanelIsOpenTF;

    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    return(
      <>
        <div className="positionFixedFullScreen bgWhite yScroll">
          <div className="displayFlexRow borderB1bbb" style={{height:"7em"}}>
            <div className="flex00a displayFlexColumnHcVc lrHugePad">
              <div className="" style={{width:"142px", height:"48px"}}>
                <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/samgovtracker16.png"} p_alt={c_productStylingObj.productName} />
              </div>
            </div>
            <div className="flex11a" />
            <div
              className="flex00a displayFlexColumnHcVc borderL1ddd hoverLightesterGray textCenter hoverFontUnderline cursorPointer"
              style={{flexBasis:"10em"}}
              onClick={this.onclick_open_login_panel}>
              <font className="font16 fontDarkGrayBlueSamGovTracker">
                {"Login"}
              </font>
            </div>
          </div>
          <SGTHomepageAdContents
            f_onClickLogin={this.onclick_open_login_panel}
          />
        </div>
        {(s_loginPanelIsOpenTF) &&
          <div
            className="positionAbsolute displayFlexColumn borderR1bbb bgGray"
            style={{zIndex:2, top:0, right:0, bottom:0, width:"95%", maxWidth:"40em", boxShadow:"-0.5em 0.5em 1em #444"}}>
            <div className="positionRelative displayFlexRow">
              <div
                className="flex00a displayFlexColumnHcVc textCenter cursorPointer"
                style={{flexBasis:"4em", height:"4em"}}
                title="Close Login"
                onClick={this.onclick_close_login_panel}>
                <font className="font20 fontAlmostWhite">
                  {"\u2715"}
                </font>
              </div>
              <div className="flex11a" />
            </div>
            <div
              className={"positionRelative displayFlexColumnHc border2999 borderRadius15 bgLightGray flex11a yScroll"}
              style={{width:"auto", margin:"0 1em 1em 1em", padding:"3.3em 2.5em"}}>
              <div>
                <LibraryReact.Image
                  p_src={c_productWebsiteGraphicsSubfolderAddress + "/samgovtracker26.png"}
                  p_alt={c_productStylingObj.productName}
                />
              </div>
              <div className="hugeTopPad" />
              <CaptureExecReact.LoginProcess p_inputTextClass="sgtLoginInput font12" />
            </div>
          </div>
        }
      </>
    );
  }
}));




export const SamGovTrackerBuySGTProcess = inject("CaptureExecMobx", "SamGovTrackerMobx")(observer(
class SamGovTrackerBuySGTProcess extends Component {
  componentDidMount() {
    this.props.SamGovTrackerMobx.a_log_sgt_ad_client_action("buySGTCustomerInfo", "");

    //immediately on mount, fetch every company code used by existing customers as a comma list
    this.load_all_used_company_codes();

    //in parallel, load the prices of both SGT created product options from the Stripe dashboard using the PHP Stripe API
    this.stripe_fetch_sgt_product_prices();
  }

  load_all_used_company_codes = () => {
    const jsDescription = JSFUNC.js_description_from_action("SamGovTrackerReact - SamGovTrackerBuySGTProcess", "load_all_used_company_codes", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadAllUsedCompanyCodes", jsDescription);
    C_CallPhpScript.add_return_vars("allUsedCompanyCodesComma");

    const functionOnSuccess = (i_parseResponse) => {
      this.props.SamGovTrackerMobx.a_set_all_used_company_codes_comma(i_parseResponse.allUsedCompanyCodesComma);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
  }

  stripe_fetch_sgt_product_prices = () => {
    const jsDescription = JSFUNC.js_description_from_action("SamGovTrackerReact - SamGovTrackerBuySGTProcess", "stripe_fetch_sgt_product_prices", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("sgt_stripe_api", jsDescription);
    C_CallPhpScript.add_post_var("i_stripeApi", "fetchSGTProductsInfo");
    C_CallPhpScript.add_return_vars("sgtNoDocsPriceAmount");
    C_CallPhpScript.add_return_vars("sgtNoDocsPriceCurrency");
    C_CallPhpScript.add_return_vars("sgtWithDocsPriceAmount");
    C_CallPhpScript.add_return_vars("sgtWithDocsPriceCurrency");

    const functionOnSuccess = (i_parseResponse) => {
      this.props.SamGovTrackerMobx.a_set_all_sgt_product_prices(i_parseResponse.sgtNoDocsPriceAmount, i_parseResponse.sgtNoDocsPriceCurrency, i_parseResponse.sgtWithDocsPriceAmount, i_parseResponse.sgtWithDocsPriceCurrency);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
  }

  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_testimonialsArrayOfObjs = this.props.SamGovTrackerMobx.o_testimonialsArrayOfObjs;
    const c_buyProcessStateIsInfoTF = this.props.SamGovTrackerMobx.c_buyProcessStateIsInfoTF;
    const c_buyProcessStateIsCCTF = this.props.SamGovTrackerMobx.c_buyProcessStateIsCCTF;

    const mqf3TF = (o_mediaQueryFlag === 3);

    const backToHomeTopRightComponent = (
      <div className="displayFlexRow smallFullPad">
        <div className="flex11a" />
        <div className="flex00a">
          <SGTBuyBackToHomeLink />
        </div>
      </div>
    );
    
    var outerMarginEm = 3.5;
    var innerPaddingEm = 3.5;
    if(c_isMobileTF) {
      outerMarginEm = 1;
      innerPaddingEm = 1;
    }
    else if(mqf3TF) {
      outerMarginEm = 2;
      innerPaddingEm = 2;
    }

    const leftSideComponent = (
      <div className="border1bbb borderRadius10" style={{background:"#f9f9f9", padding:innerPaddingEm + "em"}}>
        <BuySGTCustomerInfoFormContents />
      </div>
    );

    var rightSideComponent = null;
    if(c_buyProcessStateIsInfoTF) {
      rightSideComponent = (
        <>
          <div className="hugeFullPad textCenter">
            <font className="font25 fontBold fontWhite">
              {"SAM Gov Tracker simplifies & automates capture management for GovCon startups."}
            </font>
          </div>
          <SGTAdTestimonialsCarousel p_testimonialsArrayOfObjs={o_testimonialsArrayOfObjs} p_numTestimonialsDisplayed={1} />
        </>
      );
    }
    else if(c_buyProcessStateIsCCTF) {
      rightSideComponent = (
        <div className="border1bbb borderRadius10 animationFadeIn" style={{background:"#f9f9f9", padding:innerPaddingEm + "em"}}>
          <BuySGTCardTitle>{"Ready To Get Started?"}</BuySGTCardTitle>
          <StripeSubscriptionCheckoutForm />
        </div>
      );
    }

    var buySGTProcessContentsComponent = null;
    if(c_isMobileOrTabletTF) {
      buySGTProcessContentsComponent = (
        <div style={{padding:"0 " + outerMarginEm + "em"}}>
          {leftSideComponent}
          <div style={{paddingTop:"4em"}} />
          {rightSideComponent}
          <div style={{paddingTop:"4em"}} />
        </div>
      );
    }
    else {
      buySGTProcessContentsComponent = (
        <div className="displayFlexColumnHcVc">
          <div className="displayFlexRow" style={{width:"100%", maxWidth:"1300px"}}>
            <div className="flex11a" style={{flexBasis:"100em", padding:"0 " + outerMarginEm + "em"}}>
              {leftSideComponent}
            </div>
            <div className="flex11a" style={{flexBasis:"100em", padding:"0 " + outerMarginEm + "em"}}>
              {rightSideComponent}
            </div>
          </div>
        </div>
      );
    }
    
    return(
      <div className="positionFixedFullScreen sgtAdSection1BgBlueGradient yScroll">
        {backToHomeTopRightComponent}
        {buySGTProcessContentsComponent}
      </div>
    );
  }
}));




const StripeSubscriptionCheckoutForm = inject("SamGovTrackerMobx")(observer(
class StripeSubscriptionCheckoutForm extends Component { //props: 
  constructor(props) {
    super(props);
    this.state = {
      s_stripeSubscriptionPaymentIntentClientSecret: ""
    };
  }

  componentDidMount() {
    const o_customerCompanyName = this.props.SamGovTrackerMobx.o_customerCompanyName;
    const o_customerCompanyCode = this.props.SamGovTrackerMobx.o_customerCompanyCode;
    const o_customerFirstName = this.props.SamGovTrackerMobx.o_customerFirstName;
    const o_customerLastName = this.props.SamGovTrackerMobx.o_customerLastName;
    const o_customerEmail = this.props.SamGovTrackerMobx.o_customerEmail;
    const o_purchaseSGTNumUsers = this.props.SamGovTrackerMobx.o_purchaseSGTNumUsers;
    const o_purchaseSGTProduct = this.props.SamGovTrackerMobx.o_purchaseSGTProduct;
    var customerInfo = "[" + o_customerCompanyCode + "] " + o_customerCompanyName;
    customerInfo += "\n" + o_customerFirstName + " " + o_customerLastName + " (" + o_customerEmail + ")";
    customerInfo += "\n" + o_purchaseSGTProduct + " {" + o_purchaseSGTNumUsers + "}";
    this.props.SamGovTrackerMobx.a_log_sgt_ad_client_action("buySGTStripeCheckout", customerInfo);

    //create a new Customer and a new incomplete Subscription as soon as the component loads by calling sgt_stripe_api.php and storing the returned clientSecret
    this.stripe_create_new_customer_and_incomplete_subscription();
  }

  stripe_create_new_customer_and_incomplete_subscription = () => {
    const o_customerCompanyName = this.props.SamGovTrackerMobx.o_customerCompanyName;
    const o_customerCompanyCode = this.props.SamGovTrackerMobx.o_customerCompanyCode;
    const o_customerFirstName = this.props.SamGovTrackerMobx.o_customerFirstName;
    const o_customerLastName = this.props.SamGovTrackerMobx.o_customerLastName;
    const o_customerEmail = this.props.SamGovTrackerMobx.o_customerEmail;
    const o_purchaseSGTNumUsers = this.props.SamGovTrackerMobx.o_purchaseSGTNumUsers;
    const o_purchaseSGTProduct = this.props.SamGovTrackerMobx.o_purchaseSGTProduct;

    const jsDescription = JSFUNC.js_description_from_action("SamGovTrackerReact - StripeSubscriptionCheckoutForm", "stripe_create_new_customer_and_incomplete_subscription", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("sgt_stripe_api", jsDescription);

    C_CallPhpScript.add_post_var("i_stripeApi", "createNewCustomerAndIncompleteSubscription");
    C_CallPhpScript.add_post_var("i_companyName", o_customerCompanyName);
    C_CallPhpScript.add_post_var("i_companyCode", o_customerCompanyCode);
    C_CallPhpScript.add_post_var("i_customerFirstName", o_customerFirstName);
    C_CallPhpScript.add_post_var("i_customerLastName", o_customerLastName);
    C_CallPhpScript.add_post_var("i_customerEmail", o_customerEmail);
    C_CallPhpScript.add_post_var("i_purchaseSGTProduct", o_purchaseSGTProduct);
    C_CallPhpScript.add_post_var("i_purchaseSGTNumUsers", o_purchaseSGTNumUsers);

    C_CallPhpScript.add_return_vars("clientSecret");
    C_CallPhpScript.add_return_vars("paymentIntentAmount");

    const functionOnSuccess = (i_parseResponse) => {
      this.setState({s_stripeSubscriptionPaymentIntentClientSecret:i_parseResponse.clientSecret});
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
  }

  render() {
    const s_stripeSubscriptionPaymentIntentClientSecret = this.state.s_stripeSubscriptionPaymentIntentClientSecret;

    const o_customerEmail = this.props.SamGovTrackerMobx.o_customerEmail;

    if(s_stripeSubscriptionPaymentIntentClientSecret === "") {
      return(
        <div className="bigTopMargin textCenter">
          <font className="font11 fontDarkGrayBlueSamGovTracker">
            {"Establishing a secure connection..."}
          </font>
        </div>
      );
    }

    //appearance options (within Elements options input) https://stripe.com/docs/elements/appearance-api
    const stripeElementsOptionsAppearanceObj = {
      theme: 'stripe'
    };

    //Elements options input (client secret string fetched from server required) https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    const stripeElementsOptionsObj = {
      clientSecret: s_stripeSubscriptionPaymentIntentClientSecret,
      appearance: stripeElementsOptionsAppearanceObj
    };

    return(
      <Elements stripe={stripePromise} options={stripeElementsOptionsObj}>
        <StripeCheckoutFormAndPayButtonWithSuccessURLRedirect i_receiptEmail={o_customerEmail} />
      </Elements>
    );
  }
}));



function StripeCheckoutFormAndPayButtonWithSuccessURLRedirect({i_receiptEmail}) {
  const stripe = useStripe();
  const elements = useElements();

  const [messageOrNull, f_setMessage] = useState(null);
  const [isLoadingTF, f_setIsLoadingTF] = useState(false);

  //effect for when useStripe() stripe obj changes status
  useEffect(() => {
    if(!stripe) {
      return;
    }

    const paymentIntentClientSecretFromUrlVar = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    if(!paymentIntentClientSecretFromUrlVar) {
      return;
    }

    stripe.retrievePaymentIntent(paymentIntentClientSecretFromUrlVar).then(({ paymentIntent }) => {
      switch(paymentIntent.status) {
        case "succeeded":
          f_setMessage("Payment succeeded!");
          break;
        case "processing":
          f_setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          f_setMessage("Your payment was not successful, please try again.");
          break;
        default:
          f_setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  //[https://stripe.com/docs/payments/quickstart]
  // - When your customer clicks the pay button, call stripe.confirmPayment() with the PaymentElement and pass a return_url to indicate where Stripe should redirect the user after they complete the payment.
  // - For payments that require authentication, Stripe displays a modal for 3D Secure authentication or redirects the customer to an authentication page depending on the payment method.
  // - After the customer completes the authentication process, they’re redirected to the return_url.
  const functionOnSubmitPaymentForm = async (event) => {
    event.preventDefault();

    //Stripe.js has not yet loaded. Make sure to disable form submission until Stripe.js has loaded.
    if(!stripe || !elements) {
      return;
    }

    f_setIsLoadingTF(true);

    //redirects page to https://samgovtracker.com/php/sgt_signup_success.php?payment_intent=pi_3MHlRHFtGvcbRuR61R8NrEng&payment_intent_client_secret=pi_3MHlRHFtGvcbRuR61R8NrEng_secret_ZbJDdoPONiSFS1Xka3e0fh6sK&redirect_status=succeeded
    // - payment_intent=pi_3MHlRHFtGvcbRuR61R8NrEng
    // - payment_intent_client_secret=pi_3MHlRHFtGvcbRuR61R8NrEng_secret_ZbJDdoPONiSFS1Xka3e0fh6sK
    // - redirect_status=succeeded
    //it is mandatory for return_url to be set and to redirect to that page with the added URL vars, otherwise the Subscription Payment remains as 'incomplete'
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://samgovtracker.com/php/sgt_signup_success.php",
        receipt_email: i_receiptEmail,
      }
    });

    //This point will only be reached if there is an immediate error when confirming the payment. Otherwise, your customer will be redirected to your `return_url`.
    //For some payment methods like iDEAL, your customer will be redirected to an intermediate site first to authorize the payment, then redirected to the `return_url`.
    if((error.type === "card_error") || (error.type === "validation_error")) {
      f_setMessage(error.message);
    }
    else {
      f_setMessage("An unexpected error occurred.");
    }

    f_setIsLoadingTF(false);
  };

  const stripePaymentElementOptionsObj = {
    layout: "tabs"
  }

  const payButtonIsDisabledTF = (isLoadingTF || !stripe || !elements);

  return(
    <form id="payment-form" onSubmit={functionOnSubmitPaymentForm}>
      <PaymentElement id="payment-element" options={stripePaymentElementOptionsObj} />
      <div className="medTopMargin" />
      <button
        id="submit"
        disabled={payButtonIsDisabledTF}
        className="border bevelBorderColors borderRadius05 bgRedGradientSamGovTracker hoverLightRedGradientSamGovTracker fontBold fontWhite cursorPointer"
        style={{width:"10em", height:"2.5em"}}>
        <span id="button-text">
          {(isLoadingTF) ? (
            <div id="spinner" className="spinner" />
          ) : (
            "Pay Now"
          )}
        </span>
      </button>
      {(messageOrNull) && 
        <div id="payment-message" className="smallTopMargin">
          <div className="font11 fontDarkGrayBlueSamGovTracker">
            {messageOrNull}
          </div>
        </div>
      }
    </form>
  );
}




//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^



const SGTHomepageAdContents = inject("CaptureExecMobx", "SamGovTrackerMobx")(observer(
class SGTHomepageAdContents extends Component { //props: f_onClickLogin
  constructor(props) {
    super(props);
    this.state = {
      s_selectedFeaturesTabIndex: 0
    };
  }

  onclick_sgt_sign_up_now = () => {
    this.props.CaptureExecMobx.a_set_login_state("sgtBuySgtEnterInfo");
  }

  onclick_features_tab = (i_featuresTabIndex) => {
    this.setState({s_selectedFeaturesTabIndex:i_featuresTabIndex});
  }

  render () {
    const s_selectedFeaturesTabIndex = this.state.s_selectedFeaturesTabIndex;

    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_nowYearInt = this.props.CaptureExecMobx.c_nowYearInt;
    const o_testimonialsArrayOfObjs = this.props.SamGovTrackerMobx.o_testimonialsArrayOfObjs;

    const mqf3TF = (o_mediaQueryFlag === 3);

    //section 1 - footprints taglines
    var section1FootprintsTaglinesComponent = null;
    if(c_isMobileOrTabletTF) {
      section1FootprintsTaglinesComponent = (
        <div style={{paddingTop:"1em"}} />
      );
    }
    else {
      section1FootprintsTaglinesComponent = (
        <SGTAdFootprintsTaglinesHorizonal />
      );
    }

    //section 1 - two products
    const productBoxTightMarginsTF = (c_isMobileTF || mqf3TF);

    const productBoxSGTComponent = (
      <SGTAdProductBox
        p_product="samgovtracker"
        p_productName="SAM Gov Tracker"
        p_logoFileNameWithExt="samgovtracker14.png"
        p_topText="Capture management solution for startup, micro, small-sized government contractors"
        p_leftBulletsTextArray={["Find deals that match your talent", "Consulting support for sales strategy and proposal management", "No binding contracts", "Win more deals!"]}
        p_rightBulletsTextArray={undefined}
        p_priceDollarsPerMonthOrUndefined={25}
        p_priceFontColorClass="fontRed"
        p_buttonText="Sign Up Now"
        p_tightMarginsTF={productBoxTightMarginsTF}
      />
    );

    const productBoxCEComponent = (
      <SGTAdProductBox
        p_product="captureexec"
        p_productName="CaptureExec"
        p_logoFileNameWithExt="captureexec14.png"
        p_topText="All SAM Gov Tracker features +"
        p_leftBulletsTextArray={["Grow your business", "Develop teaming strategies", "Analyze why you win/lose", "Identify competition", "Assess Risk", "Develop your Win Themes", "Grade your Deal Shaping", "Sales team to follow repeatable Sales process"]}
        p_rightBulletsTextArray={["Pwin calculator", "Automated integration to SAM, GovWin, and Federal Compass", "Auto-generate NDA/TA/SubK", "Budget tracking by deal", "Executive oversight and management", "Contact management system", "One-click Gate Review"]}
        p_priceDollarsPerMonthOrUndefined={undefined}
        p_priceFontColorClass=""
        p_buttonText="Learn More"
        p_tightMarginsTF={productBoxTightMarginsTF}
      />
    );

    const betweenTextSGTComponent = (
      <>
        <SGTAdBetweenProductBoxesWhiteText p_boldTF={false} p_smallFontTF={mqf3TF}>SAM Gov Tracker</SGTAdBetweenProductBoxesWhiteText>
        <SGTAdBetweenProductBoxesWhiteText p_boldTF={false} p_smallFontTF={mqf3TF}>for your</SGTAdBetweenProductBoxesWhiteText>
        <SGTAdBetweenProductBoxesWhiteText p_boldTF={true} p_smallFontTF={mqf3TF}>first few wins</SGTAdBetweenProductBoxesWhiteText>
      </>
    );

    const betweenTextCEComponent = (
      <>
        <SGTAdBetweenProductBoxesWhiteText p_boldTF={false} p_smallFontTF={mqf3TF}>CaptureExec</SGTAdBetweenProductBoxesWhiteText>
        <SGTAdBetweenProductBoxesWhiteText p_boldTF={false} p_smallFontTF={mqf3TF}>for your</SGTAdBetweenProductBoxesWhiteText>
        <SGTAdBetweenProductBoxesWhiteText p_boldTF={true} p_smallFontTF={mqf3TF}>next 100</SGTAdBetweenProductBoxesWhiteText>
      </>
    );

    var section1ProductsComponent = null;
    if(c_isMobileOrTabletTF) {
      section1ProductsComponent = (
        <div className="displayFlexColumnHcVc">
            <div style={{width:"100%", maxWidth:"50em"}}>
              {betweenTextSGTComponent}
              <div style={{paddingTop:"1em"}} />
              {productBoxSGTComponent}
              <div style={{paddingTop:"7em"}} />
              {betweenTextCEComponent}
              <div style={{paddingTop:"1em"}} />
              {productBoxCEComponent}
            </div>
          </div>
      );
    }
    else {
      section1ProductsComponent = (
        <div className="displayFlexColumnHcVc">
          <div className="displayFlexRow" style={{width:"100%", maxWidth:"1500px"}}>
            {productBoxSGTComponent}
            <div className="flex00a" style={{flexBasis:"250px"}}>
              <div style={{marginTop:"2em"}} />
              {betweenTextSGTComponent}
              <div style={{marginTop:"1em"}} />
              <SGTAdBetweenProductBoxesWhiteArrow p_leftFalseRightTrue={false} />
              <div style={{marginTop:"4em"}} />
              {betweenTextCEComponent}
              <div style={{marginTop:"1em"}} />
              <SGTAdBetweenProductBoxesWhiteArrow p_leftFalseRightTrue={true} />
              <div style={{marginTop:"4em"}} />
              <SGTAdBetweenProductBoxesScrollToKnowMore />
            </div>
            {productBoxCEComponent}
          </div>
        </div>
      );
    }

    //section 1 - tagline
    var section1TaglineWidthPercent = 75;
    var section1TaglinePadding = "0.5em 0";
    var section1TaglineFontClass = "font30";
    if(c_isMobileTF) {
      section1TaglinePadding = "0 10%";
      section1TaglineWidthPercent = 95;
      section1TaglineFontClass = "font20";
    }
    else if(c_isMobileOrTabletTF) {
      section1TaglinePadding = "0.5em 5%";
      section1TaglineWidthPercent = 95;
      section1TaglineFontClass = "font25";
    }
    else if(mqf3TF) {
      section1TaglinePadding = "0.5em 0";
      section1TaglineWidthPercent = 95;
      section1TaglineFontClass = "font25";
    }

    const section1TaglineComponent = (
      <div className="displayFlexColumnHcVc">
        <div className="bgWhite displayFlexRow" style={{width:section1TaglineWidthPercent + "%", height:"240px"}}>
          {(!c_isMobileTF) &&
            <SGTAdTaglineEdgeDesign p_verticalFlipTF={false} />
          }
          <div className="flex11a displayFlexColumnHcVc textCenter" style={{padding:section1TaglinePadding}}>
            <font className={section1TaglineFontClass + " fontBold"}>
              {"SAM Gov Tracker simplifies & automates capture management for GovCon startups."}
            </font>
          </div>
          {(!c_isMobileOrTabletTF) &&
            <SGTAdTaglineEdgeDesign p_verticalFlipTF={true} />
          }
        </div>
      </div>
    );
    
    //section 1 - triple screenshot
    const section1ScreenshotLeftSrc = c_productWebsiteGraphicsSubfolderAddress + "/sgtad_screenshot_section1_1left_gantt_chart.png";
    const section1ScreenshotLeftAlt = "SAM Gov Tracker Screenshot - Gantt Chart";
    const section1ScreenshotCenterSrc = c_productWebsiteGraphicsSubfolderAddress + "/sgtad_screenshot_section1_2center_capture_table.png";
    const section1ScreenshotCenterAlt = "SAM Gov Tracker Screenshot - Capture Table";
    const section1ScreenshotRightSrc = c_productWebsiteGraphicsSubfolderAddress + "/sgtad_screenshot_section1_3right_gcss_search.png";
    const section1ScreenshotRightAlt = "SAM Gov Tracker Screenshot - GovCon Smart Search";
    var section1ScreenshotsComponent = null;
    if(c_isMobileOrTabletTF) {
      const section1ScreenShotWidth = ((c_isMobileTF) ? ("95%") : ("90%"));
      section1ScreenshotsComponent = (
        <div className="displayFlexColumnHc">
          <div style={{height:"10em"}} />
          <LibraryReact.Image p_src={section1ScreenshotLeftSrc} p_alt={section1ScreenshotLeftAlt} p_styleObj={{width:section1ScreenShotWidth, minWidth:"50em"}} />
          <div style={{height:"3em"}} />
          <LibraryReact.Image p_src={section1ScreenshotCenterSrc} p_alt={section1ScreenshotCenterAlt} p_styleObj={{width:section1ScreenShotWidth, minWidth:"50em"}} />
          <div style={{height:"3em"}} />
          <LibraryReact.Image p_src={section1ScreenshotRightSrc} p_alt={section1ScreenshotRightAlt} p_styleObj={{width:section1ScreenShotWidth, minWidth:"50em"}} />
        </div>
      );
    }
    else {
      section1ScreenshotsComponent = (
        <>
          <div className="positionAbsolute" style={{bottom:"calc(100px - 10vw)", right:"66%"}}>
            <LibraryReact.Image p_src={section1ScreenshotLeftSrc} p_alt={section1ScreenshotLeftAlt} p_styleObj={{width:"55vw"}} />
          </div>
          <div className="positionAbsolute" style={{bottom:"0", left:"30%"}}>
            <LibraryReact.Image p_src={section1ScreenshotCenterSrc} p_alt={section1ScreenshotCenterAlt} p_styleObj={{width:"55vw"}} />
          </div>
          <div className="positionAbsolute" style={{bottom:"-10vw", left:"60%"}}>
            <LibraryReact.Image p_src={section1ScreenshotRightSrc} p_alt={section1ScreenshotRightAlt} p_styleObj={{width:"55vw"}} />
          </div>
        </>
      );
    }

    //section 2 - bullets hat
    var section2BulletsComponent = (
      <>
        <div className="" style={{marginBottom:"25px"}}>
          <font className="font30">
            {"SAM Gov Tracker "}<font className="fontBold">{"makes SAM.gov work for you"}</font>
          </font>
        </div>
        <SGTAdProductBoxBulletList
          p_bulletsTextArray={["SAM.gov is difficult to use", "SAM.gov cannot manage your Captures", "Your current Pipeline list/spreadsheet needs to be manually updated", "Multiple users lose data updates on pipeline spreadsheet", "Your process is not a repeatable Sales workflow", "You miss deadlines for RFPs and other information"]}
          p_fontClass="font16"
        />
      </>
    );

    var section2BulletsHatComponent = null;
    if(c_isMobileOrTabletTF) {
      section2BulletsHatComponent = (
        <>
          <div className="displayFlexColumnHcVc">
            <SGTAdHatPicture />
          </div>
          <div style={{padding:"10%"}}>
            {section2BulletsComponent}
          </div>
        </>
      );
      
    }
    else {
      section2BulletsHatComponent = (
        <div className="displayFlexRow">
          <div className="flex11a" style={{flexBasis:"100em", paddingLeft:"4em"}}>
            {section2BulletsComponent}
          </div>
          <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
            <SGTAdHatPicture />
          </div>
        </div>
      );
    }

    //section 2 - scrolling screenshots
    const section2ScrollingScreenshotsTopTaglineComponent = (
      <div className="lrMedPad textCenter">
        <font className={((c_isMobileTF) ? ("font25") : ("font30"))}>
          <font className="fontBold">{"Capture management gets done "}</font>{"with SAM Gov Tracker"}
        </font>
      </div>
    );

    const section2ScrollingScreenshot1WinMoreBids = (
      <SGTAdLRScreenshotAndTagline
        p_horizontalTrueVerticalFalse={(!c_isMobileOrTabletTF)}
        p_screenshotLeftTrueRightFalse={true}
        p_screenshotSrc={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_screenshot_section2_1top_win_more_deals.png"}
        p_screenshotAlt="SAM Gov Tracker Screenshot - Win 10x More Bids"
        p_taglineTitle="Win 10x More Bids"
        p_taglineBulletsTextArray={["Auto-import opportunities with 1 click", "See opportunity intel at-a-glance", "Track every detail"]}
      />
    );

    const section2ScrollingScreenshot2NoMoreSpreadsheets = (
      <SGTAdLRScreenshotAndTagline
        p_horizontalTrueVerticalFalse={(!c_isMobileOrTabletTF)}
        p_screenshotLeftTrueRightFalse={false}
        p_screenshotSrc={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_screenshot_section2_2middle_no_more_spreadsheets.png"}
        p_screenshotAlt="SAM Gov Tracker Screenshot - No More Spreadsheets"
        p_taglineTitle="No More Spreadsheets"
        p_taglineBulletsTextArray={["Organize captures by various fields", "Click-and-drag to advance through stages"]}
      />
    );

    const section2ScrollingScreenshot3IncreaseEfficiency = (
      <SGTAdLRScreenshotAndTagline
        p_horizontalTrueVerticalFalse={(!c_isMobileOrTabletTF)}
        p_screenshotLeftTrueRightFalse={true}
        p_screenshotSrc={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_screenshot_section2_3bottom_increase_efficiency.png"}
        p_screenshotAlt="SAM Gov Tracker Screenshot - Increase Efficiency 100x"
        p_taglineTitle="Increase Efficiency 100x"
        p_taglineBulletsTextArray={["Log every event date & milestone", "Auto-task team members", "Share docs in central hub"]}
      />
    );

    //section 3 - start now call to action
    const section3StartNowLeftTextComponent = (
      <div className="textCenter">
        <font className={((c_isMobileOrTabletTF) ? ("font25") : ("font35")) + " fontWhite"}>
          <div>{"Start with"}</div>
          <div><font className="fontBold">{"SAM Gov Tracker Now!"}</font></div>
        </font>
      </div>
    );

    const section3StartNowRightActionButtonComponent = (
      <>
        <div className="textCenter">
          <font className="font15 fontWhite">
            {"Accelerate your time, efficiency, and wins using SAM Gov Tracker!"}
          </font>
        </div>
        <div style={{width:"30em", marginTop:"5px"}}>
          <SGTAdProductActionButton
            p_product="samgovtracker"
            p_buttonText={"Get Started \u2192"}
          />
        </div>
      </>
    );

    var section3StartNowCallToActionWithButtonComponent = null;
    if(c_isMobileOrTabletTF) {
      section3StartNowCallToActionWithButtonComponent = (
        <div className="displayFlexColumnHcVc" style={{margin:"0 5%"}}>
          {section3StartNowLeftTextComponent}
          <div style={{paddingTop:"2em"}} />
          {section3StartNowRightActionButtonComponent}
        </div>
      );
    }
    else {
      section3StartNowCallToActionWithButtonComponent = (
        <div className="displayFlexRow">
          <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
            {section3StartNowLeftTextComponent}
          </div>
          <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
            {section3StartNowRightActionButtonComponent}
          </div>
        </div>
      );
    }
    

    //section 3 - devices containing screenshots
    const maxWidthPx = 1700;
    const heightWidthRatio = 0.55;
    const section3DevicesComponent = (
      <div className="displayFlexColumnHcVc">
        <div className="positionRelative" style={{width:"100%", maxWidth:maxWidthPx + "px", height:(100 * heightWidthRatio) + "vw", maxHeight:(maxWidthPx * heightWidthRatio) + "px"}}>
          <div className="positionAbsolute" style={{bottom:"19%", left:"3%", height:"80%"}}>
            <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_devices_laptop.png"} p_alt="SAM Gov Tracker on Device - Laptop" p_styleObj={{height:"100%"}} />
          </div>
          <div className="positionAbsolute" style={{bottom:"-13%", left:"52%", height:"83%"}}>
            <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_devices_tablet.png"} p_alt="SAM Gov Tracker on Device - Tablet" p_styleObj={{height:"100%"}} />
          </div>
          <div className="positionAbsolute" style={{bottom:"0", left:"1%", height:"75%"}}>
            <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_devices_mobile.png"} p_alt="SAM Gov Tracker on Device - Mobile" p_styleObj={{height:"100%"}} />
          </div>
        </div>
      </div>
    );

    //section 4 - features tabs
    const featuresTabsTagLineComponent = (
      <div className="textCenter" style={{margin:"0 5%"}}>
        <font className="font24">
          {"Manage your captures "}<font className="fontBold">{"seamlessly & effortlessly"}</font>
        </font>
      </div>
    );

    var featuresTabsArrayOfObjs = [
      {
        tabName: "Search SAM",
        title: "Search SAM.gov from within the system",
        text: "Conduct searches directly within the system based on a series of parameters you choose. Gone are the days of manually navigating the government website.",
        gifFileNameAndExt: "sgtad_featurestab1_search_sam.gif"
      },
      {
        tabName: "Save Searches",
        title: "Save unlimited favorite searches",
        text: "Set favorite search parameters and save them for future use, cutting down opportunity identification time tenfold. Tailor your favorite searches to your core business areas, prime opportunities, subcontract opportunities, or whatever else makes sense for your company.",
        gifFileNameAndExt: "sgtad_featurestab2_save_searches.gif"
      },
      {
        tabName: "1-click Import",
        title: "One-click import of all SAM data",
        text: "Once your search criteria is set and opportunities that meet that criteria are identified, ingest them into the system and automatically add them to your Capture Table. One click is all it takes!",
        gifFileNameAndExt: "sgtad_featurestab3_1click_import.gif"
      },
      {
        tabName: "Track Deals",
        title: "Track each deal through stages from Prospect to Win!",
        text: "Track exactly where all of your captures are in the process with the Stages function, which allows you to sort by stages and gauge the overall health of your pipeline. No more manual, tedious sorting in Excel or developing confusing macros!",
        gifFileNameAndExt: "sgtad_featurestab4_track_deals.gif"
      },
      {
        tabName: "Manage Dates",
        title: "Manage important upcoming dates",
        text: "Don't lose track of important proposal dates or milestones. You know the feeling - a proposal date came and went and you forgot all about it. With SGT, you'll never feel that way again!",
        gifFileNameAndExt: "sgtad_featurestab5_manage_dates.gif"
      },
      {
        tabName: "Auto Reminders",
        title: "Collaborate across Sales team including Notepad information and Delegated Tasks with auto reminder",
        text: "Share notes across your team from one central location, and manage tasks using automatic reminders and notifications. Never miss a beat with SGT when you're collaborating on a capture!",
        gifFileNameAndExt: "sgtad_featurestab6_auto_reminders.gif"
      },
      {
        tabName: "Store Documents",
        title: "Stay organized by uploading Documents directly to each Opportunity",
        text: "Stay organized and current by uploading documents to each respective capture entry. SGT eliminates all that wasted time emailing documents around or scrambling for a link to the document folder.",
        gifFileNameAndExt: "sgtad_featurestab7_store_documents.gif"
      },
      {
        tabName: "Manage Admins",
        title: "Self managed number of users (Administrators or Capture Managers)",
        text: "Keep track of and assign roles from a central hub for all of your SGT users and contributors.",
        gifFileNameAndExt: "sgtad_featurestab8_manage_admins.gif"
      }
    ];

    const tabDbNamesArray = JSFUNC.array_fill_incrementing_0_to_nm1(featuresTabsArrayOfObjs.length);
    const tabDisplayNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(featuresTabsArrayOfObjs, "tabName");

    const featuresTabsComponent = (
      <div className="displayFlexColumnHcVc smallFullPad">
        <CEGeneralReact.TabsList
          p_tabDbNamesArray={tabDbNamesArray}
          p_tabDisplayNamesArray={tabDisplayNamesArray}
          p_selectedTabDbName={s_selectedFeaturesTabIndex}
          p_tabHeight="3.3em"
          p_textMaxHeight="3em"
          p_tabWidth={((c_isMobileOrTabletTF) ? ("7em") : ("12em"))}
          p_selectedBgClass="bgDarkRedSamGovTracker"
          p_selectedFontClass="font11 fontWhite"
          p_unselectedBgClass="bgLightGray"
          p_unselectedFontClass="font11"
          p_rowFlexWrapTF={true}
          p_borderRadiusClass=""
          f_onSelect={this.onclick_features_tab}
        />
      </div>
    );
    
    var selectedFeaturesTabObj = {};
    if(featuresTabsArrayOfObjs[s_selectedFeaturesTabIndex] !== undefined) {
      selectedFeaturesTabObj = featuresTabsArrayOfObjs[s_selectedFeaturesTabIndex];
    }

    const selectedFeaturesTabGifImageComponent = (
      <LibraryReact.Image
        p_src={c_productWebsiteGraphicsSubfolderAddress + "/" + selectedFeaturesTabObj.gifFileNameAndExt}
        p_alt={"SAM Gov Tracker Features - " + selectedFeaturesTabObj.tabName}
        p_styleObj={{width:"95%", maxWidth:"800px", imageRendering:"crisp-edges"}}
      />
    );

    var section4FeaturesTabsComponent = null;
    if(c_isMobileOrTabletTF) {
      section4FeaturesTabsComponent = (
        <>
          {featuresTabsTagLineComponent}
          <div style={{paddingTop:"50px"}} />
          {featuresTabsComponent}
          <div style={{paddingTop:"50px"}} />
          <div className="" style={{margin:"0 5%"}}>
            <div className="">
              <font className="font22">
                {selectedFeaturesTabObj.title}
              </font>
            </div>
            <div className="hugeTopPad">
              <font className="font16">
                {selectedFeaturesTabObj.text}
              </font>
            </div>
          </div>
          <div style={{paddingTop:"50px"}} />
          <div className="displayFlexColumnHcVc">
            {selectedFeaturesTabGifImageComponent}
          </div>
        </>
      );
    }
    else {
      section4FeaturesTabsComponent = (
        <>
          {featuresTabsTagLineComponent}
          <div style={{paddingTop:"50px"}} />
          {featuresTabsComponent}
          <div style={{paddingTop:"50px"}} />
          <div className="displayFlexRow">
            <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"125em"}}>
              {selectedFeaturesTabGifImageComponent}
            </div>
            <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
              <div className="flex00a" style={{width:"90%"}}>
                <div className="">
                  <font className="font22">
                    {selectedFeaturesTabObj.title}
                  </font>
                </div>
                <div className="hugeTopPad">
                  <font className="font16">
                    {selectedFeaturesTabObj.text}
                  </font>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    

    //section 5 - testimonials
    var testimonialsContainerMaxWidthEm = 120;
    var numTestimonialsDisplayed = 3;
    if(c_isMobileOrTabletTF || mqf3TF) {
      testimonialsContainerMaxWidthEm = 40;
      numTestimonialsDisplayed = 1;
    }

    const section5TestimonialsComponent = (
      <div className="displayFlexColumnHcVc">
        <div style={{width:"100%", maxWidth:testimonialsContainerMaxWidthEm + "em"}}>
          <SGTAdTestimonialsCarousel p_testimonialsArrayOfObjs={o_testimonialsArrayOfObjs} p_numTestimonialsDisplayed={numTestimonialsDisplayed} />
        </div>
      </div>
    );

    //section 6 - footer
    var footerAboutText = "BIT Solutions LLC is the ";
    footerAboutText += '"good guy"';
    footerAboutText += " in the industry, leveraging over 25 years of government contracting experience and know-how into its capture and proposal management software products. BIT is a father-son team that started with a mission to look out for the ";
    footerAboutText += '"“little guy"';
    footerAboutText += " and hasn't stopped since!";
    const footerLeftLogoAndAboutComponent = (
      <>
        <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/samgovtracker28.png"} p_alt="SAM Gov Tracker Logo" />
        <div style={{paddingTop:"2em"}}>
          <font className="font15 fontDarkGrayBlueSamGovTracker">
            {footerAboutText}
          </font>
        </div>
      </>
    );

    const footerQuickLinksComponent = (
      <>
        <SGTAdFooterLabel>{"Quick Links"}</SGTAdFooterLabel>
        <SGTAdFooterQuickLink p_text="Sign Up Now!" f_onClick={this.onclick_sgt_sign_up_now} />
        <SGTAdFooterQuickLink p_text="Login" f_onClick={this.props.f_onClickLogin} />
        <SGTAdFooterQuickLink p_text="Why SAM Gov Tracker" p_scrollToElementID="whysamgovtracker" />
        <SGTAdFooterQuickLink p_text="Benefits" p_scrollToElementID="benefits" />
        <SGTAdFooterQuickLink p_text="Features" p_scrollToElementID="features" />
        <SGTAdFooterQuickLink p_text="Testimonials" p_scrollToElementID="testimonials" />
      </>
    );

    const footerSocialMediaComponent = (
      <>
        <SGTAdFooterLabel>{"Social Media"}</SGTAdFooterLabel>
        <SGTAdFooterSocialMediaLink p_text="LinkedIn" p_href="https://www.linkedin.com/company/bitsolutionsllc/" />
        <SGTAdFooterSocialMediaLink p_text="Facebook" p_href="https://www.facebook.com/bitsolutionsllc" />
        <SGTAdFooterSocialMediaLink p_text="Twitter" p_href="https://twitter.com/bitsolutionsllc" />
      </>
    );

    const footerContactComponent = (
      <>
        <SGTAdFooterLabel>{"Contact"}</SGTAdFooterLabel>
        <div className="" style={{paddingBottom:"5px"}}>
          <font className="font14 fontDarkGrayBlueSamGovTracker">
            {"BIT Solutions, LLC"}
          </font>
        </div>
        <div className="" style={{paddingBottom:"5px"}}>
          <font className="font14 fontDarkGrayBlueSamGovTracker">
            <font className="fontBold">{"Email: "}</font><a mailto="info@bitsolutionsllc.com">{"info@bitsolutionsllc.com"}</a>
          </font>
        </div>
        <div className="" style={{paddingBottom:"5px"}}>
          <font className="font14 fontDarkGrayBlueSamGovTracker">
            <font className="fontBold">{"Phone: "}</font>{"(410) 925-0424"}
          </font>
        </div>
      </>
    );

    const footerCopyrightComponent = (
      <div className="displayFlexColumnHcVc textCenter">
        <font className="font11 fontBold fontDarkGrayBlueSamGovTracker">
          {"© " + c_nowYearInt + " BIT Solutions, LLC."}
        </font>
      </div>
    );
    
    var footerComponent = null;
    if(c_isMobileOrTabletTF) {
      footerComponent = (
        <div className="" style={{margin:"0 " + ((c_isMobileTF) ? ("5%") : ("15%"))}}>
          {footerLeftLogoAndAboutComponent}
          <div style={{paddingTop:"2em"}} />
          {footerQuickLinksComponent}
          <div style={{paddingTop:"2em"}} />
          {footerSocialMediaComponent}
          <div style={{paddingTop:"2em"}} />
          {footerContactComponent}
        </div>
      );
    }
    else {
      const mqfGt4TF = (o_mediaQueryFlag > 4);
      footerComponent = (
        <div className="displayFlexRow">
          <div className="flex11a" style={{flexBasis:((mqfGt4TF) ? ("150em") : ("250em")), padding:"0 4em"}}>
            {footerLeftLogoAndAboutComponent}
          </div>
          {(mqfGt4TF) && <div className="flex11a" style={{flexBasis:"100em"}} />}
          <div className="flex11a" style={{flexBasis:"100em"}}>
            {footerQuickLinksComponent}
          </div>
          <div className="flex11a" style={{flexBasis:"100em"}}>
            {footerSocialMediaComponent}
          </div>
          <div className="flex11a" style={{flexBasis:"100em"}}>
            {footerContactComponent}
          </div>
        </div>
      );
    }

    return(
      <>
        <div id="whysamgovtracker" className="positionRelative sgtAdSection1BgBlueGradient">
          {section1FootprintsTaglinesComponent}
          {section1ProductsComponent}
          <div style={{paddingTop:"15em"}} />
          {section1TaglineComponent}
          {(!c_isMobileOrTabletTF) && <div style={{height:"45vw"}} /> }
          {section1ScreenshotsComponent}
        </div>
        <div className="positionRelative sgtAdSection2BgSkyBlueGradient">
          <SGTAdSwail p_id={2} p_colorTop="ebf4ff" p_colorBottom="dbe4f0" />
          <div style={{paddingTop:"150px"}} />
          {section2BulletsHatComponent}
          <div style={{paddingTop:"300px"}} />
          {section2ScrollingScreenshotsTopTaglineComponent}
          <div id="benefits" style={{paddingTop:"50px"}} />
          {section2ScrollingScreenshot1WinMoreBids}
          <div style={{paddingTop:"75px"}} />
          {section2ScrollingScreenshot2NoMoreSpreadsheets}
          <div style={{paddingTop:"75px"}} />
          {section2ScrollingScreenshot3IncreaseEfficiency}
          <div style={{paddingTop:"75px"}} />
        </div>
        <div className="positionRelative sgtAdSection3BgBlue">
          <SGTAdSwail p_id={3} p_colorTop="2c67b8" p_colorBottom="1c57a8" />
          <div style={{paddingTop:"2vw"}} />
          {section3StartNowCallToActionWithButtonComponent}
          <div style={{paddingTop:"10vw"}} />
          {section3DevicesComponent}
          <div style={{paddingTop:"100px"}} />
        </div>
        <div className="positionRelative bgWhite">
          <SGTAdSwail p_id={4} p_colorTop="ffffff" p_colorBottom="ffffff" />
          <div id="features" style={{paddingTop:"100px"}} />
          {section4FeaturesTabsComponent}
          <div style={{paddingTop:"500px"}} />
        </div>
        <div className="positionRelative sgtAdSection3BgBlue">
          <SGTAdSwail p_id={5} p_colorTop="2c67b8" p_colorBottom="1c57a8" />
          <div id="testimonials" style={{paddingTop:"100px"}} />
          {section5TestimonialsComponent}
          <div style={{paddingTop:"100px"}} />
          {section3StartNowCallToActionWithButtonComponent}
          <div style={{paddingTop:"100px"}} />
        </div>
        <div className="positionRelative bgWhite">
          <div style={{paddingTop:"100px"}} />
          {footerComponent}
          <div style={{paddingTop:"100px"}} />
          {footerCopyrightComponent}
          <div style={{paddingTop:"10px"}} />
        </div>
      </>
    );
  }
}));

function SGTAdFooterSocialMediaLink(props) { //props: p_text, p_href
  const p_text = props.p_text;
  const p_href = props.p_href;

  return(
    <div className="" style={{paddingBottom:"4px"}}>
      <a href={p_href} target="_blank" className="font14 fontDarkGrayBlueSamGovTracker cursorPointer" style={{textDecoration:"none"}}>
        <font className="hoverFontUnderline">{p_text}</font>
      </a>
    </div>
  );
}

const SGTAdProductActionButton = inject("CaptureExecMobx")(observer(
class SGTAdProductActionButton extends Component { //props: p_product, p_buttonText
  onclick_product_action_button = () => {
    const p_product = this.props.p_product;

    if(p_product === "samgovtracker") {
      this.props.CaptureExecMobx.a_set_login_state("sgtBuySgtEnterInfo");
    }
    else if(p_product === "captureexec") {
      window.open("https://bitsolutionsllc.com/capture-exec/captureexec-capture-executive-software/", "_blank");
      //this.props.CaptureExecMobx.a_set_login_state("sgtCaptureExecLearnMoreAd"); //TODO actually implement this CE ad
    }
  }

  render() {
    const p_product = this.props.p_product;
    const p_buttonText = this.props.p_buttonText;

    var buttonBgClass = "";
    if(p_product === "samgovtracker") {
      buttonBgClass = "bgRedGradientSamGovTracker hoverLightRedGradientSamGovTracker";
    }
    else if(p_product === "captureexec") {
      buttonBgClass = "bgDarkBlueGradient hoverBlueGradient";
    }

    return(
      <div
        className={"displayFlexColumnHcVc borderRadius05 " + buttonBgClass + " textCenter cursorPointer"}
        style={{height:"5em"}}
        onClick={this.onclick_product_action_button}>
        <font className="font14 fontBold fontWhite">
          {p_buttonText}
        </font>
      </div>
    );
  }
}));

function SGTAdFootprintsTaglinesHorizonal(props) {
  return(
    <div className="displayFlexColumnHcVc">
      <div className="displayFlexRow" style={{width:"100%", maxWidth:"1200px", height:"100px"}}>
        <SGTAdFootprintsWhiteText><div>{"Small Business"}</div><div>{"Start-up Phase"}</div></SGTAdFootprintsWhiteText>
        <SGTAdFootprintsWhiteImg p_verticalFlipTF={false} />
        <SGTAdFootprintsWhiteText>{"Rapidly growing, first few wins booked"}</SGTAdFootprintsWhiteText>
        <SGTAdFootprintsWhiteImg p_verticalFlipTF={true} />
        <SGTAdFootprintsWhiteText><div>{"Explosive"}</div><div>{"Growth Phase"}</div></SGTAdFootprintsWhiteText>
      </div>
    </div>
  );
}

function SGTAdFootprintsWhiteText({children}) {
  return(
    <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
      <div className="flex00a displayFlexColumnHcVc textCenter" style={{maxWidth:"200px"}}>
        <font className="font16 fontItalic fontWhite">
          {children}
        </font>
      </div>
    </div>
  );
}

const SGTAdFootprintsWhiteImg = inject("CaptureExecMobx")(observer(
class SGTAdFootprintsWhiteImg extends Component { //props: p_verticalFlipTF
  render() {
    const p_verticalFlipTF= this.props.p_verticalFlipTF;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;

    return(
      <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
        <div className="flex00a" style={{width:"145px", height:"30px"}}>
          <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_footprintswhite145px30px.png"} p_alt="SGT path" p_styleObj={{transform:((p_verticalFlipTF) ? ("scaleY(-1)") : (undefined)), opacity:"0.6"}} />
        </div>
      </div>
    );
  }
}));

const SGTAdProductBox = inject("CaptureExecMobx")(observer(
class SGTAdProductBox extends Component { //props: p_product, p_productName, p_logoFileNameWithExt, p_topText, p_leftBulletsTextArray, p_rightBulletsTextArray, p_priceDollarsPerMonthOrUndefined, p_priceFontColorClass, p_buttonText, p_tightMarginsTF
  render() {
    const p_product = this.props.p_product;
    const p_productName = this.props.p_productName;
    const p_logoFileNameWithExt = this.props.p_logoFileNameWithExt;
    const p_topText = this.props.p_topText;
    const p_leftBulletsTextArray = this.props.p_leftBulletsTextArray;
    const p_rightBulletsTextArray = this.props.p_rightBulletsTextArray;
    const p_priceDollarsPerMonthOrUndefined = this.props.p_priceDollarsPerMonthOrUndefined;
    const p_priceFontColorClass = this.props.p_priceFontColorClass;
    const p_buttonText = this.props.p_buttonText;
    const p_tightMarginsTF = this.props.p_tightMarginsTF;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;

    var containerLrMarginEm = 4;
    var containerBorderRadiusEm = 4;
    var containerPaddingEm = 4;
    if(p_tightMarginsTF) {
      containerLrMarginEm = 1.5;
      containerBorderRadiusEm = 2;
      containerPaddingEm = 2;
    }

    return(
      <div
        className="flex11a displayFlexColumn border1bbb bgWhite"
        style={{flexBasis:"100em", margin:"0 " + containerLrMarginEm + "em", borderRadius:containerBorderRadiusEm + "em", boxShadow:"1.75em 1.75em 1.75em #222", padding:containerPaddingEm + "em"}}>
        <div className="flex11a">
          <div className="" style={{width:"250px", height:"42px"}}>
            <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/" + p_logoFileNameWithExt} p_alt={p_productName} />
          </div>
          <div style={{margin:"30px 0"}}>
            <font className="font13 fontBold fontDarkGrayBlueSamGovTracker">
              {p_topText}
            </font>
          </div>
          <div className="displayFlexRow">
            <SGTAdProductBoxBulletList p_bulletsTextArray={p_leftBulletsTextArray} p_fontClass="" />
            {(p_rightBulletsTextArray !== undefined) &&
              <>
                <div className="flex00a" style={{flexBasis:"20px"}} />
                <SGTAdProductBoxBulletList p_bulletsTextArray={p_rightBulletsTextArray} p_fontClass="" />
              </>
            }
          </div>
        </div>
        <div className="flex00a">
          {(p_priceDollarsPerMonthOrUndefined !== undefined) &&
            <div className="displayFlexColumnHcVc" style={{marginTop:"25px"}}>
              <div className="displayFlexRowVc">
                <div className="flex00a" style={{marginRight:"10px"}}>
                  <font className={"fontBold " + p_priceFontColorClass} style={{fontSize:"6em"}}>
                    {"$" + p_priceDollarsPerMonthOrUndefined}
                  </font>
                </div>
                <div className="flex00a">
                  <font className={"font17 fontBold " + p_priceFontColorClass}>
                    <div>{"user /"}</div>
                    <div>{"month"}</div>
                  </font>
                </div>
              </div>
            </div>
          }
          <div style={{paddingTop:"25px"}} />
          <SGTAdProductActionButton
            p_product={p_product}
            p_buttonText={p_buttonText}
          />
        </div>
      </div>
    );
  }
}));

function SGTAdProductBoxBulletList({p_bulletsTextArray, p_fontClass}) {
  return(
    <div className="flex11a" style={{flexBasis:"100em"}}>
      {p_bulletsTextArray.map((m_bulletText) =>
        <div key={m_bulletText} className={"displayFlexRow " + p_fontClass} style={{marginBottom:"0.9em"}}>
          <div className="flex00a" style={{flexBasis:"1.1em"}}>
            <font className="font11 fontDarkGrayBlueSamGovTracker">
              {"\u2022"}
            </font>
          </div>
          <div className="flex11a">
            <font className="font11">
              {m_bulletText}
            </font>
          </div>
        </div>
      )}
    </div>
  );
}

function SGTAdBetweenProductBoxesWhiteArrow(props) { //props: p_leftFalseRightTrue
  const p_leftFalseRightTrue = props.p_leftFalseRightTrue;

  var linesArrayOfArrays = [
    [50, 5, 50, 75],
    [50, 75, 5, 75],
    [5, 75, 10, 55],
    [5, 75, 10, 95]
  ];
  if(p_leftFalseRightTrue) {
    linesArrayOfArrays = [
      [50, 5, 50, 75],
      [50, 75, 95, 75],
      [95, 75, 90, 55],
      [95, 75, 90, 95]
    ];
  }

  return(
    <div className="displayFlexColumnHcVc">
      <svg height="30px" width="150px">
        {linesArrayOfArrays.map((m_lineArray, m_index) =>
          <line
            key={m_index}
            x1={m_lineArray[0] + "%"}
            y1={m_lineArray[1] + "%"}
            x2={m_lineArray[2] + "%"}
            y2={m_lineArray[3] + "%"}
            style={{stroke:"#fff", strokeWidth:"4px", strokeLinecap:"round"}}
          />
        )}
      </svg>
    </div>
  );
}

function SGTAdBetweenProductBoxesWhiteText(props) { //props: p_boldTF, p_smallFontTF, children
  const p_boldTF = props.p_boldTF;
  const p_smallFontTF = props.p_smallFontTF;

  return(
    <div className="displayFlexColumnHcVc textCenter">
      <font className={((p_smallFontTF) ? ("font18") : ("font25")) + " " + ((p_boldTF) ? ("fontBold") : ("")) + " fontWhite"}>
        {props.children}
      </font>
    </div>
  );
}

function SGTAdBetweenProductBoxesScrollToKnowMore(props) { //props: 
  return(
    <div className="displayFlexColumnHcVc">
      <div>
        <font className="font12 fontWhite">
          {"Scroll to know more"}
        </font>
      </div>
      <div className="displayFlexColumnHcVc textCenter" style={{marginTop:"2px", width:"20px", height:"20px", border:"solid 1px #ddd", borderRadius:"20px", background:"#eee"}}>
        <font className="fontBold fontRed">
          {"\u2193"}
        </font>
      </div>
    </div>
  );
}

const SGTAdTaglineEdgeDesign = inject("CaptureExecMobx")(observer(
class SGTAdTaglineEdgeDesign extends Component { //props: p_verticalFlipTF
  render() {
    const p_verticalFlipTF = this.props.p_verticalFlipTF;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;

    return(
      <div className="flex00a displayFlexColumn">
        <div className="flex11a displayFlexColumn">
          {(!p_verticalFlipTF) && <div className="flex11a" />}
          <div className="flex00a" style={{width:"234px", height:"203px"}}>
            <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_taglinediamondsbg234px203px.png"} p_alt="SGT Tagline Edge" p_styleObj={{transform:((p_verticalFlipTF) ? ("rotate(180deg)") : (undefined))}} />
          </div>
          {(p_verticalFlipTF) && <div className="flex11a" />}
        </div>
      </div>
    );
  }
}));

const SGTAdHatPicture = inject("CaptureExecMobx")(observer(
class SGTAdHatPicture extends Component { //props: 
  render() {
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;

    return(
      <div className="flex00a" style={{width:"585px", height:"402px"}}>
        <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/sgtad_samhat585px402px.png"} p_alt="SGT SAM" />
      </div>
    );
  }
}));

function SGTAdLRScreenshotAndTagline(props) { //props: p_horizontalTrueVerticalFalse, p_screenshotLeftTrueRightFalse, p_screenshotSrc, p_screenshotAlt, p_taglineTitle, p_taglineBulletsTextArray
  const p_horizontalTrueVerticalFalse = props.p_horizontalTrueVerticalFalse;
  const p_screenshotLeftTrueRightFalse = props.p_screenshotLeftTrueRightFalse;
  const p_screenshotSrc = props.p_screenshotSrc;
  const p_screenshotAlt = props.p_screenshotAlt;
  const p_taglineTitle = props.p_taglineTitle;
  const p_taglineBulletsTextArray = props.p_taglineBulletsTextArray;

  const screenshotComponent = (
    <LibraryReact.Image
      p_src={p_screenshotSrc}
      p_alt={p_screenshotAlt}
      p_styleObj={{width:"100%", maxWidth:"1500px"}}
    />
  );

  const textComponent = (
    <>
      <div className="flex00a textCenter">
        <font className="font25 fontBold">
          {p_taglineTitle}
        </font>
      </div>
      <div className="medTopMargin" />
      {p_taglineBulletsTextArray.map((m_taglineBulletText, m_index) =>
        <div
          key={m_index}
          className="flex00a textCenter">
          <font className="font18">
            {m_taglineBulletText}
          </font>
        </div>
      )}
    </>
  );

  if(p_horizontalTrueVerticalFalse) {
    const screenshotSideComponent = (
      <div className="flex11a" style={{flexBasis:"150em", padding:"4em"}}>
        <div style={{boxShadow:"0.5em 0.5em 1em #394853"}}>
          {screenshotComponent}
        </div>
      </div>
    );

    const textSideComponent = (
      <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
        {textComponent}
      </div>
    );

    return(
      <div className="displayFlexRow">
        {(p_screenshotLeftTrueRightFalse) ? (
          <>{screenshotSideComponent}{textSideComponent}</>
        ) : (
          <>{textSideComponent}{screenshotSideComponent}</>
        )}
      </div>
    );
  }

  return(
    <div style={{padding:"5%"}}>
      <div className="displayFlexColumnHcVc">
        {textComponent}
        <div className="hugeTopMargin" />
        {screenshotComponent}
      </div>
    </div>
  );
}

function SGTAdFooterLabel(props) { //props: children
  return(
    <div style={{paddingBottom:"5px"}}>
      <font className="font16 fontBold fontDarkGrayBlueSamGovTracker">
        {props.children}
      </font>
    </div>
  );
}

class SGTAdFooterQuickLink extends Component { //props: p_text, p_scrollToElementID, f_onClick
  onclick_quick_link_page_scroll = () => {
    const p_scrollToElementID = this.props.p_scrollToElementID;

    const depthOfParentsToCheck = 2;
    document.getElementById(p_scrollToElementID).scrollIntoViewIfNeeded(depthOfParentsToCheck);
  }

  render() {
    const p_text = this.props.p_text;
    const p_scrollToElementID = this.props.p_scrollToElementID;
    const f_onClick = this.props.f_onClick;

    return(
      <div className="displayFlexRow" style={{paddingBottom:"2px"}}>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <font className="font16 fontDarkGrayBlueSamGovTracker">
            {"\u2022"}
          </font>
        </div>
        <div
          className="flex11a displayFlexColumnVc hoverFontUnderline cursorPointer"
          onClick={((f_onClick !== undefined) ? (f_onClick) : (this.onclick_quick_link_page_scroll))}>
          <font className="font14 fontDarkGrayBlueSamGovTracker ">
            {p_text}
          </font>
        </div>
      </div>
    );
  }
}




//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
const SGTBuyBackToHomeLink = inject("CaptureExecMobx")(observer(
class SGTBuyBackToHomeLink extends Component {
  onclick_go_back = () => {
    this.props.CaptureExecMobx.a_set_login_state("out");
  }

  render() {
    return(
      <div
        className="displayFlexRowVc cursorPointer"
        title="Return to the samgovtracker.com homepage"
        onClick={this.onclick_go_back}>
        <div className="flex00a" style={{marginRight:"0.7em"}}>
          <font className="font25 fontBold fontAlmostWhite">{"\u2190"}</font>
        </div>
        <div className="flex00a hoverFontUnderline">
          <font className="font16 fontAlmostWhite">
            {"Back to Home"}
          </font>
        </div>
      </div>
    );
  }
}));



const BuySGTCustomerInfoFormContents = inject("CaptureExecMobx", "SamGovTrackerMobx")(observer(
class BuySGTCustomerInfoFormContents extends Component {
  onchange_customer_company_name = (i_newValueString) => {
    this.props.SamGovTrackerMobx.a_set_customer_company_name(i_newValueString);
  }

  onchange_sgt_company_code = (i_newValueString) => {
    const updatedCompanyCodeNoSpecialChars = i_newValueString.replace(/[^a-zA-Z0-9]/g, ""); //remove all characters except A-Z and 0-9
    this.props.SamGovTrackerMobx.a_set_customer_company_code(updatedCompanyCodeNoSpecialChars);
  }

  onchange_sgt_firstname = (i_newValueString) => {
    this.props.SamGovTrackerMobx.a_set_customer_first_name(i_newValueString);
  }

  onchange_sgt_lastname = (i_newValueString) => {
    this.props.SamGovTrackerMobx.a_set_customer_last_name(i_newValueString);
  }

  onchange_sgt_email = (i_newValueString) => {
    this.props.SamGovTrackerMobx.a_set_customer_email(i_newValueString);
  }

  onchange_sgt_num_users = (i_newValueInt) => {
    this.props.SamGovTrackerMobx.a_set_purchase_sgt_num_users(i_newValueInt);
  }

  onclick_product_sgt_no_docs = () => {
    this.props.SamGovTrackerMobx.a_set_purchase_sgt_product("sgtNoDocs");
  }

  onclick_product_sgt_with_docs = () => {
    this.props.SamGovTrackerMobx.a_set_purchase_sgt_product("sgtWithDocs");
  }

  onclick_continue_button_with_errors = () => {
    this.props.SamGovTrackerMobx.a_set_next_button_pushed_at_least_once_tf(true);
  }

  onclick_continue_button_no_errors = () => {
    this.props.SamGovTrackerMobx.a_set_buy_process_state("cc");
    this.props.SamGovTrackerMobx.a_set_next_button_pushed_at_least_once_tf(true);
  }

  onclick_reedit_button = () => {
    this.props.SamGovTrackerMobx.a_set_buy_process_state("info");
  }

  render() {
    const k_maxNumUsersAvailableToSelect = this.props.SamGovTrackerMobx.k_maxNumUsersAvailableToSelect;
    const o_customerCompanyName = this.props.SamGovTrackerMobx.o_customerCompanyName;
    const o_customerCompanyCode = this.props.SamGovTrackerMobx.o_customerCompanyCode;
    const o_customerFirstName = this.props.SamGovTrackerMobx.o_customerFirstName;
    const o_customerLastName = this.props.SamGovTrackerMobx.o_customerLastName;
    const o_customerEmail = this.props.SamGovTrackerMobx.o_customerEmail;
    const o_purchaseSGTNumUsers = this.props.SamGovTrackerMobx.o_purchaseSGTNumUsers;
    const o_purchaseSGTProduct = this.props.SamGovTrackerMobx.o_purchaseSGTProduct;
    const c_buyProcessStateIsInfoTF = this.props.SamGovTrackerMobx.c_buyProcessStateIsInfoTF;
    const c_buyProcessStateIsCCTF = this.props.SamGovTrackerMobx.c_buyProcessStateIsCCTF;
    const c_customerCompanyNameErrorMessageObj = this.props.SamGovTrackerMobx.c_customerCompanyNameErrorMessageObj;
    const c_customerCompanyCodeErrorMessageObj = this.props.SamGovTrackerMobx.c_customerCompanyCodeErrorMessageObj;
    const c_customerFirstNameErrorMessageObj = this.props.SamGovTrackerMobx.c_customerFirstNameErrorMessageObj;
    const c_customerLastNameErrorMessageObj = this.props.SamGovTrackerMobx.c_customerLastNameErrorMessageObj;
    const c_customerEmailErrorMessageObj = this.props.SamGovTrackerMobx.c_customerEmailErrorMessageObj;
    const c_selectSgtProductNumUsersFieldTypeObj = this.props.SamGovTrackerMobx.c_selectSgtProductNumUsersFieldTypeObj;
    const c_selectedNumUsersValueMask = this.props.SamGovTrackerMobx.c_selectedNumUsersValueMask;
    const c_selectedProductSgtNoDocsTF = this.props.SamGovTrackerMobx.c_selectedProductSgtNoDocsTF;
    const c_selectedProductSgtWithDocsTF = this.props.SamGovTrackerMobx.c_selectedProductSgtWithDocsTF;
    const c_selectedSgtProductPriceAmount = this.props.SamGovTrackerMobx.c_selectedSgtProductPriceAmount;
    const c_selectedSgtProductPriceCurrency = this.props.SamGovTrackerMobx.c_selectedSgtProductPriceCurrency;
    const c_selectedSgtProductTotalPayAmount = this.props.SamGovTrackerMobx.c_selectedSgtProductTotalPayAmount;
    const c_selectedSgtProductTotalPayFormattedPrice = this.props.SamGovTrackerMobx.c_selectedSgtProductTotalPayFormattedPrice;
    const c_priceIncreasePerUserForWithDocsFormattedPrice = this.props.SamGovTrackerMobx.c_priceIncreasePerUserForWithDocsFormattedPrice;
    const c_companyInfoFormCanContinueNoErrorsTF = this.props.SamGovTrackerMobx.c_companyInfoFormCanContinueNoErrorsTF;
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    const fieldSpacingEm = 2;

    var companyNameHoverText = undefined;

    var companyCodeHoverText = "For example, the company 'BIT Solutions' might pick 'BIT' or 'bitsolu' for their company code.";
    companyCodeHoverText += "\nYour team will use this code along with their email address and password to log in.";
    companyCodeHoverText += "\nThis is not case sensitive, however no spaces or special characters are allowed.";

    var yourNameHoverText = "This will be the name of the first Admin in the system that can then add all other Users.";

    var yourEmailHoverText = undefined;

    var numUsersHoverText = "This can always be increased later and decreased at any time.";
    numUsersHoverText += "\nYou will be the first User as an Admin, and can create/assign new Users.";
    numUsersHoverText += "\nFor companies with more than " + k_maxNumUsersAvailableToSelect + " system Users, we recommend our executive CaptureExec product.";

    var productSGTNoDocsHoverText = "In our standard SAM Gov Tracker system, imported SAM.gov Captures include a field for opportunity related documents provided by the government that are hosted on and can be downloaded from SAM.";
    productSGTNoDocsHoverText += "\nIf additionally you would like to upload, share, and manage your local capture/strategy/proposal documents within SAM Gov Tracker, you can select our upgraded 'with unlimited File Storage' option.";

    var productSGTWithDocsHoverText = "Optional " + c_priceIncreasePerUserForWithDocsFormattedPrice + "/user upgrade adds the 'Documents' card to your created and imported Captures.";
    productSGTWithDocsHoverText += "\nEasily manage your documents in one place using SGT's secure cloud storage for file uploads and organization.";

    return(
      <>
        <div className="displayFlexRow" style={{height:"45px"}}>
          <div className="flex00a">
            <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/samgovtracker13.png"} p_alt={c_productStylingObj.productName} />
          </div>
          <div className="flex11a" />
        </div>
        <div style={{marginTop:fieldSpacingEm + "em"}} />
        <BuySGTCardTitle>{"Tell Us About Yourself"}</BuySGTCardTitle>
        <SGTCompanyInfoLabel
          p_label="Company Name"
          p_infoHoverText={companyNameHoverText}
        />
        <SGTCompanyInfoSingleInputText p_value={o_customerCompanyName} p_placeholder="Your Company, LLC" p_tabIndex={1} p_errorMessageObj={c_customerCompanyNameErrorMessageObj} p_canEditTF={c_buyProcessStateIsInfoTF} f_onChange={this.onchange_customer_company_name} />
        <div style={{marginTop:fieldSpacingEm + "em"}} />
        <SGTCompanyInfoLabel
          p_label="Choose a unique 'Company Code' for your team"
          p_infoHoverText={companyCodeHoverText}
        />
        <SGTCompanyInfoSingleInputText p_value={o_customerCompanyCode} p_placeholder="YOURCO" p_tabIndex={2} p_errorMessageObj={c_customerCompanyCodeErrorMessageObj} p_canEditTF={c_buyProcessStateIsInfoTF} f_onChange={this.onchange_sgt_company_code} />
        <div style={{marginTop:fieldSpacingEm + "em"}} />
        <SGTCompanyInfoLabel
          p_label="Your Name"
          p_infoHoverText={yourNameHoverText}
        />
        <div className="displayFlexRow">
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <SGTCompanyInfoSingleInputText p_value={o_customerFirstName} p_placeholder="First Name" p_tabIndex={3} p_errorMessageObj={c_customerFirstNameErrorMessageObj} p_canEditTF={c_buyProcessStateIsInfoTF} f_onChange={this.onchange_sgt_firstname} />
          </div>
          <div className="flex00a" style={{flexBasis:"1em"}} />
          <div className="flex11a" style={{flexBasis:"125em"}}>
            <SGTCompanyInfoSingleInputText p_value={o_customerLastName} p_placeholder="Last Name" p_tabIndex={4} p_errorMessageObj={c_customerLastNameErrorMessageObj} p_canEditTF={c_buyProcessStateIsInfoTF} f_onChange={this.onchange_sgt_lastname} />
          </div>
        </div>
        <div style={{marginTop:fieldSpacingEm + "em"}} />
        <SGTCompanyInfoLabel
          p_label="Your Email Address"
          p_infoHoverText={yourEmailHoverText}
        />
        <SGTCompanyInfoSingleInputText p_value={o_customerEmail}  p_placeholder="email@yourco.com" p_tabIndex={5} p_errorMessageObj={c_customerEmailErrorMessageObj} p_canEditTF={c_buyProcessStateIsInfoTF} f_onChange={this.onchange_sgt_email} />
        <div style={{marginTop:fieldSpacingEm + "em"}} />
        <div className="displayFlexRowVc">
          <div className="flex00a" style={{flexBasis:"8em"}}>
            <SGTCompanyInfoLabel
              p_label="# Users"
              p_infoHoverText={numUsersHoverText}
            />
          </div>
          <div className="flex00a" style={{flexBasis:"10em"}}>
            {(c_buyProcessStateIsInfoTF) ? (
              <font className="font12">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={c_selectSgtProductNumUsersFieldTypeObj}
                  p_valueRaw={o_purchaseSGTNumUsers}
                  p_selectDisplayMaskOverwrite={c_selectedNumUsersValueMask}
                  p_tabIndex={6}
                  f_onChangeOrOnSelect={this.onchange_sgt_num_users}
                />
              </font>
            ) : (
              <div className="tbMargin">
                <font className="font12">
                  {c_selectedNumUsersValueMask}
                </font>
              </div>
            )}
          </div>
          <div className="flex11a" />
        </div>
        {(c_selectedSgtProductPriceAmount >= 0) &&
          <>
            <div className="bigTopMargin" />
            <SGTProductButton
              p_isSelectedTF={c_selectedProductSgtNoDocsTF}
              p_productText={o_purchaseSGTNumUsers + " standard SAM Gov Tracker " + JSFUNC.plural(o_purchaseSGTNumUsers, "License", "Licenses")}
              p_infoHoverText={productSGTNoDocsHoverText}
              p_canEditTF={c_buyProcessStateIsInfoTF}
              f_onClick={this.onclick_product_sgt_no_docs}
            />
            <SGTProductButton
              p_isSelectedTF={c_selectedProductSgtWithDocsTF}
              p_productText={(<>{o_purchaseSGTNumUsers + " SAM Gov Tracker " + JSFUNC.plural(o_purchaseSGTNumUsers, "License", "Licenses")}<font className="fontBold">{" with unlimited File Storage"}</font></>)}
              p_infoHoverText={productSGTWithDocsHoverText}
              p_canEditTF={c_buyProcessStateIsInfoTF}
              f_onClick={this.onclick_product_sgt_with_docs}
            />
            <div className="bigTopMargin">
              <font className="font35 fontBold fontDarkGrayBlueSamGovTracker">
                {c_selectedSgtProductTotalPayFormattedPrice + " / mo"}
              </font>
            </div>
          </>
        }
        <div className="bigTopMargin" />
        {(c_buyProcessStateIsCCTF) ? (
          <SGTBuyActionButton
            p_buttonType="hollow"
            p_buttonText="Re-edit Info"
            f_onClick={this.onclick_reedit_button}
          />
        ) : (
          <>
            <SGTBuyActionButton
              p_buttonType="solid"
              p_buttonText="Next"
              f_onClick={((c_companyInfoFormCanContinueNoErrorsTF) ? (this.onclick_continue_button_no_errors) : (this.onclick_continue_button_with_errors))}
            />
            <div className="bigTopMargin">
              <font className="font11 fontDarkGrayBlueSamGovTracker">
                {"Cancel anytime. No binding contracts."}
              </font>
            </div>
          </>
        )}
      </>
    );
  }
}));




const BuySGTCardTitle = inject("CaptureExecMobx")(observer(
class BuySGTCardTitle extends Component { //props: children
  render() {
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;

    return(
      <div className="bigBottomMargin">
        <font className={((c_isMobileTF) ? ("font22") : ("font25")) + " fontBold fontDarkGrayBlueSamGovTracker"}>
          {this.props.children}
        </font>
      </div>
    );
  }
}));


const SGTCompanyInfoLabel = inject("CaptureExecMobx")(observer(
class SGTCompanyInfoLabel extends Component { //props: p_label, p_infoHoverText
  render() {
    const p_label = this.props.p_label;
    const p_infoHoverText = this.props.p_infoHoverText;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;

    return(
      <div className="displayFlexRow" style={{marginBottom:"0.25em"}}>
        <div className="flex00a">
          <font className={((c_isMobileTF) ? ("font13") : ("font16")) + " fontTextLighter"}>
            {p_label}
          </font>
        </div>
        {(p_infoHoverText !== undefined) &&
          <SGTCompanyInfoHelpIconWithHoverText p_infoHoverText={p_infoHoverText} />
        }
      </div>
    );
  }
}));


function SGTCompanyInfoHelpIconWithHoverText(props) { //props: p_infoHoverText
  const p_infoHoverText = props.p_infoHoverText;

  return(
    <div
      className="flex00a displayFlexColumn cursorHelp"
      style={{padding:"0 0.25em"}}
      title={p_infoHoverText}>
      <div className="flex00a displayFlexColumnHcVc border1bbb textCenter" style={{width:"1.1em", height:"1.1em", borderRadius:"0.3em", background:"#fcfcfc"}}>
        <font className="fontBold fontBlue">
          {"i"}
        </font>
      </div>
      <div className="flex11a" />
    </div>
  );
}

function SGTCompanyInfoSingleInputText(props) { //p_value, p_placeholder, p_tabIndex, p_errorMessageObj, p_canEditTF, f_onChange
  const p_value = props.p_value;
  const p_placeholder = props.p_placeholder;
  const p_tabIndex = props.p_tabIndex;
  const p_errorMessageObj = props.p_errorMessageObj;
  const p_canEditTF = props.p_canEditTF;

  if(p_errorMessageObj === undefined) {
    return(<div className="medFullPad bgRed" />)
  }

  const errorTF = (p_errorMessageObj.showErrorMessageTF && (p_errorMessageObj.errorMessageOrUndefined !== undefined));

  if(!p_canEditTF) {
    return(
      <div className="border1bbb borderRadius05 bgLightestGray" style={{width:"100%", padding:"0.4em 0.75em"}}>
        <font className="font15 fontTextLight">
          {p_value}
        </font>
      </div>
    );
  }

  return(
    <>
      <LibraryReact.Text
        p_value={p_value}
        p_isPasswordTF={false}
        p_class="border1bbb  font15"
        p_styleObj={{width:"100%", padding:"0.4em 0.75em"}}
        p_tabIndex={p_tabIndex}
        p_placeholder={p_placeholder}
        p_errorTF={errorTF}
        f_onChange={props.f_onChange}
      />
      {(errorTF) &&
        <div className="smallTopMargin">
          <font className="font11 fontItalic fontRed">
            {p_errorMessageObj.errorMessageOrUndefined}
          </font>
        </div>
      }
    </>
  );
}

function SGTProductButton(props) { //props: p_isSelectedTF, p_productText, p_infoHoverText, p_canEditTF, f_onClick
  const p_isSelectedTF = props.p_isSelectedTF;
  const p_productText = props.p_productText;
  const p_infoHoverText = props.p_infoHoverText;
  const p_canEditTF = props.p_canEditTF;

  const canSelectTF = (p_canEditTF && !p_isSelectedTF);

  return(
    <div className="displayFlexRowVc tbPad">
      <div
        className={"flex00a displayFlexColumnHcVc " + ((canSelectTF) ? ("cursorPointer") : (""))}
        style={{paddingRight:"0.9em"}}
        onClick={((canSelectTF) ? (props.f_onClick) : (undefined))}>
        <div className="flex00a displayFlexColumnHcVc" style={{width:"1.5em", height:"1.5em", border:"solid 2px #394853", borderRadius:"1em"}}>
          {(p_isSelectedTF) &&
            <div className={"flex00a " + ((p_canEditTF) ? ("bgDarkRedSamGovTracker") : ("bgDarkGray"))} style={{width:"1em", height:"1em", borderRadius:"1em"}} />
          }
        </div>
      </div>
      <div className="flex11a displayFlexRow">
        <div className="flex00a">
          <font className="font13 fontDarkGrayBlueSamGovTracker">
            {p_productText}
          </font>
        </div>
        {(p_infoHoverText !== undefined) &&
          <SGTCompanyInfoHelpIconWithHoverText p_infoHoverText={p_infoHoverText} />
        }
      </div>
    </div>
  );
}



//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
function SGTBuyActionButton(props) { //props: p_buttonType, p_buttonText, f_onClick
  const p_buttonType = props.p_buttonType; //"solid", "hollow"
  const p_buttonText = props.p_buttonText;

  var buttonClass = "border bevelBorderColors bgRedGradientSamGovTracker hoverLightRedGradientSamGovTracker";
  var fontClass = "fontBold fontWhite";
  if(p_buttonType === "hollow") {
    fontClass = "fontDarkRedSamGovTracker";
    buttonClass = "border1DarkRedSamGovTracker bgAlmostWhite";
  }

  return(
    <div
      className={"flex00a displayFlexColumnHcVc borderRadius05 " + buttonClass + " textCenter cursorPointer"}
      style={{width:"20em", height:"4em"}}
      onClick={props.f_onClick}>
      <font className={"font15 " + fontClass}>
        {p_buttonText}
      </font>
    </div>
  );
}



const SGTAdTestimonialsCarousel = inject("CaptureExecMobx")(observer(
class SGTAdTestimonialsCarousel extends Component { //props: p_testimonialsArrayOfObjs, p_numTestimonialsDisplayed
  constructor(props) {
    super(props);
    this.state = {
      s_testimonialCarouselIndex: 0,
      s_carouselAutomatedTrueManualFalse: true
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.auto_increment_testimonial_index(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  increment_testimonial_index = () => {
    const s_testimonialCarouselIndex = this.state.s_testimonialCarouselIndex;
    const p_testimonialsArrayOfObjs = this.props.p_testimonialsArrayOfObjs;
    if(JSFUNC.is_array(p_testimonialsArrayOfObjs)) {
      const numTestimonials = p_testimonialsArrayOfObjs.length;
      if(numTestimonials > 0) {
        var updatedIndex = (s_testimonialCarouselIndex + 1);
        if(updatedIndex >= numTestimonials) {
          updatedIndex = 0;
        }
        this.setState({s_testimonialCarouselIndex:updatedIndex});
      }
    }
  }

  decrement_testimonial_index = () => {
    const s_testimonialCarouselIndex = this.state.s_testimonialCarouselIndex;
    const p_testimonialsArrayOfObjs = this.props.p_testimonialsArrayOfObjs;
    if(JSFUNC.is_array(p_testimonialsArrayOfObjs)) {
      const numTestimonials = p_testimonialsArrayOfObjs.length;
      if(numTestimonials > 0) {
        var updatedIndex = (s_testimonialCarouselIndex - 1);
        if(updatedIndex < 0) {
          updatedIndex = (numTestimonials - 1);
        }
        this.setState({s_testimonialCarouselIndex:updatedIndex});
      }
    }
  }

  auto_increment_testimonial_index = () => {
    const s_carouselAutomatedTrueManualFalse = this.state.s_carouselAutomatedTrueManualFalse;
    if(s_carouselAutomatedTrueManualFalse) {
      this.increment_testimonial_index();
    }
  }

  onclick_manual_increment_testimonial = () => {
    this.setState({s_carouselAutomatedTrueManualFalse:false});
    this.increment_testimonial_index();
  }

  onclick_manual_decrement_testimonial = () => {
    this.setState({s_carouselAutomatedTrueManualFalse:false});
    this.decrement_testimonial_index();
  }

  onclick_manual_testimonial_from_index = (i_selectedIndex) => {
    this.setState({
      s_testimonialCarouselIndex: i_selectedIndex,
      s_carouselAutomatedTrueManualFalse: false
    });
  }

  render() {
    const s_testimonialCarouselIndex = this.state.s_testimonialCarouselIndex;
    const s_carouselAutomatedTrueManualFalse = this.state.s_carouselAutomatedTrueManualFalse;

    const p_testimonialsArrayOfObjs = this.props.p_testimonialsArrayOfObjs;
    const p_numTestimonialsDisplayed = this.props.p_numTestimonialsDisplayed;

    if(!JSFUNC.is_array(p_testimonialsArrayOfObjs)) {
      return(null);
    }

    const numTestimonials = p_testimonialsArrayOfObjs.length;
    if(numTestimonials === 0) {
      return(null);
    }

    if(s_testimonialCarouselIndex >= numTestimonials) {
      return(null);
    }

    var testimonialsDisplayedComponent = null;
    if(p_numTestimonialsDisplayed === 1) {
      const testimonialObj = p_testimonialsArrayOfObjs[s_testimonialCarouselIndex];
      testimonialsDisplayedComponent = (<SGTAdSingleTestimonial key={testimonialObj.id} p_testimonialObj={testimonialObj} />);
    }
    else if(p_numTestimonialsDisplayed === 3) {
      var leftIndex = (s_testimonialCarouselIndex - 1);
      if(leftIndex < 0) {
        leftIndex = (numTestimonials - 1);
      }

      var rightIndex = (s_testimonialCarouselIndex + 1);
      if(rightIndex >= numTestimonials) {
        rightIndex = 0;
      }

      const leftTestimonialObj = p_testimonialsArrayOfObjs[leftIndex];
      const centerTestimonialObj = p_testimonialsArrayOfObjs[s_testimonialCarouselIndex];
      const rightTestimonialObj = p_testimonialsArrayOfObjs[rightIndex];

      testimonialsDisplayedComponent = (
        <div className="displayFlexRow">
          <div className="flex00a" style={{flexBasis:"2em"}} />
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <SGTAdSingleTestimonial key={leftTestimonialObj.id} p_testimonialObj={leftTestimonialObj} />
          </div>
          <div className="flex00a" style={{flexBasis:"2em"}} />
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <SGTAdSingleTestimonial key={centerTestimonialObj.id} p_testimonialObj={centerTestimonialObj} />
          </div>
          <div className="flex00a" style={{flexBasis:"2em"}} />
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <SGTAdSingleTestimonial key={rightTestimonialObj.id} p_testimonialObj={rightTestimonialObj} />
          </div>
          <div className="flex00a" style={{flexBasis:"2em"}} />
        </div>
      );
    }

    return(
      <>
        {testimonialsDisplayedComponent}
        <div className="displayFlexColumnHcVc smallTopMargin">
          <div className="displayFlexRow">
            <div
              className="flex00a displayFlexColumnHcVc textCenter cursorPointer"
              style={{width:"3em"}}
              title="View Previous Testimonial"
              onClick={this.onclick_manual_decrement_testimonial}>
              <font className="font20 fontAlmostWhite">
                {"\u25c0"}
              </font>
            </div>
            {p_testimonialsArrayOfObjs.map((m_testimonialObj, m_index) =>
              <div
                className="flex00a displayFlexColumnHcVc textCenter cursorPointer"
                style={{width:"1.6em"}}
                title={"View Testimonial #" + (m_index + 1) + " from " + m_testimonialObj.company_name}
                onClick={() => {this.onclick_manual_testimonial_from_index(m_index); }}>
                <div
                  className={"border1ddd borderRadius10 " + ((s_testimonialCarouselIndex === m_index) ? ("bgDarkRedSamGovTracker") : ("bgAlmostWhite"))}
                  style={{width:"0.6em", height:"0.6em"}}
                />
              </div>
            )}
            <div
              className="flex00a displayFlexColumnHcVc textCenter cursorPointer"
              style={{width:"3em"}}
              title="View Next Testimonial"
              onClick={this.onclick_manual_increment_testimonial}>
              <font className="font18 fontAlmostWhite">
                {"\u25b6"}
              </font>
            </div>
          </div>
        </div>
      </>
    );
  }
}));


const SGTAdSingleTestimonial = inject("CaptureExecMobx")(observer(
class SGTAdSingleTestimonial extends Component {//props: p_testimonialObj
  render() {
    const p_testimonialObj = this.props.p_testimonialObj;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;

    const carouselQuoteHeightEm = 15;

    var productLogoFileNameAndExt = "captureexec8.png";
    if(p_testimonialObj.calcProduct === "samgovtracker") {
      productLogoFileNameAndExt = "samgovtracker8.png";
    }

    return(
      <div className="animationSlideLeft border1bbb borderRadius10 bgWhite hugeFullPad">
        <div className="" style={{height:"52px"}}>
          <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/testimonial_logo50px_" + p_testimonialObj.company_code + ".png"} p_alt={p_testimonialObj.company_name} />
        </div>
        <div className="tbBigPad">
          <div className="" style={{height:carouselQuoteHeightEm + "em"}}>
            <LibraryReact.MaxHeightWrap p_maxHeight={carouselQuoteHeightEm + "em"} p_fontClass="font12 fontTextLight">
              {'"' + p_testimonialObj.quote_text + '"'}
            </LibraryReact.MaxHeightWrap>
          </div>
        </div>
        <div className="" style={{height:"2em"}}>
          <font className="font18 fontBold fontDarkGrayBlueSamGovTracker">
            {p_testimonialObj.user_full_name}
          </font>
        </div>
        <div className="bigTopMargin" />
        <div className="" style={{height:"1.6em"}}>
          <LibraryReact.Nowrap p_fontClass="font14 fontBold fontDarkGrayBlueSamGovTracker">
            {p_testimonialObj.company_name}
          </LibraryReact.Nowrap>
        </div>
        <div className="" style={{height:"1.6em"}}>
          <LibraryReact.Nowrap p_fontClass="font14 fontBold fontDarkGrayBlueSamGovTracker">
            {p_testimonialObj.user_title}
          </LibraryReact.Nowrap>
        </div>
        <div className="bigTopMargin" />
        <div className="" style={{height:"1.8em"}}>
          <font className="font14 fontTextLight">
            {"Using"}
          </font>
        </div>
        <div className="" style={{height:"2.2em"}}>
          <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/" + productLogoFileNameAndExt} p_alt={p_testimonialObj.calcProduct} />
        </div>
        <div className="" style={{height:"1.8em"}}>
          <font className="font14 fontTextLight">
            {"for " + p_testimonialObj.calcUseNumYears + " " + JSFUNC.plural(p_testimonialObj.calcUseNumYears, "year", "years")}
          </font>
        </div>
      </div>
    );
  }
}));




function SGTAdSwail(props) { //props: p_id, p_colorTop, p_colorBottom
  const p_id = props.p_id;
  const p_colorTop = props.p_colorTop;
  const p_colorBottom = props.p_colorBottom;

  const swailHeightPx = 200;

  return(
    <div className="positionAbsolute" style={{top:"-" + (swailHeightPx - 1) + "px", left:0, right:0}}>
      <SGTAdSwailSvg
        p_id={p_id}
        p_swailHeightPx={swailHeightPx}
        p_colorTop={p_colorTop}
        p_colorBottom={p_colorBottom}
      />
    </div>
  );
}


function SGTAdSwailSvg(props) { //props: p_id, p_swailHeightPx, p_colorTop, p_colorBottom
  const p_id = props.p_id;
  const p_swailHeightPx = props.p_swailHeightPx;
  const p_colorTop = props.p_colorTop;
  const p_colorBottom = props.p_colorBottom;

  const gradientIDString = "gradient" + p_id;

  var xArray = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96, 98, 100];
  var cosxArray = [0, 0.1, 0.4, 0.9, 1.6, 2.4, 3.5, 4.8, 6.2, 7.8, 9.5, 11.5, 13.6, 15.8, 18.1, 20.6, 23.2, 25.9, 28.7, 31.6, 34.5, 37.6, 40.6, 43.7, 46.9, 50, 53.1, 56.3, 59.4, 62.4, 65.5, 68.4, 71.3, 74.1, 76.8, 79.4, 81.9, 84.2, 86.4, 88.5, 90.5, 92.2, 93.8, 95.2, 96.5, 97.6, 98.4, 99.1, 99.6, 99.9, 100];

  var xyCoords0to100ArrayOfArrays1 = [[100,100],[0,100]];
  for(let i = 0; i < xArray.length; i++) {
    xyCoords0to100ArrayOfArrays1.push([xArray[i], cosxArray[i]]);
  }
  const polygonPointsString = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays1, 100);

  return(
    <svg viewBox="0 0 100 100" preserveAspectRatio="none" style={{width:"100%", height:p_swailHeightPx + "px", background:"transparent"}}>
      <defs>
        <linearGradient id={gradientIDString} x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={"#" + p_colorTop} />
          <stop offset="100%" stopColor={"#" + p_colorBottom} />
        </linearGradient>
      </defs>
      <polygon points={polygonPointsString} style={{fill:"url(#" + gradientIDString + ")"}} />
    </svg>
  );
}